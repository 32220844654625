import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../CalendarIcon';
import { DailyBonusProps } from '../..';
import { useModalContext } from 'src/hooks/useModalContext';
import { twJoin } from 'tailwind-merge';
import { SidebarTooltip, Tooltips } from 'src/components/Sidebar/components/SidebarTooltip';

type DailyBonusWithoutUserProps = {
  status: number | undefined;
};

export const DailyBonusWithoutUser: FC<DailyBonusProps & DailyBonusWithoutUserProps> = ({
  compact,
  status,
  handleActiveTooltip,
  clearTooltipState,
  activeTooltip
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  if (!status || status !== 204) return null;

  const handleButtonClick = () => {
    openModal('SIGNUP', { props: { hideWhyNot: true } });
  };

  return compact ? (
    <SidebarTooltip
      id="daily-bonus-icon"
      mouseEnterFunction={() => handleActiveTooltip?.(Tooltips.DAILY_BONUS)}
      mouseLeaveFunction={() => clearTooltipState?.()}
      showTooltip={activeTooltip === Tooltips.DAILY_BONUS}
      tooltipContent={t('mainSidebarLinks.dailyBonus')}>
      <button
        className={twJoin(
          'w-full flex items-center gap-2 text-[18px] font-bold relative opacity-50',
          compact && 'justify-center'
        )}
        onClick={handleButtonClick}>
        <CalendarIcon day={1} isDisabled />
        {!compact && t('menuItem.dailyBonus')}
      </button>
    </SidebarTooltip>
  ) : (
    <button
      className={twJoin(
        'w-full flex items-center gap-2 text-[18px] font-bold relative opacity-50',
        compact && 'justify-center'
      )}
      onClick={handleButtonClick}>
      <CalendarIcon day={1} isDisabled />
      {!compact && t('menuItem.dailyBonus')}
    </button>
  );
};
