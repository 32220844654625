import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import header from '../../assets/images/headerLandingBonus.webp';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const LandingSecondPopUp = () => {
  const { t } = useTranslation();
  const { closeModal, openModal } = useModalContext();
  return (
    <ModalWrapper isTransparentBg>
      <div className="h-full">
        <img src={header} className="w-[300px] jgf:w-[200px]" alt="header" />
        <div className="flex w-[300px] jgf:w-[200px] flex-col items-center justify-center p-[15px] py-[20px] bg-[white]">
          <p className="text-background-fivefold text-[16px] font-bold leading-5">
            {t('popUp.megaWin')}
          </p>
          <p className="text-[16px] font-bold  leading-5 text-accountEmpty pt-[5px]">
            150% BONUS + 335 FREESPINS
          </p>
          <Button
            mode="button-secondary"
            className="mt-[30px]"
            onClick={() => {
              closeModal();
              openModal('SIGNUP', {
                props: { hideWhyNot: true }
              });
            }}
            title={`${t('popUp.getBonus')}`}>
            {t('popUp.getBonus')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};
