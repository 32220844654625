import { MESSAGE_TYPE } from 'src/modules/messages/types';
import { NewsStatusIcon } from '../svg/NewsStatusIcon';

interface MessageStatusProps {
  status: MESSAGE_TYPE;
}

export const MessageStatus = ({ status }: MessageStatusProps) => {
  return (
    <div className="flex items-center gap-1 w-fit bg-nm_label-bg rounded-full py-1 px-2 text-sm font-black">
      <NewsStatusIcon />
      <p className="text-nm_label-text">{status.toUpperCase()}!</p>
    </div>
  );
};
