import React from 'react';
import { twJoin } from 'tailwind-merge';

type SeparatorProps = {
  width?: string;
  noMarginTop?: boolean;
  white?: boolean;
};

export const Separator: React.FC<SeparatorProps> = ({ width = '100', noMarginTop, white }) => (
  <div
    className={twJoin(
      'border border-secondary',
      noMarginTop ? 'mt-0' : 'mt-5',
      width,
      Boolean(white) && 'border-white'
    )}
  />
);
