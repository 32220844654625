const PRELOAD_RETRIES_KEY = 'preloadRetries';

export const resetPreloadRetries = () => localStorage.setItem(PRELOAD_RETRIES_KEY, '0');
const incrementRetry = (attempt: number) => localStorage.setItem(PRELOAD_RETRIES_KEY, `${attempt}`);

export function addVitePreloadErrorHandler() {
  const preloadRetries = parseInt(localStorage.getItem(PRELOAD_RETRIES_KEY) || '0') || 0;

  window.addEventListener('vite:preloadError', () => {
    if (preloadRetries > 5) return;
    incrementRetry(preloadRetries + 1);
    location.reload();
  });
}
