import { SimpleClose } from 'react-easyrocket';
import { InputSearch } from 'src/components/Inputs/InputSearch';
import { SearchIcon, Spin } from 'src/components/svg';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { FC, RefObject, SetStateAction, useMemo } from 'react';
import { ProviderButton } from 'src/components/ProviderButton/ProviderButton';
import moonDog from 'src/assets/images/moonDog.webp';
import { useGetAllProvidersCached } from 'src/hooks/useGetAllProvidersCached';

interface ProvidersSearchAbsoluteProps {
  providerSearchRef: RefObject<HTMLDivElement>;
  providersSearchValue: string;
  toggleShowProviders: () => void;
  showProviders: boolean;
  setProvidersSearchValue: (value: SetStateAction<string>) => void;
  setGamesSearchValue: (value: SetStateAction<string>) => void;
  onChange?: (value: string) => void;
}

export const ProvidersSearchAbsolute: FC<ProvidersSearchAbsoluteProps> = ({
  providerSearchRef,
  providersSearchValue,
  toggleShowProviders,
  showProviders,
  setProvidersSearchValue,
  setGamesSearchValue,
  onChange
}) => {
  const { t } = useTranslation();

  const {
    providers: allProviders,
    isFetching,
    isFetched
  } = useGetAllProvidersCached({ enabled: showProviders });

  const handleChangeProviders = (value: string) => {
    setProvidersSearchValue(value);
  };

  const handleProviderSelect = () => {
    setGamesSearchValue('');
    onChange?.('');
  };

  const providers = useMemo(
    () =>
      allProviders.filter((provider) =>
        provider.name.toLowerCase().includes(providersSearchValue.toLowerCase())
      ),
    [providersSearchValue, allProviders]
  );

  return (
    <div
      className="flex flex-col items-center bg-gcp_providers_list_block max-h-[400px] z-[21] absolute bottom-0 translate-y-[100%] py-2.5 pb-[15px] w-full rounded-b-[20px]"
      ref={providerSearchRef}
      style={{ boxShadow: 'var(--gcp_show_providers_shadow)' }}>
      <div className="w-full px-3 mt-2 relative">
        <InputSearch
          placeholder={`${t('general.searchProvider')}`}
          value={providersSearchValue}
          onChange={handleChangeProviders}
          leftIcon={<SearchIcon />}
          classNameInput="search-input text-text-secondary font-medium text-[16px] bg-button-secondary-stroke w-full pr-3.5 px-2.5 h-[35px]"
          className="flex flex-row items-center text-text-secondary bg-button-secondary-stroke font-medium text-[16px] w-full h-auto py-[5px] border-b-2 border-b-text-secondary justify-around cursor-pointer"
        />
        {Boolean(providersSearchValue.length) && (
          <SimpleClose
            onShow={() => {
              handleChangeProviders('');
            }}
            className="w-[11px] h-[11px] absolute top-1/2 -translate-y-1/2 right-3"
          />
        )}
      </div>
      <div
        className={twJoin(
          isFetching && !isFetched
            ? 'flex items-center justify-center w-full h-full pt-2.5'
            : 'w-full grid grid-cols-4 gap-2.5 px-3 pt-5 overflow-y-scroll items-stretch',
          !isFetching && !providers?.length && '!block'
        )}>
        {providers?.map((provider) => (
          <div
            className="w-full"
            key={provider.id}
            onClick={() => {
              toggleShowProviders();
            }}>
            <ProviderButton provider={provider} onClick={handleProviderSelect} />
          </div>
        ))}
        {isFetching && (
          <div className="h-full w-full flex justify-center items-center self-center text-center">
            <Spin classNameSvg="w-[30px] h-[30px]" />
          </div>
        )}
        {!isFetching && !providers?.length && (
          <div className="w-full">
            <p className="text-white text-center font-normal text-[20px]">
              {t('general.noProvidersFound')}
            </p>
            <img src={moonDog} className="w-[200px] m-auto" alt="providers moon dog" />
          </div>
        )}
        <div className="h-5 bg-button-secondary-stroke"></div>
      </div>
    </div>
  );
};
