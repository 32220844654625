import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface LinkTitleWrapperProps {
  className?: string;
  isLoading?: boolean;
}

export const LinkTitleWrapper: FC<PropsWithChildren<LinkTitleWrapperProps>> = ({
  children,
  className
}) => {
  return (
    <p
      className={classNames(
        'text-sm text-center mb-1 w-auto hover:text-yellow transition',
        className
      )}>
      {children}
    </p>
  );
};
