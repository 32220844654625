import { FC, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';
import { Skeletons } from 'src/components/Skeletons';

interface TournamentInfoItemProps {
  title: string;
  className?: string;
  isLoading: boolean;
}

export const TournamentInfoItem: FC<PropsWithChildren<TournamentInfoItemProps>> = ({
  title,
  children,
  className,
  isLoading
}) => {
  return (
    <div
      className={twJoin(
        'flex justify-between items-center bg-table_row_bg_even py-2.5 px-5',
        className
      )}>
      <span className="first-letter:uppercase">
        {isLoading ? <Skeletons className="bg-bg-sceleton w-[100px] h-5" /> : title}
      </span>
      <span>{isLoading ? <Skeletons className="bg-bg-sceleton w-[100px] h-5" /> : children}</span>
    </div>
  );
};
