import { Child, SinglePageResponse } from 'react-easyrocket';
import { VisitedStories } from '../types';

export const getStoryKey = (code: string, imageMobile: string) => code + imageMobile;

export const getSlideIndex = (story: Child, visited: VisitedStories, isStoryVisited: boolean) => {
  if (story.childs?.length === 1 || isStoryVisited) return 0;

  const storyKey = getStoryKey(story.code, story.imageMobile);
  const record = visited[storyKey];

  if (typeof record === 'object' && story.childs) {
    const lastUnseenSlide = story.childs.findIndex((item) => {
      const itemKey = getStoryKey(item.code, item.imageMobile);

      return !record[itemKey];
    });
    return lastUnseenSlide === -1 ? 0 : lastUnseenSlide;
  }

  return 0;
};

export const getSortedChilds = (
  data: SinglePageResponse,
  visited: VisitedStories,
  isVisited: (item: Child) => boolean
) => {
  if (!data) return [];

  return data.childs?.sort((a, b) => {
    const aRecord = visited[getStoryKey(a.code, a.imageMobile)];
    const bRecord = visited[getStoryKey(b.code, b.imageMobile)];
    const aValue = typeof aRecord === 'object' ? isVisited(a) : Boolean(aRecord);
    const bValue = typeof bRecord === 'object' ? isVisited(b) : Boolean(bRecord);

    return Number(aValue) - Number(bValue);
  });
};
