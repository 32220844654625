import classNames from 'classnames';
import React, { ForwardedRef, forwardRef } from 'react';
import './CustomMarquee.css';

const CustomMarquee = forwardRef(
  (
    {
      children,
      className = '',
      containerClassName = ''
    }: //   horizontal = false
    {
      children: React.ReactNode[];
      horizontal?: boolean;
      className?: string;
      containerClassName?: string;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={classNames('customMarqueeVertical', containerClassName)} ref={ref}>
        <div className={classNames('customMarqueeVertical-content', className)}>{children}</div>
        <div
          className={classNames(
            'customMarqueeVertical-content customMarqueeVertical-content-second',
            className
          )}>
          {children}
        </div>
      </div>
    );
  }
);

CustomMarquee.displayName = 'CustomMarquee';

export { CustomMarquee };
