import googleIcon from 'src/assets/images/social-icons/google.png';
import microsoftIcon from 'src/assets/images/social-icons/microsoft.png';
import telegramIcon from 'src/assets/images/social-icons/telegram.webp';

type SocialIconsName = 'Google' | 'Microsoft' | 'Telegram';

export const SOCIAL_ICONS: Record<SocialIconsName, string> = {
  Google: googleIcon,
  Microsoft: microsoftIcon,
  Telegram: telegramIcon
};
