import { MouseEventHandler, TouchEventHandler, useState } from 'react';
import { SinglePageResponse } from 'react-easyrocket';

export const useTouchControls = (
  data: SinglePageResponse,
  closeStory: () => void,
  openNextItem: () => void,
  openPrevItem: () => void
) => {
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setStartX(e.touches[0].clientX);
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    const currentY = e.touches[0].clientY;

    const diffY = startY - currentY;

    if (data && data.childs && diffY < -150) {
      closeStory();
    }
  };

  const handleTouchEnd: TouchEventHandler<HTMLDivElement> = (e) => {
    const currentX = e.changedTouches[0].clientX;
    const diffX = startX - currentX;

    if (data && data.childs) {
      const swipeThreshold = 100;

      if (Math.abs(diffX) > swipeThreshold) {
        if (diffX > 0) {
          openNextItem();
        } else {
          openPrevItem();
        }
      }
    }
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.clientX < Math.round(window.innerWidth / 4)) {
      openPrevItem();
    } else {
      openNextItem();
    }
  };

  return { handleTouchStart, handleTouchMove, handleTouchEnd, handleClick };
};
