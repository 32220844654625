import { ActiveUserTournament, LocalTournament } from 'react-easyrocket';
import { OpenModalFunction } from 'src/newUseModalContext/types';
import { MODAL_MAP } from 'src/newUseModalContext/constants';

export const showNewLocalTournament = (
  activeTournaments: LocalTournament[] | undefined,
  activeLocalUserTournaments: ActiveUserTournament[],
  openModal: OpenModalFunction<typeof MODAL_MAP>
) => {
  if (!activeTournaments || !activeTournaments.length) return undefined;

  const localTournamentsFromLS = localStorage.getItem('localtournamentsObject');
  setNewLocalTournamentsObjectToLS(activeTournaments);

  const newTournament = findNewLocalTournament(localTournamentsFromLS, activeTournaments);

  if (newTournament) {
    const isUserAlreadyParticipates = checkParticipation(activeLocalUserTournaments, newTournament);

    !isUserAlreadyParticipates && openNewTournamentModal(newTournament, openModal);
  }

  return newTournament;
};

function setNewLocalTournamentsObjectToLS(activeTournaments: LocalTournament[]) {
  // Формируем из текущих активных турниров с сервера новый объект показанных турниров для local storage
  const newTournamentsForLS = activeTournaments.reduce(
    (acc, tournament) => ({
      ...acc,
      [tournament.id]: tournament.id
    }),
    {}
  );

  // сетаем в local storage новый объект просмотренных турниров
  localStorage.setItem('localtournamentsObject', JSON.stringify(newTournamentsForLS));
}

function findNewLocalTournament(
  localTournamentsFromLS: string | null,
  activeTournaments: LocalTournament[]
) {
  // если данных в local storage нет, возвращаем первый объект из массива активных турниров с сервера
  if (!localTournamentsFromLS) return activeTournaments[0];

  // если есть то ищем активный турнир с сервера, id которого нет в объекте из local storage
  return activeTournaments.find(
    (tournament) => !JSON.parse(localTournamentsFromLS)[tournament.id.toString()]
  );
}

function checkParticipation(
  activeLocalUserTournaments: ActiveUserTournament[],
  newTournament: LocalTournament
) {
  const isUserAlreadyParticipates = activeLocalUserTournaments.find(
    (activeTournament) => activeTournament.id === newTournament.id
  );

  return Boolean(isUserAlreadyParticipates);
}

function openNewTournamentModal(
  newTournament: LocalTournament,
  openModal: OpenModalFunction<typeof MODAL_MAP>
) {
  /**  открываем модалку нового турнира с флажком isLocalTournament,
   который указывает компоненту модалки, что мы передали пропсы ИМЕННО ЛОКАЛЬНОГО ТУРНИРА
   */
  openModal('NEW_LOCAL_TOURNAMENT', {
    props: newTournament
  });
}
