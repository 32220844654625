import { OpenModalOptions, OpenModalOptionsWithRequiredPriority, OpenedModal } from './types';

export const prepareModals =
  <ModalMapType>(
    newModalName: keyof ModalMapType,
    newModalOptions: OpenModalOptionsWithRequiredPriority
  ) =>
  (prevModalsList: OpenedModal[]): OpenedModal[] =>
    insertNewModalAlgorithm(preparePreviousModals(prevModalsList, newModalName, newModalOptions), {
      name: newModalName,
      options: newModalOptions
    });

function preparePreviousModals<ModalMapType>(
  prevModalsList: OpenedModal[],
  newModalName: keyof ModalMapType,
  newModalOptions?: OpenModalOptions
) {
  return newModalOptions?.replace
    ? prevModalsList.filter(({ name }) => name !== newModalName)
    : prevModalsList;
}

function insertNewModalAlgorithm(activeModals: OpenedModal[], newModal: OpenedModal) {
  return [...activeModals, newModal].sort((a, b) => a.options.priority - b.options.priority);
}
