import { FC, ReactNode, useState } from 'react';
import { tournamentProgressFormatter } from 'src/utils/tournamentProgressFormatter';
import {
  LocalTournament,
  LocalTournamentMember,
  LocalTournamentPlace,
  useGetUserTournamentProgress
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { TableHeadItem } from './components/TableHeadItem';
import { TableRow } from './components/TableRow';
import { getRowColor } from './utils/getRowColor';
import { TableCell } from './components/TableCell';
import { DEFAULT_TABLE_ROWS } from '../../constants';
import { ShowMoreButton } from './components/ShowMoreButton';
import { CurrentUserRow } from './components/CurrentUserRow';

interface LeaderboardTableProps {
  title: ReactNode;
  tournament: LocalTournament;
  members: LocalTournamentMember[];
  getPrize: (prize: LocalTournamentPlace) => string | undefined;
}

export const LeaderboardTable: FC<LeaderboardTableProps> = ({
  title,
  tournament,
  members,
  getPrize
}) => {
  const { t } = useTranslation();
  const [membersCount, setMembersCount] = useState<number>(DEFAULT_TABLE_ROWS);

  const { data: myProgress } = useGetUserTournamentProgress(String(tournament?.id) || '');

  const membersUserPlace = tournament?.members?.findIndex((member) => member.isCurrentUser);

  const isUserParticipate = membersUserPlace > -1;
  const leaderBoard = members?.slice(0, membersCount);

  const membersLength = members.length;
  const showButton = tournament && membersCount < membersLength;

  const handleShowMore = () => {
    if (!membersLength) {
      return;
    }

    if (membersCount + DEFAULT_TABLE_ROWS >= membersLength) {
      setMembersCount(membersLength);
      return;
    }

    setMembersCount((prev) => prev + DEFAULT_TABLE_ROWS);
  };

  return (
    <>
      {title}
      <table className="max-w-[900px] w-full mb-10">
        <thead className="bg-background-hexademical">
          <tr>
            <TableHeadItem>№</TableHeadItem>
            <TableHeadItem>{t('tournaments.user')}</TableHeadItem>
            <TableHeadItem>{t('tournaments.results')}</TableHeadItem>
            <TableHeadItem>{t('tournaments.prize')}</TableHeadItem>
          </tr>
        </thead>
        <tbody>
          {leaderBoard?.map((user, i) => {
            const isLeaderBoardPlace = membersUserPlace === i;
            return (
              <TableRow rowColor={getRowColor({ row: i })} key={i}>
                <TableCell isLeaderBoardPlace={isLeaderBoardPlace}>{i + 1}</TableCell>
                <TableCell isLeaderBoardPlace={isLeaderBoardPlace}>{user.login}</TableCell>
                <TableCell isLeaderBoardPlace={isLeaderBoardPlace}>
                  {tournamentProgressFormatter(
                    tournament?.type,
                    tournament?.tasks?.[0]?.type,
                    user?.progress
                  )}
                </TableCell>
                <TableCell isLeaderBoardPlace={isLeaderBoardPlace}>
                  {getPrize(tournament?.places[i])}
                </TableCell>
              </TableRow>
            );
          })}
          {isUserParticipate && (
            <CurrentUserRow
              membersUserPlace={membersUserPlace + 1}
              progress={myProgress?.progress[0]?.progress}
              prize={getPrize(tournament?.places[membersUserPlace])}
            />
          )}
        </tbody>
      </table>
      {showButton && <ShowMoreButton onClick={handleShowMore} />}
    </>
  );
};
