import rabbitCatch from 'src/assets/event-images/rabbit-catch.png';
import rabbitSpins from 'src/assets/event-images/rabbit-spins.png';
import eggs from 'src/assets/event-images/egg.png';

export const END_OF_PROMO = new Date('2024-04-15T00:00:00');

export const EVENT_LINKS = {
  CATCH_GAME: 'catch-easter-rabbit',
  CARD_GAME: 'easter-spins',
  SHOP: 'shop'
};

export const LS_KEY = 'promoNotification';

export type Name = 'catch' | 'card' | 'shop';

export type LinkParams = {
  path: string;
  imageSource: string;
  title: string;
};

export type NotificationParams = {
  path: string;
  imageSource: string;
  title: string;
  content: string;
  timerForShop: boolean;
};

export const daysToShowCatchGameLink: number[] = [];
export const daysToShowCardGameLink: number[] = [];
export const daysToShowShopLink: number[] = [];

export const daysToShowGamesLinkOnDev = [20, 21, 22, 23, 24, 25, 3, 4, 5];

export const daysToShowCatchGameNotification = daysToShowCatchGameLink;
export const daysToShowCardGameNotification = daysToShowCardGameLink;
export const daysToShowShopNotification: number[] = [];

export const EVENT_GAMES_LINK_CONFIG: Record<Name, LinkParams> = {
  catch: {
    path: EVENT_LINKS.CATCH_GAME,
    imageSource: rabbitCatch,
    title: 'easterPromo.catch'
  },
  card: {
    path: EVENT_LINKS.CARD_GAME,
    imageSource: rabbitSpins,
    title: 'easterPromo.spins'
  },
  shop: {
    path: EVENT_LINKS.SHOP,
    imageSource: eggs,
    title: 'easterPromo.shop'
  }
};

export const EVENT_NOTIFICATION_CONFIG: Record<Name, NotificationParams> = {
  catch: {
    path: EVENT_LINKS.CATCH_GAME,
    imageSource: rabbitCatch,
    title: 'easterPromo.notificationGameTitle',
    content: 'easterPromo.notificationCatchGame',
    timerForShop: false
  },
  card: {
    path: EVENT_LINKS.CARD_GAME,
    imageSource: rabbitSpins,
    title: 'easterPromo.notificationGameTitle',
    content: 'easterPromo.notificationCardGame',
    timerForShop: false
  },
  shop: {
    path: EVENT_LINKS.SHOP,
    imageSource: eggs,
    title: 'easterPromo.notificationShopTitle',
    content: 'easterPromo.notificationShopContent',
    timerForShop: true
  }
};
