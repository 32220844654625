export const BlackjackIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278124)">
      <path
        d="M24.9345 21.2359C24.3979 20.4393 23.6433 19.859 22.7664 19.5574C22.8181 19.2913 22.8445 19.0193 22.8445 18.7446C22.8445 16.3338 20.8189 14.3726 18.3293 14.3726C15.8395 14.3726 13.8141 16.3338 13.8141 18.7446C13.8141 19.0194 13.8404 19.2914 13.8921 19.5574C13.0152 19.859 12.2606 20.4393 11.724 21.236C11.0662 22.2126 10.8158 23.3997 11.0191 24.5788C11.2223 25.7577 11.8557 26.7925 12.8026 27.4924C13.7651 28.2038 14.9343 28.4856 16.0946 28.2854C16.4657 28.2214 16.8224 28.1083 17.1574 27.9522V30.0313H19.501V27.9522C19.836 28.1083 20.1927 28.2215 20.5638 28.2855C20.8076 28.3275 21.0516 28.3483 21.2942 28.3483C22.2062 28.3483 23.0955 28.0545 23.8558 27.4925C24.8027 26.7925 25.4361 25.7578 25.6394 24.5787C25.8428 23.3997 25.5924 22.2126 24.9345 21.2359ZM23.3298 24.1806C23.2296 24.7617 22.9217 25.2687 22.4626 25.608C22.0192 25.9358 21.4863 26.0664 20.9619 25.9761C20.2593 25.8549 19.687 25.3602 19.431 24.6527L18.3291 22.3172L17.2273 24.6527C16.9713 25.3602 16.3989 25.855 15.6963 25.9761C15.1722 26.0665 14.639 25.9358 14.1956 25.608C13.7366 25.2687 13.4286 24.7618 13.3284 24.1807C13.2283 23.5993 13.3487 23.0186 13.6676 22.5452C13.9756 22.0879 14.4341 21.7862 14.9585 21.6958C15.118 21.6683 15.2803 21.6612 15.4411 21.6745L17.9105 21.8798L16.5024 19.8408C16.2768 19.5141 16.1576 19.135 16.1576 18.7445C16.1576 17.6261 17.1318 16.7161 18.3292 16.7161C19.5266 16.7161 20.5008 17.626 20.5008 18.7445C20.5008 19.1351 20.3816 19.5142 20.156 19.8409L18.7481 21.8799L21.2173 21.6745C21.3779 21.6613 21.5403 21.6684 21.6999 21.6959C22.2242 21.7863 22.6827 22.0879 22.9907 22.5453C23.3096 23.0187 23.4301 23.5995 23.3298 24.1806Z"
        fill="white"
      />
      <path
        d="M35.1095 0.658691H10.9036V5.17548H6.24518V40.6559H30.4507V36.139H35.1096L35.1095 0.658691ZM28.1071 38.3123H8.58875V7.51906H28.1071V38.3123ZM32.766 33.7954H30.4506V5.17548H13.2472V3.00228H32.766V33.7954Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278124">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.658203 0.663086)"
        />
      </clipPath>
    </defs>
  </svg>
);
