import { SVGPropsIcon } from 'src/types';

export const NewsStatusIcon = (props: SVGPropsIcon) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_6_15275)">
      <path
        d="M24.1777 19.7484C24.1777 19.7484 22.6055 18.7749 22.7139 17.7479C22.8161 16.7692 24.102 16.4098 24.102 16.4098C25.5798 15.045 25.8886 14.3142 25.8886 14.3142C25.1918 13.9539 24.8573 13.7792 24.2113 13.4364C22.4793 14.7595 20.512 15.9294 18.6518 16.8493C16.9308 17.7012 14.7298 18.4718 12.7615 17.7191C10.8906 16.7492 10.0029 14.5928 9.49506 12.7409C8.94605 10.7383 8.51134 8.49241 8.34815 6.31901C7.66263 6.06433 7.31119 5.93133 6.57732 5.64968C6.57732 5.64968 6.23681 6.36625 6.19982 8.37753C6.19982 8.37753 6.78475 9.57633 6.12315 10.304C5.42806 11.0678 3.65564 10.5406 3.65564 10.5406C3.65564 10.5406 3.0887 11.3037 2.63735 12.9333C2.70907 14.4097 2.90907 15.9313 3.35717 17.335C4.61279 17.0043 6.27025 17.0832 7.14935 18.1653C8.25893 19.5308 7.61589 21.6916 6.97598 23.1679C6.97598 23.1679 7.86803 24.2981 9.4857 25.024C11.1034 25.7498 12.5407 25.6646 12.5407 25.6646C13.2187 24.2037 14.4895 22.2029 16.1623 22.2092C17.5248 22.214 18.67 23.2497 19.3037 24.4898C20.6503 23.8526 21.9073 23.0376 23.0702 22.1011C23.9875 20.6805 24.1806 19.7496 24.1806 19.7496L24.1777 19.7484Z"
        fill="url(#paint0_linear_6_15275)"
      />
      <path
        d="M19.4155 12.3214C19.4905 10.7451 18.0679 9.46145 16.7953 8.72117C15.3507 8.25641 13.5045 8.08228 12.3639 9.15757C10.9262 10.5139 11.8317 12.569 11.8317 12.569C12.7074 11.6605 14.1522 11.1432 15.3326 11.7539C16.5621 12.3902 17.2205 13.6061 17.221 14.9871C17.221 14.9871 19.3184 14.3358 19.4155 12.3214Z"
        fill="url(#paint1_linear_6_15275)"
      />
      <path
        d="M22.9723 16.4831C23.4129 14.9544 25.8873 14.3136 25.8873 14.3136C25.8723 12.9649 25.5241 11.5003 24.8526 10.3367C23.5783 10.4932 22.2604 10.1735 21.2918 9.33327C19.763 8.00899 21.4293 6.01987 21.4293 6.01987C21.4293 6.01987 20.813 4.77172 18.9466 3.9343C17.0801 3.09688 15.738 3.46632 15.738 3.46632C15.738 3.46632 15.3586 6.03286 13.3544 5.77197C12.0828 5.607 10.9684 4.83367 10.2375 3.77923C8.8839 4.05886 7.65923 4.78254 6.57891 5.65034C6.57891 5.65034 7.74389 7.92407 6.89637 9.27016C6.04741 10.6156 3.65579 10.5406 3.65579 10.5406C3.18931 12.0153 3.42501 13.7769 4.24606 15.0693C5.66161 14.925 7.15461 15.3974 8.01006 16.513C8.85664 17.6176 8.96641 19.1093 8.54944 20.4775C8.54944 20.4775 9.4045 21.2244 10.8899 21.8909C12.3753 22.5573 13.5016 22.6995 13.5016 22.6995C14.2027 21.5451 15.431 20.5092 16.8217 20.4666C18.2661 20.4234 19.5692 21.165 20.404 22.319C21.9435 21.964 23.3494 21.1365 24.1793 19.749C24.1793 19.749 22.5332 18.0124 22.9738 16.4837L22.9723 16.4831ZM11.3063 11.203C12.0796 9.37565 14.6396 8.3747 16.5287 9.3193C18.2839 10.1967 19.5182 12.5316 18.5952 14.4733C17.7006 16.3555 15.2084 16.9319 13.4326 16.2198C11.5283 15.4553 10.5548 12.9818 11.3063 11.203Z"
        fill="url(#paint2_linear_6_15275)"
      />
      <path
        d="M17.0997 8.04661C14.6269 6.9283 11.4161 8.03226 10.3065 10.6168C9.18449 13.2293 10.2422 16.5852 12.8407 17.7546C15.4441 18.9245 18.6879 17.4774 19.8765 14.9053C21.0453 12.3773 19.5121 9.13782 17.0997 8.04661ZM13.7579 15.4946C12.3042 14.8424 11.4699 13.0944 12.1136 11.5828C12.7723 10.0338 14.6896 9.46043 16.1678 10.1237C17.6459 10.7869 18.4938 12.6009 17.7747 14.1228C17.0714 15.6094 15.2117 16.1469 13.7579 15.4946Z"
        fill="url(#paint3_linear_6_15275)"
      />
      <g style={{ mixBlendMode: 'soft-light', opacity: 0.5 }}>
        <path
          d="M17.8833 14.6776C18.1371 14.9167 19.9665 14.6354 20.0804 13.7502C20.404 11.2503 18.9773 9.04779 16.6984 8.17872C14.3105 7.26778 10.893 8.41655 10.2651 11.025C10.1646 11.4454 11.6296 12.034 11.9575 11.511C12.6577 10.3933 13.7934 9.52917 15.1525 9.66463C16.6338 9.81261 17.6422 10.8223 18.0588 12.173C18.3777 13.2101 17.7174 14.5238 17.8827 14.6791L17.8833 14.6776Z"
          fill="white"
        />
      </g>
      <path
        d="M12.9508 17.2857C10.8399 16.3386 9.23693 13.3393 10.157 10.973C10.1366 11.0185 10.1155 11.0655 10.0951 11.111C9.12345 13.4806 10.4888 16.6977 12.7578 17.7158C16.0506 19.1932 18.8796 16.3608 18.8796 16.3608C18.8694 16.3527 15.9776 18.6437 12.9508 17.2857Z"
        fill="url(#paint4_linear_6_15275)"
      />
      <g style={{ mixBlendMode: 'soft-light', opacity: 0.5 }}>
        <path
          d="M11.1118 9.3453C11.1118 9.3453 13.8164 7.02496 16.9554 8.43335C20.4596 10.0056 20.2539 13.4401 20.2539 13.4401C20.2539 13.4401 20.5893 9.63179 17.0189 8.02985C13.6542 6.52017 11.1118 9.3453 11.1118 9.3453Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.3 }}>
        <path
          d="M9.89878 4.04515C9.30455 3.99895 6.88268 5.58913 7.0298 6.00428C7.43961 7.15449 9.26079 9.59039 10.1065 9.61893C10.9713 9.64723 12.4076 7.88991 13.8036 7.5235C15.7372 7.01386 18.2777 7.83104 19.3505 9.67897C20.2045 11.1519 20.188 13.772 20.5634 14.6563C20.8702 15.3777 25.3196 14.7818 25.7008 13.99C25.8887 13.5982 25.222 11.1919 24.5897 10.7724C23.6705 10.1607 21.6587 10.0939 20.7445 9.00131C20.1121 8.24322 20.9604 6.64529 20.8909 6.03397C20.7548 4.85495 16.7401 2.90373 16.0146 3.92014C15.4952 4.64641 14.7524 5.9901 13.6301 5.98909C12.0793 5.98808 10.9062 4.1251 9.89956 4.04726L9.89878 4.04515Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.5 }}>
        <path
          d="M9.80127 12.4322C9.76868 12.4317 9.30406 17.2171 12.5926 18.6926C15.4339 19.9674 18.8102 16.4425 18.8102 16.4425C18.7933 16.4226 15.8771 18.5986 12.9523 17.2863C10.0276 15.9741 9.80271 12.4328 9.80271 12.4328L9.80127 12.4322Z"
          fill="#CA2790"
        />
      </g>
      <path
        d="M16.0293 3.41368C17.9078 4.02021 21.3197 5.83672 21.3197 5.83672C21.3197 5.83672 20.4367 4.38602 18.5396 3.75173C16.641 3.11679 16.0308 3.41432 16.0308 3.41432L16.0293 3.41368Z"
        fill="#FFD300"
      />
      <path
        d="M8.61568 20.5337C11.248 23.129 13.5001 22.6988 13.5001 22.6988C13.4979 22.234 8.56804 20.4859 8.61568 20.5337Z"
        fill="#FFD300"
      />
      <path
        d="M10.1473 3.79167C8.81203 3.8609 7.41902 4.59017 6.57598 5.64907C6.57598 5.64907 8.84711 4.69132 9.47537 4.27667C10.1036 3.86202 10.1487 3.79232 10.1487 3.79232L10.1473 3.79167Z"
        fill="#FFD300"
      />
      <path
        d="M24.8533 10.3352C25.1687 11.6688 25.5115 12.9952 25.888 14.3121C25.888 14.3121 25.9204 12.3958 25.6373 11.4946C25.3534 10.5949 24.8533 10.3352 24.8533 10.3352Z"
        fill="#FFD300"
      />
      <path
        d="M12.2176 14.0383C12.6759 15.681 14.5568 16.3592 16.0321 15.6121C16.0321 15.6121 14.6426 15.9216 13.6861 15.4007C12.4382 14.7209 12.2162 14.0376 12.2162 14.0376L12.2176 14.0383Z"
        fill="#FFD300"
      />
      <g style={{ mixBlendMode: 'multiply', opacity: 0.5 }}>
        <path
          d="M19.3962 24.2068C19.8371 24.514 20.4586 22.4934 19.5876 21.6282C18.5729 20.621 17.3032 20.4517 16.9199 20.7593C16.6007 21.0164 16.5647 21.8167 17.2673 22.2642C17.7232 22.5534 19.1214 24.0148 19.3962 24.2068Z"
          fill="#CA2790"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.3 }}>
        <path
          d="M20.4273 22.4634C20.1326 23.7208 19.6864 24.1995 19.7779 24.1841C20.6783 24.0291 22.1954 22.8424 22.9818 21.9291C23.6959 21.0998 22.9816 21.3982 22.1514 21.8087C21.3197 22.2185 20.4743 22.2624 20.4281 22.4656L20.4273 22.4634Z"
          fill="#CA2790"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.5 }}>
        <path
          d="M8.38786 20.7683C7.97634 21.185 7.14414 22.9435 7.3286 23.2061C7.51306 23.4687 8.65978 24.3818 9.88508 24.958C11.1104 25.5342 12.3217 25.6527 12.5218 25.2682C12.722 24.8836 13.5275 23.4041 13.0852 23.2656C10.8359 22.5579 8.49195 20.6633 8.3872 20.7697L8.38786 20.7683Z"
          fill="#CA2790"
        />
      </g>
      <path
        d="M19.1032 21.0865C19.9932 22.4486 19.9988 23.3099 20.1393 23.1508C20.2798 22.9916 21.1769 22.2744 21.0145 22.0781C20.8521 21.8818 18.9024 20.7795 19.1032 21.0865Z"
        fill="url(#paint5_linear_6_15275)"
      />
      <g style={{ mixBlendMode: 'multiply', opacity: 0.5 }}>
        <path
          d="M3.47071 17.1743C3.35883 16.3342 4.70047 14.7954 5.98686 15.2932C7.27324 15.791 8.00375 16.5614 7.88526 17.2682C7.76676 17.9751 6.28707 17.2883 5.42359 17.1107C4.55867 16.9325 3.49058 17.3226 3.47071 17.1743Z"
          fill="#CA2790"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.3 }}>
        <path
          d="M3.26303 11.6393C2.64599 12.5756 2.72562 13.5724 2.8929 14.8589C3.06018 16.1454 3.23715 16.8367 3.34715 16.6339C3.4578 16.4296 4.00884 15.3208 4.00486 14.9716C4.00232 14.6231 3.67781 14.176 3.48799 13.0822C3.3059 12.0288 3.44143 11.3649 3.26094 11.6401L3.26303 11.6393Z"
          fill="#CA2790"
        />
      </g>
      <path
        d="M4.09471 14.8022C3.8281 15.6312 3.82549 15.7641 4.06743 15.6945C4.30938 15.625 5.81996 15.0649 5.66982 15.0222C5.52035 14.978 4.217 14.4179 4.09471 14.8022Z"
        fill="url(#paint6_linear_6_15275)"
      />
      <path
        d="M21.0882 9.4712C20.9068 10.834 22.2188 10.4317 22.3763 10.2731C22.5338 10.1145 21.1131 9.28486 21.0882 9.4712Z"
        fill="white"
      />
      <path
        d="M18.1803 9.1804C17.7723 9.8279 18.7753 10.3414 19.0631 10.0967C19.3514 9.85044 18.3793 8.86407 18.1803 9.1804Z"
        fill="white"
      />
      <g style={{ mixBlendMode: 'soft-light', opacity: 0.3 }}>
        <path
          d="M9.16763 11.908C8.00204 11.4643 3.94386 10.5993 3.70324 11.4436C3.47334 12.2485 4.08692 14.192 4.46299 14.3202C5.80981 14.7834 8.76889 15.7073 9.31766 15.0771C9.51266 14.8542 9.59295 12.0688 9.16829 11.9065L9.16763 11.908Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'soft-light' }}>
        <path
          d="M11.8865 16.5083C13.2531 15.1024 13.9903 17.2671 13.6474 17.4077C13.3051 17.5469 11.7393 16.6592 11.8865 16.5083Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply', opacity: 0.5 }}>
        <path
          d="M15.2441 11.4585C13.0702 11.2061 12.1358 12.2646 12.1365 12.0321C12.1366 11.1117 13.8151 9.90394 15.0246 9.9264C16.985 9.96135 15.9336 11.5386 15.2441 11.4585Z"
          fill="#CA2790"
        />
      </g>
      <g style={{ mixBlendMode: 'soft-light', opacity: 0.5 }}>
        <path
          d="M7.74523 21.4498C8.37464 20.3973 9.13979 18.3301 8.33683 17.0282C7.24099 15.251 3.4084 16.94 3.84146 16.9104C9.37669 16.5355 7.74667 21.4505 7.74667 21.4505L7.74523 21.4498Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6_15275"
        x1="9.48217"
        y1="25.0241"
        x2="16.279"
        y2="9.87561"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.05" stopColor="#FF4B00" />
        <stop offset="1" stopColor="#FF7204" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6_15275"
        x1="16.7924"
        y1="8.71988"
        x2="14.5235"
        y2="13.7767"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.05" stopColor="#FF4B00" />
        <stop offset="1" stopColor="#FF7204" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6_15275"
        x1="18.9451"
        y1="3.93365"
        x2="10.8884"
        y2="21.8902"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9600" />
        <stop offset="1" stopColor="#FFA900" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6_15275"
        x1="12.8499"
        y1="17.757"
        x2="17.1888"
        y2="8.08661"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFB100" />
        <stop offset="1" stopColor="#FFC500" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_6_15275"
        x1="9.11573"
        y1="13.2937"
        x2="18.3903"
        y2="17.455"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.05" stopColor="#FF4B00" />
        <stop offset="1" stopColor="#FF7204" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_6_15275"
        x1="18.7889"
        y1="21.7178"
        x2="20.8293"
        y2="22.6333"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFA900" />
        <stop offset="1" stopColor="#FFC600" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_6_15275"
        x1="4.00382"
        y1="15.0047"
        x2="5.41154"
        y2="15.6363"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFA900" />
        <stop offset="1" stopColor="#FFC600" />
      </linearGradient>
      <clipPath id="clip0_6_15275">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
