import { useTranslation } from 'react-i18next';
import { Language, SupportedLanguages } from 'src/locale/types';
import { languageOptions, SUPPORTED_LANGUAGES } from 'src/locale/LanguagesAdjustable';
import { useApiContext, useUpdateInfo } from 'react-easyrocket';

interface UseLanguageChangeOutput {
  handleChangeLanguage: (lang: string) => void;
  language: keyof SupportedLanguages;
  currentLanguageIcon: Language['icon'];
  currentLanguageName: Language['labelName'];
}

export const useLanguageChange = (): UseLanguageChangeOutput => {
  const {
    i18n: { language, changeLanguage }
  } = useTranslation();
  const { mutate: updateProfileLanguageInfo } = useUpdateInfo();

  const { isAuthenticated } = useApiContext();

  const handleChangeLanguage = (lang: string): void => {
    const newLanguage =
      lang && lang in SUPPORTED_LANGUAGES
        ? (lang as keyof SupportedLanguages)
        : SUPPORTED_LANGUAGES.en;

    changeLanguage(newLanguage)
      .then(() => {
        if (isAuthenticated && newLanguage !== language) {
          updateProfileLanguageInfo({ language: newLanguage });
        }
      })
      .catch(() => changeLanguage(language));
  };

  const languageOption = languageOptions[language as keyof SupportedLanguages];

  const { icon: currentLanguageIcon, labelName: currentLanguageName } = languageOption;

  return {
    language: language as keyof SupportedLanguages,
    handleChangeLanguage,
    currentLanguageIcon,
    currentLanguageName
  };
};
