import { FC } from 'react';
import { progressBarAnimation } from '../config';
import { motion } from 'framer-motion';

interface StoryProgressbarProps {
  isAnimated: boolean;
  isComplete: boolean;
}

export const StoryProgressbar: FC<StoryProgressbarProps> = ({ isAnimated, isComplete }) => {
  return (
    <div className="h-[3px] w-full rounded-[10px] bg-stories_progress_bar_bg">
      {isAnimated && (
        <motion.div
          className="h-[3px] w-full rounded-[10px] bg-stories_progress_bar_bg_active"
          style={{ width: '0%' }}
          animate={{ width: '100%' }}
          transition={{ duration: progressBarAnimation, ease: 'linear' }}
        />
      )}
      {isComplete && (
        <div className="h-[3px] w-full rounded-[10px] bg-stories_progress_bar_bg_active" />
      )}
    </div>
  );
};
