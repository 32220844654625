import { twMerge } from 'tailwind-merge';
import { FC, PropsWithChildren } from 'react';

/** Этот компонент редерит прямоугольник, имитирующий блок по его размерам, для более плавного UI
 * во время загрузки элемента
 */

interface SkeletonOfAnySizeProps {
  className?: string;
}

export const SkeletonOfAnySize: FC<PropsWithChildren<SkeletonOfAnySizeProps>> = ({
  className,
  children
}) => {
  return (
    <div className={twMerge('bg-gg_bg rounded-[10px] animate-pulse', className)}>{children}</div>
  );
};

/**
 * Рендерит count скелетонов. Без обертки
 * @param count - количество скелетонов
 * @param className - класснейм ОДНОГО скелетона
 */
export const Skeletons = ({ count = 1, className }: { count?: number; className?: string }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <SkeletonOfAnySize className={className} key={index} />
      ))}
    </>
  );
};
