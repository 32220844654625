import { forwardRef, PropsWithChildren, RefAttributes } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useResolvedPath } from 'react-router-dom';

import { defaultFallbackLanguage } from '../../constants';

export const LinkLng = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<LinkProps & RefAttributes<HTMLAnchorElement>>
>(({ children, to, ...props }, ref) => {
  const { i18n } = useTranslation();

  const { pathname, hash, search } = useResolvedPath(to);

  const href = pathname + search + hash;

  const fallbackLanguage = Array.isArray(i18n.options.fallbackLng)
    ? i18n.options.fallbackLng[0]
    : i18n.options.fallbackLng;

  const defaultLanguage = (fallbackLanguage as string) || defaultFallbackLanguage;

  const linkWithLanguage = i18n.language !== defaultLanguage ? `/${i18n.language}${href}` : href;

  return (
    <Link to={linkWithLanguage} {...props} ref={ref}>
      {children}
    </Link>
  );
});

LinkLng.displayName = 'LinkLng';
