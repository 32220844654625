import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { GPT_GAME_MODAL_BG } from '../../config';

export const ModalContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center',
        'left-0 top-0 fixed z-[900] w-screen h-full max-h-screen'
      )}
      style={{
        background: GPT_GAME_MODAL_BG,
      }}
    >
      {children}
    </div>
  );
};
