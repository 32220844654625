import { DepositPaymentMethod, DepositPaymentMethodOption } from 'react-easyrocket';
import { CRYPTO_ICONS } from 'src/pages/AccountWallet/config';
import { twJoin } from 'tailwind-merge';
import wallet from 'src/assets/images/wallet.png';
import { Skeletons } from 'src/components/Skeletons';

interface CryptoMethodsProps {
  sortedPaymentMethods: DepositPaymentMethod[];
  coin: string;
  onSelectCryptoPaymentMethod?: (methodName: DepositPaymentMethodOption['name']) => void;
  isLoadingPaymentMethods?: boolean;
}

export const CryptoMethods = ({
  sortedPaymentMethods,
  coin,
  onSelectCryptoPaymentMethod,
  isLoadingPaymentMethods
}: CryptoMethodsProps) => {
  const cryptoMethods = sortedPaymentMethods?.filter(({ type }) => type === 'coin')?.[0]?.options;

  return (
    <div className="flex gap-1 flex-wrap justify-center">
      {!isLoadingPaymentMethods ? (
        cryptoMethods?.map((method) => {
          const selected = method.name === coin;
          return (
            <div
              key={method.name}
              className={twJoin(
                'rounded-[10px] relative w-[75px] 768:w-[85px] h-[80px] flex flex-col items-center justify-center py-2 px-1 gap-1 payment cursor-pointer',
                selected ? 'bg-button-primary' : 'bg-background-octal'
              )}
              onClick={() => {
                onSelectCryptoPaymentMethod && onSelectCryptoPaymentMethod(method.name);
              }}>
              {method.name && method.name in CRYPTO_ICONS ? (
                CRYPTO_ICONS[method.name]
              ) : (
                <img src={wallet} alt={method.title} className="object-contain h-7" />
              )}
              <p
                className={twJoin(
                  'text-[11px] text-center break-all leading-[110%]',
                  selected ? 'text-black' : 'text-white'
                )}>
                {method.title}
              </p>
            </div>
          );
        })
      ) : (
        <Skeletons className="w-[75px] 768:w-[85px] h-[80px]" count={8} />
      )}
    </div>
  );
};
