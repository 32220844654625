import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { CryptoIcon, MethodsIcon } from '../../../components/svg';
import { Dispatch, SetStateAction } from 'react';
import { PaymentMethod } from 'react-easyrocket';

interface TabsMethodsProps {
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  resetCoin: () => void;
  selectCryptoPaymentMethod: () => void;
  currentMethods: string;
  setCurrentMethods: Dispatch<SetStateAction<string>>;
  isLoadingPaymentMethods: boolean;
}

export const TabsMethods = ({
  setPaymentMethod,
  resetCoin,
  selectCryptoPaymentMethod,
  currentMethods,
  isLoadingPaymentMethods,
  setCurrentMethods
}: TabsMethodsProps) => {
  const { t } = useTranslation();
  const handleBasicMethods = () => {
    setCurrentMethods('basic');
    setPaymentMethod(undefined);
    resetCoin();
  };

  const handleCryptoMethods = () => {
    setCurrentMethods('crypto');
    selectCryptoPaymentMethod();
    resetCoin();
  };

  return (
    <div className="flex bg-[#221B47] rounded-[10px] p-1 min-h-[45px]">
      <button
        onClick={handleBasicMethods}
        disabled={isLoadingPaymentMethods}
        className={twJoin(
          'text-[white] font-bold flex gap-[10px] items-center flex-grow justify-center rounded-[8px] transition-colors',
          currentMethods === 'basic' ? 'bg-[#D940FF]' : 'bg-[#221B47]'
        )}>
        <MethodsIcon />
        {t('depositTab.methods')}
      </button>
      <button
        onClick={handleCryptoMethods}
        disabled={isLoadingPaymentMethods}
        className={twJoin(
          'text-[white] font-bold flex gap-[10px] items-center flex-grow justify-center rounded-[8px] transition-colors',
          currentMethods === 'crypto' ? 'bg-[#D940FF]' : 'bg-[#221B47]'
        )}>
        <CryptoIcon />
        {t('depositTab.crypto')}
      </button>
    </div>
  );
};
