import { FC, SVGProps } from 'react';

export const NavbarSearchIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="white"
    {...props}>
    <path d="M8.52215 7.55148C8.23371 7.26281 7.76574 7.26281 7.47731 7.55148C6.40637 8.62242 5.88 10.1143 6.03297 11.6448C6.07113 12.0261 6.39235 12.3104 6.76746 12.3104C6.79211 12.3104 6.81696 12.3091 6.8416 12.3066C7.24778 12.266 7.54406 11.9037 7.50344 11.4978C7.39457 10.4101 7.76602 9.35246 8.52215 8.59629C8.81082 8.30789 8.81082 7.83988 8.52215 7.55148Z" />
    <path d="M11.4483 3C6.78988 3 3 6.78988 3 11.4483C3 16.1067 6.78988 19.8966 11.4483 19.8966C16.1067 19.8966 19.8966 16.1067 19.8966 11.4483C19.8966 6.78988 16.1066 3 11.4483 3ZM11.4483 18.4187C7.60469 18.4187 4.47785 15.2919 4.47785 11.4483C4.47785 7.60469 7.60469 4.47785 11.4483 4.47785C15.2916 4.47785 18.4187 7.60469 18.4187 11.4483C18.4187 15.2919 15.2919 18.4187 11.4483 18.4187Z" />
    <path d="M22.7835 21.7387L17.414 16.3692C17.1254 16.0805 16.6579 16.0805 16.3692 16.3692C16.0805 16.6577 16.0805 17.1256 16.3692 17.4141L21.7387 22.7835C21.883 22.9279 22.0719 23 22.2611 23C22.4503 23 22.6392 22.9279 22.7835 22.7835C23.0722 22.4951 23.0722 22.0271 22.7835 21.7387Z" />
  </svg>
);

export const NavbarSearchIconActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path
      d="M8.52215 7.55148C8.23371 7.26281 7.76574 7.26281 7.47731 7.55148C6.40637 8.62242 5.88 10.1143 6.03297 11.6448C6.07113 12.0261 6.39235 12.3104 6.76746 12.3104C6.79211 12.3104 6.81696 12.3091 6.8416 12.3066C7.24778 12.266 7.54406 11.9037 7.50344 11.4978C7.39457 10.4101 7.76602 9.35246 8.52215 8.59629C8.81082 8.30789 8.81082 7.83988 8.52215 7.55148Z"
      fill="#FFF000"
    />
    <path
      d="M11.4483 3C6.78988 3 3 6.78988 3 11.4483C3 16.1067 6.78988 19.8966 11.4483 19.8966C16.1067 19.8966 19.8966 16.1067 19.8966 11.4483C19.8966 6.78988 16.1066 3 11.4483 3ZM11.4483 18.4187C7.60469 18.4187 4.47785 15.2919 4.47785 11.4483C4.47785 7.60469 7.60469 4.47785 11.4483 4.47785C15.2916 4.47785 18.4187 7.60469 18.4187 11.4483C18.4187 15.2919 15.2919 18.4187 11.4483 18.4187Z"
      fill="#FFF000"
    />
    <path
      d="M22.7835 21.7387L17.414 16.3692C17.1254 16.0805 16.6579 16.0805 16.3692 16.3692C16.0805 16.6577 16.0805 17.1256 16.3692 17.4141L21.7387 22.7835C21.883 22.9279 22.0719 23 22.2611 23C22.4503 23 22.6392 22.9279 22.7835 22.7835C23.0722 22.4951 23.0722 22.0271 22.7835 21.7387Z"
      fill="#FFF000"
    />
  </svg>
);

type SearchIconThinProps = {
  classNameIcon?: string;
};

export const SearchIconThin = ({ classNameIcon }: SearchIconThinProps) => {
  return (
    <svg
      width="22"
      height="22"
      className={classNameIcon}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23721 2.07154C5.65836 1.12195 7.32919 0.615112 9.03839 0.615112H9.03844C11.3304 0.615258 13.5284 1.52578 15.149 3.14641C16.7696 4.76704 17.6802 6.96505 17.6803 9.25697V9.25702C17.6803 10.9662 17.1735 12.637 16.2239 14.0582C16.0572 14.3076 15.8782 14.5474 15.6878 14.7767L20.9322 20.0211C21.2442 20.3331 21.2442 20.8389 20.9322 21.1509C20.6203 21.4628 20.1145 21.4628 19.8025 21.1509L14.5581 15.9065C13.8952 16.4568 13.1501 16.9078 12.3455 17.2411C10.7664 17.8952 9.02881 18.0663 7.35244 17.7329C5.67608 17.3994 4.13624 16.5764 2.92764 15.3678C1.71905 14.1592 0.89599 12.6193 0.56254 10.943C0.22909 9.2666 0.400229 7.529 1.05431 5.9499C1.7084 4.3708 2.81605 3.02112 4.23721 2.07154ZM9.03834 2.21278C7.64514 2.21279 6.28323 2.62593 5.12483 3.39995C3.96641 4.17398 3.06353 5.27414 2.53037 6.56131C1.99721 7.84847 1.85771 9.26483 2.12951 10.6313C2.40132 11.9977 3.07221 13.2529 4.05737 14.238C5.04252 15.2232 6.29768 15.8941 7.66413 16.1659C9.03058 16.4377 10.4469 16.2982 11.7341 15.765C13.0213 15.2319 14.1214 14.329 14.8955 13.1706C15.6695 12.0122 16.0826 10.6503 16.0826 9.25707M9.03839 2.21278C10.9066 2.21292 12.6982 2.95512 14.0193 4.27614C15.3403 5.59716 16.0825 7.38881 16.0826 9.25702"
        fill="#9D9D9D"
      />
    </svg>
  );
};

type SearchIconProps = {
  className?: string;
  fill?: string;
};

export const SearchIcon = ({ className, fill }: SearchIconProps) => {
  return (
    <svg
      width="15"
      height="15"
      className={className}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.65995 2.26087C4.49388 1.70366 5.4743 1.40625 6.47725 1.40625H6.47728C7.82216 1.40634 9.11193 1.94062 10.0629 2.8916C11.0139 3.84257 11.5482 5.13234 11.5482 6.47722V6.47725C11.5482 7.48019 11.2508 8.46062 10.6936 9.29454C10.5958 9.4409 10.4908 9.58159 10.3791 9.71618L13.4565 12.7935C13.6395 12.9766 13.6395 13.2734 13.4565 13.4565C13.2734 13.6395 12.9766 13.6395 12.7935 13.4565L9.71618 10.3791C9.32718 10.702 8.88998 10.9667 8.41783 11.1622C7.49123 11.546 6.47162 11.6465 5.48795 11.4508C4.50427 11.2551 3.6007 10.7722 2.89151 10.063C2.18232 9.35379 1.69936 8.45023 1.50369 7.46655C1.30802 6.48287 1.40845 5.46326 1.79226 4.53666C2.17607 3.61006 2.82603 2.81808 3.65995 2.26087ZM6.47722 2.34375C5.6597 2.34376 4.86054 2.58618 4.1808 3.04037C3.50105 3.49456 2.97125 4.14013 2.6584 4.89543C2.34554 5.65072 2.26368 6.48183 2.42318 7.28365C2.58267 8.08547 2.97635 8.82199 3.55443 9.40007C4.13251 9.97815 4.86902 10.3718 5.67084 10.5313C6.47266 10.6908 7.30377 10.609 8.05907 10.2961C8.81437 9.98324 9.45993 9.45344 9.91412 8.77369C10.3683 8.09395 10.6107 7.29479 10.6107 6.47728M6.47725 2.34375C7.5735 2.34383 8.62482 2.77934 9.39998 3.55451C10.1751 4.32967 10.6107 5.381 10.6107 6.47725"
        fill={fill || 'white'}
      />
    </svg>
  );
};
