import { t } from 'i18next';
import { CountryCode, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { allCountriesData } from '../../constants';
import allCountries from '../../constants/countries.v1.json';

import {
  PASSWORD_VALIDATION_LENGTH,
  PASSWORD_VALIDATION_SYMBOLS
} from 'src/constants/validationRules';
import { makeFloat } from 'react-easyrocket';
import dayjs from 'dayjs';

export const isBirthdayOverEighteen = (date: string) => {
  const birthday = dayjs(date);
  const currentDate = dayjs();

  const age = currentDate.diff(birthday, 'year');

  return age >= 18;
};

export function validateRequired(value: string) {
  if (!value) return t('validation.valueRequired');
  return undefined;
}

export const validateMaxLength = (maxLength: number) => (value: string) => {
  if (value && value.length > maxLength)
    return `${t('general.valueLonger')} ${maxLength} ${t('general.characters')}`;
  return undefined;
};

export const validateDifferenceOfPasswords = (value: string, values: any) => {
  if (value === values.oldPassword) {
    return t('accountProfileSecurity.newPasswordFromOld');
  }
  return undefined;
};

export const validateNumbersString = (value: any) => {
  if (!value.length) {
    return undefined;
  }

  if (/^[0-9]+$/.test(value)) {
    return undefined;
  }
  if (!/^[0-9]+$/.test(value)) {
    return 'registration.lettersInPhoneNumberError';
  }
};

export const validatePhoneByCountryCode = (value: string, values: any) => {
  if (!value.length) {
    return undefined;
  }

  const alpha2 = allCountriesData.find((country) =>
    country.phoneCodes.includes(values.countryCode)
  )?.alpha2;
  if (!alpha2) {
    return t('registration.notFoundCountryPhoneCodeError');
  }
  if (
    isValidPhoneNumber(
      `+${getCountryCallingCode(alpha2 as CountryCode)}${value}`,
      alpha2 as CountryCode
    )
  ) {
    return undefined;
  }
  return t('registration.invalidPhoneNumberError');
};

export const validatePositiveNumber = (value: number) => {
  if (value <= 0) return t('depositTab.invalidAmountError');
  return undefined;
};

export const validateEmail = (email: string) => {
  if (
    email.toLowerCase().match(
      // eslint-disable-next-line no-useless-escape
      /^([^@\s\."'\(\)\[\]\{\}\\/,:;]+\.)*[^@\s\."'\(\)\[\]\{\}\\/,:;]+@[^@\s\."'\(\)\[\]\{\}\\/,:;]+(\.[^@\s\."'\(\)\[\]\{\}\\/,:;]+)+$/
    )
  ) {
    return undefined;
  }
  return t('registration.enterValidEmail');
};

export const validateCountry = (country: string | undefined) => {
  if (allCountries.find((c) => c.name === country)?.alpha3) {
    return undefined;
  }
  return t('registration.invalidCountry');
};

export const validateForStringEquality = (value: string, values: any) => {
  if (value !== values.newPassword) {
    return t('accountProfileSecurity.passwordsAreNotEqual');
  }
  return undefined;
};

export const validateWithdrawalAccountRequired =
  (type: string | undefined, typeToValidate: string) => (value: string) => {
    if (type !== typeToValidate) {
      if (!value) {
        return t('validation.valueRequired');
      }
      return undefined;
    }
    return undefined;
  };

export const validateWithdrawalCardRequired =
  (type: string | undefined, typeToValidate: string) => (value: string) => {
    if (type === typeToValidate) {
      if (!value) {
        return t('validation.valueRequired');
      }
      return undefined;
    }
    return undefined;
  };

export const validatePassword = (password: string) => {
  const passwordRules = [
    {
      text: `${t('rulesPassword.countSymbols')}`,
      regex: PASSWORD_VALIDATION_LENGTH
    },
    {
      text: `${t('rulesPassword.onlyLatinLetters')}`,
      regex: PASSWORD_VALIDATION_SYMBOLS
    }
  ];

  for (let i = 0; i < passwordRules.length; i++) {
    const good = passwordRules[i].regex.test(password);
    if (!good) {
      return passwordRules[i].text;
    }
  }
  return undefined;
};

export const compareWithBalance = (balance?: number) => (value: string) => {
  if (balance === undefined) return undefined;
  const amountValue = Number(value);

  if (amountValue > Number(makeFloat(balance))) {
    return t('accountWalletWithdrawal.notEnoughAmount');
  }
  return undefined;
};

export const validateDateOfBirth = (
  value: string | undefined,
  values: { day: string; month: string; year: string }
) => {
  /** Функция dayjs().isValid не работает !!!
   * Функция isThereDate проверяет несуществующие даты такие как 31.02.2020 и возвращает boolean
   * Суть работы функция в том что она приводит дату к определённому формату и сравнивает полученную дату с оригиналом.
   * Как это работает: если в функцию придёт дата 31.02.2020 после форматирования и преобразований dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
   * мы получим дату 03.03.2020 т.е. она не будет равна изначальной дате и вернётся false
   * */

  const isThereDate = (date: string): boolean => {
    return dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date;
  };

  if (!values.day || !values.month || !values.year) {
    return undefined;
  }

  const dateFormat = `${values.year.padStart(2, '0')}-${values.month.padStart(
    2,
    '0'
  )}-${values.day.padStart(2, '0')}`;

  if (!isThereDate(dateFormat)) {
    return t('apiErrors.invalidFormat');
  }

  const enteredDate = dayjs()
    .date(parseInt(values.day))
    .month(parseInt(values.month) - 1)
    .year(parseInt(values.year))
    .add(18, 'years');

  if (enteredDate.valueOf() > dayjs().valueOf()) {
    return t('apiErrors.mustBeOldest');
  }

  return undefined;
};
