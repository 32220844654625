import { MESSAGE_STATUS } from 'react-easyrocket';

export type RedirectKey = 'depositSuccess' | 'depositFailure' | 'depositReturn';
type RedirectParams = {
  text: string;
  status: MESSAGE_STATUS;
};

type RedirectConfig = {
  [key in RedirectKey]: RedirectParams;
};

export const REDIRECT_CONFIG: RedirectConfig = {
  depositSuccess: {
    text: 'general.depositSuccess',
    status: MESSAGE_STATUS.SUCCESS
  },
  depositReturn: {
    text: 'general.depositReturn',
    status: MESSAGE_STATUS.INFO
  },
  depositFailure: {
    text: 'general.depositFailure',
    status: MESSAGE_STATUS.ERROR
  }
};
