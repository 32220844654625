import { useApiContext } from 'react-easyrocket';
import { useEffect } from 'react';
import { useModalContext } from './useModalContext';
import { useLocation } from 'react-router-dom';

const SHOW_DEPOSIT_SS_KEY = 'showDepositModal';

/** Отмечает что окно депозита было уже открыто. Также можно использовать, если нужно запретить автооткрывание модалки депозита раз в сессию */
export const setDepositModalAsShown = () => sessionStorage.setItem(SHOW_DEPOSIT_SS_KEY, 'false');
/** Отмечает, что окно депозита нужно открыть, когда пользователь будет залогинен. Также можно использовать если нужно разрешить модалке депозита открыться как только пользователь будет залогинен */
export const resetDepositModalShow = () => sessionStorage.setItem(SHOW_DEPOSIT_SS_KEY, 'true');
/** Функция отображающее текущее состояние запрещено или разрешено открывать модалку депозита раз в сессию */
export const getShowDeposit = () => {
  const showDepositFromSession = sessionStorage.getItem(SHOW_DEPOSIT_SS_KEY);
  return showDepositFromSession === null ? true : JSON.parse(showDepositFromSession);
};

/** Обновлен 10.04.2024 */
export function useOpenDepositOnSessionStart() {
  const { isAuthenticated } = useApiContext();
  const { openModal, openedModalName, closeModal } = useModalContext();
  const { pathname } = useLocation();

  /** Открывает окно депозита, если разрешено и юзер авторизован. Отмечает
   * в sessionStorage, что открыл окно
   */
  useEffect(() => {
    const showDepositFromSession = getShowDeposit();
    const excludeRoutes = /deposit-(success|failure|return)/.test(pathname);

    if (
      showDepositFromSession &&
      isAuthenticated &&
      !pathname.includes('pwa-instruction') &&
      !excludeRoutes
    ) {
      openModal('POPUP_DEPOSIT');
      setDepositModalAsShown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  /** Закрывает модальное окно депозита, если вдруг оно открылось
   * или было открыто когда юзер стал неавторизованным
   */
  useEffect(() => {
    if (!isAuthenticated && openedModalName === 'POPUP_DEPOSIT') {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, openedModalName]);
}
