import React, { FC, useEffect } from 'react';
import { DepositSuccessRedirect } from 'react-easyrocket';
import { useMessage } from 'src/modules/messages';
import { useModalContext } from 'src/hooks/useModalContext';
import { LOCAL_STORAGE_KEY_CURRENT_URL } from 'src/constants/storageKey';
import { REDIRECT_CONFIG, RedirectKey } from './config';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigateLng } from 'src/lib';

interface DepositRedirectWrapper {
  redirectType: RedirectKey;
}
/**
 * DepositRedirectWrapper отвечает за перенаправления пользователя на страницу с которой он перешёл в депозит,
 * так же за вывод модального окна с последними депозитами и сообщения натификации.
 * @param {redirectType} может быть 3 типов 'depositSuccess' | 'depositFailure' | 'depositReturn' зависит от того по какому
 * url дресу вернулся пользователь
 * */

export const DepositRedirectWrapper: FC<DepositRedirectWrapper> = ({ redirectType }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { openMessage } = useMessage();
  const { openModal } = useModalContext();

  useEffect(() => {
    if (openMessage) {
      openMessage({
        type: 'USER_MESSAGE',
        props: {
          text: t(REDIRECT_CONFIG[redirectType].text),
          type: REDIRECT_CONFIG[redirectType].status
        }
      });
    }

    if (!pathname.includes('history')) {
      /** Открываем модальное окно с 10 последними транзакциями */
      openModal('LATEST_TRANSACTIONS');
    }

    return () => {
      /** После редиректа чистим url в localStorage*/
      localStorage.removeItem(LOCAL_STORAGE_KEY_CURRENT_URL);
    };
  }, []);

  /** При редиректе с сервиса депозита забираем url оследней страницы с которой ушёл пользователь из localStorage*/
  const returnedUrl =
    localStorage.getItem(LOCAL_STORAGE_KEY_CURRENT_URL) || '/account/wallet/history';

  if (redirectType === 'depositFailure') {
    return <NavigateLng to={returnedUrl} />;
  }

  return <DepositSuccessRedirect redirectTo={returnedUrl} />;
};

/** Ссылки для теста, просто вставить в адресную строку после того как перешёл на страницу сервиса депозита*/
// http://localhost:5173/deposit-success/12345
// http://localhost:5173/deposit-return/
// http://localhost:5173/deposit-failure/
