import { FC } from 'react';
import { Provider } from 'react-easyrocket';
import { ProviderButton } from '../ProviderButton';
import { useTranslation } from 'react-i18next';
import { Spin } from 'src/components/svg';

interface AllProvidersResults {
  isFetching: boolean;
  providers: Provider[];
}

export const AllProviders: FC<AllProvidersResults> = ({ isFetching, providers }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="text-text-secondary font-bold text-base mt-3">{t('general.allProviders')}</h3>
      {isFetching ? (
        <div className="h-full w-full flex justify-center items-center self-center text-center">
          <Spin classNameSvg="w-[30px] h-[30px]" />
        </div>
      ) : (
        <div className="mt-2 grid grid-cols-2 gap-2 568:grid-cols-3">
          {providers?.map((provider) => <ProviderButton provider={provider} key={provider.id} />)}
        </div>
      )}
    </>
  );
};
