import classNames from 'classnames';
import { ChangeEvent, ChangeEventHandler, memo, useCallback, useEffect, useState } from 'react';

interface SwitchProps {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  onClick?: () => void;
  alwaysDisabled?: boolean;
}

const Switch = ({
  label,
  checked = false,
  disabled,
  onChange,
  name,
  onClick,
  alwaysDisabled = false
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsChecked((isChecked) => !isChecked);
      onChange?.(e);
    },
    [onChange]
  );

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className="flex items-center gap-2.5">
      <label className="relative inline-block w-8 min-w-8 h-4 switch">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={(event) => {
            onClick?.();
            !alwaysDisabled && handleChange(event);
          }}
          name={name}
        />
        <span
          className={classNames(
            'switch-slider absolute pointer top-0 left-0 right-0 bottom-0 bg-i_switch_slider_bg before:absolute before:h-3 before:w-3 before:left-[2px] before:bottom-[2px] before:bg-i_switch_slider_bg_before rounded-[34px] before:rounded-[50%] ',
            disabled && 'cursor-not-allowed'
          )}></span>
      </label>
      <p className="text-[16px] font-medium text-i_switch_text">{label}</p>
    </div>
  );
};

export default memo(Switch);
