export const CollapsedSidebarLogin = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.239 13.3185H0.847825C0.379826 13.3185 0 12.9386 0 12.4706C0 12.0026 0.379826 11.6228 0.847825 11.6228H17.239C17.707 11.6228 18.0869 12.0026 18.0869 12.4706C18.0869 12.9386 17.707 13.3185 17.239 13.3185Z"
        fill="white"
      />
      <path
        d="M12.7173 17.8401C12.5001 17.8401 12.2832 17.7577 12.118 17.5915C11.7868 17.2603 11.7868 16.7234 12.118 16.392L16.0407 12.4695L12.118 8.5469C11.7868 8.21572 11.7868 7.67857 12.118 7.34739C12.4494 7.0162 12.9863 7.0162 13.3175 7.34739L17.8392 11.8693C18.1704 12.2004 18.1704 12.7374 17.8392 13.0686L13.3175 17.5902C13.239 17.6696 13.1456 17.7326 13.0425 17.7755C12.9395 17.8184 12.8289 17.8403 12.7173 17.8401Z"
        fill="white"
      />
      <path
        d="M13.5652 24.9055C8.4205 24.9055 3.87854 21.8104 1.99186 17.0194C1.82007 16.5853 2.03468 16.0925 2.46999 15.9207C2.90407 15.7512 3.39814 15.9625 3.56993 16.4001C5.19892 20.5363 9.12266 23.2098 13.5652 23.2098C19.4863 23.2098 24.3044 18.3917 24.3044 12.4706C24.3044 6.5495 19.4863 1.73142 13.5652 1.73142C9.12261 1.73142 5.19892 4.40488 3.56993 8.54115C3.39687 8.97874 2.90407 9.19006 2.46999 9.02055C2.03468 8.8487 1.82007 8.3559 1.99186 7.92182C3.87854 3.13086 8.4205 0.0357666 13.5652 0.0357666C20.4213 0.0357666 26.0001 5.61451 26.0001 12.4706C26.0001 19.3267 20.4213 24.9055 13.5652 24.9055Z"
        fill="white"
      />
    </svg>
  );
};

export const CollapsedSidebarSignUp = () => {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8039_24169)">
        <path
          d="M13 26.5001C5.83151 26.5001 0 20.6686 0 13.5001C0 6.33163 5.83151 0.500122 13 0.500122C20.1685 0.500122 26 6.33163 26 13.5001C26 20.6686 20.1685 26.5001 13 26.5001ZM13 2.12512C6.72752 2.12512 1.625 7.22765 1.625 13.5001C1.625 19.7726 6.72752 24.8751 13 24.8751C19.2725 24.8751 24.375 19.7726 24.375 13.5001C24.375 7.22765 19.2725 2.12512 13 2.12512Z"
          fill="#FFF000"
        />
        <path
          d="M18.6875 14.3126H7.3125C6.864 14.3126 6.5 13.9486 6.5 13.5001C6.5 13.0516 6.864 12.6876 7.3125 12.6876H18.6875C19.136 12.6876 19.5 13.0516 19.5 13.5001C19.5 13.9486 19.136 14.3126 18.6875 14.3126Z"
          fill="#FFF000"
        />
        <path
          d="M13 20.0001C12.5515 20.0001 12.1875 19.6361 12.1875 19.1876V7.81262C12.1875 7.36412 12.5515 7.00012 13 7.00012C13.4485 7.00012 13.8125 7.36412 13.8125 7.81262V19.1876C13.8125 19.6361 13.4485 20.0001 13 20.0001Z"
          fill="#FFF000"
        />
      </g>
      <defs>
        <clipPath id="clip0_8039_24169">
          <rect width="26" height="26" fill="white" transform="translate(0 0.500122)" />
        </clipPath>
      </defs>
    </svg>
  );
};
