import { useTranslation } from 'react-i18next';
import bgBackToSignUp from 'src/assets/images/bgBackToSignUpModal.webp';
import characterBackToSignUp from 'src/assets/images/characterBackToSignUpModal.png';
import { Button } from '../Buttons/Button';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const BackToSignup = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();

  return (
    <ModalWrapper>
      <div
        className="w-full h-[483px] relative overflow-hidden p-5 768:p-10 jsm:w-[300px] 768:w-[690px] 768:h-[320px]"
        style={{
          backgroundImage: `url(${bgBackToSignUp})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top center'
        }}>
        <div className="absolute bottom-[-120px] left-[-30px] w-[235px] 768:w-[275px]">
          <img src={characterBackToSignUp} alt="back to sign up modal character" />
        </div>

        <div className="flex flex-col items-center font-bold text-center 768:float-right 768:w-[395px] relative h-[45%] 768:h-full">
          <p className="text-yellow text-2xl mb-2 uppercase 768:text-[30px] leading-6">
            {t('backToSignUpModal.title')}
          </p>
          <p className="text-text-secondary text-lg 768:text-[20px] leading-[120%]">
            {t('backToSignUpModal.description')}
            <span className="text-yellow text-xl ml-[5px] 768:text-[26px] leading-[120%]">54 sec</span>
          </p>
          <Button
            onClick={() => {
              closeModal();
              openModal('SIGNUP');
            }}
            mode="button-secondary"
            className="px-6 max-w-full mt-auto"
            title="Back to registration">
            {t('backToSignUpModal.button')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};
