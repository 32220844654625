import { ChangeEvent, MouseEvent } from 'react';
import { DropDownOption } from '../../types';
import { Validator } from './useForm.types';

export enum FieldType {
  Text,
  Date,
  Autocomplete,
  Checkbox,
  Dropdown,
  Radio,
  Multiselect,
  Number,
  WithoutWildcards,
  DecimalNumber
}

export type AnyFieldConfig = {
  validation?: Validator[];
  label?: string;
};

// TEXT
export type TextFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  fieldType: FieldType.Text;
};
export type TextFieldProps = {
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  placeholder?: string;
  onFocus?: () => void;
};

// DATE
export type DateFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  fieldType: FieldType.Date;
};
export type DateFieldProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  placeholder?: string;
};

// AUTOCOMPLETE
export type AutocompleteFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  options?: DropDownOption[];
  fieldType: FieldType.Autocomplete;
};
export type AutocompleteFieldProps = {
  name: string;
  value: string;
  filter: (value: string | undefined, options: DropDownOption[]) => DropDownOption[];
  onChange: (value: string | undefined, option?: DropDownOption) => void;
  onSelect: (value: string | undefined, option?: DropDownOption) => void;
  onBlur: () => void;
  placeholder?: string;
  options?: DropDownOption[];
  onFocus: () => void;
};

// CHECKBOX
export type CheckboxFieldConfig = AnyFieldConfig & {
  defaultValue?: boolean;
  fieldType: FieldType.Checkbox;
};
export type CheckboxFieldProps = {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

// DROPDOWN
export type DropdownFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  options?: DropDownOption[];
  fieldType: FieldType.Dropdown;
};
// TODO перепроверить
export type DropdownFieldProps = {
  name: string;
  label?: string;
  value: string | undefined;
  options?: DropDownOption[];
  onChange: (element?: string) => void;
};

// RADIO
export type RadioFieldConfig = AnyFieldConfig & {
  defaultValue?: boolean;
  fieldType: FieldType.Radio;
  options?: DropDownOption[];
};
// TODO перепроверить все типы
export type RadioFieldProps = {
  name: string;
  value: string;
  // defaultChecked?: boolean;
  defaultValue?: string;
  options?: DropDownOption[];
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
};

// MULTISELECT
export type MultiselectFieldConfig = AnyFieldConfig & {
  defaultValue?: string[];
  placeholder?: string;
  options?: DropDownOption[];
  fieldType: FieldType.Multiselect;
};
export type MultiselectFieldProps = {
  name: string;
  values: string[];
  onChange: (selectedValues: string[], selectedOptions: DropDownOption[]) => void;
  onBlur: () => void;
  placeholder?: string;
  options?: DropDownOption[];
};

// Number
export type NumberFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  fieldType: FieldType.Number;
};
export type NumberFieldProps = {
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  placeholder?: string;
};

export type WithoutWildcardsConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  fieldType: FieldType.WithoutWildcards;
};
export type WithoutWildcardsProps = {
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  placeholder?: string;
};

// Decimal Number
export type DecimalNumberFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  placeholder?: string;
  fieldType: FieldType.DecimalNumber;
};
export type DecimalNumberFieldProps = {
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  placeholder?: string;
};

export type FieldConfig<T extends FieldType> = T extends FieldType.Text
  ? TextFieldConfig
  : T extends FieldType.Date
    ? DateFieldConfig
    : T extends FieldType.Autocomplete
      ? AutocompleteFieldConfig
      : T extends FieldType.Checkbox
        ? CheckboxFieldConfig
        : T extends FieldType.Dropdown
          ? DropdownFieldConfig
          : T extends FieldType.Radio
            ? RadioFieldConfig
            : T extends FieldType.Multiselect
              ? MultiselectFieldConfig
              : T extends FieldType.Number
                ? NumberFieldConfig
                : T extends FieldType.WithoutWildcards
                  ? WithoutWildcardsConfig
                  : T extends FieldType.DecimalNumber
                    ? DecimalNumberFieldConfig
                    : never;

export type FieldProps<T extends FieldType> = T extends FieldType.Text
  ? TextFieldProps
  : T extends FieldType.Date
    ? DateFieldProps
    : T extends FieldType.Autocomplete
      ? AutocompleteFieldProps
      : T extends FieldType.Checkbox
        ? CheckboxFieldProps
        : T extends FieldType.Dropdown
          ? DropdownFieldProps
          : T extends FieldType.Radio
            ? RadioFieldProps
            : T extends FieldType.Multiselect
              ? MultiselectFieldProps
              : T extends FieldType.Number
                ? NumberFieldProps
                : T extends FieldType.WithoutWildcards
                  ? WithoutWildcardsProps
                  : T extends FieldType.DecimalNumber
                    ? DecimalNumberFieldProps
                    : never;

export type FieldState<T extends FieldType> = T extends FieldType.Text
  ? string
  : T extends FieldType.Date
    ? string
    : T extends FieldType.Autocomplete
      ? string | undefined
      : T extends FieldType.Checkbox
        ? boolean
        : T extends FieldType.Dropdown
          ? string | undefined
          : T extends FieldType.Radio
            ? string | undefined
            : T extends FieldType.Multiselect
              ? string[] | undefined
              : T extends FieldType.Number
                ? string
                : T extends FieldType.WithoutWildcards
                  ? string
                  : T extends FieldType.DecimalNumber
                    ? string
                    : never;
