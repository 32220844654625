import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from './LanguagesAdjustable';
import { SupportedLanguages } from './types';

import translationEn from './en/translationEn.json';
import translationEs from './es/translationEs.json';
import translationFr from './fr/translationFr.json';
import translationEl from './el/translationEl.json';
import translationDe from './de/translationDe.json';
import translationPt from './pt/translationPt.json';
import translationSl from './sl/translationSl.json';
import translationAu from './au/translationAu.json';
import translationRo from './ro/translationRo.json';

interface TranslationJson {
  [key: string]: string | TranslationJson;
}

const resources: Record<keyof SupportedLanguages, { translation: TranslationJson }> = {
  en: {
    translation: translationEn
  },
  es: {
    translation: translationEs
  },
  sl: {
    translation: translationSl
  },
  fr: {
    translation: translationFr
  },
  el: {
    translation: translationEl
  },
  de: {
    translation: translationDe
  },
  pt: {
    translation: translationPt
  },
  au: {
    translation: translationAu
  },
  ro: {
    translation: translationRo
  },
  gb: {
    translation: translationEn
  }
};

const LANGUAGE_LOCAL_STORAGE_KEY = 'language';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      convertDetectedLanguage: (lang) => {
        const language = lang.split('-')[0];

        if (!Object.keys(SUPPORTED_LANGUAGES).includes(language)) {
          return SUPPORTED_LANGUAGES.en;
        }
        return lang.split('-')[0];
      },
      lookupLocalStorage: LANGUAGE_LOCAL_STORAGE_KEY,
      lookupFromPathIndex: 0,
      order: ['path', 'localStorage', 'navigator']
    },
    resources,
    fallbackLng: SUPPORTED_LANGUAGES.en,
    supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
