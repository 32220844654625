import { FC, PropsWithChildren } from 'react';
import { Table } from 'src/components/Table';
import { bonusHeader } from 'src/constants/index';

export const TransactionTable: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="overflow-auto">
      <div className="w-[800px] 1280:w-full">
        <Table columnHeaders={bonusHeader} tableRows={children} />
      </div>
    </div>
  );
};
