import { useEffect, useLayoutEffect } from 'react';
import { useSportContext } from 'src/context/SportProvider';

export const useSoftSwissTheme = () => {
  const { setIsSportPage } = useSportContext();

  useLayoutEffect(() => {
    setIsSportPage(true);

    return () => {
      setIsSportPage(false);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('sport-theme');

    return () => {
      document.body.classList.remove('sport-theme');
    };
  }, []);
};
