import { ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';

interface ChangeTabButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isActive: boolean;
}

export const ChangeTabButton: FC<PropsWithChildren<ChangeTabButtonProps>> = ({
  children,
  isActive,
  className,
  ...props
}) => {
  return (
    <button
      className={twJoin(
        'text-[18px] w-full text-center pt-[7px] pb-[9px] font-bold uppercase leading-[110%] border-b-2',
        isActive ? 'border-b-yellow text-yellow' : 'border-b-[transparent] text-text-secondary',
        className
      )}
      {...props}>
      {children}
    </button>
  );
};
