import { useEffect } from 'react';
import {
  useGetActiveUserTournaments,
  useGetLocalTournaments,
  useGetProviderTournaments
} from 'react-easyrocket';
import { showNewLocalTournament } from 'src/utils/showNewLocalTournament';
import { useModalContext } from './useModalContext';

export const useProviderTournamentNotification = () => {
  const { tournaments, isFetched: isTournamentsFetched } = useGetProviderTournaments();
  const { tournaments: localTournaments, isFetched: isLocalTournamentsFetched } =
    useGetLocalTournaments();
  const { data: activeLocalUserTournaments } = useGetActiveUserTournaments();
  const { openModal } = useModalContext();

  useEffect(() => {
    if (!isTournamentsFetched || !isLocalTournamentsFetched) return;

    const shouldShowNewTournament = showNewLocalTournament(
      localTournaments?.active,
      activeLocalUserTournaments,
      openModal
    );

    if (tournaments && !shouldShowNewTournament) {
      const tournamentsFromStorageString = localStorage.getItem('tournamentsObject');
      const obj = Object.fromEntries(tournaments.map((val) => [val.code, val.code]));
      if (!tournamentsFromStorageString) {
        localStorage.setItem('tournamentsObject', JSON.stringify(obj));
      } else {
        const tournamentsFromStorage = JSON.parse(tournamentsFromStorageString);
        for (let i = 0; i < tournaments.length; i++) {
          if (!(tournaments[i].code in tournamentsFromStorage)) {
            localStorage.setItem('tournamentsObject', JSON.stringify(obj));
            openModal('NEW_TOURNAMENT', {
              props: {
                ...tournaments.sort((a, b) => b.createdAt - a.createdAt)[0]
              }
            });
            break;
          }
        }
      }
    }
  }, [
    tournaments,
    localTournaments,
    isLocalTournamentsFetched,
    isTournamentsFetched,
    openModal,
    activeLocalUserTournaments
  ]);

  return tournaments;
};
