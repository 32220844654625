export const getEmptyFields = (
  fields: Record<string, string> | undefined
): { [key: string]: string } => {
  return Object.keys(fields || {}).reduce(
    (acc, key) => {
      if (fields && !fields[key]) {
        acc[key] = fields[key];
      }
      return acc;
    },
    {} as { [key: string]: string }
  );
};
