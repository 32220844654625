import { FC } from 'react';
import { LocalTournament, makeFloat } from 'react-easyrocket';
import { getCurrencyIcon } from 'src/utils';
import { Skeletons } from '../Skeletons';


interface PrizeTournamentProps {
  tournament: LocalTournament;
  currency: string | undefined;
  isLoading?: boolean;
}

export const PrizeTournament: FC<PrizeTournamentProps> = ({ tournament, currency, isLoading }) => {
  if (isLoading) {
    return (
      <Skeletons
        className="bg-bg-sceleton 768:min-w-[60vw] min-w-[45vw] w-full mb-1.5 768:h-14 h-8"
        count={2}
      />
    );
  }

  const hasPrizePool =
    !Array.isArray(tournament.fullPrizePool?.amount) &&
    tournament.fullPrizePool?.amount &&
    typeof tournament.fullPrizePool?.amount[currency || 'EUR'] === 'number' &&
    tournament.fullPrizePool?.amount[currency || 'EUR'] !== 0;
  const hasFreespinPool = Boolean(tournament.freespinPool);
  const hasPointsPool = Boolean(tournament.pointsPool);

  const PRIZE_CLASSNAMES = 'whitespace-nowrap leading-[103%]';

  const prizePool = hasPrizePool && (
    <span className={PRIZE_CLASSNAMES}>
      {`${getCurrencyIcon(currency)} ${makeFloat(tournament.fullPrizePool.amount[currency || 'EUR'])}`}
    </span>
  );
  const freeSpinPool = hasFreespinPool && (
    <span className={PRIZE_CLASSNAMES}>
      {hasPrizePool ? '\u00A0+ ' : ''}
      {tournament.freespinPool} FS
    </span>
  );

  const pointsPool = hasPointsPool && (
    <span className={PRIZE_CLASSNAMES}>
      {hasPrizePool || hasFreespinPool ? '\u00A0+ ' : ''}
      {tournament.pointsPool} E.P.
    </span>
  );

  return (
    <>
      {prizePool}
      {freeSpinPool}
      {pointsPool}
    </>
  );
};
