import { useWindowSize } from 'react-easyrocket';

import { FC, PropsWithChildren } from 'react';
import { useModalContext } from 'src/hooks/useModalContext';

import { DepositClose } from 'src/components/svg';
import ModalWrapper from 'src/components/ModalWrapper';
import FullScreenModalWrapper from 'src/components/FullScreenModalWrapper';

export const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isMobileLayout } = useWindowSize();
  const { closeModal } = useModalContext();

  if (isMobileLayout) {
    return (
      <FullScreenModalWrapper className="bg-gradient">
        <DepositClose
          className="absolute top-0 right-0 cursor-pointer z-[20] box-content pt-4 px-3"
          onClick={closeModal}
        />
        {children}
      </FullScreenModalWrapper>
    );
  }
  return (
    <ModalWrapper containerClassName="bg-gradient max-w-[900px]" className="min-w-[750px]">
      {children}
    </ModalWrapper>
  );
};
