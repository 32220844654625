import { useCurrency, useGetProviders } from 'react-easyrocket';

const FIVE_MINUTES_MS = 5 * 60 * 60 * 1000;

export function useGetAllProvidersCached({ enabled }: { enabled?: boolean } = {}) {
  const { currencyName } = useCurrency();

  return useGetProviders(
    {
      currency: currencyName,
      per_page: 9000
    },
    { enabled: enabled, cacheTime: FIVE_MINUTES_MS, staleTime: FIVE_MINUTES_MS }
  );
}
