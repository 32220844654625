import { FC } from 'react';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

interface CountdownItemCellProps {
  date: string;
  value: string;
}

export const CountdownItemCell: FC<CountdownItemCellProps> = ({ date, value }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-col justify-center">
        <p
          className={twJoin(
            'text-button-primary-inactive tracking-[0.03em] text-[20px] leading-none font-medium 768:text-[27px] w-[42px] 768:w-[55px] text-center pr-3 768:pr-[15px]',
            date !== 'seconds' && 'border-r-2'
          )}>
          {value}
        </p>
        <p className="text-button-primary-inactive text-[6px] tracking-[0.03em] font-medium 768:text-[8px] pr-[15px] text-center">
          {t(`general.${date}`)}
        </p>
      </div>
    </div>
  );
};
