export const JackpotMiniIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12167_264774)">
        <path
          d="M23.9191 17.3503C23.7919 17.0638 23.6118 16.8201 23.3783 16.6193C23.1448 16.4186 22.86 16.2506 22.5242 16.1154C22.1881 15.9802 21.8114 15.8554 21.3936 15.7406L21.0003 15.6422V13.971C21.2296 14.0038 21.4569 14.0591 21.6823 14.1369C21.9075 14.2148 22.1124 14.2966 22.2968 14.3826C22.4811 14.4687 22.6326 14.5486 22.7514 14.6222C22.8701 14.696 22.942 14.7493 22.9665 14.782L23.8513 13.1107C23.5072 12.9141 23.1016 12.7217 22.6347 12.5331C22.1677 12.3447 21.6679 12.2258 21.1354 12.1767V10.9355H20.2876V12.1767C19.8944 12.2178 19.5276 12.3058 19.1877 12.4409C18.8477 12.5761 18.5528 12.7625 18.303 13C18.053 13.2377 17.8544 13.5223 17.707 13.8541C17.5595 14.1859 17.4858 14.565 17.4858 14.9908C17.4858 15.3186 17.5329 15.6053 17.6271 15.8511C17.7212 16.0968 17.8647 16.314 18.0573 16.5023C18.2497 16.6908 18.4936 16.8568 18.7885 17C19.0834 17.1435 19.4315 17.2766 19.833 17.3995L20.4229 17.5715V19.2059C20.1279 19.165 19.841 19.0974 19.5626 19.0031C19.284 18.909 19.0341 18.8107 18.813 18.7082C18.5918 18.6059 18.4094 18.5075 18.2662 18.4133C18.1227 18.3192 18.0346 18.2516 18.002 18.2105L17.1172 19.9678C17.5596 20.2627 18.0573 20.5004 18.6103 20.6806C19.1633 20.8609 19.7225 20.9755 20.2877 21.0247V22.4625H21.1356V21.0369C21.5289 21.0205 21.9037 20.959 22.2601 20.8527C22.6165 20.7463 22.9317 20.5885 23.2063 20.3795C23.4808 20.1706 23.6998 19.9023 23.8638 19.5745C24.0276 19.247 24.1096 18.8495 24.1096 18.3825C24.1096 17.9812 24.046 17.6371 23.9191 17.3503ZM19.7776 15.1569C19.6342 15.0382 19.5625 14.8764 19.5625 14.6715C19.5625 14.2456 19.8492 14.0079 20.4228 13.9588V15.4703C20.1359 15.3802 19.9209 15.2758 19.7776 15.1569ZM21.0004 19.2307V17.756C21.287 17.8543 21.5165 17.9629 21.6886 18.0816C21.8606 18.2004 21.9467 18.3664 21.9467 18.5793C21.9467 18.9891 21.6311 19.2061 21.0004 19.2307Z"
          fill="white"
        />
        <path
          d="M0.625 5.9209V27.0122H40.6219V5.9209H0.625ZM2.96857 8.26448H6.88475C6.43799 10.2043 4.90842 11.7338 2.96857 12.1804V8.26448ZM2.96857 24.6686V20.7524C4.90858 21.199 6.43815 22.7286 6.88483 24.6686H2.96857ZM31.9752 24.6686H9.27175C8.76656 21.4332 6.20394 18.8706 2.96857 18.3654V14.5674C6.20379 14.0622 8.76632 11.4997 9.27168 8.26456H31.9752C32.4805 11.4997 35.043 14.0623 38.2783 14.5674V18.3654C35.043 18.8706 32.4804 21.4332 31.9752 24.6686ZM38.2783 24.6686H34.3621C34.8088 22.7286 36.3383 21.199 38.2783 20.7524V24.6686ZM38.2783 12.1804C36.3385 11.7337 34.8089 10.2043 34.3622 8.26448H38.2783V12.1804Z"
          fill="white"
        />
        <path d="M40.6219 28.9229H0.625V31.2664H40.6219V28.9229Z" fill="white" />
        <path d="M40.6219 33.0713H0.625V35.4149H40.6219V33.0713Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_12167_264774">
          <rect
            width="39.9969"
            height="39.9972"
            fill="white"
            transform="translate(0.65625 0.667969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
