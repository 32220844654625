import { FC } from 'react';
import { PrizeTournament } from 'src/components/PrizeTournament';
import { useTranslation } from 'react-i18next';
import { LocalTournament } from 'react-easyrocket';
import { Skeletons } from 'src/components/Skeletons';

interface TournamentTitleProps {
  tournament: LocalTournament;
  currencyName: string;
  isLoading: boolean;
}

export const TournamentTitle: FC<TournamentTitleProps> = ({
  tournament,
  currencyName,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-[26px] leading-[28px] tracking-[0.03em] font-bold text-yellow 640:text-[38px] 640:leading-[41px] mb-[30px] text-center">
        {!isLoading ? (
          tournament?.name
        ) : (
          <Skeletons className="bg-bg-sceleton 768:min-w-[60vw] min-w-[45vw] w-full h-10" />
        )}
      </div>
      <div className="text-[16px] leading-[17px] font-medium text-text-secondary first-letter:uppercase text-center pb-2.5">
        {!isLoading ? (
          t('general.prizePool')
        ) : (
          <Skeletons className="bg-bg-sceleton 768:min-w-[60vw] min-w-[45vw] w-full h-4 mb-2" />
        )}
      </div>

      <div className="text-[38px] leading-[41px] tracking-[0.03em] font-bold text-yellow 640:text-[60px] 768:leading-[71px] mb-5 text-center w-[90%] md:w-full flex flex-wrap justify-center">
        <PrizeTournament tournament={tournament} currency={currencyName} isLoading={isLoading} />
      </div>
    </>
  );
};
