import { useEffect } from 'react';
import { useLanguageChange } from 'src/locale/hook/useLanguageChange';
import { useLocation, useParams } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from '../../locale/LanguagesAdjustable';

/**
 * Эффект который отлавливает ситуацию, при которой юзер переключился на
 * новый язык, а затем нажал кнопку назад в браузере.
 */

export const useLanguageSync = () => {
  const location = useLocation();
  const { lang } = useParams();
  const { handleChangeLanguage } = useLanguageChange();

  useEffect(() => {
    handleChangeLanguage(lang || SUPPORTED_LANGUAGES.en);
  }, [location.pathname]);
};
