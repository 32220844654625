import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropDownOption } from '../types';

type DropdownConfig = {
  value?: string | undefined;
  onChange?: (value: string, option: DropDownOption) => void;
  options?: DropDownOption[];
};

export const useDropdown = ({ value, onChange, options = [] }: DropdownConfig) => {
  const [showList, setShowList] = useState(false);
  const [option, setOption] = useState<DropDownOption | undefined>(() =>
    getOptionById(value, options)
  );

  const toggle = () => {
    setShowList((showList) => !showList);
  };
  const close = () => setShowList(false);
  const open = () => setShowList(true);

  const onSelect = useCallback(
    (value: string, option: DropDownOption) => {
      setOption(option);
      onChange?.(value, option);
      setShowList(false);
    },
    [onChange]
  );
  useEffect(() => {
    setOption(getOptionById(value, options));
  }, [options, value]);

  return {
    selectedOption: option,
    value: option?.id,
    selectedOptionName: option?.name,
    onSelect,
    showList,
    close,
    open,
    toggle
  };
};

type MultiselectConfig = {
  values?: string[];
  onChange?: (selectedValues: string[], selectedOptions: DropDownOption[]) => void;
  options?: DropDownOption[];
  onBlur?: () => void;
};

export const useMultiselect = ({ values, onChange, options = [], onBlur }: MultiselectConfig) => {
  const [showList, setShowList] = useState(false);
  const [selection, setSelection] = useState<Record<string, DropDownOption>>(() =>
    setDefaulMultiselectState(values, options)
  );

  useEffect(() => {
    setSelection(setDefaulMultiselectState(values, options));
  }, [options, values]);

  const isSelected = useCallback(
    (value: string) => {
      return value in selection;
    },
    [selection]
  );

  const onSelect = useCallback(
    (value: string, option: DropDownOption) => {
      const nextSelection = { ...selection };
      if (isSelected(value)) {
        delete nextSelection[value];
      } else {
        nextSelection[value] = option;
      }
      setSelection(nextSelection);
      const selectedArray = Object.values(nextSelection);
      const selectedValues = selectedArray.map((option) => option.id);
      onChange?.(selectedValues, selectedArray);
    },
    [isSelected, onChange, selection]
  );

  const toggle = () => {
    setShowList((showList) => !showList);
  };
  const close = () => {
    setShowList(false);
    onBlur?.();
  };
  const open = () => setShowList(true);

  const selectedArray = useMemo(() => {
    return Object.values(selection);
  }, [selection]);

  return {
    showList,
    toggle,
    open,
    close,
    selection,
    onSelect,
    selectedArray,
    isSelected
  };
};

function setDefaulMultiselectState(
  values: string[] | undefined,
  options: DropDownOption[]
): Record<string, DropDownOption> {
  if (!values) return {};
  return values?.reduce<Record<string, DropDownOption>>((result, value) => {
    const option = getOptionById(value, options);
    if (option) {
      return { ...result, [option.id]: option };
    }
    return result;
  }, {});
}

function getOptionById(
  id: string | undefined,
  options: DropDownOption[]
): DropDownOption | undefined {
  return options.find((option) => {
    return id === option.id;
  });
}
