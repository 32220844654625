import { FC } from 'react';
import { makeFloat } from 'react-easyrocket';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TournamentInfoItem } from './components/TournamentInfoItem';

interface TournamentInfoProps {
  currencyIcon: string;
  currencyName: string;
  minBet: { [currency: string]: number };
  placesLength: number;
  dateStart: number;
  dateEnd: number;
  isLoading: boolean;
}

export const TournamentInfo: FC<TournamentInfoProps> = ({
  currencyIcon,
  currencyName,
  minBet,
  placesLength,
  dateStart,
  dateEnd,
  isLoading
}) => {
  const { t } = useTranslation();

  const minBetCurrency = `${currencyIcon} ${makeFloat(minBet?.[currencyName])}`;
  const dateTournament = `${dayjs.unix(dateStart).format('DD.MM.YY')}-${dayjs.unix(dateEnd).format('DD.MM.YY')}`;

  return (
    <div className="flex flex-col gap-0.5 py-5 text-text-secondary text-[14px] font-medium leading-[18px] w-full">
      <TournamentInfoItem
        isLoading={isLoading}
        title={t('tournaments.minBet')}
        className="rounded-t-lg">
        {minBetCurrency}
      </TournamentInfoItem>
      <TournamentInfoItem isLoading={isLoading} title={t('tournaments.numberWinners')}>
        {placesLength}
      </TournamentInfoItem>
      <TournamentInfoItem
        isLoading={isLoading}
        title={t('tournaments.duration')}
        className="rounded-b-lg">
        {dateTournament}
      </TournamentInfoItem>
    </div>
  );
};
