import { GAME_COLORS, GAME_NAME, GAME_ORIGIN } from '../../../config';

export const postShowRequest = (gameFrame: HTMLIFrameElement) => {
  const sessionId = localStorage.getItem('accessToken') || '';

  const data = JSON.stringify({
    sessionId,
    preferences: GAME_COLORS
  });

  gameFrame.contentWindow?.postMessage(
    {
      type: 'SHOW_REQUEST',
      gameName: GAME_NAME,
      data
    },
    GAME_ORIGIN
  );
};
