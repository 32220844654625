export const LiveChatIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.8354 4H10.483C9.2919 4 8.31839 4.69823 8.31839 5.5583V13.209C8.31839 14.0686 9.2919 14.7715 10.483 14.7715H16.157C16.2166 14.7709 16.2758 14.7818 16.331 14.8036C16.3861 14.8253 16.4363 14.8574 16.4784 14.898C16.5206 14.9387 16.5539 14.987 16.5765 15.0402C16.599 15.0934 16.6103 15.1504 16.6097 15.2078V18.0314L20.1147 14.885C20.1992 14.8117 20.3089 14.7712 20.4226 14.7715H21.8354C23.0265 14.7715 24 14.0686 24 13.209V5.5583C24 4.69823 23.0265 4 21.8354 4ZM11.597 10.9436C11.2616 10.9432 10.9338 10.8469 10.6552 10.667C10.3765 10.487 10.1595 10.2315 10.0316 9.93265C9.90366 9.63382 9.87055 9.30514 9.93644 8.98817C10.0023 8.6712 10.1643 8.38018 10.4018 8.15191C10.6393 7.92365 10.9417 7.7684 11.2707 7.70579C11.5998 7.64318 11.9407 7.67603 12.2504 7.80018C12.5601 7.92434 12.8247 8.13421 13.0106 8.40327C13.1965 8.67233 13.2955 8.98849 13.295 9.31176C13.2938 9.74501 13.1144 10.1601 12.7961 10.4661C12.4777 10.772 12.0465 10.9437 11.597 10.9436ZM16.157 10.9436C15.8216 10.9432 15.4939 10.8469 15.2152 10.667C14.9366 10.487 14.7196 10.2315 14.5916 9.93265C14.4637 9.63382 14.4306 9.30514 14.4965 8.98817C14.5624 8.6712 14.7243 8.38018 14.9618 8.15191C15.1993 7.92365 15.5017 7.7684 15.8308 7.70579C16.1598 7.64318 16.5008 7.67603 16.8105 7.80018C17.1202 7.92434 17.3847 8.13421 17.5706 8.40327C17.7565 8.67233 17.8555 8.98849 17.855 9.31176C17.8541 9.74508 17.6747 10.1603 17.3563 10.4663C17.038 10.7723 16.6066 10.944 16.157 10.9436ZM20.7214 10.9436C20.386 10.9431 20.0583 10.8468 19.7797 10.6668C19.5011 10.4868 19.2842 10.2312 19.1563 9.93236C19.0285 9.63352 18.9954 9.30485 19.0613 8.98791C19.1273 8.67097 19.2893 8.37999 19.5268 8.15177C19.7643 7.92355 20.0667 7.76834 20.3958 7.70576C20.7248 7.64319 21.0657 7.67606 21.3754 7.80023C21.6851 7.92439 21.9496 8.13427 22.1355 8.40332C22.3214 8.67237 22.4203 8.9885 22.4198 9.31176C22.4186 9.74479 22.2394 10.1597 21.9214 10.4656C21.6033 10.7715 21.1725 10.9434 20.7231 10.9436H20.7214Z"
        fill="white"
      />
      <path
        d="M7.41293 13.209V7.47433H1.9156C0.860102 7.47433 0 8.08933 0 8.8401V15.7446C0 16.5013 0.860102 17.1108 1.9156 17.1108H3.18962C3.30395 17.1114 3.41385 17.1535 3.49755 17.2285L6.58598 20V17.5488C6.58632 17.4332 6.63413 17.3224 6.71896 17.2407C6.80379 17.1589 6.91874 17.1128 7.03871 17.1125H12.1583C13.1955 17.1125 14.0739 16.4883 14.0739 15.7463V15.6458H10.483C8.78944 15.6442 7.41293 14.5533 7.41293 13.209Z"
        fill="white"
      />
      <path
        d="M11.597 8.54795C11.4409 8.54836 11.2884 8.59334 11.1587 8.67719C11.0291 8.76105 10.9281 8.88003 10.8686 9.01913C10.8091 9.15823 10.7936 9.31121 10.8241 9.45877C10.8547 9.60633 10.9299 9.74186 11.0403 9.84826C11.1507 9.95466 11.2914 10.0272 11.4445 10.0566C11.5976 10.0861 11.7563 10.0712 11.9006 10.0138C12.0449 9.95638 12.1684 9.85908 12.2554 9.73414C12.3424 9.6092 12.3891 9.46223 12.3895 9.31176C12.3894 9.10922 12.3058 8.915 12.1572 8.77179C12.0086 8.62857 11.8071 8.54806 11.597 8.54795Z"
        fill="white"
      />
      <path
        d="M16.157 8.54795C16.0009 8.54836 15.8484 8.59334 15.7188 8.67719C15.5891 8.76105 15.4882 8.88003 15.4286 9.01913C15.3691 9.15823 15.3536 9.31121 15.3842 9.45877C15.4147 9.60633 15.49 9.74186 15.6004 9.84826C15.7108 9.95466 15.8514 10.0272 16.0045 10.0566C16.1576 10.0861 16.3163 10.0712 16.4606 10.0138C16.605 9.95638 16.7284 9.85908 16.8154 9.73414C16.9024 9.6092 16.9491 9.46223 16.9495 9.31175C16.9499 9.21134 16.9297 9.11184 16.89 9.019C16.8503 8.92615 16.792 8.8418 16.7183 8.77079C16.6446 8.69979 16.5571 8.64354 16.4608 8.6053C16.3644 8.56705 16.2612 8.54756 16.157 8.54795Z"
        fill="white"
      />
      <path
        d="M20.7231 8.54795C20.567 8.54837 20.4145 8.59334 20.2849 8.6772C20.1553 8.76105 20.0543 8.88004 19.9948 9.01913C19.9352 9.15823 19.9197 9.31121 19.9503 9.45877C19.9809 9.60633 20.0561 9.74186 20.1665 9.84826C20.2769 9.95466 20.4175 10.0272 20.5706 10.0566C20.7237 10.0861 20.8824 10.0712 21.0268 10.0138C21.1711 9.95639 21.2945 9.85908 21.3815 9.73415C21.4686 9.60921 21.5152 9.46223 21.5156 9.31176C21.5159 9.21138 21.4956 9.11193 21.4559 9.01913C21.4162 8.92634 21.3578 8.84203 21.2842 8.77105C21.2105 8.70007 21.123 8.64381 21.0268 8.60553C20.9305 8.56724 20.8273 8.54767 20.7231 8.54795Z"
        fill="white"
      />
    </svg>
  );
};
