import { useTranslation } from 'react-i18next';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

export const useNavigateLng = () => {
  const { i18n } = useTranslation();
  const fallbackLanguage = Array.isArray(i18n.options.fallbackLng)
    ? i18n.options.fallbackLng[0]
    : i18n.options.fallbackLng;

  const defaultLanguage = fallbackLanguage || 'en';

  const navigate = useNavigate();

  return (to: To, options?: NavigateOptions) => {
    if (typeof to === 'string' && to[0] !== '/') {
      navigate(to, options);
      return;
    }

    if (typeof to === 'object') {
      if (to.pathname) {
        const linkWithLanguage =
          i18n.language !== defaultLanguage ? `/${i18n.language}${to.pathname}` : to.pathname;

        navigate({ ...to, pathname: linkWithLanguage }, options);
        return;
      }

      navigate(to, options);
      return;
    }

    const linkWithLanguage = i18n.language !== defaultLanguage ? `/${i18n.language}${to}` : to;

    navigate(linkWithLanguage, options);
  };
};
