import { BonusTransaction, Jackpot, Transaction } from 'react-easyrocket';
import { GetDataFromLS } from './types';
import dayjs from 'dayjs';

// Поиск поля probability с наибольшим значением среди объектов Jackpots в массиве
const findMaxProbabilityFromJackpotsArray = (jackpots: Jackpot[]): number => {
  if (!jackpots) return 0;

  let chance = 0;

  try {
    chance = jackpots?.reduce((currentProbability, jackpot) => {
      const jackpotProbability = jackpot.jackpotInfo?.probability;
      if (!jackpotProbability) return currentProbability;

      return jackpotProbability > currentProbability ? jackpotProbability : currentProbability;
    }, 0);
  } catch (error) {
    console.error(error);
  }

  return chance;
};

// Получение интервала, зависящего от процента выпадения джектопа с обработкой исключений
const getIntervalFromProbabilityWithFormula = (probability: number) => {
  // обработка исключений (0 процентов либо 91+ процентов)
  if (probability === 0) {
    return 30000;
  }
  if (probability > 90) {
    return 5000;
  }
  // Вычесление общего случая по формуле ниже
  return -281 * probability + 30281;
};

// Функция, объединяющая 2 предыдущие и возвращающая интервал для перезапроса транзакций
export const getRefetchIntervalForTransactionsFromJackpotsProbability = (jackpots: Jackpot[]) => {
  return getIntervalFromProbabilityWithFormula(findMaxProbabilityFromJackpotsArray(jackpots));
};

// НАЧАЛО ОБРАБОТКИ ДЖЕКПОТА ИЗ ТРАНЗАКЦИЙ_____________________________________________________________________

// Функция, позволяющая достать любые данные из LocalStorage с типизацией (Обязательно указать generic)
const getDataFromLS: GetDataFromLS = (key, defaultValue) => {
  const completedNoWagerJackpotsJSON = localStorage.getItem(key);
  return completedNoWagerJackpotsJSON ? JSON.parse(completedNoWagerJackpotsJSON) : defaultValue;
};

// Функция, проверяющая, был ли уже показан джекпот пришедший из обычных транзакций (достает массив id уже показанных джекпотов из localStorage и ищет переданный id среди них)
const checkIfJackpotFromDefaultTransactionsWasAlreadyShown = (
  incomingJackpotId: number
): boolean => {
  return getDataFromLS<number[]>('completedNoWagerJackpots', []).includes(incomingJackpotId);
};

// Функция, проверяющая, был ли джекпот пришедший из обычных транзакций завершен текущей датой (сегодня)
const checkIfJackpotFromDefaultTransactionsAppearedToday = (
  incomingJackpotCompletedDate: number
) => {
  return dayjs(incomingJackpotCompletedDate * 1000).isSame(dayjs(), 'day');
};

// функция, добавляющая новый id джекпота к массиву id уже показанных в localStorage
const pushNewJackpotIdToShownInLS = (incomingJackpotId: number) => {
  localStorage.setItem(
    'completedNoWagerJackpots',
    JSON.stringify([incomingJackpotId, ...getDataFromLS<number[]>('completedNoWagerJackpots', [])])
  );
};

// Функция, которая определяет, стоит ли показывать модалку джекпота, полученную из транзакций (принимает массив джекпотов из транзаций и функцию открытия модалки)
const shouldShowJackpotModalFromTransactions = (
  jackpots: Transaction[] | undefined,
  showJackpotsModal: (jackpot: Transaction) => void
) => {
  if (!jackpots?.length) return; // Проверка наличия массива джекпотов и его длины

  const newestJackpot = jackpots[0]; // Получение новейшего джекпота из массива
  // Проверка на то, был показан джекпот до этого или нет
  const wasJackpotAlreadyShown = checkIfJackpotFromDefaultTransactionsWasAlreadyShown(
    newestJackpot.id
  );

  // Проверка на то, разыгрался джекпот текущей датой (сегодня) или нет
  const wasJackpotCompletedToday = checkIfJackpotFromDefaultTransactionsAppearedToday(
    newestJackpot.dateComplete
  );

  // Вызов колбэка открытия модалки при выполнении вышенаписанных условий
  if (!wasJackpotAlreadyShown && wasJackpotCompletedToday) {
    showJackpotsModal(newestJackpot);
  }
};

// Функция, которая принимает колбэк открытия модального окна с джекпотом и возвращает функцию, которая показывает модалку и отмечает ее как показанную в localStorage (Принимает кол)
const showJackpotModalFromTransactions =
  (callback: (jackpot: Transaction) => void) => (jackpot: Transaction) => {
    pushNewJackpotIdToShownInLS(jackpot.id);
    callback(jackpot);
  };

// Фунция-агрегатор двух прошлых
export const openJackpotModalFromTransactions = (
  jackpots: Transaction[] | undefined,
  callback: (jackpot: Transaction) => void
) => {
  shouldShowJackpotModalFromTransactions(jackpots, showJackpotModalFromTransactions(callback));
};

// КОНЕЦ ОБРАБОТКИ ДЖЕКПОТА ИЗ ТРАНЗАКЦИЙ_____________________________________________________________________

// НАЧАЛО ОБРАБОТКИ ДЖЕКПОТА ИЗ БОНУСНЫХ ТРАНЗАКЦИЙ_____________________________________________________________________

// Функция, которая определяет, стоит ли показывать модалку джекпота, полученную из бонусных транзакций (принимает массив джекпотов из бонусных транзаций и функцию открытия модалки)
const shouldShowJackpotModalFromBonusesTransactions = (
  arrayBonusesTansactions: BonusTransaction[],
  callback: (jackpot: BonusTransaction) => void
) => {
  // Проверка наличия транзакций и их длинны
  if (!(arrayBonusesTansactions.length > 0)) return;

  // типа транзакции
  const newestJackpot = arrayBonusesTansactions[0];
  if (newestJackpot.type !== 16) return;

  // Выполнение колбэка
  callback(newestJackpot);
};

// Фунция для открытия модалки джекпота из бонусных транзакций
export const openJackpotModalFromBonusesTransactions = (
  arrayBonusesTansactions: BonusTransaction[],
  callback: (jackpot: BonusTransaction) => void
) => {
  shouldShowJackpotModalFromBonusesTransactions(arrayBonusesTansactions, callback);
};
