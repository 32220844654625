import { useEffect, useMemo } from 'react';
import {
  getRefetchIntervalForTransactionsFromJackpotsProbability,
  openJackpotModalFromBonusesTransactions,
  openJackpotModalFromTransactions
} from './jackpotModalUtils';
import { useGetBonusesTransactions, useGetJackpots, useGetTransactions } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { FIFTEEN_SECONDS } from 'src/constants/time';

export const useJackpotWin = () => {
  const { openModal } = useModalContext();
  const { jackpots } = useGetJackpots({}, { refetchInterval: FIFTEEN_SECONDS });

  const bonusesRefetchInterval = useMemo(
    () => getRefetchIntervalForTransactionsFromJackpotsProbability(jackpots),
    [jackpots]
  );
  const { arrayBonusesTansactions } = useGetBonusesTransactions(
    {
      bonus_type: 'All',
      status: ['Pending'],
      type: ['JackpotWin']
    },
    {
      isNeedPages: false,
      refetchInterval: bonusesRefetchInterval
    }
  );
  const { transactions } = useGetTransactions(
    {
      type: ['Jackpot']
    },
    { refetchInterval: bonusesRefetchInterval, target: 'type jackpot' }
  );

  useEffect(() => {
    openJackpotModalFromTransactions(transactions?.transactions, (jackpot) => {
      openModal('JACKPOT_WIN', {
        props: { jackpot }
      });
    });
    openJackpotModalFromBonusesTransactions(arrayBonusesTansactions, (jackpot) => {
      openModal('JACKPOT_WIN', {
        props: { jackpot }
      });
    });
  }, [arrayBonusesTansactions, transactions, openModal]);
};
