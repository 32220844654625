import { ButtonHTMLAttributes, FC } from 'react';
import { useBoolean } from 'react-easyrocket';
import { UpIcon } from 'src/components/svg/ScrollToTopButton';
import { useScrollButton } from 'src/hooks/useScrollButton';

interface ScrollToTopButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  visible?: boolean;
}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({ ...rest }) => {
  const [buttonVisible, showButton, hideButton] = useBoolean(false);
  useScrollButton(hideButton, showButton, location.pathname);
  if (!buttonVisible) return null;

  return (
    <div className="flex justuify-center items-center fixed lg:bottom-5 bottom-[100px] left-5 z-[1] bg-[#000] rounded-md p-2 opacity-30 hover:opacity-50 duration-300">
      <button
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        {...rest}>
        <UpIcon />
      </button>
    </div>
  );
};
