import { useEffect } from 'react';
import { useApiContext, useGetFreeSpins } from 'react-easyrocket';
import { useLocation } from 'react-router-dom';
import { useFreeSpins } from 'src/hooks/useFreeSpins';
import { useModalContext } from 'src/hooks/useModalContext';

export function FreeSpinsHandler() {
  const { openModal } = useModalContext();
  const { saveFreeSpins, freeSpinsChanged } = useFreeSpins();
  const { isAuthenticated } = useApiContext();
  const location = useLocation();

  const { refetch } = useGetFreeSpins({
    onSuccess: (data) => {
      !location.pathname.includes('/game/') &&
        isAuthenticated &&
        saveFreeSpins(data?.data?.freeSpins);
    }
  });

  useEffect(() => {
    if (!location) return;
    isAuthenticated && refetch();

    if (freeSpinsChanged) {
      openModal('POPUP_FREESPINS');
    }
  }, [isAuthenticated, freeSpinsChanged, location, refetch]);

  return null;
}
