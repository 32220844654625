export const AllRights = () => {
  return (
    <svg width="58" height="50" viewBox="0 0 58 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_650_5975)">
        <path
          d="M33.3656 45.5082C27.6957 48.3822 21.3901 48.6896 15.7848 46.8652C10.1734 45.0418 5.26479 41.0802 2.38253 35.4164C-0.497006 29.7579 -0.805129 23.4646 1.02306 17.8702C2.85601 12.2689 6.82446 7.36855 12.4944 4.49472C18.1695 1.61818 24.4746 1.30931 30.0807 3.1351C31.072 3.45891 32.0416 3.84941 32.9819 4.30389V7.10342C31.8105 6.449 30.5796 5.91113 29.3085 5.49645C24.3085 3.87223 18.6843 4.14714 13.623 6.71265C8.56201 9.27787 5.01968 13.6476 3.38624 18.6357C1.75676 23.6252 2.03426 29.2389 4.60472 34.2902C7.17518 39.3413 11.5542 42.8744 16.5521 44.5046C21.5509 46.1328 27.1758 45.8558 32.237 43.2905C37.2983 40.725 40.8385 36.3549 42.47 31.3661C43.0401 29.6226 43.3769 27.8033 43.4648 25.9584H45.9606C45.871 28.0664 45.4911 30.1459 44.8398 32.138C43.01 37.7328 39.0407 42.6318 33.3656 45.5082Z"
          fill="white"
        />
        <path
          d="M18.195 15.6523V34.3475H11.8794V20.4062H8.66821V15.6523H18.195ZM34.6794 24.4925C35.4823 24.9911 36.1022 25.6008 36.5393 26.3219C36.9764 27.043 37.1949 27.8665 37.1949 28.7923C37.1949 29.9853 36.8426 31.0358 36.1379 31.9438C35.4331 32.8519 34.443 33.5508 33.1675 34.0404C31.8919 34.5301 30.4335 34.7748 28.7922 34.7748C27.1508 34.7748 25.6924 34.5301 24.4168 34.0404C23.1413 33.5508 22.1511 32.8519 21.4465 31.9438C20.7418 31.0358 20.3894 29.9853 20.3894 28.7923C20.3894 27.8665 20.6079 27.043 21.045 26.3219C21.4821 25.6008 22.1021 24.9911 22.9049 24.4925C21.6739 23.5133 21.0583 22.2847 21.0583 20.8069C21.0583 19.6851 21.384 18.706 22.0351 17.8691C22.6863 17.0323 23.5961 16.3824 24.7647 15.9195C25.9332 15.4564 27.2758 15.225 28.7922 15.225C30.3086 15.225 31.651 15.4564 32.8195 15.9195C33.9881 16.3824 34.898 17.0323 35.5491 17.8691C36.2003 18.706 36.5259 19.6851 36.5259 20.8069C36.5259 22.2847 35.9104 23.5133 34.6794 24.4925ZM28.7922 19.4715C28.3462 19.4715 27.9848 19.614 27.7083 19.8988C27.4319 20.1837 27.2936 20.5753 27.2936 21.074C27.2936 21.5724 27.4319 21.9642 27.7083 22.2491C27.9848 22.534 28.3462 22.6764 28.7922 22.6764C29.2382 22.6764 29.5995 22.534 29.8759 22.2491C30.1525 21.9642 30.2907 21.5724 30.2907 21.074C30.2907 20.5753 30.1525 20.1837 29.8759 19.8988C29.5995 19.614 29.2382 19.4715 28.7922 19.4715ZM28.7922 30.5283C29.3988 30.5283 29.8937 30.3503 30.2774 29.9943C30.6609 29.6381 30.8527 29.1663 30.8527 28.5787C30.8527 27.9912 30.6609 27.5194 30.2774 27.1632C29.8937 26.8071 29.3988 26.6291 28.7922 26.6291C28.1856 26.6291 27.6905 26.8071 27.3069 27.1632C26.9234 27.5194 26.7317 27.9912 26.7317 28.5787C26.7317 29.1663 26.9234 29.6381 27.3069 29.9943C27.6905 30.3503 28.1856 30.5283 28.7922 30.5283Z"
          fill="white"
        />
        <path
          d="M50.0283 14.3645H45.6128V18.6376H40.7959V14.3645H36.3805V9.82422H40.7959V5.55096H45.6128V9.82422H50.0283V14.3645Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_650_5975">
          <rect width="57.5" height="46" fill="white" transform="translate(0 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
