import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import { FreeSpins } from 'react-easyrocket';

type FreeSpinsContextProps = {
  oldFreeSpins: FreeSpins | null;
  saveFreeSpins: (freeSpins: FreeSpins) => void;
  freeSpinsChanged: boolean;
  setFreeSpinsChanged: Dispatch<SetStateAction<boolean>>;
};

export const FreeSpinsContext = createContext<FreeSpinsContextProps>({} as FreeSpinsContextProps);

export const useIniFreeSpins = () => {
  const [oldFreeSpins, setOldFreeSpins] = useState<FreeSpins | null>(null);
  const [freeSpinsChanged, setFreeSpinsChanged] = useState<boolean>(false);

  const saveFreeSpins = useCallback((freeSpins: FreeSpins) => {
    const fsFromStorage = localStorage.getItem('oldFreeSpin');
    if (fsFromStorage === null) {
      setOldFreeSpins(freeSpins);
      localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
    }
    if (fsFromStorage && Number(fsFromStorage) < freeSpins.number) {
      setFreeSpinsChanged(true);
      setOldFreeSpins(freeSpins);
      localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
    }
    if (Number(fsFromStorage) === freeSpins.number) {
      setOldFreeSpins(freeSpins);
      localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
    } else {
      setOldFreeSpins(freeSpins);
      localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
    }
  }, []);

  return { oldFreeSpins, saveFreeSpins, freeSpinsChanged, setFreeSpinsChanged };
};

export const FreeSpinsProvider = ({ children }: PropsWithChildren) => {
  const values = useIniFreeSpins();
  return <FreeSpinsContext.Provider value={values}>{children}</FreeSpinsContext.Provider>;
};

export const useFreeSpins = () => {
  return useContext(FreeSpinsContext);
};
