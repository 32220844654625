import { useMemo } from 'react';
import { useGetSinglePage } from 'react-easyrocket';
import { SPECIAL_SLIDE } from '../types';
import { useGetNewestTournament } from './useGetNewestTournament';
import { useTimedSlidesSchedule } from './useTimedSlidesSchedule';

/**
 * Получает список включенных в админке слайдов и отфильтровывает те,
 * которые по внутренним для слайда причинам должны быть скрыты (например,
 * в админке слайд для отображения турнира включен, а самого запущенного локального
 * турнира в данный момент нет )
 */
export function useGetSlides() {
  const { data, isLoading } = useGetSinglePage('main_sliders');
  const _slides = useMemo(() => data?.childs || [], [data?.childs]);

  /** Tournament check start */
  const hasTournamentSlide = useMemo(
    () => _slides.some((slide) => slide.code === SPECIAL_SLIDE.TOURNAMENT),
    [_slides]
  );
  const showTournamentSlide = useGetNewestTournament(hasTournamentSlide);
  /** Tournament check end */

  const slides = useTimedSlidesSchedule(_slides);

  const slidesFiltered = useMemo(() => {
    return slides.filter((slide) => {
      let leaveSlide = true;

      if (!showTournamentSlide && slide.code === SPECIAL_SLIDE.TOURNAMENT) {
        leaveSlide = false;
      }

      return leaveSlide;
    });
  }, [showTournamentSlide, slides]);

  return { slides: slidesFiltered, isLoading };
}
