import { MESSAGE_STATUS } from 'react-easyrocket';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'src/components/Buttons/Button';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { useMessage } from 'src/modules/messages';

export const DepositCryptoModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModalContext<{
    exchangeCurrency: string;
    amount: string;
    account: string;
    exchangeAmount: string;
  }>();
  const { openMessage } = useMessage();

  return (
    <ModalWrapper>
      <div className="flex flex-col items-center gap-5 p-[15px] w-[400px] jmid:w-full h-full">
        <p className="text-2xl jsm:text-xl font-bold whitespace-normal text-center text-button-secondary">
          {`${t(
            'accountWalletDeposite.successTitle'
          ).toUpperCase()} ${modalProps?.exchangeCurrency.toUpperCase()}`}
        </p>
        <div className="flex flex-col items-start gap-2 w-full">
          <Trans
            components={{
              '1': (
                <span
                  className="text-button-secondary break-all underline cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(modalProps?.exchangeAmount || '');
                    openMessage({
                      type: 'USER_MESSAGE',
                      props: { text: `${t('general.amountCopied')}`, type: MESSAGE_STATUS.SUCCESS },
                      saveToHistory: false
                    });
                  }}
                />
              ),
              '2': (
                <span
                  className="text-button-secondary break-all underline cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(modalProps?.account || '');
                    openMessage({
                      type: 'USER_MESSAGE',
                      props: { text: `${t('general.amountCopied')}`, type: MESSAGE_STATUS.SUCCESS },
                      saveToHistory: false
                    });
                  }}
                />
              )
            }}
            values={{
              amount: `${modalProps?.exchangeAmount} ${modalProps?.exchangeCurrency}`,
              paymentAddress: modalProps?.account || ''
            }}>
            {t('accountWalletDeposite.successText')}
          </Trans>
          <Button
            mode="button-secondary"
            onClick={() => closeModal()}
            label={`${t('modalResetPassword.okay')}`}
            title={`${t('modalResetPassword.okay')}`}
            className="mt-[30px] m-auto"
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
