export const getQueryStatus = <
  S extends { status: number },
  E extends { response?: { status: number } }
>(
  successResponse: S | undefined,
  errorReponse: E | null
) => {
  if (errorReponse) {
    return errorReponse.response?.status;
  }
  return successResponse?.status;
};
