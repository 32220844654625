import { CSSProperties, FC, useEffect, useRef } from 'react';

import { clsx } from 'clsx';
import { splitStringRegex } from 'src/components/GameCard/components/Gradient/utils/splitStringRegex';

import styles from './styles.module.css';

interface GradientProps {
  gradientColor: string | null;
  name: string;
  providerName: string | undefined;
}

export const Gradient: FC<GradientProps> = ({ gradientColor, name, providerName }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [firstWord, otherWords] = splitStringRegex(name);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth;

        document.body.style.setProperty('--gameNameContainer', `${width}px`);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateWidth);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    requestAnimationFrame(updateWidth);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="gameNameBlock z-[1] rounded-b-lg absolute bottom-0 flex h-2/3 w-full flex-col items-center justify-end px-1 pb-2 1024:pb-3"
      style={{
        background: `linear-gradient(180deg, rgba(99, 105, 255, 0.00) 18%, ${gradientColor} 68%)`
      }}>
      <span
        style={{ '--word': `${firstWord.length}` } as CSSProperties}
        className={clsx(
          'mx-2 text-center flex-nowrap line-clamp-2 font-franklinGothicDemiCondRegular uppercase text-text-secondary',
          styles.containerTitleFirst
        )}>
        {firstWord}
      </span>
      <span
        style={{ '--otherWords': `${otherWords.length}` } as CSSProperties}
        className={clsx(
          'line-clamp-1 flex-nowrap overflow-hidden text-center font-franklinGothicDemiCondRegular uppercase text-text-secondary',
          styles.containerTitleSecond
        )}>
        {otherWords}
      </span>

      <p className="line-clamp-1 text-center font-mont text-[9px] text-text-secondary">
        {providerName}
      </p>
    </div>
  );
};
