import { useGameFrameAutoResize } from 'react-easyrocket';

export const useSportFrameStyle = () => {
  const headerOffset = document.querySelector<HTMLElement>('.header-container')?.offsetHeight || 0;

  const navBarContainer =
    document.querySelector<HTMLElement>('.nav-bar-container')?.offsetHeight || 0;

  const { gameFrameStyle } = useGameFrameAutoResize({
    isHeaderVisible: true,
    headerHeight: headerOffset + navBarContainer
  });

  return gameFrameStyle;
};
