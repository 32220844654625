import { FC } from 'react';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    seon: any;
    _smartico: any;
    _smartico_user_id: any;
    _smartico_language: any;
  }
}

export enum GameCategory {
  NewGames = 1,
  TopGames = 2,
  FeatureBuy = 579,
  Megaways = 580,
  BooksOfSlots = 581,
  ReadyToDrop = 582,
  Videoslot = 583,
  SportGames = 584,
  CasualGames = 585,
  FruitsGames = 586,
  DropWinsGames = 587
}

export enum KycStatus {
  Pending = 0,
  NotVerified = 1,
  Verified = 2,
  DocumentsExpired = 3,
  Less18Years = 4
}

export type Country = {
  name: string;
  alpha2: string;
  alpha3: string;
  flag: string;
  timezones: {
    gmtOffset: number;
    gmtOffsetName: string;
    zoneName: string;
  }[];
  currencies: string[];
  phoneCodes: string[];
};

export type DropDownOption = { id: string; name: string };

export type StepProps = {
  changeStep: (value: number) => void;
  availableSteps: boolean[];
  completedSteps: boolean[];
  showAlternativeMethods?: boolean;
};

export type StepsOption = {
  title: string;
  children: FC<StepProps>;
};

export enum BonusTransactionType {
  'bonusTransactionType.bonus' = 0,
  'bonusTransactionType.moneyPrize' = 1,
  'bonusTransactionType.manualBonus' = 2,
  'bonusTransactionType.referral' = 3,
  'bonusTransactionType.freeSpinWin' = 4,
  'bonusTransactionType.bet' = 5,
  'bonusTransactionType.betSport' = 6,
  'bonusTransactionType.win' = 7,
  'bonusTransactionType.zeroing' = 8,
  'bonusTransactionType.cancelWager' = 9,
  'bonusTransactionType.winSport' = 10,
  'bonusTransactionType.cashBack' = 11,
  'bonusTransactionType.levelBonus' = 12,
  'bonusTransactionType.refund' = 13,
  'bonusTransactionType.rollbackBet' = 14,
  'bonusTransactionType.rollbackWin' = 15,
  'bonusTransactionType.jackpotWin' = 16
}

export enum StatusBonusTransaction {
  Pending = 0,
  Queue = 1,
  Credited = 2,
  Activated = 3,
  Rejected = 4,
  Cancel = 5,
  Complete = 6,
  Refund = 7
}

export const STATUS_BONUS_TRANSACTION_NAME: { [key: number]: string } = {
  [StatusBonusTransaction.Pending]: 'kycStatus.pending',
  [StatusBonusTransaction.Queue]: 'kycStatus.queue',
  [StatusBonusTransaction.Credited]: 'kycStatus.credited',
  [StatusBonusTransaction.Activated]: 'kycStatus.activated',
  [StatusBonusTransaction.Rejected]: 'kycStatus.rejected',
  [StatusBonusTransaction.Cancel]: 'kycStatus.cancel',
  [StatusBonusTransaction.Complete]: 'kycStatus.complete',
  [StatusBonusTransaction.Refund]: 'kycStatus.refund'
};

export const TRANSACTION_STATUS: { [key: string]: string } = {
  '0': 'kycStatus.pending',
  '1': 'kycStatus.complete',
  '2': 'kycStatus.cancel',
  '3': 'kycStatus.failed',
  '4': 'kycStatus.refund',
  '5': 'kycStatus.pendingWager',
  '6': 'kycStatus.rejected',
  '7': 'kycStatus.notConfirmed',
  '8': 'kycStatus.processing',
  '9': 'kycStatus.aggregatorRejected',
  '10': 'kycStatus.rollback',
  '11': 'kycStatus.wagerZeroing',
  '12': 'kycStatus.notConfirmedBlockAmount'
};

export const TRANSACTION_TYPE: string[] = [
  'transactionType.deposit',
  'transactionType.withdraw',
  'transactionType.bet',
  'transactionType.win',
  'transactionType.bonus',
  'transactionType.jackpot',
  'transactionType.changeBalanceInc',
  'transactionType.changeBalanceDec',
  'transactionType.referral',
  'transactionType.convertBalance',
  'transactionType.moneyPrize',
  'transactionType.bonusTransaction',
  'transactionType.cancelBonusFine',
  'transactionType.refund',
  'transactionType.rollbackBet',
  'transactionType.rollbackWin',
  'transactionType.betSport',
  'transactionType.jackpotIncreasing',
  'transactionType.freeSpinWin',
  'transactionType.zeroing',
  'transactionType.winSport',
  'transactionType.increment',
  'transactionType.cashback',
  'transactionType.levelBonus'
];

export type PaymentFormField = {
  name: string;
  title: string;
  toShow: boolean;
  type: string;
  value: string;
};

export type PaymentMethod = {
  success: boolean;
  type: string;
  order: string;
  method: string;
  title: string;
  logoUrl: string;
  options?: PaymentMethodOption[];
  formFields?: PaymentFormField[];
  min_amount: string;
  max_amount: string;
  commission: string;
  commission_payer: number;
  exchangeCurrency?: string;
  exchangeRate?: string;
  _reactDepositMethodId?: number;
};

export type PaymentMethodOption = {
  name: string;
  title: string;
  exchangeRate: string;
  amount?: number;
  currency?: string;
  id?: number;
};

export type ResponsiveBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'gf';

export type HellocleverResponse = {
  success: boolean;
  currency: string;
  amount: string;
  orderId: number;
  account: string;
  nonce: number;
};

export interface SVGPropsIcon {
  className?: string;
  onClick?: () => void;
  fill?: string;
  style?: React.CSSProperties;
  onShow?: ((e: any) => void) | undefined;
  closeTopDrawer?: (() => void) | undefined;
  setPasswordShown?: (value: React.SetStateAction<boolean>) => void;
  passwordShown?: boolean;
  stroke?: string;
  isGame?: boolean;
}

// ============================= Далее находяться типы которые не используються ======================================= //

export const KYC_STATUS_NAME: { [key: number]: string } = {
  [KycStatus.Pending]: 'kycStatus.pending',
  [KycStatus.NotVerified]: 'kycStatus.notVerified',
  [KycStatus.Verified]: 'kycStatus.verified',
  [KycStatus.DocumentsExpired]: 'kycStatus.documentsExpired',
  [KycStatus.Less18Years]: 'kycStatus.less18Years'
};

export const BONUS_TRANSACTION_TYPE: string[] = [
  'bonusTransactionType.bonus',
  'bonusTransactionType.moneyPrize',
  'bonusTransactionType.manualBonus',
  'bonusTransactionType.referral',
  'bonusTransactionType.freeSpinWin',
  'bonusTransactionType.bet',
  'bonusTransactionType.betSport',
  'bonusTransactionType.win',
  'bonusTransactionType.zeroing',
  'bonusTransactionType.cancelWager',
  'bonusTransactionType.winSport',
  'bonusTransactionType.cashBack',
  'bonusTransactionType.levelBonus',
  'bonusTransactionType.refund',
  'bonusTransactionType.rollbackBet',
  'bonusTransactionType.rollbackWin',
  'bonusTransactionType.jackpotWin'
];

export enum BONUS_TYPE {
  Registration = 0,
  RegistrationPromoCode = 1,
  FirstAuthorization = 2,
  PromoCode = 3,
  Birthday = 4,
  Deposits = 5,
  Periodic = 6,
  IncreaseWinnings = 7,
  ActivatedByLink = 8
}

export type GetTransactionsRequestBody = {
  'amount-from'?: string;
  'amount-to'?: string;
  'date-start'?: string;
  'date-end'?: string;
  type?: string;
  sort?: string;
  'sort-dir'?: string;
  page?: number;
  status?: string;
};

export type GameCategoriesMap = Record<
  string,
  { name: string; title: string; value: number | boolean }
>;

export type UploadedFile = {
  file?: File;
  imagePreviewUrl?: string | ArrayBuffer | null;
};

export const GamesCategoryName: { [key: number]: string } = {
  [GameCategory.NewGames]: 'New Games',
  [GameCategory.TopGames]: 'Top Games',
  [GameCategory.FeatureBuy]: 'Feature Buy',
  [GameCategory.Megaways]: 'Megaways',
  [GameCategory.BooksOfSlots]: 'Books Of Slots',
  [GameCategory.ReadyToDrop]: 'Ready To Drop',
  [GameCategory.Videoslot]: 'Videoslot',
  [GameCategory.SportGames]: 'Sport Games',
  [GameCategory.CasualGames]: 'Casual Games',
  [GameCategory.FruitsGames]: 'Fruits Games',
  [GameCategory.DropWinsGames]: 'Drop Wins Games'
};
