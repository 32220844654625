import { useMemo } from 'react';
import {
  useApiContext,
  useGetDailyBonusWithUser,
  useGetDailyBonusWithoutUser
} from 'react-easyrocket';
import { useUpdateUserState } from './hooks/useUpdateUserState';
import { getQueryStatus } from './utils/getQueryStatus';

export const useGPTDailyBonus = () => {
  const { isAuthenticated } = useApiContext();
  const {
    data: dailyBonusWithoutUser,
    error: dailyBonusWithoutUserError,
    isLoading: isLoadingWithoutUser
  } = useGetDailyBonusWithoutUser({});
  const {
    data: dailyBonusWithUser,
    error: dailyBonusWithUserError,
    isLoading: isLoadingWithUser
  } = useGetDailyBonusWithUser({});

  useUpdateUserState();

  const dailyBonusWithUserStatus = useMemo(() => {
    return getQueryStatus(dailyBonusWithUser, dailyBonusWithUserError);
  }, [dailyBonusWithUser, dailyBonusWithUserError]);

  const dailyBonusWithoutUserStatus = useMemo(() => {
    return getQueryStatus(dailyBonusWithoutUser, dailyBonusWithoutUserError);
  }, [dailyBonusWithoutUser, dailyBonusWithoutUserError]);

  const isLoadingState =
    (isLoadingWithUser && isAuthenticated) || (isLoadingWithoutUser && !isAuthenticated);

  return {
    isLoadingState,
    dailyBonusWithUser: dailyBonusWithUser?.data,
    dailyBonusWithUserStatus,
    dailyBonusWithoutUserStatus
  };
};
