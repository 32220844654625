import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationsClose } from 'src/components/svg';
import { useDrawer } from 'src/hooks/useDrawer';

export const NotificationsHead = () => {
  const { closeNotifications } = useDrawer();
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center mb-[22px]">
      <h3 className="text-xl font-bold text-nb-stub-text">{t('general.notifications')}</h3>
      <NotificationsClose className="cursor-pointer" onClick={closeNotifications} />
    </div>
  );
};
