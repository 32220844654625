import { memo, useRef } from 'react';
import {
  useCurrency,
  useGetLocalTournament,
  useGetTournamentWithUsersProgress
} from 'react-easyrocket';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CountdownTimer } from './components/CountdownTimer';
import { Banner } from './components/Banner';
import { FinishedBadge } from './components/FinishedBadge';
import { TournamentInfo } from './components/TournamentInfo';
import { LeaderboardTable } from './components/LeaderboardTable';
import { TournamentDescription } from './components/TournamentDescription';
import { GameList } from './components/GameList';
import { TournamentPlaces } from './components/TournamentPlace';
import { useGetLocalTournamentPrize } from 'src/hooks/useGetLocalTournamentPrize';
import { TournamentTitle } from './components/TournamentTitle';
import { TournamentPlacesWrapper } from './components/TournamentPlacesWrapper';

export const LocalTournament = memo(() => {
  const { t } = useTranslation();
  const participantsTableTitleRef = useRef<HTMLHeadingElement | null>(null);
  const { getPrize } = useGetLocalTournamentPrize();
  const { currencyName, currencyIcon } = useCurrency();
  const { tournamentId } = useParams();

  const { tournament, isLoading, isFetching } = useGetLocalTournament({
    tournament_id: Number(tournamentId) ?? 0
  });

  const { data: tournamentWithMyProgress } = useGetTournamentWithUsersProgress({ tournamentId });

  const isFinishedTournament = tournament?.status === 3;

  const members = !tournamentWithMyProgress
    ? tournament?.members
    : tournamentWithMyProgress?.members;

  const isShowTable = Boolean(members?.length) && Boolean(tournament);

  const membersUserPlace = members?.findIndex((member) => member.isCurrentUser);

  const isUserParticipate = membersUserPlace > -1;

  const isLoadingTournament = isLoading || isFetching;

  return (
    <div className="flex flex-col gap-5 768:gap-[50px] max-w-[1450px] mx-auto">
      <Banner
        isFinishedTournament={isFinishedTournament}
        tournamentImage={tournament?.image}
        isUserParticipate={isUserParticipate}
        ref={participantsTableTitleRef}
        isLoading={isLoadingTournament}
      />

      <div className="flex flex-col items-center">
        {isFinishedTournament && <FinishedBadge />}

        <div className="flex flex-col items-center mb-5 max-w-[700px] px-2.5">
          <TournamentTitle
            tournament={tournament}
            currencyName={currencyName}
            isLoading={isLoadingTournament}
          />
          <CountdownTimer tournamentDateEnd={tournament?.dateEnd} />

          <TournamentInfo
            dateEnd={tournament?.dateEnd}
            dateStart={tournament?.dateStart}
            currencyIcon={currencyIcon}
            currencyName={currencyName}
            minBet={tournament?.tasks?.[0]?.minBet}
            placesLength={tournament?.places?.length}
            isLoading={isLoadingTournament}
          />
        </div>
        <TournamentDescription
          isLoading={isLoadingTournament}
          content={tournament?.description || ''}
        />
        <h3 className="font-bold text-yellow text-[26px] leading-[28px] text-center first-letter:uppercase 768:text-[48px] 768:leading-[52px] 768:tracking-[0.03em] mb-[30px]">
          {isFinishedTournament ? t('tournaments.winners') : t('tournaments.tournamentPrizes')}
        </h3>
        <TournamentPlacesWrapper>
          {tournament?.places
            .slice(0, 3)
            .map((localTournamentPlace, index) => (
              <TournamentPlaces
                key={index}
                index={index}
                isFinishedTournament={isFinishedTournament}
                prize={getPrize(localTournamentPlace)}
                login={members[index]?.login}
              />
            ))}
        </TournamentPlacesWrapper>
        {isShowTable && (
          <LeaderboardTable
            title={
              <h4
                className="text-[26px] leading-[28px] font-bold text-text-secondary text-center first-letter:uppercase my-5"
                ref={participantsTableTitleRef}>
                {t('tournaments.currentLeaderboard')}
              </h4>
            }
            tournament={tournament}
            members={members}
            getPrize={getPrize}
          />
        )}
      </div>
      <GameList isShowTable={isShowTable} games={tournament?.tasks[0]?.games} />
    </div>
  );
});

LocalTournament.displayName = 'LocalTournament';
