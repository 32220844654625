import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'src/hooks/useModalContext';
import { ChangeTabButton } from './components/ChangeTabButton';
import { twJoin } from 'tailwind-merge';

interface AuthTabsProps {
  isSignUpActive: boolean;
  className?: string;
}

export const AuthTabs: FC<AuthTabsProps> = ({ isSignUpActive, className }) => {
  const { t } = useTranslation();
  const { closeModal, openModal } = useModalContext();

  const handleOpenLoginModal = () => {
    openModal('LOGIN');
    closeModal();
  };

  const handleOpenSignupModal = () => {
    openModal('SIGNUP');
    closeModal();
  };

  return (
    <div
      className={twJoin(
        'flex relative w-full before:content-[""] before:w-full before:absolute before:h-[1px] before:bg-background-white-transparent before:left-0 before:bottom-[1px]',
        className
      )}>
      <ChangeTabButton
        isActive={isSignUpActive}
        onClick={!isSignUpActive ? handleOpenSignupModal : undefined}>
        {t('general.signUp')}
      </ChangeTabButton>
      <ChangeTabButton
        isActive={!isSignUpActive}
        onClick={isSignUpActive ? handleOpenLoginModal : undefined}>
        {t('modalLogin.title')}
      </ChangeTabButton>
    </div>
  );
};
