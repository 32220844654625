import { HTMLAttributes } from 'react';
import { SVGPropsIcon } from 'src/types';
import { twJoin } from 'tailwind-merge';

type PropsClose = {
  onShow?: (() => void) | undefined;
  fill?: string;
  className?: string;
};

export const SimpleClose = ({ onShow, fill = 'white', className }: PropsClose) => {
  return (
    <svg
      onClick={onShow}
      className={twJoin('cursor-pointer', className)}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.96 12.0008L23.3847 4.57604C24.2051 3.75569 24.2051 2.42569 23.3847 1.60661L22.3949 0.616805C21.5743 -0.203807 20.2443 -0.203807 19.4252 0.616805L12.0008 8.04127L4.57604 0.615266C3.75569 -0.205089 2.42569 -0.205089 1.60661 0.615266L0.615266 1.60508C-0.205089 2.42569 -0.205089 3.75569 0.615266 4.57476L8.04127 12.0008L0.616805 19.4252C-0.203807 20.2459 -0.203807 21.5759 0.616805 22.3949L1.60661 23.3847C2.42697 24.2051 3.75697 24.2051 4.57604 23.3847L12.0008 15.96L19.4252 23.3847C20.2459 24.2051 21.5759 24.2051 22.3949 23.3847L23.3847 22.3949C24.2051 21.5743 24.2051 20.2443 23.3847 19.4252L15.96 12.0008Z"
        fill={fill}
      />
    </svg>
  );
};

export const BigCloseGame = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48" fill="none">
      <path
        d="M8 8L40 40M8 8L40 40M8 8L40 40"
        stroke="white"
        strokeWidth="3.75"
        strokeLinecap="round"
      />
      <path
        d="M40 8L8 40M40 8L8 40M40 8L8 40"
        stroke="white"
        strokeWidth="3.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ThinClose = ({
  onClick,
  className
}: {
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  className?: string;
}) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={twJoin('cursor-pointer', className)}
      fill="none">
      <rect
        x="2.10938"
        y="1.90039"
        width="12"
        height="1.00001"
        transform="rotate(45 2.10938 1.90039)"
        fill="white"
      />
      <rect
        x="1.40039"
        y="10.3896"
        width="12"
        height="1.00001"
        transform="rotate(-45 1.40039 10.3896)"
        fill="white"
      />
    </svg>
  );
};

export const DepositClose = (props: HTMLAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}>
    <path
      d="M7.98 6.00038L11.6924 2.28802C12.1025 1.87784 12.1025 1.21284 11.6924 0.803307L11.1975 0.308402C10.7872 -0.101903 10.1222 -0.101903 9.71262 0.308402L6.00038 4.02064L2.28802 0.307633C1.87784 -0.102544 1.21284 -0.102544 0.803307 0.307633L0.307633 0.802538C-0.102544 1.21284 -0.102544 1.87784 0.307633 2.28738L4.02064 6.00038L0.308402 9.71262C-0.101903 10.1229 -0.101903 10.7879 0.308402 11.1975L0.803307 11.6924C1.21348 12.1025 1.87848 12.1025 2.28802 11.6924L6.00038 7.98L9.71262 11.6924C10.1229 12.1025 10.7879 12.1025 11.1975 11.6924L11.6924 11.1975C12.1025 10.7872 12.1025 10.1222 11.6924 9.71262L7.98 6.00038Z"
      fill="white"
    />
  </svg>
);

export const CloseBanner = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98 5.9709L11.6924 2.27678C12.1025 1.86862 12.1025 1.20688 11.6924 0.79936L11.1975 0.306887C10.7872 -0.101403 10.1222 -0.101403 9.71262 0.306887L6.00038 4.00088L2.28802 0.306122C1.87784 -0.102041 1.21284 -0.102041 0.803307 0.306122L0.307633 0.798595C-0.102544 1.20688 -0.102544 1.86862 0.307633 2.27614L4.02064 5.9709L0.308402 9.6649C-0.101903 10.0732 -0.101903 10.7349 0.308402 11.1424L0.803307 11.6349C1.21348 12.0431 1.87848 12.0431 2.28802 11.6349L6.00038 7.94079L9.71262 11.6349C10.1229 12.0431 10.7879 12.0431 11.1975 11.6349L11.6924 11.1424C12.1025 10.7342 12.1025 10.0724 11.6924 9.6649L7.98 5.9709Z"
        fill="white"
      />
    </svg>
  );
};

export const NotificationsClose = (props: SVGPropsIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.2625 12L19.5312 6.73125C19.8333 6.42916 20 6.02709 20 5.6C20 5.17291 19.8333 4.77084 19.5312 4.46875C19.2292 4.16666 18.8271 4 18.4 4C17.9729 4 17.5708 4.16666 17.2688 4.46875L12 9.7375L6.73125 4.46875C6.42916 4.16666 6.02709 4 5.6 4C5.17291 4 4.77084 4.16666 4.46875 4.46875C4.16666 4.77084 4 5.17291 4 5.6C4 6.02709 4.16666 6.42916 4.46875 6.73125L9.7375 12L4.46875 17.2688C4.16666 17.5708 4 17.9729 4 18.4C4 18.8271 4.16666 19.2292 4.46875 19.5312C4.77134 19.8333 5.17291 20 5.6 20C6.02709 20 6.42866 19.8333 6.73125 19.5312L12 14.2625L17.2688 19.5312C17.5713 19.8333 17.9729 20 18.4 20C18.8271 20 19.2287 19.8333 19.5312 19.5312C19.8333 19.2292 20 18.8271 20 18.4C20 17.9729 19.8333 17.5708 19.5312 17.2688L14.2625 12Z"
      fill="#CECECE"
    />
  </svg>
);
