import { FC } from 'react';
import { Child } from 'react-easyrocket';

import { SPECIAL_SLIDE } from './types';
import JackpotSlide from './components/JackpotSlide';
import TournamentSlide from './components/TournamentSlide';
import RecentWinnersSlide from './components/RecentWinnersSlide';

export const SLIDES: Record<SPECIAL_SLIDE, FC<{ slide: Child }>> = {
  [SPECIAL_SLIDE.RECENT_WINNERS]: RecentWinnersSlide,
  [SPECIAL_SLIDE.JACKPOTS]: JackpotSlide,
  [SPECIAL_SLIDE.TOURNAMENT]: TournamentSlide
};
