import { SidebarTooltip, useSidebarTooltip } from '../SidebarTooltip';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FC } from 'react';
import { SidebarLink } from 'src/components/SidebarGamesDropdown/config';
import { NavLinkLng, useNavigateLng } from 'src/lib';

interface CollapsedLinkProps {
  data: SidebarLink;
}

export const CollapsedLink: FC<CollapsedLinkProps> = ({
  data: { id, path, tooltip, title, icon }
}) => {
  const { handleActiveTooltip, clearTooltipState, activeTooltip } = useSidebarTooltip();
  const navigate = useNavigateLng();
  const { t } = useTranslation();

  return (
    <SidebarTooltip
      key={path}
      id={id}
      mouseEnterFunction={() => handleActiveTooltip(tooltip)}
      mouseLeaveFunction={clearTooltipState}
      containerClickFunction={() => {
        navigate(path, { replace: true });
      }}
      showTooltip={activeTooltip === tooltip}
      tooltipContent={t(title)}>
      <NavLinkLng
        className={({ isActive }) =>
          classNames('[&_>svg>path]:hover:fill-yellow', isActive && '[&>svg>path]:fill-yellow')
        }
        to={path}>
        {icon}
      </NavLinkLng>
    </SidebarTooltip>
  );
};
