import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const MethodsIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none">
      <path
        d="M15.5 1.25H2.75C2.35218 1.25 1.97064 1.40804 1.68934 1.68934C1.40804 1.97064 1.25 2.35218 1.25 2.75V13.25C1.25 13.6478 1.40804 14.0294 1.68934 14.3107C1.97064 14.592 2.35218 14.75 2.75 14.75H14.75C15.1478 14.75 15.5294 14.592 15.8107 14.3107C16.092 14.0294 16.25 13.6478 16.25 13.25V5C16.25 4.80109 16.171 4.61032 16.0303 4.46967C15.8897 4.32902 15.6989 4.25 15.5 4.25H3.5C3.30109 4.25 3.11032 4.17098 2.96967 4.03033C2.82902 3.88968 2.75 3.69891 2.75 3.5C2.75 3.30109 2.82902 3.11032 2.96967 2.96967C3.11032 2.82902 3.30109 2.75 3.5 2.75H16.25V2C16.25 1.80109 16.171 1.61032 16.0303 1.46967C15.8897 1.32902 15.6989 1.25 15.5 1.25ZM13.625 8.375C13.8475 8.375 14.065 8.44098 14.25 8.5646C14.435 8.68821 14.5792 8.86391 14.6644 9.06948C14.7495 9.27505 14.7718 9.50125 14.7284 9.71948C14.685 9.9377 14.5778 10.1382 14.4205 10.2955C14.2632 10.4528 14.0627 10.56 13.8445 10.6034C13.6262 10.6468 13.4 10.6245 13.1945 10.5394C12.9889 10.4542 12.8132 10.31 12.6896 10.125C12.566 9.94001 12.5 9.7225 12.5 9.5C12.5 9.20163 12.6185 8.91548 12.8295 8.7045C13.0405 8.49353 13.3266 8.375 13.625 8.375Z"
        fill="white"
      />
    </svg>
  );
};
