import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Cherry } from 'src/components/svg';
import { GameCard } from 'src/components';
import { GameData, LocalTournamentGame } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { HEIGHT_CHERRY_ICON, WIDTH_CHERRY_ICON } from '../../constants';

interface GameListProps {
  isShowTable: boolean;
  games: LocalTournamentGame[] | undefined;
}

export const GameList: FC<GameListProps> = ({ isShowTable, games }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={twMerge('flex self-center items-center gap-5', !isShowTable && 'mt-5')}>
        <Cherry width={WIDTH_CHERRY_ICON} height={HEIGHT_CHERRY_ICON} />
        <h4 className="text-[26px] leading-[28px] font-bold text-text-secondary first-letter:uppercase">
          {t('tournaments.gamesParticipating')}
        </h4>
      </div>
      <div className="grid 768:grid-cols-4 gap-x-1.5 gap-y-2.5 grid-cols-3 1024:grid-cols-8 mt-4 w-full px-[15px]">
        {games?.map((game) => (
          <GameCard
            key={game.id}
            game={game as GameData}
          />
        ))}
      </div>
    </>
  );
};
