import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GamesCategoriesSwitcher } from './components/GamesCategoriesSwitcher';
import { GamesSearchResults } from './components/GamesSearchResults';
import { GamesAndProvidersSearch } from './components/GamesAndProvidersSearch';
import { useGamesGridConfig } from '../GamesGrid/GamesGridLegacy';
import { MIN_SEARCH_VALUE_LENGTH } from 'src/constants';
import {
  useGetCombinedGames,
  useGetFullBalance,
  useInfiniteScroll,
  useRecentSearches,
  useSearch,
  useWindowSize
} from 'react-easyrocket';
import { RecentSearches } from '../RecentSearches/RecentSearches';
import { checkGamesFromFullBalance, getGamesToRender } from './utils';
import { useGetProviderFromUrl } from './hooks/useGetProviderFromUrl';
import noGamesInCategory from 'src/assets/images/noSearchGames.webp';
import { GAME_CATEGORIES, GameCategoryName } from 'src/constants/gameCategories';
import { scrollIntoViewWithHeaderOffset } from 'src/utils/scrollIntoViewWithOffset';

type GamesControlPanelProps = {
  target: string | undefined;
  isGamesPage?: boolean;
};

export const GamesControlPanel = ({ target, isGamesPage = false }: GamesControlPanelProps) => {
  const { pathname } = useLocation();
  const { is768 } = useWindowSize();

  const { categoryId: categoryFromUrl } = useParams<{ categoryId: GameCategoryName }>();
  const categoryId = (categoryFromUrl && GAME_CATEGORIES[categoryFromUrl]?.param) || 'all';

  const pageRef = useRef<HTMLDivElement>(null);
  const { value, onChange, debouncedValue, reset } = useSearch();
  const { totalGames } = useGamesGridConfig();
  const { providerId, providerTitle } = useGetProviderFromUrl();
  const { fullBalance } = useGetFullBalance();

  const { games, fetchNextPage, hasNextPage, isFetching, isFetched, isLoading } =
    useGetCombinedGames(
      {
        name: debouncedValue.length >= MIN_SEARCH_VALUE_LENGTH ? debouncedValue : undefined,
        per_page: totalGames,
        provider_id: !debouncedValue ? providerId : undefined
      },
      {
        categoryId,
        target,
        enabled: !isGamesPage ? debouncedValue.length >= MIN_SEARCH_VALUE_LENGTH : true
      }
    );

  const { parentRef, childRef } = useInfiniteScroll({
    callback: () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  });

  const { recentSearches, saveRecentResult, deleteRecentSearch } = useRecentSearches({
    storageKey: 'gamesSearchResults',
    maxStoredSearchesLength: 6
  });

  const handleSaveRecentResult = () => {
    saveRecentResult(debouncedValue, true);
  };

  const areGamesFromFullBalance = checkGamesFromFullBalance({
    fullBalance,
    categoryId
  });

  const gamesToRender = getGamesToRender({
    games,
    areGamesFromFullBalance
  });

  // scroll into view when page changes
  useEffect(() => {
    if (isGamesPage) {
      scrollIntoViewWithHeaderOffset(pageRef?.current);
    }
  }, [isGamesPage, pathname]);

  const isSearchResults = isGamesPage || debouncedValue.length >= MIN_SEARCH_VALUE_LENGTH;

  return (
    <>
      <div
        className="w-full flex justify-center text-gcp_provider_title text-[30px] font-bold"
        ref={pageRef}>
        {debouncedValue.length === 0 && providerTitle}
      </div>
      {is768 && <GamesAndProvidersSearch onChange={onChange} value={value} />}
      <GamesCategoriesSwitcher
        lightActiveLink={!debouncedValue && !providerId}
        onCategoryClick={reset}
      />
      {is768 && !value && Boolean(recentSearches?.length) && (
        <RecentSearches
          handleClick={onChange}
          items={recentSearches}
          handleDelete={deleteRecentSearch}
        />
      )}
      {isSearchResults && (
        <div className="flex flex-col justify-center items-center mt-[30px] w-full" ref={parentRef}>
          <GamesSearchResults
            games={gamesToRender}
            isFetching={areGamesFromFullBalance ? isLoading : isFetching}
            image={noGamesInCategory}
            isFetched={isFetched}
            onPlayClick={handleSaveRecentResult}
          />
          {hasNextPage && !isFetching && <div className="h-10 w-full" ref={childRef} />}
        </div>
      )}
    </>
  );
};
