import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  ActiveUserTournament,
  LocalTournament,
  LocalTournamentsResponse,
  useApiContext,
  useGetActiveUserTournaments,
  useGetLocalTournaments,
  userTournamentProgress
} from 'react-easyrocket';
import { useModalContext } from './useModalContext';

const CLOSEST_TOURNAMENT_ID_LS = 'closestTournamentId';
const MAX_TIMEOUT_DELAY = 2147483640
export const TOURNAMENT_COMPLETE_LAG_MS = 20000;
export const TOURNAMENT_COMPLETE_REFETCH_MS = 5000;

export function useTournamentWin() {
  const {
    i18n: { language }
  } = useTranslation();
  const { openModal } = useModalContext();
  const [searchParams] = useSearchParams();
  const participating = searchParams.get('participating') === 'true';
  const { refetch: getLocalTournaments } = useGetLocalTournaments({}, { enabled: false });
  const { refetch: refetchMyTournaments } = useGetActiveUserTournaments({ enabled: false });
  const { isAuthenticated } = useApiContext();
  const timerRef = useRef<NodeJS.Timeout>();

  const onTournamentComplete = async (completedTournament: LocalTournament) => {
    const response = await userTournamentProgress({
      tournament_id: `${completedTournament.id}`,
      language
    });
    if (!response || !response.data) return;

    if (response.data.prize) {
      openModal('TOURNAMENT_WIN', {
        props: { prize: response.data.prize, tournamentName: completedTournament.name }
      });
    }
  };

  const updateTournamentsState = async () => {
    const { data: myTournamentsResponse } = await refetchMyTournaments();

    const myTournaments: ActiveUserTournament[] = myTournamentsResponse?.data || [];
    const { data: localTournamentsResponse } = await getLocalTournaments(); // вот на этом запросе можно попробовать сэкономить, но тогда на странице турнира не обновляется список турниров

    // Здесь проверка турнира на завершенность. В LS мог быть сохранен турнир, в котором я участвую,
    // и этот турнир мог уже оказаться завершенным. Если он завершается, то проверяем показать ли уведомление
    const completedTournament = getCompletedTournament(localTournamentsResponse);
    if (completedTournament) {
      onTournamentComplete(completedTournament);
      localStorage.removeItem(CLOSEST_TOURNAMENT_ID_LS);
    }

    if (myTournaments.length === 0) return;
    // После того как мы обрабатываем завершенный турнир, нужно снова проверить есть ли у нас
    // еще турниры в процессе, и если есть, то снова засетать таймаут на перепроверку позже

    const nextClosestTournament = findClosestTournament(myTournaments);
    localStorage.setItem(CLOSEST_TOURNAMENT_ID_LS, `${nextClosestTournament.id}`);

    const timeLeft = nextClosestTournament.dateEnd * 1000 - dayjs().valueOf();
    // Может быть ситуация, при которой таймаут истечет, но турнир еще не завершится - при этом тогда
    // нужно подождать еще - временно выбрано TOURNAMENT_COMPLETE_REFETCH_MS
    clearAndSetTimeout(
      timerRef,
      updateTournamentsState,
      timeLeft < 0 ? TOURNAMENT_COMPLETE_REFETCH_MS : timeLeft
    );
  };

  useEffect(() => {
    // Этот эффект ответственен за запуск функции updateTournamentsState когда мы логинимся или
    // начинаем принимать участие в турнире (participating - это searchParam который становится true)
    // - так этот хук стоя в роутере может "почувствовать", что участие в турнире начато без использования контекста
    if (!isAuthenticated) return;
    updateTournamentsState();
    const timeoutId = timerRef.current;
    return () => {
      if (!timeoutId) return;
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, participating]);
}

function clearAndSetTimeout(
  timerRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  callback: () => any,
  timeout: number
) {
  if (timerRef.current) {
    clearTimeout(timerRef.current);
  }
  timerRef.current = setTimeout(callback, Math.min(timeout, MAX_TIMEOUT_DELAY));
}

function findClosestTournament(tournaments: ActiveUserTournament[]): ActiveUserTournament {
  if (tournaments.length === 1) return tournaments[0];
  let closestTournament = tournaments[0];
  tournaments.forEach((t) => {
    if (t.dateEnd < closestTournament.dateEnd) closestTournament = t;
  });
  return closestTournament;
}

function findTournamentById(
  tournaments: LocalTournament[] | undefined,
  id: number | null
): LocalTournament | undefined {
  if (!tournaments || !id) return undefined;
  return tournaments.find((t) => t.id === id);
}

function getCompletedTournament(
  localTournamentsResponse: AxiosResponse<LocalTournamentsResponse, any> | undefined
): LocalTournament | undefined {
  const closestTournamentId = localStorage.getItem(CLOSEST_TOURNAMENT_ID_LS);
  return findTournamentById(
    localTournamentsResponse?.data?.data?.completed,
    closestTournamentId ? parseInt(closestTournamentId) : null
  );
}
