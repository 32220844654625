import { PaymentFormField } from 'react-easyrocket';
import { DropDownOption } from 'src/types';

export const generateSelectOptionsForFormGenerator = (
  field: PaymentFormField & { options?: { [key: string]: string } }
): DropDownOption[] => {
  if (field.options) {
    return Object.entries(field.options).map(([key, value]) => {
      return {
        id: key,
        name: value
      };
    });
  }
  return Object.entries(field.value).map(([key, value]) => {
    return {
      id: key,
      name: value
    };
  });
};
