export const Megaways = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_277976)">
      <path
        d="M26.5582 11.661H8.25861V22.1109H26.5583L26.5582 11.661ZM13.5773 19.7673H10.6022V14.0046H13.5773V19.7673ZM18.896 19.7673H15.9208V14.0046H18.896V19.7673ZM24.2146 19.7673H21.2395V14.0046H24.2146V19.7673Z"
        fill="white"
      />
      <path
        d="M37.4967 3.60569C35.7621 3.60569 34.3509 4.98684 34.3509 6.68445C34.3509 7.97679 35.1692 9.08499 36.3249 9.54082V16.5704H35.2207V13.3691H31.1273V2.64099H3.66107V24.0971H0.645752V34.241H5.36203V38.6963H29.4263V34.241H34.1426V24.0971H31.1273V22.1152H35.2207V18.9139H38.6685V9.54074C39.8244 9.08499 40.6427 7.97679 40.6427 6.68445C40.6427 4.98684 39.2314 3.60569 37.4967 3.60569ZM6.00463 4.98458H28.7838V13.3691V22.1152V24.0971H28.7837H6.00463V4.98458ZM27.0828 36.3527H7.7056V34.241H27.0828V36.3527ZM31.799 26.4407V31.8974H2.98932V26.4407H31.799ZM32.8772 19.7717H31.1274V15.7127H32.8772V19.7717ZM37.4967 7.41964C37.0543 7.41964 36.6945 7.08982 36.6945 6.68445C36.6945 6.27909 37.0543 5.94927 37.4967 5.94927C37.9391 5.94927 38.2991 6.27909 38.2991 6.68445C38.2991 7.08982 37.9392 7.41964 37.4967 7.41964Z"
        fill="white"
      />
      <path d="M26.5028 7.09741H8.09637V9.441H26.5028V7.09741Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_12190_277976">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.642578 0.667969)"
        />
      </clipPath>
    </defs>
  </svg>
);
