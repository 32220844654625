import { memo, useRef } from 'react';
import { useSynchronizePaths } from './hooks/useSynchronizePaths';
import { useSoftSwissTheme } from './hooks/useSoftSwissTheme';
import { useSportFrameStyle } from './hooks/useSportFrameStyle';
import { useSportUrl } from './hooks/useSportUrl';

export const SportSwissPage = memo(() => {
  const sportFrameRef = useRef<HTMLIFrameElement>(null);
  const sportUrl = useSportUrl();
  const sportFrameStyle = useSportFrameStyle();

  useSynchronizePaths({ sportFrameRef });

  useSoftSwissTheme();

  return (
    <iframe
      ref={sportFrameRef}
      className="w-full h-screen !mt-0"
      style={sportFrameStyle}
      src={sportUrl}
    />
  );
});

SportSwissPage.displayName = 'SportSwissPage';
