import { ChangeEventHandler, FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/Inputs/Input';
import { Arrow, SearchIconThin } from 'src/components/svg';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { ProvidersSearchAbsolute } from './components/ProvidersSearchAbsolute';
import { twJoin } from 'tailwind-merge';
import { useBoolean } from 'react-easyrocket';
import { ShowProvidersButton } from './components/ShowProvidersButton';

interface InputWithButtonProps {
  onChange?: (value: string) => void;
  value?: string;
}

export const GamesAndProvidersSearch: FC<InputWithButtonProps> = ({ onChange, value }) => {
  const [gamesSearchValue, setGamesSearchValue] = useState(value || '');
  const [providersSearchValue, setProvidersSearchValue] = useState<string>('');
  const [showProviders, , disableShowProviders, toggleShowProviders] = useBoolean(false);
  const providerSearchRef = useRef<HTMLDivElement>(null);
  const providersToggle = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (value !== undefined) {
      setGamesSearchValue(value);
    }
  }, [value]);

  const handleGameSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setGamesSearchValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleProviderSwitchClick: MouseEventHandler<HTMLButtonElement> = () => {
    toggleShowProviders();
    setProvidersSearchValue('');
  };

  useClickOutside(
    providerSearchRef,
    () => {
      disableShowProviders();
    },
    providersToggle
  );

  return (
    <div
      className={twJoin(
        'flex flex-col items-center relative mt-2.5 w-full',
        showProviders && 'bg-gcp_search_bg rounded-t-[20px]'
      )}>
      <div className="relative w-full">
        <Input
          value={gamesSearchValue}
          onChange={handleGameSearchChange}
          leftIcon={<SearchIconThin classNameIcon="pr-2" />}
          overrideContainerClassName="gap-0"
          className="pr-[36%]"
          placeholder={t('general.placeholderSearchGames') as string}
        />
        <ShowProvidersButton
          ref={providersToggle}
          onClick={handleProviderSwitchClick}
          isOpen={showProviders}
        />
      </div>
      {showProviders && (
        <ProvidersSearchAbsolute
          providerSearchRef={providerSearchRef}
          providersSearchValue={providersSearchValue}
          toggleShowProviders={toggleShowProviders}
          showProviders={showProviders}
          setProvidersSearchValue={setProvidersSearchValue}
          setGamesSearchValue={setGamesSearchValue}
          onChange={onChange}
        />
      )}
    </div>
  );
};
