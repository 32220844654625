import { SOFTSWISS_FRAME_DOMAIN } from 'src/pages/SportSwiss/config';
import { getFrameUrlFromInAppPathname } from 'src/pages/SportSwiss/utils/getFrameUrlFromInAppPathname';
import { getLanguageForSoftSwissFrame } from 'src/pages/SportSwiss/utils/getLanguageForSoftSwissFrame';

export const prepareSportUrl = (token: string | undefined, appLanguage: string) => {
  const inFramePath = getFrameUrlFromInAppPathname(location.pathname);

  const languageToUse = getLanguageForSoftSwissFrame(appLanguage);

  const frameUrl = new URL(languageToUse + inFramePath, SOFTSWISS_FRAME_DOMAIN);
  frameUrl.search = location.search;

  if (token) {
    frameUrl.searchParams.append('session_request_id', token);
  }

  return frameUrl.toString();
};
