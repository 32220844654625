import { Language, SupportedLanguages } from '../types';
import {
  AuLang,
  DeLang,
  EnLang,
  EsLang,
  FrLang,
  GrLang,
  PtLang,
  RoLang,
  SlLang
} from 'src/components/svg';

export const SUPPORTED_LANGUAGES: SupportedLanguages = {
  en: 'en',
  gb: 'gb',
  es: 'es',
  fr: 'fr',
  el: 'el',
  de: 'de',
  pt: 'pt',
  au: 'au',
  sl: 'sl',
  ro: 'ro'
};

export const languageOptions: Record<keyof SupportedLanguages, Language> = {
  en: {
    icon: <EnLang />,
    labelCode: 'en',
    labelName: 'English',
    currency: 'EUR',
    code: 'en-EU'
  },
  gb: {
    icon: <EnLang />,
    labelCode: 'en',
    labelName: 'English',
    currency: 'GBR',
    code: 'en-GB'
  },
  es: {
    icon: <EsLang />,
    labelCode: 'es',
    labelName: 'Spanish',
    currency: 'EUR',
    code: 'es-ES'
  },
  fr: {
    icon: <FrLang />,
    labelCode: 'fr',
    labelName: 'French',
    currency: 'EUR',
    code: 'fr-FR'
  },
  sl: {
    icon: <SlLang />,
    labelCode: 'sl',
    labelName: 'Slovenian',
    currency: 'EUR',
    code: 'sl-SL'
  },
  el: {
    icon: <GrLang />,
    labelCode: 'el',
    labelName: 'Greek',
    currency: 'EUR',
    code: 'el-EL'
  },
  de: {
    icon: <DeLang />,
    labelCode: 'de',
    labelName: 'German',
    currency: 'EUR',
    code: 'de-DE'
  },
  pt: {
    icon: <PtLang />,
    labelCode: 'pt',
    labelName: 'Portuguese',
    currency: 'EUR',
    code: 'pt-PT'
  },
  au: {
    icon: <AuLang />,
    labelCode: 'au',
    labelName: 'English-AU',
    currency: 'EUR',
    code: 'en-AU'
  },
  ro: {
    icon: <RoLang />,
    labelCode: 'ro',
    labelName: 'Romanian',
    currency: 'EUR',
    code: 'ro-RO'
  }
};

export const ARRAY_LANGUAGES: Language[] = Object.values(languageOptions);
