import { PropsWithChildren, memo, useCallback } from 'react';
import { useWindowSize } from 'react-easyrocket';
import { twMerge } from 'tailwind-merge';
import CloseModal from './CloseModal';
import { useModalContext } from 'src/hooks/useModalContext';

function ModalWrapper({
  onClose,
  children,
  className,
  containerClassName,
  isTransparentBg,
  showCross = true
}: PropsWithChildren<{
  onClose?: () => void;
  className?: string;
  containerClassName?: string;
  isTransparentBg?: boolean;
  showCross?: boolean;
}>) {
  const { openedModal, modalProps, closeModal } = useModalContext<{
    onClose?: () => void;
  }>();
  const { isMobileLayout, isLandscape } = useWindowSize();

  const handleClose = useCallback(() => {
    closeModal(); // основная функция которая закрывает модальное окно в контексте модальных окон
    onClose?.(); // функция переданная из компонента конкретного модального окна
    modalProps?.onClose?.(); // функция-коллбэк переданная в момент открытия модального окна
  }, [closeModal, modalProps, onClose]);

  return (
    <div
      data-role="blackBackgroundFullscreenPlaceholder"
      className={twMerge(
        'flex items-center justify-center',
        'left-0 top-0 fixed z-[900] w-screen h-full max-h-screen bg-m_bg_shadowing',
        openedModal ? 'translate-y-[0px]' : 'translate-y-[-100%]'
      )}>
      <div
        className={twMerge(
          'flex flex-col items-end max-w-[90%] max-h-[90%] overflow-y-hidden rounded-b-[20px]',
          isLandscape && isMobileLayout && 'flex-row-reverse items-start h-full',
          className
        )}>
        {showCross && (
          <CloseModal onClose={handleClose} className="flex-shrink-0 mb-[8px] ml-[8px]" />
        )}
        <div
          key="modalContentWrapper"
          data-role="modalContentWrapper"
          className={twMerge(
            'modal-wrapper max-h-full w-full h-auto overflow-y-auto overflow-x-hidden rounded-[20px] self-center',
            !isTransparentBg ? 'bg-m_main_bg shadow-xl' : 'bg-opacity-0',
            containerClassName
          )}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default memo(ModalWrapper);
