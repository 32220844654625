import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GPTWheelOfFortuneButtonProps } from '../../';
import { useModalContext } from 'src/hooks/useModalContext';
import { twJoin } from 'tailwind-merge';
import { useGetWheelOfFortuneState } from 'react-easyrocket';
import { WheelArrow } from '../../icons/WheelArrow';
import { WheelIcon } from '../../icons/WheelIcon';

export const GPTWheelOfFortuneButtonNonAuth: FC<GPTWheelOfFortuneButtonProps> = ({ compact }) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const { data } = useGetWheelOfFortuneState({});

  if (data?.status !== 204) return null;

  const handleButtonClick = () => {
    openModal('SIGNUP', { props: { hideWhyNot: true } });
  };

  return (
    <button
      className={twJoin(
        'w-full flex items-center gap-2 text-[18px] font-bold relative opacity-50',
        compact && 'justify-center'
      )}
      onClick={handleButtonClick}>
      <div className="relative">
        <WheelArrow className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[3px] z-10" />
        <WheelIcon isDisabled />
      </div>
      {!compact && t('menuItem.wheelOfFortune')}
    </button>
  );
};
