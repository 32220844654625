import { useGetSinglePage } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { CONTENT_PAGES } from 'src/constants';
import ModalWrapper from '../ModalWrapper';

export const IosSetupModal = () => {
  const { t } = useTranslation();

  const { data } = useGetSinglePage(CONTENT_PAGES.IOS);

  return (
    <ModalWrapper>
      <div className="p-[20px] h-full min-w-[550px] max-w-[550px] jsm:min-w-full">
        <p className="w-full font-bold text-[20px] mb-[40px]">{data?.title || ''}</p>
        <div
          dangerouslySetInnerHTML={{ __html: data?.content || `${t('apiErrors.not_response')}` }}
          className="text-[14px] font-medium cms-dropdown"></div>
      </div>
    </ModalWrapper>
  );
};
