import { ModalMapKeys } from 'src/newUseModalContext/constants';
import { SidebarTooltip, Tooltips } from './SidebarTooltip';
import { CollapsedSidebarLogin, CollapsedSidebarSignUp } from 'src/components/svg';
import { useModalContext } from 'src/hooks/useModalContext';
import { useTranslation } from 'react-i18next';

type LoginData = {
  id: string;
  tooltipText: string;
  icon: React.ReactNode;
  modalToOpen: ModalMapKeys;
  tooltip: Tooltips;
};

const loginData: LoginData[] = [
  {
    id: 'signup',
    tooltipText: 'general.signUp',
    icon: <CollapsedSidebarSignUp />,
    modalToOpen: 'SIGNUP',
    tooltip: Tooltips.SIGNUP
  },
  {
    id: 'login',
    tooltipText: 'general.logIn',
    icon: <CollapsedSidebarLogin />,
    modalToOpen: 'LOGIN',
    tooltip: Tooltips.LOGIN
  }
];

export function AuthButtonsWithTooltips({
  handleActiveTooltip,
  clearTooltipState,
  activeTooltip
}: {
  handleActiveTooltip: (value: Tooltips) => void;
  clearTooltipState: () => void;
  activeTooltip: Tooltips;
}) {
  const { openModal } = useModalContext();
  const { t } = useTranslation();
  return (
    <>
      {loginData.map(({ modalToOpen, tooltip, tooltipText, id, icon }) => (
        <SidebarTooltip
          key={id}
          id={id}
          mouseEnterFunction={() => handleActiveTooltip(tooltip)}
          mouseLeaveFunction={clearTooltipState}
          containerClickFunction={() => {
            openModal(modalToOpen);
          }}
          showTooltip={activeTooltip === tooltip}
          tooltipContent={t(tooltipText)}>
          {icon}
        </SidebarTooltip>
      ))}
    </>
  );
}
