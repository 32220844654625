import { FC } from 'react';
import { BlockTournaments } from './components/BlockTournaments';
import { BlockDeposit } from './components/BlockDeposit';
import { TournamentsCarousel } from 'src/pages/Main/components/TournamentsCarousel/TournamentsCarousel';
import { GamesControlPanel } from 'src/components/GamesControlPanel';
import { GamesBlock } from 'src/components/GamesBlock';
import { useWindowSize } from 'react-easyrocket';
import MainSlider from 'src/pages/Main/components/MainSlider';
import Stories from 'src/pages/Main/components/Stories/Stories';

export const MainPage: FC = () => {
  const { isDekstopLayout, isMobileLayout, is568 } = useWindowSize();
  return (
    <>
      {!is568 && <Stories />}
      <div className="flex flex-col w-full">
        <MainSlider />
        <div className="flex flex-col w-full">
          {isDekstopLayout && (
            <div className="px-5">
              <GamesControlPanel target="globalSearch" />
            </div>
          )}
          <GamesBlock categoryId={'top'} />
          <BlockDeposit />
          <GamesBlock categoryId={'live'} />
          <GamesBlock categoryId={'new'} />
          {isMobileLayout && <BlockTournaments />}
          {isDekstopLayout && <TournamentsCarousel />}
        </div>
      </div>
    </>
  );
};
