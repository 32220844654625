import { memo } from 'react';
import { FooterWave } from '../svg';
import { LanguagesSelect } from '../LanguagesSelect';
import { PaymentsCards } from './components/PaymentsCards';
import { Separator } from '../Separator/Separator';
import { LinkList } from './components/LinkList';
import { LicenseBlock } from './components/LicenseBlock';

export const Footer = memo(() => {
  return (
    <footer>
      <FooterWave />
      <div className="flex flex-col w-full items-center justify-center text-text-secondary relative bg-bg-footer pb-[70px] px-5 top-[-2px] 768:top-0">
        <div className="max-w-[1250px]">
          <LinkList>
            <LanguagesSelect containerClassname="!my-0 h-fit" />
          </LinkList>
          <PaymentsCards />
          <Separator width="w-full" />
          <LicenseBlock />
        </div>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
