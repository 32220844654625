import { FC, SVGProps } from 'react';

export const SlotsMobile: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="white"
    {...props}>
    <path d="M22.4141 3H19.2761C18.9525 3 18.6901 3.26234 18.6901 3.58594C18.6901 3.95496 18.3898 4.25523 18.0208 4.25523C15.6412 4.25523 14.3739 3 11.7448 3C10.0897 3 8.53398 3.44441 7.30988 4.24531V3.58594C7.30988 3.26234 7.04754 3 6.72395 3H3.58594C3.26234 3 3 3.26234 3 3.58594V11.1172C3 11.4408 3.26234 11.7031 3.58594 11.7031H6.72395C7.04754 11.7031 7.30988 11.4408 7.30988 11.1172C7.30988 9.71 8.45477 8.56512 9.86199 8.56512C11.1829 8.56512 12.6064 9.1927 14.2552 9.1927C14.6662 9.1927 15.0558 9.16844 15.4237 9.12008L13.8409 10.7029C10.6364 13.9073 7.39324 16.5263 7.39324 22.4141C7.39324 22.7377 7.65559 23 7.97918 23H15.5104C15.834 23 16.0964 22.7377 16.0964 22.4141C16.0964 16.5575 18.2363 13.4373 20.1243 10.6845C21.6032 8.52832 23 6.49176 23 3.58594C23 3.26234 22.7377 3 22.4141 3ZM19.158 10.0217C17.0291 13.1256 15.0658 16.2327 14.9314 21.8281H8.57711C8.78395 16.8578 11.6252 14.5762 14.6695 11.5315L18.4321 7.76887C18.4332 7.76781 18.4343 7.76672 18.4354 7.76562C18.664 7.53676 18.6639 7.16586 18.435 6.93711C18.2062 6.70844 17.8352 6.70848 17.6065 6.93727L17.6039 6.9398C16.8859 7.65707 15.7592 8.02082 14.2552 8.02082C12.7729 8.02082 11.3512 7.39324 9.86195 7.39324C8.00781 7.39324 6.46586 8.75516 6.18402 10.5312H4.17188V4.17188H6.13801V5.46875C6.13801 5.70574 6.28074 5.91937 6.49973 6.01008C6.71875 6.10074 6.97074 6.0507 7.13828 5.88305C8.22574 4.79559 9.90477 4.17188 11.7448 4.17188C14.1261 4.17188 15.3903 5.42711 18.0208 5.42711C18.8313 5.42711 19.5211 4.90074 19.7664 4.17188H21.8057C21.6325 6.41363 20.4782 8.09676 19.158 10.0217Z" />
  </svg>
);
