import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';
forwardRef;
export const WheelIcon: ForwardRefExoticComponent<
  RefAttributes<SVGSVGElement> & { isDisabled?: boolean }
> = forwardRef(({ isDisabled, ...props }, ref) => (
  <svg
    id="Wheel"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 503.6 503.6"
    fill="#fff"
    width={24}
    height={24}
    ref={ref}
    strokeWidth={0}
    {...props}
    className={twJoin(
      !isDisabled && 'group-hover:[&>path]:fill-yellow group-hover:[&>circle]:fill-yellow'
    )}>
    <path d="M443,226.6l-115.4,20.9c.6,10.2-.9,20.2-4.3,29.9-.5,1.3-1,2.5-1.5,3.8l101.8,58.1c3.7-7.2,6.9-14.7,9.7-22.5,10.5-29.4,13.7-60.2,9.7-90.2h0Z" />
    <path d="M175.9,248.3l-115.6-19.8c-4.4,35.8,1.3,72.2,17.1,105.6,1.1,2.3,2.2,4.6,3.4,6.8l101.2-59.1c-4.6-10.6-6.7-22.1-6.2-33.6h0Z" />
    <path d="M191.4,297.8l-101.2,59.1c21.2,32.7,51.6,58,87.9,73l39.6-110.3c-10.3-5.2-19.2-12.6-26.2-21.8Z" />
    <path d="M382,109.5l-75,90.2c7.9,8.4,13.8,18.5,17.2,29.6l115.4-20.9c-3.1-13.3-7.6-26.3-13.6-38.9-10.9-23-25.9-43.2-44.1-59.9h0Z" />
    <path d="M312.6,297.3c-6.9,9.2-15.8,16.7-26.1,22l40.6,109.9c12.1-5.1,23.6-11.5,34.5-19,21.3-14.7,39.1-33.3,52.8-54.8l-101.8-58.1h0Z" />
    <path d="M235.1,325.8l-39.5,110.3c18.3,5.6,37.2,8.5,56.3,8.5s39-3,57.7-8.9c0,0,.2,0,.3,0l-40.6-110c-5.7,1.4-11.6,2-17.4,2s-11.2-.6-16.7-1.9h0Z" />
    <path d="M141.9,93.3c-2.6,1.8-5.2,3.7-7.7,5.6l75.8,89.4c9.7-6.4,20.6-10.4,32.1-11.9l-.6-117.3c-16.1.9-32,3.8-47.5,8.6-18.6,5.8-36.1,14.4-52.1,25.5h0Z" />
    <path d="M63.5,210.3l115.5,19.8c.4-1.3.8-2.6,1.3-3.9,3.5-9.7,8.7-18.4,15.6-25.8l-75.8-89.4c-22.3,20.8-39.3,46.6-49.8,75.8-2.8,7.8-5,15.6-6.8,23.5h0Z" />
    <path d="M316.8,70.3c-18.4-6.6-37.5-10.4-56.8-11.2l.6,117.2c5.7.7,11.4,2,16.8,3.9s10.6,4.5,15.5,7.6l74.9-90.2c-15.3-11.5-32.4-20.8-51-27.4h0Z" />
    <circle cx="251.8" cy="251.8" r="54.3" />
    <path d="M251.8,40c23.7,0,47.8,4,71.4,12.5,110.1,39.4,167.3,160.7,127.9,270.7-31,86.5-112.4,140.3-199.3,140.3s-47.8-4-71.4-12.5C70.3,411.6,13,290.4,52.5,180.3,83.5,93.9,164.9,40,251.8,40M251.8,0h0c-25.6,0-51,3.9-75.4,11.6-24.2,7.6-47.1,18.8-67.9,33.2-43.4,30-75.8,72.2-93.6,122C3.4,198.8-1.5,232.1.4,265.8c1.8,32.5,9.8,64,23.8,93.5,13.9,29.5,33.2,55.7,57.1,77.8,24.8,22.9,53.6,40.2,85.6,51.7,27.4,9.8,56,14.8,84.9,14.8s51-3.9,75.4-11.6c24.2-7.6,47.1-18.8,67.9-33.2,43.4-30,75.8-72.2,93.6-122,11.5-32,16.3-65.3,14.4-98.9-1.8-32.5-9.8-64-23.8-93.5-14-29.6-33.2-55.7-57.1-77.8-24.8-22.8-53.6-40.2-85.6-51.7C309.3,5,280.7,0,251.8,0h0Z" />
  </svg>
));

WheelIcon.displayName = 'WheelIcon';
