import { useEffect } from 'react';
import { useApiContext } from 'react-easyrocket';
import { useLocation } from 'react-router-dom';
import { getLanguageFromLS, getUserIdFromLS } from 'src/utils';
import { smarticoSetUserData } from 'src/utils/smartico';

export const useSetSmarticoUser = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useApiContext();

  useEffect(() => {
    if (isAuthenticated) {
      smarticoSetUserData(getUserIdFromLS(), getLanguageFromLS());
    }
  }, [pathname, isAuthenticated]);
};
