import { useCallback, useEffect } from 'react';
import { useApiContext } from 'react-easyrocket';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useModalContext } from 'src/hooks/useModalContext';
import { useNavigateLng } from 'src/lib';

export const OpenSingnUpByLink = () => {
  const { openModal } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigateLng();
  const location = useLocation();
  const clearUrl = useCallback(() => {
    navigate(`${location.pathname}`);
  }, [location.pathname, navigate]);
  useEffect(() => {
    const haveAction = searchParams.get('action');
    if (haveAction && !isAuthenticated) {
      openModal('SIGNUP');
    }
    haveAction && clearUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, searchParams]);
  return null;
};
