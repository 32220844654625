import { MESSAGE_STATUS, useApiContext } from 'react-easyrocket';
import { useSearchParams } from 'react-router-dom';
import { NavigateLng } from 'src/lib';
import { useMessage } from 'src/modules/messages';

export function SocialSignUpHandler() {
  const { saveSession } = useApiContext();
  const [searchParams] = useSearchParams();
  const { openMessage } = useMessage();

  const status = searchParams.get('status');
  const message = searchParams.get('message');
  const uuid = searchParams.get('session_uuid');

  status === 'error'
    ? openMessage({
        type: 'USER_MESSAGE',
        props: { text: message || '', type: MESSAGE_STATUS.ERROR },
        saveToHistory: false
      })
    : saveSession(uuid);
  return <NavigateLng to="/" />;
}
