import { useDrawer } from './useDrawer';
import { useModalContext } from './useModalContext';
import { useCallback, useEffect, useState } from 'react';
import { useNavigateLng } from 'src/lib';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
  handleClick?: () => void,
  promptable?: IBeforeInstallPromptEvent | null,
  promptToInstall?: () => void,
  isInstalled?: boolean,
  isIos?: boolean
] {
  const navigate = useNavigateLng();
  const { openModal } = useModalContext();
  const { closeLeft } = useDrawer();

  const [promptable, setPromptable] = useState<IBeforeInstallPromptEvent | null>(null);

  const [isInstalled, setIsInstalled] = useState<boolean>(false);

  const [isIos, setIsIos] = useState<boolean>(false);

  const promptToInstall = useCallback(() => {
    if (promptable) {
      return promptable.prompt();
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    );
  }, [promptable]);

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptable(e);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  useEffect(() => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIsIos(true);
    } else setIsIos(false);

    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener('appinstalled', onInstall as any);

    return () => {
      window.removeEventListener('appinstalled', onInstall as any);
    };
  }, []);

  const handleClick = useCallback(() => {
    if (!promptable && !isIos) {
      navigate('/pwa-instruction');
      closeLeft();
    } else if (isIos) {
      openModal('IOSSETUP');
      closeLeft();
    } else {
      !isInstalled && promptable && promptToInstall?.();
      closeLeft();
      navigate('/pwa-instruction');
    }
  }, [closeLeft, isInstalled, isIos, navigate, openModal, promptToInstall, promptable]);

  return [handleClick, promptable, promptToInstall, isInstalled, isIos];
}
