import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { useFreeSpins } from 'src/hooks/useFreeSpins';
import { useMemo } from 'react';
import { getCurrencyIcon } from 'src/utils';
import { GameLoading } from '../GameCard/GameLoading';
import { GameCard } from '../GameCard';
import { makeFloat, useGetCombinedGames, useGetShortBalance } from 'react-easyrocket';
import ModalWrapper from '../ModalWrapper';
import { useModalContext } from 'src/hooks/useModalContext';

export const PopUpFreeSpins = () => {
  const { t } = useTranslation();
  const { oldFreeSpins: freeSpins, setFreeSpinsChanged } = useFreeSpins();
  const { data } = useGetShortBalance();
  const { closeModal } = useModalContext();

  const { allGames: games, isFetching } = useGetCombinedGames(
    { per_page: 6 },
    {
      categoryId: 'freespins',
      target: 'games-with-freespins'
    }
  );

  const currencySing = useMemo(() => {
    return data?.currency ? getCurrencyIcon(data?.currency) : '';
  }, [data?.currency]);

  const number = useMemo(() => {
    return freeSpins?.number ? `${freeSpins?.number}` : '--';
  }, [freeSpins?.number]);

  const dayLimit = useMemo(() => {
    return freeSpins?.dayLimit ? `${freeSpins?.dayLimit}` : '--';
  }, [freeSpins?.dayLimit]);

  const betAmount = useMemo(() => {
    return freeSpins?.betAmount ? `${currencySing} ${makeFloat(freeSpins?.betAmount)}` : '--';
  }, [currencySing, freeSpins?.betAmount]);

  const numberDay = useMemo(() => {
    return freeSpins?.numberDay ? `${freeSpins?.numberDay}` : '--';
  }, [freeSpins?.numberDay]);

  const closeFreeSpinsPopUp = () => {
    closeModal();
    setFreeSpinsChanged?.(false);
  };

  return (
    <ModalWrapper onClose={() => setFreeSpinsChanged(false)}>
      <div className=" flex flex-col gap-9 p-[24px] w-[400px] jmid:w-full">
        <div className="flex flex-col gap-7 items-center w-full">
          <div className="">
            <p className="font-bold text-2xl text-promoCodeTitle">{t('freeSpins.freeSpins')}</p>
          </div>
          <div className="flex flex-col justify-between h-auto gap-2.5 w-full jsm:text-end">
            <div className="flex items-center justify-between gap-20">
              <p className="text-sm font-bold w-1/2 jsm:text-xs">{t('freeSpins.amount')}:</p>
              <p className="whitespace-nowrap text-lg font-bold text-text-strong flex-grow jsm:text-sm">
                {number}
              </p>
            </div>
            <div className="flex items-center justify-between gap-20">
              <p className="text-sm font-bold w-1/2 jsm:text-xs">{t('freeSpins.limitDay')}:</p>
              <p className="text-lg whitespace-nowrap font-bold text-text-strong flex-grow jsm:text-sm">
                {dayLimit}
              </p>
            </div>
            <div className="flex items-center justify-between gap-20">
              <p className="text-sm font-bold w-1/2 jsm:text-xs">{t('freeSpins.betAmount')}:</p>
              <p className="text-lg whitespace-nowrap font-bold text-text-strong flex-grow jsm:text-sm">
                {betAmount}
              </p>
            </div>
            <div className="flex items-center justify-between gap-20">
              <p className="text-sm font-bold w-1/2 jsm:text-xs">{t('freeSpins.leftForDay')}:</p>
              <p className="text-lg whitespace-nowrap font-bold text-text-strong flex-grow jsm:text-sm">
                {numberDay}
              </p>
            </div>
          </div>
          {games.length > 0 && (
            <div className="flex flex-col gap-3 w-full">
              <div className="ml-3">
                <p className="font-bold text-base py-0.5 text-center">
                  {t('freeSpins.gameFreeSpins')}
                </p>
              </div>
              <div className="grid grid-cols-2 412:grid-cols-3 gap-x-2 gap-y-1 jsm:gap-x-1">
                {isFetching ? (
                  <GameLoading count={3} />
                ) : (
                  games.map((game) => (
                    <GameCard game={game} key={game.id} onGameClick={closeFreeSpinsPopUp} />
                  ))
                )}
              </div>
            </div>
          )}
          <Button
            title={`${t('general.accept')}`}
            mode="button-secondary"
            onClick={closeFreeSpinsPopUp}
            label={`${t('general.accept')}`}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
