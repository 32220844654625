import { useEffect } from 'react';

export const useScrollButton = (
  hideButton: () => void,
  showButton: () => void,
  pathname: string
) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      if (documentHeight - scrollPosition <= windowHeight + 600) {
        showButton();
      } else {
        hideButton();
      }
    };

    window.addEventListener('scroll', handleScroll);

    hideButton();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hideButton, showButton, pathname]);
};
