import 'swiper/css';
import 'swiper/css/pagination';

import { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { Slide } from './components/Slide';
import { useGetSlides } from './hooks/useGetSlides';
import { SkeletonOfAnySize } from 'src/components/Skeletons';
import { useBoolean } from 'react-easyrocket';

const MainSlider = () => {
  const [isSliderControls, enableSliderControls, disableSliderControls] = useBoolean(false);
  const { slides, isLoading } = useGetSlides();

  return (
    <div
      className="w-full px-4"
      onMouseEnter={enableSliderControls}
      onMouseLeave={disableSliderControls}>
      <Swiper
        className="custom-swiper-controls main-slider min-w-full pt-4 pb-6"
        slidesPerView="auto"
        initialSlide={0}
        breakpoints={{
          568: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        }}
        navigation={slides.length > 3 && isSliderControls}
        modules={[Navigation, Pagination, Autoplay]}
        rewind={true}
        pagination={{
          clickable: true
        }}>
        <>
          {isLoading &&
            slides.length === 0 &&
            Array.from(new Array(3)).map((_, i) => (
              <SwiperSlide key={i} className="!flex h-full w-full justify-center">
                <SkeletonOfAnySize className="h-[407px] w-[clamp(96%,98%,374px)] max-w-[374px]" />
              </SwiperSlide>
            ))}
          {slides.map((slide) => {
            return (
              <SwiperSlide key={slide.code} className="!flex h-full w-full justify-center">
                <Slide slide={slide} />
              </SwiperSlide>
            );
          })}
        </>
      </Swiper>
    </div>
  );
};

export default memo(MainSlider);
