import { PropsWithChildren, createContext, useContext } from 'react';
import { useBoolean } from 'react-easyrocket';

export const DrawerContext = createContext<{
  isMobileAppPopupOpened: boolean;
  openMobileAppSidebar: () => void;
  closeMobileAppSidebar: () => void;
  toggleMobileAppSidebar: () => void;
  isTopDrawerOpen: boolean;
  toggleTopDrawer: () => void;
  openTopDrawer: () => void;
  closeTopDrawer: () => void;
  isProvidersSearchOpen: boolean;
  toggleProvidersSearch: () => void;
  openProvidersSearch: () => void;
  closeProvidersSearch: () => void;
  isTopSearchDrawerOpen: boolean;
  toggleIsTopSearchDrawerOpen: () => void;
  openIsTopSearchDrawerOpen: () => void;
  closeIsTopSearchDrawerOpen: () => void;
  isHeaderGameDrawerOpen: boolean;
  toggleHeaderGameDrawerOpenDrawer: () => void;
  openHeaderGameDrawerOpenDrawer: () => void;
  closeHeaderGameDrawerOpenDrawer: () => void;
  isLeftOpen: boolean;
  openLeft: () => void;
  closeLeft: () => void;
  toggleLeft: () => void;
  isCookiesOpen: boolean;
  openCookies: () => void;
  closeCookies: () => void;
  isNotificationsOpen: boolean;
  openNotifications: () => void;
  closeNotifications: () => void;
  toggleNotifications: () => void;
}>({} as ReturnType<typeof useInitDrawer>);

export const useInitDrawer = () => {
  const [isLeftOpen, openLeft, closeLeft, toggleLeft] = useBoolean(false);
  const [isCookiesOpen, openCookies, closeCookies] = useBoolean(false);

  const [isNotificationsOpen, openNotifications, closeNotifications, toggleNotifications] =
    useBoolean(false);

  const [isTopDrawerOpen, openTopDrawer, closeTopDrawer, toggleTopDrawer] = useBoolean();
  const [isProvidersSearchOpen, openProvidersSearch, closeProvidersSearch, toggleProvidersSearch] =
    useBoolean();
  const [
    isTopSearchDrawerOpen,
    openIsTopSearchDrawerOpen,
    closeIsTopSearchDrawerOpen,
    toggleIsTopSearchDrawerOpen
  ] = useBoolean();
  const [
    isHeaderGameDrawerOpen,
    toggleHeaderGameDrawerOpenDrawer,
    openHeaderGameDrawerOpenDrawer,
    closeHeaderGameDrawerOpenDrawer
  ] = useBoolean();

  const [
    isMobileAppPopupOpened,
    openMobileAppSidebar,
    closeMobileAppSidebar,
    toggleMobileAppSidebar
  ] = useBoolean();

  return {
    isMobileAppPopupOpened,
    openMobileAppSidebar,
    closeMobileAppSidebar,
    toggleMobileAppSidebar,
    isTopDrawerOpen,
    toggleTopDrawer,
    openTopDrawer,
    closeTopDrawer,
    isProvidersSearchOpen,
    toggleProvidersSearch,
    openProvidersSearch,
    closeProvidersSearch,
    isTopSearchDrawerOpen,
    toggleIsTopSearchDrawerOpen,
    openIsTopSearchDrawerOpen,
    closeIsTopSearchDrawerOpen,
    isHeaderGameDrawerOpen,
    toggleHeaderGameDrawerOpenDrawer,
    openHeaderGameDrawerOpenDrawer,
    closeHeaderGameDrawerOpenDrawer,
    isLeftOpen,
    openLeft,
    closeLeft,
    toggleLeft,
    isCookiesOpen,
    openCookies,
    closeCookies,
    isNotificationsOpen,
    toggleNotifications,
    openNotifications,
    closeNotifications
  };
};

export const DrawerProvider = ({ children }: PropsWithChildren) => {
  const values = useInitDrawer();
  return <DrawerContext.Provider value={values}>{children}</DrawerContext.Provider>;
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};
