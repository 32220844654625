import { MessageFromFrame } from '../types';
import { checkIsValidMessage } from './checkIsValidMessage';

/**
 * Функция, выполняющая соответствующий сценарий для сообщений с юнити клиента при успешной валидации сообщения
 * @param messagesHandler - коллбэк соответствующего сценария
 * @param options - объект, содержащий messageEvent(ивент сообщения) и gameFrame(ссылку на iframe с игрой),
 * параметр options необходим для валидации
 */
export const executeMessageScenarios = (
  messagesHandler: (messageFromFrame: MessageFromFrame) => void,
  options: { messageEvent: MessageEvent<MessageFromFrame>; gameFrame: HTMLIFrameElement | null }
) => {
  const { messageEvent, gameFrame } = options;
  const messageFromFrame = messageEvent.data;

  const isValidMessage = checkIsValidMessage({
    gameName: messageFromFrame.gameName,
    messageOrigin: messageEvent.origin
  });

  if (gameFrame && isValidMessage) {
    messagesHandler(messageFromFrame);
  }
};
