type EcommerceGtmDataLayerFieldsType = {
  transaction_id: number; // The unique identifier of a transaction.
  value: number; // The monetary value of the event.
  currency: string; // Currency of the items associated with the event, in 3-letter ISO 4217 format.
};

const generateAnyGtmScriptEvent =
  (event: string) => (args: { [key: string]: string | unknown }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...args
    });
  };

export const pushAddPaymentInfoToGtmDataLayer = (data: EcommerceGtmDataLayerFieldsType) =>
  generateAnyGtmScriptEvent('add_payment_info')({
    ecommerce: { ...data, items: [{ item_name: 'deposit' }] }
  });
