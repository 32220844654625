import { PropsWithChildren, memo } from 'react';
import { useBackgroundScrollLock } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';

function FullScreenMobileModalWrapper({
  className,
  children
}: PropsWithChildren<{
  className?: string;
}>) {
  useBackgroundScrollLock(true, []);
  return (
    <div
      className={twJoin(
        'fixed z-[900] bg-m_main_bg top-0 bottom-0 left-0 right-0 overflow-auto',
        className
      )}>
      {children}
    </div>
  );
}

export default memo(FullScreenMobileModalWrapper);
