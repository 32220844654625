import {
  MESSAGE_STATUS,
  useApplyTournament,
  useCurrency,
  useGetLocalTournament
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import sandClockIcon from 'src/assets/images/sandClock.webp';
import tournamentCup from 'src/assets/images/winnersCup.webp';
import { useCountdownTimer } from 'src/hooks/useCountdownTimer';
import { useModalContext } from 'src/hooks/useModalContext';
import { Button } from '../Buttons/Button';
import { PrizeTournament } from '../PrizeTournament';
import { TournamentPlace } from '../TournamentPlace/TournamentPlace';
import { memo } from 'react';
import ModalWrapper from '../ModalWrapper';
import { useMessage } from 'src/modules/messages';

const GameTournament = () => {
  const { modalProps, closeModal } = useModalContext<{
    tournamentId: number;
    handleParticipate: (value: boolean) => void;
  }>();
  const { t } = useTranslation();
  const { openMessage, closeMessage } = useMessage();
  const { currencyName } = useCurrency();
  const { tournament } = useGetLocalTournament(
    {
      tournament_id: modalProps?.tournamentId || 0
    },
    {
      enabled: Boolean(modalProps?.tournamentId)
    }
  );
  const { openModal } = useModalContext();

  const { dateEnd, name, descriptionShort, places } = tournament || {};

  const timeLeft = useCountdownTimer(dateEnd);
  const { mutateAsync: applyForTournament, isLoading: applyTournamentLoading } = useApplyTournament(
    {
      onSuccess: () => {
        closeModal();
        modalProps?.handleParticipate?.(true);
        openMessage({
          type: 'USER_MESSAGE',
          props: {
            text: `${t('general.successfulRegistration')}`,
            type: MESSAGE_STATUS.SUCCESS,
            saveToHistory: false
          }
        });
      }
    }
  );

  const handleButtonParticipateClick = () => {
    modalProps?.tournamentId &&
      applyForTournament(
        { tournament_id: modalProps?.tournamentId },
        {
          onError: (error) => {
            if (error.response?.data && error.response?.data?.code === 16) {
              closeMessage?.(error.response.data.message || '');
              closeModal();
              openModal('TOURNAMENT_LOW_LEVEL');
            }
          }
        }
      );
  };

  return (
    <ModalWrapper>
      <div className="max-w-full 768:w-[460px] 412:w-[350px] py-[30px] px-[10px] gap-[30px] text-white flex flex-col items-center bg-LOCAL_TOURNAMET_MODAL_BG">
        <p className="text-[28px] font-black text-center">{name}</p>

        <Button
          onClick={handleButtonParticipateClick}
          loading={applyTournamentLoading}
          title={`${t('general.participate')}`}
          mode="button-secondary"
          className="text-text-primary lowercase text-[18px] w-[200px]">
          {t('general.participate')}
        </Button>

        <div className="max-w-full 568:max-w-[320px] w-full flex flex-col gap-[30px] items-center">
          <div
            className="w-full shadow-tournamentCard bg-bg-big-winner-in-recent-winners flex items-center gap-[18px] border-2 border-yellow rounded-lg px-[18px] min-h-[60px]"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.20)'
            }}>
            <img src={tournamentCup} className="w-[40px] h-[40px]" alt="tournament cup" />

            <div className="font-bold">
              <p className="text-[14px]">{t('jackpots.totalJackpot')}</p>
              {tournament && (
                <div className="text-yellow text-[23px] leading-[20px] flex flex-wrap">
                  <PrizeTournament tournament={tournament} currency={currencyName} />
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex shadow-tournamentCard bg-bg-big-winner-in-recent-winners items-center gap-[18px] border-2 border-yellow rounded-lg px-[18px] h-[60px]">
            <img src={sandClockIcon} alt="clock" className="w-[25px]" />

            <div className="w-full flex items-center divide-x-1 divide-solid divide-active-step mx-[-12px]">
              {Object.entries(timeLeft).map(([date, value]) => {
                return (
                  <div
                    className="flex flex-col font-medium items-center text-center px-[12px]"
                    key={date}>
                    <p className="text-[20px] text-active-step leading-[16px]">{value}</p>
                    <p className="text-[6px]">{t(`general.${date}`)}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <p className="text-[14px] w-full font-medium leading-[18px]">{descriptionShort}</p>

          <p className="text-yellow text-[26px] font-bold text-center first-letter:uppercase">
            {t('tournaments.tournamentPrizes')}
          </p>

          {places?.map((place, index) => (
            <TournamentPlace key={place.id} status={tournament?.status} place={place} id={index} />
          ))}
        </div>

        <Button
          onClick={handleButtonParticipateClick}
          loading={applyTournamentLoading}
          title={`${t('general.participate')}`}
          mode="button-secondary"
          className="text-text-primary lowercase text-[18px] w-[200px]">
          {t('general.participate')}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default memo(GameTournament);
