export const BlackjackLeague = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2582 8.01921C39.9904 8.63142 40.2087 9.66497 39.7868 10.521C35.0948 20.0413 37.6807 29.9733 38.4273 32.3543C38.6206 32.9709 38.5147 33.6424 38.1411 34.1696C37.7674 34.6969 37.1689 35.0192 36.5231 35.0411C29.9047 35.2651 23.4634 37.169 17.8339 40.5615C17.2941 40.8868 16.6355 40.9483 16.0448 40.7286C15.4541 40.5089 14.9957 40.0319 14.7997 39.4329C13.9627 36.8753 10.3169 27.439 1.05653 22.2422C0.22033 21.7729 -0.183864 20.7906 0.0799258 19.8687C0.343716 18.9469 1.20636 18.327 2.16421 18.3711C8.05084 18.6422 12.4033 20.963 15.4003 23.44C15.3082 22.8827 15.2162 22.3049 15.1258 21.7096C14.2853 16.177 13.5549 9.00081 14.1043 2.74643C14.1775 1.91236 14.7463 1.20467 15.545 0.95366C16.3438 0.702645 17.2151 0.957794 17.7523 1.60002C21.8114 6.45279 25.358 12.7737 27.8666 17.8032C28.1514 18.3742 28.4236 18.9302 28.6824 19.4671C29.727 15.756 31.9857 11.4231 36.7024 7.94175C37.4702 7.375 38.5261 7.407 39.2582 8.01921ZM30.13 22.5672C29.6955 25.4715 30.0104 27.5265 30.0104 27.5265C30.0104 27.5265 29.2803 25.6808 27.9997 22.8806C25.9057 18.302 22.3398 11.1714 18.0871 5.3849C17.4606 4.53242 16.8191 3.70912 16.1653 2.92745C16.0762 3.94224 16.0221 4.98488 15.9978 6.04343C15.835 13.1364 17.0088 20.9438 17.9289 25.8715C18.4972 28.9148 18.9687 30.8598 18.9687 30.8598C18.9687 30.8598 18.0413 28.9923 16.0057 26.8366C14.0657 24.782 11.1191 22.4655 7.00941 21.2544C5.51825 20.8149 3.87395 20.521 2.06905 20.4379C3.52157 21.253 4.8443 22.1635 6.04694 23.1326C11.6764 27.6685 14.6745 33.4875 16.0356 36.8161C16.3766 37.6499 16.6149 38.3275 16.766 38.7894C17.3685 38.4264 17.9797 38.0795 18.599 37.7491C23.458 35.1564 28.8144 33.5735 34.3302 33.1002C35.0355 33.0397 35.7433 32.9973 36.4531 32.9733C36.3135 32.5281 36.1111 31.8297 35.9007 30.9246C35.0909 27.4423 34.1614 20.9017 36.2266 13.9623C36.656 12.5196 37.2148 11.0597 37.931 9.60638C36.5125 10.6534 35.3404 11.7831 34.3728 12.948C31.6489 16.2276 30.546 19.7867 30.13 22.5672Z"
        fill="white"
      />
    </svg>
  );
};
