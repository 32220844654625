export const scrollIntoViewWithOffset = (item: HTMLElement | null, offset: number) => {
  if (item) {
    window.scrollTo({
      behavior: 'smooth',
      top: item.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
    });
  }
};

export const scrollIntoViewWithHeaderOffset = (item: HTMLElement | null) => {
  const headerOffset = document.querySelector<HTMLElement>('.header-container')?.offsetHeight || 0;

  scrollIntoViewWithOffset(item, headerOffset);
};
