import { useMemo } from 'react';
import { useGetSinglePage } from 'react-easyrocket';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const PageHead = () => {
  const { childs } = useGetSinglePage('seo_pages');
  const { pathname } = useLocation();

  const seoPageData = useMemo(() => {
    const path = pathname === '/' ? '/main' : pathname;
    return childs?.find((child) => {
      return path.includes(child.code);
    });
  }, [childs, pathname]);

  return (
    <Helmet>
      {seoPageData?.title && <title>{seoPageData.title}</title>}
      {seoPageData?.description && <meta name="description" content={seoPageData.description} />}
      {seoPageData && pathname && <link rel="canonical" href={pathname} />}
    </Helmet>
  );
};
