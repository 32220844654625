import { useTranslation } from 'react-i18next';
import noNotificationsStubImage from 'src/assets/images/noNotificationsStub.webp';

export const NotificationsStub = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-[44px] flex flex-col items-center">
      <h3 className="text-nb-stub-text text-base font-medium">{t('general.noNotifications')}</h3>
      <img src={noNotificationsStubImage} alt="no notifications" className="h-auto w-[164px]" />
    </div>
  );
};
