import { GAME_ORIGIN, GAME_PATH } from '../config';
import { useGameFrameMessages } from '../hooks/useGameFrameMessages';
import { ModalContainer } from './ModalContainer';
import { ModalWrapper } from './ModalWrapper';
import { Preloader } from '../config/Preloader';
import { useKillGameOnSessionEnd } from '../hooks/useKillGameOnSessionEnd';
import { ScreenRotate } from './ScreenRotate';
import { useOrientationHandler } from '../hooks/useOrientationHandler';
import { useMemo } from 'react';

export const GameModal = () => {
  const { messagesRef, isGameReady } = useGameFrameMessages();
  const { shouldFrameRender, shouldDisplayScreenRotate } = useOrientationHandler();

  const timestampParam = useMemo(() => `?timestamp=${Date.now()}`, []);
  const gameSrc = GAME_ORIGIN + GAME_PATH + timestampParam;

  useKillGameOnSessionEnd();

  return (
    <ModalContainer>
      <ModalWrapper
        isGameReady={isGameReady}
        screenRotateImage={shouldDisplayScreenRotate ? <ScreenRotate /> : null}
        preloader={<Preloader />}>
        {shouldFrameRender ? (
          <iframe className="w-full h-full z-[1]" ref={messagesRef} src={gameSrc} />
        ) : null}
      </ModalWrapper>
    </ModalContainer>
  );
};
