import { RefObject } from 'react';

export const scrollToCenter = (
  element: HTMLElement | null,
  container: RefObject<HTMLDivElement>['current']
) => {
  if (!container) return;

  const containerRect = container.getBoundingClientRect();
  if (element) {
    const elementRect = element.getBoundingClientRect();

    const containerCenterX = containerRect.left + containerRect.width / 2;
    const elementCenterX = elementRect.left + elementRect.width / 2;

    const scrollLeft = elementCenterX - containerCenterX + container.scrollLeft;
    container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  }
};
