import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const AttentionModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModalContext<{ rejectBonus: () => void }>();
  return (
    <ModalWrapper>
      <div className=" flex flex-col gap-9 p-[24px] max-w-[300px] jxsm:w-full">
        <div className="text-2xl font-bold uppercase text-center text-[#f91e4e]">
          {t('modalCancelBonus.attention')}!
        </div>
        <div className="text-lg font-bold text-center">{t('modalCancelBonus.text')}</div>
        <div className="flex flex-col gap-7 items-center w-full">
          <div className="flex items-center justify-center w-full">
            <Button
              className="button-primary"
              title={`${t('modalCancelBonus.sure')}`}
              label={`${t('modalCancelBonus.sure')}`}
              onClick={() => {
                modalProps?.rejectBonus();
                closeModal();
              }}
            />
          </div>
          <div className="flex items-center justify-center w-full">
            <Button
              title={`${t('modalCancelBonus.save')}`}
              className="btn-primary"
              onClick={closeModal}
              label={`${t('modalCancelBonus.save')}`}
            />
          </div>
          {/* <div className="col-12">
    <a className="btn " aria-label='Ask in the Support Chat'>
      <span>Ask in the Support Chat</span>
    </a>
  </div> */}
        </div>
      </div>
    </ModalWrapper>
  );
};
