import { FC, PropsWithChildren } from 'react';
import localTournamentWinnerGrandFather from 'src/assets/images/localTournamentWinnerDedushka.webp';

export const TournamentPlacesWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full rounded-[15px] bg-LOCAL_TOURNAMENT_WINNER_BG p-5 768:pl-20 flex flex-col items-center 768:flex-row 768:justify-between relative 768:min-h-[500px] bg-no-repeat bg-cover">
      <img
        className="hidden 768:block 768:w-[400px] 1024:w-[500px] mb-2.5 768:mb-0 768:mr-5 768:order-1 object-contain 768:absolute 768:right-0 768:bottom-[60px] 768:z-[1]"
        src={localTournamentWinnerGrandFather}
        alt="winners"
      />
      <div className="flex flex-col items-center w-full 768:items-start 768:justify-center gap-[30px] 768:flex-grow relative left-0 top-0 z-[2]">
        {children}
      </div>
    </div>
  );
};
