import { SVGPropsIcon } from 'src/types';

export const MoreIcon = (props: SVGPropsIcon) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10 0.9375C5.0029 0.9375 0.9375 5.0029 0.9375 10C0.9375 14.9971 5.0029 19.0625 10 19.0625C14.9971 19.0625 19.0625 14.9971 19.0625 10C19.0625 5.0029 14.9971 0.9375 10 0.9375ZM14.1919 10.4419L12.0044 12.6294C11.8825 12.7515 11.7224 12.8125 11.5625 12.8125C11.4026 12.8125 11.2425 12.7515 11.1206 12.6294C10.8765 12.3854 10.8765 11.9896 11.1206 11.7456L12.2411 10.625H6.25C5.90485 10.625 5.625 10.3452 5.625 10C5.625 9.65485 5.90485 9.375 6.25 9.375H12.2411L11.1206 8.25439C10.8765 8.01041 10.8765 7.61459 11.1206 7.37061C11.3644 7.12646 11.7606 7.12646 12.0044 7.37061L14.1919 9.55811C14.436 9.80209 14.436 10.1979 14.1919 10.4419Z"
      fill="#363636"
    />
  </svg>
);
