import { FC } from 'react';
import { useApiContext } from 'react-easyrocket';
import { DailyBonusWithUser } from './components/DailyBonusWithUser';
import { DailyBonusWithoutUser } from './components/DailyBonusWithoutUser';
import { useStatusUpdateTimer } from './hooks/useStatusUpdateTimer';
import { Skeletons } from 'src/components/Skeletons';
import { useGPTDailyBonus } from './hooks/useGPTDailyBonus';
import { twJoin } from 'tailwind-merge';
import { Tooltips } from 'src/components/Sidebar/components/SidebarTooltip';

export interface DailyBonusProps {
  compact?: boolean;
  handleActiveTooltip?: (value: Tooltips) => void;
  clearTooltipState?: () => void;
  activeTooltip?: Tooltips;
}

/**
 * Кнопка для фичи дейли бонус
 * Возвращает скелетон, если фича загружается,
 * Кнопку, открывающую регистрацию, если юзер не авторизован,
 * Кнопку, ведущую на страницу фичи, если пользователь авторизован,
 * и ничего, если по каким-то причинам фича недоступна
 */
export const DailyBonusButton: FC<DailyBonusProps> = ({ compact, ...toltipProps }) => {
  const { isAuthenticated } = useApiContext();
  const {
    dailyBonusWithUserStatus,
    dailyBonusWithUser,
    isLoadingState,
    dailyBonusWithoutUserStatus
  } = useGPTDailyBonus();

  useStatusUpdateTimer({ dailyBonusWithUser });

  if (isLoadingState) {
    return (
      <div
        className={twJoin(
          'flex gap-2 w-full items-center h-[27px]',
          compact ? 'justify-center' : 'justify-start '
        )}>
        <Skeletons className="w-6 h-6 rounded" />
        {!compact && <Skeletons className="min-w-[112.55px] h-[22px] rounded" />}
      </div>
    );
  }

  return isAuthenticated ? (
    <DailyBonusWithUser
      compact={compact}
      dailyBonusData={dailyBonusWithUser}
      status={dailyBonusWithUserStatus}
      {...toltipProps}
    />
  ) : (
    <DailyBonusWithoutUser
      compact={compact}
      status={dailyBonusWithoutUserStatus}
      {...toltipProps}
    />
  );
};
