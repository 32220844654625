import { ReactNode } from 'react';
import { MESSAGE_STATUS } from 'react-easyrocket';

import classNames from 'classnames';
import { CrossCloseIcon, FailureIcon, InfoIcon, SuccessIcon } from '../svg/userMessageIcons';
import { useMessage } from 'src/modules/messages';
import { motion } from 'framer-motion';
import { DoubleTick } from '../svg/DoubleTick';

interface UserMessageProps {
  id: string;
  text: string | ReactNode;
  status: MESSAGE_STATUS;
  className?: string;
  isReadIt?: boolean;
}

const statusIcon = {
  info: <InfoIcon />,
  error: <FailureIcon />,
  success: <SuccessIcon />
};

const statusColors = {
  info: 'text-um_info_text',
  error: 'text-um_error_text',
  success: 'text-um_success_text'
};

export const UserMessage = ({
  id,
  status,
  text,
  className,
  isReadIt = false
}: UserMessageProps) => {
  const { closeMessage } = useMessage();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classNames(
        'relative p-3 rounded-[20px] hover:bg-um_bg_hover transition-all bg-um_bg w-full cursor-pointer hover:bg-um_bg_hover',
        className
      )}>
      {isReadIt ? (
        <DoubleTick className="absolute top-3 right-3" />
      ) : (
        <CrossCloseIcon
          className="absolute top-3 right-3 cursor-pointer"
          onClick={() => closeMessage(id)}
        />
      )}

      <div
        className={classNames(
          'flex items-center gap-1 capitalize text-sm font-semibold mb-[6px]',
          statusColors[status]
        )}>
        {statusIcon[status]} <h4>{status}</h4>
      </div>
      <p className="text-sm font-medium text-um_text">{text}</p>
    </motion.div>
  );
};
