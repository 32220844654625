import { FC } from 'react';
import { STORAGE_URL, useBoolean } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';

interface CardImageProps {
  imageUrl: string;
}

export const CardImage: FC<CardImageProps> = ({ imageUrl }) => {
  const [isLoaded, setLoaded] = useBoolean(false);

  return (
    <div
      className={twJoin(
        'h-full relative rounded-t-lg rounded-b-[12px]',
        !isLoaded && 'gameCardImage'
      )}>
      <img
        className="h-full w-full object-cover block aspect-[158/211] relative rounded-t-lg rounded-b-[11px]"
        onLoad={() => {
          setLoaded();
        }}
        src={`${STORAGE_URL}/storage/games/vertically${imageUrl}`}
        alt="game image"
        loading="lazy"
      />
    </div>
  );
};
