export const DropwinsIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278235)">
      <path
        d="M15.9796 0.659058V15.925H0.629272V40.6562H25.2758V25.3903H40.6262V0.659058H15.9796ZM22.9322 38.3046H2.97284V18.2766H22.9322V38.3046ZM38.2826 23.0386H25.2758V15.925H18.3232V3.01069H38.2826V23.0386Z"
        fill="white"
      />
      <path
        d="M8.08394 19.699C6.13807 19.699 4.55499 21.2875 4.55499 23.2401C4.55499 25.1926 6.13807 26.7811 8.08394 26.7811C10.0298 26.7811 11.6129 25.1926 11.6129 23.2401C11.6129 21.2875 10.0298 19.699 8.08394 19.699ZM8.08394 24.4295C7.43032 24.4295 6.89856 23.8959 6.89856 23.2401C6.89856 22.5842 7.43032 22.0506 8.08394 22.0506C8.73756 22.0506 9.26932 22.5842 9.26932 23.2401C9.26932 23.8959 8.73756 24.4295 8.08394 24.4295Z"
        fill="white"
      />
      <path
        d="M18.0603 30.0511C16.1144 30.0511 14.5313 31.6396 14.5313 33.5922C14.5313 35.5448 16.1144 37.1333 18.0603 37.1333C20.0061 37.1333 21.5892 35.5447 21.5892 33.5922C21.5892 31.6397 20.0061 30.0511 18.0603 30.0511ZM18.0603 34.7817C17.4066 34.7817 16.8749 34.248 16.8749 33.5922C16.8749 32.9363 17.4066 32.4028 18.0603 32.4028C18.7139 32.4028 19.2456 32.9363 19.2456 33.5922C19.2456 34.2481 18.7139 34.7817 18.0603 34.7817Z"
        fill="white"
      />
      <path
        d="M28.3257 9.48352C26.3798 9.48352 24.7968 11.0721 24.7968 13.0246C24.7968 14.9772 26.3798 16.5657 28.3257 16.5657C30.2716 16.5657 31.8546 14.9771 31.8546 13.0246C31.8546 11.072 30.2716 9.48352 28.3257 9.48352ZM28.3257 14.214C27.6721 14.214 27.1403 13.6804 27.1403 13.0246C27.1403 12.3687 27.6721 11.8352 28.3257 11.8352C28.9793 11.8352 29.5111 12.3687 29.5111 13.0246C29.5111 13.6805 28.9793 14.214 28.3257 14.214Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278235">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.654297 0.658203)"
        />
      </clipPath>
    </defs>
  </svg>
);
