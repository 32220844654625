import { MouseEventHandler, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from 'src/components/svg';
import { twJoin } from 'tailwind-merge';

interface ShowProvidersButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
}

export const ShowProvidersButton = forwardRef<HTMLButtonElement, ShowProvidersButtonProps>(
  ({ onClick, isOpen }, ref) => {
    const { t } = useTranslation();
    return (
      <button
        title={`${t('general.providerList')}`}
        ref={ref}
        onClick={onClick}
        className="input_button w-[35%] flex justify-between items-center normal-case font-normal text-b_input_text bg-b_input_bg hover:bg-b_input_bg_hover">
        <p className="w-full text-sm 768:text-base font-bold uppercase">
          {t('general.providerList')}
        </p>
        <Arrow
          className={twJoin('w-5 stroke-[3px] transition-transform mr-5', isOpen && 'rotate-180')}
        />
      </button>
    );
  }
);

ShowProvidersButton.displayName = 'ShowProvidersButton';
