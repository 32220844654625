import classNames from 'classnames';
import { ForwardedRef, MutableRefObject, forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyItem } from 'src/components/CurrencyItem/CurrencyItem';
import { useInView } from '@react-spring/web';
import { CurrencyMinRawJackpotFOrRecentWinners } from 'src/constants';
import { prepareGameUrl } from 'src/utils';
import { STORAGE_URL, Winner, useApiContext } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useNavigateLng } from 'src/lib';

const RecentWinersCard = forwardRef(
  (
    { winner, disableClick = false }: { winner: Winner; disableClick?: boolean },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [inViewRef, inView] = useInView({
      root: ref as MutableRefObject<HTMLDivElement>,
      rootMargin: '-10% 0% 0% 0%'
    });

    useEffect(() => {
      const blockToAnimate = inViewRef.current as HTMLDivElement;
      if (
        !inView &&
        !blockToAnimate.parentElement?.classList.contains('customMarqueeVertical-content-second')
      ) {
        blockToAnimate.style.opacity = '0';
        blockToAnimate.style.transition = '1s';
      } else {
        blockToAnimate.style.opacity = '1';
        blockToAnimate.style.transition = '1s';
      }
    }, [inView]);

    const { isAuthenticated } = useApiContext();
    const navigate = useNavigateLng();
    const { openModal } = useModalContext();
    const { t } = useTranslation();

    const gameClick = () => {
      if (isAuthenticated) {
        navigate(prepareGameUrl(winner.game.id, winner.game.name));
      } else {
        openModal('SIGNUP');
      }
    };

    const winnerLogin = useMemo(() => {
      return winner.login.slice(0, 10);
    }, [winner.login]);

    const applyHighWinStyles = useCallback(
      (classname: string) => {
        const minimalNeededAmount =
          CurrencyMinRawJackpotFOrRecentWinners[
            winner.currency as keyof typeof CurrencyMinRawJackpotFOrRecentWinners
          ];
        if (minimalNeededAmount && minimalNeededAmount !== 0) {
          return winner.amount >= minimalNeededAmount ? classname : '';
        }
        return '';
      },
      [winner]
    );

    return (
      <div
        className={classNames(
          'flex flex-row items-center jsm:w-full opacity-1 touch-pan-y',
          applyHighWinStyles('bg-bg-big-winner-in-recent-winners rounded-[5px] p-[5px]')
        )}
        ref={inViewRef}>
        <div
          style={{
            backgroundImage: `url(${STORAGE_URL}/storage/games/vertically${winner.game.image})`
          }}
          onClick={disableClick ? undefined : gameClick}
          className={classNames(
            disableClick ? '' : 'cursor-pointer',
            'no-repeat w-[50px] min-w-[50px] max-w-[50px] h-[50px] rounded-[100%] bg-cover bg-center cursor-default jsm:w-[40px] jsm:min-w-[40px] jsm:max-w-[40px] jsm:h-[40px]'
          )}></div>
        <div className="flex flex-col pl-[15px]">
          <p className="text-white font-bold text-[14px] jgf:text-[10px] cursor-default">
            <>{winnerLogin}</> {t('general.wins')} {t('general.in')}{' '}
            <span
              className={classNames(
                'font-bold text-[14px] jgf:text-[10px] cursor-default',
                disableClick ? '' : 'cursor-pointer'
              )}
              onClick={disableClick ? undefined : gameClick}>
              {winner.game.name}
            </span>
            <br />
            <span
              className={classNames(
                'font-bold text-[20px] sm:text-[14px] gf:text-[10px]',
                applyHighWinStyles('!text-[40px]')
              )}
              style={{ textShadow: '0px 0px 12px #FF7A00, 0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
              <CurrencyItem currency={winner.currency} value={winner.amount} />
            </span>
          </p>
        </div>
      </div>
    );
  }
);

RecentWinersCard.displayName = 'RecentWinersCard';

export { RecentWinersCard };
