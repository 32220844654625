import { LiveChatWidget as LiveChatWidgetLib, useWidgetState } from '@livechat/widget-react';
import { useTranslation } from 'react-i18next';
import { languageGroups } from './constants';
import { SupportedLanguages } from 'src/locale/types';
import { useEffect, useState } from 'react';
import { useChangeEmailInLiveChat } from './hooks/useChangeEmailInLiveChat';

const LiveChat = () => {
  const [visibility, setVisibility] = useState<'maximized' | 'minimized' | 'hidden'>('hidden');

  const {
    i18n: { language }
  } = useTranslation();

  useChangeEmailInLiveChat();

  const state = useWidgetState();

  // subscribe to widget visibility if it was changed by calling assignVisibility
  useEffect(() => {
    if (state?.visibility && visibility !== state.visibility) {
      setVisibility(state.visibility);
    }
  }, [state?.visibility, visibility]);

  return (
    <LiveChatWidgetLib
      license="13199751"
      visibility={visibility}
      group={languageGroups[language as keyof SupportedLanguages]}
      chatBetweenGroups={false}
    />
  );
};

export const LiveChatWidget = () => {
  if (
    // TODO: uncomment next line before prod release, temporary enabled for testing
    // window.origin.includes('froach') ||
    window.origin.includes('localhost')
  ) {
    return null;
  }

  return <LiveChat />;
};
