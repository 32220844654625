import { useTranslation } from 'react-i18next';
import man from 'src/assets/images/seeAllMan.webp';
import classNames from 'classnames';

import './styles.css';
import { FC } from 'react';

interface CardSeeAllProps {
  onClick?: () => void;
  totalGames?: string;
}

export const CardSeeAll: FC<CardSeeAllProps> = ({ onClick, totalGames }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => onClick?.()}
      className={classNames(
        'rounded-[10px] group seeAllGamesCard transition-all duration-200 text-csa_text flex flex-col font-bold text-center items-center relative overflow-hidden cursor-pointer px-2.5 py-2 bg-gradient-to-b from-csa_bg_from to-csa_bg_to'
      )}
      style={{ boxShadow: 'var(--csa_shadow)' }}>
      <p className="text-csa_text_all_games font-bold text-[16px] mt-1">{t('general.allGames')}</p>
      <p className="text-[25px]">{totalGames}+</p>
      <div className="relative mt-1 w-full">
        <img
          src={man}
          alt="all games"
          title="all games"
          className="absolute group-hover:w-full transition-all duration-200 top-0 left-1/2 -translate-x-1/2 w-[80%]"
        />
      </div>
    </div>
  );
};
