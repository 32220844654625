import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { getTimestampsDiff } from './utils/getTimestampsDiff';
import { DailyBonus } from 'react-easyrocket';

/**
 * Хук, запускающий таймер до следующего дня.
 * Нужен для того, чтобы обновить календарик в сайдбаре пока пользователь сидит на страничке игры
 */
export const useStatusUpdateTimer = ({
  dailyBonusWithUser
}: {
  dailyBonusWithUser: DailyBonus | undefined;
}) => {
  const queryClient = useQueryClient();
  const statusUpdateTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!dailyBonusWithUser) return;

    const nextDay = dailyBonusWithUser.days[dailyBonusWithUser.currentDay];

    const updateTimeout = getTimestampsDiff(nextDay?.canBeCollectedAfterTs);

    const handleStatusUpdate = () => {
      queryClient.invalidateQueries(['daily-bonus-with-user']);
    };

    if (updateTimeout) {
      statusUpdateTimerRef.current = setTimeout(handleStatusUpdate, updateTimeout);
    }

    return () => {
      if (statusUpdateTimerRef.current) {
        clearTimeout(statusUpdateTimerRef.current);
      }
    };
  }, [dailyBonusWithUser]);
};
