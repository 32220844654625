import { SOFTSWISS_FRAME_DOMAIN } from 'src/pages/SportSwiss/config';

export const extractInAppPathnameFromFrameLocation = (inFrameLocation: string) => {
  const regexString = `^${SOFTSWISS_FRAME_DOMAIN}/[^/]+/(.*)$`;
  const regex = new RegExp(regexString);
  const match = inFrameLocation.match(regex);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};
