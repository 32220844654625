export const JackpotMidIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12167_264780)">
        <path
          d="M24.9134 26.6248C24.7471 26.2498 24.5115 25.9311 24.2061 25.6683C23.9006 25.4059 23.5282 25.1861 23.0888 25.0092C22.6493 24.8324 22.1564 24.6691 21.6099 24.519L21.0955 24.3904V22.2041C21.3954 22.2471 21.6928 22.3194 21.9877 22.4211C22.2823 22.5231 22.5503 22.6301 22.7914 22.7426C23.0326 22.8551 23.2307 22.9596 23.3862 23.0561C23.5414 23.1525 23.6353 23.2223 23.6675 23.2651L24.8249 21.0788C24.3748 20.8217 23.8443 20.57 23.2335 20.3233C22.6226 20.0769 21.9688 19.9213 21.2723 19.857V18.2334H20.163V19.857C19.6486 19.9108 19.1688 20.0258 18.7242 20.2026C18.2794 20.3795 17.8936 20.6234 17.5668 20.9341C17.2398 21.245 16.9801 21.6173 16.7872 22.0513C16.5943 22.4854 16.4979 22.9812 16.4979 23.5383C16.4979 23.9671 16.5594 24.3421 16.6828 24.6636C16.8058 24.9852 16.9934 25.2692 17.2454 25.5156C17.4971 25.7623 17.816 25.9793 18.2019 26.1667C18.5877 26.3544 19.043 26.5284 19.5683 26.6892L20.3399 26.9143V29.0523C19.9541 28.9988 19.5788 28.9104 19.2146 28.787C18.8501 28.664 18.5233 28.5353 18.234 28.4012C17.9446 28.2673 17.706 28.1387 17.5186 28.0154C17.331 27.8922 17.2157 27.8038 17.173 27.7501L16.0156 30.0489C16.5943 30.4347 17.2454 30.7456 17.9688 30.9812C18.6921 31.2172 19.4236 31.3671 20.163 31.4314V33.3123H21.2722V31.4475C21.7867 31.4259 22.2769 31.3455 22.7432 31.2063C23.2094 31.0672 23.6218 30.8607 23.9809 30.5875C24.3399 30.3141 24.6265 29.9633 24.841 29.5345C25.0552 29.106 25.1625 28.586 25.1625 27.9751C25.1625 27.4502 25.0794 27 24.9134 26.6248ZM20.3399 24.1652C19.9646 24.0474 19.6833 23.9108 19.4959 23.7553C19.3083 23.6001 19.2146 23.3883 19.2146 23.1203C19.2146 22.5633 19.5896 22.2523 20.3399 22.188V24.1652ZM21.0955 29.0844V27.1553C21.4704 27.2839 21.7706 27.4261 21.9957 27.5813C22.2208 27.7368 22.3333 27.9539 22.3333 28.2324C22.3333 28.7684 21.9206 29.0522 21.0955 29.0844Z"
          fill="white"
        />
        <path
          d="M26.5513 12.9978V7.50909H24.7942L27.272 0.668945H14.0339L16.5117 7.50909H14.7546V12.9978C11.522 14.9239 9.35156 18.4544 9.35156 22.4828V36.5534C9.35156 38.8212 11.1964 40.6661 13.4641 40.6661H27.8419C30.1096 40.6661 31.9545 38.8212 31.9545 36.5534V22.4829C31.9544 18.4544 29.7839 14.924 26.5513 12.9978ZM23.9305 3.01253L22.3017 7.50909H21.8247V4.98973H19.4812V7.50909H19.0043L17.3754 3.01253H23.9305ZM17.0982 9.85267H17.3606H23.9454H24.2078V11.4494H20.9211H20.3849H17.0982V9.85267ZM29.6109 36.5534C29.6109 37.5289 28.8172 38.3225 27.8418 38.3225H13.4641C12.4887 38.3225 11.6951 37.5289 11.6951 36.5534V22.4829C11.6951 17.6913 15.5933 13.793 20.3848 13.793H20.9211C25.7126 13.793 29.6109 17.6913 29.6109 22.4829V36.5534Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12167_264780">
          <rect
            width="39.9969"
            height="39.9972"
            fill="white"
            transform="translate(0.648438 0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
