import classNames from 'classnames';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { useDrawer } from 'src/hooks/useDrawer';
import { LinkLng } from 'src/lib';
import './styles.css';

export const CookiesBanner = () => {
  const { isCookiesOpen, openCookies, closeCookies } = useDrawer();

  const onAcceptCookiesClick = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    closeCookies();
  };

  useEffect(() => {
    if (!localStorage.getItem('cookiesAccepted')) {
      openCookies();
    }
    if (localStorage.getItem('cookiesAccepted') === 'true') {
      closeCookies();
    }
  }, []);

  if (!isCookiesOpen) {
    return null;
  }

  return (
    <div className="cookie-banner-wrapper">
      <div className="content-wrapper">
        <p className={classNames('cookies-content flex-1')}>
          <Trans
            i18nKey="cookiesBanner.text"
            components={{
              1: (
                <LinkLng
                  target="_blank"
                  aria-label="privacy policy"
                  to="/info_pages/privacy_policy"
                  className="font-bold cursor-pointer text-color-link underline"
                />
              )
            }}
          />
        </p>
        <Button
          title="ok"
          mode="button-secondary"
          className="!max-w-[60px] !max-h-[30px]"
          onClick={onAcceptCookiesClick}>
          ok
        </Button>
      </div>
    </div>
  );
};
