import classNames from 'classnames';
import { useState } from 'react';
import { Eye } from '../svg';
import { Input, InputProps } from './Input';

export type PasswordInputProps = InputProps;

export const PasswordInput = ({
  label,
  labelWithPadding,
  labelClassname,
  ...inputPasswordProps
}: PasswordInputProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="flex flex-col justify-center items-start gap-2 w-[100%]">
      {label ? (
        <label
          className={classNames(
            'text-base text-text-secondary',
            labelWithPadding && 'pl-[20px]',
            labelClassname
          )}>
          {label}
        </label>
      ) : null}
      <Input
        {...inputPasswordProps}
        htmlType={passwordShown ? 'text' : 'password'}
        rightIcon={<Eye setPasswordShown={setPasswordShown} passwordShown={passwordShown} />}
      />
    </div>
  );
};
