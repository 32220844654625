import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetJackpots, useGetUserCountry } from 'react-easyrocket';
import { FIFTEEN_SECONDS } from 'src/constants/time';
import { getCategories } from './utils/getCategories';
import { scrollToCenter } from './utils/scrollToCenter';
import { isDropsAndWinsDisabled } from 'src/utils/isDropsAndWinsDisabled';
import { CategoryLink } from './components';

interface GamesCategoriesSwitcherProps {
  lightActiveLink?: boolean;
  onCategoryClick?: () => void;
}

export const GamesCategoriesSwitcher: FC<GamesCategoriesSwitcherProps> = ({
  lightActiveLink = true,
  onCategoryClick
}) => {
  const { jackpots } = useGetJackpots(
    {},
    { refetchInterval: FIFTEEN_SECONDS, staleTime: FIFTEEN_SECONDS, cacheTime: FIFTEEN_SECONDS }
  );
  const location = useLocation();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const { userCountry, isInitialLoading: isUserCountryLoading } = useGetUserCountry();

  const categories = useMemo(() => {
    const isDropsAndWinsHidden = isDropsAndWinsDisabled(userCountry?.country);

    return getCategories(jackpots, location.pathname, isDropsAndWinsHidden);
  }, [jackpots, location.pathname, userCountry?.country]);

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    const currentElement = document.getElementById(location.pathname);
    if (scrollableElement) {
      scrollToCenter(currentElement, scrollableElement);
    }
  }, [location]);

  return (
    <div
      className="switcher-wrapper py-4 flex justify-start overflow-x-scroll w-full gap-x-3 bounce"
      ref={scrollableRef}>
      {categories.map(({ path, title, image, param }) => (
        <CategoryLink
          key={param}
          path={path}
          title={title}
          image={image}
          lightActiveLink={lightActiveLink}
          onCategoryClick={onCategoryClick}
          isLoading={isUserCountryLoading && param === 'dropwinscat'}
        />
      ))}
    </div>
  );
};
