import title from '../../../assets/images/jackpotWinModalImages/title.webp';
import { currencyIcon } from 'src/utils';
import { Button } from 'src/components/Buttons/Button';
import { getNumberFormatter } from 'src/utils/getNumberFormatter';
import { SimpleClose } from 'src/components/svg';
import { Spinner } from 'src/components/Spinner';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './fireworks.css';
import { useEffect } from 'react';
import {
  BonusTransaction,
  STORAGE_URL,
  makeFloat,
  useGetGame,
  useUpdateBonusStatus
} from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useNavigateLng } from 'src/lib';

export const JackpotWinModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigateLng();
  const location = useLocation();
  const { modalProps, closeModal } = useModalContext<{ jackpot: BonusTransaction }>();
  const { game } = useGetGame(
    { game_id: modalProps.jackpot?.game?.id || 0 },
    { enabled: Boolean(modalProps.jackpot?.game?.id) }
  );
  const { numberFormatter } = getNumberFormatter();

  const { mutateAsync: updateStatus } = useUpdateBonusStatus();

  const handleCloseModal = () => {
    closeModal();
  };

  const handleNavigateToAccount = () => {
    navigate('/account/promo');
    closeModal();
  };

  useEffect(() => {
    if (modalProps?.jackpot.id && modalProps?.jackpot.wager > 0) {
      updateStatus({ bonus_id: modalProps.jackpot.id, status: 'Queue' });
    }
  }, [modalProps.jackpot, updateStatus]);

  return (
    <div className="fixed z-[900] bg-m_bg_shadowing top-0 bottom-0 left-0 right-0 overflow-auto">
      <div className="relative flex items-center justify-center w-[100vw] h-[100vh] jackpotWinModal bg-no-repeat bg-cover bg-center overflow-hidden">
        <div className="absolute top-[20px] z-[1000] right-[20px] flex justify-end items-center w-full mb-[24px]">
          <SimpleClose onShow={handleCloseModal} />
        </div>
        {modalProps?.jackpot ? (
          <>
            <div className="firework"></div>
            <div className="firework"></div>
            <div className="firework"></div>
            <div className="firework"></div>
            <div className="flex flex-col items-center">
              <img src={title} alt={'Jackpot'} className="max-w-[680px]" />
              <p
                className="font-[900] text-yellow text-[50px] md:!text-[120px] xs:text-[80px]"
                style={{
                  textShadow:
                    '0px 7.199999809265137px 54px rgba(255, 190, 0, 0.56), 0px 7.199999809265137px 7.199999809265137px #B70202'
                }}>
                {currencyIcon(modalProps?.jackpot.currency)}

                {modalProps?.jackpot.amount
                  ? numberFormatter.format(Number(makeFloat(modalProps?.jackpot.amount)))
                  : null}
              </p>
              {game && (
                <div className="flex flex-col md:flex-row text-center md:text-start items-center gap-[20px] mb-[55px]">
                  <img
                    src={`${STORAGE_URL}/storage/games/vertically${game?.image}`}
                    className="w-[80px] h-[80px] rounded-[6px]"
                  />
                  <div className="text-white text-[20px]">
                    <p className="font-normal">{t('jackpotWinModal.youWonIn')}</p>
                    <p className="font-bold underline">{game?.name}</p>
                    <p className="font-normal">{game?.providerName}</p>
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row gap-[15px]">
                {modalProps?.jackpot.wager > 0 && (
                  <Button
                    mode="button-secondary"
                    className="min-w-[300px]"
                    onClick={handleNavigateToAccount}>
                    {t('promoCode.activate')}
                  </Button>
                )}
                <Button
                  mode="button-transparent"
                  className="min-w-[300px]"
                  onClick={handleCloseModal}>
                  {location.pathname.includes('game/')
                    ? t('jackpotWinModal.backToGame')
                    : t('gameNotification.close')}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <Spinner color="border-yellow" className="!w-[100px] !h-[100px] !border-[10px]" />
        )}
      </div>
    </div>
  );
};
