import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ShowMoreButton: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <button {...props} className="text-[18px] font-bold underline text-white mb-[40px]">
      {t('general.showMore')}
    </button>
  );
};
