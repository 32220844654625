import { Portal } from '../Portal/Portal';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useClickOutside, useGetUserCountry } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { PROGRESSIVE_TOOLTIP_TYPE, useTooltip } from 'src/hooks/useProgressiveTooltip';
import { progressiveTooltipsData } from '../Sidebar/constants';
import { SidebarContainerItem } from '../SidebarContainerItem/SidebarContainerItem';
import { useDrawer } from 'src/hooks/useDrawer';
import { isDropsAndWinsDisabled } from 'src/utils/isDropsAndWinsDisabled';

export const ProgressiveTooltip = () => {
  const { t } = useTranslation();
  const { openedTooltip, closeTooltips, coordinates, controllerRef } = useTooltip();
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const { closeNotifications } = useDrawer();
  const { userCountry } = useGetUserCountry();

  const tooltipData = useMemo(() => {
    return progressiveTooltipsData[openedTooltip as PROGRESSIVE_TOOLTIP_TYPE];
  }, [openedTooltip]);

  const filteredChildsData = useMemo(() => {
    if (!tooltipData?.childsData) {
      return [];
    }
    const isDropsAndWinsHidden = isDropsAndWinsDisabled(userCountry?.country);

    if (isDropsAndWinsHidden) {
      return tooltipData.childsData.filter((item) => {
        return item.param !== 'dropwinscat';
      });
    }

    return tooltipData.childsData;
  }, [userCountry?.country, tooltipData]);

  useClickOutside(tooltipRef, closeTooltips, [controllerRef as MutableRefObject<any>]);

  const position = useMemo(() => {
    return coordinates && Math.round(coordinates);
  }, [coordinates]);

  return (
    <Portal>
      {openedTooltip !== undefined && (
        <div
          ref={tooltipRef}
          className="w-[200px] fixed left-[85px] z-[1000] rounded bg-ptc_bg_menu p-[8px] max-h-[70vh] overflow-y-auto"
          style={{ top: position ? position : 'none' }}>
          <div className="flex justify-center items-center w-full gap-2">
            <div className="flex justify-center items-center w-5 h-5">{tooltipData.icon}</div>
            <p className="flex-1 text-left text-xs font-bold text-ptc_text uppercase">
              {t(tooltipData.title)}
            </p>
          </div>
          <div className="w-full flex flex-col justify-center items-center gap-1 mt-[8px]">
            {(filteredChildsData || []).map((child, index) => (
              <SidebarContainerItem
                onClick={() => {
                  closeTooltips();
                  closeNotifications();
                }}
                index={index}
                key={child.title}
                title={child.title}
                path={child.path}
                icon={child.image}
                state={child.title}
              />
            ))}
          </div>
        </div>
      )}
    </Portal>
  );
};
