import { CheckEventDatesParams, checkEventDatesFromEnv } from 'src/utils/checkEventDates';

const END_EVENT_DATE = {
  year: '2024',
  month: '11',
  day: '03'
};

export const START_EVENT_DATE_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '10',
    day: '22'
  },
  endDate: END_EVENT_DATE
};

export const START_EVENT_DATE_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '10',
    day: '24'
  },
  endDate: END_EVENT_DATE
};

export const shouldEvent = checkEventDatesFromEnv({
  devDates: START_EVENT_DATE_DEV,
  prodDates: START_EVENT_DATE_PROD
});
