import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren } from 'react';
import { ApiProvider, IS_PWA, MESSAGE_STATUS } from 'react-easyrocket';
import OneSignal from 'react-onesignal';
import { BrowserRouter } from 'react-router-dom';
import { API_URL, CONTENT_URL, isProductionEnv, isDev } from 'src/api';
import { QueryBoundaries } from 'src/components/QueryBoundaries';
import { DrawerProvider } from 'src/hooks/useDrawer';
import { FreeSpinsProvider } from 'src/hooks/useFreeSpins';
import { FullScreenProvider } from 'src/hooks/useFullScreen';
import { TooltipProvider } from 'src/hooks/useProgressiveTooltip';
import { useInitMessagesContext } from 'src/modules/messages/hooks/useInitMessagesContext';
import { MessagesProvider } from 'src/modules/messages/provider';

import { ModalContextProvider } from 'src/newUseModalContext';
import { MODAL_MAP } from 'src/newUseModalContext/constants';
import { SportContextProvider } from './SportProvider';

if (isProductionEnv) {
  OneSignal.init({
    appId: 'cb66b5af-8ea6-44ac-a0db-011178735b62',
    serviceWorkerPath: '/build/'
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

export const AllProvidersComponent = ({ children }: PropsWithChildren) => {
  const messageContextValue = useInitMessagesContext();

  return (
    <BrowserRouter basename={IS_PWA ? '/pwa' : undefined}>
      <MessagesProvider contextValue={messageContextValue}>
        <FullScreenProvider>
          <DrawerProvider>
            <FreeSpinsProvider>
              <QueryClientProvider client={queryClient}>
                <QueryBoundaries>
                  <ApiProvider
                    openErrorMessage={(text: string) =>
                      messageContextValue.openMessage({
                        type: 'USER_MESSAGE',
                        props: { text, type: MESSAGE_STATUS.ERROR },
                        saveToHistory: false,
                        preventDuplicates: true,
                        id: text
                      })
                    }
                    apiUrl={API_URL}
                    contentUrl={CONTENT_URL}
                    isProductionEnvironment={isProductionEnv}>
                    <ModalContextProvider modalMap={MODAL_MAP}>
                      <TooltipProvider>
                        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
                        <SportContextProvider>{children}</SportContextProvider>
                      </TooltipProvider>
                    </ModalContextProvider>
                  </ApiProvider>
                </QueryBoundaries>
              </QueryClientProvider>
            </FreeSpinsProvider>
          </DrawerProvider>
        </FullScreenProvider>
      </MessagesProvider>
    </BrowserRouter>
  );
};
