import { FullInfoResponse, PaymentFormField } from 'react-easyrocket';
import { shouldFetchDefaultValue } from './shouldFetchDefaultValue';

export const generateFormFields = (
  items: PaymentFormField[] | undefined,
  fullInfo: FullInfoResponse
): Record<string, string> | undefined => {
  if (!items?.length) {
    return undefined;
  }
  return items.reduce<Record<string, string>>((acc, item) => {
    if (item.title) {
      acc[item.name] = shouldFetchDefaultValue(item, fullInfo);
    }
    if (item.name === 'country') {
      acc[item.name] = shouldFetchDefaultValue(item, fullInfo);
    }

    return acc;
  }, {});
};
