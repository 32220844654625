import { AnimatePresence, motion } from 'framer-motion';
import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Child, SinglePageResponse, useBackgroundScrollLock } from 'react-easyrocket';
import { CONTENT_URL } from 'src/api';
import { FADE_IN_OUT_ANIMATION } from 'src/animation';
import { Portal } from 'src/components/Portal/Portal';
import { SimpleClose } from 'src/components/svg';
import { useTouchControls } from '../hooks/useTouchContorls';
import { StoryDirection, VisitedStories } from '../types';
import { getSlideIndex, getStoryKey } from '../utils';
import { nextStoryTimeout } from '../config';
import { StoryProgressbar } from './StoryProgressbar';
import { LinkLng } from 'src/lib';

interface StoryProps {
  data: SinglePageResponse;
  activeStory: Child;
  openStory: (story: Child, index: number) => void;
  closeStory: () => void;
  visited: VisitedStories;
  markVisited: (storyKey: string, childInfo?: string) => void;
  openNextStory: () => void;
  openPrevStory: () => void;
  storyDirection: StoryDirection;
  isStoryVisited: (story: Child) => boolean;
}

export const Story: FC<StoryProps> = ({
  data,
  activeStory,
  closeStory,
  visited,
  openNextStory,
  openPrevStory,
  markVisited,
  storyDirection,
  isStoryVisited
}) => {
  if (!activeStory.childs) {
    closeStory();
  }

  const [slideIndex, setSlideIndex] = useState<number>(
    getSlideIndex(activeStory, visited, isStoryVisited(activeStory))
  );

  const activeStorySlide = activeStory.childs?.[slideIndex] || activeStory;

  const handleNextItem = () => {
    const nextSlideIndex = slideIndex + 1;
    const slidesLength = activeStory.childs?.length;

    if (slidesLength && nextSlideIndex >= slidesLength) {
      openNextStory();
      setSlideIndex(0);
      return;
    }

    setSlideIndex(nextSlideIndex);
  };

  const handlePrevItem = () => {
    const prevSlideIndex = slideIndex - 1;

    if (prevSlideIndex < 0) {
      openPrevStory();
      return;
    }

    setSlideIndex(prevSlideIndex);
  };

  const { handleTouchStart, handleTouchMove, handleTouchEnd, handleClick } = useTouchControls(
    data,
    closeStory,
    handleNextItem,
    handlePrevItem
  );

  useBackgroundScrollLock(Boolean(activeStory), [activeStory]);

  const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    closeStory();
  };

  // Set timeout to open next slide and mark slide as visited
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleNextItem();
    }, nextStoryTimeout);

    const slidesLength = activeStory.childs?.length;

    // if else condition is used to mark slide as visited
    if (slidesLength === 1) {
      markVisited(getStoryKey(activeStory.code, activeStory.imageMobile));
    } else {
      const slide = activeStory.childs?.[slideIndex];

      if (slide) {
        const storyKey = getStoryKey(activeStory.code, activeStory.imageMobile);
        const slideKey = getStoryKey(slide.code, slide.imageMobile);

        markVisited(storyKey, slideKey);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [slideIndex, activeStory]);

  // open last slide if user is swiping back
  useEffect(() => {
    if (storyDirection === 'back' && activeStory.childs?.length) {
      setSlideIndex(activeStory.childs?.length - 1);
    }
  }, [activeStory, storyDirection]);

  return (
    <Portal>
      <AnimatePresence>
        {activeStory && activeStory.childs && (
          <motion.div
            className="fixed bottom-0 left-0 right-0 top-0 z-[100] grid place-items-center bg-stories_background bg-cover bg-no-repeat p-2.5"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={FADE_IN_OUT_ANIMATION}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={handleClick}>
            <img
              src={CONTENT_URL + activeStorySlide?.imageMobile}
              alt=""
              className="absolute bottom-0 left-0 right-0 top-0"
            />
            <div className="absolute left-1.5 right-1.5 top-4 flex justify-between gap-1">
              {activeStory.childs.map((child, i) => (
                <StoryProgressbar
                  isAnimated={slideIndex === i}
                  isComplete={slideIndex > i}
                  key={child.code}
                />
              ))}
            </div>
            <button className="absolute right-2 top-6 p-2" onClick={handleCloseButtonClick}>
              <SimpleClose />
            </button>
            <LinkLng
              to={activeStorySlide.title || '/'}
              // calc(100vw*1.8*0.79): 1.8 - image aspect ratio, 0.79 - 79% button position as image height
              // pt-[18%]: is used to set button height as 18% of container width
              className="absolute block pt-[18%] w-3/5 top-[calc(100vw*1.8*0.79)] left-2/4 -translate-x-1/2"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};
