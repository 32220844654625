import { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps, useResolvedPath } from 'react-router-dom';

import { defaultFallbackLanguage } from '../../constants';

export const NavLinkLng = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, to, ...props }, ref) => {
    const { i18n } = useTranslation();

    const { pathname, hash, search } = useResolvedPath(to);

    const href = pathname + search + hash;

    const fallbackLanguage = Array.isArray(i18n.options.fallbackLng)
      ? i18n.options.fallbackLng[0]
      : i18n.options.fallbackLng;

    const defaultLanguage = (fallbackLanguage as string) || defaultFallbackLanguage;

    const linkWithLanguage = i18n.language !== defaultLanguage ? `/${i18n.language}${href}` : href;

    return (
      <NavLink to={linkWithLanguage} {...props} ref={ref}>
        {children}
      </NavLink>
    );
  }
);

NavLinkLng.displayName = 'NavLinkLng';
