import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const TehterIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#59AF99"
      />
      <path
        d="M12.1418 9.6459V8.3468H15.0975V6.2975H6.8948V8.3468H9.8582V9.6459C7.3755 9.7658 5.5121 10.2641 5.5121 10.8559C5.5121 11.4477 7.3733 11.9482 9.8582 12.0659V16.3559H12.1418V12.0659C14.6245 11.9471 16.4879 11.4488 16.4879 10.8559C16.4879 10.263 14.63 9.7658 12.1418 9.6459ZM11 11.6875C8.3116 11.6875 6.1325 11.2717 6.1325 10.7712C6.1325 10.3411 7.7198 9.9792 9.856 9.8824V11.3498C10.2234 11.3663 10.6051 11.3762 10.9978 11.3762C11.3905 11.3762 11.7744 11.3663 12.1396 11.3498V9.8824C14.2758 9.9792 15.8631 10.3411 15.8631 10.7712C15.8675 11.2772 13.6884 11.6875 11 11.6875Z"
        fill="white"
      />
    </svg>
  );
};
