import { useState } from 'react';
import { COOLDOWN, calcStartingTime, storageKeys } from './useTimeLeft';

export const useCanRotate = () => {
  const [canRotate, setCanRotate] = useState<boolean>(() => {
    const canRotateLS = localStorage.getItem(storageKeys.canRotate);
    const timeFromLS = localStorage.getItem(storageKeys.startTimer);

    if (canRotateLS && calcStartingTime(timeFromLS) === COOLDOWN) {
      localStorage.setItem(storageKeys.canRotate, 'true');
      return true;
    }

    if (!canRotateLS) {
      localStorage.setItem(storageKeys.canRotate, 'true');
      return true;
    }

    return JSON.parse(canRotateLS);
  });

  return { canRotate, setCanRotate };
};
