import eggShop from 'src/assets/event-images/eggShop.webp';
import { useTranslation } from 'react-i18next';
import { MESSAGE_STATUS, ShopItemType, ShortBalanceResponse, useCurrency } from 'react-easyrocket';
import { memo } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { twJoin } from 'tailwind-merge';
import { useModalContext } from 'src/hooks/useModalContext';
import { useMessage } from 'src/modules/messages';

interface BonusMoneyShopItem {
  item?: ShopItemType;
  shortBalance: ShortBalanceResponse | undefined;
  buyShopItem?: (item: ShopItemType) => void;
  isModal?: boolean;
  isCoins?: boolean;
  handleBuyButtonClick?: (itemId: number) => Promise<void>;
  isLoading?: boolean;
}

const BonusMoneyShopItem = ({
  item,
  buyShopItem,
  isModal,
  handleBuyButtonClick,
  isCoins,
  isLoading
}: BonusMoneyShopItem) => {
  const { closeModal, openModal } = useModalContext();
  const { t } = useTranslation();
  const { openMessage } = useMessage();

  const { currencyIcon, currencyName } = useCurrency();
  return (
    <div className="flex flex-col justify-center max-w-[220px] w-full">
      <div
        className={twJoin(
          'pb-5 w-full flex flex-col items-center',
          isModal ? 'bg-[transparent]' : 'pb-7 bg-mp_card_bg rounded-[20px]'
        )}>
        <div className="pt-2.5 px-5 flex items-center justify-between flex-col text-mp_card_text font-bold">
          <div className="rounded-[40px] px-4 my-1 flex items-center justify-center text-3xl bg-mp_card_coins_bg">
            <span>{item?.costPoints || 0}</span>
            <img src={eggShop} className="w-10 pl-2" />
          </div>
          <div
            className="flex items-center text-[55px] gap-2 text-mp_card_amount font-bold"
            style={{ filter: 'drop-shadow( 0px 3px 0px var(--mp_card_shadow))' }}>
            <span>{item?.amount ? item?.amount?.[currencyName] / 100 : 0}</span>
            <span>{currencyIcon}</span>
          </div>
        </div>
        {!isModal && (
          <Button
            mode="button-secondary"
            className="!w-[165px]"
            onClick={() => item && buyShopItem?.(item)}>
            {t('christmasShop.buy')}
          </Button>
        )}
        {isModal && isCoins && (
          <Button
            mode="button-secondary"
            className="!w-[165px] self-center"
            onClick={async () => {
              openMessage({
                type: 'USER_MESSAGE',
                props: {
                  text: `${t('shop.successfully')}`,
                  type: MESSAGE_STATUS.SUCCESS
                },
                saveToHistory: false
              });
              closeModal();
              if (!item) return;
              await handleBuyButtonClick?.(item.id);
            }}
            loading={isLoading}
            disabled={isLoading}>
            {t('christmasShop.accept')}
          </Button>
        )}
        {isModal && !isCoins && (
          <Button
            mode="button-secondary"
            className="!w-[165px] self-center"
            onClick={() => {
              closeModal?.();
              openModal?.('POPUP_DEPOSIT');
            }}>
            {t('christmasShop.deposit')}
          </Button>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default memo(BonusMoneyShopItem);
