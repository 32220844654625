import { useEffect } from 'react';
import { useApiContext } from 'react-easyrocket';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

/**
 *
 * Хук, необходимый для обновления user state.
 * при переключении страницы перезапрашивает state,
 */
export const useUpdateUserState = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useApiContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!pathname.includes('easydailybonus')) {
      queryClient.invalidateQueries(
        isAuthenticated ? ['daily-bonus-with-user'] : ['daily-bonus-without-user']
      );
    }
  }, [pathname, isAuthenticated]);
};
