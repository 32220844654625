export const NewIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278049)">
      <path
        d="M31.5408 3.844L11.5423 2.84204L0.675415 19.6603L9.80698 37.4806L29.8054 38.4826L40.6723 21.6642L31.5408 3.844ZM30.0187 6.19962L37.2317 20.2758L21.4349 19.4844L14.2219 5.40827L30.0187 6.19962ZM3.47653 19.8005L12.0603 6.51592L19.2734 20.5921L10.6895 33.8769L3.47653 19.8005ZM28.5264 35.9865L12.7296 35.1951L21.3134 21.9103L37.1102 22.7017L28.5264 35.9865Z"
        fill="white"
      />
      <path
        d="M26.0428 13.9374C26.8252 13.9374 27.4595 13.3031 27.4595 12.5208C27.4595 11.7384 26.8252 11.1041 26.0428 11.1041C25.2605 11.1041 24.6262 11.7384 24.6262 12.5208C24.6262 13.3031 25.2605 13.9374 26.0428 13.9374Z"
        fill="white"
      />
      <path
        d="M19.6794 33.463C20.4618 33.463 21.096 32.8288 21.096 32.0464C21.096 31.264 20.4618 30.6298 19.6794 30.6298C18.897 30.6298 18.2628 31.264 18.2628 32.0464C18.2628 32.8288 18.897 33.463 19.6794 33.463Z"
        fill="white"
      />
      <path
        d="M29.9652 27.7393C30.7476 27.7393 31.3818 27.105 31.3818 26.3226C31.3818 25.5403 30.7476 24.906 29.9652 24.906C29.1828 24.906 28.5486 25.5403 28.5486 26.3226C28.5486 27.105 29.1828 27.7393 29.9652 27.7393Z"
        fill="white"
      />
      <path
        d="M11.4292 15.6283C12.2116 15.6283 12.8458 14.9941 12.8458 14.2117C12.8458 13.4293 12.2116 12.795 11.4292 12.795C10.6468 12.795 10.0126 13.4293 10.0126 14.2117C10.0126 14.9941 10.6468 15.6283 11.4292 15.6283Z"
        fill="white"
      />
      <path
        d="M7.52306 21.5946C8.30544 21.5946 8.93968 20.9604 8.93968 20.178C8.93968 19.3956 8.30544 18.7614 7.52306 18.7614C6.74069 18.7614 6.10645 19.3956 6.10645 20.178C6.10645 20.9604 6.74069 21.5946 7.52306 21.5946Z"
        fill="white"
      />
      <path
        d="M14.6824 22.0853C15.4647 22.0853 16.099 21.4511 16.099 20.6687C16.099 19.8863 15.4647 19.2521 14.6824 19.2521C13.9 19.2521 13.2657 19.8863 13.2657 20.6687C13.2657 21.4511 13.9 22.0853 14.6824 22.0853Z"
        fill="white"
      />
      <path
        d="M10.8896 27.7417C11.672 27.7417 12.3063 27.1075 12.3063 26.3251C12.3063 25.5427 11.672 24.9084 10.8896 24.9084C10.1073 24.9084 9.47302 25.5427 9.47302 26.3251C9.47302 27.1075 10.1073 27.7417 10.8896 27.7417Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278049">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.666016 0.665039)"
        />
      </clipPath>
    </defs>
  </svg>
);
