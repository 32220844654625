import dayjs from 'dayjs';

// hidden field for form generator expect date in format DD.MM.YYYY, while in current implementation date stored in state in format YYYY-MM-DD
export const getHiddenInputValue = (value: string, isDevCodeMethod?: boolean) => {
  if (!value) return '';
  if (isDevCodeMethod) {
    return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  return dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
};
