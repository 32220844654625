import { FC } from 'react';
import { AllRights } from 'src/components/svg';
import { P4pSupport } from 'src/components/svg/p4pSupport';
import { useGetLicense } from 'react-easyrocket';

export const LicenseBlock: FC = () => {
  const { image: licenseImage, text: licenseText } = useGetLicense();

  return (
    <div className="flex gap-5 justify-between mb-14 768:mb-0 mt-6 flex-col 1024:flex-row items-center">
      <p className=" not-italic font-medium text-sm text-text-fourtiary flex-1 text-[10px] mb-4">
        {licenseText}
      </p>
      <div className="flex gap-4">
        <AllRights />
        <P4pSupport />
        <div
          dangerouslySetInnerHTML={{ __html: licenseImage }}
          className="[&_img]:block [&_img]:h-[50px] [&_img]:w-auto"
        />
      </div>
    </div>
  );
};
