import { useGetLocalTournamentPrize } from 'src/hooks/useGetLocalTournamentPrize';
import { useCallback } from 'react';
import { Button } from '../Buttons/Button';

import contentBg from 'src/assets/images/winner-tournament-modal-bg.webp';
import headerBg from 'src/assets/images/winner-tournament-header-bg.webp';
import { useTranslation } from 'react-i18next';
import { LocalTournamentPlace } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { useNavigateLng } from 'src/lib';

// TODO chanche LocalTournamentPlace to Prize
type ModalProps = {
  prize: LocalTournamentPlace;
  tournamentName: string;
};

export const WinnerTournamentModal = () => {
  const { getPrize } = useGetLocalTournamentPrize();
  const { closeModal, modalProps } = useModalContext<ModalProps>();
  const { t } = useTranslation();
  const navigate = useNavigateLng();

  const handleGetReward = useCallback(() => {
    closeModal();
    if (!modalProps?.prize) return;

    if (modalProps.prize?.wager > 0) {
      navigate('account/promo');
    }
    if (modalProps.prize.wager === 0) {
      navigate('account/transaction-history/basic');
    }
  }, [closeModal, modalProps?.prize, navigate]);

  return (
    <ModalWrapper isTransparentBg>
      <div className="w-full min-w-[300px] 768:w-[500px] flex flex-col items-center bg-[transparent]">
        <div
          className="py-[46px] bg-no-repeat bg-cover w-full flex justify-center"
          style={{
            backgroundImage: `url(${headerBg})`
          }}>
          <h2 className="text-[26px] leading-[29px] font-black text-text-secondary max-w-[250px] text-center">
            {modalProps?.tournamentName}
          </h2>
        </div>
        <div
          className="w-full flex justify-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url(${contentBg})`
          }}>
          <div className="pt-[25px] pb-[30px] px-5 max-w-[350px] w-full gap-2.5 flex flex-col items-center">
            <h3 className="first-letter:uppercase text-[16px] text-text-secondary leading-[17px] font-bold">
              {t('tournaments.congratulations')}!
            </h3>
            <p className="first-letter:uppercase text-[14px] text-text-secondary leading-[15px]">
              {t('tournaments.youWin')}:
            </p>
            <p
              className="flex flex-wrap justify-center text-[30px] leading-[33px] tracking-[0.03em] font-black text-yellow text-center"
              style={{
                textShadow: '0px 5px 37px 0px #FFBE008F, 0px 5px 5px 0px #B70202'
              }}>
              {getPrize(modalProps?.prize)}
            </p>
            <Button
              className="my-2.5 py-2.5 px-10"
              mode="button-secondary"
              onClick={handleGetReward}>
              {t('general.getReward')}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
