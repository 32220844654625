import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PROVIDER_ID_SEARCH_PARAM } from 'src/constants';
import { useGetAllProvidersCached } from 'src/hooks/useGetAllProvidersCached';

export function useGetProviderFromUrl() {
  const [searchParams] = useSearchParams();
  const { providers } = useGetAllProvidersCached();

  const providerId = useMemo(() => {
    const providerIdFromUrl = searchParams.get(PROVIDER_ID_SEARCH_PARAM);
    if (!providerIdFromUrl) return undefined;
    return parseInt(providerIdFromUrl, 10);
  }, [searchParams]);

  const providerTitle = useMemo(() => {
    if (!providerId) return undefined;
    return providers?.find((provider) => provider.id === providerId)?.name;
  }, [providerId, providers]);

  return { providerId, providerTitle };
}
