import { useTranslation } from 'react-i18next';
import firstPlaceBadge from 'src/assets/images/firstPlaceBadge.webp';
import secondPlaceBadge from 'src/assets/images/secondPlaceBadge.webp';
import thirdPlaceBadge from 'src/assets/images/thirdPlaceBadge.webp';
import { useGetLocalTournamentPrize } from 'src/hooks/useGetLocalTournamentPrize';

const IMAGES = [firstPlaceBadge, secondPlaceBadge, thirdPlaceBadge];
const placeSuffix = ['ST', 'ND', 'RD'];

interface TournamentPlaceProps {
  place: any;
  id: number;
  status: number;
  placesCount?: number;
}

export const TournamentPlace = ({ place, id, status, placesCount = 3 }: TournamentPlaceProps) => {
  const { t } = useTranslation();
  const { getPrize } = useGetLocalTournamentPrize();

  if (id >= placesCount) {
    return null;
  }

  return (
    <div className="flex gap-[12px] jmid:gap-[7px] items-center w-full">
      <img className="w-[70px]" src={IMAGES[id]} alt="tournament medal" />

      <div
        className="border-2 border-yellow rounded-[10px] backdrop-blur-sm p-[15px] text-white w-full"
        style={{
          boxShadow: '0px 0px 5px 0px #F0B6FF'
        }}>
        <p className="uppercase flex flex-col gap-[5px]">
          <span className="412:text-[20px] text-[17px] leading-[22px] font-extrabold">
            {id + 1}
            {placeSuffix[id]} {t('tournaments.placeGet')}
          </span>
          {status === 3 && (
            <span className="412:text-[20px] text-[17px] leading-[22px] uppercase">
              {place.login.slice(0, 12)} {t('general.wins')}
            </span>
          )}
          <span className="412:text-[31px] text-[28px] leading-[33px] text-yellow font-bold">
            {getPrize(place)}
          </span>
        </p>
      </div>
    </div>
  );
};
