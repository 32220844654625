import { Trans } from 'react-i18next';
import logo from 'src/assets/images/logoSidebar.webp';
import ModalWrapper from 'src/components/ModalWrapper';
import desktopImage from './assets/bgDesktop.webp';
import mobileImage from './assets/bgMobile.webp';

export const SportStubModal = () => {
  return (
    <ModalWrapper>
      <div className="w-[304px] max-w-full 768:w-[740px] bg-SPORT_STUB_MODAL_BG relative">
        <img
          className="w-full block 768:hidden"
          src={mobileImage}
          alt="sport is coming soon"
          width={608}
          height={560}
        />
        <img
          className="w-full hidden 768:block"
          src={desktopImage}
          alt="sport is coming soon"
          width={1480}
          height={800}
        />
        <div className="absolute top-4 left-2.5 768:top-7 768:left-7">
          <img
            src={logo}
            alt="website logo"
            width={736}
            height={560}
            className="w-[47px] 768:w-[96px] aspect-[96/69]"
          />
          <p className="text-2xl 768:text-5xl text-white font-black uppercase mt-1 768:mt-4">
            <Trans
              i18nKey="general.comingSoon"
              components={{
                span: <span className="block italic 768:text-4xl text-xl" />
              }}
            />
          </p>
        </div>
      </div>
    </ModalWrapper>
  );
};
