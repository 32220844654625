import { FC } from 'react';
import { Child } from 'react-easyrocket';
import { CONTENT_URL } from 'src/api';
import { Skeletons } from 'src/components/Skeletons';
import { twJoin } from 'tailwind-merge';

interface StoriesListProps {
  handleOpenStory: (story: Child, index: number) => void;
  sortedChilds: Child[] | undefined;
  isStoryVisited: (story: Child) => boolean;
  isFetching: boolean;
}

export const StoriesList: FC<StoriesListProps> = ({
  handleOpenStory,
  sortedChilds,
  isStoryVisited,
  isFetching
}) => (
  <div className="scrollbar-hide h-[68px] w-full overflow-x-scroll px-2 mt-4">
    <div className="flex justify-start gap-4">
      {isFetching ? (
        <Skeletons count={4} className="h-[68px] w-[68px] flex-none rounded-full" />
      ) : (
        sortedChilds &&
        sortedChilds.map((story, index) => (
          <div
            className={twJoin(
              'h-[68px] w-[68px] flex-none rounded-full bg-cover bg-no-repeat',
              isStoryVisited(story) ? 'border-none' : 'border-white border-2 border-solid'
            )}
            key={story.code}
            style={{
              backgroundImage: `url(${CONTENT_URL + story.image})`
            }}
            onClick={() => handleOpenStory(story, index)}
          />
        ))
      )}
    </div>
  </div>
);
