import { PropsWithChildren } from 'react';

import { MessagesContext } from './context';
import { MessagesContextType } from './types';

export const MessagesProvider = ({
  children,
  contextValue
}: PropsWithChildren<{ contextValue: MessagesContextType }>) => {
  return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>;
};
