import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ShopItemType } from 'react-easyrocket';
import ByBonusModalMoney from './ByBonusModalMoney';
import ByBonusModalFreespins from './ByBonusModalFreespins';
import { SimpleClose } from 'src/components/svg/Closes';
import ModalWrapper from 'src/components/ModalWrapper';
import { useModalContext } from 'src/hooks/useModalContext';

interface ModalProps {
  item: ShopItemType;
  isFreeSpins?: boolean;
  isMoney?: boolean;
  isCoins: boolean;
}

const ShopModalEvent = () => {
  const { closeModal, modalProps } = useModalContext<ModalProps>();

  const { t } = useTranslation();
  return (
    <ModalWrapper showCross={false}>
      <div
        className="1024:w-[915px] w-full min-h-[400px] flex-col  pt-4 flex items-center justify-center"
        style={{ background: 'var(--se_bg_modal)' }}>
        <SimpleClose
          onShow={() => {
            closeModal?.();
          }}
          className="self-end mr-6"
        />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center">
            {!modalProps?.isCoins && (
              <p className="text-center first-letter:uppercase text-mp_title drop-shadow-purpleForText 1024:text-4xl 768:text-2xl text-base font-bold w-full">
                {t('shop.noEggs')}
              </p>
            )}
            {modalProps?.isMoney && modalProps?.isCoins && (
              <p
                style={{ filter: 'drop-shadow( 0px 3px 0px var(--mp_card_shadow))' }}
                className="text-center first-letter:uppercase text-mp_title drop-shadow-purpleForText 1024:text-4xl text-base 768:text-2xl font-black w-full">
                {t('shop.bonusMoney')}
              </p>
            )}
            {modalProps?.isFreeSpins && modalProps?.isCoins && (
              <p
                style={{ filter: 'drop-shadow( 0px 3px 0px var(--mp_card_shadow))' }}
                className="text-center first-letter:uppercase text-mp_title drop-shadow-purpleForText 1024:text-4xl text-base 768:text-2xl font-black w-full">
                {t('shop.freeSpins')}
              </p>
            )}
          </div>
          <div className="flex flex-col items-center rounded-[20px] justify-center w-full pt-3 px-5">
            {modalProps?.isMoney && (
              <ByBonusModalMoney bonus={modalProps?.item} isCoins={modalProps?.isCoins} />
            )}
            {modalProps?.isFreeSpins && (
              <ByBonusModalFreespins bonus={modalProps?.item} isCoins={modalProps?.isCoins} />
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

// eslint-disable-next-line no-restricted-exports
export default memo(ShopModalEvent);
