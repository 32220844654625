import { useRef } from 'react';
import { Arrow } from '../svg';
import { useDropdown } from '../../hooks/useDropdown';
import './styles.css';
import { DropDownOption } from '../../types';
import classNames from 'classnames';
import { useClickOutside } from '../../hooks/useClickOutside';

type Props = {
  options?: DropDownOption[];
  value?: string | undefined;
  placeholder?: string;
  onChange?: (element?: string) => void;
  disabled?: boolean;
  className?: string;
  label?: string;
  labelWithPadding?: boolean;
  classNameArrow?: string;
};

export const Dropdown = ({
  options = [],
  value,
  onChange,
  placeholder,
  disabled,
  className,
  label,
  labelWithPadding,
  classNameArrow
}: Props) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const {
    value: selectedValue,
    selectedOptionName,
    onSelect,
    close,
    toggle,
    showList
  } = useDropdown({
    value,
    onChange,
    options
  });

  useClickOutside(dropdownRef, close);

  return (
    <div className="relative text-[16px] w-full cursor-pointer z-10" ref={dropdownRef}>
      {label ? (
        <label
          className={classNames('text-text-secondary mb-2 block', labelWithPadding && 'pl-[20px]')}>
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'flex relative flex-row items-center h-[42px] z-[11] border border-solid border-input-border rounded-[20px] bg-text-secondary',
          className && `${className}`,
          disabled && 'bg-input-disabled cursor-not-allowed'
        )}
        onClick={() => disabled || toggle()}>
        {selectedOptionName || selectedValue || value ? (
          <p className="text-[16px] text-text-primary py-2.5 w-full px-5">
            {selectedOptionName || selectedValue || value}
          </p>
        ) : (
          <p className="text-[16px] text-input-border py-2.5 w-full px-5">{placeholder}</p>
        )}
        <Arrow
          className={classNames(
            'absolute right-5 w-[12px] h-full stroke-[3px] transition-transform stroke-[var(--input-border)]',
            classNameArrow ? `${classNameArrow}` : '',
            { opened: showList }
          )}
        />
      </div>
      {showList && (
        <div
          className={classNames(
            'entrance absolute rounded-b-[20px] bg-[white] z-10 flex flex-col items-start overflow-y-scroll w-full max-h-[150px] pt-4 pb-2 shadow',
            label ? 'top-[53px]' : 'top-[20px]'
          )}>
          {options.map((option) => (
            <div
              className="text-[12px] cursor-pointer py-2 px-5 w-full jxsm:px-2.5 hover:bg-input-border"
              onClick={() => onSelect(option.id, option)}
              key={option.id}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
