import { SingleNavbarItem } from '../SingleNavbarItem';
import { useDrawer } from 'src/hooks/useDrawer';
import { useTranslation } from 'react-i18next';
import { LiveCasinoTvMobile, SlotsMobile } from 'src/components/svg';
import { FC } from 'react';
import { useSportContext } from 'src/context/SportProvider';
import { twJoin } from 'tailwind-merge';
import { useNavigateLng } from 'src/lib';

interface SlotLinksProps {
  activeCategory: {
    GAME: boolean;
    LIVE: boolean;
    VIP: boolean;
    PROVIDERS: boolean;
  };
}

export const SlotLinks: FC<SlotLinksProps> = ({ activeCategory }) => {
  const { t } = useTranslation();
  const navigate = useNavigateLng();
  const { closeProvidersSearch } = useDrawer();
  const { isSportPage } = useSportContext();

  const handleSlotsClick = () => {
    navigate('/games/slots');
    closeProvidersSearch?.();
  };

  const handleLiveClick = () => {
    navigate('/games/live');
    closeProvidersSearch?.();
  };

  return (
    <>
      <SingleNavbarItem
        onClick={handleSlotsClick}
        textHighlightCondition={activeCategory.GAME}
        title={t('categoriesItem.slots')}>
        <SlotsMobile className={twJoin('w-[26px] h-auto', activeCategory.GAME && 'fill-yellow')} />
      </SingleNavbarItem>
      <SingleNavbarItem
        onClick={handleLiveClick}
        textHighlightCondition={activeCategory.LIVE}
        title={isSportPage ? t('gameCategories.liveGames') : t('gameCategories.live')}>
        <LiveCasinoTvMobile
          className={twJoin('w-[26px] h-auto', activeCategory.LIVE && 'fill-yellow')}
        />
      </SingleNavbarItem>
    </>
  );
};
