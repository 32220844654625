/* eslint-disable react/no-unknown-property */
export const PromoCode = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_17978_47739"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_17978_47739)">
        <path
          d="M20.6714 14.7131C20.6168 15.4825 20.4784 16.2436 20.2586 16.983C20.2083 17.1515 20.1545 17.3175 20.0961 17.4836C19.6558 18.7529 18.9757 19.9257 18.0927 20.9383C16.4835 22.7664 14.1879 24 11.3669 24C9.93311 24 8.635 23.6832 7.49591 23.1276C4.85634 21.8435 3.07055 19.2788 2.43786 16.41C2.17243 15.2105 2.11428 13.9744 2.26593 12.7553C2.52321 10.7169 3.54753 7.8575 5.15577 6.76517V6.78624C5.15807 7.64698 6.37905 7.84814 6.66906 7.03651C9.39633 -0.594432 15.7911 0.0113738 15.7911 0.0113738C13.7409 3.00658 16.1279 5.07058 16.7185 5.64014C16.8048 5.72366 16.9093 5.78602 17.0238 5.8224C17.1383 5.85878 17.2596 5.86814 17.3783 5.84981C17.497 5.83142 17.6099 5.78587 17.7081 5.71661C17.8062 5.64739 17.887 5.55638 17.9441 5.45069C18.2548 4.87906 18.7004 4.39203 19.2423 4.032C19.3148 3.9829 19.3896 3.936 19.4645 3.89165C18.35 7.15464 20.9965 10.0784 20.6714 14.7131Z"
          fill="url(#paint0_linear_17978_47739)"
        />
        <path
          d="M20.2582 16.9823C20.2079 17.1507 20.1541 17.3168 20.0956 17.4829C19.6554 18.7521 18.9753 19.925 18.0923 20.9375C16.4831 22.7657 14.1875 23.9993 11.3666 23.9993C9.93275 23.9993 8.63464 23.6825 7.49555 23.1268C4.85598 21.8427 3.07019 19.278 2.4375 16.4093C2.74508 14.2001 3.39179 11.1348 4.67939 9.9993C4.65136 10.0589 3.82686 11.8494 5.33219 12.8553C6.86185 13.8775 6.84318 9.79707 8.56816 8.04286C10.2943 6.28861 12.424 5.88867 12.424 5.88867C12.424 5.88867 10.1435 10.1596 13.1011 12.1595C16.0576 14.1581 17.8621 9.89768 17.3113 8.4943C17.3111 8.49416 20.4956 12.6728 20.2582 16.9823Z"
          fill="url(#paint1_linear_17978_47739)"
        />
        <path
          d="M9.13514 16.7565C8.70511 16.7565 8.28477 16.629 7.92722 16.3901C7.56967 16.1512 7.29098 15.8117 7.12643 15.4144C6.96189 15.0171 6.91883 14.58 7.00269 14.1582C7.08659 13.7365 7.29367 13.349 7.59775 13.045C7.90178 12.7409 8.28919 12.5339 8.71096 12.45C9.13269 12.3661 9.56987 12.4092 9.96712 12.5737C10.3644 12.7382 10.704 13.0169 10.9429 13.3745C11.1818 13.732 11.3093 14.1524 11.3093 14.5824C11.3086 15.1588 11.0793 15.7114 10.6718 16.119C10.2641 16.5266 9.71157 16.7559 9.13514 16.7565ZM9.13514 13.7229C8.96512 13.7229 8.7989 13.7733 8.65754 13.8678C8.51618 13.9622 8.40597 14.0965 8.34088 14.2536C8.27584 14.4107 8.2588 14.5836 8.29197 14.7503C8.32514 14.9171 8.40703 15.0702 8.52722 15.1905C8.64746 15.3107 8.80063 15.3926 8.96738 15.4258C9.13413 15.4589 9.30703 15.4419 9.46408 15.3769C9.62119 15.3118 9.75544 15.2016 9.84991 15.0602C9.94437 14.9189 9.99482 14.7527 9.99482 14.5826C9.99458 14.3547 9.90391 14.1362 9.74277 13.975C9.58159 13.8138 9.36309 13.7232 9.13514 13.7229Z"
          fill="#1F1F1F"
        />
        <path
          d="M13.7484 21.4167C13.3184 21.4167 12.8981 21.2892 12.5405 21.0503C12.1829 20.8114 11.9043 20.4718 11.7397 20.0745C11.5752 19.6772 11.5321 19.2401 11.616 18.8184C11.6999 18.3966 11.9069 18.0092 12.211 17.7052C12.5151 17.4011 12.9025 17.194 13.3242 17.1101C13.746 17.0263 14.1831 17.0693 14.5804 17.2339C14.9777 17.3984 15.3173 17.6771 15.5561 18.0346C15.795 18.3922 15.9225 18.8125 15.9225 19.2426C15.9219 19.8189 15.6926 20.3716 15.285 20.7791C14.8774 21.1868 14.3248 21.416 13.7484 21.4167ZM13.7484 18.3831C13.5784 18.3831 13.4122 18.4335 13.2708 18.5279C13.1294 18.6224 13.0192 18.7567 12.9542 18.9137C12.8891 19.0708 12.872 19.2437 12.9052 19.4104C12.9384 19.5772 13.0203 19.7304 13.1405 19.8506C13.2607 19.9709 13.4139 20.0528 13.5807 20.0859C13.7474 20.1191 13.9203 20.102 14.0774 20.037C14.2344 19.9719 14.3687 19.8618 14.4632 19.7204C14.5577 19.579 14.6081 19.4128 14.6081 19.2428C14.6079 19.0148 14.5172 18.7963 14.3561 18.635C14.1949 18.4738 13.9764 18.3832 13.7484 18.3829V18.3831Z"
          fill="#1F1F1F"
        />
        <path
          d="M14.0089 13.3906L7.94141 19.4584L8.87088 20.3878L14.9383 14.32L14.0089 13.3906Z"
          fill="#1F1F1F"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17978_47739"
          x1="13"
          y1="6"
          x2="13"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6A38" />
          <stop offset="1" stopColor="#FA3F00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17978_47739"
          x1="11"
          y1="8.49902"
          x2="11.3542"
          y2="23.9993"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFDA82" />
          <stop offset="1" stopColor="#FFB300" />
        </linearGradient>
      </defs>
    </svg>
  );
};
