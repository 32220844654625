import { memo } from 'react';
import { getCurrencyIcon } from 'src/utils';
import { totalJackpot } from './fakeSliderJackpots';
import { Jackpot, useApiContext, useGetShortBalance, useGetUserCountry } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';

export const jackpotColors: { [type: string]: { [key: string]: string } } = {
  mini: { color: '#5C89FF' },
  middle: { color: '#1BB2A9' },
  grand: { color: '#FF493E' },
  total: { color: '#ff465f' }
};

const compactFormatter = Intl.NumberFormat('en', { notation: 'compact' });
const numberFormatter = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 0
});

interface DrawsBetweenJackpotsProps {
  isLineBreak?: boolean;
  isShortedNumbers?: boolean;
  separate?: 'and' | 'dash';
  jackpot: Jackpot | undefined;
  classNames: string;
  numberClassNames?: string;
}

const DrawsBetweenJackpots = ({
  isLineBreak = true,
  isShortedNumbers,
  jackpot,
  separate = 'and',
  classNames,
  numberClassNames
}: DrawsBetweenJackpotsProps) => {
  const { t } = useTranslation();
  const { data: currentBalance } = useGetShortBalance();
  const { isAuthenticated } = useApiContext();
  const { userCountry } = useGetUserCountry();
  const currency = isAuthenticated
    ? currentBalance?.currency || 'EUR'
    : userCountry?.currency[0] || 'EUR';

  const currencyIcon = getCurrencyIcon(currency);
  const format = (value: number) => compactFormatter.format(value);

  if (!jackpot || !jackpot.title) {
    return null;
  }

  const jackpotTitle = jackpot.title.toLowerCase();
  const colorStyle = { color: jackpotColors[jackpotTitle].color };

  const fromValue = totalJackpot[currency][jackpotTitle].from;
  const toValue = totalJackpot[currency][jackpotTitle].to;

  const fromFormatted = isShortedNumbers ? format(fromValue) : numberFormatter.format(fromValue);
  const toFormatted = isShortedNumbers ? format(toValue) : numberFormatter.format(toValue);

  return (
    <p className={classNames}>
      {t('jackpots.draws')} {isLineBreak && <br />}
      <span className={numberClassNames} style={colorStyle}>
        {currencyIcon} {fromFormatted}
      </span>{' '}
      {separate === 'and' ? t('jackpots.and') : ' - '}{' '}
      <span className={numberClassNames} style={colorStyle}>
        {currencyIcon} {toFormatted}
      </span>
    </p>
  );
};

export default memo(DrawsBetweenJackpots);
