import { useDeviceDetect } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import logoMini from 'src/assets/images/logoMini.webp';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';
import { useDrawer } from 'src/hooks/useDrawer';
import { Download } from '../svg';
import { replaceWithMiniLogo } from 'src/event/utils/replaceWithBigLogo';

export const PWACards = () => {
  const [addToHomeScreen] = useAddToHomescreenPrompt();
  const { t } = useTranslation();
  const { closeLeft } = useDrawer();
  const { isMobile } = useDeviceDetect();

  const handlePWA = () => {
    addToHomeScreen?.();
    isMobile && closeLeft();
  };

  return (
    <button
      onClick={handlePWA}
      className="w-full flex items-center justify-between py-[7px] px-[16px] rounded-[8px] bg-button-primary hover:bg-button-primary-hover transition-all text-background-fivefold text-[16px] font-bold">
      <img className="w-[50px]" src={replaceWithMiniLogo(logoMini)} alt="logo" />
      <p>{t('general.installApp')}</p>
      <Download fill="black" className="w-[30px] h-[30px]" />
    </button>
  );
};
