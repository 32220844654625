import { FC } from 'react';
import { ProgressiveTooltipControllerProps } from './ProgressiveTooltipController.types';
import { Arrow } from '../svg';
import { twMerge } from 'tailwind-merge';

export const ProgressiveTooltipController: FC<ProgressiveTooltipControllerProps> = ({
  icon,
  controllerRef,
  active,
  ...rest
}) => {
  return (
    <div
      className="flex flex-row justify-center items-center gap-1 w-[35px] h-[25px] transition-all rounded cursor-pointer relative"
      ref={controllerRef}
      {...rest}>
      <div className="[&_>svg>path]:hover:fill-yellow">{icon}</div>
      <Arrow
        className={twMerge(
          'w-[13px] h-full stroke-text-hexadecimal stroke-[3px] transition-all self-center flex justify-center items-center absolute left-[100%]',
          active ? '-rotate-90' : 'rotate-0'
        )}
      />
    </div>
  );
};
