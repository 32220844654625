import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const BitcoinCashIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#0AA77B"
      />
      <path
        d="M14.0877 8.0531C13.7005 6.9036 12.5994 6.7474 11.2926 6.9223L10.9197 5.3108L9.9297 5.5407L10.296 7.1258L9.5029 7.3095L9.1355 5.72L8.1576 5.951L8.5338 7.5746L6.5318 8.0388L6.7771 9.0937L7.4932 8.9276C7.62133 8.88501 7.76088 8.89281 7.88347 8.94941C8.00606 9.006 8.1025 9.10715 8.1532 9.2323L9.1894 13.7082C9.19728 13.7554 9.1956 13.8036 9.18445 13.8501C9.1733 13.8966 9.15291 13.9404 9.1245 13.9788C9.09771 14.0185 9.06295 14.0522 9.02242 14.0778C8.98189 14.1034 8.93649 14.1202 8.8891 14.1273L8.173 14.2934L8.2632 15.51L10.2432 15.0502L10.6271 16.7068L11.6248 16.4758L11.2409 14.8192L12.034 14.6355L12.4102 16.2602L13.4002 16.0314L13.0207 14.3946C14.6707 13.904 15.741 13.2253 15.5254 11.6248C15.5244 11.397 15.4785 11.1716 15.3903 10.9616C15.3021 10.7516 15.1733 10.561 15.0114 10.4008C14.8494 10.2406 14.6575 10.1139 14.4465 10.028C14.2355 9.94204 14.0097 9.89855 13.7819 9.9C13.7302 9.9 13.6796 9.9 13.629 9.9077C13.899 9.69222 14.0903 9.39363 14.1733 9.05829C14.2562 8.72294 14.2261 8.3696 14.0877 8.0531ZM13.5146 11.891C13.7995 13.1186 11.6633 13.4651 10.9923 13.6213L10.4863 11.44C11.1584 11.2849 13.2176 10.6062 13.5146 11.891ZM12.3442 8.9287C12.6027 10.0474 10.8196 10.3191 10.2542 10.45L9.7977 8.47C10.3609 8.3369 12.0714 7.7528 12.3442 8.9287Z"
        fill="white"
      />
    </svg>
  );
};
