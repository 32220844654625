import { FC } from 'react';
import { TableCell } from 'src/components/Table/components/TableCell';
import { makeFloat } from 'react-easyrocket';
import { getCurrencyIcon } from 'src/utils';

interface TableRowTransactionProps {
  id: number;
  type: string;
  amount: number;
  balance: number;
  date: string;
  currency: string;
}

export const TransactionTableRow: FC<TableRowTransactionProps> = ({
  id,
  type,
  amount,
  balance,
  date,
  currency
}) => {
  return (
    <tr className="even:bg-bg-evenRowTable">
      <TableCell item={id} />
      <TableCell item={type} />
      <TableCell item={makeFloat(amount)} />
      <TableCell item={`${getCurrencyIcon(currency)} ${makeFloat(balance)}`} />
      <TableCell item={date} />
    </tr>
  );
};
