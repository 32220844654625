import { useApiContext } from 'react-easyrocket';
import { useEffect } from 'react';
import { smarticoLogout } from 'src/utils/smartico';

export const useDoSomethingOnLogout = () => {
  const { isAuthenticated } = useApiContext();

  useEffect(() => {
    if (!isAuthenticated) {
      smarticoLogout();
    }
  }, [isAuthenticated]);
};
