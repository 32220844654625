import classNames from 'classnames';
import { FC, SVGProps } from 'react';

export const Spin = ({ classNameSvg }: { classNameSvg?: string }) => {
  return (
    <div className="flex justify-center mr-2.5">
      <svg
        role="status"
        className={classNames(
          'inline w-6 h-6 text-background-fivefold animate-spin',
          classNameSvg ? `${classNameSvg}` : ''
        )}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#8188af"></path>
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"></path>
      </svg>
    </div>
  );
};

export const Wallet: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M22.0371 0.291992H2.91211C2.31537 0.291992 1.74308 0.529045 1.32112 0.951002C0.899162 1.37296 0.662109 1.94525 0.662109 2.54199V18.292C0.662109 18.8887 0.899162 19.461 1.32112 19.883C1.74308 20.3049 2.31537 20.542 2.91211 20.542H20.9121C21.5088 20.542 22.0811 20.3049 22.5031 19.883C22.9251 19.461 23.1621 18.8887 23.1621 18.292V5.91699C23.1621 5.61862 23.0436 5.33248 22.8326 5.1215C22.6216 4.91052 22.3355 4.79199 22.0371 4.79199H4.03711C3.73874 4.79199 3.45259 4.67347 3.24161 4.46249C3.03064 4.25151 2.91211 3.96536 2.91211 3.66699C2.91211 3.36862 3.03064 3.08248 3.24161 2.8715C3.45259 2.66052 3.73874 2.54199 4.03711 2.54199H23.1621V1.41699C23.1621 1.11862 23.0436 0.832475 22.8326 0.621497C22.6216 0.410519 22.3355 0.291992 22.0371 0.291992ZM19.2246 10.9795C19.5584 10.9795 19.8846 11.0785 20.1621 11.2639C20.4396 11.4493 20.6559 11.7129 20.7837 12.0212C20.9114 12.3296 20.9448 12.6689 20.8797 12.9962C20.8146 13.3235 20.6539 13.6242 20.4179 13.8602C20.1819 14.0962 19.8812 14.257 19.5538 14.3221C19.2265 14.3872 18.8872 14.3538 18.5788 14.226C18.2705 14.0983 18.0069 13.882 17.8215 13.6045C17.6361 13.327 17.5371 13.0007 17.5371 12.667C17.5371 12.2194 17.7149 11.7902 18.0314 11.4737C18.3478 11.1573 18.7771 10.9795 19.2246 10.9795Z"
        fill="white"
      />
    </svg>
  );
};

export const Promo: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.90529 0.542034C6.97234 0.531807 5.09052 2.39317 5.94961 4.70453H2.70756C2.16508 4.70453 1.64481 4.92004 1.26121 5.30363C0.877612 5.68723 0.662109 6.2075 0.662109 6.74999V8.79544C0.662109 9.06669 0.769861 9.32682 0.961659 9.51862C1.15346 9.71042 1.41359 9.81817 1.68484 9.81817H10.8894V6.74999H12.9348V9.81817H22.1394C22.4106 9.81817 22.6708 9.71042 22.8626 9.51862C23.0544 9.32682 23.1621 9.06669 23.1621 8.79544V6.74999C23.1621 6.2075 22.9466 5.68723 22.563 5.30363C22.1794 4.92004 21.6591 4.70453 21.1167 4.70453H17.8746C19.0712 1.36022 14.5712 -1.00228 12.4951 1.88181L11.9121 2.65908L11.3292 1.86135C10.6848 0.951125 9.79506 0.552261 8.90529 0.542034ZM8.84393 2.65908C9.75415 2.65908 10.2144 3.76363 9.57006 4.40794C8.92575 5.05226 7.8212 4.59203 7.8212 3.68181C7.8212 3.41056 7.92895 3.15043 8.12075 2.95863C8.31255 2.76683 8.57268 2.65908 8.84393 2.65908ZM14.9803 2.65908C15.8905 2.65908 16.3507 3.76363 15.7064 4.40794C15.0621 5.05226 13.9576 4.59203 13.9576 3.68181C13.9576 3.41056 14.0653 3.15043 14.2571 2.95863C14.4489 2.76683 14.709 2.65908 14.9803 2.65908ZM1.68484 10.8409V19.0227C1.68484 19.5652 1.90034 20.0855 2.28394 20.4691C2.66753 20.8527 3.1878 21.0682 3.73029 21.0682H20.0939C20.6364 21.0682 21.1567 20.8527 21.5403 20.4691C21.9239 20.0855 22.1394 19.5652 22.1394 19.0227V10.8409H12.9348V19.0227H10.8894V10.8409H1.68484Z"
        fill="white"
      />
    </svg>
  );
};

export const Vip: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.9121 18.3732L6.63119 22.4052C6.36952 22.6193 6.08406 22.7202 5.77482 22.7078C5.46558 22.6964 5.19201 22.6074 4.95413 22.4409C4.71626 22.2744 4.53214 22.0484 4.40178 21.763C4.27047 21.4775 4.26428 21.1683 4.38322 20.8352L6.41709 14.2341L1.24321 10.5588C0.95776 10.3685 0.77935 10.1188 0.707986 9.80953C0.636622 9.50028 0.648516 9.21483 0.743668 8.95316C0.83882 8.69149 1.00534 8.45932 1.24321 8.25665C1.48109 8.05493 1.76655 7.95406 2.09958 7.95406H8.48665L10.5562 1.10313C10.6751 0.770104 10.8597 0.514146 11.11 0.335261C11.3593 0.157327 11.6267 0.0683594 11.9121 0.0683594C12.1976 0.0683594 12.4649 0.157327 12.7142 0.335261C12.9645 0.514146 13.1491 0.770104 13.268 1.10313L15.3376 7.95406H21.7246C22.0577 7.95406 22.3431 8.05493 22.581 8.25665C22.8189 8.45932 22.9854 8.69149 23.0806 8.95316C23.1757 9.21483 23.1876 9.50028 23.1162 9.80953C23.0449 10.1188 22.8665 10.3685 22.581 10.5588L17.4071 14.2341L19.441 20.8352C19.5599 21.1683 19.5542 21.4775 19.4239 21.763C19.2926 22.0484 19.108 22.2744 18.8701 22.4409C18.6322 22.6074 18.3586 22.6964 18.0494 22.7078C17.7402 22.7202 17.4547 22.6193 17.193 22.4052L11.9121 18.3732Z"
        fill="white"
      />
    </svg>
  );
};

export const GameHistory: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.9121 0.708984C9.68707 0.708984 7.512 1.36879 5.66195 2.60495C3.8119 3.84112 2.36996 5.59813 1.51847 7.65379C0.666984 9.70946 0.444196 11.9715 0.87828 14.1537C1.31236 16.336 2.38382 18.3406 3.95716 19.9139C5.5305 21.4873 7.53506 22.5587 9.71735 22.9928C11.8996 23.4269 14.1616 23.2041 16.2173 22.3526C18.273 21.5011 20.03 20.0592 21.2661 18.2091C22.5023 16.3591 23.1621 14.184 23.1621 11.959C23.1564 8.97705 21.9693 6.11889 19.8607 4.01035C17.7522 1.9018 14.894 0.714702 11.9121 0.708984ZM17.9698 12.8244H11.9121C11.6826 12.8244 11.4625 12.7332 11.3002 12.5709C11.1379 12.4086 11.0467 12.1885 11.0467 11.959V5.90129C11.0467 5.67178 11.1379 5.45166 11.3002 5.28937C11.4625 5.12708 11.6826 5.03591 11.9121 5.03591C12.1416 5.03591 12.3617 5.12708 12.524 5.28937C12.6863 5.45166 12.7775 5.67178 12.7775 5.90129V11.0936H17.9698C18.1993 11.0936 18.4194 11.1848 18.5817 11.3471C18.744 11.5094 18.8352 11.7295 18.8352 11.959C18.8352 12.1885 18.744 12.4086 18.5817 12.5709C18.4194 12.7332 18.1993 12.8244 17.9698 12.8244Z"
        fill="white"
      />
    </svg>
  );
};

export const LogOut: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.4745 12.3965C13.956 12.3965 13.537 12.8165 13.537 13.334V17.084C13.537 17.6005 13.117 18.0215 12.5995 18.0215H9.78699V3.95898C9.78699 3.15836 9.27699 2.44305 8.51011 2.1768L8.23261 2.08398H12.5995C13.117 2.08398 13.537 2.50492 13.537 3.02148V5.83398C13.537 6.35148 13.956 6.77148 14.4745 6.77148C14.9929 6.77148 15.412 6.35148 15.412 5.83398V3.02148C15.412 1.47086 14.1501 0.208984 12.5995 0.208984H2.52136C2.48574 0.208984 2.45574 0.224922 2.42105 0.229609C2.37605 0.225859 2.33292 0.208984 2.28699 0.208984C1.25292 0.208984 0.411987 1.04992 0.411987 2.08398V18.959C0.411987 19.7596 0.921987 20.4749 1.68886 20.7412L7.33074 22.6218C7.52199 22.6809 7.7123 22.709 7.91199 22.709C8.94605 22.709 9.78699 21.868 9.78699 20.834V19.8965H12.5995C14.1501 19.8965 15.412 18.6346 15.412 17.084V13.334C15.412 12.8165 14.9929 12.3965 14.4745 12.3965Z"
        fill="white"
      />
      <path
        d="M22.6373 8.92135L18.8873 5.17135C18.6192 4.90322 18.2161 4.8226 17.8654 4.96791C17.5157 5.11322 17.287 5.45541 17.287 5.83416V8.64666H13.537C13.0195 8.64666 12.5995 9.06666 12.5995 9.58416C12.5995 10.1017 13.0195 10.5217 13.537 10.5217H17.287V13.3342C17.287 13.7129 17.5157 14.0551 17.8654 14.2004C18.2161 14.3457 18.6192 14.2651 18.8873 13.997L22.6373 10.247C23.0039 9.88041 23.0039 9.28791 22.6373 8.92135Z"
        fill="white"
      />
    </svg>
  );
};

export const Info = ({ fill = 'white', className }: { fill?: string; className?: string }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.34009C7.94365 4.34009 4.25 8.03374 4.25 12.5901C4.25 17.1464 7.94365 20.8401 12.5 20.8401C17.0563 20.8401 20.75 17.1464 20.75 12.5901C20.75 8.03374 17.0563 4.34009 12.5 4.34009ZM2.75 12.5901C2.75 7.20531 7.11522 2.84009 12.5 2.84009C17.8848 2.84009 22.25 7.20531 22.25 12.5901C22.25 17.9749 17.8848 22.3401 12.5 22.3401C7.11522 22.3401 2.75 17.9749 2.75 12.5901Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.8401C11 11.4259 11.3358 11.0901 11.75 11.0901H12.5C12.9142 11.0901 13.25 11.4259 13.25 11.8401V16.3401C13.6642 16.3401 14 16.6759 14 17.0901C14 17.5043 13.6642 17.8401 13.25 17.8401H12.5C12.0858 17.8401 11.75 17.5043 11.75 17.0901V12.5901C11.3358 12.5901 11 12.2543 11 11.8401Z"
        fill={fill}
      />
      <path
        d="M12.3125 9.59009C12.9338 9.59009 13.4375 9.08641 13.4375 8.46509C13.4375 7.84377 12.9338 7.34009 12.3125 7.34009C11.6912 7.34009 11.1875 7.84377 11.1875 8.46509C11.1875 9.08641 11.6912 9.59009 12.3125 9.59009Z"
        fill={fill}
      />
    </svg>
  );
};

export const CupIcon = ({ className, fill = 'white' }: { className?: string; fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1462 19.3047L25.3535 18.4285L24.6571 14.3678L27.6064 11.493L27.1452 10.0735L23.0694 9.4812L21.2468 5.7884H19.7538L17.9313 9.4812L13.8537 10.0737L13.3955 11.4942L16.3435 14.3678L15.6469 18.4295L16.8554 19.3042L20.5003 17.3879L24.1462 19.3047ZM22.5256 15.8047L20.5003 14.74L18.475 15.8048L18.8618 13.5496L17.2233 11.9524L19.4876 11.6234L20.5003 9.57157L21.513 11.6234L23.7773 11.9524L22.1388 13.5496L22.5256 15.8047Z"
        fill={fill}
      />
      <mask
        id="mask0_15635_10885"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="41"
        height="41">
        <path d="M0.50293 -0.00390244H40.5029V39.9961H0.50293V-0.00390244Z" fill="white" />
      </mask>
      <g mask="url(#mask0_15635_10885)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.04 39.9961H30.9638V34.5341C30.9638 32.1394 29.0226 30.1983 26.628 30.1983H14.3758C11.9812 30.1983 10.04 32.1394 10.04 34.5341V39.9961ZM12.3838 37.6523V34.5341C12.3838 33.4338 13.2756 32.542 14.3758 32.542H26.628C27.7282 32.542 28.62 33.4338 28.62 34.5341V37.6523H12.3838Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8037 19.4492H32.8975C36.3378 19.4492 39.1268 16.6602 39.1268 13.2198V5.78625H32.0913V8.13H36.783V13.2198C36.783 15.3658 35.0434 17.1055 32.8975 17.1055H30.8037V19.4492Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.1082 17.1055C5.96231 17.1055 4.22266 15.3658 4.22266 13.2198V8.13H8.91437V5.78625H1.87891V13.2198C1.87891 16.6602 4.66785 19.4492 8.1082 19.4492H10.202V17.1055H8.1082Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0626 32.543H24.9407V26.6679L26.5595 25.907C26.5595 25.907 26.5595 25.907 26.5595 25.907C30.6297 23.9938 33.2279 19.9012 33.2279 15.4039V-0.00296783H7.77539V15.4039C7.77539 19.9012 10.3736 23.9937 14.4437 25.9069C14.4437 25.9069 14.4437 25.907 14.4437 25.9069L16.0626 26.6679V32.543ZM18.4063 30.1992V25.1799L15.4408 23.7859C12.1927 22.2591 10.1191 18.993 10.1191 15.4039V2.34078H30.8841V15.4039C30.8841 18.993 28.8106 22.2591 25.5625 23.7859L22.597 25.1799V30.1992H18.4063Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export const AutocompleteIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3634 0.331331L6.50323 4.23109L2.60347 0.370946C2.51042 0.278838 2.40013 0.205961 2.27891 0.156474C2.15769 0.106987 2.02792 0.0818597 1.89699 0.0825281C1.76606 0.0831966 1.63654 0.109647 1.51584 0.160369C1.39513 0.211091 1.2856 0.285091 1.19349 0.378144C1.10138 0.471197 1.0285 0.581482 0.979015 0.7027C0.929528 0.823918 0.904401 0.953697 0.90507 1.08463C0.905738 1.21555 0.932188 1.34507 0.98291 1.46578C1.03363 1.58648 1.10763 1.69602 1.20069 1.78813L5.81406 6.35463C5.90704 6.44686 6.01731 6.51985 6.13853 6.56941C6.25976 6.61898 6.38957 6.64414 6.52054 6.64347C6.65151 6.64281 6.78106 6.61631 6.90177 6.56552C7.02249 6.51472 7.132 6.44061 7.22404 6.34744L11.7905 1.73406C12.1786 1.34208 12.1753 0.712085 11.7833 0.324082C11.3914 -0.0539226 10.7514 -0.060655 10.3634 0.331331Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};
