export const GAME_ORIGIN = window.origin.includes('localhost')
  ? import.meta.env.VITE_DOMAIN
  : window.origin;

export const GAME_PATH = '/gpt/easycards/index.html';

export const GAME_NAME = 'cardGame';

export const GPT_GAME_MODAL_BG = 'rgba(0, 0, 0, 0.8)';

export const GAME_COLORS = {
  BackgroundColor: ['#211753', '#562892', '#4a2584', '#902fa5', '#50158c', '#551b89'],
  ButtonBackColor: ['#fff000', '#2a1f63', '#7c7c7c', '#562892'],
  ButtonBorderColor: ['#5a529d', '#ac3edf', '#484547', '#908f90'],
  SelectionColor: ['#ffde00', '#7974bd', '#b2aeea', '#f1eef4'],
  TextColor: ['#1f1f1f', '#f1eef4', '#fcd666', '#f1eef4'],
  WindowBorderColor: ['#20004b', '#953bba']
};
