import classNames from 'classnames';
import { useBackgroundScrollLock, useWindowSize } from 'react-easyrocket';
import { useDrawer } from 'src/hooks/useDrawer';
import { useMessage } from 'src/modules/messages';
import { NotificationsStub } from './NotificationsStub';
import { NotificationsList } from './NotificationsList';
import { NotificationsHead } from './NotificationsHead';

export const NotificationsDrawer = () => {
  const { isLeftOpen } = useDrawer();
  const { messageHistory } = useMessage();
  const { is640 } = useWindowSize();

  useBackgroundScrollLock(isLeftOpen && !is640, [isLeftOpen, !is640]);

  return (
    <div
      className={classNames(
        'fixed top-0 h-screen bg-nb-bg z-[1000] notifications-blur py-5 px-4 overflow-auto',
        {
          'left-[270px]': isLeftOpen && is640,
          '768:left-[70px]': !isLeftOpen,
          'left-[0px] w-full': !is640,
          'w-[348px]': is640
        }
      )}>
      <NotificationsHead />
      {!Boolean(messageHistory.length) ? (
        <NotificationsStub />
      ) : (
        <NotificationsList messages={messageHistory} />
      )}
    </div>
  );
};
