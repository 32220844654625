import { FC } from 'react';
import { twJoin } from 'tailwind-merge';

interface CalendarIconProps {
  isDisabled?: boolean;
  isCollectable?: boolean;
  day: number;
}

export const CalendarIcon: FC<CalendarIconProps> = ({ isDisabled, isCollectable, day }) => {
  const isHoverPermitted = !isDisabled;
  const shouldPaintDot = isCollectable && !isDisabled;

  const dotClassName = shouldPaintDot ? 'fill-[#ff0707]' : 'fill-[white]';
  const calendarClassName = twJoin(
    'fill-white',
    isHoverPermitted && '768:group-hover:fill-yellow 768:group-[.isActive]:fill-yellow'
  );

  return (
    <span className={'relative group'}>
      {/* При замене иконок для разных проектов заменять только svg и его внутренности */}
      {/* В каждой иконке календаря должна быть точка или квадрат - это индикатор того, что пользователь может забрать награду текущего дня */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 7.39901V5.19584C22 3.98816 21.0357 3 19.8571 3H17.3571V4.37396C17.3571 4.77653 17.0357 5.10591 16.6429 5.10591C16.25 5.10591 15.9286 4.77653 15.9286 4.37396V3H8.07143V4.37396C8.07143 4.77653 7.74999 5.10591 7.35714 5.10591C6.96429 5.10591 6.64286 4.77653 6.64286 4.37396V3H4.14286C2.96427 3 2 3.98816 2 5.19584V7.39901H22ZM22 15V8.80273H2V19.8039C2 21.0116 2.96427 21.9998 4.14286 21.9998H14V17.1958C14 15.9881 14.9643 15 16.1429 15H22Z"
          className={calendarClassName}
        />
        <path
          d="M17 17C16.4477 17 16 17.4477 16 18V21.9999L16.0108 21.9909L16.0015 22H20C21.1046 22 22 21.1046 22 20V17H17Z"
          className={dotClassName}
        />
      </svg>
      {/* Span, содержащий цифру текущего дня, не должен удаляться, но его стили могут изменяться от проекта к проекту */}
      <span className="text-xs font-bold text-[#551b8e] absolute top-[7px] left-1">{day}</span>
    </span>
  );
};
