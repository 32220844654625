export const UpIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9.75L17.3083 11.325L13.1667 7.33125L13.1667 21L10.8333 21L10.8333 7.33125L6.69167 11.325L5 9.75L12 3L19 9.75Z"
        fill="white"
      />
    </svg>
  );
};
