import { createPath, useLocation, useParams, useResolvedPath } from 'react-router-dom';
import { useNavigateLng } from 'src/lib';

export const useSetLanguageToUrl = () => {
  const { lang } = useParams();
  const { pathname, state, search, hash } = useLocation();
  const { pathname: href } = useResolvedPath(pathname);
  const navigate = useNavigateLng();

  return () => {
    if (!lang) {
      navigate(createPath({ pathname: href, search, hash }), {
        state,
        replace: true
      });
    } else {
      const pathNameWithoutLang = href.replace(`/${lang}`, '');
      navigate(createPath({ pathname: pathNameWithoutLang, search, hash }), {
        state,
        replace: true
      });
    }
  };
};
