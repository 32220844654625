import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { Skeletons } from '../Skeletons';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  htmlType?: string;
  label?: ReactNode;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  overrideContainerClassName?: string;
  disabled?: boolean;
  labelWithPadding?: boolean;
  labelClassname?: string;
  inputBorderColor?: string;
  containerClassName?: string;
  arrowClassName?: string;
  isLoading?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function _Input(
  {
    htmlType,
    label,
    rightIcon,
    leftIcon,
    className,
    inputBorderColor,
    overrideContainerClassName,
    disabled,
    labelWithPadding,
    labelClassname,
    containerClassName,
    arrowClassName,
    isLoading,
    ...inputProps
  },
  ref
) {
  return (
    <div
      className={
        overrideContainerClassName || 'flex flex-col justify-between items-start gap-2 w-[100%]'
      }>
      {!isLoading ? (
        <>
          {' '}
          {label ? (
            <label
              className={classNames(
                'text-text-secondary',
                labelWithPadding && 'pl-5',
                labelClassname
              )}>
              {label}
            </label>
          ) : null}
          <div
            className={classNames(
              'flex flex-row items-center w-full border-solid bg-text-secondary rounded-[20px] h-11 px-5 py-3 border',
              disabled && '!bg-input-disabled cursor-not-allowed',
              inputBorderColor ? inputBorderColor : 'border-input-border',
              containerClassName && containerClassName
            )}>
            {leftIcon}
            <input
              disabled={disabled}
              {...inputProps}
              className={classNames(
                'input w-full justify-between placeholder:text-[16px] relative leading-[110%]',
                className
              )}
              type={htmlType}
              ref={ref}
            />
            {rightIcon && (
              <div
                className={classNames(
                  'pl-1 cursor-pointer transition-transform',
                  arrowClassName ? arrowClassName : '',
                  disabled && 'pointer-events-none'
                )}>
                {rightIcon}
              </div>
            )}
          </div>
        </>
      ) : (
        <Skeletons count={1} className="w-full rounded-[20px] h-[44px]" />
      )}
    </div>
  );
});
