import { DetailedHTMLProps, FormEvent, forwardRef, InputHTMLAttributes } from 'react';
import { PaymentFormField } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import './style.css';
import classNames from 'classnames';
import { twJoin } from 'tailwind-merge';

interface PhoneInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  field: PaymentFormField;
  labelWithPadding: boolean;
  containerClassName?: string;
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ field, labelWithPadding, containerClassName, ...props }, ref) => {
    const { t } = useTranslation();
    const handlePhoneValidation = (event: FormEvent<HTMLInputElement>) =>
      (event.currentTarget.value = event.currentTarget.value.replace(/[^\d]/g, ''));

    return (
      <div className="flex w-full flex-col items-start justify-between gap-2" key={field.name}>
        {field.title ? (
          <label className={classNames('text-base', labelWithPadding && 'pl-5')}>
            {field.title}
          </label>
        ) : null}
        <div className="phone-input-container self-stretch">
          <input
            ref={ref}
            className={twJoin(
              props.disabled && '!bg-input-disabled',
              'phone-input',
              containerClassName
            )}
            type="tel"
            name={field.name}
            placeholder={field.title}
            defaultValue={field.title}
            pattern="^[^+0].*"
            onInput={handlePhoneValidation}
            {...props}
          />
        </div>
      </div>
    );
  }
);

PhoneInput.displayName = 'PhoneInput';
