import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface TableCellProps {
  isLeaderBoardPlace: boolean;
}

export const TableCell: FC<PropsWithChildren<TableCellProps>> = ({
  isLeaderBoardPlace,
  children
}) => {
  return (
    <td
      className={twMerge(
        'text-[14px] font-medium 768:text-[16px] px-[5px] 768:px-[15px] py-5 text-center',
        isLeaderBoardPlace ? 'text-slt_participate_text' : 'text-text-secondary'
      )}>
      {children}
    </td>
  );
};
