import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './styles.css';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DisplayOfFreespins, Timer } from 'src/components/LandingOfWheel';
import { BorderWheel, Wheel } from 'src/components/svg/wheel';
import { useCanRotate } from 'src/hooks/useCanRotate';
import { useSpinCount } from 'src/hooks/useSpinCount';
import { COOLDOWN, TIME_ROTATE, storageKeys, useTimeLeft } from 'src/hooks/useTimeLeft';
import borderWheel from 'src/assets/images/borderButton.svg';
import { useGetUserCountry } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';

const BONUSES = [
  'wheelOfFortune.bonus10',
  'wheelOfFortune.freespins25',
  'wheelOfFortune.bonus25',
  'wheelOfFortune.nowin',
  'wheelOfFortune.bonus100',
  'wheelOfFortune.freespins100',
  'wheelOfFortune.bonus50',
  'wheelOfFortune.freespins335',
  'wheelOfFortune.bonus150',
  'wheelOfFortune.freespins50'
];

export const LandingWheelOfFortune = () => {
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<number>(0);
  const { openModal } = useModalContext();
  const wheelRef = useRef<HTMLDivElement>(null);
  const { isAUS } = useGetUserCountry();

  const { timeLeft, setTimeLeft } = useTimeLeft();
  const { spinCount, setSpinCount } = useSpinCount();
  const { canRotate, setCanRotate } = useCanRotate();

  const handleWheelClick = () => {
    const element = wheelRef.current;
    element?.classList.remove('wheel');
    element?.classList.add('wheelRotate1');

    localStorage.setItem(storageKeys.freespinsCount, '1');
    setSpinCount(1);

    localStorage.setItem(storageKeys.canRotate, 'false');
    setCanRotate(false);

    setTimeout(() => {
      openModal('POPUP_LANDING_FIRST', {
        props: { onClickSpinAgain }
      });
    }, TIME_ROTATE);
  };

  const onClickSpinAgain = () => {
    const element = wheelRef.current;
    element?.classList.add('wheelRotate2');

    localStorage.setItem(storageKeys.freespinsCount, '0');
    setSpinCount(0);

    setTimeout(() => {
      openModal('POPUP_LANDING_SECOND');
    }, TIME_ROTATE);
  };

  const updateWheelSize = (containerWidth?: number) => {
    if (!containerWidth) return;
    if (containerWidth >= 260 && containerWidth <= 310) {
      setSize(180);
      return;
    }
    if (containerWidth > 310 && containerWidth <= 360) {
      setSize(260);
      return;
    }
    if (containerWidth > 360 && containerWidth <= 410) {
      setSize(300);
      return;
    }
    if (containerWidth > 410 && containerWidth <= 450) {
      setSize(330);
      return;
    }
    if (containerWidth > 450 && containerWidth <= 500) {
      setSize(400);
      return;
    }
    if (containerWidth > 500 && containerWidth <= 600) {
      setSize(400);
      return;
    }
    if (containerWidth > 600 && containerWidth <= 768) {
      setSize(450);
      return;
    }
    if (containerWidth > 768 && containerWidth <= 1024) {
      setSize(550);
      return;
    }
    if (containerWidth > 1024 && containerWidth <= 1280) {
      setSize(550);
      return;
    }
    if (containerWidth > 1280) {
      setSize(650);
      return;
    }
  };

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    updateWheelSize(wrapperRef.current?.getBoundingClientRect()?.width);
  }, []);

  const textTop = size * 0.1;

  useEffect(() => {
    const handleWindowResize = () => {
      const rect = wrapperRef.current?.getBoundingClientRect();
      const width = rect?.width;
      updateWheelSize(width);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timeLeft !== COOLDOWN) {
      interval = setInterval(() => {
        setTimeLeft((prev) => Number(prev) - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex justify-center flex-1 items-center flex-col gap-y-[25px] jsm:gap-y-[10px]"
      ref={wrapperRef}>
      <DisplayOfFreespins spinCount={spinCount} />
      <div
        className="relative flex justify-center items-center mt-[30px] jgf:mt-0"
        style={{ width: size, height: size }}>
        <BorderWheel />
        <div
          onClick={handleWheelClick}
          style={{
            animationDelay: '2ms',
            animationDuration: '2s',
            animationName: 'borderOfWheel',
            animationIterationCount: 'infinite',
            display: canRotate ? 'block' : 'none'
          }}
          className="relative text-white text-3xl font-extrabold leading-110 uppercase cursor-pointer w-[96px] h-[96px] rounded-full wheelButton">
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            spin
          </div>
          <img
            src={borderWheel}
            className="absolute top-[0] left-[0]"
            alt="border of wheel of fortune"
          />
        </div>
        <div
          ref={wheelRef}
          className={classNames('absolute flex z-[1] justify-center w-full h-full wheel')}>
          {BONUSES.map((bonus, index) => (
            <p
              lang={i18n.language}
              key={index}
              style={{
                transform: `rotate(${36 * index}deg)`,
                transformOrigin: `center ${size * 0.5 - textTop}px`,
                top: `${textTop}px`,
                hyphens: 'auto'
              }}
              className="text-white font-bold bonus
              jsm:w-[50px] jlg:w-[50px] jgf:w-[30px] w-[80px] text-center absolute whitespace-pre-wrap wheel-text">
              {t(`${bonus}`).replace(/€\/\$/g, isAUS ? 'A$' : '€/$')}
            </p>
          ))}
          <Wheel />
        </div>
      </div>
      <Timer timeLeft={timeLeft} />
    </div>
  );
};
