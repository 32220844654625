import { useMemo } from 'react';
import { useBackgroundScrollLock } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { MODAL_MAP } from 'src/newUseModalContext/constants';

export const Modal = () => {
  const { openedModalName } = useModalContext();
  const InnerContent = useMemo(
    () => (openedModalName ? MODAL_MAP[openedModalName as keyof typeof MODAL_MAP].component : null),
    [openedModalName]
  );
  useBackgroundScrollLock(Boolean(InnerContent), [InnerContent]);
  return InnerContent ? <InnerContent /> : null;
};
