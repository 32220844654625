import { twJoin } from 'tailwind-merge';
import { CalendarIcon } from '../CalendarIcon';
import { DailyBonusProps } from '../..';
import { FC } from 'react';
import { DailyBonus } from 'react-easyrocket';
import { useGameTrigger } from 'src/modules/GPT/GPTDailyBonusGame/hooks/useGameTrigger';
import { useTranslation } from 'react-i18next';
import { SidebarTooltip, Tooltips } from 'src/components/Sidebar/components/SidebarTooltip';

type DailyBonusWithUserProps = {
  dailyBonusData: DailyBonus | undefined;
  status: number | undefined;
};

export const DailyBonusWithUser: FC<DailyBonusProps & DailyBonusWithUserProps> = ({
  compact,
  dailyBonusData,
  status,
  handleActiveTooltip,
  clearTooltipState,
  activeTooltip
}) => {
  const { t } = useTranslation();
  const triggerDailyBonus = useGameTrigger();

  if (!dailyBonusData || status !== 200) return null;

  const { currentDay, days } = dailyBonusData;
  const isCollectable = days?.[currentDay - 1]?.bonusStatus === 'READY_TO_COLLECT';

  return compact ? (
    <SidebarTooltip
      id="daily-bonus-icon"
      mouseEnterFunction={() => handleActiveTooltip?.(Tooltips.DAILY_BONUS)}
      mouseLeaveFunction={() => clearTooltipState?.()}
      showTooltip={activeTooltip === Tooltips.DAILY_BONUS}
      tooltipContent={t('mainSidebarLinks.dailyBonus')}>
      <button
        onClick={triggerDailyBonus}
        className={twJoin(
          'group w-full flex items-center gap-[8px] text-[18px] font-bold relative 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow',
          compact && 'justify-center'
        )}>
        <CalendarIcon day={currentDay} isCollectable={isCollectable} />
        {compact ? undefined : t('menuItem.dailyBonus')}
      </button>
    </SidebarTooltip>
  ) : (
    <button
      onClick={triggerDailyBonus}
      className={twJoin(
        'group w-full flex items-center gap-[8px] text-[18px] font-bold relative 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow',
        compact && 'justify-center'
      )}>
      <CalendarIcon day={currentDay} isCollectable={isCollectable} />
      {compact ? undefined : t('menuItem.dailyBonus')}
    </button>
  );
};
