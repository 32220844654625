import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { isProductionEnv } from 'src/api';

dayjs.extend(isBetween);
dayjs.extend(utc);

export type DateObject = {
  year: string;
  month: string;
  day: string;
};

export type CheckEventDatesParams = {
  startDate: DateObject;
  endDate: DateObject;
};

/**
 * Возвращает true, если текущая дата по utc находится включительно между начальной датой указанной в параметрах и конечной датой из параметров.
 * Иначе возвращает false
 */
export const checkEventDates = ({ startDate, endDate }: CheckEventDatesParams) => {
  const startDateConcatted = `${startDate.year}-${startDate.month}-${startDate.day}`;
  const endDateConcatted = `${endDate.year}-${endDate.month}-${endDate.day}`;
  const currentDate = dayjs().format('YYYY-MM-DD');

  return dayjs(currentDate).utc().isBetween(startDateConcatted, endDateConcatted, 'day', '[]');
};

/**
 * ывполняет проверку сheckEventDates по одному из условий в зависимости от env приложения
 */
export const checkEventDatesFromEnv = ({
  devDates,
  prodDates
}: {
  devDates: CheckEventDatesParams;
  prodDates: CheckEventDatesParams;
}) => {
  return checkEventDates(devDates);
};
