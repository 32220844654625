import { memo } from 'react';
import { Child } from 'react-easyrocket';
import { CONTENT_URL } from 'src/api';
import { Button } from 'src/components/Buttons/Button';
import { useNavigateLng } from 'src/lib';

function GenericSlide({ slide }: { slide: Child }) {
  const navigate = useNavigateLng();

  const handleButtonClick = () => {
    navigate(slide.title);
  };

  return (
    <div
      className="flex h-[407px] w-[clamp(96%,98%,374px)] max-w-[374px] cursor-pointer flex-col items-center justify-between rounded-[10px] bg-cover bg-center p-5"
      style={{ backgroundImage: `url(${CONTENT_URL}${slide.image})` }}
      onClick={() => navigate(slide.title)}>
      <div
        className="[&_h3]:mb-[7px] [&_h3]:text-center [&_h3]:text-[30px] [&_h3]:uppercase [&_h3]:leading-[36px] [&_h3]:text-mp_banner_vip_title text-center text-[18px] font-bold text-mp_banner_vip_text"
        dangerouslySetInnerHTML={{ __html: slide.content }}></div>
      {slide.keywords && (
        <Button
          onClick={handleButtonClick}
          mode="button-secondary"
          className="!h-[50px] !w-[190px] leading-none">
          {slide.keywords}
        </Button>
      )}
    </div>
  );
}

export default memo(GenericSlide);
