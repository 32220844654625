import { Button } from 'src/components/Buttons/Button';
import { Input } from 'src/components/Inputs/Input';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import {
  FieldType,
  useForm,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validateRequired
} from 'src/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import { useLogin } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from 'src/components/ModalWrapper';
import { AlternativeAuthMethodsBlock } from 'src/components/AlternativeAuthMethods';
import { AuthTabs } from '../components/AuthTabs';
import { AuthModalContainer } from '../components/ModalContainer';

export const LoginModal = () => {
  const { openModal, closeModal } = useModalContext();
  const { mutateAsync: login, isLoading } = useLogin({
    onSuccess: () => {
      closeModal();
    }
  });
  const { t } = useTranslation();
  const {
    errors,
    isFormValid,
    values,
    fieldsProps: { emailProps, passwordProps },
    submitTried,
    setSubmitTried
  } = useForm<{
    email: FieldType.Text;
    password: FieldType.Text;
  }>({
    email: {
      placeholder: `${t('general.email')}`,
      validation: [validateRequired, validateMaxLength(256), validateEmail],
      fieldType: FieldType.Text
    },
    password: {
      placeholder: `${t('general.password')}`,
      validation: [validateRequired, validatePassword],
      fieldType: FieldType.Text
    }
  });

  const handleForgotPasswordClick = () => {
    closeModal();
    openModal('RESET_PASSWORD');
  };

  return (
    <ModalWrapper>
      <AuthModalContainer className="flex flex-col items-center bg-LOCAL_AUTH_MODAL_BG gap-3 p-3">
        <AuthTabs isSignUpActive={false} />
        <AlternativeAuthMethodsBlock className="mt-5" />
        <div className="w-full relative">
          <Input
            {...emailProps}
            id="email"
            inputBorderColor={classNames(
              submitTried && Boolean(errors.email) && 'border-input-border-error'
            )}
          />

          <Tooltip
            anchorSelect="#email"
            className="bg-color-link text-bg-content-page rounded-4xl !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.email)}>
            <div>{errors.email}</div>
          </Tooltip>
        </div>
        <div className="w-full relative">
          <PasswordInput
            {...passwordProps}
            id="password"
            inputBorderColor={classNames(
              submitTried && Boolean(errors.email) && 'border-input-border-error'
            )}
          />

          <Tooltip
            anchorSelect="#password"
            className="bg-color-link text-bg-content-page rounded-4xl !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.password)}>
            <div>{errors.password}</div>
          </Tooltip>
        </div>
        <button
          className="font-medium text-button-primary text-sm underline"
          onClick={handleForgotPasswordClick}>
          {t('modalLogin.forgot')}
        </button>
        <Button
          className="text-background-fivefold mt-1 !w-auto !px-11 !font-bold !text-[18px] 768:mt-auto"
          loading={isLoading}
          mode="button-secondary"
          label={`${t('modalLogin.title')}`}
          onClick={() => {
            setSubmitTried(true);
            if (!isFormValid) return;
            login({ email: values.email, password: values.password });
          }}>
          {t('modalLogin.title')}
        </Button>
      </AuthModalContainer>
    </ModalWrapper>
  );
};
