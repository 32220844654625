import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const LitecoinIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M11 20.8094C16.4176 20.8094 20.8094 16.4176 20.8094 11C20.8094 5.58239 16.4176 1.19056 11 1.19056C5.58238 1.19056 1.19055 5.58239 1.19055 11C1.19055 16.4176 5.58238 20.8094 11 20.8094Z"
        fill="white"
      />
      <path
        d="M11 0C8.82439 0 6.69766 0.645139 4.88872 1.85383C3.07979 3.06253 1.66989 4.78049 0.837328 6.79048C0.00476611 8.80047 -0.21307 11.0122 0.211366 13.146C0.635803 15.2798 1.68345 17.2398 3.22182 18.7782C4.7602 20.3165 6.72021 21.3642 8.85399 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9374 18.9202 20.1461 17.1113C21.3548 15.3023 22 13.1756 22 11C22.0042 9.55965 21.7246 8.13258 21.1773 6.80027C20.63 5.46796 19.8256 4.2565 18.8101 3.23505C17.7946 2.2136 16.5879 1.40218 15.2588 0.847111C13.9297 0.29204 12.5042 0.0041911 11.0639 0H11ZM11.1864 11.3729L10.0411 15.2349H16.167C16.2076 15.2334 16.2481 15.24 16.2861 15.2543C16.3241 15.2685 16.359 15.2901 16.3887 15.3178C16.4184 15.3455 16.4423 15.3788 16.4591 15.4157C16.4759 15.4527 16.4853 15.4926 16.4867 15.5332V15.6344L15.954 17.4722C15.9305 17.5591 15.8781 17.6354 15.8055 17.6886C15.7329 17.7419 15.6444 17.7689 15.5545 17.7651H6.17917L7.75059 12.4116L5.99273 12.9443L6.39224 11.7191L8.15011 11.1864L10.3608 3.67554C10.3851 3.58912 10.4376 3.51333 10.51 3.46023C10.5824 3.40714 10.6705 3.3798 10.7603 3.38257H13.1307C13.1713 3.38114 13.2118 3.38773 13.2498 3.40196C13.2878 3.41618 13.3227 3.43776 13.3524 3.46547C13.382 3.49317 13.406 3.52646 13.4228 3.56342C13.4396 3.60038 13.449 3.64029 13.4503 3.68087V3.78208L11.5859 10.1211L13.3438 9.58838L12.9709 10.8668L11.1864 11.3729Z"
        fill="#345D9D"
      />
    </svg>
  );
};
