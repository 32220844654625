import { memo, useRef } from 'react';
import { Child, Winner, useGetWinners } from 'react-easyrocket';
import { CONTENT_URL } from 'src/api';
import { CustomMarquee } from 'src/components/CustomMarquee';
import winnersLogo from 'src/assets/images/winnersLogo.webp';
import { RecentWinersCard } from 'src/components/RecentWinnersCard';

const RecentWinnersSlide = ({ slide }: { slide: Child }) => {
  const { allWinners } = useGetWinners();
  const marqueContainerRef = useRef(null);
  return (
    <div
      className="relative h-[407px] w-[clamp(96%,98%,374px)] max-w-[374px] rounded-[10px] bg-bg_main"
      style={{
        backgroundImage: `url(${CONTENT_URL}${slide.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }}>
      <div className="relative">
        <img src={winnersLogo} className="relative z-[3] w-[80%] mx-auto" alt="winners logo" />
      </div>
      <div className="flex h-full w-full flex-col items-center justify-between">
        <div className="relative z-[3] w-full flex-1">
          <div className="absolute bottom-0 right-0 top-0 flex w-[100%] flex-col px-[3%]">
            <div className="h-[260px] pl-[3%]">
              <div className="winners-list flex h-full flex-col gap-2 overflow-y-auto pb-4 select-none touch-manipulation">
                <CustomMarquee
                  className="gap-3"
                  containerClassName="gap-3"
                  ref={marqueContainerRef}>
                  {allWinners.map((winner: Winner, index) => {
                    return (
                      <RecentWinersCard
                        key={index}
                        winner={winner}
                        // clickableCard={!winner.game.geo_link}
                      />
                    );
                  })}
                </CustomMarquee>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(RecentWinnersSlide);
