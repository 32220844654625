import dayjs from 'dayjs';
import { Child } from 'react-easyrocket';

const SLIDES_SCHEDULE = {
  easter_soon_slide: [22, 23, 24],
  easter_new_promo_slide: [25],
  catch_easter_rabbit_slide: [26, 27, 1, 2, 6, 7],
  easter_spins_slide: [29, 30, 4, 5],
  easter_shop_slide: [25, 26, 27, 28, 29, 30, 31, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  easter_special_bonus_slide: [28, 31, 3],
  easter_promo_slide: [26, 27, 28, 29, 30, 31, 1, 2, 3, 4, 5, 6, 7]
};

/** Фильтрует слайды согласно расписанию. С бэка приходят все слайды промо,
 * а на фронте скрываются те, которые не должны быть отображены в текущий день
 */
export const useTimedSlidesSchedule = (slides: Child[]) => {
  const today = dayjs.utc();

  const todayDayNumber = today.date();

  return slides.filter((slide) => {
    if (slide.code in SLIDES_SCHEDULE) {
      return SLIDES_SCHEDULE[slide.code as keyof typeof SLIDES_SCHEDULE].includes(todayDayNumber);
    }
    return true;
  });
};
