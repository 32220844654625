import ModalWrapper from 'src/components/ModalWrapper';
import { EVENT_IMAGES } from 'src/constants/eventImages';
import bgMobile from './images/bg-mobile.webp';
import bgTabletop from './images/bg-tabletop.webp';
import bgDesktop from './images/bg-desktop.webp';
import { CSSProperties } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useModalContext } from 'src/hooks/useModalContext';

export const SmarticoDepositFirstModal = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const handleDepositClick = () => {
    openModal('POPUP_DEPOSIT', { replace: true });
  };

  const styles = {
    '--bg-mobile': `url('${bgMobile}')`,
    '--bg-tabletop': `url('${bgTabletop}')`,
    '--bg-desktop': `url('${bgDesktop}')`
  } as CSSProperties;

  return (
    <ModalWrapper>
      <div
        style={styles}
        className="w-[300px] 360:w-[340px] py-2.5 px-4 640:py-[30px] 640:px-10 768:py-12 bg-100 768:px-10 640:w-[640px] 768:w-[740px] bg-[image:var(--bg-mobile)] 640:bg-[image:var(--bg-tabletop)] 768:bg-[image:var(--bg-desktop)]">
        <img
          src={EVENT_IMAGES.LOGO.REGULAR}
          alt="website logo"
          className="w-[45px] 640:w-[85px] pb-2 640:pb-4 768:pb-4"
        />
        <p className="text-sm 640:text-xl 768:text-2xl text-[white] font-medium uppercase">
          {t('smartico.make')}
        </p>
        <h2 className="text-xl text-[white] 640:text-4xl 768:text-5xl font-black uppercase">
          <Trans
            i18nKey="smartico.firstDeposit"
            components={{
              1: <br className="hidden 640:block" />
            }}
          />
        </h2>
        <p className="text-xs 640:text-base text-[white] font-medium mb-2 640:mb-4">
          <Trans
            i18nKey="smartico.firstDepositText"
            components={{
              1: <br />
            }}
          />
        </p>
        <Button
          mode="button-secondary"
          className="!w-[180px]"
          label={t('general.deposit')}
          onClick={handleDepositClick}
        />
      </div>
    </ModalWrapper>
  );
};
