import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { useChangeCurrency } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const ChangeCurrencyModal = () => {
  const { modalProps, closeModal } = useModalContext<{ currency: string }>();
  const { mutateAsync: changeCurrency, isLoading } = useChangeCurrency();
  const { t } = useTranslation();
  const handleChangeCurrencyButton = useCallback(async () => {
    await changeCurrency({
      currency: modalProps?.currency as string
    });
    closeModal();
  }, [changeCurrency, closeModal, modalProps?.currency]);

  return (
    <ModalWrapper>
      <div className="p-[20px] flex flex-col gap-5 items-center w-[300px] jxsm:w-full">
        <p className="font-bold text-[16px] text-center">
          {t('accountWalletBalance.changeCurrencyTitle')}
        </p>
        <Button
          title={`${t('general.cancel')}`}
          mode="button-secondary"
          onClick={() => {
            closeModal();
          }}>
          {t('general.cancel')}
        </Button>
        <Button
          title={`${t('accountWalletBalance.changeCurrencyButton')}`}
          loading={isLoading}
          onClick={handleChangeCurrencyButton}
          mode="button-primary">
          {t('accountWalletBalance.changeCurrencyButton')}
        </Button>
      </div>
    </ModalWrapper>
  );
};
