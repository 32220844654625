import { FC, PropsWithChildren } from 'react';
import NoGames from 'src/assets/images/noSearchGames.webp';

export const NoGamesStub: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <p className="text-gcp_no_providers_searching_text text-sm font-medium m-auto text-center mt-3 px-3">
        {children}
      </p>
      <img src={NoGames} className="w-[132px] 568:w-[171px] m-auto" alt="providers moon dog" />
    </>
  );
};
