import classNames from 'classnames';

export const Download = ({
  className,
  fill = 'inherit'
}: {
  className?: string;
  fill?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      className={classNames(className ? className : '')}>
      <path d="M8.00002 10.3833C7.91113 10.3833 7.8278 10.3695 7.75002 10.342C7.67224 10.3144 7.60002 10.2671 7.53336 10.2L5.13335 7.79999C5.01113 7.67777 4.95002 7.52221 4.95002 7.33333C4.95002 7.14444 5.01113 6.98888 5.13335 6.86666C5.25558 6.74444 5.41402 6.68066 5.60869 6.67533C5.80336 6.66999 5.96158 6.72821 6.08335 6.84999L7.33335 8.09999V3.33333C7.33335 3.14444 7.39736 2.98599 7.52536 2.85799C7.65336 2.72999 7.81158 2.66621 8.00002 2.66666C8.18891 2.66666 8.34736 2.73066 8.47536 2.85866C8.60336 2.98666 8.66713 3.14488 8.66669 3.33333V8.09999L9.91669 6.84999C10.0389 6.72777 10.1974 6.66933 10.392 6.67466C10.5867 6.67999 10.7449 6.74399 10.8667 6.86666C10.9889 6.98888 11.05 7.14444 11.05 7.33333C11.05 7.52221 10.9889 7.67777 10.8667 7.79999L8.46669 10.2C8.40002 10.2667 8.3278 10.314 8.25002 10.342C8.17224 10.37 8.08891 10.3838 8.00002 10.3833ZM4.00002 13.3333C3.63335 13.3333 3.31935 13.2027 3.05802 12.9413C2.79669 12.68 2.66624 12.3662 2.66669 12V10.6667C2.66669 10.4778 2.73069 10.3193 2.85869 10.1913C2.98669 10.0633 3.14491 9.99955 3.33335 9.99999C3.52224 9.99999 3.68069 10.064 3.80869 10.192C3.93669 10.32 4.00047 10.4782 4.00002 10.6667V12H12V10.6667C12 10.4778 12.064 10.3193 12.192 10.1913C12.32 10.0633 12.4782 9.99955 12.6667 9.99999C12.8556 9.99999 13.014 10.064 13.142 10.192C13.27 10.32 13.3338 10.4782 13.3334 10.6667V12C13.3334 12.3667 13.2027 12.6807 12.9414 12.942C12.68 13.2033 12.3662 13.3338 12 13.3333H4.00002Z" />
    </svg>
  );
};
