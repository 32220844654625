import { FormEvent, useEffect, useState } from 'react';
import { Button } from '../Buttons/Button';
import { Trans, useTranslation } from 'react-i18next';
import { countriesOptions } from 'src/constants';
import { PaymentFormField } from 'src/types';
import { useProcessHelloclever } from './hook/useProcessHelloclever';
import { DepositTransaction, makeFloat, useGetFullInfo, useUpdateInfo } from 'react-easyrocket';
import { PasswordInputComponent } from './components/PasswordInputComponent';
import { SelectComponent } from './components/SelectComponent';
import { generateSelectOptionsForFormGenerator } from './utils/generateSelectOptionsForFormGenerator';
import { pushAddPaymentInfoToGtmDataLayer } from './utils/pushAddPaymentInfoToGtmDataLayer';
import { InputDate } from '../Inputs/InputDate';
import { PhoneInput } from './components/PhoneInput';
import { generateFormFields } from './utils/generateFormFields';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../Inputs/Input';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { getEmptyFields } from './utils/getEmptyFields';
import { isBirthdayOverEighteen } from 'src/hooks/useForm';
import { prepareUpdateInfoData } from './utils/prepareUpdateInfoData';
import classNames from 'classnames';
import ModalWrapper from '../ModalWrapper';
import dayjs from 'dayjs';
import './styles.css';

export const FormGenerator = ({
  formFields,
  checkoutUrl,
  transaction,
  paymentMethodName,
  methodName
}: {
  formFields?: PaymentFormField[];
  checkoutUrl?: string;
  transaction?: DepositTransaction;
  paymentMethodName?: string;
  methodName: 'withdrawal';
}) => {
  const { t } = useTranslation();
  const { fullInfo, isLoading } = useGetFullInfo();
  const { mutateAsync: updateProfileInfo, isLoading: loadingUpdateInfo } = useUpdateInfo();

  const [isSendingForm, setIsSendingForm] = useState(false);

  const fields = generateFormFields(formFields, fullInfo);
  const emptyFields = getEmptyFields(fields);
  const isFormFullyFilled = !Object.keys(emptyFields).length;

  const {
    control,
    register,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    values: { ...fields },
    shouldFocusError: true
  });
  const isDevCodeMethod = transaction?.paymentMethod === 'devcode';
  const isWithdrawal = methodName === 'withdrawal';
  const processIfHelloclever = useProcessHelloclever();

  useEffect(() => {
    if (!transaction) return;
    const date = new Date();

    pushAddPaymentInfoToGtmDataLayer({
      transaction_id: date.getTime(),
      value: parseInt(makeFloat(transaction.amount, 0), 10),
      currency: transaction.currency
    });
  }, [transaction]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    if (isFormFullyFilled) {
      return;
    }

    event.preventDefault();

    if (!isValid) {
      setIsSendingForm(false);
      return;
    }

    const form = event.currentTarget;
    const formData = new FormData(form);

    const dateOfBirthday = formData.get('birthday') as string | null;

    const dateInput = form.birthday;
    if (dateOfBirthday && dateInput) {
      const formattedBirthday = isDevCodeMethod
        ? dateOfBirthday.split('.').reverse().join('-')
        : dateOfBirthday;
      dateInput.value = formattedBirthday;

      formData.set('birthday', formattedBirthday);
    }

    processIfHelloclever({ event, transaction, checkoutUrl, paymentMethodName });

    const updateInfoData = prepareUpdateInfoData(formData);

    setIsSendingForm(true);

    if (isWithdrawal) {
      form.submit();
    } else {
      updateProfileInfo(updateInfoData)
        .then(() => {
          form.submit();
        })
        .finally(() => setIsSendingForm(false));
    }
  };

  return (
    <ModalWrapper>
      <div className="px-[15px] py-[20px] w-[300px] jxsm:w-full">
        <form
          className="flex flex-col gap-4 m-2.5"
          method="POST"
          action={checkoutUrl}
          onSubmit={handleSubmit}>
          <p className="text-2xl jsm:text-xl font-bold whitespace-normal text-center text-button-secondary">
            {t('general.paymentDetails')}
          </p>
          {isLoading || !formFields
            ? null
            : formFields.map((field) => {
                const isSelect = field.type === 'select';
                const isSourceWalletPwd = field.name === 'source_wallet_pwd';
                const isBirthday = field.name === 'birthday';
                const isCountry = field.name === 'country';
                const isNationality = field.name === 'nationality';
                const isPhone = field.name === 'phone';
                const isPostCode = field.name === 'postcode';

                if (isSelect) {
                  const selectOptions = generateSelectOptionsForFormGenerator(field);
                  return (
                    <SelectComponent
                      key={field.name}
                      field={field}
                      disabled={!(field.name in emptyFields) && !isWithdrawal}
                      options={selectOptions}
                      className="input border-input-border rounded-[20px] pl-[15px] pr-2.5 py-2 border"
                    />
                  );
                }
                if (isCountry) {
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  return (
                    <Controller
                      key={field.name}
                      control={control}
                      name={field.name}
                      render={({ field: { onChange, value, onBlur } }) => {
                        return (
                          <SelectComponent
                            field={field}
                            defaultValue={fullInfo?.country}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            options={countriesOptions}
                            className={classNames(
                              'w-full border-solid h-11 leading-3 rounded-[20px] px-5 py-3 border bg-text-secondary border-input-border focus:outline-none appearance-none',
                              isDisabled &&
                                'pointer-events-none !bg-input-disabled cursor-not-allowed'
                            )}
                          />
                        );
                      }}
                    />
                  );
                }
                if (isBirthday) {
                  // field.toShow тут обязательно, так как если поле задизейблено в него не подставляется defaultValue
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  const isError = field.toShow && errors[field.name];
                  return (
                    <div
                      key={field.name}
                      className={classNames(!field.toShow && 'hidden', 'relative w-full')}>
                      <Controller
                        key={field.name}
                        control={control}
                        name={field.name}
                        rules={{
                          validate: (value) => {
                            if (!dayjs(value).isValid()) {
                              return t('apiErrors.invalidFormat') as string;
                            }

                            if (!isBirthdayOverEighteen(value)) {
                              return t('apiErrors.mustBeOldest') as string;
                            }
                          },
                          required: field.toShow ? (t('general.requiredField') as string) : false
                        }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <InputDate
                              {...register(field.name)}
                              name={field.name}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              disabled={isDisabled}
                              label={field.title}
                              labelClassName="!text-text-primary"
                              buttonClassName="h-[47px] border-input-border text-text-primary text-[14px]"
                              placeholder={field.title}
                              labelWithPadding
                              pickerPosition="top"
                              displayedValueFormat="DD.MM.YYYY"
                              isFullScreenDesktop
                              required={isDevCodeMethod ? true : field.toShow}
                              isDevCodeMethod={isDevCodeMethod}
                            />
                          );
                        }}
                      />
                      {isError && (
                        <span className="text-[red] text-xs">{errors[field.name]?.message}</span>
                      )}
                    </div>
                  );
                }
                if (isNationality) {
                  // field.toShow тут обязательно, так как если поле задизейблено в него не подставляется defaultValue
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  const isError = field.toShow && errors[field.name];
                  return (
                    <div key={field.name} className={classNames(!field.toShow && 'hidden')}>
                      <Input
                        {...register(field.name, {
                          required: field.toShow ? (t('general.requiredField') as string) : false
                        })}
                        label={field.title}
                        name={field.name}
                        defaultValue={field.value || ''}
                        labelClassname="!text-[black]"
                        placeholder={field.title}
                        labelWithPadding={true}
                        maxLength={2}
                        containerClassName={classNames(
                          isDisabled && '!bg-input-disabled cursor-not-allowed'
                        )}
                        readOnly={isDisabled}
                        autoComplete="new-password"
                      />
                      {isError && (
                        <span className="text-[red] text-xs">{errors[field.name]?.message}</span>
                      )}
                    </div>
                  );
                }
                if (isPostCode) {
                  // field.toShow тут обязательно, так как если поле задизейблено в него не подставляется defaultValue
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  const isError = field.toShow && errors[field.name];
                  return (
                    <div key={field.name} className={classNames(!field.toShow && 'hidden')}>
                      <Input
                        {...register(field.name, {
                          required: field.toShow ? (t('general.requiredField') as string) : false,
                          validate: (value) => {
                            if (!value.match(/^\S*$/)) {
                              return t('formGeneratorErrors.postcodeSpaces') as string;
                            }
                          }
                        })}
                        label={field.title}
                        name={field.name}
                        defaultValue={field.value || ''}
                        labelClassname="!text-[black]"
                        placeholder={field.title}
                        labelWithPadding={true}
                        containerClassName={classNames(
                          isDisabled && '!bg-input-disabled cursor-not-allowed'
                        )}
                        readOnly={isDisabled}
                        autoComplete="postcode"
                      />
                      {isError && (
                        <span className="text-[red] text-xs">{errors[field.name]?.message}</span>
                      )}
                    </div>
                  );
                }
                if (isPhone) {
                  // field.toShow тут обязательно, так как если поле задизейблено в него не подставляется defaultValue
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  const isError = field.toShow && errors[field.name];
                  return (
                    <div className={classNames(!field.toShow && 'hidden')} key={field.name}>
                      <PhoneInput
                        {...register(field.name, {
                          required: field.toShow ? (t('general.requiredField') as string) : false,
                          validate: (value) => {
                            if (isDevCodeMethod && !isValidPhoneNumber('+' + value)) {
                              return t('registration.invalidPhoneNumberError') as string;
                            }
                          }
                          // maxLength: {
                          //   value: 20,
                          //   message: `${t('general.valueLonger')} ${20} ${t('general.characters')}`
                          // }
                        })}
                        readOnly={isDisabled}
                        field={field}
                        labelWithPadding
                        containerClassName={classNames(
                          isDisabled && 'bg-input-disabled cursor-not-allowed'
                        )}
                      />
                      {isError && (
                        <span className="text-[red] text-xs">{errors[field.name]?.message}</span>
                      )}
                    </div>
                  );
                }
                if (
                  !isBirthday &&
                  !isCountry &&
                  !isSelect &&
                  !isNationality &&
                  !isPhone &&
                  !isPostCode
                ) {
                  // field.toShow тут обязательно, так как если поле задизейблено в него не подставляется defaultValue
                  const isDisabled = field.toShow && !(field.name in emptyFields) && !isWithdrawal;
                  const isError = field.toShow && errors[field.name];
                  return (
                    <div key={field.name} className={classNames(!field.toShow && 'hidden')}>
                      <Input
                        {...register(field.name, {
                          required: field.toShow ? (t('general.requiredField') as string) : false
                          // maxLength: {
                          //   value: Boolean(maxLengthField[field.name as keyof object])
                          //     ? maxLengthField[field.name as keyof object]
                          //     : 255,
                          //   message: `${t('general.valueLonger')} ${
                          //     Boolean(maxLengthField[field.name as keyof object])
                          //       ? maxLengthField[field.name as keyof object]
                          //       : 255
                          //   } ${t('general.characters')}`
                          // }
                        })}
                        readOnly={isDisabled}
                        label={field.title}
                        name={field.name}
                        defaultValue={field.value || ''}
                        labelClassname="!text-[black]"
                        placeholder={field.title}
                        labelWithPadding={true}
                        autoComplete="new-password"
                        containerClassName={classNames(
                          isDisabled && '!bg-input-disabled cursor-not-allowed'
                        )}
                      />
                      {isError && (
                        <span className="text-[red] text-xs">{errors[field.name]?.message}</span>
                      )}
                    </div>
                  );
                }
                if (isSourceWalletPwd) {
                  return <PasswordInputComponent key={field.name} field={field} />;
                }
                return null;
              })}
          {/* Показываем сообщие что все поля заполнены и нужно нажать кнопку продолжить */}
          {isFormFullyFilled && (
            <p>
              <Trans
                i18nKey={'general.conformToProceed'}
                components={{
                  bold: <span className="font-bold" />
                }}
              />
            </p>
          )}
          <Button
            onClick={() => trigger()}
            type="submit"
            mode="button-secondary"
            className="mt-[20px]"
            loading={isSendingForm || loadingUpdateInfo}>
            {t('general.confirm')}
          </Button>
        </form>
      </div>
    </ModalWrapper>
  );
};

// ----возможно пригодится в будущем - поставить в компонент FormGenerator
// const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
//   e.preventDefault();
//   const formFields: Record<string, any> = {};
//   // @ts-ignore можно получать target напрямую
//   const formData = new FormData(e.target);
//   for (const pair of formData.entries()) {
//     formFields[pair[0]] = pair[1];
//   }
//   axios.post(data.checkoutUrl, formFields, {
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//   });
// };
