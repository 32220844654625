import { useEffect } from 'react';
import { useActivateBonusViaLink, useApiContext } from 'react-easyrocket';
import { ACTIVATE_BONUS_LOCAL_STORAGE_KEY } from 'src/constants/storageKey';

/**
 * when user visits /activate-bonus/:bonusId page and user isn't authorized,
 * bonusId saved in local storage and needs to be activated as soon as user is authorized
 */
export const useActivateAuthBonus = () => {
  const { isAuthenticated } = useApiContext();
  const { mutateAsync } = useActivateBonusViaLink();

  useEffect(() => {
    if (isAuthenticated) {
      const bonusId = localStorage.getItem(ACTIVATE_BONUS_LOCAL_STORAGE_KEY);

      if (bonusId) {
        mutateAsync({ bonus_id: bonusId });
        localStorage.removeItem(ACTIVATE_BONUS_LOCAL_STORAGE_KEY);
      }
    }
  }, [isAuthenticated]);
};
