import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { CONTENT_URL } from '../../api';
import gift from '../../assets/images/gift.webp';
import { getCurrencyIcon } from 'src/utils';
import classNames from 'classnames';
import {
  makeFloat,
  useGetBonusTransaction,
  useGetFullBalance,
  useUpdateBonusStatus
} from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const BonusModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModalContext<{ bonusId: number }>();
  const { bonusTransaction, isFetched } = useGetBonusTransaction(modalProps?.bonusId || 0);
  const { mutateAsync: updateBonusStatus } = useUpdateBonusStatus();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();

  const currency = useMemo(() => {
    return fullBalance?.currency ?? 'EUR';
  }, [fullBalance]);
  const icon = getCurrencyIcon(currency);

  const text = useMemo(() => {
    if (bonusAccountCasino?.balance === 0) {
      return t('promoCode.textPopUpFirst');
    } else if (bonusAccountCasino && bonusAccountCasino?.balance > 0) {
      return `${t('promoCode.textPopUpSecond')} ${icon} ${makeFloat(bonusAccountCasino?.balance)}`;
    }
  }, [bonusAccountCasino, icon, t]);

  const handleActiveBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps?.bonusId || 0, status: 'Activated' });
    closeModal();
  }, [closeModal, modalProps, updateBonusStatus]);

  const handleCancelBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps?.bonusId || 0, status: 'Rejected' });
    closeModal();
  }, [closeModal, modalProps, updateBonusStatus]);

  const handleSendQueueBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps?.bonusId || 0, status: 'Queue' });
    closeModal();
  }, [closeModal, modalProps, updateBonusStatus]);

  return (
    <ModalWrapper showCross={false}>
      <div
        className={classNames(
          'flex flex-col max-w-[400px]',
          bonusTransaction?.bonus?.imageBanner ? undefined : 'bg-text-secondary'
        )}>
        {bonusTransaction?.bonus?.imageBanner ? (
          <img
            src={`${CONTENT_URL}${bonusTransaction?.bonus?.imageBanner}`}
            alt={bonusTransaction?.bonus.name}
            className="w-full z-0 relative translate-y-[5%]"
          />
        ) : null}
        <div
          className={classNames(
            'flex flex-col gap-5 items-center justify-center py-7 px-5  relative',
            bonusTransaction?.bonus?.imageBanner ? 'bg-text-secondary' : undefined
          )}>
          {!isFetched && bonusTransaction?.bonus?.imageBanner ? null : (
            <img src={gift} alt="gift" className="h-auto w-auto" />
          )}
          <p className="text-background-fivefold text-center text-base font-bold px-[15px]">
            {text}
          </p>
          <div className="flex flex-wrap gap-5 justify-center">
            <Button
              mode="button-secondary"
              className="text-base"
              title={`${t('promoCode.activate')}`}
              label={`${t('promoCode.activate')}`}
              onClick={handleActiveBonus}
            />
            <div className="flex w-full gap-2.5 justify-between items-center">
              <Button
                title={`${t('general.cancel')}`}
                mode="button-transparent"
                label={`${t('general.cancel')}`}
                onClick={handleCancelBonus}
                className="text-base"
              />
              <Button
                title={`${t('promoCode.toQueue')}`}
                mode="button-primary"
                label={`${t('promoCode.toQueue')}`}
                onClick={handleSendQueueBonus}
                className="text-base"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
