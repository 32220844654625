import { CONTENT_URL } from 'src/api';
import { Button } from 'src/components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ProviderTournament, useApiContext } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { LinkLng } from 'src/lib';

export const TournamentsCard = ({ tournament }: { tournament: ProviderTournament }) => {
  const { openModal } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  return (
    <LinkLng
      to={`/tournaments/${tournament.code}`}
      state={{ id: tournament.code }}
      className="cursor-pointer w-full"
      aria-label="tournament">
      <div
        className="flex flex-col aspect-[371/290] justify-end items-center rounded-2xl bg-cover bg-no-repeat"
        style={{
          filter: 'var(--tc_filter_bg)',
          backgroundImage: `url(${CONTENT_URL}${tournament.imageMobile})`
        }}>
        <div className="flex justify-center items-end 768:mb-10 mb-[2%]">
          {isAuthenticated ? (
            <LinkLng
              to={`/tournaments/${tournament.code}`}
              state={{ id: tournament.code }}
              aria-label="tournament">
              <Button
                title={`${t('tournaments.participate')}`}
                mode="button-secondary"
                className="768:min-w-[190px] min-w-[140px]"
                label={`${t('tournaments.participate')}`}
              />
            </LinkLng>
          ) : (
            <Button
              mode="button-secondary"
              onClick={() => openModal('SIGNUP')}
              className="768:min-w-[190px] min-w-[140px]"
              label={`${t('tournaments.participate')}`}
              title={`${t('tournaments.participate')}`}
            />
          )}
        </div>
      </div>
    </LinkLng>
  );
};
