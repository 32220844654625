import React from 'react';

export const CopyIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.25 3.16547H8.25C8.05109 3.16547 7.86032 3.24448 7.71967 3.38514C7.57902 3.52579 7.5 3.71655 7.5 3.91547V7.66547H3.75C3.55109 7.66547 3.36032 7.74448 3.21967 7.88514C3.07902 8.02579 3 8.21655 3 8.41547V20.4155C3 20.6144 3.07902 20.8051 3.21967 20.9458C3.36032 21.0864 3.55109 21.1655 3.75 21.1655H15.75C15.9489 21.1655 16.1397 21.0864 16.2803 20.9458C16.421 20.8051 16.5 20.6144 16.5 20.4155V16.6655H20.25C20.4489 16.6655 20.6397 16.5864 20.7803 16.4458C20.921 16.3051 21 16.1144 21 15.9155V3.91547C21 3.71655 20.921 3.52579 20.7803 3.38514C20.6397 3.24448 20.4489 3.16547 20.25 3.16547ZM15 19.6655H4.5V9.16547H15V19.6655ZM19.5 15.1655H16.5V8.41547C16.5 8.21655 16.421 8.02579 16.2803 7.88514C16.1397 7.74448 15.9489 7.66547 15.75 7.66547H9V4.66547H19.5V15.1655Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};
