import { GAME_CATEGORIES, GameCategoryParams } from 'src/constants/gameCategories';
import { shouldEvent } from './dates';

export const modificationCategory = (categories: GameCategoryParams[]) => {
  if (shouldEvent) {
    return [categories[0], GAME_CATEGORIES.halloween, ...categories.slice(1)];
  }

  return categories;
};
