import { GAME_CATEGORIES } from 'src/constants/gameCategories';
import { CasinoIcon, JackpotIcon } from '../svg/SidebarIcons';
import { AllLive } from '../svg/categories/AllLive';
import { PROGRESSIVE_TOOLTIP_TYPE } from 'src/hooks/useProgressiveTooltip';
import dailyChallengeImg from 'src/assets/images/smartico/DailyChallenge.svg';
import missionsImg from 'src/assets/images/smartico/Missions.svg';
import storeImg from 'src/assets/images/smartico/Store.svg';
import cashbackImg from 'src/assets/images/smartico/Сashback.svg';
import bigWinImg from 'src/assets/images/smartico/BigWin.svg';
import { modificationCategory } from '../../event/categories';

export const collapsedSidebarDataForTooltips = [
  {
    title: 'gameCategories.casino',
    icon: <CasinoIcon />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.CASINO
  },
  {
    title: 'general.allLiveGames',
    icon: <AllLive />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.LIVE
  },
  {
    title: 'general.jackpotGames',
    icon: <JackpotIcon />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.JACKPOTS
  }
];

export const progressiveTooltipsData = {
  /**
   * !если сюда добавляется новый объект, надо в компоненте DesktopCollapsedSidebarContent добавить новый ref для ProgressiveTooltipController
   * !в DesktopCollapsedSidebarContent есть коммент перед местом где надо докинуть ref
   */
  'gameCategories.casino': {
    title: 'gameCategories.casino',
    icon: <CasinoIcon />,
    childsData: modificationCategory([
      // GAME_CATEGORIES.easter,
      GAME_CATEGORIES.all,
      GAME_CATEGORIES.top,
      GAME_CATEGORIES.new,
      GAME_CATEGORIES.megaways,
      GAME_CATEGORIES['bonus-games'],
      GAME_CATEGORIES.dropwins,
      GAME_CATEGORIES.slots,
      GAME_CATEGORIES.fruits,
      GAME_CATEGORIES.books
    ])
  },
  'general.allLiveGames': {
    title: 'general.allLiveGames',
    icon: <AllLive />,
    childsData: [
      GAME_CATEGORIES['all-live'],
      GAME_CATEGORIES.blackjack,
      GAME_CATEGORIES.baccarat,
      GAME_CATEGORIES.poker,
      GAME_CATEGORIES.roulette
    ]
  },
  'general.jackpotGames': {
    title: 'general.jackpotGames',
    icon: <JackpotIcon />,
    childsData: [
      GAME_CATEGORIES['all-jackpots'],
      GAME_CATEGORIES['jackpot-mini'],
      GAME_CATEGORIES['jackpot-middle'],
      GAME_CATEGORIES['jackpot-grand']
    ]
  }
};

export const smarticoButtons = [
  {
    key: 1,
    icon: dailyChallengeImg,
    label: 'smartico.dailyChallenge',
    isSoonLabel: true
  },
  {
    key: 2,
    icon: missionsImg,
    label: 'smartico.missions',
    isSoonLabel: false,
    action: 'dp:gf_missions'
  },
  {
    key: 3,
    icon: storeImg,
    label: 'smartico.store',
    isSoonLabel: false,
    action: 'dp:gf_store'
  },
  {
    key: 4,
    icon: cashbackImg,
    label: 'smartico.cashback',
    isSoonLabel: false,
    navigate: '/bonuses'
  },
  {
    key: 5,
    icon: bigWinImg,
    label: 'smartico.bigWin',
    isSoonLabel: true
  }
];
