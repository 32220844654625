import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { descriptions } from './constants';
import { useCurrency } from 'react-easyrocket';
import { memo } from 'react';

interface WelcomePackInfoProps {
  className?: string;
}
const WelcomePackInfo = ({ className }: WelcomePackInfoProps) => {
  const { t } = useTranslation();
  const { currencyName } = useCurrency();

  return (
    <div className={twJoin('flex flex-col items-center w-auto px-3', className)}>
      <div className="text-center mt-3 w-full">
        <h1 className="text-3xl 360:text-5xl font-extrabold text-wblp_bonus_currency 1440:text-[80px] text-nowrap">
          <span className="text-wblp_bonus">5000 </span>
          {currencyName}
        </h1>
        <h2 className="uppercase font-extrabold text-xl text-wblp_bonus_currency 1440:text-4xl">
          <span className="text-wblp_bonus">150% </span> + 300 free{' '}
          <span className="text-wblp_bonus">spins</span>
        </h2>
      </div>
      <hr className="border-wblp_separator h-[1px] w-full my-5 1280:my-2" />
      <div className="grid grid-cols-2 gap-y-5 mt-2 w-fit justify-center 1440:w-full 1280:grid-cols-4 1280:grid-rows-1">
        {descriptions.map(({ value, name }) => {
          return (
            <div className="text-center justify-self-center self-start" key={value}>
              <h4 className="text-sm font-extrabold text-wblp_advantage_items_title 1920:text-xl">
                {t(value)}
              </h4>
              <p className="font-normal text-xs text-wblp_advantage_items_text uppercase 1920:text-xl">
                {t(name)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(WelcomePackInfo);
