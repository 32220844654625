import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from 'src/components/svg';
import classNames from 'classnames';
import { Input } from 'src/components/Inputs/Input';
import { PromoCode } from 'src/components/svg/PromoCode';
import { useLocation } from 'react-router-dom';

interface DepositPromoCodeProps {
  changePromoCode: (code: string) => void;
  label?: string;
  placeholder?: string;
  value: string;
  isModal: boolean;
}

export const DepositPromoCode = ({
  changePromoCode,
  value,
  label,
  placeholder
}: DepositPromoCodeProps) => {
  const { t } = useTranslation();

  const container = useRef<HTMLDivElement>(null);
  const { state } = useLocation();
  const [showPromocode, setShowPromocode] = useState(false);

  const handleChangePromoCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      changePromoCode(event.target.value);
    },
    [changePromoCode]
  );

  useEffect(() => {
    setShowPromocode(!!value);
  }, [value]);

  useEffect(() => {
    if (state?.shouldOpenPromoCode) {
      setShowPromocode(true);
    }
  }, [state, container?.current]);

  const changeShowPromocode = () => {
    setShowPromocode((showPromocode) => !showPromocode);
  };

  return (
    <div className="flex flex-col justify-between gap-2.5 mb-5">
      <div
        className="flex gap-[12px] items-center cursor-pointer select-none"
        onClick={changeShowPromocode}
        ref={container}>
        <PromoCode />
        <p className="font-bold text-base leading-[110%] text-dpc_title">{t('promoCode.title')}</p>
        <Arrow
          className={classNames('w-[20px] stroke-dpc_arrow', showPromocode ? 'rotate-180' : '')}
        />
      </div>
      {showPromocode ? (
        <div>
          {label ? <p className="ml-[15px] mb-2.5">{label}</p> : null}
          <div className="relative">
            <Input placeholder={placeholder} value={value} onChange={handleChangePromoCode} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
