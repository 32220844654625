import 'react-tooltip/dist/react-tooltip.css';
import './locale/config';
import { AllProvidersComponent } from './context/AllProvidersComponent';
import { Router } from './router';

export const App = () => {
  return (
    <AllProvidersComponent>
      <Router />
    </AllProvidersComponent>
  );
};
