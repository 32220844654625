import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface TableSkeletonProps {
  count?: number;
}

export const TableSkeleton: FC<TableSkeletonProps> = ({ count = 10 }) => {
  const skeletonList = Array.from({ length: count });

  return (
    <>
      {skeletonList.map((_, index) => (
        <tr className={twMerge('h-[72px] even:bg-bg-evenRowTable even:animate-pulse')} key={index}>
          <td className="w-[118px]" />
          <td className="w-[174px]" />
          <td className="w-[132px]" />
          <td className="w-[174px]" />
          <td className="w-[263px]" />
        </tr>
      ))}
    </>
  );
};
