import { memo, useEffect, useMemo, useState } from 'react';
import { Child, Jackpot, makeFloat, useApiContext, useGetJackpots } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { CONTENT_URL } from 'src/api';
import { Button } from 'src/components/Buttons/Button';
import { FIFTEEN_SECONDS } from 'src/constants/time';
import DrawsBetweenJackpots, { jackpotColors } from 'src/components/DrawsBetweenJackpots';
import { useModalContext } from 'src/hooks/useModalContext';
import { prepareJackpotsForBanner } from 'src/utils';
import { CountUp } from 'src/tmp/CountUp';
import { useNavigateLng } from 'src/lib';

const shadowColors = [
  '0px 0px 12px #FF7A00, 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '0px 0px 12px #00F0FF, 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '0px 0px 12px #BD00FF, 0px 4px 4px rgba(0, 0, 0, 0.25)'
];

const JackpotSlide = ({ slide }: { slide: Child }) => {
  const [currentSlide, setCurrentSlide] = useState<Jackpot | undefined>(undefined);
  const { openModal } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  const navigate = useNavigateLng();

  const { jackpots } = useGetJackpots(
    {},
    { refetchInterval: FIFTEEN_SECONDS, staleTime: FIFTEEN_SECONDS, cacheTime: FIFTEEN_SECONDS }
  );

  const data = useMemo(() => prepareJackpotsForBanner(jackpots), [jackpots]);
  const total = jackpots.find((jackpot) => jackpot.code === 'jackpot-total');

  useEffect(() => {
    if (!data || !data.length) return;
    let currentSlide = 0;
    setCurrentSlide(data[currentSlide]);
    const changeSlideInterval = setInterval(() => {
      if (currentSlide === data.length - 1) {
        currentSlide = 0;
      } else {
        currentSlide++;
      }
      setCurrentSlide(data[currentSlide]);
    }, 6000);
    return () => {
      clearInterval(changeSlideInterval);
    };
  }, [data]);

  return (
    <div
      className="flex h-[407px] w-[clamp(96%,98%,374px)] max-w-[374px] items-end justify-start rounded-[10px] bg-sjw_bg pb-5"
      style={{
        backgroundImage: `url(${CONTENT_URL}${slide.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }}>
      <div className="flex w-full flex-col items-center overflow-hidden">
        {currentSlide && (
          <>
            <div
              style={{ textShadow: 'var(--sjw_text_shadow)' }}
              className="flex flex-col text-sjw_text text-[40px] font-bold items-center mb-[5px]">
              {total?.allJackpotsSum && (
                <CountUp
                  to={Number(makeFloat(total?.allJackpotsSum?.jackpotsFund))}
                  currency={total?.allJackpotsSum?.currency}
                />
              )}
              <DrawsBetweenJackpots
                classNames="font-bold text-center text-[16px] text-sjw_text px-2"
                jackpot={total}
                numberClassNames="!text-yellow"
              />
            </div>
            <div className="flex justify-center w-full mb-[5px]">
              <div className="relative flex flex-col gap-2.5 w-[270px] px-[12px] py-[8px] h-full rounded-lg bg-sjw_bg mx-[2.5px]">
                <p className="text-[12px] text-center">
                  {currentSlide.title && (
                    <span
                      className="text-center font-bold py-[5px] px-2.5 rounded-[20px] text-sjw_current_text"
                      style={{
                        backgroundColor: jackpotColors[currentSlide.title.toLowerCase()].color,
                        transition: 'background-color 0.5s ease'
                      }}>
                      {t(`jackpots.${currentSlide?.title?.toLowerCase()}`).toUpperCase()}
                    </span>
                  )}
                </p>
                {currentSlide.jackpotInfo?.amount ? (
                  <div
                    style={{ textShadow: shadowColors[0] }}
                    className="text-sjw_current_info_text text-[27px] font-bold flex justify-center leading-none ">
                    {
                      <CountUp
                        to={Number(makeFloat(currentSlide.jackpotInfo?.amount))}
                        currency={currentSlide.allJackpotsSum?.currency}
                        reload={currentSlide.title}
                      />
                    }
                  </div>
                ) : null}
                <DrawsBetweenJackpots
                  classNames="font-bold text-center text-[12px] text-sjw_draws_between_text"
                  jackpot={currentSlide}
                  separate="and"
                />
                <div className="absolute top-0 right-[-280px] flex flex-col gap-2.5 w-[270px] h-full rounded-lg bg-sjw_bg mx-[2.5px] mb-[5px]" />
                <div className="absolute top-0 left-[-280px] flex flex-col gap-2.5 h-full  w-[270px] rounded-lg bg-sjw_bg mx-[2.5px] mb-[5px]" />
              </div>
            </div>
          </>
        )}
        <Button
          mode="button-secondary"
          onClick={() => {
            navigate('/jackpots');
            !isAuthenticated && openModal('SIGNUP');
          }}
          className="768:w-[40%] shadow-xl !h-[50px] 768:h-auto !w-[190px] mt-[5px] leading-[19px]"
          label={`${t('jackpots.getJackpot')}`}
          title={`${t('jackpots.getJackpot')}`}
        />
      </div>
    </div>
  );
};

export default memo(JackpotSlide);
