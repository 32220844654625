import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

interface TabsProps {
  items: Array<{ id: number; handleClick: () => void; label: string; isActive: boolean }>;
  className: string;
}

export const Tabs: FC<TabsProps> = ({ items, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={twJoin('p-0.5 bg-background-octal rounded-lg flex items-center gap-1', className)}>
      {items.map(({ id, handleClick, label, isActive }) => {
        return (
          <button
            key={id}
            onClick={handleClick}
            className={twJoin(
              'p-2 text-base capitalize w-full rounded-lg font-bold text-text-secondary',
              isActive && 'bg-topbar_search_button'
            )}>
            {t(label)}
          </button>
        );
      })}
    </div>
  );
};
