import { useGetAllProvidersCached } from 'src/hooks/useGetAllProvidersCached';
import { ProvidersSearchResults } from './components/ProvidersSearchResults';
import { AllProviders } from './components/AllProviders';

export const Providers = () => {
  const { providers: allProviders, isFetching } = useGetAllProvidersCached();

  return (
    <>
      <ProvidersSearchResults providers={allProviders} isFetching={isFetching} />
      <AllProviders providers={allProviders} isFetching={isFetching} />
    </>
  );
};
