// * Logo imports
import logo from 'src/assets/images/logoSidebar.webp';
import smallLogo from 'src/assets/images/logoMini.webp';
import { replaceWithBigLogo, replaceWithMiniLogo } from 'src/event/utils/replaceWithBigLogo';

export const EVENT_IMAGES = {
  LOGO: {
    REGULAR: replaceWithBigLogo(logo),
    SMALL: replaceWithMiniLogo(smallLogo)
  }
};
