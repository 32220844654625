import { MouseEvent } from 'react';
import { DropDownOption } from '../../types';
import { Radio } from './Radio';

export const RadioGroup = ({
  name,
  onClick,
  options = [],
  checkValue,
  labelsClassName
}: {
  name?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  options?: DropDownOption[];
  checkValue?: string;
  labelsClassName?: string;
}) => {
  return (
    <div className="flex gap-7 w-full flex-wrap" onClick={onClick}>
      {options.map((option) => (
        <Radio
          labelClassName={labelsClassName}
          name={name}
          key={option.id}
          value={option.id}
          label={option.name}
          checked={checkValue === option.id}
        />
      ))}
    </div>
  );
};
