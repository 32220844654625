import { useDrawer } from 'src/hooks/useDrawer';
import { EVENT_IMAGES } from 'src/constants/eventImages';
import { NavLinkLng } from 'src/lib';

export const Logo = () => {
  const { closeProvidersSearch } = useDrawer();
  return (
    <NavLinkLng
      to="/"
      aria-label="main page"
      className="flex items-center justify-center cursor-pointer w-[45px]"
      onClick={closeProvidersSearch}>
      <img src={EVENT_IMAGES.LOGO.SMALL} alt="logo" className="w-full h-auto object-contain" />
    </NavLinkLng>
  );
};
