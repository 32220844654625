import { useState } from 'react';

export const COOLDOWN = 43200;
export const TIME_ROTATE = 8000;

export const storageKeys = {
  startTimer: 'startTimer',
  freespinsCount: 'freespinsCount',
  canRotate: 'canRotate'
};

export const calcStartingTime = (time: string | number | null): number => {
  if (!time) return COOLDOWN;

  const timeLeft = Math.floor(COOLDOWN - Math.abs(Date.now() / 1000 - +time));

  if (timeLeft <= 0) return COOLDOWN;

  return timeLeft;
};

export const useTimeLeft = () => {
  const [timeLeft, setTimeLeft] = useState<number>(() => {
    const timeFromLS = localStorage.getItem(storageKeys.startTimer);
    const timeNow = Math.floor(Date.now() / 1000);

    if (timeFromLS && calcStartingTime(timeFromLS) === COOLDOWN) {
      localStorage.setItem(storageKeys.startTimer, `${timeNow}`);
      localStorage.setItem(storageKeys.canRotate, 'true');
      localStorage.setItem(storageKeys.freespinsCount, '2');

      return calcStartingTime(timeNow);
    }

    if (!timeFromLS) {
      localStorage.setItem(storageKeys.startTimer, `${timeNow}`);

      return calcStartingTime(timeNow);
    }

    return calcStartingTime(timeFromLS);
  });

  return { timeLeft, setTimeLeft };
};
