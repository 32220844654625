import { useTranslation } from 'react-i18next';
import { FC } from 'react';

interface TableHeaderProps {
  columnHeaders: string[];
}

export const TableHeader: FC<TableHeaderProps> = ({ columnHeaders }) => {
  const { t } = useTranslation();

  return (
    <>
      {columnHeaders?.map((param: string) => (
        <th
          key={param}
          className="font-bold text-xs text-white bg-background-hexademical p-2.5 pl-6 first:rounded-tl-md text-left last:rounded-tr-md">
          {t(param)}
        </th>
      ))}
    </>
  );
};
