import { FC, PropsWithChildren, ReactNode } from 'react';
import { CloseIcon } from './components/CloseIcon';
import { useDeviceDetect } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';
import { useModalContext } from 'src/hooks/useModalContext';

type ModalWrapperProps = {
  isGameReady: boolean;
  preloader: ReactNode;
  screenRotateImage: ReactNode;
};

export const ModalWrapper: FC<PropsWithChildren<ModalWrapperProps>> = ({
  children,
  isGameReady,
  preloader,
  screenRotateImage
}) => {
  const { isMobile } = useDeviceDetect();
  const { closeModal } = useModalContext();

  return (
    <div
      className={twJoin(
        'relative overflow-hidden',
        isMobile && 'landscape:w-screen landscape:h-full',
        isMobile && 'portrait:w-screen portrait:aspect-[16/9]',
        !isMobile && 'aspect-[16/9] max-w-[1024px] w-full rounded-2xl'
      )}>
      {!isGameReady && !Boolean(screenRotateImage) && (
        <CloseIcon
          className="absolute top-0 right-0 cursor-pointer z-[20] box-content pt-5 px-5"
          onClick={closeModal}
        />
      )}
      {screenRotateImage}
      {!isGameReady && preloader}
      {children}
    </div>
  );
};
