import { FC } from 'react';
import ModalWrapper from 'src/components/ModalWrapper';
import { useAutoDepositSuccess, useGetTransactionsHistory } from 'react-easyrocket';
import { TransactionTable } from 'src/pages/AccountGameHistory/components/TransactionTable';
import { TableSkeleton } from 'src/pages/AccountGameHistory/components/TransactionTable/components/TableSkeleton';

import { TransactionTableRow } from 'src/pages/AccountGameHistory/components/TransactionTable/components/TransactionTableRow';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'src/types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const LatestTransaction: FC = () => {
  const { t } = useTranslation();
  const { transactions, isLoading } = useGetTransactionsHistory('deposit');

  useAutoDepositSuccess({ transactions });

  return (
    <ModalWrapper>
      <div className="bg-background-main max-w-[680px]">
        <TransactionTable>
          {isLoading ? (
            <TableSkeleton count={10} />
          ) : (
            transactions.map((data, index) => {
              const { id, type, amount, balance, dateCreate, currency, status } = data;
              const date = dayjs(dateCreate).format('DD-MM-YYYY');

              return (
                <TransactionTableRow
                  key={index}
                  id={id}
                  type={t(TRANSACTION_TYPE[type])}
                  amount={amount}
                  balance={balance}
                  currency={currency}
                  date={`${date} ${t(TRANSACTION_STATUS[status])}`}
                />
              );
            })
          )}
        </TransactionTable>
      </div>
    </ModalWrapper>
  );
};
