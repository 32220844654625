import classNames from 'classnames';
import { FC } from 'react';
import './styles.css';

type PercentageScaleProps = {
  value?: number;
  additionalClassName?: string;
};

export const PercentageScale: FC<PercentageScaleProps> = ({ value, additionalClassName }) => {
  return (
    <div className={classNames('main-container', additionalClassName)}>
      <div className='scale-container' style={{width: `${value}%`}}></div>
    </div>
  );
};
