import {
  ChangeEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { DropDownOption } from '../../types';
import { Input } from '../Inputs/Input';
import { useClickOutside } from '../../hooks/useClickOutside';
import classNames from 'classnames';
import { AutocompleteIcon } from '../svg';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: string;
  options?: DropDownOption[];
  filter?: (value: string | undefined, options: DropDownOption[]) => DropDownOption[];
  onBlur?: (inputValue: string | undefined, option?: DropDownOption) => void;
  onSelect?: (inputValue: string | undefined, option?: DropDownOption) => void;
  onChange?: (inputValue: string, option?: DropDownOption) => void;
  onFocus?: () => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  labelWithPadding?: boolean;
  inputBorderColor?: string;
  conditionForRegex?: string | RegExp;
  regexRestrictedSymbols?: RegExp;
  regexAllowedSymbols?: RegExp;
  notFoundText?: string;
  maxLength?: number;
  formatter?: (value: string) => string;
};

export const Autocomplete = ({
  options = [],
  filter,
  onBlur,
  onSelect,
  onChange,
  onFocus,
  value = '',
  label,
  disabled,
  className,
  inputBorderColor,
  labelWithPadding,
  regexRestrictedSymbols,
  regexAllowedSymbols,
  notFoundText,
  maxLength,
  formatter,
  ...restProps
}: Props) => {
  const [valueString, setValueString] = useState(value);
  const [valueObject, setValueObject] = useState<DropDownOption | undefined>(undefined);
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState<DropDownOption[]>(
    () => filter?.(valueString, options) || options
  );
  const [focused, setFocused] = useState(false);

  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValueString(value);
  }, [value]);

  useEffect(() => {
    if (valueString.length === 0) {
      setSuggestions(options);
      return;
    }
    setSuggestions(filter?.(valueString, options) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, valueString.length, focused]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      let nextValue = e.target.value;
      if (regexRestrictedSymbols) {
        nextValue = e.target.value.replace(regexRestrictedSymbols, '');
      }

      if (regexAllowedSymbols) {
        nextValue = nextValue.match(regexAllowedSymbols)?.join('') || '';
      }

      if (maxLength) {
        nextValue = nextValue.substring(0, maxLength);
      }
      if (formatter) {
        nextValue = formatter(nextValue);
      }
      onChange?.(nextValue, valueObject);
      setValueString(nextValue);
    },
    [formatter, maxLength, onChange, regexAllowedSymbols, regexRestrictedSymbols, valueObject]
  );

  const handleSelect = useCallback(
    (option: DropDownOption) => {
      setValueObject(option);
      setValueString(option.name);
      setSuggestions([]);
      setFocused(false);
      onSelect?.(valueString, option);
      onBlur?.(valueString, option);
    },
    [onBlur, onSelect, valueString]
  );

  const handleBlur = useCallback(() => {
    if (!focused) return;
    setFocused(false);
    onBlur?.(valueString, valueObject);
  }, [focused, onBlur, valueObject, valueString]);

  useClickOutside(autocompleteRef, handleBlur);

  return (
    <div className="flex flex-col justify-between h-full items-start gap-2 w-[100%]">
      {label ? (
        <label className={classNames('text-text-secondary', labelWithPadding && 'pl-[20px]')}>
          {label}
        </label>
      ) : null}
      <div className="flex relative justify-center w-full" ref={autocompleteRef}>
        <Input
          {...restProps}
          overrideContainerClassName="w-full z-40"
          value={valueString}
          onChange={(e) => disabled || handleChange(e)}
          ref={inputRef}
          onFocus={() => {
            if (disabled) return;
            setFocused(true);
            onFocus?.();
          }}
          onBlur={(e) => disabled || e.preventDefault()}
          autoComplete="off"
          className={`${className}`}
          inputBorderColor={inputBorderColor}
          rightIcon={<AutocompleteIcon onClick={() => setFocused(!focused)} />}
          disabled={disabled}
          arrowClassName={focused ? 'opened' : ''}
        />
        {focused && suggestions.length ? (
          <OptionsWrapper>
            <Options options={suggestions} onSelect={handleSelect} />
          </OptionsWrapper>
        ) : null}

        {focused && !suggestions.length ? (
          <OptionsWrapper>
            <div className="text-[12px] cursor-pointer py-2 px-4 w-full">
              {t(`${notFoundText}`)}
            </div>
            <Options options={options} onSelect={handleSelect} />
          </OptionsWrapper>
        ) : null}
      </div>
    </div>
  );
};

function Options({
  options,
  onSelect
}: {
  options: DropDownOption[];
  onSelect: (option: DropDownOption) => void;
}) {
  return (
    <>
      {options.map((element) => (
        <div
          className="text-[14px] cursor-pointer py-2 px-4 w-full hover:bg-input-border"
          onClick={() => onSelect(element)}
          key={element.id}>
          {element.name}
        </div>
      ))}
    </>
  );
}

function OptionsWrapper({ children }: PropsWithChildren) {
  return (
    <div className="absolute rounded-[20px] top-[0] bg-text-secondary z-30 flex flex-col items-start overflow-y-auto w-full max-h-[150px] pt-[2.5em] pb-2 border border-solid border-input-border shadow-lg">
      {children}
    </div>
  );
}
