export const TopIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.803 0.361694H9.2163L0.0112305 10.2244L20.0097 31.6403L40.0081 10.2244L30.803 0.361694ZM29.7848 2.70481L35.7093 9.05256H28.1769L24.645 2.70481H29.7848ZM21.9635 2.70481L25.4954 9.05256H14.5239L18.0558 2.70481H21.9635ZM10.2347 2.70489H15.3745L11.8426 9.05264H4.31012L10.2347 2.70489ZM4.31105 11.3958H11.7302L17.0347 25.0211L4.31105 11.3958ZM14.2447 11.3958H25.7747L20.0097 26.2041L14.2447 11.3958ZM22.9847 25.0211L28.2891 11.3958H35.7083L22.9847 25.0211Z"
        fill="white"
      />
    </svg>
  );
};
