export const Cherry = ({ width = 23, height = 23 }: { width?: number; height?: number }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5265 14.5405L18.8617 14.1231C17.972 13.5635 17.1579 12.7737 16.3005 11.6222C14.0859 8.63442 12.8129 5.0539 12.6445 1.33866L12.5977 0.253723L19.5265 14.5405ZM13.1842 2.49287C13.5387 5.70171 14.7385 8.75895 16.6613 11.3523C17.1602 12.0439 17.7404 12.673 18.3894 13.2262L13.1842 2.49287Z"
        fill="#7E7B21"
      />
      <path
        d="M5.70026 17.0622L5.30532 16.8507L11.9247 1.41153L12.3511 1.55187C11.0478 7.06767 8.79744 12.3156 5.70026 17.0622ZM11.0746 4.5377L6.98311 14.079C8.68432 11.0541 10.056 7.85535 11.0746 4.5377Z"
        fill="#7E7B21"
      />
      <path
        d="M12.4331 20.0849C11.9599 21.4442 11.0755 22.6938 9.83407 23.4233C9.03432 23.8929 7.80544 23.7283 6.92022 23.6725C5.65536 23.5925 4.66308 23.9055 3.4522 23.2929C2.43884 22.7667 1.61304 21.94 1.08801 20.926C0.476268 19.7511 0.313438 18.1039 0.734458 16.7311C0.880184 16.2361 1.11861 15.7733 1.43706 15.3673C1.76097 14.9901 2.15629 14.6807 2.60026 14.4569C3.4486 13.9981 4.18269 13.8199 5.11919 13.8973C6.10876 13.9801 7.37992 14.2518 8.34701 13.9954C9.43285 13.7093 10.4035 13.8577 11.1655 14.3714C11.8231 14.8149 12.3242 15.5301 12.6049 16.472C12.9593 17.646 12.8361 18.9271 12.4331 20.0849Z"
        fill="#B2001C"
      />
      <path
        d="M11.1822 20.3305C10.3219 21.5516 9.03563 22.4066 7.57659 22.727C5.49578 23.1912 3.15677 22.4707 1.81185 20.8172C1.35991 20.2473 1.03071 19.5902 0.844935 18.887C0.659155 18.1838 0.62081 17.4498 0.732308 16.7311C0.878579 16.236 1.11797 15.7732 1.43706 15.3673C1.76097 14.9901 2.15629 14.6807 2.60026 14.4569C3.4486 13.9981 4.18269 13.8199 5.11919 13.8973C6.10876 13.9801 7.37992 14.2518 8.34701 13.9954C9.43285 13.7093 10.4035 13.8577 11.1655 14.3714C11.5823 14.8741 11.8736 15.4686 12.0162 16.1059C12.3481 17.5444 12.0198 19.1133 11.1822 20.3305Z"
        fill="#F80C32"
      />
      <path
        d="M2.69825 15.1019C2.50479 15.2611 2.36321 15.4744 2.29162 15.7146C2.20223 15.9561 2.20899 16.2227 2.31051 16.4594C2.37092 16.5709 2.45271 16.6693 2.55119 16.7491C2.64968 16.8289 2.76293 16.8885 2.88447 16.9245C3.12833 16.9944 3.38296 17.0188 3.63565 16.9965C4.04048 16.9749 4.48219 16.6843 4.73048 16.3668C5.74795 15.0497 3.60776 14.3777 2.69825 15.1019Z"
        fill="white"
      />
      <path
        d="M25.1193 17.9024C24.6461 19.2617 23.7627 20.5113 22.5212 21.24C21.7206 21.7096 20.4917 21.5449 19.6065 21.4892C18.3416 21.4091 17.3493 21.7222 16.1393 21.1104C15.1255 20.5842 14.2993 19.7571 13.7743 18.7426C13.1625 17.5677 13.0006 15.9205 13.4216 14.5477C13.567 14.0537 13.8055 13.592 14.1242 13.1875C14.4468 12.8093 14.8412 12.4989 15.2847 12.2744C16.133 11.8156 16.8671 11.6375 17.8036 11.7148C18.7932 11.7967 20.0644 12.0693 21.0315 11.8129C22.1173 11.5259 23.0889 11.6743 23.8509 12.188C24.5076 12.6315 25.0087 13.3476 25.2902 14.2886C25.6456 15.4626 25.5232 16.7437 25.1193 17.9024Z"
        fill="#B3001C"
      />
      <path
        d="M23.869 18.148C23.0078 19.3683 21.7219 20.223 20.2633 20.5446C18.1816 21.0079 15.8435 20.2873 14.4977 18.6338C14.0456 18.0641 13.7162 17.4069 13.5305 16.7037C13.3447 16.0005 13.3064 15.2664 13.4181 14.5477C13.5636 14.0537 13.802 13.592 14.1207 13.1875C14.4441 12.809 14.8403 12.4986 15.2847 12.2744C16.133 11.8156 16.8671 11.6375 17.8036 11.7148C18.7932 11.7967 20.0644 12.0693 21.0315 11.8129C22.1173 11.5259 23.0889 11.6743 23.8509 12.188C24.2671 12.691 24.5576 13.2854 24.7002 13.9225C25.034 15.3619 24.7056 16.9299 23.869 18.148Z"
        fill="#F80C32"
      />
      <path
        d="M15.386 12.9185C15.1922 13.0776 15.0503 13.2909 14.9784 13.5312C14.889 13.7727 14.8958 14.0393 14.9973 14.276C15.0577 14.3875 15.1395 14.486 15.238 14.5658C15.3365 14.6456 15.4497 14.7052 15.5713 14.7411C15.815 14.8117 16.0697 14.8364 16.3225 14.814C16.7273 14.7915 17.169 14.5018 17.4173 14.1843C18.433 12.8663 16.2946 12.1961 15.386 12.9185Z"
        fill="white"
      />
      <path
        d="M11.1786 1.54917C11.7184 1.78937 12.3643 1.84155 12.9437 1.96839L13.1524 1.21002C12.5344 1.08857 11.9073 1.0049 11.283 0.927538C10.7171 0.857368 10.7234 1.74349 11.283 1.81366C11.8686 1.88563 12.4579 1.9513 13.048 1.98279C13.5572 2.00978 13.5482 1.23341 13.048 1.19652C12.4606 1.15244 11.8084 1.04449 11.2281 1.17134C11.0626 1.20732 11.014 1.4763 11.1786 1.54917Z"
        fill="#7E7B21"
      />
      <path
        d="M11.512 1.00762C11.512 1.00762 9.02191 5.94112 13.4228 7.98864C13.4228 7.99044 16.1082 3.68398 11.512 1.00762Z"
        fill="#769A2C"
      />
      <path
        d="M11.5258 0.940133C11.5258 0.940133 14.6168 7.36159 20.1621 4.46932C20.1666 4.46932 17.8726 -1.65886 11.5258 0.940133Z"
        fill="#8EB125"
      />
    </svg>
  );
};

export const Crown = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.0827 24.531H4.69458L3.45181 10.3584C3.45181 9.79618 4.20005 9.60248 4.47285 10.0942L7.31063 15.2091L10.6515 4.94742C10.8147 4.44601 11.5241 4.44601 11.6874 4.94742L15.0281 12.2402L18.369 4.94737C18.5322 4.44596 19.2416 4.44596 19.4049 4.94737L22.7457 15.209L25.5835 10.0941C25.8562 9.60253 26.6045 9.79613 26.6045 10.3583L25.0827 24.531Z"
        fill="#FFCA25"
      />
      <path
        d="M25.5846 10.0942L23.6507 13.5798L23.194 17.8332C22.9288 20.3028 20.8445 22.1752 18.3608 22.1752L4.5653 23.0444L4.69567 24.531H25.0837L26.6056 10.3584C26.6056 9.79616 25.8574 9.60251 25.5846 10.0942Z"
        fill="#F3B800"
      />
      <path
        d="M3.88134 9.81868C4.45331 9.81868 4.91698 9.355 4.91698 8.78304C4.91698 8.21107 4.45331 7.7474 3.88134 7.7474C3.30937 7.7474 2.8457 8.21107 2.8457 8.78304C2.8457 9.355 3.30937 9.81868 3.88134 9.81868Z"
        fill="#FC4C59"
      />
      <path
        d="M26.1183 9.81868C26.6903 9.81868 27.1539 9.35501 27.1539 8.78304C27.1539 8.21107 26.6903 7.7474 26.1183 7.7474C25.5463 7.7474 25.0827 8.21107 25.0827 8.78304C25.0827 9.35501 25.5463 9.81868 26.1183 9.81868Z"
        fill="#FC4C59"
      />
      <path
        d="M11.162 4.57128C11.734 4.57128 12.1977 4.10761 12.1977 3.53564C12.1977 2.96367 11.734 2.5 11.162 2.5C10.5901 2.5 10.1264 2.96367 10.1264 3.53564C10.1264 4.10761 10.5901 4.57128 11.162 4.57128Z"
        fill="#FC4C59"
      />
      <path
        d="M18.9194 4.57128C19.4914 4.57128 19.9551 4.10761 19.9551 3.53564C19.9551 2.96367 19.4914 2.5 18.9194 2.5C18.3475 2.5 17.8838 2.96367 17.8838 3.53564C17.8838 4.10761 18.3475 4.57128 18.9194 4.57128Z"
        fill="#FC4C59"
      />
      <path
        d="M3.88134 8.99038C3.45678 8.99038 3.09228 8.73462 2.93247 8.369C2.87695 8.4959 2.8457 8.63562 2.8457 8.78304C2.8457 9.355 3.30937 9.81868 3.88134 9.81868C4.45331 9.81868 4.91698 9.355 4.91698 8.78304C4.91698 8.63562 4.88568 8.4959 4.83026 8.369C4.67035 8.73462 4.3059 8.99038 3.88134 8.99038Z"
        fill="#E83A64"
      />
      <path
        d="M26.1183 8.99039C25.6938 8.99039 25.3292 8.73463 25.1694 8.36901C25.1139 8.49591 25.0827 8.63563 25.0827 8.78304C25.0827 9.35501 25.5463 9.81868 26.1183 9.81868C26.6903 9.81868 27.1539 9.35501 27.1539 8.78304C27.1539 8.63563 27.1226 8.49591 27.0672 8.36901C26.9074 8.73463 26.543 8.99039 26.1183 8.99039Z"
        fill="#E83A64"
      />
      <path
        d="M11.162 3.74283C10.7375 3.74283 10.373 3.48707 10.2131 3.12144C10.1576 3.24835 10.1264 3.38823 10.1264 3.53564C10.1264 4.10761 10.5901 4.57128 11.162 4.57128C11.734 4.57128 12.1977 4.10761 12.1977 3.53564C12.1977 3.38823 12.1664 3.24835 12.111 3.12144C11.9511 3.48707 11.5866 3.74283 11.162 3.74283Z"
        fill="#E83A64"
      />
      <path
        d="M18.9185 3.74284C18.4939 3.74284 18.1294 3.48708 17.9696 3.12145C17.9141 3.24836 17.8838 3.38823 17.8838 3.53564C17.8838 4.10761 18.3475 4.57128 18.9194 4.57128C19.4914 4.57128 19.9551 4.10761 19.9551 3.53564C19.9551 3.38823 19.9228 3.24836 19.8674 3.12145C19.7076 3.48708 19.3431 3.74284 18.9185 3.74284Z"
        fill="#E83A64"
      />
      <path
        d="M14.8677 21.6943C16.0974 21.6943 17.0943 20.2964 17.0943 18.5721C17.0943 16.8478 16.0974 15.45 14.8677 15.45C13.638 15.45 12.641 16.8478 12.641 18.5721C12.641 20.2964 13.638 21.6943 14.8677 21.6943Z"
        fill="#FD4DD7"
      />
      <path
        d="M20.2641 19.8712C20.8618 19.8712 21.3464 19.3866 21.3464 18.7889C21.3464 18.1911 20.8618 17.7066 20.2641 17.7066C19.6663 17.7066 19.1817 18.1911 19.1817 18.7889C19.1817 19.3866 19.6663 19.8712 20.2641 19.8712Z"
        fill="#7C54B8"
      />
      <path
        d="M9.47214 19.8712C10.0699 19.8712 10.5545 19.3866 10.5545 18.7889C10.5545 18.1911 10.0699 17.7066 9.47214 17.7066C8.87439 17.7066 8.38982 18.1911 8.38982 18.7889C8.38982 19.3866 8.87439 19.8712 9.47214 19.8712Z"
        fill="#7C54B8"
      />
      <path
        d="M14.8677 20.8288C13.7428 20.8288 12.8133 19.6588 12.6629 18.1393C12.6489 18.2809 12.641 18.4252 12.641 18.5721C12.641 20.2964 13.638 21.6943 14.8677 21.6943C16.0974 21.6943 17.0943 20.2964 17.0943 18.5721C17.0943 18.4252 17.0865 18.2808 17.0725 18.1393C16.9222 19.6588 15.9927 20.8288 14.8677 20.8288Z"
        fill="#E846AA"
      />
      <path
        d="M20.264 19.005C19.8203 19.005 19.4395 18.7377 19.2724 18.3557C19.2144 18.4884 19.1817 18.6349 19.1817 18.7889C19.1817 19.3866 19.6663 19.8712 20.2641 19.8712C20.8618 19.8712 21.3464 19.3866 21.3464 18.7889C21.3464 18.6348 21.3137 18.4883 21.2557 18.3557C21.0885 18.7377 20.7077 19.005 20.264 19.005Z"
        fill="#674799"
      />
      <path
        d="M9.47209 19.005C9.02839 19.005 8.64758 18.7377 8.48049 18.3557C8.42248 18.4884 8.38982 18.6349 8.38982 18.7889C8.38982 19.3866 8.87439 19.8712 9.47214 19.8712C10.0699 19.8712 10.5545 19.3866 10.5545 18.7889C10.5545 18.6348 10.5218 18.4883 10.4637 18.3557C10.2966 18.7377 9.91574 19.005 9.47209 19.005Z"
        fill="#674799"
      />
      <path
        d="M25.2561 24.3929H4.80231C4.32555 24.3929 3.93903 24.7795 3.93903 25.2562V26.6367C3.93903 27.1135 4.32555 27.5 4.80231 27.5H25.2561C25.7328 27.5 26.1194 27.1135 26.1194 26.6367V25.2562C26.1194 24.7794 25.7328 24.3929 25.2561 24.3929Z"
        fill="#FC4C59"
      />
      <path
        d="M25.2561 24.3929L22.5807 24.3929C23.0574 24.3929 23.4439 24.7795 23.4439 25.2562V26.6367C23.4439 27.1135 23.0575 27.5 22.5807 27.5L25.2561 27.5C25.7328 27.5 26.1194 27.1135 26.1194 26.6367V25.2562C26.1194 24.7794 25.7328 24.3929 25.2561 24.3929Z"
        fill="#E83A64"
      />
    </svg>
  );
};

export const Ball = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.4996 15C27.4996 20.971 23.3144 25.9654 17.7062 27.207C16.8413 27.4023 15.9345 27.5 14.9998 27.5C8.09422 27.5 2.5 21.9057 2.5 15C2.5 8.09431 8.09422 2.5 14.9998 2.5C15.9206 2.5 16.8274 2.59766 17.7062 2.79297C23.3144 4.0346 27.4996 9.02902 27.4996 15Z"
        fill="#C90808"
      />
      <path
        d="M27.4996 15C27.4996 20.971 23.3144 25.9654 17.7062 27.207C12.0981 25.9794 7.91327 20.9709 7.91327 14.9999C7.91327 9.02896 12.0981 4.0346 17.7062 2.79297C23.3144 4.0346 27.4996 9.02902 27.4996 15Z"
        fill="#FF0707"
      />
    </svg>
  );
};

export const Present = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.7784 8.07256H16.8002V10.0444H24.7784L24.084 9.05951L24.7784 8.07256Z"
        fill="#35AE95"
      />
      <path
        d="M22.1431 8.07256H16.8002V10.0444H24.7784L24.3112 9.38107H23.5192C23.3386 9.38134 23.1597 9.34769 22.9927 9.28205C22.8258 9.21641 22.6741 9.12007 22.5463 8.99854C22.4185 8.877 22.3171 8.73266 22.2479 8.57377C22.1787 8.41489 22.1431 8.24457 22.1431 8.07256Z"
        fill="black"
      />
      <path d="M25.3775 11.5107H4.61974V27.4879H25.3775V11.5107Z" fill="#50C7A4" />
      <path
        d="M17.1381 11.5107V10.0445H13.3582V11.5107L4.61974 11.5107V13.8264C4.61974 14.1777 4.76628 14.5147 5.02713 14.7631C5.28798 15.0115 5.64177 15.1511 6.01066 15.1511H9.08769C10.2203 15.1511 11.3065 15.5796 12.1074 16.3423C12.9082 17.1051 13.3582 18.1395 13.3582 19.2182V27.4879H16.6263C16.6935 27.4879 16.7601 27.4753 16.8222 27.4508C16.8843 27.4263 16.9407 27.3904 16.9882 27.3452C17.0357 27.2999 17.0734 27.2462 17.0991 27.1871C17.1249 27.1279 17.1381 27.0645 17.1381 27.0005V17.1978C17.1385 17.0075 17.1967 16.8214 17.3056 16.6619C17.4145 16.5024 17.5695 16.3763 17.7519 16.2987C17.9343 16.2211 18.1364 16.1954 18.3338 16.2247C18.5312 16.254 18.7155 16.3369 18.8645 16.4637C19.4274 16.9445 19.8775 17.5328 20.1856 18.1904C20.4936 18.8479 20.6528 19.5599 20.6526 20.28V27.4879L25.3775 27.4879V11.5107L17.1381 11.5107Z"
        fill="#34A890"
      />
      <path
        d="M7.45601 8.07256H13.9477V10.0444H7.45601L8.15041 9.05951L7.45601 8.07256Z"
        fill="#50C7A4"
      />
      <path d="M11.483 8.07256H9.4168L8.56738 10.0444H10.6336L11.483 8.07256Z" fill="#61CFAF" />
      <path d="M7.84887 9.48625L7.45601 10.0444H13.9477V9.48625H7.84887Z" fill="black" />
      <path
        d="M15.3176 9.68444L14.0329 9.88668L11.2001 4.2178C11.1245 4.06718 11.099 3.89815 11.1271 3.73337C11.1552 3.56858 11.2355 3.41591 11.3572 3.29579C11.479 3.17567 11.6363 3.09385 11.8082 3.06128C11.9802 3.02871 12.1584 3.04695 12.3192 3.11355L13.3618 3.54231L14.2813 2.73334C14.4072 2.62104 14.5657 2.54739 14.7361 2.52194C14.9065 2.4965 15.081 2.52044 15.2369 2.59066C15.3928 2.66088 15.523 2.77413 15.6105 2.91571C15.698 3.05728 15.7387 3.22064 15.7275 3.38456L15.3176 9.68444Z"
        fill="#50C7A4"
      />
      <path
        d="M14.6424 9.55706L13.7314 10.1638L9.36112 6.76003C9.24518 6.67003 9.16084 6.54847 9.11938 6.4116C9.07792 6.27473 9.0813 6.12906 9.12909 5.99408C9.17687 5.85909 9.26678 5.7412 9.38679 5.65618C9.5068 5.57115 9.6512 5.52303 9.8007 5.51826L10.7712 5.48387L11.1683 4.53131C11.2231 4.40131 11.3175 4.28992 11.4394 4.21146C11.5612 4.133 11.705 4.09108 11.852 4.09108C11.9991 4.09108 12.1429 4.133 12.2647 4.21146C12.3866 4.28992 12.481 4.40131 12.5358 4.53131L14.6424 9.55706Z"
        fill="#8EDBBC"
      />
      <path
        d="M13.1725 4.73956C13.1177 4.60956 13.0233 4.49817 12.9014 4.41971C12.7796 4.34125 12.6358 4.29933 12.4887 4.29933C12.3416 4.29933 12.1979 4.34125 12.076 4.41971C11.9542 4.49817 11.8598 4.60956 11.8049 4.73956L11.6478 5.11775L14.0326 9.89676L15.2515 9.69452L13.1725 4.73956Z"
        fill="black"
      />
      <path
        d="M14.6424 9.55706L13.7314 10.1638L9.36112 6.76003C9.24518 6.67003 9.16084 6.54847 9.11938 6.4116C9.07792 6.27473 9.0813 6.12906 9.12909 5.99408C9.17687 5.85909 9.26678 5.7412 9.38679 5.65618C9.5068 5.57115 9.6512 5.52303 9.8007 5.51826L10.7712 5.48387L11.1683 4.53131C11.2231 4.40131 11.3175 4.28992 11.4394 4.21146C11.5612 4.133 11.705 4.09108 11.852 4.09108C11.9991 4.09108 12.1429 4.133 12.2647 4.21146C12.3866 4.28992 12.481 4.40131 12.5358 4.53131L14.6424 9.55706Z"
        fill="#8EDBBC"
      />
      <path
        d="M14.9946 9.47208L16.0564 10.1961L21.206 6.26451C21.3429 6.16033 21.4431 6.01884 21.4932 5.85897C21.5433 5.6991 21.5409 5.52847 21.4864 5.3699C21.4319 5.21134 21.3279 5.07241 21.1882 4.97169C21.0485 4.87098 20.8799 4.81328 20.7049 4.80633L19.5709 4.75779L19.1186 3.63939C19.0568 3.48614 18.9481 3.35411 18.8066 3.26042C18.665 3.16673 18.4972 3.11571 18.3248 3.11396C18.1525 3.11222 17.9836 3.15984 17.84 3.25064C17.6964 3.34145 17.5848 3.47125 17.5196 3.62321L14.9946 9.47208Z"
        fill="#50C7A4"
      />
      <path
        d="M20.2717 6.97645L20.3439 6.03804C20.3561 5.88405 20.3195 5.7301 20.2388 5.59616C20.1581 5.46223 20.037 5.35448 19.8912 5.28691C19.7454 5.21933 19.5816 5.19503 19.4211 5.21718C19.2606 5.23932 19.1109 5.30688 18.9912 5.41109L15.4024 8.52968L14.9946 9.47208L16.0564 10.1941H16.067L20.2717 6.97645Z"
        fill="black"
      />
      <path
        d="M15.3216 9.41752L15.9141 10.4389L21.9534 9.06764C22.1131 9.03093 22.2572 8.94882 22.3667 8.83222C22.4762 8.71562 22.5458 8.57005 22.5663 8.41486C22.5868 8.25967 22.5572 8.10221 22.4815 7.96341C22.4058 7.8246 22.2876 7.71103 22.1424 7.63778L21.1996 7.16251L21.2866 6.03804C21.2989 5.88405 21.2623 5.7301 21.1816 5.59616C21.1009 5.46223 20.9797 5.35448 20.8339 5.28691C20.6881 5.21933 20.5244 5.19503 20.3639 5.21718C20.2034 5.23932 20.0536 5.30688 19.9339 5.41109L15.3216 9.41752Z"
        fill="#8EDBBC"
      />
      <path
        d="M16.4985 10.0262C16.4985 10.3094 16.4104 10.5863 16.2452 10.8217C16.08 11.0572 15.8452 11.2407 15.5704 11.3491C15.2957 11.4575 14.9934 11.4858 14.7018 11.4306C14.4101 11.3753 14.1422 11.239 13.932 11.0387C13.7217 10.8385 13.5785 10.5833 13.5205 10.3056C13.4625 10.0278 13.4922 9.73991 13.606 9.47827C13.7198 9.21663 13.9125 8.993 14.1598 8.83566C14.407 8.67832 14.6977 8.59434 14.9951 8.59434C15.3938 8.59434 15.7762 8.7452 16.0582 9.01373C16.3401 9.28226 16.4985 9.64647 16.4985 10.0262Z"
        fill="#50C7A4"
      />
      <path d="M26.413 10.0444H3.58691V13.9376H26.413V10.0444Z" fill="#35AE95" />
      <path d="M14.0372 10.0445H6.23319V13.9376H14.0372V10.0445Z" fill="#50C7A4" />
      <path d="M16.6398 15.5899L13.3589 17.2079V13.5999L16.6398 11.9819V15.5899Z" fill="#CDEED0" />
      <path
        d="M15.2645 8.61862C15.1755 8.60324 15.0856 8.59512 14.9951 8.59434C14.5965 8.59488 14.2141 8.74604 13.9325 9.01464C13.6508 9.28323 13.4927 9.6473 13.4927 10.0269C13.4927 10.0727 13.4927 10.1193 13.4927 10.1665H13.5606C13.9995 10.1679 14.422 10.0076 14.7396 9.7191C15.0572 9.43058 15.2453 9.03623 15.2645 8.61862Z"
        fill="#98EBC9"
      />
      <path d="M16.6398 10.0445H13.3582V27.4879H16.6398V10.0445Z" fill="#8EDBBC" />
      <path d="M16.6398 15.5899L13.3589 17.2079V13.5999L16.6398 11.9819V15.5899Z" fill="#98EBC9" />
    </svg>
  );
};

export const Chalice = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3331 21.6285L18.3184 21.636L18.3064 21.6428H11.6922L11.6654 21.6285C11.5867 21.5865 11.5177 21.5239 11.4641 21.4456C11.4105 21.3674 11.3738 21.2757 11.3569 21.1782C11.34 21.0806 11.3435 20.9798 11.367 20.8841C11.3905 20.7883 11.4335 20.7003 11.4923 20.6271C11.5226 20.5908 11.5565 20.5586 11.5933 20.5313C12.3035 20.0019 12.8459 19.3053 13.2938 18.503C13.3283 18.4404 13.361 18.3778 13.3944 18.3152C13.7421 17.6388 14.043 16.9321 14.2941 16.2017C14.3279 16.1091 14.3607 16.0139 14.3935 15.9213C14.5989 15.3318 14.7955 14.7241 14.9993 14.1114C15.2134 14.7529 15.4188 15.39 15.6346 16.0052C15.6482 16.0452 15.6625 16.0847 15.6767 16.1235C15.9842 16.9909 16.3174 17.8133 16.7304 18.5443C17.1734 19.3284 17.7088 20.0088 18.4058 20.5282C18.4882 20.5898 18.5546 20.6757 18.5982 20.7768C18.6418 20.8779 18.6608 20.9905 18.6534 21.1029C18.646 21.2154 18.6123 21.3234 18.556 21.4159C18.4997 21.5083 18.4227 21.5817 18.3331 21.6285Z"
        fill="#FEC611"
      />
      <path
        d="M18.3331 21.6285L18.3184 21.636L18.2283 21.6393C18.2009 21.6393 18.1736 21.6393 18.1469 21.6431L11.6922 21.6428L11.6654 21.6285C11.5867 21.5865 11.5177 21.5239 11.4641 21.4456C11.4105 21.3674 11.3738 21.2757 11.3569 21.1782C11.34 21.0806 11.3435 20.9798 11.367 20.8841C11.3905 20.7883 11.4335 20.7003 11.4923 20.6271C11.5819 20.6158 11.6714 20.6104 11.761 20.6048C13.307 20.5046 15.3604 20.4796 16.3027 20.5422C16.4966 20.5547 16.7583 20.5209 16.8391 20.3194C16.8792 20.2246 16.9003 20.1207 16.9008 20.0153C16.9014 19.91 16.8814 19.8058 16.8424 19.7104C16.6086 19.1766 16.2131 19.0508 15.71 18.8968C15.2069 18.7429 14.6792 18.7178 14.1673 18.6039C13.9 18.5485 13.6402 18.4534 13.3944 18.3211C13.7421 17.6447 14.043 16.938 14.2941 16.2076C14.4892 16.1418 14.6885 16.0931 14.89 16.0617C15.1368 16.024 15.3857 16.007 15.6346 16.011C15.6482 16.0511 15.6625 16.0905 15.6767 16.1293C15.9842 16.9967 16.3174 17.8191 16.7304 18.5501C17.1734 19.3343 17.7088 20.0146 18.4058 20.5341C18.487 20.596 18.5523 20.6817 18.595 20.7821C18.6378 20.8826 18.6564 20.9943 18.649 21.1057C18.6416 21.2172 18.6084 21.3243 18.5529 21.4162C18.4974 21.5081 18.4216 21.5812 18.3331 21.6285Z"
        fill="#F59A00"
      />
      <path
        d="M9.49489 12.9986C7.41282 13.2237 5.34278 13.5766 3.29369 14.0557C3.13582 14.0926 3.02821 13.9167 3.12981 13.7872C3.51912 13.2957 3.91716 12.8092 4.32395 12.3277C4.52279 12.0918 4.47199 11.7507 4.21033 11.6099C3.67681 11.3232 3.136 11.0447 2.58792 10.7743C2.44371 10.7036 2.48522 10.4927 2.65183 10.4533C4.81321 9.94812 6.99666 9.57604 9.1928 9.33864L9.49489 12.9986Z"
        fill="#D73E16"
      />
      <path
        d="M7.35946 12.3412C8.0452 12.2389 8.73149 12.1509 9.41833 12.0771C9.44346 12.3841 9.46939 12.6911 9.49489 12.9986C8.79203 12.7654 8.0798 12.5464 7.35946 12.3412Z"
        fill="#B53419"
      />
      <path
        d="M20.5048 12.9986C22.5869 13.2238 24.6569 13.5766 26.706 14.0557C26.8634 14.0926 26.9715 13.9167 26.8699 13.7872C26.481 13.2957 26.0829 12.809 25.6758 12.3271C25.4769 12.0918 25.5283 11.7507 25.7894 11.6099C26.3229 11.3232 26.8637 11.0447 27.4118 10.7743C27.556 10.7036 27.5151 10.4927 27.3484 10.4533C25.187 9.94822 23.0036 9.57614 20.8075 9.33864L20.5048 12.9986Z"
        fill="#D73E16"
      />
      <path
        d="M9.39516 14.6001C2.75805 14.4061 2.6346 4.91189 9.25751 4.532C10.0463 4.53701 10.0594 5.88071 9.26898 5.90262C3.73641 6.32131 4.76121 13.9135 10.0501 13.1725C10.8417 13.0473 11.0301 14.3723 10.2397 14.5268C9.96021 14.5757 9.67789 14.6002 9.39516 14.6001Z"
        fill="#FEC611"
      />
      <path
        d="M20.9193 14.5832C20.8166 14.5911 20.7119 14.5967 20.6052 14.6001C20.3225 14.6003 20.0401 14.5756 19.7607 14.5262C18.9708 14.3723 19.1598 13.0448 19.9502 13.1725C20.5097 13.2579 21.0777 13.2382 21.6316 13.1143C24.5705 12.4259 25.349 8.62945 23.2158 6.83013C22.6209 6.32945 21.7988 5.98335 20.7341 5.90262C19.9437 5.88071 19.9573 4.53701 20.7456 4.532C21.7485 4.58958 22.5969 4.85619 23.2912 5.268C27.1052 7.53108 26.2705 14.177 20.9193 14.5832Z"
        fill="#FEC611"
      />
      <path
        d="M22.6412 12.3402C21.9562 12.2388 21.2701 12.1508 20.5829 12.0761C20.5578 12.3832 20.5325 12.6907 20.507 12.9986C21.2102 12.7654 21.9216 12.5459 22.6412 12.3402Z"
        fill="#B53419"
      />
      <path
        d="M21.6316 13.1143C21.4153 13.615 21.1785 14.1044 20.9214 14.5829C20.8187 14.5909 20.714 14.5965 20.6073 14.5998C20.3246 14.6001 20.0423 14.5754 19.7628 14.526C18.9729 14.372 19.1619 13.0446 19.9524 13.1723C20.5112 13.2574 21.0784 13.2379 21.6316 13.1143Z"
        fill="#FBBA00"
      />
      <path
        d="M23.2141 6.82819C22.6192 6.3275 21.797 5.98141 20.7324 5.90067C19.9419 5.87877 19.9556 4.53507 20.7438 4.53006C21.7468 4.58764 22.5951 4.85425 23.2894 5.26606C23.3046 5.7883 23.2794 6.31105 23.2141 6.82819Z"
        fill="#FBBA00"
      />
      <path
        d="M8.36787 13.1141C8.58637 13.6148 8.824 14.1044 9.08074 14.5829C9.18289 14.5917 9.28864 14.5969 9.39516 14.6001C9.67789 14.6002 9.96021 14.5757 10.2397 14.5268C11.029 14.3735 10.8411 13.0455 10.0501 13.1725C9.49063 13.2578 8.92186 13.238 8.36787 13.1141Z"
        fill="#FBBA00"
      />
      <path
        d="M6.78694 6.82819C7.38236 6.3275 8.20395 5.98141 9.26861 5.90067C10.0591 5.87939 10.0459 4.53569 9.25769 4.53006C8.2542 4.58764 7.4064 4.85425 6.7121 5.26669C6.69673 5.7887 6.72177 6.31124 6.78694 6.82819Z"
        fill="#FBBA00"
      />
      <path
        d="M22.7539 4.11567C22.5217 11.9939 19.1393 18.2449 15.0002 18.2449C13.4581 18.2449 12.0209 17.3768 10.8126 15.8804C8.99514 13.6274 7.69504 9.95549 7.33559 5.67404C7.29189 5.16293 7.26239 4.64347 7.2471 4.11567C7.24109 3.90414 7.41098 3.72952 7.62675 3.72952H22.3759C22.5878 3.72952 22.7599 3.90414 22.7539 4.11567Z"
        fill="#FFEB4A"
      />
      <path
        d="M22.7539 4.11567C22.5217 11.9939 19.1393 18.2449 15.0002 18.2449C13.4581 18.2449 12.0209 17.3768 10.8126 15.8804C11.39 16.1489 11.9565 16.3491 12.6732 16.3185C13.9788 16.2615 15.2647 15.65 16.2545 14.6812C17.8698 13.0997 18.7673 10.7478 19.2622 8.38016C19.3983 7.72927 19.4889 6.97512 19.1273 6.45004C18.7925 5.96437 18.1943 5.86048 17.6524 5.82418C15.8126 5.70903 9.93423 5.55632 7.33347 5.6721C7.28977 5.16099 7.26027 4.64153 7.24497 4.11373C7.23897 3.90219 7.40885 3.72758 7.62463 3.72758H22.3738C22.5879 3.72946 22.7599 3.90414 22.7539 4.11567Z"
        fill="#FAD337"
      />
      <path
        d="M22.9137 3.58705H7.08626C6.93933 3.58705 6.82023 3.72351 6.82023 3.89184V4.43571C6.82023 4.60404 6.93933 4.74049 7.08626 4.74049H22.9137C23.0606 4.74049 23.1798 4.60404 23.1798 4.43571V3.89184C23.1798 3.72351 23.0606 3.58705 22.9137 3.58705Z"
        fill="#FEC611"
      />
      <path
        d="M20.0065 22.4275V25.0561C20.0063 25.416 19.8814 25.7611 19.6592 26.0156C19.4371 26.27 19.1358 26.4129 18.8216 26.4129H11.181C11.1334 26.4131 11.0857 26.4098 11.0385 26.4029C10.7511 26.363 10.4865 26.204 10.2945 25.9559C10.1024 25.7078 9.99634 25.3877 9.99618 25.0561V22.4275C9.99618 22.0675 10.121 21.7222 10.3432 21.4676C10.5654 21.2131 10.8668 21.0701 11.181 21.0701H18.8216C19.1358 21.0702 19.4371 21.2133 19.6593 21.4678C19.8815 21.7224 20.0063 22.0675 20.0065 22.4275Z"
        fill="#544A3E"
      />
      <path
        d="M12.4955 20.4375C12.46 20.4376 12.4255 20.4249 12.3967 20.4012C12.368 20.3774 12.3466 20.3438 12.3355 20.3052C12.3245 20.2667 12.3244 20.2251 12.3353 20.1864C12.3461 20.1478 12.3674 20.1141 12.396 20.0902C12.8324 19.7248 13.1806 19.2383 13.4077 18.6764C13.4264 18.6301 13.4604 18.5941 13.5022 18.5765C13.544 18.5589 13.5902 18.5611 13.6306 18.5825C13.671 18.6039 13.7024 18.6428 13.7177 18.6907C13.7331 18.7386 13.7312 18.7915 13.7125 18.8378C13.4613 19.4593 13.0766 19.9977 12.5943 20.4025C12.5654 20.4258 12.5308 20.4381 12.4955 20.4375Z"
        fill="white"
      />
      <path
        d="M20.0065 22.4275V25.0561C20.0063 25.416 19.8814 25.7611 19.6592 26.0156C19.4371 26.27 19.1358 26.4129 18.8216 26.4129H11.181C11.1334 26.4131 11.0857 26.4098 11.0385 26.4029C11.4679 26.2915 11.8918 26.1533 12.3081 25.9891C13.1894 25.6251 14.0337 25.153 14.8264 24.5809C15.4098 24.1616 15.9904 23.6609 16.2767 22.9431C16.517 22.3404 16.4761 21.5518 16.109 21.0705L18.8216 21.0701C19.1358 21.0702 19.4371 21.2133 19.6593 21.4678C19.8815 21.7224 20.0063 22.0675 20.0065 22.4275Z"
        fill="#444135"
      />
      <path d="M18.378 22.4768H11.6245V25.0603H18.378V22.4768Z" fill="#FFEB4A" />
      <path
        d="M18.3771 22.4749V25.0584H14.9788C15.0061 25.0459 15.0334 25.0321 15.0591 25.0177C15.4819 24.776 15.845 24.4166 16.115 23.9723C16.385 23.528 16.5533 23.0131 16.6045 22.4749H18.3771Z"
        fill="#FCDC2E"
      />
      <path
        d="M14.2901 21.0701C13.728 22.8412 13.1839 24.628 12.6639 26.4123H11.8156C12.3329 24.6293 12.8737 22.845 13.4347 21.0701H14.2901Z"
        fill="white"
      />
      <path
        d="M16.1847 21.0701C15.6187 22.8393 15.0703 24.6268 14.5459 26.4123H13.3441C13.8647 24.628 14.4093 22.8412 14.9725 21.0701H16.1847Z"
        fill="white"
      />
      <path
        d="M5.5212 12.4995C5.50167 12.4994 5.48238 12.4945 5.46465 12.4851C5.44692 12.4757 5.43118 12.462 5.4185 12.445C4.83728 11.6596 4.52263 10.6169 4.5341 9.50914C4.54448 8.45834 4.85366 7.11714 5.50645 6.65526C5.52143 6.64461 5.5381 6.63745 5.5555 6.63421C5.5729 6.63096 5.5907 6.63168 5.60785 6.63633C5.62501 6.64098 5.6412 6.64947 5.65548 6.66132C5.66976 6.67316 5.68186 6.68811 5.69109 6.70533C5.70915 6.7402 5.71469 6.78175 5.70654 6.82121C5.69839 6.86067 5.67718 6.89497 5.64739 6.91687C5.17159 7.29676 4.81269 8.51279 4.80232 9.5129C4.79194 10.5462 5.08364 11.5156 5.62171 12.246C5.63766 12.2683 5.64777 12.2954 5.65088 12.3241C5.654 12.3527 5.64999 12.3818 5.63931 12.4079C5.62864 12.4341 5.61174 12.4563 5.59055 12.4719C5.56937 12.4875 5.54476 12.496 5.51956 12.4963L5.5212 12.4995Z"
        fill="white"
      />
      <path
        d="M7.30277 4.10197C7.26713 4.10197 7.23295 4.08575 7.20775 4.05687C7.18255 4.028 7.16839 3.98884 7.16839 3.94801C7.16839 3.90717 7.18255 3.86802 7.20775 3.83914C7.23295 3.81027 7.26713 3.79405 7.30277 3.79405L16.1124 3.7615C16.1465 3.764 16.1784 3.78127 16.2018 3.80981C16.2252 3.83835 16.2382 3.87602 16.2382 3.91515C16.2382 3.95429 16.2252 3.99195 16.2018 4.02049C16.1784 4.04903 16.1465 4.0663 16.1124 4.06879L7.30277 4.10197Z"
        fill="white"
      />
      <path
        d="M10.0535 7.26676C9.63011 7.81688 9.43018 8.56039 9.40996 9.29326C9.38975 10.0261 9.53779 10.7527 9.74155 11.4474C9.98791 12.288 10.3495 13.1372 10.998 13.6229C11.6338 14.0979 12.4466 14.1417 13.1978 14.0197C13.9194 13.902 14.6699 13.6091 15.1097 12.9426C15.592 12.2129 15.5926 11.2159 15.5287 10.3034C15.4686 9.4491 15.3582 8.55977 14.9278 7.85819C14.6 7.32371 14.1084 6.94069 13.5752 6.70224C12.4565 6.20281 10.922 6.14022 10.0535 7.26676Z"
        fill="#FFF377"
      />
      <path
        d="M22.6401 12.3398C17.568 11.5863 12.4322 11.5863 7.36003 12.3398C7.2231 11.1248 7.08563 9.90961 6.94761 8.69421C12.2937 7.89938 17.707 7.89938 23.0531 8.69421L22.6401 12.3398Z"
        fill="#F34522"
      />
    </svg>
  );
};

export const StarIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9961 1.24355L16.5408 4.37331C17.2366 5.78322 18.5817 6.76054 20.1375 6.98656L23.5915 7.48838C25.4174 7.75388 26.1464 9.99768 24.8252 11.2855L22.326 13.7218C21.2001 14.8191 20.6862 16.4002 20.9521 17.95L21.5421 21.3899C21.8539 23.2085 19.9452 24.5951 18.3122 23.7367L15.2229 22.1124C13.8313 21.3809 12.1686 21.3809 10.777 22.1124L7.68771 23.7367C6.05464 24.5951 4.14596 23.2085 4.45781 21.3899L5.04775 17.95C5.31345 16.4002 4.7998 14.8193 3.67389 13.7218L1.1747 11.2855C-0.146513 9.99768 0.582474 7.75369 2.40837 7.48838L5.86219 6.98656C7.4182 6.76054 8.76326 5.78322 9.45906 4.37331L11.0036 1.24355C11.8202 -0.41088 14.1795 -0.41088 14.9961 1.24355Z"
        fill="#FF83B4"
      />
      <path
        d="M14.9961 1.24355C14.1795 -0.41088 11.8202 -0.41088 11.0036 1.24355L9.45906 4.37331C8.76326 5.78322 7.4182 6.76054 5.86219 6.98656L2.40837 7.48838C0.582474 7.75369 -0.146513 9.99768 1.1747 11.2855L3.67389 13.7218C4.7998 14.8193 5.31345 16.4002 5.04775 17.95L4.45781 21.3899C4.14596 23.2085 6.05464 24.5951 7.68771 23.7367L9.24958 22.9155C9.64668 19.703 9.49562 16.3667 9.26312 13.911C9.05846 11.75 9.76856 9.59409 11.2479 8.00546C14.2615 4.76927 14.9961 1.24355 14.9961 1.24355Z"
        fill="#FF5D9C"
      />
      <path
        d="M10.6377 9.61563C10.2414 9.48707 9.81583 9.70451 9.68747 10.1009L8.5278 13.6813L7.36795 10.1009C7.23958 9.70451 6.81425 9.48707 6.41771 9.61563C6.02136 9.74399 5.80393 10.1695 5.93248 10.5659L7.80988 16.3625C7.91078 16.6738 8.20069 16.8845 8.5278 16.8845C8.85491 16.8845 9.14464 16.6738 9.24554 16.3625L11.1231 10.5659C11.2515 10.1693 11.0342 9.74399 10.6377 9.61563Z"
        fill="#F9EE80"
      />
      <path
        d="M13 9.57881C12.5832 9.57881 12.2454 9.9166 12.2454 10.3334V16.13C12.2454 16.5467 12.5832 16.8845 13 16.8845C13.4167 16.8845 13.7545 16.5467 13.7545 16.13V10.3334C13.7545 9.9166 13.4167 9.57881 13 9.57881Z"
        fill="#F9EE80"
      />
      <path
        d="M17.878 9.57881H15.8905C15.474 9.57881 15.136 9.9166 15.136 10.3334V16.13C15.136 16.5467 15.474 16.8845 15.8905 16.8845C16.3073 16.8845 16.6451 16.5467 16.6451 16.13V14.7254H17.878C19.0687 14.7254 20.0375 13.7567 20.0375 12.5657V11.7385C20.0375 10.5475 19.0687 9.57881 17.878 9.57881ZM18.5286 12.5657C18.5286 12.9245 18.2366 13.2163 17.878 13.2163H16.6451V11.0879H17.878C18.2366 11.0879 18.5286 11.3797 18.5286 11.7385V12.5657Z"
        fill="#F9EE80"
      />
    </svg>
  );
};
