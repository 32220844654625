import React from 'react';
import { MessageType } from 'src/modules/messages/types';
import { Message } from 'src/modules/messages/components/Message';

interface NotificationsListProps {
  messages: MessageType[];
}

export const NotificationsList = ({ messages }: NotificationsListProps) => {
  return (
    <div className="flex flex-col gap-3 overflow-y-auto">
      {messages.map((message, index) => (
        <Message key={index} {...message} isReadIt={true} />
      ))}
    </div>
  );
};
