export const Wallet = () => {
  return (
    <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.5 5.05078V3.46875C30.5 1.65656 29.0309 0.1875 27.2188 0.1875H3.78125C1.96906 0.1875 0.5 1.65656 0.5 3.46875V5.05078C0.5 5.21256 0.631191 5.34375 0.792969 5.34375H30.207C30.3688 5.34375 30.5 5.21256 30.5 5.05078Z"
        fill="white"
      />
      <path
        d="M0.5 7.51172V17.5312C0.5 19.3434 1.96906 20.8125 3.78125 20.8125H27.2188C29.0309 20.8125 30.5 19.3434 30.5 17.5312V7.51172C30.5 7.34994 30.3688 7.21875 30.207 7.21875H0.792969C0.631191 7.21875 0.5 7.34994 0.5 7.51172ZM8 15.1875C8 15.7052 7.58023 16.125 7.0625 16.125H6.125C5.60727 16.125 5.1875 15.7052 5.1875 15.1875V14.25C5.1875 13.7323 5.60727 13.3125 6.125 13.3125H7.0625C7.58023 13.3125 8 13.7323 8 14.25V15.1875Z"
        fill="white"
      />
    </svg>
  );
};

export const Support = () => {
  return (
    <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8603 24.0458C18.5738 24.1806 18.255 24.2546 17.9192 24.2546H2.31465C1.08131 24.2546 0.0794678 23.2546 0.0794678 22.0194C0.0794678 19.4616 1.11737 17.1468 2.7928 15.4694C4.46824 13.7921 6.78501 12.7561 9.34275 12.7561H10.8911C11.8246 12.7561 12.7278 12.8946 13.5778 13.1508L13.5702 13.1641C13.3046 13.6251 13.2344 14.1621 13.371 14.6763C13.5095 15.1905 13.8397 15.6212 14.3007 15.8869L14.5133 16.0102C14.4886 16.2569 14.4753 16.5054 14.4753 16.754C14.4753 17.0026 14.4886 17.2511 14.5133 17.4978L14.3007 17.6211C13.8397 17.8868 13.5095 18.3175 13.371 18.8317C13.2344 19.3459 13.3046 19.8829 13.5702 20.3439L14.6119 22.1465C14.9667 22.7613 15.629 23.1446 16.3386 23.1446C16.6877 23.1446 17.0312 23.0516 17.3348 22.877L17.5473 22.7537C17.9514 23.044 18.384 23.2945 18.8375 23.4975V23.7442C18.8375 23.8466 18.8451 23.9472 18.8603 24.0458Z"
        fill="white"
      />
      <path
        d="M10.1169 10.7828C12.8886 10.7828 15.1356 8.53584 15.1356 5.76408C15.1356 2.99232 12.8886 0.745361 10.1169 0.745361C7.3451 0.745361 5.09814 2.99232 5.09814 5.76408C5.09814 8.53584 7.3451 10.7828 10.1169 10.7828Z"
        fill="white"
      />
      <path
        d="M28.6833 18.9361L27.5467 18.2796C27.6776 17.792 27.7478 17.2815 27.7478 16.7541C27.7478 16.2266 27.6776 15.7143 27.5467 15.2285L28.6833 14.572C28.911 14.4411 28.9888 14.1508 28.8578 13.9231L27.8162 12.1205C27.6852 11.8928 27.3949 11.815 27.1672 11.946L26.0307 12.6025C25.3096 11.8814 24.4027 11.3464 23.3875 11.075V9.76391C23.3875 9.50206 23.175 9.28955 22.9132 9.28955H20.8317C20.5698 9.28955 20.3573 9.50206 20.3573 9.76391V11.075C19.3422 11.3445 18.4352 11.8796 17.7142 12.6025L16.5776 11.946C16.3499 11.815 16.0596 11.8928 15.9287 12.1205L14.887 13.9231C14.7561 14.1508 14.8339 14.4411 15.0616 14.572L16.1982 15.2285C16.0672 15.7162 15.997 16.2266 15.997 16.7541C15.997 17.2815 16.0672 17.7939 16.1982 18.2796L15.0616 18.9361C14.8339 19.067 14.7561 19.3573 14.887 19.585L15.9287 21.3876C16.0596 21.6153 16.3499 21.6931 16.5776 21.5622L17.7142 20.9057C18.4352 21.6267 19.3422 22.1618 20.3573 22.4331V23.7442C20.3573 24.0061 20.5698 24.2186 20.8317 24.2186H22.9132C23.175 24.2186 23.3875 24.0061 23.3875 23.7442V22.4331C24.4027 22.1637 25.3096 21.6286 26.0307 20.9057L27.1672 21.5622C27.3949 21.6931 27.6852 21.6153 27.8162 21.3876L28.8578 19.585C28.9888 19.3573 28.911 19.067 28.6833 18.9361ZM21.8715 19.1505C20.5471 19.1505 19.475 18.0766 19.475 16.7541C19.475 15.4297 20.549 14.3576 21.8715 14.3576C23.194 14.3576 24.2679 15.4315 24.2679 16.7541C24.2679 18.0766 23.1959 19.1505 21.8715 19.1505Z"
        fill="white"
      />
    </svg>
  );
};

export const Games = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0555 0.5H2.94446C1.59387 0.5 0.5 1.59387 0.5 2.94446V20.0556C0.5 21.4061 1.59387 22.5 2.94446 22.5H20.0556C21.4061 22.5 22.5 21.4061 22.5 20.0555V2.94446C22.5 1.59387 21.4061 0.5 20.0555 0.5ZM6 18.8333C4.98554 18.8333 4.16667 18.0145 4.16667 17C4.16667 15.9855 4.98554 15.1667 6 15.1667C7.01446 15.1667 7.83333 15.9855 7.83333 17C7.83333 18.0145 7.01446 18.8333 6 18.8333ZM6 7.83333C4.98554 7.83333 4.16667 7.01446 4.16667 6C4.16667 4.98554 4.98554 4.16667 6 4.16667C7.01446 4.16667 7.83333 4.98554 7.83333 6C7.83333 7.01446 7.01446 7.83333 6 7.83333ZM11.5 13.3333C10.4855 13.3333 9.66667 12.5145 9.66667 11.5C9.66667 10.4855 10.4855 9.66667 11.5 9.66667C12.5145 9.66667 13.3333 10.4855 13.3333 11.5C13.3333 12.5145 12.5145 13.3333 11.5 13.3333ZM17 18.8333C15.9855 18.8333 15.1667 18.0145 15.1667 17C15.1667 15.9855 15.9855 15.1667 17 15.1667C18.0145 15.1667 18.8333 15.9855 18.8333 17C18.8333 18.0145 18.0145 18.8333 17 18.8333ZM17 7.83333C15.9855 7.83333 15.1667 7.01446 15.1667 6C15.1667 4.98554 15.9855 4.16667 17 4.16667C18.0145 4.16667 18.8333 4.98554 18.8333 6C18.8333 7.01446 18.0145 7.83333 17 7.83333Z"
        fill="white"
      />
    </svg>
  );
};

export const Security = () => {
  return (
    <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8393 26.1727C23.2399 21.7569 24.8125 13.9909 24.2605 3.9204C24.2367 3.48718 23.903 3.14299 23.4707 3.10588C20.847 2.88066 17.3991 2.10869 12.7363 0.790003C12.5754 0.744478 12.4263 0.744478 12.2654 0.790003C7.60263 2.10871 4.15472 2.88066 1.53097 3.1059C1.09876 3.14297 0.765036 3.48713 0.741253 3.92028C0.178202 14.1685 1.8023 21.7768 12.1625 26.1727C12.3869 26.268 12.6149 26.268 12.8393 26.1727ZM8.46659 11.475H8.81119V9.03222C8.81119 7.00084 10.4683 5.34378 12.4996 5.34378C14.531 5.34378 16.1881 7.00084 16.1881 9.03222V11.475H16.5327C16.8929 11.475 17.1876 11.7698 17.1876 12.13V18.6792C17.1876 19.0394 16.8929 19.3341 16.5327 19.3341H8.46659C8.10636 19.3341 7.81167 19.0394 7.81167 18.6792V12.13C7.81167 11.7698 8.10636 11.475 8.46659 11.475ZM10.2084 11.475H14.7909V9.03218C14.7909 7.77203 13.7598 6.74096 12.4997 6.74096C11.2395 6.74096 10.2084 7.77198 10.2084 9.03218V11.475ZM12.4996 13.0261C13.2357 13.0261 13.8325 13.6229 13.8325 14.359C13.8325 14.8133 13.6051 15.2145 13.258 15.4551V17.0247C13.258 17.4418 12.9167 17.783 12.4996 17.783C12.0826 17.783 11.7413 17.4418 11.7413 17.0247V15.4551C11.3942 15.2145 11.1668 14.8133 11.1668 14.359C11.1668 13.6229 11.7635 13.0261 12.4996 13.0261Z"
        fill="white"
      />
    </svg>
  );
};
