export const FooterWave = () => {
  return (
    <svg
      id="wave"
      style={{ transform: 'rotate(0deg)', transition: '0.3s' }}
      viewBox="0 0 1440 290"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stopColor="rgba(60, 50, 111, 1)" offset="0%" />
          <stop stopColor="rgba(60, 50, 111, 1)" offset="100%" />
        </linearGradient>
      </defs>
      <path
        style={{ transform: 'translate(0, 0px)', opacity: 1 }}
        fill="url(#sw-gradient-0)"
        d="M0,58L34.3,62.8C68.6,68,137,77,206,101.5C274.3,126,343,164,411,149.8C480,135,549,68,617,62.8C685.7,58,754,116,823,120.8C891.4,126,960,77,1029,87C1097.1,97,1166,164,1234,164.3C1302.9,164,1371,97,1440,62.8C1508.6,29,1577,29,1646,58C1714.3,87,1783,145,1851,154.7C1920,164,1989,126,2057,120.8C2125.7,116,2194,145,2263,135.3C2331.4,126,2400,77,2469,67.7C2537.1,58,2606,87,2674,96.7C2742.9,106,2811,97,2880,87C2948.6,77,3017,68,3086,58C3154.3,48,3223,39,3291,58C3360,77,3429,126,3497,145C3565.7,164,3634,155,3703,140.2C3771.4,126,3840,106,3909,82.2C3977.1,58,4046,29,4114,58C4182.9,87,4251,174,4320,217.5C4388.6,261,4457,261,4526,236.8C4594.3,213,4663,164,4731,130.5C4800,97,4869,77,4903,67.7L4937.1,58L4937.1,290L4902.9,290C4868.6,290,4800,290,4731,290C4662.9,290,4594,290,4526,290C4457.1,290,4389,290,4320,290C4251.4,290,4183,290,4114,290C4045.7,290,3977,290,3909,290C3840,290,3771,290,3703,290C3634.3,290,3566,290,3497,290C3428.6,290,3360,290,3291,290C3222.9,290,3154,290,3086,290C3017.1,290,2949,290,2880,290C2811.4,290,2743,290,2674,290C2605.7,290,2537,290,2469,290C2400,290,2331,290,2263,290C2194.3,290,2126,290,2057,290C1988.6,290,1920,290,1851,290C1782.9,290,1714,290,1646,290C1577.1,290,1509,290,1440,290C1371.4,290,1303,290,1234,290C1165.7,290,1097,290,1029,290C960,290,891,290,823,290C754.3,290,686,290,617,290C548.6,290,480,290,411,290C342.9,290,274,290,206,290C137.1,290,69,290,34,290L0,290Z"
      />
    </svg>
  );
};
