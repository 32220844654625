export const LiveIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278228)">
      <path
        d="M23.2462 10.593C22.833 9.83735 22.2217 9.26216 21.6823 8.75461C21.5966 8.67399 21.5117 8.59408 21.4288 8.51455C21.1027 8.20145 20.7766 7.90319 20.4612 7.61493C20.1424 7.32339 19.8413 7.04809 19.536 6.75499L18.7213 5.97278L17.9067 6.75507C17.6013 7.04825 17.3 7.32378 16.9811 7.6154C16.6659 7.90366 16.34 8.20161 16.014 8.51455C15.9311 8.59423 15.8461 8.67407 15.7604 8.75469C15.2211 9.26216 14.6098 9.83735 14.1966 10.5929L14.1967 10.5928C13.598 11.6875 13.6192 12.9594 14.2532 13.9951C14.877 15.0139 15.8499 15.5797 16.9924 15.5882C17.001 15.5882 17.0096 15.5883 17.0182 15.5883C17.1951 15.5883 17.3711 15.5729 17.5452 15.5459V16.2655H19.8977V15.5452C20.0803 15.5736 20.2649 15.5892 20.4503 15.5882C21.593 15.5797 22.5659 15.0139 23.1897 13.995C23.8238 12.9594 23.8448 11.6875 23.2462 10.593ZM21.1834 12.7667C20.9836 13.093 20.7592 13.2333 20.4328 13.2356C20.2143 13.2386 19.9998 13.173 19.8014 13.0477L18.7215 12.3712L17.6385 13.0496C17.4454 13.1715 17.2311 13.2358 17.0179 13.2358C17.0152 13.2358 17.0126 13.2358 17.0099 13.2357C16.6838 13.2334 16.4593 13.0931 16.2595 12.7668C16.0105 12.3601 16.1226 11.974 16.2606 11.7218L16.2607 11.7217C16.4943 11.2944 16.9408 10.8743 17.3725 10.4681C17.4642 10.3819 17.5549 10.2965 17.6434 10.2115C17.9486 9.91844 18.2497 9.64315 18.5685 9.3516C18.6192 9.30528 18.6702 9.25864 18.7215 9.21177C18.7725 9.25848 18.8234 9.30489 18.8739 9.35113C19.1929 9.64275 19.4942 9.91828 19.7995 10.2114C19.888 10.2964 19.9787 10.3818 20.0703 10.4679C20.5021 10.8742 20.9486 11.2943 21.1823 11.7217C21.3203 11.9739 21.4325 12.36 21.1834 12.7667Z"
        fill="white"
      />
      <path
        d="M34.997 34.0908V23.8107C34.997 21.8615 34.168 19.9936 32.7225 18.686L26.9318 13.4472V0.663452H10.5214V4.2632L4.513 5.33898L8.68471 28.6355L17.5502 26.9949V29.2247C17.5485 29.503 17.5923 32.434 20.1006 34.0368V34.0907H18.1042V40.6606H36.8039V34.0908H34.997ZM10.5834 25.8917L7.24334 7.24002L10.5214 6.65318V24.3236H19.057L10.5834 25.8917ZM12.8738 21.971V3.01594H24.5792V17.6865L22.4063 15.574L21.593 16.2484C21.4029 16.406 19.7304 17.8507 19.5547 19.9972C19.499 20.6763 19.5994 21.3358 19.851 21.971H12.8738ZM19.9026 29.2491L19.9027 26.5595L22.9549 25.9947L22.9258 25.8375C23.2891 26.2095 23.5902 26.5015 23.8324 26.7263C23.3019 27.9534 22.76 30.1918 24.3406 32.4971L26.2809 31.1666C24.9297 29.1962 26.1798 27.2602 26.2731 27.1212L27.0087 26.1562L25.9856 25.4811C25.9028 25.4187 24.8981 24.6417 22.6075 22.004C22.084 21.4012 21.8521 20.8115 21.8985 20.2011C21.9374 19.6894 22.1629 19.2228 22.4079 18.8565L29.956 26.195L31.5958 24.5082L26.9318 19.9737V16.6195L31.1444 20.4306C32.0977 21.293 32.6446 22.525 32.6446 23.8107V34.0908H22.4531V32.5876L21.7684 32.2726C19.9539 31.4379 19.9037 29.411 19.9026 29.2491ZM20.4567 38.3081V36.4433H34.4514V38.3081H20.4567Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278228">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.658203 0.65918)"
        />
      </clipPath>
    </defs>
  </svg>
);
