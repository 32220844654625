import { PaymentMethod, makeFloat } from 'react-easyrocket';

const getLimit = ({
  limit,
  globalLimit,
  paymentLimit
}: {
  limit: number;
  globalLimit: number;
  paymentLimit: number;
}) => {
  return Math[limit === 0 ? 'max' : 'min'](
    globalLimit <= 0 ? limit : globalLimit,
    paymentLimit <= 0 ? limit : paymentLimit
  );
};

export const getCurrentValueWithinLimits = ({
  paymentMethod,
  globalMin,
  globalMax,
  currentValue,
  amountPresets
}: {
  paymentMethod?: PaymentMethod;
  globalMin?: number;
  globalMax?: number;
  currentValue: string | number;
  amountPresets: string[];
}): number => {
  const min = getLimit({
    limit: 0,
    globalLimit: globalMin ? Number(makeFloat(globalMin, 0)) : 0,
    paymentLimit: paymentMethod?.min_amount ? Number(paymentMethod?.min_amount) : 0
  });
  const max = getLimit({
    limit: Infinity,
    globalLimit: globalMax ? Number(makeFloat(globalMax)) : 0,
    paymentLimit: paymentMethod?.max_amount ? Number(paymentMethod?.max_amount) : 0
  });
  const value = Number(currentValue);
  if (value < min) return Number(amountPresets[1]);
  if (value > max) return max;
  return value;
};

export const findClosestNumber = (array: Array<number>, target: number) => {
  if (!array.length) return target;
  const sortedArray = array.sort((a, b) => a - b);
  const largesOptionNumber = sortedArray[sortedArray.length - 1];

  if (target >= largesOptionNumber) return largesOptionNumber;

  return sortedArray.find((el) => el >= target);
};
