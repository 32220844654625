import { FC } from 'react';
import { Skeletons } from 'src/components/Skeletons';

interface TournamentDescriptionProps {
  content: string;
  isLoading: boolean;
}

export const TournamentDescription: FC<TournamentDescriptionProps> = ({ content, isLoading }) => {
  return (
    <div className="px-[15px] mb-[30px] max-w-[700px] w-full">
      {isLoading ? (
        <Skeletons count={2} className="w-full h-5 first:mb-1" />
      ) : (
        <div
          className="static-page cms-dropdown
              [&>*]:text-[18px]
              [&>*]:leading-[27px]
              text-tournament-description
              [&_strong]:text-yellow
              "
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      )}
    </div>
  );
};
