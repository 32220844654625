import { MutableRefObject, useEffect, useRef } from 'react';
import { upToTop } from 'src/utils';

export const useScrollToTopOnMount: (
  dependencies?: any[],
  behaviorSmooth?: boolean,
  condition?: boolean
) => MutableRefObject<any> = (dependencies = [], behaviorSmooth, condition) => {
  const pageRef = useRef<any>();

  useEffect(() => {
    if (condition === undefined || (condition !== undefined && condition)) {
      upToTop({ behaviorSmooth, pageRef });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return pageRef;
};
