import { FC, useEffect } from 'react';
import { useApiContext, useGetWheelOfFortuneState } from 'react-easyrocket';
import { GPTWheelOfFortuneButtonAuth } from './components/GPTWheelOfFortuneButtonAuth';
import { GPTWheelOfFortuneButtonNonAuth } from './components/GPTWheelOfFortuneButtonNonAuth';
import { useLocation } from 'react-router-dom';
import { getQueryStatus } from './utils/getQueryStatus';
import { Skeletons } from 'src/components/Skeletons';
import { twJoin } from 'tailwind-merge';
import { SidebarTooltip, Tooltips } from 'src/components/Sidebar/components/SidebarTooltip';
import { useTranslation } from 'react-i18next';

export interface GPTWheelOfFortuneButtonProps {
  compact?: boolean;
  handleActiveTooltip?: (value: Tooltips) => void;
  clearTooltipState?: () => void;
  activeTooltip?: Tooltips;
}

export const GPTWheelOfFortuneButton: FC<GPTWheelOfFortuneButtonProps> = ({
  compact,
  handleActiveTooltip,
  clearTooltipState,
  activeTooltip
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApiContext();
  const { pathname } = useLocation();
  const { isLoading, data, error, refetch } = useGetWheelOfFortuneState({});

  useEffect(() => {
    refetch();
  }, [isAuthenticated, pathname, refetch]);

  if (isLoading) {
    return (
      <div
        className={twJoin(
          'flex gap-2 w-full items-center h-[27px]',
          compact ? 'justify-center' : 'justify-start '
        )}>
        <Skeletons className="w-6 h-6 rounded" />
        {!compact && <Skeletons className="min-w-[112.55px] h-[22px] rounded" />}
      </div>
    );
  }

  const wheelRequestStatus = getQueryStatus(data, error);

  const shouldRenderButton = !isLoading && wheelRequestStatus === 204;

  if (!shouldRenderButton) return null;

  return compact ? (
    <SidebarTooltip
      id="wheel-of-fortune-icon"
      mouseEnterFunction={() => handleActiveTooltip?.(Tooltips.WHEEL_OF_FORTUNE)}
      mouseLeaveFunction={() => clearTooltipState?.()}
      showTooltip={activeTooltip === Tooltips.WHEEL_OF_FORTUNE}
      tooltipContent={t('mainSidebarLinks.wheelOfFortune')}>
      {isAuthenticated ? (
        <GPTWheelOfFortuneButtonAuth compact={compact} />
      ) : (
        <GPTWheelOfFortuneButtonNonAuth compact={compact} />
      )}
    </SidebarTooltip>
  ) : isAuthenticated ? (
    <GPTWheelOfFortuneButtonAuth compact={compact} />
  ) : (
    <GPTWheelOfFortuneButtonNonAuth compact={compact} />
  );
};
