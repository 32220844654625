import {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
  useCallback,
  RefObject
} from 'react';

export enum PROGRESSIVE_TOOLTIP_TYPE {
  CASINO = 'gameCategories.casino',
  LIVE = 'general.allLiveGames',
  JACKPOTS = 'general.jackpotGames'
}

export const TooltipContext = createContext<{
  controllerRef?: RefObject<HTMLDivElement>;
  coordinates?: number;
  openedTooltip?: PROGRESSIVE_TOOLTIP_TYPE;
  openTooltip: (
    tooltipType?: PROGRESSIVE_TOOLTIP_TYPE,
    controllerRef?: RefObject<HTMLDivElement>
  ) => void;
  closeTooltips: () => void;
}>({
  controllerRef: undefined,
  coordinates: undefined,
  openedTooltip: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openTooltip: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeTooltips: () => {}
});

export const useInitTooltips = () => {
  const [openedTooltip, setOpenedTooltip] = useState<PROGRESSIVE_TOOLTIP_TYPE | undefined>(
    undefined
  );
  const [coordinates, setCoordinates] = useState<number | undefined>(undefined);
  const [controllerRef, setControllerRef] = useState<RefObject<HTMLDivElement> | undefined>(
    undefined
  );

  const getСoordinates = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return ref.current.getBoundingClientRect();
    }
  };

  const openTooltip = useCallback(
    (tooltipType?: PROGRESSIVE_TOOLTIP_TYPE, controllerRef?: RefObject<HTMLDivElement>) => {
      setOpenedTooltip(tooltipType);
      setCoordinates(getСoordinates(controllerRef as RefObject<HTMLDivElement>)?.y);
      setControllerRef(controllerRef);
    },
    []
  );

  const closeTooltips = useCallback(() => {
    setOpenedTooltip(undefined);
    setCoordinates(undefined);
    setControllerRef(undefined);
  }, []);

  return { openTooltip, openedTooltip, closeTooltips, coordinates, controllerRef };
};

export const TooltipProvider = ({ children }: PropsWithChildren) => {
  const values = useInitTooltips();
  return <TooltipContext.Provider value={values}>{children}</TooltipContext.Provider>;
};

export const useTooltip = () => {
  return useContext(TooltipContext);
};
