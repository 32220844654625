import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GameData } from 'react-easyrocket';
import { GamesGrid } from 'src/components/GamesGrid/GamesGrid';

interface GamesSearchResultsProps {
  games: GameData[];
  isFetching: boolean;
  image?: string;
  isFetched: boolean;
  onPlayClick?: () => void;
}

export const GamesSearchResults: FC<GamesSearchResultsProps> = ({
  games,
  isFetching,
  image,
  isFetched,
  onPlayClick
}) => {
  const { t } = useTranslation();

  const isNoGames = isFetched && !isFetching && !games.length;

  if (isNoGames) {
    return (
      <div className="w-full my-[30px] flex flex-col justify-center items-center">
        <p className="text-gcp_games_search_results_text text-center font-[400] text-[20px]">
          {t('gamesPage.noGamesInCategory')}
        </p>
        <img src={image} className="w-auto" alt="no games" />
      </div>
    );
  }

  return (
    <GamesGrid
      isFetched={isFetched}
      isFetching={isFetching}
      onGameClick={onPlayClick}
      games={games}
    />
  );
};
