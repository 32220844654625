import { useEffect, useMemo, useState } from 'react';
import { StepOne } from './components/StepOne';
import { StepThree } from './components/StepThree';
import { StepTwo } from './components/StepTwo';
import gift from 'src/assets/images/gift.webp';
import { StepProps, StepsOption } from 'src/types';
import classNames from 'classnames';
import { useApiContext, useSignUpModal } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from 'src/components/ModalWrapper';
import { resetDepositModalShow } from 'src/hooks/useOpenDepositOnSessionStart';
import { AuthTabs } from '../components/AuthTabs';
import { twJoin } from 'tailwind-merge';
import { AuthModalContainer } from '../components/ModalContainer';
import { useTranslation } from 'react-i18next';

export type SignUpModalProps = {
  initialStep: number | undefined;
  hideWhyNot?: boolean;
  onSignupSuccess?: () => void;
  isSuccessfullNavigateDisabled?: boolean;
};

export const SignUpModal = () => {
  const { isLongModal } = useSignUpModal();
  const { modalProps, openModal } = useModalContext<SignUpModalProps>();
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(modalProps?.initialStep || 0);
  const [availableSteps, setAvailableSteps] = useState<boolean[]>([
    false,
    modalProps?.initialStep === 1,
    false
  ]);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([false, false, false]);

  const steps: StepsOption[] = useMemo(
    () => [
      {
        title: 'First',
        children: ({ changeStep, completedSteps }: StepProps) => (
          <StepOne
            isCompleted={completedSteps[0]}
            onRegistrationSuccess={() => {
              changeStep(1);
            }}
            changeStep={changeStep}
            disableFields={completedSteps[0]}
          />
        )
      },
      {
        title: 'Second',
        children: ({ changeStep }: StepProps) => <StepTwo changeStep={changeStep} />
      },
      {
        title: 'Last',
        children: ({ completedSteps }: StepProps) => <StepThree disableFields={completedSteps[2]} />
      }
    ],
    []
  );

  const handleChangeStep = (newStep: number) => {
    setCurrentStep(newStep);

    const nextAvailableStepsState = [...availableSteps];
    const nextCompletedStepsState = [...completedSteps];

    // available
    nextAvailableStepsState[newStep] = true;

    // completed
    if (newStep !== 0) {
      nextCompletedStepsState[newStep - 1] = true;
    }

    setAvailableSteps(nextAvailableStepsState);
    setCompletedSteps(nextCompletedStepsState);
  };

  useEffect(() => {
    if (isAuthenticated && !completedSteps[0]) {
      const nextCompletedStepsState = [...completedSteps];
      nextCompletedStepsState[0] = true;
      setCompletedSteps(nextCompletedStepsState);
    }
  }, [completedSteps, isAuthenticated]);

  const StepComponent = steps[currentStep].children;

  return (
    <ModalWrapper
      onClose={() => {
        if (!isAuthenticated && !modalProps?.hideWhyNot) {
          openModal('BACK_TO_SIGNUP');
        }
        if (isAuthenticated && modalProps?.onSignupSuccess) {
          modalProps.onSignupSuccess();
        }
        if (isAuthenticated) {
          resetDepositModalShow();
        }
      }}>
      <AuthModalContainer className="flex flex-col flex-1">
        <div className="mb-4">
          {!currentStep ? (
            <AuthTabs isSignUpActive />
          ) : (
            <p className="text-[24px] uppercase font-bold text-white text-center tracking-[0.72px] leading-[110%]">
              {t('general.signUp')}
            </p>
          )}
          {isLongModal && (
            <div className="flex justify-center text-white mt-4 items-center gap-[18px]">
              {steps.map((item, index) => {
                return (
                  <button
                    key={item.title}
                    className={classNames(
                      availableSteps[index]
                        ? 'cursor-pointer text-button-primary'
                        : 'cursor-no-drop',
                      completedSteps[index] &&
                        'bg-AUTH_MODAL_COMPLETED_STEP_BG text-popup-background',
                      index === currentStep && 'text-button-primary',
                      'flex relative text-xs font-bold text-center justify-center items-center min-h-[30px] h-full max-w-[30px] w-full rounded-full border-border-step border'
                    )}
                    onClick={() => availableSteps[index] && handleChangeStep(index)}>
                    {index + 1}
                    <span
                      className={twJoin(
                        'flex absolute translate-x-full -right-1 top-1/2 -translate-y-[0.5px] gap-0.5',
                        index === currentStep ? '[&>span]:bg-yellow' : '[&>span]:bg-white'
                      )}>
                      <span className="w-[1px] h-[1px]" />
                      <span className="w-0.5 h-[1px]" />
                      <span className="w-0.5 h-[1px]" />
                      <span className="w-[1px] h-[1px]" />
                    </span>
                  </button>
                );
              })}
              <div className="flex justify-center items-center min-h-[30px] h-full max-w-[30px] w-full rounded-full border-border-step border">
                <img src={gift} alt="gift" className="w-[15px]" />
              </div>
            </div>
          )}
        </div>
        <StepComponent
          changeStep={handleChangeStep}
          availableSteps={availableSteps}
          completedSteps={completedSteps}
        />
      </AuthModalContainer>
    </ModalWrapper>
  );
};
