import { useEffect, useState } from 'react';
import { TOURNAMENT_COMPLETE_LAG_MS } from './useTournamentWin';

type UseCountdownTimer = (dateEnd: number | undefined) => {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

export const useCountdownTimer: UseCountdownTimer = (dateEnd) => {
  const INTERVAL_TIME = 1000;
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [dateEnd]);

  function getTimeLeft() {
    if (!dateEnd) {
      return {
        days: '--',
        hours: '--',
        minutes: '--',
        seconds: '--'
      };
    }

    const now = new Date().getTime();
    const difference = (dateEnd * 1000 - now + TOURNAMENT_COMPLETE_LAG_MS) / 1000;

    if (difference <= 0) {
      return {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      };
    }

    const days = String(Math.floor(difference / (60 * 60 * 24))).padStart(2, '0');
    const hours = String(Math.floor((difference / (60 * 60)) % 24)).padStart(2, '0');
    const minutes = String(Math.floor((difference / 60) % 60)).padStart(2, '0');
    const seconds = String(Math.floor(difference % 60)).padStart(2, '0');

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  return timeLeft;
};
