import { FullInfoResponse, GameData } from 'react-easyrocket';

export const getAccountInfoErrorMessage = (
  providerId: GameData['providerId'],
  fullInfo: FullInfoResponse
) => {
  if (providerId === 27 && !fullInfo?.birthday) {
    return 'general.nonFilledBirth';
  }
  if (providerId === 101 && !fullInfo?.birthday && !fullInfo?.sex) {
    return 'general.nonFilledBirth';
  }

  return;
};
