import { FullBalanceResponse, GameData } from 'react-easyrocket';
import { GAME_CATEGORIES } from 'src/constants/gameCategories';

export const checkGamesFromFullBalance = ({
  categoryId,
  fullBalance
}: {
  categoryId: string | undefined;
  fullBalance: FullBalanceResponse;
}) => {
  const isBonusGamesCategory = categoryId === GAME_CATEGORIES['bonus-games'].param;
  const isFromWageringGames =
    fullBalance?.bonusAccounts?.casino?.wager?.bonus?.wageringGames?.length;

  return Boolean(isBonusGamesCategory && isFromWageringGames);
};

export const getGamesToRender = ({
  games,
  areGamesFromFullBalance
}: {
  games: GameData[];
  areGamesFromFullBalance: boolean;
}) => {
  if (areGamesFromFullBalance) {
    return games.map((el) => ({ ...el, isWagering: true }));
  }
  return games;
};
