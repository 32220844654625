import { Dispatch, FC, SetStateAction } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, EffectCoverflow, Autoplay, Navigation } from 'swiper';
import { AutoplayOptions } from 'swiper/types/modules/autoplay';
import { twJoin } from 'tailwind-merge';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';

type CarouselProps = {
  slides: JSX.Element[] | undefined;
  autoplay?: boolean | AutoplayOptions;
  interval?: number;
  arrows?: boolean;
  onSlideChange?: (arg: any) => void;
  effect?: 'cards' | 'coverflow';
  slideClassname?: string;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  inactiveSlideClassname?: string;
  initialSlide?: number;
  loop?: boolean;
  wrapperClassname?: string;
};

export const Carousel: FC<CarouselProps> = ({
  slides,
  arrows = true,
  autoplay = false,
  effect = 'coverflow',
  inactiveSlideClassname,
  setActiveIndex,
  initialSlide,
  loop = false,
  slideClassname = 'w-2/5 768:w-4/5 1024:w-2/4 1280:w-[30%]',
  wrapperClassname
}) => {
  return (
    <Swiper
      loop={loop}
      initialSlide={initialSlide}
      navigation={arrows}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
        scale: 0.5
      }}
      cardsEffect={{
        slideShadows: false,
        perSlideOffset: 40
      }}
      className={twJoin('w-full', wrapperClassname)}
      autoplay={autoplay}
      slidesPerView={'auto'}
      modules={[Navigation, EffectCards, EffectCoverflow, Autoplay]}
      centeredSlides={true}
      rewind={true}
      effect={effect}
      onSlideChange={(slide) => {
        setActiveIndex?.(slide.activeIndex);
      }}>
      {slides
        ? slides.map((slide, index) => (
            <SwiperSlide key={index} className={slideClassname}>
              {({ isActive }) => {
                if (effect !== 'coverflow') {
                  return <div className={twJoin(!isActive && inactiveSlideClassname)}>{slide}</div>;
                }
                return (
                  <div
                    className={twJoin(
                      !isActive && 'opacity-40',
                      !isActive && inactiveSlideClassname
                    )}>
                    {slide}
                  </div>
                );
              }}
            </SwiperSlide>
          ))
        : null}
    </Swiper>
  );
};
