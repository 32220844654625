import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { Notification } from 'src/pages/Game/hooks/useNotification';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const GameNotificationModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModalContext<{ notificationData: Notification[] }>();

  return (
    <ModalWrapper>
      <div className="flex flex-col items-center p-[30px] max-w-[300px]">
        {modalProps.notificationData.map((item: Notification, index: number) => {
          return (
            <div key={index} className="mb-[10px]">
              <p className="font-bold text-base w-full text-center">{item.content}</p>
              {item.haveAdditionalContent && (
                <p className="text-xl font-bold text-[#AD31E0] w-full text-center">
                  {item.additionalContent}
                </p>
              )}
            </div>
          );
        })}
        <Button
          mode="button-secondary"
          className="min-w-[190px]"
          onClick={() => closeModal()}
          title={`${t('gameNotification.close')}`}>
          {t('gameNotification.close')}
        </Button>
      </div>
    </ModalWrapper>
  );
};
