import classNames from 'classnames';
import backgroundFreespins from 'src/assets/images/backgroundFreespins.webp';
import backgroundTimer from 'src/assets/images/backgroundTimer.webp';

export const DisplayOfFreespins = ({ spinCount }: { spinCount: number }) => {
  return (
    <div className="relative flex justify-center items-center mt-[35px] jgf:mt-[10px] jsm:mt-[10px] overflow-hidden">
      <img
        src={backgroundFreespins}
        className="w-[220px] jsm:w-[180px] jgf:w-[180px]"
        alt="freespins background"
      />
      <p
        className={classNames(
          'text-[white] text-[30px] jsm:text-[25px] jgf:text-[20px] absolute font-bold'
        )}>
        {`${spinCount} ${spinCount === 1 ? 'freespin' : 'freespins'}`}
      </p>
    </div>
  );
};

export const Timer = ({ timeLeft }: { timeLeft: number }) => {
  const getTimeRemaining = () => {
    const hours = Math.floor(timeLeft / 3600);
    const remains = timeLeft - hours * 3600;
    const minutes = Math.floor(remains / 60);
    const seconds = Math.floor(remains - minutes * 60);
    return {
      seconds,
      minutes,
      hours
    };
  };

  const { hours, minutes, seconds } = getTimeRemaining();

  return (
    <div className="flex flex-row mt-[35px] jgf:mt-[0px] gap-x-[13px] items-center justify-center">
      <Time time={hours < 10 ? `0${hours}` : hours} timeDescroprtion="hour" />
      <p className="text-[white] text-[26px] font-extrabold text-center leading-[110%]">:</p>
      <Time time={minutes < 10 ? `0${minutes}` : minutes} timeDescroprtion="min" />
      <p className="text-[white] text-[26px] font-extrabold text-center leading-[110%] items-center">
        :
      </p>
      <Time time={seconds < 10 ? `0${seconds}` : seconds} timeDescroprtion="sec" />
    </div>
  );
};

function Time({ time, timeDescroprtion }: { time: number | string; timeDescroprtion: string }) {
  return (
    <div className="relative flex justify-center items-center">
      <img
        src={backgroundTimer}
        className="w-[75px] jsm:w-[55px] jgf:w-[45px]"
        alt="timer background"
      />
      <div className="absolute flex flex-col items-center">
        <p className="text-[white] text-[15px] font-semibold jsm:text-[12px] jgf:text-[12px] pt-[7px]">
          {timeDescroprtion}
        </p>
        <p className="text-[white] text-[30px] font-bold jsm:text-[25px] jgf:text-[20px]">{time}</p>
      </div>
    </div>
  );
}
