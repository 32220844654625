import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface TableRowListProps {
  rowColor?: string;
}

export const TableRow: FC<PropsWithChildren<TableRowListProps>> = ({ children, rowColor }) => {
  return (
    <tr className={twMerge('odd:bg-background-main even:bg-table_row_bg_even', rowColor)}>
      {children}
    </tr>
  );
};
