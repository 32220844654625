import { useEffect } from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useApiContext, useGetFullInfo, useGetUserCountry } from 'react-easyrocket';
import { useNavigateLng } from 'src/lib';
import { useSetLanguageToUrl } from '../components/LanguagesSelect/hooks/useSetLanguageToUrl';
import { SUPPORTED_LANGUAGES } from '../locale/LanguagesAdjustable';

export const useSetUserLanguage = () => {
  const { isAuthenticated } = useApiContext();
  const { fullInfo, isFetching } = useGetFullInfo();
  const { userCountry, isGBR } = useGetUserCountry();
  const navigate = useNavigateLng();
  const { lang } = useParams();
  const setLanguageToUrl = useSetLanguageToUrl();
  const { pathname, search, hash, state } = useLocation();

  const {
    i18n: { changeLanguage, language }
  } = useTranslation();

  useEffect(() => {
    if (fullInfo?.language && userCountry?.country) {
      if (fullInfo?.language === SUPPORTED_LANGUAGES.gb && !isGBR) {
        changeLanguage(SUPPORTED_LANGUAGES.en).then(setLanguageToUrl);
        return;
      }

      const userCountryLanguage =
        isGBR && fullInfo?.language === SUPPORTED_LANGUAGES.en
          ? SUPPORTED_LANGUAGES.gb
          : fullInfo?.language;

      changeLanguage(userCountryLanguage).then(setLanguageToUrl);
    }
  }, [fullInfo?.language, userCountry?.country]);

  useEffect(() => {
    if (!isAuthenticated && userCountry?.country) {
      if (language === SUPPORTED_LANGUAGES.gb && !isGBR) {
        changeLanguage(SUPPORTED_LANGUAGES.en).then(setLanguageToUrl);
        return;
      }

      const userCountryLanguage =
        isGBR && language === SUPPORTED_LANGUAGES.en ? SUPPORTED_LANGUAGES.gb : language;

      changeLanguage(userCountryLanguage).then(setLanguageToUrl);
    }
  }, [userCountry?.country]);

  useEffect(() => {
    if (!isAuthenticated && !isFetching) {
      navigate(generatePath(pathname.replace(`${lang}`, '') + search + hash), {
        replace: true,
        state
      });
    }
  }, []);
};
