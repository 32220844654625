import classNames from 'classnames';
import { Skeletons } from 'src/components/Skeletons';
import { FC } from 'react';

interface AmountPresetsProps {
  value?: string;
  onClick?: (value: string) => void;
  amountPresets?: string[];
  isLoading?: boolean;
}

export const AmountPresets: FC<AmountPresetsProps> = ({
  onClick,
  value = '0',
  amountPresets = [],
  isLoading
}) => {
  return (
    <div className="flex justify-center gap-2">
      {isLoading && <Skeletons className="w-[100px] 768:w-[85px] h-[40px] mb-2 flex" count={4} />}

      {!isLoading &&
        amountPresets.map((preset) => {
          return (
            <button
              title={`${preset}`}
              key={preset}
              onClick={() => onClick?.(preset)}
              className={classNames(
                value.toString() === preset.toString()
                  ? 'border-button-primary text-text-primary bg-button-primary'
                  : 'border-text-secondary text-text-secondary bg-inherit',
                'border-[1px] border-solid rounded-[20px] py-[7px] w-[100px] text-[16px] font-medium jmid:py-[5px] jmid:font-bold jmid:leading-[100%]'
              )}>
              {preset.endsWith('000') ? `${preset.slice(0, preset.length - 3)}k` : preset}
            </button>
          );
        })}
    </div>
  );
};
