import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PLACE_SUFFIX, PRIZE_PODIUM_BADGES } from 'src/pages/LocalTournament/constants';

interface TournamentPlacesProps {
  isFinishedTournament: boolean;
  index: number;
  login: string;
  prize: string | undefined;
}

export const TournamentPlaces: FC<TournamentPlacesProps> = ({
  isFinishedTournament,
  login,
  prize,
  index
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex 568:gap-3 gap-[7px] items-center">
      <img
        className="568:w-[130px] w-[70px]"
        src={PRIZE_PODIUM_BADGES[index]}
        alt="tournament medal"
      />

      <div
        className="border-2 border-yellow rounded-lg backdrop-blur-sm p-5 text-white w-[295px]"
        style={{
          boxShadow: '0px 0px 5px 0px #F0B6FF'
        }}>
        <p className="uppercase flex flex-col gap-[5px]">
          <span className="text-[20px] leading-[22px] font-extrabold">
            {index + 1}
            {PLACE_SUFFIX[index]} {t('tournaments.placeGet')}
          </span>
          {isFinishedTournament && (
            <span className="text-[20px] leading-[22px] uppercase">{login}</span>
          )}
          <span className="text-[31px] leading-[33px] text-yellow font-bold">{prize}</span>
        </p>
      </div>
    </div>
  );
};
