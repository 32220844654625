export function unregisterOldServiceWorkers() {
  console.log('[SW]: check for outdated service workers');
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        if (!registration.active) continue;
        if (
          !registration.scope.includes('/pwa') &&
          registration.active.scriptURL.includes('sw.js')
        ) {
          console.log('[SW]: old service worker detected', registration);
          registration.unregister();
        }
      }
    });
  }
}
