import { useEffect, useState } from 'react';
import { useDeviceDetect } from 'react-easyrocket';

export const useOrientationHandler = () => {
  const { isMobile } = useDeviceDetect();
  const [shouldFrameRender, setShouldFrameRender] = useState(false);
  const [shouldDisplayScreenRotate, setShouldDisplayScreenRotate] = useState(false);

  useEffect(() => {
    const properOrientation = window.matchMedia('(orientation: portrait)');
    const isOpenedInProperOrientation = isMobile && properOrientation.matches;

    if (!isMobile) {
      setShouldFrameRender(true);
      return;
    }

    const handleOrientationChange = (e: MediaQueryListEvent) => {
      const isProperOrientation = e.matches;
      if (isProperOrientation) {
        setShouldFrameRender(true);
      }
      setShouldDisplayScreenRotate(() => !e.matches);
    };

    if (isOpenedInProperOrientation) {
      setShouldFrameRender(true);
    } else if (isMobile) {
      setShouldDisplayScreenRotate(true);
    }

    if (isMobile) {
      properOrientation.addEventListener('change', handleOrientationChange);
    }

    return () => {
      properOrientation.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return { shouldFrameRender, shouldDisplayScreenRotate };
};
