import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Buttons/Button';
import { MessageHead } from 'src/components/MessageHead';
import { MessageStatus } from 'src/components/MessageStatus';
// import { MoreIcon } from 'src/components/svg/MoreIcon';
import { MESSAGE_TYPE } from 'src/modules/messages/types';
import { useNavigateLng } from 'src/lib';

interface AnnouncementMessageProps {
  id: string;
  text: string;
  status: MESSAGE_TYPE;
  className?: string;
  isReadIt?: boolean;
}

export const AnnouncementMessage = ({
  status,
  id,
  text,
  className,
  isReadIt
}: AnnouncementMessageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigateLng();

  return (
    <div
      className={classNames(
        'rounded-[20px] p-3 bg-am_bg hover:bg-um_bg_hover relative',
        className
      )}>
      <MessageHead isReadIt={isReadIt} id={id} />
      <MessageStatus status={status} />
      <div
        className="[&>h2]:text-lg [&>h2]:font-black [&>h2]:text-am_title [&>h2]:mb-1 [&>p]:text-sm [&>p]:font-semibold [&>p]:text-am_text hide-text-two-lines"
        dangerouslySetInnerHTML={{ __html: text || '' }}
      />
      <div className="flex gap-1 mt-2">
        <Button
          label={t('general.participate')}
          mode="button-secondary"
          onClick={() => navigate('/tournaments')}
        />
        {/* <Button
          className="!w-fit !bg-am_more-bg rounded-[50px] px-4 py-3 flex items-center gap-1"
          label={t('general.more')}
          mode="button-simple"
          icon={<MoreIcon />}
        /> */}
      </div>
    </div>
  );
};
