export const PokerIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278210)">
      <path
        d="M20.1851 19.4463C19.3341 18.0564 18.0128 17.2846 16.4642 17.2731C15.5474 17.2672 14.6457 17.5275 13.8544 18.0266L13.7644 18.083L13.6774 18.0284C12.8932 17.5339 12.0028 17.2729 11.0996 17.2729C11.0881 17.2729 11.0763 17.273 11.0646 17.2731C9.51608 17.2846 8.19477 18.0564 7.34375 19.4462C6.46522 20.8811 6.43897 22.5771 7.27164 24.0996C7.86707 25.1885 8.78254 26.0498 9.59014 26.8098C9.72575 26.9373 9.86019 27.0638 9.99135 27.1898C10.4955 27.6738 11.0024 28.1373 11.4927 28.5856C11.9727 29.0243 12.4688 29.4781 12.9523 29.9422L13.7643 30.722L14.5764 29.9422C15.0599 29.478 15.556 29.0243 16.036 28.5856C16.5263 28.1373 17.0332 27.6738 17.5373 27.1898C17.6685 27.0638 17.8029 26.9374 17.9386 26.8098C18.7461 26.0498 19.6616 25.1885 20.257 24.0996C21.0897 22.5771 21.0635 20.881 20.1851 19.4463ZM18.1998 22.9744C17.7832 23.7362 17.0453 24.4305 16.3316 25.1019C16.1902 25.235 16.0501 25.3669 15.9133 25.4982C15.4299 25.9624 14.9337 26.4161 14.4537 26.8549C14.2269 27.0623 13.9964 27.2729 13.7644 27.4874C13.5323 27.2729 13.302 27.0623 13.0751 26.8549C12.5951 26.4161 12.099 25.9623 11.6155 25.4982C11.4787 25.3669 11.3387 25.235 11.1972 25.1019C10.4836 24.4305 9.74567 23.7362 9.32906 22.9744C8.90433 22.1978 8.90949 21.3798 9.34367 20.6707C9.77996 19.9581 10.3323 19.6236 11.0821 19.6179C11.0879 19.6179 11.0937 19.6179 11.0995 19.6179C11.5591 19.6179 12.0176 19.7539 12.4294 20.0137L13.7644 20.85L15.1024 20.0118C15.5163 19.7507 15.9825 19.6167 16.4466 19.6179C17.1965 19.6236 17.7488 19.9581 18.1851 20.6708C18.6192 21.3797 18.6244 22.1978 18.1998 22.9744Z"
        fill="white"
      />
      <path
        d="M18.1789 0.657104L16.4415 4.3559L9.86814 2.88937L8.97805 6.87776H2.2431V40.6543H25.3249V39.7438L39.0717 10.4684L18.1789 0.657104ZM11.646 5.68854L16.9763 6.87776H11.3807L11.646 5.68854ZM22.98 38.3094H4.58808V9.22275H22.9801L22.98 38.3094ZM18.7865 4.87907L19.3045 3.77626L24.2474 6.09742L18.7865 4.87907ZM25.325 28.8431V8.74044L29.5972 9.69357L25.325 28.8431ZM31.9909 9.73373L35.9526 11.5941L27.614 29.3523L31.9909 9.73373Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278210">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.644531 0.661133)"
        />
      </clipPath>
    </defs>
  </svg>
);
