import { Key, updateInfoMap } from '../constants';
import { splitPhoneNumber } from './splitPhoneNumber';

const updateInfoData = {} as { [key: string]: string };

export const prepareUpdateInfoData = (formData: FormData): { [key: string]: string } => {
  for (const field of formData) {
    const key = field[0] as Key;
    const value = field[1] as string;

    if (updateInfoMap[key] && value) {
      updateInfoData[key] = value;
    }
    if (updateInfoMap[key] === 'birthday' && value) {
      updateInfoData[key] = value.split('.').reverse().join('-');
    }

    if (updateInfoMap[key] === 'phone' && value) {
      const { code, phone } = splitPhoneNumber(`+${value}`);
      if (code && phone) {
        updateInfoData['code'] = code;
        updateInfoData['phone'] = phone;
      }
    }
  }
  return updateInfoData;
};
