import classNames from 'classnames';

export const Spinner = ({
  className,
  containerClassName,
  color = 'border-bg-footer'
}: {
  className?: string;
  containerClassName?: string;
  color?: string;
}) => (
  <div className={containerClassName}>
    <span
      className={classNames(
        'w-[48px] h-[48px] border-[5px] border-b-[transparent] rounded-[50%] inline-block box-border animate-spin',
        color,
        className
      )}></span>
  </div>
);
