import dayjs from 'dayjs';

export const getTimestampsDiff = (canBeCollectedAfterTs: number | null | undefined) => {
  if (!canBeCollectedAfterTs) return null;

  const currentTimestamp = dayjs();
  const canBeCollectedTimestamp = dayjs(canBeCollectedAfterTs * 1000);

  return canBeCollectedTimestamp.diff(currentTimestamp, 'millisecond');
};
