import { Trans, useTranslation } from 'react-i18next';
import { HellocleverResponse } from 'src/types';
import { Input } from '../Inputs/Input';
import { CopyIcon } from '../svg';
import { useEffect, useMemo, useState } from 'react';
import { getCurrencyIcon } from 'src/utils';
import { Tooltip } from 'react-tooltip';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { openChat } from '../LiveChatWidget/utils';

type HellocleverResultModalProps = HellocleverResponse & {
  paymentMethodName?: string;
};

export const HellocleverResultModal = () => {
  const { t } = useTranslation();
  const { modalProps } = useModalContext<HellocleverResultModalProps>();
  const [isCopiedText, setIsCopiedText] = useState(false);
  const [isCopiedCode, setIsCopiedCode] = useState(false);

  const currencySing = useMemo(() => {
    return modalProps?.currency ? getCurrencyIcon(modalProps?.currency) : '';
  }, [modalProps?.currency]);

  const handleCopyText = () => {
    navigator.clipboard.writeText(modalProps?.account ? modalProps?.account : '');
    setIsCopiedText(true);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(modalProps?.nonce ? `${modalProps?.nonce}` : '');
    setIsCopiedCode(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      isCopiedText && setIsCopiedText(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isCopiedText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      isCopiedCode && setIsCopiedCode(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isCopiedCode]);

  return (
    <ModalWrapper>
      <div className="flex flex-col items-center gap-[30px] p-[20px] w-[400px] jmid:w-full h-full">
        <div className="text-[20px] font-bold whitespace-normal text-center text-button-secondary uppercase">
          {t('helloCleverResult.topUp')} {modalProps?.paymentMethodName}{' '}
          {t('helloCleverResult.playMore')}
        </div>
        <h1 className="text-[38px] font-bold whitespace-normal text-center text-button-secondary">
          {currencySing} {Number(modalProps?.amount).toFixed(2)}
        </h1>
        <div className="flex flex-col items-start gap-2 w-full font-bold text-center">
          {t('helloCleverResult.send')} {currencySing}
          {Number(modalProps?.amount).toFixed(2)} {t('helloCleverResult.toThe')}{' '}
          {modalProps?.paymentMethodName} {t('helloCleverResult.shownBelowToComplete')}
        </div>
        <div
          className="flex flex-row relative !w-full !max-w-[400px] cursor-pointer"
          onClick={handleCopyText}>
          <Input
            id="account-input"
            type="text"
            value={modalProps?.account}
            readOnly
            rightIcon={<CopyIcon />}
            className="!cursor-pointer"
          />
          <Tooltip
            anchorSelect="#account-input"
            className="bg-color-link text-bg-content-page rounded-[20px]"
            place="top"
            isOpen={isCopiedText}>
            <div>{t('helloCleverResult.copied')}</div>
          </Tooltip>
        </div>
        <div className="flex flex-col items-start text-start gap-2 w-full text-[#1F1F1F] text-[14px] font-medium">
          <p>{t('helloCleverResult.nonceText')}</p>
          <p>{t('helloCleverResult.nonceAlert')}</p>
        </div>

        <div
          className="flex flex-row relative !w-full !max-w-[400px] cursor-pointer"
          onClick={handleCopyCode}>
          <Input
            id="nonce-input"
            type="text"
            value={modalProps?.nonce}
            readOnly
            rightIcon={<CopyIcon />}
            className="!cursor-pointer"
          />
          <Tooltip
            anchorSelect="#nonce-input"
            className="bg-color-link text-bg-content-page rounded-[20px]"
            place="top"
            isOpen={isCopiedCode}>
            <div>{t('helloCleverResult.copied')}</div>
          </Tooltip>
        </div>

        <div className="flex flex-col items-start text-start gap-2 w-full text-text_20 text-[14px] font-medium mb-6">
          <p>
            <Trans
              i18nKey="helloCleverResult.modalInfoGeneral"
              components={{
                '1': (
                  <span
                    className="text-[#AD31E0] underline cursor-pointer"
                    onClick={() => openChat()}
                  />
                )
              }}
            />
          </p>
          <ul className="mb-[40px]">
            <li className="list-disc ml-4">{t('helloCleverResult.modalInfoFirst')}</li>
            <li className="list-disc ml-4">{t('helloCleverResult.modalInfoSecond')}</li>
            <li className="list-disc ml-4">{t('helloCleverResult.modalInfoThird')}</li>
          </ul>
        </div>
      </div>
    </ModalWrapper>
  );
};
