import { useEffect } from 'react';
import { useActivateBonusViaLink, useApiContext } from 'react-easyrocket';
import { useParams } from 'react-router-dom';
import { ACTIVATE_BONUS_LOCAL_STORAGE_KEY } from 'src/constants/storageKey';
import { useModalContext } from 'src/hooks/useModalContext';
import { NavigateLng } from 'src/lib';

export function ActivateBonus() {
  const { bonusId } = useParams();
  const { isAuthenticated } = useApiContext();
  const { mutateAsync } = useActivateBonusViaLink();
  const { openModal } = useModalContext();

  useEffect(() => {
    if (bonusId) {
      if (isAuthenticated) {
        mutateAsync({ bonus_id: bonusId });
      } else {
        localStorage.setItem(ACTIVATE_BONUS_LOCAL_STORAGE_KEY, bonusId);
        openModal('SIGNUP');
      }
    }
  }, [isAuthenticated]);

  return <NavigateLng to="/" />;
}
