import { FC } from 'react';
import { makeFloat } from 'react-easyrocket';
import { getNumberFormatter } from 'src/utils/getNumberFormatter';
import { getCurrencyIcon } from 'src/utils';

type Props = { currency: string | undefined; value: number | undefined };

export const CurrencyItem: FC<Props> = ({ currency, value }) => {
  const { numberFormatter } = getNumberFormatter();
  return (
    <>
      {getCurrencyIcon(currency)} {numberFormatter.format(Number(makeFloat(value)))}
    </>
  );
};
