import { useMessage } from '..';
import { Message } from './Message';
import { AnimatePresence, motion } from 'framer-motion';

export const MessagesContainer = () => {
  const { openedMessages } = useMessage();

  return (
    <AnimatePresence initial={true}>
      <motion.div className="fixed top-[55px] right-2 768:top-6 768:right-4 flex flex-col gap-y-4 z-[1000] w-[356px]">
        {openedMessages.map((message) => (
          <Message key={message.uuid} {...message} />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};
