type PropsAccountIcon = {
  className?: string;
};

export const AccountIcon = ({ className }: PropsAccountIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
};

export const AccountForProfile = ({ className }: PropsAccountIcon) => {
  return (
    <svg
      width="26"
      height="26"
      className={className}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7438 12.0662L9.30533 18.8835C8.92991 19.281 8.66354 19.779 8.53475 20.3244L8.03561 22.4388C7.81845 23.3583 8.60514 24.1912 9.47358 23.9614L11.4705 23.4327C11.9856 23.2964 12.4559 23.0145 12.8313 22.617L19.2698 15.7997C20.2434 14.7686 20.2434 13.0971 19.2698 12.0662C18.2962 11.0352 16.7174 11.0352 15.7438 12.0662ZM3.99954 0.70581C3.99954 0.316005 3.70109 0 3.33295 0C2.96481 0 2.66636 0.316005 2.66636 0.70581V1.41162H1.99977C0.895324 1.41162 0 2.35962 0 3.52905V20.4685C0 21.6379 0.895324 22.5859 1.99977 22.5859H6.66971C6.67943 22.4248 6.70316 22.2614 6.74211 22.0964L7.24134 19.982C7.42871 19.1884 7.81621 18.4637 8.3625 17.8854L14.801 11.068C15.156 10.6922 15.5633 10.4056 15.9982 10.2083V3.52905C15.9982 2.35962 15.1028 1.41162 13.9984 1.41162H13.3318V0.70581C13.3318 0.316005 13.0333 0 12.6652 0C12.2971 0 11.9986 0.316005 11.9986 0.70581V1.41162H8.66567V0.70581C8.66567 0.316005 8.36717 0 7.99908 0C7.63094 0 7.33249 0.316005 7.33249 0.70581V1.41162H3.99954V0.70581ZM4.66613 7.0581H11.332C11.7001 7.0581 11.9986 7.3741 11.9986 7.76391C11.9986 8.15371 11.7001 8.46972 11.332 8.46972H4.66613C4.29799 8.46972 3.99954 8.15371 3.99954 7.76391C3.99954 7.3741 4.29799 7.0581 4.66613 7.0581ZM11.9986 11.9988C11.9986 12.3885 11.7001 12.7046 11.332 12.7046H4.66613C4.29799 12.7046 3.99954 12.3885 3.99954 11.9988C3.99954 11.609 4.29799 11.293 4.66613 11.293H11.332C11.7001 11.293 11.9986 11.609 11.9986 11.9988ZM4.66613 15.5278H7.33249C7.70063 15.5278 7.99908 15.8439 7.99908 16.2336C7.99908 16.6234 7.70063 16.9394 7.33249 16.9394H4.66613C4.29799 16.9394 3.99954 16.6234 3.99954 16.2336C3.99954 15.8439 4.29799 15.5278 4.66613 15.5278Z"
        fill="white"
      />
    </svg>
  );
};

export const GameHeaderAccountIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08824 14.5058C7.77757 13.8571 8.70327 13.5 9.65967 13.5H14.3403C15.2967 13.5 16.2224 13.8571 16.9118 14.5058C17.6026 15.1559 18 16.0478 18 16.9881V18C18 18.4142 17.6642 18.75 17.25 18.75C16.8358 18.75 16.5 18.4142 16.5 18V16.9881C16.5 16.4761 16.2843 15.975 15.8838 15.5982C15.4818 15.2199 14.9273 15 14.3403 15H9.65967C9.07268 15 8.51821 15.2199 8.11621 15.5982C7.71574 15.975 7.5 16.4761 7.5 16.9881V18C7.5 18.4142 7.16421 18.75 6.75 18.75C6.33579 18.75 6 18.4142 6 18V16.9881C6 16.0478 6.39736 15.1559 7.08824 14.5058Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C10.7574 6 9.75 7.00736 9.75 8.25C9.75 9.49264 10.7574 10.5 12 10.5C13.2426 10.5 14.25 9.49264 14.25 8.25C14.25 7.00736 13.2426 6 12 6ZM8.25 8.25C8.25 6.17893 9.92893 4.5 12 4.5C14.0711 4.5 15.75 6.17893 15.75 8.25C15.75 10.3211 14.0711 12 12 12C9.92893 12 8.25 10.3211 8.25 8.25Z"
        fill="white"
      />
    </svg>
  );
};
