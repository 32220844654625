import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSportId } from '../useGetSportId';
import { useApiContext, useGetSportBrandId } from 'react-easyrocket';
import { prepareSportUrl } from './utils/prepareSportUrl';
import { SOFTSWISS_TOKEN_REFRESH_INTERVAL } from '../../config';

export const useSportUrl = () => {
  const { i18n } = useTranslation();
  const sportId = useGetSportId();
  const { isAuthenticated } = useApiContext();
  const refetchIntervalRef = useRef<NodeJS.Timeout>();

  const { data, refetch } = useGetSportBrandId({ 'sport-id': sportId }, {});

  useEffect(() => {
    if (isAuthenticated) {
      refetchIntervalRef.current = setInterval(refetch, SOFTSWISS_TOKEN_REFRESH_INTERVAL);
      // TODO пока непонятно что с этим делать. Пока останется закомменчено (31.10.2024)
      // window.addEventListener('message', (e) => {
      //   if (
      //     e.origin == SOFTSWISS_FRAME_DOMAIN &&
      //     e.data?.type === 'sportsbook:bet-slip-state-changed' &&
      //     e.data?.payload?.quickBet?.state === 'SUCCESS'
      //   ) {
      //     clearInterval(refetchIntervalRef.current);
      //     refetchIntervalRef.current = setInterval(refetch, SOFTSWISS_TOKEN_REFRESH_INTERVAL);
      //   }
      // });

      return () => {
        clearInterval(refetchIntervalRef.current);
      };
    }
  }, [isAuthenticated]);

  const sportUrl = useMemo(() => {
    return prepareSportUrl(data?.data?.token, i18n.language);
  }, [data?.data?.token, i18n.language]);

  return sportUrl;
};
