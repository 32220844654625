import { useEffect, useState } from 'react';
import { ResponsiveBreakpoint } from '../types';
import { BP } from 'src/constants';

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
      const handler = (event: MediaQueryListEvent) => setMatches(event.matches);
      mediaQuery.addEventListener('change', handler);
      return () => mediaQuery.removeEventListener('change', handler);
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );
  return matches;
}

/** @deprecated */
const _BP = {
  xs: 460,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

/** @deprecated */
export function useBreakpoints() {
  const breakpoints: {
    isXxs: boolean;
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    active: ResponsiveBreakpoint;
  } = {
    isXxs: useMediaQuery(`(max-width: ${_BP.xs - 1}px)`),
    isXs: useMediaQuery(`(max-width: ${_BP.sm - 1}px)`),
    isSm: useMediaQuery(`(min-width: ${_BP.sm}px) and (max-width: ${_BP.md - 1}px)`),
    isMd: useMediaQuery(`(min-width: ${_BP.md}px) and (max-width: ${_BP.lg - 1}px)`),
    isLg: useMediaQuery(`(min-width: ${_BP.lg}px) and (max-width: ${_BP.xl - 1}px)`),
    isXl: useMediaQuery(`(min-width: ${_BP.xl}px)`),
    active: 'xs'
  };
  if (breakpoints.isXxs) breakpoints.active = 'gf';
  if (breakpoints.isXs) breakpoints.active = 'xs';
  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';
  if (breakpoints.isXl) breakpoints.active = 'xl';
  return breakpoints;
}

export function useMediaBreakpoint() {
  const gf = useMediaQuery(`(max-width: ${BP.gf}px)`);
  const mobile = useMediaQuery(`(min-width: ${BP.gf + 1}px)`);
  const lg = useMediaQuery(`(min-width: ${BP.lg}px)`);
  const xl = useMediaQuery(`(min-width: ${BP.xl}px)`);
  return { mobile, gf, lg, xl };
}
