import './styles.css';

export const Wheel = () => {
  return (
    <svg
      className="index-[1] w-full h-full jgf:w-[93%]"
      viewBox="0 0 593 593"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12021_246350)" filter="url(#filter0_i_12021_246350)">
        <path
          d="M204.877 14.4951C176.009 23.8672 148.097 37.8134 122.239 56.6185C96.3598 75.4235 74.4857 97.6643 56.6367 122.202L296.5 296.49L204.877 14.4951Z"
          fill="#FF3A81"
        />
        <path
          d="M388.122 14.4951C359.254 5.12311 328.476 0 296.498 0C264.521 0 233.742 5.12311 204.875 14.4951L296.498 296.49L388.122 14.4951Z"
          fill="#6D3C7E"
        />
        <path
          d="M536.363 122.202C518.514 97.644 496.64 75.4032 470.761 56.6185C444.882 37.8134 416.97 23.8875 388.123 14.4951L296.5 296.49L536.363 122.202Z"
          fill="#FF9900"
        />
        <path
          d="M56.6367 470.777C74.4857 495.336 96.3598 517.556 122.239 536.361C148.118 555.166 176.03 569.112 204.877 578.484L296.5 296.49L56.6367 470.777Z"
          fill="#FFD600"
        />
        <path
          d="M204.875 578.505C233.742 587.877 264.521 593 296.498 593C328.476 593 359.254 587.877 388.122 578.505L296.498 296.51L204.875 578.505Z"
          fill="#3C2A93"
        />
        <path
          d="M0 296.51C0 326.863 4.63504 357.723 14.515 388.137C24.3949 418.55 38.7879 446.239 56.6166 470.798L296.48 296.51H0Z"
          fill="#9344F3"
        />
        <path
          d="M536.364 470.777C554.193 446.219 568.586 418.53 578.465 388.116C588.345 357.682 592.98 326.842 592.98 296.49H296.48L536.344 470.777H536.364Z"
          fill="#8BAE00"
        />
        <path
          d="M56.6166 122.223C38.7879 146.781 24.3949 174.47 14.515 204.884C4.63504 235.297 0 266.158 0 296.49H296.5L56.6166 122.223Z"
          fill="#ACD700"
        />
        <path
          d="M592.999 296.49C592.999 266.137 588.364 235.277 578.484 204.863C568.604 174.45 554.211 146.761 536.383 122.202L296.52 296.49H593.02H592.999Z"
          fill="#B171FF"
        />
        <path
          d="M388.123 578.484C416.991 569.092 444.902 555.166 470.761 536.361C496.64 517.556 518.514 495.336 536.363 470.777L296.5 296.49L388.123 578.484Z"
          fill="#FB3233"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_12021_246350"
          x="0"
          y="0"
          width="603"
          height="603"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="10" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12021_246350" />
        </filter>
        <clipPath id="clip0_12021_246350">
          <rect width="593" height="593" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

// width="627"
//       height="633"
//       className="absolute z-[5] left-[24.5%] top-[40px]"

export const BorderWheel = () => {
  return (
    <svg
      className="absolute z-[5] w-[120%] jgf:w-[111%] jgf:h-[111%] h-[120%]"
      viewBox="0 0 723 723"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M361.745 48.3994C190.125 48.3994 51 187.383 51 358.88C51 530.377 190.125 669.309 361.745 669.309C533.365 669.309 672.489 530.326 672.489 358.88C672.489 187.434 533.365 48.3994 361.745 48.3994ZM361.745 641.44C205.778 641.44 78.8456 514.688 78.8456 358.828C78.8456 202.969 205.727 76.2684 361.745 76.2684C517.763 76.2684 644.644 203.02 644.644 358.88C644.644 514.74 517.763 641.492 361.745 641.492V641.44Z"
        fill="url(#paint0_linear_12021_246197)"
      />
      <path
        d="M361.745 53.0442C190.125 53.0442 51 192.028 51 363.473C51 534.919 190.125 673.902 361.745 673.902C533.365 673.902 672.489 534.919 672.489 363.473C672.489 192.028 533.365 53.0442 361.745 53.0442ZM361.745 646.085C205.778 646.085 78.8456 519.333 78.8456 363.473C78.8456 207.614 205.727 80.9131 361.745 80.9131C517.763 80.9131 644.644 207.665 644.644 363.525C644.644 519.384 517.763 646.137 361.745 646.137V646.085Z"
        fill="url(#paint1_linear_12021_246197)"
      />
      <path
        d="M359.41 137.232C355.742 128.459 345.1 122.214 335.956 112.666C332.598 109.157 331.978 104.099 334.096 100.383C335.698 97.5965 337.816 94.8096 338.746 92.126C339.521 89.7519 342 88.0488 344.893 88.0488H373.927C376.872 88.0488 379.3 89.7519 380.075 92.126C380.953 94.758 383.123 97.5965 384.725 100.383C386.843 104.099 386.223 109.157 382.865 112.666C373.772 122.214 363.13 128.459 359.41 137.232Z"
        fill="url(#paint2_linear_12021_246197)"
      />
      <path
        d="M359.41 133.155C356.672 125.104 348.665 119.375 341.845 110.602C339.314 107.351 338.849 102.757 340.45 99.3511C341.639 96.8223 343.24 94.2418 343.912 91.8162C344.532 89.6486 346.34 88.1003 348.561 88.1003H370.362C372.532 88.1003 374.392 89.6486 375.012 91.8162C375.684 94.2418 377.285 96.8223 378.473 99.3511C380.075 102.757 379.61 107.402 377.079 110.602C370.259 119.375 362.252 125.104 359.514 133.155H359.41Z"
        fill="url(#paint3_linear_12021_246197)"
      />
      <g filter="url(#filter0_d_12021_246197)">
        <path
          d="M359.41 137.232C355.742 128.459 345.1 122.214 335.956 112.666C332.598 109.157 331.978 104.099 334.096 100.383C335.698 97.5965 337.816 94.8096 338.746 92.126C339.521 89.7519 342 88.0488 344.893 88.0488H373.927C376.872 88.0488 379.3 89.7519 380.075 92.126C380.953 94.758 383.123 97.5965 384.725 100.383C386.843 104.099 386.223 109.157 382.865 112.666C373.772 122.214 363.13 128.459 359.41 137.232Z"
          fill="url(#paint4_linear_12021_246197)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
        <g filter="url(#filter1_d_12021_246197)">
          <path
            d="M325.934 49.2386C329.86 50.2708 331.048 52.3868 331.616 53.9351C335.078 63.2247 340.76 71.9983 342.672 81.1847C343.86 86.9649 345.048 95.8933 345.823 102.757C346.495 108.331 352.074 112.615 358.79 112.615H359.049C359.049 112.615 359.204 112.615 359.255 112.615C359.307 112.615 359.41 112.615 359.462 112.615H359.72C366.385 112.615 372.016 108.383 372.687 102.757C373.514 95.8933 374.651 86.9133 375.839 81.1847C377.75 71.9466 383.433 63.2247 386.894 53.9351C387.463 52.3868 388.651 50.2708 392.577 49.2386C392.577 47.5871 325.985 47.5871 325.985 49.2386H325.934Z"
            fill="#733F08"
          />
        </g>
      </g>
      <path
        d="M325.938 49.2386C329.864 50.2708 331.052 52.3868 331.62 53.9351C335.082 63.2247 340.764 71.9983 342.676 81.1847C343.864 86.9649 345.052 95.8933 345.827 102.757C346.499 108.331 352.078 112.615 358.794 112.615H359.053C359.053 112.615 359.208 112.615 359.259 112.615C359.311 112.615 359.414 112.615 359.466 112.615H359.724C366.389 112.615 372.02 108.383 372.691 102.757C373.518 95.8933 374.654 86.9133 375.843 81.1847C377.754 71.9466 383.437 63.2247 386.898 53.9351C387.467 52.3868 388.655 50.2708 392.581 49.2386C392.581 47.5871 325.989 47.5871 325.989 49.2386H325.938Z"
        fill="url(#paint5_linear_12021_246197)"
      />
      <path
        d="M326.402 49.2386C330.277 50.2708 331.414 52.3868 331.982 53.9351C335.392 63.2247 341.023 71.9983 342.883 81.1847C344.071 86.9649 345.207 95.8933 346.034 102.757C346.705 108.331 352.182 112.615 358.794 112.615H359.053C359.053 112.615 359.208 112.615 359.259 112.615C359.311 112.615 359.414 112.615 359.466 112.615H359.724C366.337 112.615 371.813 108.383 372.485 102.757C373.311 95.8933 374.448 86.9133 375.636 81.1847C377.496 71.9466 383.127 63.2247 386.537 53.9351C387.105 52.3868 388.241 50.2708 392.116 49.2386C392.116 47.5871 326.506 47.5871 326.506 49.2386H326.402Z"
        fill="url(#paint6_linear_12021_246197)"
      />
      <path
        d="M326.919 49.2386C330.742 50.2708 331.879 52.3868 332.447 53.9351C335.805 63.2247 341.333 71.9983 343.193 81.1847C344.329 86.9649 345.466 95.8933 346.292 102.757C346.912 108.331 352.388 112.615 358.846 112.615H359.104C359.104 112.615 359.259 112.615 359.311 112.615C359.363 112.615 359.466 112.615 359.518 112.615H359.724C366.234 112.615 371.658 108.383 372.278 102.757C373.053 95.8933 374.189 86.9133 375.378 81.1847C377.237 71.9466 382.765 63.2247 386.123 53.9351C386.692 52.3868 387.828 50.2708 391.651 49.2386C391.651 47.5871 326.971 47.5871 326.971 49.2386H326.919Z"
        fill="url(#paint7_linear_12021_246197)"
      />
      <path
        d="M327.384 49.2386C331.155 50.2708 332.24 52.3868 332.809 53.9351C336.115 63.2247 341.591 71.9983 343.399 81.1847C344.536 86.9649 345.672 95.8933 346.447 102.757C347.067 108.331 352.44 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.466 112.615H359.673C366.079 112.615 371.451 108.383 372.071 102.757C372.846 95.8933 373.983 86.9133 375.119 81.1847C376.928 71.9466 382.404 63.2247 385.71 53.9351C386.278 52.3868 387.363 50.2708 391.134 49.2386C391.134 47.5871 327.436 47.5871 327.436 49.2386H327.384Z"
        fill="url(#paint8_linear_12021_246197)"
      />
      <path
        d="M327.849 49.2386C331.517 50.2708 332.654 52.3868 333.17 53.9351C336.425 63.2247 341.798 71.9983 343.606 81.1847C344.742 86.9649 345.827 95.8933 346.602 102.757C347.222 108.331 352.492 112.615 358.794 112.615H359.001C359.001 112.615 359.104 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.414 112.615H359.621C365.924 112.615 371.193 108.383 371.813 102.757C372.588 95.8933 373.673 86.9133 374.809 81.1847C376.618 71.9466 381.99 63.2247 385.245 53.9351C385.762 52.3868 386.898 50.2708 390.566 49.2386C390.566 47.5871 327.797 47.5871 327.797 49.2386H327.849Z"
        fill="url(#paint9_linear_12021_246197)"
      />
      <path
        d="M328.366 49.2386C331.982 50.2708 333.118 52.3868 333.635 53.9351C336.838 63.2247 342.108 71.9983 343.916 81.1847C345.001 86.9649 346.086 95.8933 346.86 102.757C347.48 108.331 352.647 112.615 358.898 112.615H359.104C359.104 112.615 359.208 112.615 359.311 112.615C359.363 112.615 359.414 112.615 359.518 112.615H359.724C365.924 112.615 371.141 108.383 371.761 102.757C372.536 95.8933 373.621 86.9133 374.706 81.1847C376.463 71.9466 381.784 63.2247 384.987 53.9351C385.503 52.3868 386.64 50.2708 390.256 49.2386C390.256 47.5871 328.469 47.5871 328.469 49.2386H328.366Z"
        fill="url(#paint10_linear_12021_246197)"
      />
      <path
        d="M328.831 49.2386C332.395 50.2708 333.48 52.3868 333.997 53.9351C337.148 63.2247 342.366 71.9983 344.122 81.1847C345.207 86.9649 346.292 95.8933 347.015 102.757C347.635 108.331 352.75 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.466 112.615H359.673C365.769 112.615 370.883 108.383 371.503 102.757C372.226 95.8933 373.311 86.9133 374.396 81.1847C376.153 71.9466 381.37 63.2247 384.522 53.9351C385.038 52.3868 386.123 50.2708 389.688 49.2386C389.688 47.5871 328.831 47.5871 328.831 49.2386Z"
        fill="url(#paint11_linear_12021_246197)"
      />
      <path
        d="M329.296 49.2386C332.809 50.2708 333.893 52.3868 334.41 53.9351C337.51 63.2247 342.624 71.9983 344.381 81.1847C345.466 86.9649 346.499 95.8933 347.222 102.757C347.79 108.331 352.853 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.466 112.615H359.673C365.665 112.615 370.728 108.383 371.296 102.757C372.02 95.8933 373.105 86.9133 374.138 81.1847C375.843 71.9466 381.009 63.2247 384.109 53.9351C384.625 52.3868 385.71 50.2708 389.223 49.2386C389.223 47.5871 329.347 47.5871 329.347 49.2386H329.296Z"
        fill="url(#paint12_linear_12021_246197)"
      />
      <path
        d="M329.76 49.2386C333.222 50.2708 334.255 52.3868 334.772 53.9351C337.82 63.2247 342.883 71.9983 344.536 81.1847C345.569 86.9649 346.602 95.8933 347.325 102.757C347.894 108.331 352.853 112.615 358.794 112.615H359.001C359.001 112.615 359.104 112.615 359.156 112.615C359.208 112.615 359.259 112.615 359.311 112.615H359.518C365.459 112.615 370.367 108.383 370.986 102.757C371.71 95.8933 372.743 86.9133 373.776 81.1847C375.481 71.9466 380.492 63.2247 383.54 53.9351C384.057 52.3868 385.09 50.2708 388.551 49.2386C388.551 47.5871 329.605 47.5871 329.605 49.2386H329.76Z"
        fill="url(#paint13_linear_12021_246197)"
      />
      <path
        d="M330.277 49.2386C333.687 50.2708 334.72 52.3868 335.237 53.9351C338.233 63.2247 343.193 71.9983 344.846 81.1847C345.879 86.9649 346.912 95.8933 347.584 102.757C348.152 108.331 353.008 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.363 112.615H359.569C365.407 112.615 370.263 108.383 370.831 102.757C371.555 95.8933 372.536 86.9133 373.57 81.1847C375.223 71.9466 380.182 63.2247 383.179 53.9351C383.695 52.3868 384.728 50.2708 388.138 49.2386C388.138 47.5871 330.174 47.5871 330.174 49.2386H330.277Z"
        fill="url(#paint14_linear_12021_246197)"
      />
      <path
        d="M330.742 49.2386C334.1 50.2708 335.082 52.3868 335.598 53.9351C338.543 63.2247 343.451 71.9983 345.052 81.1847C346.086 86.9649 347.067 95.8933 347.79 102.757C348.359 108.331 353.163 112.615 358.898 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.414 112.615H359.621C365.355 112.615 370.16 108.383 370.728 102.757C371.4 95.8933 372.433 86.9133 373.466 81.1847C375.119 71.9466 379.976 63.2247 382.92 53.9351C383.437 52.3868 384.418 50.2708 387.776 49.2386C387.776 47.5871 330.742 47.5871 330.742 49.2386Z"
        fill="url(#paint15_linear_12021_246197)"
      />
      <path
        d="M331.207 49.2386C334.513 50.2708 335.495 52.3868 335.96 53.9351C338.853 63.2247 343.657 71.9983 345.259 81.1847C346.241 86.9649 347.222 95.8933 347.945 102.757C348.514 108.331 353.215 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.363 112.615H359.569C365.2 112.615 369.902 108.383 370.47 102.757C371.141 95.8933 372.123 86.9133 373.156 81.1847C374.758 71.9466 379.562 63.2247 382.455 53.9351C382.92 52.3868 383.954 50.2708 387.208 49.2386C387.208 47.5871 331.155 47.5871 331.155 49.2386H331.207Z"
        fill="url(#paint16_linear_12021_246197)"
      />
      <path
        d="M331.672 49.2386C334.927 50.2708 335.908 52.3868 336.373 53.9351C339.215 63.2247 343.967 71.9983 345.517 81.1847C346.499 86.9649 347.48 95.8933 348.152 102.757C348.72 108.331 353.318 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.363 112.615H359.569C365.097 112.615 369.747 108.383 370.263 102.757C370.935 95.8933 371.916 86.9133 372.898 81.1847C374.499 71.9466 379.201 63.2247 382.042 53.9351C382.507 52.3868 383.489 50.2708 386.743 49.2386C386.743 47.5871 331.672 47.5871 331.672 49.2386Z"
        fill="url(#paint17_linear_12021_246197)"
      />
      <path
        d="M332.189 49.2386C335.392 50.2708 336.321 52.3868 336.786 53.9351C339.576 63.2247 344.226 71.9983 345.776 81.1847C346.757 86.9649 347.687 95.8933 348.359 102.757C348.875 108.331 353.422 112.615 358.898 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.414 112.615H359.621C365.045 112.615 369.592 108.383 370.16 102.757C370.831 95.8933 371.761 86.9133 372.743 81.1847C374.293 71.9466 378.942 63.2247 381.732 53.9351C382.197 52.3868 383.179 50.2708 386.33 49.2386C386.33 47.5871 332.189 47.5871 332.189 49.2386Z"
        fill="url(#paint18_linear_12021_246197)"
      />
      <path
        d="M332.654 49.2386C335.805 50.2708 336.735 52.3868 337.2 53.9351C339.938 63.2247 344.484 71.9983 346.034 81.1847C346.964 86.9649 347.894 95.8933 348.565 102.757C349.082 108.331 353.576 112.615 358.898 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.311 112.615 359.363 112.615 359.414 112.615H359.621C364.942 112.615 369.437 108.383 369.953 102.757C370.625 95.8933 371.555 86.9133 372.485 81.1847C374.034 71.9466 378.581 63.2247 381.319 53.9351C381.784 52.3868 382.714 50.2708 385.865 49.2386C385.865 47.5871 332.705 47.5871 332.705 49.2386H332.654Z"
        fill="url(#paint19_linear_12021_246197)"
      />
      <path
        d="M333.118 49.2386C336.167 50.2708 337.096 52.3868 337.561 53.9351C340.248 63.2247 344.742 71.9983 346.241 81.1847C347.17 86.9649 348.1 95.8933 348.72 102.757C349.237 108.331 353.628 112.615 358.846 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.363 112.615H359.569C364.839 112.615 369.178 108.383 369.695 102.757C370.315 95.8933 371.245 86.9133 372.175 81.1847C373.673 71.9466 378.167 63.2247 380.854 53.9351C381.319 52.3868 382.249 50.2708 385.297 49.2386C385.297 47.5871 333.067 47.5871 333.067 49.2386H333.118Z"
        fill="url(#paint20_linear_12021_246197)"
      />
      <path
        d="M333.635 49.2386C336.631 50.2708 337.561 52.3868 338.026 53.9351C340.661 63.2247 345.052 71.9983 346.55 81.1847C347.48 86.9649 348.359 95.8933 348.979 102.757C349.495 108.331 353.783 112.615 358.949 112.615H359.156C359.156 112.615 359.259 112.615 359.311 112.615C359.363 112.615 359.414 112.615 359.466 112.615H359.673C364.839 112.615 369.127 108.383 369.643 102.757C370.263 95.8933 371.193 86.9133 372.071 81.1847C373.518 71.9466 377.909 63.2247 380.596 53.9351C381.06 52.3868 381.939 50.2708 384.987 49.2386C384.987 47.5871 333.738 47.5871 333.738 49.2386H333.635Z"
        fill="url(#paint21_linear_12021_246197)"
      />
      <path
        d="M334.1 49.2386C337.045 50.2708 337.975 52.3868 338.388 53.9351C340.971 63.2247 345.311 71.9983 346.757 81.1847C347.635 86.9649 348.565 95.8933 349.134 102.757C349.65 108.331 353.835 112.615 358.898 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.259 112.615 359.311 112.615 359.363 112.615H359.518C364.58 112.615 368.817 108.383 369.282 102.757C369.902 95.8933 370.78 86.9133 371.658 81.1847C373.105 71.9466 377.392 63.2247 380.027 53.9351C380.441 52.3868 381.37 50.2708 384.315 49.2386C384.315 47.5871 333.997 47.5871 333.997 49.2386H334.1Z"
        fill="url(#paint22_linear_12021_246197)"
      />
      <path
        d="M334.565 49.2386C337.458 50.2708 338.336 52.3868 338.75 53.9351C341.281 63.2247 345.517 71.9983 346.964 81.1847C347.842 86.9649 348.72 95.8933 349.34 102.757C349.805 108.331 353.99 112.615 358.949 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.259 112.615 359.363 112.615 359.414 112.615H359.569C364.529 112.615 368.662 108.383 369.178 102.757C369.798 95.8933 370.625 86.9133 371.555 81.1847C372.95 71.9466 377.186 63.2247 379.769 53.9351C380.182 52.3868 381.06 50.2708 383.954 49.2386C383.954 47.5871 334.617 47.5871 334.617 49.2386H334.565Z"
        fill="url(#paint23_linear_12021_246197)"
      />
      <path
        d="M335.03 49.2386C337.871 50.2708 338.75 52.3868 339.163 53.9351C341.694 63.2247 345.827 71.9983 347.222 81.1847C348.1 86.9649 348.927 95.8933 349.547 102.757C350.012 108.331 354.093 112.615 358.949 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.259 112.615 359.363 112.615 359.414 112.615H359.569C364.425 112.615 368.507 108.383 368.972 102.757C369.54 95.8933 370.418 86.9133 371.296 81.1847C372.691 71.9466 376.824 63.2247 379.356 53.9351C379.769 52.3868 380.647 50.2708 383.489 49.2386C383.489 47.5871 335.082 47.5871 335.082 49.2386H335.03Z"
        fill="url(#paint24_linear_12021_246197)"
      />
      <path
        d="M335.547 49.2386C338.336 50.2708 339.163 52.3868 339.576 53.9351C342.056 63.2247 346.086 71.9983 347.48 81.1847C348.307 86.9649 349.185 95.8933 349.754 102.757C350.218 108.331 354.196 112.615 358.949 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.259 112.615 359.363 112.615 359.414 112.615H359.569C364.322 112.615 368.3 108.383 368.765 102.757C369.333 95.8933 370.16 86.9133 371.038 81.1847C372.381 71.9466 376.463 63.2247 378.942 53.9351C379.356 52.3868 380.182 50.2708 382.972 49.2386C382.972 47.5871 335.547 47.5871 335.547 49.2386Z"
        fill="url(#paint25_linear_12021_246197)"
      />
      <path
        d="M336.012 49.2386C338.75 50.2708 339.576 52.3868 339.989 53.9351C342.418 63.2247 346.396 71.9983 347.687 81.1847C348.514 86.9649 349.34 95.8933 349.908 102.757C350.373 108.331 354.248 112.615 358.949 112.615H359.104C359.104 112.615 359.208 112.615 359.259 112.615C359.259 112.615 359.363 112.615 359.414 112.615H359.569C364.219 112.615 368.145 108.383 368.61 102.757C369.178 95.8933 370.005 86.9133 370.831 81.1847C372.175 71.9466 376.153 63.2247 378.529 53.9351C378.942 52.3868 379.769 50.2708 382.507 49.2386C382.507 47.5871 336.012 47.5871 336.012 49.2386Z"
        fill="url(#paint26_linear_12021_246197)"
      />
      <path
        d="M336.476 49.2386C339.163 50.2708 339.938 52.3868 340.351 53.9351C342.728 63.2247 346.602 71.9983 347.894 81.1847C348.72 86.9649 349.495 95.8933 350.063 102.757C350.528 108.331 354.351 112.615 358.898 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.208 112.615 359.311 112.615 359.363 112.615H359.518C364.115 112.615 367.938 108.383 368.352 102.757C368.92 95.8933 369.695 86.9133 370.522 81.1847C371.813 71.9466 375.739 63.2247 378.064 53.9351C378.477 52.3868 379.252 50.2708 381.939 49.2386C381.939 47.5871 336.425 47.5871 336.425 49.2386H336.476Z"
        fill="url(#paint27_linear_12021_246197)"
      />
      <path
        d="M336.941 49.2386C339.576 50.2708 340.351 52.3868 340.713 53.9351C343.038 63.2247 346.86 71.9983 348.1 81.1847C348.875 86.9649 349.702 95.8933 350.218 102.757C350.683 108.331 354.403 112.615 358.898 112.615H359.053C359.053 112.615 359.156 112.615 359.208 112.615C359.208 112.615 359.311 112.615 359.363 112.615H359.518C364.012 112.615 367.732 108.383 368.197 102.757C368.765 95.8933 369.54 86.9133 370.315 81.1847C371.606 71.9466 375.429 63.2247 377.702 53.9351C378.064 52.3868 378.891 50.2708 381.474 49.2386C381.474 47.5871 336.941 47.5871 336.941 49.2386Z"
        fill="url(#paint28_linear_12021_246197)"
      />
      <path
        d="M325.934 49.2386C329.86 50.2708 331.048 52.3868 331.616 53.9351C335.078 63.2247 340.76 71.9983 342.672 81.1847C343.86 86.9649 345.048 95.8933 345.823 102.757C346.495 108.331 352.074 112.615 358.79 112.615H359.049C359.049 112.615 359.204 112.615 359.255 112.615C359.307 112.615 359.41 112.615 359.462 112.615H359.72C366.385 112.615 372.016 108.383 372.687 102.757C373.514 95.8933 374.651 86.9133 375.839 81.1847C377.75 71.9466 383.433 63.2247 386.894 53.9351C387.463 52.3868 388.651 50.2708 392.577 49.2386C392.577 47.5871 325.985 47.5871 325.985 49.2386H325.934Z"
        fill="url(#paint29_linear_12021_246197)"
      />
      <path
        d="M325.934 49.2386C329.86 50.2708 331.048 52.3868 331.616 53.9351C335.078 63.2247 340.76 71.9983 342.672 81.1847C343.86 86.9649 345.048 95.8933 345.823 102.757C346.495 108.331 352.074 112.615 358.79 112.615H359.049C359.049 112.615 359.204 112.615 359.255 112.615C359.307 112.615 359.41 112.615 359.462 112.615H359.72C366.385 112.615 372.016 108.383 372.687 102.757C373.514 95.8933 374.651 86.9133 375.839 81.1847C377.75 71.9466 383.433 63.2247 386.894 53.9351C387.463 52.3868 388.651 50.2708 392.577 49.2386C392.577 47.5871 325.985 47.5871 325.985 49.2386H325.934Z"
        fill="url(#paint30_linear_12021_246197)"
      />
      <path
        d="M336.938 49.2386C339.572 50.2708 340.347 52.3868 340.709 53.9351C343.034 63.2247 346.857 71.9983 348.096 81.1847C348.871 86.9649 349.698 95.8933 350.215 102.757C350.68 108.331 354.399 112.615 358.894 112.615H359.049C359.049 112.615 359.152 112.615 359.204 112.615C359.204 112.615 359.307 112.615 359.359 112.615H359.514C364.008 112.615 367.728 108.383 368.193 102.757C368.761 95.8933 369.536 86.9133 370.311 81.1847C371.602 71.9466 375.425 63.2247 377.699 53.9351C378.06 52.3868 378.887 50.2708 381.47 49.2386C381.47 47.5871 336.938 47.5871 336.938 49.2386Z"
        fill="url(#paint31_linear_12021_246197)"
      />
      <path
        d="M336.938 49.2386C339.572 50.2708 340.347 52.3868 340.709 53.9351C343.034 63.2247 346.857 71.9983 348.096 81.1847C348.871 86.9649 349.698 95.8933 350.215 102.757C350.68 108.331 354.399 112.615 358.894 112.615H359.049C359.049 112.615 359.152 112.615 359.204 112.615C359.204 112.615 359.307 112.615 359.359 112.615H359.514C364.008 112.615 367.728 108.383 368.193 102.757C368.761 95.8933 369.536 86.9133 370.311 81.1847C371.602 71.9466 375.425 63.2247 377.699 53.9351C378.06 52.3868 378.887 50.2708 381.47 49.2386C381.47 47.5871 336.938 47.5871 336.938 49.2386Z"
        fill="url(#paint32_linear_12021_246197)"
      />
      <path
        d="M329.55 49.2386C333.063 50.2708 334.096 52.3868 334.613 53.9351C337.712 63.2247 342.775 71.9983 344.48 81.1847C345.513 86.9649 346.598 95.8933 347.322 102.757C347.89 108.331 352.901 112.615 358.842 112.615H359.049C359.049 112.615 359.152 112.615 359.204 112.615C359.255 112.615 359.307 112.615 359.359 112.615H359.565C365.506 112.615 370.518 108.383 371.086 102.757C371.809 95.8933 372.842 86.9133 373.927 81.1847C375.632 71.9466 380.695 63.2247 383.795 53.9351C384.311 52.3868 385.344 50.2708 388.857 49.2386C388.857 47.5871 329.498 47.5871 329.498 49.2386H329.55Z"
        fill="url(#paint33_linear_12021_246197)"
      />
      <path
        d="M329.55 49.2386C333.063 50.2708 334.096 52.3868 334.613 53.9351C337.712 63.2247 342.775 71.9983 344.48 81.1847C345.513 86.9649 346.598 95.8933 347.322 102.757C347.89 108.331 352.901 112.615 358.842 112.615H359.049C359.049 112.615 359.152 112.615 359.204 112.615C359.255 112.615 359.307 112.615 359.359 112.615H359.565C365.506 112.615 370.518 108.383 371.086 102.757C371.809 95.8933 372.842 86.9133 373.927 81.1847C375.632 71.9466 380.695 63.2247 383.795 53.9351C384.311 52.3868 385.344 50.2708 388.857 49.2386C388.857 47.5871 329.498 47.5871 329.498 49.2386H329.55Z"
        fill="url(#paint34_linear_12021_246197)"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M171.425 149.347C172.819 153.579 170.701 157.553 166.62 158.224C162.59 158.895 158.147 156.005 156.753 151.773C155.358 147.541 157.476 143.567 161.557 142.896C165.587 142.225 170.03 145.115 171.425 149.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M171.269 149.038C172.663 153.218 170.494 157.141 166.516 157.811C162.486 158.482 158.147 155.644 156.752 151.464C155.357 147.283 157.527 143.361 161.505 142.69C165.534 142.019 169.874 144.858 171.269 149.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M171.062 148.728C172.406 152.857 170.287 156.728 166.309 157.45C162.331 158.121 158.044 155.334 156.7 151.205C155.357 147.077 157.475 143.206 161.453 142.483C165.431 141.813 169.719 144.599 171.062 148.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M170.908 148.418C172.251 152.496 170.133 156.366 166.207 157.037C162.28 157.708 158.044 154.973 156.701 150.896C155.358 146.819 157.476 142.948 161.402 142.277C165.328 141.606 169.565 144.341 170.908 148.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M170.752 148.161C172.043 152.186 169.977 156.005 166.102 156.676C162.228 157.347 158.043 154.663 156.752 150.638C155.46 146.612 157.526 142.793 161.401 142.122C165.276 141.451 169.46 144.135 170.752 148.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M170.6 147.851C171.839 151.825 169.773 155.592 165.95 156.263C162.127 156.934 157.994 154.302 156.754 150.328C155.514 146.354 157.581 142.587 161.404 141.916C165.227 141.245 169.36 143.877 170.6 147.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M170.441 147.541C171.681 151.463 169.614 155.179 165.843 155.902C162.072 156.624 157.991 153.992 156.751 150.07C155.511 146.148 157.577 142.432 161.349 141.709C165.12 140.987 169.201 143.619 170.441 147.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M170.286 147.231C171.474 151.102 169.459 154.766 165.688 155.489C161.968 156.211 157.939 153.631 156.751 149.812C155.562 145.941 157.577 142.277 161.348 141.554C165.12 140.832 169.098 143.412 170.286 147.283V147.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M170.083 146.973C171.271 150.793 169.205 154.405 165.537 155.128C161.869 155.85 157.891 153.321 156.754 149.554C155.566 145.735 157.632 142.122 161.3 141.4C164.968 140.677 168.946 143.206 170.083 146.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M169.924 146.664C171.061 150.431 169.046 154.044 165.43 154.715C161.814 155.437 157.887 152.96 156.751 149.244C155.614 145.477 157.629 141.864 161.245 141.193C164.862 140.471 168.788 142.948 169.924 146.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M169.77 146.354C170.906 150.07 168.891 153.631 165.275 154.354C161.659 155.076 157.887 152.65 156.751 148.986C155.614 145.27 157.629 141.709 161.245 140.987C164.862 140.264 168.633 142.69 169.77 146.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M169.618 146.045C170.703 149.657 168.688 153.218 165.175 153.941C161.662 154.663 157.891 152.289 156.806 148.677C155.721 145.064 157.736 141.503 161.249 140.78C164.813 140.058 168.533 142.432 169.618 146.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M169.46 145.787C170.494 149.348 168.531 152.857 165.018 153.58C161.505 154.302 157.837 151.98 156.752 148.419C155.718 144.858 157.682 141.348 161.195 140.626C164.708 139.903 168.376 142.226 169.46 145.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M169.305 145.477C170.338 148.986 168.375 152.444 164.914 153.167C161.452 153.889 157.836 151.618 156.803 148.109C155.77 144.6 157.733 141.142 161.194 140.419C164.655 139.697 168.272 141.967 169.305 145.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M169.101 145.167C170.082 148.625 168.171 152.031 164.71 152.805C161.3 153.528 157.735 151.309 156.702 147.851C155.72 144.393 157.684 140.987 161.093 140.213C164.503 139.49 168.068 141.709 169.101 145.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M168.945 144.858C169.927 148.264 167.964 151.67 164.606 152.393C161.248 153.115 157.735 150.947 156.753 147.541C155.772 144.135 157.735 140.729 161.093 140.006C164.451 139.284 167.964 141.451 168.945 144.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M168.791 144.599C169.721 147.954 167.809 151.309 164.503 152.031C161.197 152.754 157.735 150.638 156.805 147.283C155.875 143.929 157.787 140.574 161.093 139.851C164.4 139.129 167.861 141.245 168.791 144.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M168.633 144.29C169.563 147.593 167.651 150.896 164.345 151.618C161.09 152.341 157.681 150.277 156.751 146.974C155.821 143.671 157.732 140.368 161.039 139.645C164.293 138.923 167.703 140.987 168.633 144.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M168.479 143.98C169.357 147.232 167.446 150.483 164.243 151.257C161.04 151.98 157.682 149.967 156.803 146.716C155.925 143.464 157.837 140.213 161.04 139.439C164.243 138.665 167.601 140.729 168.479 143.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M168.324 143.671C169.151 146.87 167.291 150.07 164.088 150.844C160.885 151.618 157.63 149.606 156.803 146.406C155.925 143.206 157.837 140.006 161.04 139.232C164.243 138.458 167.497 140.471 168.324 143.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M168.116 143.412C168.943 146.561 167.083 149.709 163.932 150.483C160.78 151.257 157.577 149.296 156.751 146.148C155.924 142.999 157.784 139.851 160.935 139.077C164.087 138.303 167.29 140.264 168.116 143.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M167.964 143.103C168.739 146.199 166.879 149.348 163.831 150.07C160.783 150.793 157.58 148.935 156.805 145.838C156.03 142.742 157.89 139.593 160.938 138.871C163.986 138.148 167.189 140.006 167.964 143.103Z"
          fill="#150B0B"
        />
        <path
          d="M167.807 142.793C168.582 145.838 166.723 148.935 163.675 149.657C160.626 150.38 157.527 148.573 156.804 145.528C156.08 142.484 157.888 139.387 160.936 138.664C163.984 137.89 167.084 139.748 167.807 142.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="156" y="137" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M166.667 139.606C168.888 141.825 168.888 145.438 166.667 147.657C164.445 149.877 160.829 149.877 158.607 147.657C156.386 145.438 156.386 141.825 158.607 139.606C160.829 137.387 164.445 137.387 166.667 139.606Z"
        fill="url(#paint35_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M167.34 138.935C169.923 141.516 169.923 145.748 167.34 148.328C164.757 150.909 160.52 150.909 157.937 148.328C155.354 145.748 155.354 141.516 157.937 138.935C160.52 136.355 164.757 136.355 167.34 138.935Z"
        fill="url(#paint36_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M165.481 139.606C167.6 141.722 167.6 145.18 165.481 147.296C163.363 149.412 159.902 149.412 157.784 147.296C155.666 145.18 155.666 141.722 157.784 139.606C159.902 137.49 163.363 137.49 165.481 139.606Z"
        fill="url(#paint37_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M164.603 148.844C163.053 148.38 162.433 146.057 163.156 143.683C163.879 141.309 165.688 139.761 167.237 140.226C168.787 140.69 169.407 143.013 168.684 145.387C167.961 147.761 166.153 149.309 164.603 148.844Z"
        fill="url(#paint38_linear_12021_246197)"
      />
      <g
        filter="url(#filter2_f_12021_246197)"
        style={{
          animationDelay: '1s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="143.789"
          y="124.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter3_f_12021_246197)">
        <rect x="158" y="138" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M70.4245 367.347C71.8194 371.579 69.7012 375.553 65.62 376.224C61.5904 376.895 57.1475 374.005 55.7526 369.773C54.3577 365.541 56.4759 361.567 60.5571 360.896C64.5867 360.225 69.0296 363.115 70.4245 367.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M70.2686 367.038C71.6635 371.218 69.4937 375.141 65.5157 375.811C61.4861 376.482 57.1466 373.644 55.7517 369.464C54.3568 365.283 56.5266 361.361 60.5046 360.69C64.5342 360.019 68.8738 362.858 70.2686 367.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M70.0623 366.728C71.4055 370.857 69.2874 374.728 65.3094 375.45C61.3315 376.121 57.0436 373.334 55.7004 369.205C54.3572 365.077 56.4753 361.206 60.4533 360.483C64.4312 359.813 68.7191 362.599 70.0623 366.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M69.9078 366.418C71.251 370.496 69.1328 374.366 65.2066 375.037C61.2803 375.708 57.044 372.973 55.7008 368.896C54.3576 364.819 56.4757 360.948 60.402 360.277C64.3283 359.606 68.5646 362.341 69.9078 366.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M69.7519 366.161C71.0434 370.186 68.977 374.005 65.1023 374.676C61.2277 375.347 57.0431 372.663 55.7516 368.638C54.46 364.612 56.5265 360.793 60.4011 360.122C64.2757 359.451 68.4603 362.135 69.7519 366.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M69.5995 365.851C70.8394 369.825 68.7729 373.592 64.95 374.263C61.127 374.934 56.9941 372.302 55.7542 368.328C54.5143 364.354 56.5808 360.587 60.4037 359.916C64.2267 359.245 68.3596 361.877 69.5995 365.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M69.441 365.541C70.6809 369.463 68.6144 373.179 64.8431 373.902C61.0718 374.624 56.9906 371.992 55.7507 368.07C54.5108 364.148 56.5773 360.432 60.3486 359.709C64.1199 358.987 68.2011 361.619 69.441 365.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M69.286 365.231C70.4742 369.102 68.4594 372.766 64.6881 373.489C60.9684 374.211 56.9388 371.631 55.7506 367.812C54.5624 363.941 56.5772 360.277 60.3485 359.554C64.1198 358.832 68.0977 361.412 69.286 365.283V365.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M69.0828 364.973C70.271 368.793 68.2046 372.405 64.5366 373.128C60.8686 373.85 56.8907 371.321 55.7541 367.554C54.5659 363.735 56.6324 360.122 60.3004 359.4C63.9683 358.677 67.9463 361.206 69.0828 364.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M68.9245 364.664C70.061 368.431 68.0462 372.044 64.4299 372.715C60.8136 373.437 56.8873 370.96 55.7508 367.244C54.6142 363.477 56.629 359.864 60.2453 359.193C63.8616 358.471 67.7879 360.948 68.9245 364.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M68.7695 364.354C69.9061 368.07 67.8912 371.631 64.2749 372.354C60.6586 373.076 56.8873 370.65 55.7508 366.986C54.6142 363.27 56.629 359.709 60.2453 358.987C63.8616 358.264 67.633 360.69 68.7695 364.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M68.6178 364.045C69.7027 367.657 67.6879 371.218 64.1749 371.941C60.6619 372.663 56.8906 370.289 55.8057 366.677C54.7208 363.064 56.7356 359.503 60.2486 358.78C63.8133 358.058 67.5329 360.432 68.6178 364.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M68.4605 363.787C69.4937 367.348 67.5305 370.857 64.0176 371.58C60.5046 372.302 56.8366 369.98 55.7517 366.419C54.7185 362.858 56.6816 359.348 60.1946 358.626C63.7076 357.903 67.3756 360.226 68.4605 363.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M68.3049 363.477C69.3381 366.986 67.375 370.444 63.9137 371.167C60.4523 371.889 56.836 369.618 55.8028 366.109C54.7695 362.6 56.7327 359.142 60.194 358.419C63.6554 357.697 67.2717 359.967 68.3049 363.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M68.1008 363.167C69.0824 366.625 67.1709 370.031 63.7096 370.805C60.2999 371.528 56.7353 369.309 55.702 365.851C54.7205 362.393 56.6836 358.987 60.0933 358.213C63.5029 357.49 67.0676 359.709 68.1008 363.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M67.9452 362.858C68.9268 366.264 66.9637 369.67 63.6057 370.393C60.2477 371.115 56.7347 368.947 55.7531 365.541C54.7715 362.135 56.7347 358.729 60.0927 358.006C63.4507 357.284 66.9637 359.451 67.9452 362.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M67.7908 362.599C68.7207 365.954 66.8093 369.309 63.5029 370.031C60.1966 370.754 56.7352 368.638 55.8053 365.283C54.8754 361.929 56.7869 358.574 60.0933 357.851C63.3996 357.129 66.8609 359.245 67.7908 362.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M67.6328 362.29C68.5627 365.593 66.6513 368.896 63.3449 369.618C60.0902 370.341 56.6806 368.277 55.7506 364.974C54.8207 361.671 56.7322 358.368 60.0386 357.645C63.2932 356.923 66.7029 358.987 67.6328 362.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M67.479 361.98C68.3572 365.232 66.4458 368.483 63.2427 369.257C60.0397 369.98 56.6817 367.967 55.8035 364.716C54.9252 361.464 56.8367 358.213 60.0397 357.439C63.2427 356.665 66.6007 358.729 67.479 361.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M67.324 361.671C68.1506 364.87 66.2908 368.07 63.0877 368.844C59.8847 369.618 56.63 367.606 55.8035 364.406C54.9252 361.206 56.8367 358.006 60.0397 357.232C63.2427 356.458 66.4974 358.471 67.324 361.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M67.1164 361.412C67.943 364.561 66.0832 367.709 62.9318 368.483C59.7805 369.257 56.5774 367.296 55.7508 364.148C54.9243 360.999 56.7841 357.851 59.9354 357.077C63.0868 356.303 66.2898 358.264 67.1164 361.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M66.9637 361.103C67.7386 364.199 65.8788 367.348 62.8307 368.07C59.7827 368.793 56.5797 366.935 55.8047 363.838C55.0298 360.742 56.8897 357.593 59.9377 356.871C62.9857 356.148 66.1887 358.006 66.9637 361.103Z"
          fill="#150B0B"
        />
        <path
          d="M66.8074 360.793C67.5824 363.838 65.7225 366.935 62.6745 367.657C59.6265 368.38 56.5268 366.573 55.8035 363.528C55.0802 360.484 56.8884 357.387 59.9364 356.664C62.9845 355.89 66.0842 357.748 66.8074 360.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="55" y="355" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M65.6667 357.606C67.8881 359.825 67.8881 363.438 65.6667 365.657C63.4452 367.877 59.8289 367.877 57.6075 365.657C55.386 363.438 55.386 359.825 57.6075 357.606C59.8289 355.387 63.4452 355.387 65.6667 357.606Z"
        fill="url(#paint39_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M66.3397 356.935C68.9228 359.516 68.9228 363.748 66.3397 366.328C63.7566 368.909 59.5204 368.909 56.9373 366.328C54.3542 363.748 54.3542 359.516 56.9373 356.935C59.5204 354.355 63.7566 354.355 66.3397 356.935Z"
        fill="url(#paint40_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M64.4815 357.606C66.5996 359.722 66.5996 363.18 64.4815 365.296C62.3634 367.412 58.902 367.412 56.7839 365.296C54.6658 363.18 54.6658 359.722 56.7839 357.606C58.902 355.49 62.3634 355.49 64.4815 357.606Z"
        fill="url(#paint41_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M63.6027 366.844C62.0529 366.38 61.4329 364.057 62.1562 361.683C62.8794 359.309 64.6876 357.761 66.2374 358.226C67.7873 358.69 68.4072 361.013 67.684 363.387C66.9607 365.761 65.1526 367.309 63.6027 366.844Z"
        fill="url(#paint42_linear_12021_246197)"
      />
      <g
        filter="url(#filter4_f_12021_246197)"
        style={{
          animationDelay: '3s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="42.7891"
          y="342.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter5_f_12021_246197)">
        <rect x="57" y="356" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M157.425 578.347C158.819 582.579 156.701 586.553 152.62 587.224C148.59 587.895 144.147 585.005 142.753 580.773C141.358 576.541 143.476 572.567 147.557 571.896C151.587 571.225 156.03 574.115 157.425 578.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M157.269 578.038C158.663 582.218 156.494 586.141 152.516 586.811C148.486 587.482 144.147 584.644 142.752 580.464C141.357 576.283 143.527 572.361 147.505 571.69C151.534 571.019 155.874 573.858 157.269 578.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M157.062 577.728C158.406 581.857 156.287 585.728 152.309 586.45C148.331 587.121 144.044 584.334 142.7 580.205C141.357 576.077 143.475 572.206 147.453 571.483C151.431 570.813 155.719 573.599 157.062 577.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M156.908 577.418C158.251 581.496 156.133 585.366 152.207 586.037C148.28 586.708 144.044 583.973 142.701 579.896C141.358 575.819 143.476 571.948 147.402 571.277C151.328 570.606 155.565 573.341 156.908 577.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M156.752 577.161C158.043 581.186 155.977 585.005 152.102 585.676C148.228 586.347 144.043 583.663 142.752 579.638C141.46 575.612 143.526 571.793 147.401 571.122C151.276 570.451 155.46 573.135 156.752 577.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M156.6 576.851C157.839 580.825 155.773 584.592 151.95 585.263C148.127 585.934 143.994 583.302 142.754 579.328C141.514 575.354 143.581 571.587 147.404 570.916C151.227 570.245 155.36 572.877 156.6 576.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M156.441 576.541C157.681 580.463 155.614 584.179 151.843 584.902C148.072 585.624 143.991 582.992 142.751 579.07C141.511 575.148 143.577 571.432 147.349 570.709C151.12 569.987 155.201 572.619 156.441 576.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M156.286 576.231C157.474 580.102 155.459 583.766 151.688 584.489C147.968 585.211 143.939 582.631 142.751 578.812C141.562 574.941 143.577 571.277 147.348 570.554C151.12 569.832 155.098 572.412 156.286 576.283V576.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M156.083 575.973C157.271 579.793 155.205 583.405 151.537 584.128C147.869 584.85 143.891 582.321 142.754 578.554C141.566 574.735 143.632 571.122 147.3 570.4C150.968 569.677 154.946 572.206 156.083 575.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M155.924 575.664C157.061 579.431 155.046 583.044 151.43 583.715C147.814 584.437 143.887 581.96 142.751 578.244C141.614 574.477 143.629 570.864 147.245 570.193C150.862 569.471 154.788 571.948 155.924 575.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M155.77 575.354C156.906 579.07 154.891 582.631 151.275 583.354C147.659 584.076 143.887 581.65 142.751 577.986C141.614 574.27 143.629 570.709 147.245 569.987C150.862 569.264 154.633 571.69 155.77 575.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M155.618 575.045C156.703 578.657 154.688 582.218 151.175 582.941C147.662 583.663 143.891 581.289 142.806 577.677C141.721 574.064 143.736 570.503 147.249 569.78C150.813 569.058 154.533 571.432 155.618 575.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M155.46 574.787C156.494 578.348 154.531 581.857 151.018 582.58C147.505 583.302 143.837 580.98 142.752 577.419C141.718 573.858 143.682 570.348 147.195 569.626C150.708 568.903 154.376 571.226 155.46 574.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M155.305 574.477C156.338 577.986 154.375 581.444 150.914 582.167C147.452 582.889 143.836 580.618 142.803 577.109C141.77 573.6 143.733 570.142 147.194 569.419C150.655 568.697 154.272 570.967 155.305 574.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M155.101 574.167C156.082 577.625 154.171 581.031 150.71 581.805C147.3 582.528 143.735 580.309 142.702 576.851C141.72 573.393 143.684 569.987 147.093 569.213C150.503 568.49 154.068 570.709 155.101 574.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M154.945 573.858C155.927 577.264 153.964 580.67 150.606 581.393C147.248 582.115 143.735 579.947 142.753 576.541C141.772 573.135 143.735 569.729 147.093 569.006C150.451 568.284 153.964 570.451 154.945 573.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M154.791 573.599C155.721 576.954 153.809 580.309 150.503 581.031C147.197 581.754 143.735 579.638 142.805 576.283C141.875 572.929 143.787 569.574 147.093 568.851C150.4 568.129 153.861 570.245 154.791 573.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M154.633 573.29C155.563 576.593 153.651 579.896 150.345 580.618C147.09 581.341 143.681 579.277 142.751 575.974C141.821 572.671 143.732 569.368 147.039 568.645C150.293 567.923 153.703 569.987 154.633 573.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M154.479 572.98C155.357 576.232 153.446 579.483 150.243 580.257C147.04 580.98 143.682 578.967 142.803 575.716C141.925 572.464 143.837 569.213 147.04 568.439C150.243 567.665 153.601 569.729 154.479 572.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M154.324 572.671C155.151 575.87 153.291 579.07 150.088 579.844C146.885 580.618 143.63 578.606 142.803 575.406C141.925 572.206 143.837 569.006 147.04 568.232C150.243 567.458 153.497 569.471 154.324 572.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M154.116 572.412C154.943 575.561 153.083 578.709 149.932 579.483C146.78 580.257 143.577 578.296 142.751 575.148C141.924 571.999 143.784 568.851 146.935 568.077C150.087 567.303 153.29 569.264 154.116 572.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M153.964 572.103C154.739 575.199 152.879 578.348 149.831 579.07C146.783 579.793 143.58 577.935 142.805 574.838C142.03 571.742 143.89 568.593 146.938 567.871C149.986 567.148 153.189 569.006 153.964 572.103Z"
          fill="#150B0B"
        />
        <path
          d="M153.807 571.793C154.582 574.838 152.723 577.935 149.675 578.657C146.626 579.38 143.527 577.573 142.804 574.528C142.08 571.484 143.888 568.387 146.936 567.664C149.984 566.89 153.084 568.748 153.807 571.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="142" y="566" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M152.667 568.606C154.888 570.825 154.888 574.438 152.667 576.657C150.445 578.877 146.829 578.877 144.607 576.657C142.386 574.438 142.386 570.825 144.607 568.606C146.829 566.387 150.445 566.387 152.667 568.606Z"
        fill="url(#paint43_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M153.34 567.935C155.923 570.516 155.923 574.748 153.34 577.328C150.757 579.909 146.52 579.909 143.937 577.328C141.354 574.748 141.354 570.516 143.937 567.935C146.52 565.355 150.757 565.355 153.34 567.935Z"
        fill="url(#paint44_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M151.481 568.606C153.6 570.722 153.6 574.18 151.481 576.296C149.363 578.412 145.902 578.412 143.784 576.296C141.666 574.18 141.666 570.722 143.784 568.606C145.902 566.49 149.363 566.49 151.481 568.606Z"
        fill="url(#paint45_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M150.603 577.844C149.053 577.38 148.433 575.057 149.156 572.683C149.879 570.309 151.688 568.761 153.237 569.226C154.787 569.69 155.407 572.013 154.684 574.387C153.961 576.761 152.153 578.309 150.603 577.844Z"
        fill="url(#paint46_linear_12021_246197)"
      />
      <g
        filter="url(#filter6_f_12021_246197)"
        style={{
          animationDelay: '1s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="129.789"
          y="553.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter7_f_12021_246197)">
        <rect x="144" y="567" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M369.425 665.347C370.819 669.579 368.701 673.553 364.62 674.224C360.59 674.895 356.147 672.005 354.753 667.773C353.358 663.541 355.476 659.567 359.557 658.896C363.587 658.225 368.03 661.115 369.425 665.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M369.269 665.038C370.663 669.218 368.494 673.141 364.516 673.811C360.486 674.482 356.147 671.644 354.752 667.464C353.357 663.283 355.527 659.361 359.505 658.69C363.534 658.019 367.874 660.858 369.269 665.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M369.062 664.728C370.406 668.857 368.287 672.728 364.309 673.45C360.331 674.121 356.044 671.334 354.7 667.205C353.357 663.077 355.475 659.206 359.453 658.483C363.431 657.813 367.719 660.599 369.062 664.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M368.908 664.418C370.251 668.496 368.133 672.366 364.207 673.037C360.28 673.708 356.044 670.973 354.701 666.896C353.358 662.819 355.476 658.948 359.402 658.277C363.328 657.606 367.565 660.341 368.908 664.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M368.752 664.161C370.043 668.186 367.977 672.005 364.102 672.676C360.228 673.347 356.043 670.663 354.752 666.638C353.46 662.612 355.526 658.793 359.401 658.122C363.276 657.451 367.46 660.135 368.752 664.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M368.6 663.851C369.839 667.825 367.773 671.592 363.95 672.263C360.127 672.934 355.994 670.302 354.754 666.328C353.514 662.354 355.581 658.587 359.404 657.916C363.227 657.245 367.36 659.877 368.6 663.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M368.441 663.541C369.681 667.463 367.614 671.179 363.843 671.902C360.072 672.624 355.991 669.992 354.751 666.07C353.511 662.148 355.577 658.432 359.349 657.709C363.12 656.987 367.201 659.619 368.441 663.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M368.286 663.231C369.474 667.102 367.459 670.766 363.688 671.489C359.968 672.211 355.939 669.631 354.751 665.812C353.562 661.941 355.577 658.277 359.348 657.554C363.12 656.832 367.098 659.412 368.286 663.283V663.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M368.083 662.973C369.271 666.793 367.205 670.405 363.537 671.128C359.869 671.85 355.891 669.321 354.754 665.554C353.566 661.735 355.632 658.122 359.3 657.4C362.968 656.677 366.946 659.206 368.083 662.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M367.924 662.664C369.061 666.431 367.046 670.044 363.43 670.715C359.814 671.437 355.887 668.96 354.751 665.244C353.614 661.477 355.629 657.864 359.245 657.193C362.862 656.471 366.788 658.948 367.924 662.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M367.77 662.354C368.906 666.07 366.891 669.631 363.275 670.354C359.659 671.076 355.887 668.65 354.751 664.986C353.614 661.27 355.629 657.709 359.245 656.987C362.862 656.264 366.633 658.69 367.77 662.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M367.618 662.045C368.703 665.657 366.688 669.218 363.175 669.941C359.662 670.663 355.891 668.289 354.806 664.677C353.721 661.064 355.736 657.503 359.249 656.78C362.813 656.058 366.533 658.432 367.618 662.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M367.46 661.787C368.494 665.348 366.531 668.857 363.018 669.58C359.505 670.302 355.837 667.98 354.752 664.419C353.718 660.858 355.682 657.348 359.195 656.626C362.708 655.903 366.376 658.226 367.46 661.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M367.305 661.477C368.338 664.986 366.375 668.444 362.914 669.167C359.452 669.889 355.836 667.618 354.803 664.109C353.77 660.6 355.733 657.142 359.194 656.419C362.655 655.697 366.272 657.967 367.305 661.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M367.101 661.167C368.082 664.625 366.171 668.031 362.71 668.805C359.3 669.528 355.735 667.309 354.702 663.851C353.72 660.393 355.684 656.987 359.093 656.213C362.503 655.49 366.068 657.709 367.101 661.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M366.945 660.858C367.927 664.264 365.964 667.67 362.606 668.393C359.248 669.115 355.735 666.947 354.753 663.541C353.772 660.135 355.735 656.729 359.093 656.006C362.451 655.284 365.964 657.451 366.945 660.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M366.791 660.599C367.721 663.954 365.809 667.309 362.503 668.031C359.197 668.754 355.735 666.638 354.805 663.283C353.875 659.929 355.787 656.574 359.093 655.851C362.4 655.129 365.861 657.245 366.791 660.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M366.633 660.29C367.563 663.593 365.651 666.896 362.345 667.618C359.09 668.341 355.681 666.277 354.751 662.974C353.821 659.671 355.732 656.368 359.039 655.645C362.293 654.923 365.703 656.987 366.633 660.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M366.479 659.98C367.357 663.232 365.446 666.483 362.243 667.257C359.04 667.98 355.682 665.967 354.803 662.716C353.925 659.464 355.837 656.213 359.04 655.439C362.243 654.665 365.601 656.729 366.479 659.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M366.324 659.671C367.151 662.87 365.291 666.07 362.088 666.844C358.885 667.618 355.63 665.606 354.803 662.406C353.925 659.206 355.837 656.006 359.04 655.232C362.243 654.458 365.497 656.471 366.324 659.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M366.116 659.412C366.943 662.561 365.083 665.709 361.932 666.483C358.78 667.257 355.577 665.296 354.751 662.148C353.924 658.999 355.784 655.851 358.935 655.077C362.087 654.303 365.29 656.264 366.116 659.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M365.964 659.103C366.739 662.199 364.879 665.348 361.831 666.07C358.783 666.793 355.58 664.935 354.805 661.838C354.03 658.742 355.89 655.593 358.938 654.871C361.986 654.148 365.189 656.006 365.964 659.103Z"
          fill="#150B0B"
        />
        <path
          d="M365.807 658.793C366.582 661.838 364.723 664.935 361.675 665.657C358.626 666.38 355.527 664.573 354.804 661.528C354.08 658.484 355.888 655.387 358.936 654.664C361.984 653.89 365.084 655.748 365.807 658.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="354" y="653" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M364.667 655.606C366.888 657.825 366.888 661.438 364.667 663.657C362.445 665.877 358.829 665.877 356.607 663.657C354.386 661.438 354.386 657.825 356.607 655.606C358.829 653.387 362.445 653.387 364.667 655.606Z"
        fill="url(#paint47_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M365.34 654.935C367.923 657.516 367.923 661.748 365.34 664.328C362.757 666.909 358.52 666.909 355.937 664.328C353.354 661.748 353.354 657.516 355.937 654.935C358.52 652.355 362.757 652.355 365.34 654.935Z"
        fill="url(#paint48_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M363.481 655.606C365.6 657.722 365.6 661.18 363.481 663.296C361.363 665.412 357.902 665.412 355.784 663.296C353.666 661.18 353.666 657.722 355.784 655.606C357.902 653.49 361.363 653.49 363.481 655.606Z"
        fill="url(#paint49_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M362.603 664.844C361.053 664.38 360.433 662.057 361.156 659.683C361.879 657.309 363.688 655.761 365.237 656.226C366.787 656.69 367.407 659.013 366.684 661.387C365.961 663.761 364.153 665.309 362.603 664.844Z"
        fill="url(#paint50_linear_12021_246197)"
      />
      <g
        filter="url(#filter8_f_12021_246197)"
        style={{
          animationDelay: '3s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="341.789"
          y="640.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter9_f_12021_246197)">
        <rect x="356" y="654" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M580.425 577.347C581.819 581.579 579.701 585.553 575.62 586.224C571.59 586.895 567.147 584.005 565.753 579.773C564.358 575.541 566.476 571.567 570.557 570.896C574.587 570.225 579.03 573.115 580.425 577.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M580.269 577.038C581.663 581.218 579.494 585.141 575.516 585.811C571.486 586.482 567.147 583.644 565.752 579.464C564.357 575.283 566.527 571.361 570.505 570.69C574.534 570.019 578.874 572.858 580.269 577.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M580.062 576.728C581.406 580.857 579.287 584.728 575.309 585.45C571.331 586.121 567.044 583.334 565.7 579.205C564.357 575.077 566.475 571.206 570.453 570.483C574.431 569.813 578.719 572.599 580.062 576.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M579.908 576.418C581.251 580.496 579.133 584.366 575.207 585.037C571.28 585.708 567.044 582.973 565.701 578.896C564.358 574.819 566.476 570.948 570.402 570.277C574.328 569.606 578.565 572.341 579.908 576.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M579.752 576.161C581.043 580.186 578.977 584.005 575.102 584.676C571.228 585.347 567.043 582.663 565.752 578.638C564.46 574.612 566.526 570.793 570.401 570.122C574.276 569.451 578.46 572.135 579.752 576.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M579.6 575.851C580.839 579.825 578.773 583.592 574.95 584.263C571.127 584.934 566.994 582.302 565.754 578.328C564.514 574.354 566.581 570.587 570.404 569.916C574.227 569.245 578.36 571.877 579.6 575.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M579.441 575.541C580.681 579.463 578.614 583.179 574.843 583.902C571.072 584.624 566.991 581.992 565.751 578.07C564.511 574.148 566.577 570.432 570.349 569.709C574.12 568.987 578.201 571.619 579.441 575.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M579.286 575.231C580.474 579.102 578.459 582.766 574.688 583.489C570.968 584.211 566.939 581.631 565.751 577.812C564.562 573.941 566.577 570.277 570.348 569.554C574.12 568.832 578.098 571.412 579.286 575.283V575.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M579.083 574.973C580.271 578.793 578.205 582.405 574.537 583.128C570.869 583.85 566.891 581.321 565.754 577.554C564.566 573.735 566.632 570.122 570.3 569.4C573.968 568.677 577.946 571.206 579.083 574.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M578.924 574.664C580.061 578.431 578.046 582.044 574.43 582.715C570.814 583.437 566.887 580.96 565.751 577.244C564.614 573.477 566.629 569.864 570.245 569.193C573.862 568.471 577.788 570.948 578.924 574.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M578.77 574.354C579.906 578.07 577.891 581.631 574.275 582.354C570.659 583.076 566.887 580.65 565.751 576.986C564.614 573.27 566.629 569.709 570.245 568.987C573.862 568.264 577.633 570.69 578.77 574.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M578.618 574.045C579.703 577.657 577.688 581.218 574.175 581.941C570.662 582.663 566.891 580.289 565.806 576.677C564.721 573.064 566.736 569.503 570.249 568.78C573.813 568.058 577.533 570.432 578.618 574.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M578.46 573.787C579.494 577.348 577.531 580.857 574.018 581.58C570.505 582.302 566.837 579.98 565.752 576.419C564.718 572.858 566.682 569.348 570.195 568.626C573.708 567.903 577.376 570.226 578.46 573.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M578.305 573.477C579.338 576.986 577.375 580.444 573.914 581.167C570.452 581.889 566.836 579.618 565.803 576.109C564.77 572.6 566.733 569.142 570.194 568.419C573.655 567.697 577.272 569.967 578.305 573.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M578.101 573.167C579.082 576.625 577.171 580.031 573.71 580.805C570.3 581.528 566.735 579.309 565.702 575.851C564.72 572.393 566.684 568.987 570.093 568.213C573.503 567.49 577.068 569.709 578.101 573.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M577.945 572.858C578.927 576.264 576.964 579.67 573.606 580.393C570.248 581.115 566.735 578.947 565.753 575.541C564.772 572.135 566.735 568.729 570.093 568.006C573.451 567.284 576.964 569.451 577.945 572.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M577.791 572.599C578.721 575.954 576.809 579.309 573.503 580.031C570.197 580.754 566.735 578.638 565.805 575.283C564.875 571.929 566.787 568.574 570.093 567.851C573.4 567.129 576.861 569.245 577.791 572.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M577.633 572.29C578.563 575.593 576.651 578.896 573.345 579.618C570.09 580.341 566.681 578.277 565.751 574.974C564.821 571.671 566.732 568.368 570.039 567.645C573.293 566.923 576.703 568.987 577.633 572.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M577.479 571.98C578.357 575.232 576.446 578.483 573.243 579.257C570.04 579.98 566.682 577.967 565.803 574.716C564.925 571.464 566.837 568.213 570.04 567.439C573.243 566.665 576.601 568.729 577.479 571.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M577.324 571.671C578.151 574.87 576.291 578.07 573.088 578.844C569.885 579.618 566.63 577.606 565.803 574.406C564.925 571.206 566.837 568.006 570.04 567.232C573.243 566.458 576.497 568.471 577.324 571.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M577.116 571.412C577.943 574.561 576.083 577.709 572.932 578.483C569.78 579.257 566.577 577.296 565.751 574.148C564.924 570.999 566.784 567.851 569.935 567.077C573.087 566.303 576.29 568.264 577.116 571.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M576.964 571.103C577.739 574.199 575.879 577.348 572.831 578.07C569.783 578.793 566.58 576.935 565.805 573.838C565.03 570.742 566.89 567.593 569.938 566.871C572.986 566.148 576.189 568.006 576.964 571.103Z"
          fill="#150B0B"
        />
        <path
          d="M576.807 570.793C577.582 573.838 575.723 576.935 572.675 577.657C569.626 578.38 566.527 576.573 565.804 573.528C565.08 570.484 566.888 567.387 569.936 566.664C572.984 565.89 576.084 567.748 576.807 570.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="565" y="565" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M575.667 567.606C577.888 569.825 577.888 573.438 575.667 575.657C573.445 577.877 569.829 577.877 567.607 575.657C565.386 573.438 565.386 569.825 567.607 567.606C569.829 565.387 573.445 565.387 575.667 567.606Z"
        fill="url(#paint51_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M576.34 566.935C578.923 569.516 578.923 573.748 576.34 576.328C573.757 578.909 569.52 578.909 566.937 576.328C564.354 573.748 564.354 569.516 566.937 566.935C569.52 564.355 573.757 564.355 576.34 566.935Z"
        fill="url(#paint52_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M574.481 567.606C576.6 569.722 576.6 573.18 574.481 575.296C572.363 577.412 568.902 577.412 566.784 575.296C564.666 573.18 564.666 569.722 566.784 567.606C568.902 565.49 572.363 565.49 574.481 567.606Z"
        fill="url(#paint53_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M573.603 576.844C572.053 576.38 571.433 574.057 572.156 571.683C572.879 569.309 574.688 567.761 576.237 568.226C577.787 568.69 578.407 571.013 577.684 573.387C576.961 575.761 575.153 577.309 573.603 576.844Z"
        fill="url(#paint54_linear_12021_246197)"
      />
      <g
        filter="url(#filter10_f_12021_246197)"
        style={{
          animationDelay: '1s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="552.789"
          y="552.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter11_f_12021_246197)">
        <rect x="567" y="566" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M667.425 366.347C668.819 370.579 666.701 374.553 662.62 375.224C658.59 375.895 654.147 373.005 652.753 368.773C651.358 364.541 653.476 360.567 657.557 359.896C661.587 359.225 666.03 362.115 667.425 366.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M667.269 366.038C668.663 370.218 666.494 374.141 662.516 374.811C658.486 375.482 654.147 372.644 652.752 368.464C651.357 364.283 653.527 360.361 657.505 359.69C661.534 359.019 665.874 361.858 667.269 366.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M667.062 365.728C668.406 369.857 666.287 373.728 662.309 374.45C658.331 375.121 654.044 372.334 652.7 368.205C651.357 364.077 653.475 360.206 657.453 359.483C661.431 358.813 665.719 361.599 667.062 365.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M666.908 365.418C668.251 369.496 666.133 373.366 662.207 374.037C658.28 374.708 654.044 371.973 652.701 367.896C651.358 363.819 653.476 359.948 657.402 359.277C661.328 358.606 665.565 361.341 666.908 365.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M666.752 365.161C668.043 369.186 665.977 373.005 662.102 373.676C658.228 374.347 654.043 371.663 652.752 367.638C651.46 363.612 653.526 359.793 657.401 359.122C661.276 358.451 665.46 361.135 666.752 365.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M666.6 364.851C667.839 368.825 665.773 372.592 661.95 373.263C658.127 373.934 653.994 371.302 652.754 367.328C651.514 363.354 653.581 359.587 657.404 358.916C661.227 358.245 665.36 360.877 666.6 364.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M666.441 364.541C667.681 368.463 665.614 372.179 661.843 372.902C658.072 373.624 653.991 370.992 652.751 367.07C651.511 363.148 653.577 359.432 657.349 358.709C661.12 357.987 665.201 360.619 666.441 364.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M666.286 364.231C667.474 368.102 665.459 371.766 661.688 372.489C657.968 373.211 653.939 370.631 652.751 366.812C651.562 362.941 653.577 359.277 657.348 358.554C661.12 357.832 665.098 360.412 666.286 364.283V364.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M666.083 363.973C667.271 367.793 665.205 371.405 661.537 372.128C657.869 372.85 653.891 370.321 652.754 366.554C651.566 362.735 653.632 359.122 657.3 358.4C660.968 357.677 664.946 360.206 666.083 363.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M665.924 363.664C667.061 367.431 665.046 371.044 661.43 371.715C657.814 372.437 653.887 369.96 652.751 366.244C651.614 362.477 653.629 358.864 657.245 358.193C660.862 357.471 664.788 359.948 665.924 363.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M665.77 363.354C666.906 367.07 664.891 370.631 661.275 371.354C657.659 372.076 653.887 369.65 652.751 365.986C651.614 362.27 653.629 358.709 657.245 357.987C660.862 357.264 664.633 359.69 665.77 363.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M665.618 363.045C666.703 366.657 664.688 370.218 661.175 370.941C657.662 371.663 653.891 369.289 652.806 365.677C651.721 362.064 653.736 358.503 657.249 357.78C660.813 357.058 664.533 359.432 665.618 363.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M665.46 362.787C666.494 366.348 664.531 369.857 661.018 370.58C657.505 371.302 653.837 368.98 652.752 365.419C651.718 361.858 653.682 358.348 657.195 357.626C660.708 356.903 664.376 359.226 665.46 362.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M665.305 362.477C666.338 365.986 664.375 369.444 660.914 370.167C657.452 370.889 653.836 368.618 652.803 365.109C651.77 361.6 653.733 358.142 657.194 357.419C660.655 356.697 664.272 358.967 665.305 362.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M665.101 362.167C666.082 365.625 664.171 369.031 660.71 369.805C657.3 370.528 653.735 368.309 652.702 364.851C651.72 361.393 653.684 357.987 657.093 357.213C660.503 356.49 664.068 358.709 665.101 362.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M664.945 361.858C665.927 365.264 663.964 368.67 660.606 369.393C657.248 370.115 653.735 367.947 652.753 364.541C651.772 361.135 653.735 357.729 657.093 357.006C660.451 356.284 663.964 358.451 664.945 361.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M664.791 361.599C665.721 364.954 663.809 368.309 660.503 369.031C657.197 369.754 653.735 367.638 652.805 364.283C651.875 360.929 653.787 357.574 657.093 356.851C660.4 356.129 663.861 358.245 664.791 361.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M664.633 361.29C665.563 364.593 663.651 367.896 660.345 368.618C657.09 369.341 653.681 367.277 652.751 363.974C651.821 360.671 653.732 357.368 657.039 356.645C660.293 355.923 663.703 357.987 664.633 361.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M664.479 360.98C665.357 364.232 663.446 367.483 660.243 368.257C657.04 368.98 653.682 366.967 652.803 363.716C651.925 360.464 653.837 357.213 657.04 356.439C660.243 355.665 663.601 357.729 664.479 360.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M664.324 360.671C665.151 363.87 663.291 367.07 660.088 367.844C656.885 368.618 653.63 366.606 652.803 363.406C651.925 360.206 653.837 357.006 657.04 356.232C660.243 355.458 663.497 357.471 664.324 360.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M664.116 360.412C664.943 363.561 663.083 366.709 659.932 367.483C656.78 368.257 653.577 366.296 652.751 363.148C651.924 359.999 653.784 356.851 656.935 356.077C660.087 355.303 663.29 357.264 664.116 360.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M663.964 360.103C664.739 363.199 662.879 366.348 659.831 367.07C656.783 367.793 653.58 365.935 652.805 362.838C652.03 359.742 653.89 356.593 656.938 355.871C659.986 355.148 663.189 357.006 663.964 360.103Z"
          fill="#150B0B"
        />
        <path
          d="M663.807 359.793C664.582 362.838 662.723 365.935 659.675 366.657C656.626 367.38 653.527 365.573 652.804 362.528C652.08 359.484 653.888 356.387 656.936 355.664C659.984 354.89 663.084 356.748 663.807 359.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="652" y="354" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M662.667 356.606C664.888 358.825 664.888 362.438 662.667 364.657C660.445 366.877 656.829 366.877 654.607 364.657C652.386 362.438 652.386 358.825 654.607 356.606C656.829 354.387 660.445 354.387 662.667 356.606Z"
        fill="url(#paint55_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M663.34 355.935C665.923 358.516 665.923 362.748 663.34 365.328C660.757 367.909 656.52 367.909 653.937 365.328C651.354 362.748 651.354 358.516 653.937 355.935C656.52 353.355 660.757 353.355 663.34 355.935Z"
        fill="url(#paint56_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M661.481 356.606C663.6 358.722 663.6 362.18 661.481 364.296C659.363 366.412 655.902 366.412 653.784 364.296C651.666 362.18 651.666 358.722 653.784 356.606C655.902 354.49 659.363 354.49 661.481 356.606Z"
        fill="url(#paint57_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M660.603 365.844C659.053 365.38 658.433 363.057 659.156 360.683C659.879 358.309 661.688 356.761 663.237 357.226C664.787 357.69 665.407 360.013 664.684 362.387C663.961 364.761 662.153 366.309 660.603 365.844Z"
        fill="url(#paint58_linear_12021_246197)"
      />
      <g
        filter="url(#filter12_f_12021_246197)"
        style={{
          animationDelay: '3s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="639.789"
          y="341.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter13_f_12021_246197)">
        <rect x="654" y="355" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M579.425 155.347C580.819 159.579 578.701 163.553 574.62 164.224C570.59 164.895 566.147 162.005 564.753 157.773C563.358 153.541 565.476 149.567 569.557 148.896C573.587 148.225 578.03 151.115 579.425 155.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M579.269 155.038C580.663 159.218 578.494 163.141 574.516 163.811C570.486 164.482 566.147 161.644 564.752 157.464C563.357 153.283 565.527 149.361 569.505 148.69C573.534 148.019 577.874 150.858 579.269 155.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M579.062 154.728C580.406 158.857 578.287 162.728 574.309 163.45C570.331 164.121 566.044 161.334 564.7 157.205C563.357 153.077 565.475 149.206 569.453 148.483C573.431 147.813 577.719 150.599 579.062 154.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M578.908 154.418C580.251 158.496 578.133 162.366 574.207 163.037C570.28 163.708 566.044 160.973 564.701 156.896C563.358 152.819 565.476 148.948 569.402 148.277C573.328 147.606 577.565 150.341 578.908 154.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M578.752 154.161C580.043 158.186 577.977 162.005 574.102 162.676C570.228 163.347 566.043 160.663 564.752 156.638C563.46 152.612 565.526 148.793 569.401 148.122C573.276 147.451 577.46 150.135 578.752 154.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M578.6 153.851C579.839 157.825 577.773 161.592 573.95 162.263C570.127 162.934 565.994 160.302 564.754 156.328C563.514 152.354 565.581 148.587 569.404 147.916C573.227 147.245 577.36 149.877 578.6 153.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M578.441 153.541C579.681 157.463 577.614 161.179 573.843 161.902C570.072 162.624 565.991 159.992 564.751 156.07C563.511 152.148 565.577 148.432 569.349 147.709C573.12 146.987 577.201 149.619 578.441 153.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M578.286 153.231C579.474 157.102 577.459 160.766 573.688 161.489C569.968 162.211 565.939 159.631 564.751 155.812C563.562 151.941 565.577 148.277 569.348 147.554C573.12 146.832 577.098 149.412 578.286 153.283V153.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M578.083 152.973C579.271 156.793 577.205 160.405 573.537 161.128C569.869 161.85 565.891 159.321 564.754 155.554C563.566 151.735 565.632 148.122 569.3 147.4C572.968 146.677 576.946 149.206 578.083 152.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M577.924 152.664C579.061 156.431 577.046 160.044 573.43 160.715C569.814 161.437 565.887 158.96 564.751 155.244C563.614 151.477 565.629 147.864 569.245 147.193C572.862 146.471 576.788 148.948 577.924 152.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M577.77 152.354C578.906 156.07 576.891 159.631 573.275 160.354C569.659 161.076 565.887 158.65 564.751 154.986C563.614 151.27 565.629 147.709 569.245 146.987C572.862 146.264 576.633 148.69 577.77 152.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M577.618 152.045C578.703 155.657 576.688 159.218 573.175 159.941C569.662 160.663 565.891 158.289 564.806 154.677C563.721 151.064 565.736 147.503 569.249 146.78C572.813 146.058 576.533 148.432 577.618 152.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M577.46 151.787C578.494 155.348 576.531 158.857 573.018 159.58C569.505 160.302 565.837 157.98 564.752 154.419C563.718 150.858 565.682 147.348 569.195 146.626C572.708 145.903 576.376 148.226 577.46 151.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M577.305 151.477C578.338 154.986 576.375 158.444 572.914 159.167C569.452 159.889 565.836 157.618 564.803 154.109C563.77 150.6 565.733 147.142 569.194 146.419C572.655 145.697 576.272 147.967 577.305 151.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M577.101 151.167C578.082 154.625 576.171 158.031 572.71 158.805C569.3 159.528 565.735 157.309 564.702 153.851C563.72 150.393 565.684 146.987 569.093 146.213C572.503 145.49 576.068 147.709 577.101 151.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M576.945 150.858C577.927 154.264 575.964 157.67 572.606 158.393C569.248 159.115 565.735 156.947 564.753 153.541C563.772 150.135 565.735 146.729 569.093 146.006C572.451 145.284 575.964 147.451 576.945 150.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M576.791 150.599C577.721 153.954 575.809 157.309 572.503 158.031C569.197 158.754 565.735 156.638 564.805 153.283C563.875 149.929 565.787 146.574 569.093 145.851C572.4 145.129 575.861 147.245 576.791 150.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M576.633 150.29C577.563 153.593 575.651 156.896 572.345 157.618C569.09 158.341 565.681 156.277 564.751 152.974C563.821 149.671 565.732 146.368 569.039 145.645C572.293 144.923 575.703 146.987 576.633 150.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M576.479 149.98C577.357 153.232 575.446 156.483 572.243 157.257C569.04 157.98 565.682 155.967 564.803 152.716C563.925 149.464 565.837 146.213 569.04 145.439C572.243 144.665 575.601 146.729 576.479 149.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M576.324 149.671C577.151 152.87 575.291 156.07 572.088 156.844C568.885 157.618 565.63 155.606 564.803 152.406C563.925 149.206 565.837 146.006 569.04 145.232C572.243 144.458 575.497 146.471 576.324 149.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M576.116 149.412C576.943 152.561 575.083 155.709 571.932 156.483C568.78 157.257 565.577 155.296 564.751 152.148C563.924 148.999 565.784 145.851 568.935 145.077C572.087 144.303 575.29 146.264 576.116 149.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M575.964 149.103C576.739 152.199 574.879 155.348 571.831 156.07C568.783 156.793 565.58 154.935 564.805 151.838C564.03 148.742 565.89 145.593 568.938 144.871C571.986 144.148 575.189 146.006 575.964 149.103Z"
          fill="#150B0B"
        />
        <path
          d="M575.807 148.793C576.582 151.838 574.723 154.935 571.675 155.657C568.626 156.38 565.527 154.573 564.804 151.528C564.08 148.484 565.888 145.387 568.936 144.664C571.984 143.89 575.084 145.748 575.807 148.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="564" y="143" width="13" height="13" rx="6.5" fill="#FCB231" />
      <path
        d="M574.667 145.606C576.888 147.825 576.888 151.438 574.667 153.657C572.445 155.877 568.829 155.877 566.607 153.657C564.386 151.438 564.386 147.825 566.607 145.606C568.829 143.387 572.445 143.387 574.667 145.606Z"
        fill="url(#paint59_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M575.34 144.935C577.923 147.516 577.923 151.748 575.34 154.328C572.757 156.909 568.52 156.909 565.937 154.328C563.354 151.748 563.354 147.516 565.937 144.935C568.52 142.355 572.757 142.355 575.34 144.935Z"
        fill="url(#paint60_radial_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M573.481 145.606C575.6 147.722 575.6 151.18 573.481 153.296C571.363 155.412 567.902 155.412 565.784 153.296C563.666 151.18 563.666 147.722 565.784 145.606C567.902 143.49 571.363 143.49 573.481 145.606Z"
        fill="url(#paint61_linear_12021_246197)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M572.603 154.844C571.053 154.38 570.433 152.057 571.156 149.683C571.879 147.309 573.688 145.761 575.237 146.226C576.787 146.69 577.407 149.013 576.684 151.387C575.961 153.761 574.153 155.309 572.603 154.844Z"
        fill="url(#paint62_linear_12021_246197)"
      />
      <g
        filter="url(#filter14_f_12021_246197)"
        style={{
          animationDelay: '1s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="551.789"
          y="130.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter15_f_12021_246197)">
        <rect x="566" y="144" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M93.4245 250.347C94.8194 254.579 92.7012 258.553 88.62 259.224C84.5904 259.895 80.1475 257.005 78.7526 252.773C77.3577 248.541 79.4759 244.567 83.5571 243.896C87.5867 243.225 92.0296 246.115 93.4245 250.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M93.2686 250.038C94.6635 254.218 92.4937 258.141 88.5157 258.811C84.4861 259.482 80.1466 256.644 78.7517 252.464C77.3568 248.283 79.5266 244.361 83.5046 243.69C87.5342 243.019 91.8738 245.858 93.2686 250.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M93.0623 249.728C94.4055 253.857 92.2874 257.728 88.3094 258.45C84.3315 259.121 80.0436 256.334 78.7004 252.205C77.3572 248.077 79.4753 244.206 83.4533 243.483C87.4312 242.813 91.7191 245.599 93.0623 249.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M92.9078 249.418C94.251 253.496 92.1328 257.366 88.2066 258.037C84.2803 258.708 80.044 255.973 78.7008 251.896C77.3576 247.819 79.4757 243.948 83.402 243.277C87.3283 242.606 91.5646 245.341 92.9078 249.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M92.7519 249.161C94.0434 253.186 91.977 257.005 88.1023 257.676C84.2277 258.347 80.0431 255.663 78.7516 251.638C77.46 247.612 79.5265 243.793 83.4011 243.122C87.2757 242.451 91.4603 245.135 92.7519 249.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M92.5995 248.851C93.8394 252.825 91.7729 256.592 87.95 257.263C84.127 257.934 79.9941 255.302 78.7542 251.328C77.5143 247.354 79.5808 243.587 83.4037 242.916C87.2267 242.245 91.3596 244.877 92.5995 248.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M92.441 248.541C93.6809 252.463 91.6144 256.179 87.8431 256.902C84.0718 257.624 79.9906 254.992 78.7507 251.07C77.5108 247.148 79.5773 243.432 83.3486 242.709C87.1199 241.987 91.2011 244.619 92.441 248.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M92.286 248.231C93.4742 252.102 91.4594 255.766 87.6881 256.489C83.9684 257.211 79.9388 254.631 78.7506 250.812C77.5624 246.941 79.5772 243.277 83.3485 242.554C87.1198 241.832 91.0977 244.412 92.286 248.283V248.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M92.0828 247.973C93.271 251.793 91.2046 255.405 87.5366 256.128C83.8686 256.85 79.8907 254.321 78.7541 250.554C77.5659 246.735 79.6324 243.122 83.3004 242.4C86.9683 241.677 90.9463 244.206 92.0828 247.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M91.9245 247.664C93.061 251.431 91.0462 255.044 87.4299 255.715C83.8136 256.437 79.8873 253.96 78.7508 250.244C77.6142 246.477 79.629 242.864 83.2453 242.193C86.8616 241.471 90.7879 243.948 91.9245 247.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M91.7695 247.354C92.9061 251.07 90.8912 254.631 87.2749 255.354C83.6586 256.076 79.8873 253.65 78.7508 249.986C77.6142 246.27 79.629 242.709 83.2453 241.987C86.8616 241.264 90.633 243.69 91.7695 247.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M91.6178 247.045C92.7027 250.657 90.6879 254.218 87.1749 254.941C83.6619 255.663 79.8906 253.289 78.8057 249.677C77.7208 246.064 79.7356 242.503 83.2486 241.78C86.8133 241.058 90.5329 243.432 91.6178 247.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M91.4605 246.787C92.4937 250.348 90.5305 253.857 87.0176 254.58C83.5046 255.302 79.8366 252.98 78.7517 249.419C77.7185 245.858 79.6816 242.348 83.1946 241.626C86.7076 240.903 90.3756 243.226 91.4605 246.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M91.3049 246.477C92.3381 249.986 90.375 253.444 86.9137 254.167C83.4523 254.889 79.836 252.618 78.8028 249.109C77.7695 245.6 79.7327 242.142 83.194 241.419C86.6554 240.697 90.2717 242.967 91.3049 246.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M91.1008 246.167C92.0824 249.625 90.1709 253.031 86.7096 253.805C83.2999 254.528 79.7353 252.309 78.702 248.851C77.7205 245.393 79.6836 241.987 83.0933 241.213C86.5029 240.49 90.0676 242.709 91.1008 246.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M90.9452 245.858C91.9268 249.264 89.9637 252.67 86.6057 253.393C83.2477 254.115 79.7347 251.947 78.7531 248.541C77.7715 245.135 79.7347 241.729 83.0927 241.006C86.4507 240.284 89.9637 242.451 90.9452 245.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M90.7908 245.599C91.7207 248.954 89.8093 252.309 86.5029 253.031C83.1966 253.754 79.7352 251.638 78.8053 248.283C77.8754 244.929 79.7869 241.574 83.0933 240.851C86.3996 240.129 89.8609 242.245 90.7908 245.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M90.6328 245.29C91.5627 248.593 89.6513 251.896 86.3449 252.618C83.0902 253.341 79.6806 251.277 78.7506 247.974C77.8207 244.671 79.7322 241.368 83.0386 240.645C86.2932 239.923 89.7029 241.987 90.6328 245.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M90.479 244.98C91.3572 248.232 89.4458 251.483 86.2427 252.257C83.0397 252.98 79.6817 250.967 78.8035 247.716C77.9252 244.464 79.8367 241.213 83.0397 240.439C86.2427 239.665 89.6007 241.729 90.479 244.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M90.324 244.671C91.1506 247.87 89.2908 251.07 86.0877 251.844C82.8847 252.618 79.63 250.606 78.8035 247.406C77.9252 244.206 79.8367 241.006 83.0397 240.232C86.2427 239.458 89.4974 241.471 90.324 244.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M90.1164 244.412C90.943 247.561 89.0832 250.709 85.9318 251.483C82.7805 252.257 79.5774 250.296 78.7508 247.148C77.9243 243.999 79.7841 240.851 82.9354 240.077C86.0868 239.303 89.2898 241.264 90.1164 244.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M89.9637 244.103C90.7386 247.199 88.8788 250.348 85.8307 251.07C82.7827 251.793 79.5797 249.935 78.8047 246.838C78.0298 243.742 79.8897 240.593 82.9377 239.871C85.9857 239.148 89.1887 241.006 89.9637 244.103Z"
          fill="#150B0B"
        />
        <path
          d="M89.8074 243.793C90.5824 246.838 88.7225 249.935 85.6745 250.657C82.6265 251.38 79.5268 249.573 78.8035 246.528C78.0802 243.484 79.8884 240.387 82.9364 239.664C85.9845 238.89 89.0842 240.748 89.8074 243.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="78" y="238" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M86.8204 239.381C89.7135 240.619 91.0567 243.922 89.8685 246.812C88.6286 249.703 85.3223 251.044 82.4292 249.857C79.5361 248.619 78.1929 245.316 79.3812 242.426C80.621 239.536 83.9274 238.194 86.8204 239.381Z"
        fill="url(#paint63_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M87.183 238.503C90.541 239.948 92.1426 243.819 90.7477 247.174C89.3012 250.528 85.4265 252.128 82.0685 250.735C78.7105 249.29 77.109 245.419 78.5039 242.064C79.9504 238.71 83.825 237.11 87.183 238.503Z"
        fill="url(#paint64_radial_12021_246197)"
      />
      <g
        filter="url(#filter16_f_12021_246197)"
        style={{
          animationDelay: '2ms',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="65.7891"
          y="225.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter17_f_12021_246197)">
        <rect x="80" y="239" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M256.425 89.3475C257.819 93.5794 255.701 97.5533 251.62 98.2242C247.59 98.8951 243.147 96.005 241.753 91.7731C240.358 87.5411 242.476 83.5672 246.557 82.8963C250.587 82.2254 255.03 85.1155 256.425 89.3475Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M256.269 89.0379C257.663 93.2182 255.494 97.1405 251.516 97.8115C247.486 98.4824 243.147 95.6439 241.752 91.4635C240.357 87.2832 242.527 83.3609 246.505 82.69C250.534 82.0191 254.874 84.8576 256.269 89.0379Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M256.062 88.7282C257.406 92.8569 255.287 96.7276 251.309 97.4501C247.331 98.121 243.044 95.3341 241.7 91.2054C240.357 87.0767 242.475 83.206 246.453 82.4834C250.431 81.8125 254.719 84.5994 256.062 88.7282Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M255.908 88.4184C257.251 92.4956 255.133 96.3663 251.207 97.0372C247.28 97.7081 243.044 94.9728 241.701 90.8957C240.358 86.8186 242.476 82.9479 246.402 82.277C250.328 81.606 254.565 84.3413 255.908 88.4184Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M255.752 88.1605C257.043 92.1861 254.977 96.0051 251.102 96.6761C247.228 97.347 243.043 94.6633 241.752 90.6378C240.46 86.6123 242.526 82.7932 246.401 82.1223C250.276 81.4514 254.46 84.135 255.752 88.1605Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M255.6 87.8509C256.839 91.8249 254.773 95.5923 250.95 96.2632C247.127 96.9342 242.994 94.3021 241.754 90.3282C240.514 86.3543 242.581 82.5868 246.404 81.9159C250.227 81.245 254.36 83.877 255.6 87.8509Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M255.441 87.5411C256.681 91.4634 254.614 95.1793 250.843 95.9018C247.072 96.6243 242.991 93.9922 241.751 90.07C240.511 86.1477 242.577 82.4318 246.349 81.7093C250.12 80.9867 254.201 83.6188 255.441 87.5411Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M255.286 87.2314C256.474 91.1021 254.459 94.7664 250.688 95.4889C246.968 96.2114 242.939 93.631 241.751 89.8119C240.562 85.9412 242.577 82.2769 246.348 81.5544C250.12 80.8319 254.098 83.4124 255.286 87.283V87.2314Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M255.083 86.9735C256.271 90.7926 254.205 94.4052 250.537 95.1277C246.869 95.8503 242.891 93.3214 241.754 89.5539C240.566 85.7349 242.632 82.1222 246.3 81.3997C249.968 80.6772 253.946 83.206 255.083 86.9735Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M254.924 86.6638C256.061 90.4313 254.046 94.0439 250.43 94.7148C246.814 95.4373 242.887 92.9601 241.751 89.2442C240.614 85.4768 242.629 81.8641 246.245 81.1932C249.862 80.4707 253.788 82.9479 254.924 86.6638Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M254.77 86.3541C255.906 90.07 253.891 93.631 250.275 94.3536C246.659 95.0761 242.887 92.6505 241.751 88.9862C240.614 85.2703 242.629 81.7093 246.245 80.9868C249.862 80.2643 253.633 82.6899 254.77 86.3541Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M254.618 86.0446C255.703 89.6572 253.688 93.2182 250.175 93.9408C246.662 94.6633 242.891 92.2893 241.806 88.6766C240.721 85.064 242.736 81.503 246.249 80.7804C249.813 80.0579 253.533 82.4319 254.618 86.0446Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M254.46 85.7866C255.494 89.3476 253.531 92.857 250.018 93.5796C246.505 94.3021 242.837 91.9797 241.752 88.4187C240.718 84.8576 242.682 81.3482 246.195 80.6257C249.708 79.9031 253.376 82.2256 254.46 85.7866Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M254.305 85.4769C255.338 88.9863 253.375 92.4441 249.914 93.1667C246.452 93.8892 242.836 91.6184 241.803 88.109C240.77 84.5996 242.733 81.1417 246.194 80.4192C249.655 79.6967 253.272 81.9675 254.305 85.4769Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M254.101 85.1671C255.082 88.6249 253.171 92.0311 249.71 92.8052C246.3 93.5278 242.735 91.3086 241.702 87.8508C240.72 84.393 242.684 80.9867 246.093 80.2126C249.503 79.4901 253.068 81.7093 254.101 85.1671Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M253.945 84.8576C254.927 88.2638 252.964 91.67 249.606 92.3925C246.248 93.115 242.735 90.9475 241.753 87.5412C240.772 84.135 242.735 80.7288 246.093 80.0063C249.451 79.2838 252.964 81.4514 253.945 84.8576Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M253.791 84.5994C254.721 87.954 252.809 91.3086 249.503 92.0312C246.197 92.7537 242.735 90.6377 241.805 87.2831C240.875 83.9285 242.787 80.5739 246.093 79.8514C249.4 79.1289 252.861 81.2448 253.791 84.5994Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M253.633 84.2899C254.563 87.5928 252.651 90.8958 249.345 91.6184C246.09 92.3409 242.681 90.2765 241.751 86.9735C240.821 83.6706 242.732 80.3676 246.039 79.645C249.293 78.9225 252.703 80.9869 253.633 84.2899Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M253.479 83.9803C254.357 87.2317 252.446 90.483 249.243 91.2572C246.04 91.9797 242.682 89.9669 241.803 86.7156C240.925 83.4642 242.837 80.2128 246.04 79.4387C249.243 78.6645 252.601 80.7289 253.479 83.9803Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M253.324 83.6706C254.151 86.8704 252.291 90.0702 249.088 90.8443C245.885 91.6184 242.63 89.6057 241.803 86.4059C240.925 83.2062 242.837 80.0064 246.04 79.2323C249.243 78.4581 252.497 80.4709 253.324 83.6706Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M253.116 83.4124C253.943 86.5605 252.083 89.7087 248.932 90.4828C245.78 91.257 242.577 89.2958 241.751 86.1477C240.924 82.9995 242.784 79.8513 245.935 79.0772C249.087 78.3031 252.29 80.2642 253.116 83.4124Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M252.964 83.1029C253.739 86.1995 251.879 89.3476 248.831 90.0701C245.783 90.7927 242.58 88.9347 241.805 85.8382C241.03 82.7417 242.89 79.5935 245.938 78.871C248.986 78.1484 252.189 80.0064 252.964 83.1029Z"
          fill="#150B0B"
        />
        <path
          d="M252.807 82.7932C253.582 85.8381 251.723 88.9347 248.675 89.6572C245.626 90.3797 242.527 88.5734 241.804 85.5285C241.08 82.4835 242.888 79.387 245.936 78.6644C248.984 77.8903 252.084 79.7482 252.807 82.7932Z"
          fill="#0A0000"
        />
      </g>
      <rect x="241" y="77" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M249.82 78.3807C252.713 79.6193 254.057 82.9223 252.868 85.8124C251.629 88.7025 248.322 90.0444 245.429 88.8574C242.536 87.6188 241.193 84.3158 242.381 81.4257C243.621 78.5356 246.927 77.1937 249.82 78.3807Z"
        fill="url(#paint65_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M250.183 77.5034C253.541 78.9484 255.143 82.8191 253.748 86.1737C252.301 89.5283 248.427 91.1282 245.069 89.7347C241.711 88.2897 240.109 84.419 241.504 81.0644C242.95 77.7098 246.825 76.1099 250.183 77.5034Z"
        fill="url(#paint66_radial_12021_246197)"
      />
      <g
        filter="url(#filter18_f_12021_246197)"
        style={{
          animationDelay: '2s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="228.789"
          y="64.4736"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter19_f_12021_246197)">
        <rect x="243" y="78" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M92.4245 478.347C93.8194 482.579 91.7012 486.553 87.62 487.224C83.5904 487.895 79.1475 485.005 77.7526 480.773C76.3577 476.541 78.4759 472.567 82.5571 471.896C86.5867 471.225 91.0296 474.115 92.4245 478.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M92.2686 478.038C93.6635 482.218 91.4937 486.141 87.5157 486.811C83.4861 487.482 79.1466 484.644 77.7517 480.464C76.3568 476.283 78.5266 472.361 82.5046 471.69C86.5342 471.019 90.8738 473.858 92.2686 478.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M92.0623 477.728C93.4055 481.857 91.2874 485.728 87.3094 486.45C83.3315 487.121 79.0436 484.334 77.7004 480.205C76.3572 476.077 78.4753 472.206 82.4533 471.483C86.4312 470.813 90.7191 473.599 92.0623 477.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M91.9078 477.418C93.251 481.496 91.1328 485.366 87.2066 486.037C83.2803 486.708 79.044 483.973 77.7008 479.896C76.3576 475.819 78.4757 471.948 82.402 471.277C86.3283 470.606 90.5646 473.341 91.9078 477.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M91.7519 477.161C93.0434 481.186 90.977 485.005 87.1023 485.676C83.2277 486.347 79.0431 483.663 77.7516 479.638C76.46 475.612 78.5265 471.793 82.4011 471.122C86.2757 470.451 90.4603 473.135 91.7519 477.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M91.5995 476.851C92.8394 480.825 90.7729 484.592 86.95 485.263C83.127 485.934 78.9941 483.302 77.7542 479.328C76.5143 475.354 78.5808 471.587 82.4037 470.916C86.2267 470.245 90.3596 472.877 91.5995 476.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M91.441 476.541C92.6809 480.463 90.6144 484.179 86.8431 484.902C83.0718 485.624 78.9906 482.992 77.7507 479.07C76.5108 475.148 78.5773 471.432 82.3486 470.709C86.1199 469.987 90.2011 472.619 91.441 476.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M91.286 476.231C92.4742 480.102 90.4594 483.766 86.6881 484.489C82.9684 485.211 78.9388 482.631 77.7506 478.812C76.5624 474.941 78.5772 471.277 82.3485 470.554C86.1198 469.832 90.0977 472.412 91.286 476.283V476.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M91.0828 475.973C92.271 479.793 90.2046 483.405 86.5366 484.128C82.8686 484.85 78.8907 482.321 77.7541 478.554C76.5659 474.735 78.6324 471.122 82.3004 470.4C85.9683 469.677 89.9463 472.206 91.0828 475.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M90.9245 475.664C92.061 479.431 90.0462 483.044 86.4299 483.715C82.8136 484.437 78.8873 481.96 77.7508 478.244C76.6142 474.477 78.629 470.864 82.2453 470.193C85.8616 469.471 89.7879 471.948 90.9245 475.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M90.7695 475.354C91.9061 479.07 89.8912 482.631 86.2749 483.354C82.6586 484.076 78.8873 481.65 77.7508 477.986C76.6142 474.27 78.629 470.709 82.2453 469.987C85.8616 469.264 89.633 471.69 90.7695 475.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M90.6178 475.045C91.7027 478.657 89.6879 482.218 86.1749 482.941C82.6619 483.663 78.8906 481.289 77.8057 477.677C76.7208 474.064 78.7356 470.503 82.2486 469.78C85.8133 469.058 89.5329 471.432 90.6178 475.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M90.4605 474.787C91.4937 478.348 89.5305 481.857 86.0176 482.58C82.5046 483.302 78.8366 480.98 77.7517 477.419C76.7185 473.858 78.6816 470.348 82.1946 469.626C85.7076 468.903 89.3756 471.226 90.4605 474.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M90.3049 474.477C91.3381 477.986 89.375 481.444 85.9137 482.167C82.4523 482.889 78.836 480.618 77.8028 477.109C76.7695 473.6 78.7327 470.142 82.194 469.419C85.6554 468.697 89.2717 470.967 90.3049 474.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M90.1008 474.167C91.0824 477.625 89.1709 481.031 85.7096 481.805C82.2999 482.528 78.7353 480.309 77.702 476.851C76.7205 473.393 78.6836 469.987 82.0933 469.213C85.5029 468.49 89.0676 470.709 90.1008 474.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M89.9452 473.858C90.9268 477.264 88.9637 480.67 85.6057 481.393C82.2477 482.115 78.7347 479.947 77.7531 476.541C76.7715 473.135 78.7347 469.729 82.0927 469.006C85.4507 468.284 88.9637 470.451 89.9452 473.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M89.7908 473.599C90.7207 476.954 88.8093 480.309 85.5029 481.031C82.1966 481.754 78.7352 479.638 77.8053 476.283C76.8754 472.929 78.7869 469.574 82.0933 468.851C85.3996 468.129 88.8609 470.245 89.7908 473.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M89.6328 473.29C90.5627 476.593 88.6513 479.896 85.3449 480.618C82.0902 481.341 78.6806 479.277 77.7506 475.974C76.8207 472.671 78.7322 469.368 82.0386 468.645C85.2932 467.923 88.7029 469.987 89.6328 473.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M89.479 472.98C90.3572 476.232 88.4458 479.483 85.2427 480.257C82.0397 480.98 78.6817 478.967 77.8035 475.716C76.9252 472.464 78.8367 469.213 82.0397 468.439C85.2427 467.665 88.6007 469.729 89.479 472.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M89.324 472.671C90.1506 475.87 88.2908 479.07 85.0877 479.844C81.8847 480.618 78.63 478.606 77.8035 475.406C76.9252 472.206 78.8367 469.006 82.0397 468.232C85.2427 467.458 88.4974 469.471 89.324 472.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M89.1164 472.412C89.943 475.561 88.0832 478.709 84.9318 479.483C81.7805 480.257 78.5774 478.296 77.7508 475.148C76.9243 471.999 78.7841 468.851 81.9354 468.077C85.0868 467.303 88.2898 469.264 89.1164 472.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M88.9637 472.103C89.7386 475.199 87.8788 478.348 84.8307 479.07C81.7827 479.793 78.5797 477.935 77.8047 474.838C77.0298 471.742 78.8897 468.593 81.9377 467.871C84.9857 467.148 88.1887 469.006 88.9637 472.103Z"
          fill="#150B0B"
        />
        <path
          d="M88.8074 471.793C89.5824 474.838 87.7225 477.935 84.6745 478.657C81.6265 479.38 78.5268 477.573 77.8035 474.528C77.0802 471.484 78.8884 468.387 81.9364 467.664C84.9845 466.89 88.0842 468.748 88.8074 471.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="77" y="466" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M85.8204 467.381C88.7135 468.619 90.0567 471.922 88.8685 474.812C87.6286 477.703 84.3223 479.044 81.4292 477.857C78.5361 476.619 77.1929 473.316 78.3812 470.426C79.621 467.536 82.9274 466.194 85.8204 467.381Z"
        fill="url(#paint67_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M86.183 466.503C89.541 467.948 91.1426 471.819 89.7477 475.174C88.3012 478.528 84.4265 480.128 81.0685 478.735C77.7105 477.29 76.109 473.419 77.5039 470.064C78.9504 466.71 82.825 465.11 86.183 466.503Z"
        fill="url(#paint68_radial_12021_246197)"
      />
      <g
        filter="url(#filter20_f_12021_246197)"
        style={{
          animationDelay: '2s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="64.7891"
          y="453.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter21_f_12021_246197)">
        <rect x="79" y="467" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M253.425 642.347C254.819 646.579 252.701 650.553 248.62 651.224C244.59 651.895 240.147 649.005 238.753 644.773C237.358 640.541 239.476 636.567 243.557 635.896C247.587 635.225 252.03 638.115 253.425 642.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M253.269 642.038C254.663 646.218 252.494 650.141 248.516 650.811C244.486 651.482 240.147 648.644 238.752 644.464C237.357 640.283 239.527 636.361 243.505 635.69C247.534 635.019 251.874 637.858 253.269 642.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M253.062 641.728C254.406 645.857 252.287 649.728 248.309 650.45C244.331 651.121 240.044 648.334 238.7 644.205C237.357 640.077 239.475 636.206 243.453 635.483C247.431 634.813 251.719 637.599 253.062 641.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M252.908 641.418C254.251 645.496 252.133 649.366 248.207 650.037C244.28 650.708 240.044 647.973 238.701 643.896C237.358 639.819 239.476 635.948 243.402 635.277C247.328 634.606 251.565 637.341 252.908 641.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M252.752 641.161C254.043 645.186 251.977 649.005 248.102 649.676C244.228 650.347 240.043 647.663 238.752 643.638C237.46 639.612 239.526 635.793 243.401 635.122C247.276 634.451 251.46 637.135 252.752 641.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M252.6 640.851C253.839 644.825 251.773 648.592 247.95 649.263C244.127 649.934 239.994 647.302 238.754 643.328C237.514 639.354 239.581 635.587 243.404 634.916C247.227 634.245 251.36 636.877 252.6 640.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M252.441 640.541C253.681 644.463 251.614 648.179 247.843 648.902C244.072 649.624 239.991 646.992 238.751 643.07C237.511 639.148 239.577 635.432 243.349 634.709C247.12 633.987 251.201 636.619 252.441 640.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M252.286 640.231C253.474 644.102 251.459 647.766 247.688 648.489C243.968 649.211 239.939 646.631 238.751 642.812C237.562 638.941 239.577 635.277 243.348 634.554C247.12 633.832 251.098 636.412 252.286 640.283V640.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M252.083 639.973C253.271 643.793 251.205 647.405 247.537 648.128C243.869 648.85 239.891 646.321 238.754 642.554C237.566 638.735 239.632 635.122 243.3 634.4C246.968 633.677 250.946 636.206 252.083 639.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M251.924 639.664C253.061 643.431 251.046 647.044 247.43 647.715C243.814 648.437 239.887 645.96 238.751 642.244C237.614 638.477 239.629 634.864 243.245 634.193C246.862 633.471 250.788 635.948 251.924 639.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M251.77 639.354C252.906 643.07 250.891 646.631 247.275 647.354C243.659 648.076 239.887 645.65 238.751 641.986C237.614 638.27 239.629 634.709 243.245 633.987C246.862 633.264 250.633 635.69 251.77 639.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M251.618 639.045C252.703 642.657 250.688 646.218 247.175 646.941C243.662 647.663 239.891 645.289 238.806 641.677C237.721 638.064 239.736 634.503 243.249 633.78C246.813 633.058 250.533 635.432 251.618 639.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M251.46 638.787C252.494 642.348 250.531 645.857 247.018 646.58C243.505 647.302 239.837 644.98 238.752 641.419C237.718 637.858 239.682 634.348 243.195 633.626C246.708 632.903 250.376 635.226 251.46 638.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M251.305 638.477C252.338 641.986 250.375 645.444 246.914 646.167C243.452 646.889 239.836 644.618 238.803 641.109C237.77 637.6 239.733 634.142 243.194 633.419C246.655 632.697 250.272 634.967 251.305 638.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M251.101 638.167C252.082 641.625 250.171 645.031 246.71 645.805C243.3 646.528 239.735 644.309 238.702 640.851C237.72 637.393 239.684 633.987 243.093 633.213C246.503 632.49 250.068 634.709 251.101 638.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M250.945 637.858C251.927 641.264 249.964 644.67 246.606 645.393C243.248 646.115 239.735 643.947 238.753 640.541C237.772 637.135 239.735 633.729 243.093 633.006C246.451 632.284 249.964 634.451 250.945 637.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M250.791 637.599C251.721 640.954 249.809 644.309 246.503 645.031C243.197 645.754 239.735 643.638 238.805 640.283C237.875 636.929 239.787 633.574 243.093 632.851C246.4 632.129 249.861 634.245 250.791 637.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M250.633 637.29C251.563 640.593 249.651 643.896 246.345 644.618C243.09 645.341 239.681 643.277 238.751 639.974C237.821 636.671 239.732 633.368 243.039 632.645C246.293 631.923 249.703 633.987 250.633 637.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M250.479 636.98C251.357 640.232 249.446 643.483 246.243 644.257C243.04 644.98 239.682 642.967 238.803 639.716C237.925 636.464 239.837 633.213 243.04 632.439C246.243 631.665 249.601 633.729 250.479 636.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M250.324 636.671C251.151 639.87 249.291 643.07 246.088 643.844C242.885 644.618 239.63 642.606 238.803 639.406C237.925 636.206 239.837 633.006 243.04 632.232C246.243 631.458 249.497 633.471 250.324 636.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M250.116 636.412C250.943 639.561 249.083 642.709 245.932 643.483C242.78 644.257 239.577 642.296 238.751 639.148C237.924 635.999 239.784 632.851 242.935 632.077C246.087 631.303 249.29 633.264 250.116 636.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M249.964 636.103C250.739 639.199 248.879 642.348 245.831 643.07C242.783 643.793 239.58 641.935 238.805 638.838C238.03 635.742 239.89 632.593 242.938 631.871C245.986 631.148 249.189 633.006 249.964 636.103Z"
          fill="#150B0B"
        />
        <path
          d="M249.807 635.793C250.582 638.838 248.723 641.935 245.675 642.657C242.626 643.38 239.527 641.573 238.804 638.528C238.08 635.484 239.888 632.387 242.936 631.664C245.984 630.89 249.084 632.748 249.807 635.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="238" y="630" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M246.82 631.381C249.713 632.619 251.057 635.922 249.868 638.812C248.629 641.703 245.322 643.044 242.429 641.857C239.536 640.619 238.193 637.316 239.381 634.426C240.621 631.536 243.927 630.194 246.82 631.381Z"
        fill="url(#paint69_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M247.183 630.503C250.541 631.948 252.143 635.819 250.748 639.174C249.301 642.528 245.427 644.128 242.069 642.735C238.711 641.29 237.109 637.419 238.504 634.064C239.95 630.71 243.825 629.11 247.183 630.503Z"
        fill="url(#paint70_radial_12021_246197)"
      />
      <g
        filter="url(#filter22_f_12021_246197)"
        style={{
          animationDelay: '2ms',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="225.789"
          y="617.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter23_f_12021_246197)">
        <rect x="240" y="631" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M481.425 643.347C482.819 647.579 480.701 651.553 476.62 652.224C472.59 652.895 468.147 650.005 466.753 645.773C465.358 641.541 467.476 637.567 471.557 636.896C475.587 636.225 480.03 639.115 481.425 643.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M481.269 643.038C482.663 647.218 480.494 651.141 476.516 651.811C472.486 652.482 468.147 649.644 466.752 645.464C465.357 641.283 467.527 637.361 471.505 636.69C475.534 636.019 479.874 638.858 481.269 643.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M481.062 642.728C482.406 646.857 480.287 650.728 476.309 651.45C472.331 652.121 468.044 649.334 466.7 645.205C465.357 641.077 467.475 637.206 471.453 636.483C475.431 635.813 479.719 638.599 481.062 642.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M480.908 642.418C482.251 646.496 480.133 650.366 476.207 651.037C472.28 651.708 468.044 648.973 466.701 644.896C465.358 640.819 467.476 636.948 471.402 636.277C475.328 635.606 479.565 638.341 480.908 642.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M480.752 642.161C482.043 646.186 479.977 650.005 476.102 650.676C472.228 651.347 468.043 648.663 466.752 644.638C465.46 640.612 467.526 636.793 471.401 636.122C475.276 635.451 479.46 638.135 480.752 642.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M480.6 641.851C481.839 645.825 479.773 649.592 475.95 650.263C472.127 650.934 467.994 648.302 466.754 644.328C465.514 640.354 467.581 636.587 471.404 635.916C475.227 635.245 479.36 637.877 480.6 641.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M480.441 641.541C481.681 645.463 479.614 649.179 475.843 649.902C472.072 650.624 467.991 647.992 466.751 644.07C465.511 640.148 467.577 636.432 471.349 635.709C475.12 634.987 479.201 637.619 480.441 641.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M480.286 641.231C481.474 645.102 479.459 648.766 475.688 649.489C471.968 650.211 467.939 647.631 466.751 643.812C465.562 639.941 467.577 636.277 471.348 635.554C475.12 634.832 479.098 637.412 480.286 641.283V641.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M480.083 640.973C481.271 644.793 479.205 648.405 475.537 649.128C471.869 649.85 467.891 647.321 466.754 643.554C465.566 639.735 467.632 636.122 471.3 635.4C474.968 634.677 478.946 637.206 480.083 640.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M479.924 640.664C481.061 644.431 479.046 648.044 475.43 648.715C471.814 649.437 467.887 646.96 466.751 643.244C465.614 639.477 467.629 635.864 471.245 635.193C474.862 634.471 478.788 636.948 479.924 640.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M479.77 640.354C480.906 644.07 478.891 647.631 475.275 648.354C471.659 649.076 467.887 646.65 466.751 642.986C465.614 639.27 467.629 635.709 471.245 634.987C474.862 634.264 478.633 636.69 479.77 640.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M479.618 640.045C480.703 643.657 478.688 647.218 475.175 647.941C471.662 648.663 467.891 646.289 466.806 642.677C465.721 639.064 467.736 635.503 471.249 634.78C474.813 634.058 478.533 636.432 479.618 640.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M479.46 639.787C480.494 643.348 478.531 646.857 475.018 647.58C471.505 648.302 467.837 645.98 466.752 642.419C465.718 638.858 467.682 635.348 471.195 634.626C474.708 633.903 478.376 636.226 479.46 639.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M479.305 639.477C480.338 642.986 478.375 646.444 474.914 647.167C471.452 647.889 467.836 645.618 466.803 642.109C465.77 638.6 467.733 635.142 471.194 634.419C474.655 633.697 478.272 635.967 479.305 639.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M479.101 639.167C480.082 642.625 478.171 646.031 474.71 646.805C471.3 647.528 467.735 645.309 466.702 641.851C465.72 638.393 467.684 634.987 471.093 634.213C474.503 633.49 478.068 635.709 479.101 639.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M478.945 638.858C479.927 642.264 477.964 645.67 474.606 646.393C471.248 647.115 467.735 644.947 466.753 641.541C465.772 638.135 467.735 634.729 471.093 634.006C474.451 633.284 477.964 635.451 478.945 638.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M478.791 638.599C479.721 641.954 477.809 645.309 474.503 646.031C471.197 646.754 467.735 644.638 466.805 641.283C465.875 637.929 467.787 634.574 471.093 633.851C474.4 633.129 477.861 635.245 478.791 638.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M478.633 638.29C479.563 641.593 477.651 644.896 474.345 645.618C471.09 646.341 467.681 644.277 466.751 640.974C465.821 637.671 467.732 634.368 471.039 633.645C474.293 632.923 477.703 634.987 478.633 638.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M478.479 637.98C479.357 641.232 477.446 644.483 474.243 645.257C471.04 645.98 467.682 643.967 466.803 640.716C465.925 637.464 467.837 634.213 471.04 633.439C474.243 632.665 477.601 634.729 478.479 637.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M478.324 637.671C479.151 640.87 477.291 644.07 474.088 644.844C470.885 645.618 467.63 643.606 466.803 640.406C465.925 637.206 467.837 634.006 471.04 633.232C474.243 632.458 477.497 634.471 478.324 637.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M478.116 637.412C478.943 640.561 477.083 643.709 473.932 644.483C470.78 645.257 467.577 643.296 466.751 640.148C465.924 636.999 467.784 633.851 470.935 633.077C474.087 632.303 477.29 634.264 478.116 637.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M477.964 637.103C478.739 640.199 476.879 643.348 473.831 644.07C470.783 644.793 467.58 642.935 466.805 639.838C466.03 636.742 467.89 633.593 470.938 632.871C473.986 632.148 477.189 634.006 477.964 637.103Z"
          fill="#150B0B"
        />
        <path
          d="M477.807 636.793C478.582 639.838 476.723 642.935 473.675 643.657C470.626 644.38 467.527 642.573 466.804 639.528C466.08 636.484 467.888 633.387 470.936 632.664C473.984 631.89 477.084 633.748 477.807 636.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="466" y="631" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M474.82 632.381C477.713 633.619 479.057 636.922 477.868 639.812C476.629 642.703 473.322 644.044 470.429 642.857C467.536 641.619 466.193 638.316 467.381 635.426C468.621 632.536 471.927 631.194 474.82 632.381Z"
        fill="url(#paint71_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M475.183 631.503C478.541 632.948 480.143 636.819 478.748 640.174C477.301 643.528 473.427 645.128 470.069 643.735C466.711 642.29 465.109 638.419 466.504 635.064C467.95 631.71 471.825 630.11 475.183 631.503Z"
        fill="url(#paint72_radial_12021_246197)"
      />
      <g
        filter="url(#filter24_f_12021_246197)"
        style={{
          animationDelay: '2s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="453.789"
          y="618.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter25_f_12021_246197)">
        <rect x="468" y="632" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M644.425 483.347C645.819 487.579 643.701 491.553 639.62 492.224C635.59 492.895 631.147 490.005 629.753 485.773C628.358 481.541 630.476 477.567 634.557 476.896C638.587 476.225 643.03 479.115 644.425 483.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M644.269 483.038C645.663 487.218 643.494 491.141 639.516 491.811C635.486 492.482 631.147 489.644 629.752 485.464C628.357 481.283 630.527 477.361 634.505 476.69C638.534 476.019 642.874 478.858 644.269 483.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M644.062 482.728C645.406 486.857 643.287 490.728 639.309 491.45C635.331 492.121 631.044 489.334 629.7 485.205C628.357 481.077 630.475 477.206 634.453 476.483C638.431 475.813 642.719 478.599 644.062 482.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M643.908 482.418C645.251 486.496 643.133 490.366 639.207 491.037C635.28 491.708 631.044 488.973 629.701 484.896C628.358 480.819 630.476 476.948 634.402 476.277C638.328 475.606 642.565 478.341 643.908 482.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M643.752 482.161C645.043 486.186 642.977 490.005 639.102 490.676C635.228 491.347 631.043 488.663 629.752 484.638C628.46 480.612 630.526 476.793 634.401 476.122C638.276 475.451 642.46 478.135 643.752 482.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M643.6 481.851C644.839 485.825 642.773 489.592 638.95 490.263C635.127 490.934 630.994 488.302 629.754 484.328C628.514 480.354 630.581 476.587 634.404 475.916C638.227 475.245 642.36 477.877 643.6 481.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M643.441 481.541C644.681 485.463 642.614 489.179 638.843 489.902C635.072 490.624 630.991 487.992 629.751 484.07C628.511 480.148 630.577 476.432 634.349 475.709C638.12 474.987 642.201 477.619 643.441 481.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M643.286 481.231C644.474 485.102 642.459 488.766 638.688 489.489C634.968 490.211 630.939 487.631 629.751 483.812C628.562 479.941 630.577 476.277 634.348 475.554C638.12 474.832 642.098 477.412 643.286 481.283V481.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M643.083 480.973C644.271 484.793 642.205 488.405 638.537 489.128C634.869 489.85 630.891 487.321 629.754 483.554C628.566 479.735 630.632 476.122 634.3 475.4C637.968 474.677 641.946 477.206 643.083 480.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M642.924 480.664C644.061 484.431 642.046 488.044 638.43 488.715C634.814 489.437 630.887 486.96 629.751 483.244C628.614 479.477 630.629 475.864 634.245 475.193C637.862 474.471 641.788 476.948 642.924 480.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M642.77 480.354C643.906 484.07 641.891 487.631 638.275 488.354C634.659 489.076 630.887 486.65 629.751 482.986C628.614 479.27 630.629 475.709 634.245 474.987C637.862 474.264 641.633 476.69 642.77 480.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M642.618 480.045C643.703 483.657 641.688 487.218 638.175 487.941C634.662 488.663 630.891 486.289 629.806 482.677C628.721 479.064 630.736 475.503 634.249 474.78C637.813 474.058 641.533 476.432 642.618 480.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M642.46 479.787C643.494 483.348 641.531 486.857 638.018 487.58C634.505 488.302 630.837 485.98 629.752 482.419C628.718 478.858 630.682 475.348 634.195 474.626C637.708 473.903 641.376 476.226 642.46 479.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M642.305 479.477C643.338 482.986 641.375 486.444 637.914 487.167C634.452 487.889 630.836 485.618 629.803 482.109C628.77 478.6 630.733 475.142 634.194 474.419C637.655 473.697 641.272 475.967 642.305 479.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M642.101 479.167C643.082 482.625 641.171 486.031 637.71 486.805C634.3 487.528 630.735 485.309 629.702 481.851C628.72 478.393 630.684 474.987 634.093 474.213C637.503 473.49 641.068 475.709 642.101 479.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M641.945 478.858C642.927 482.264 640.964 485.67 637.606 486.393C634.248 487.115 630.735 484.947 629.753 481.541C628.772 478.135 630.735 474.729 634.093 474.006C637.451 473.284 640.964 475.451 641.945 478.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M641.791 478.599C642.721 481.954 640.809 485.309 637.503 486.031C634.197 486.754 630.735 484.638 629.805 481.283C628.875 477.929 630.787 474.574 634.093 473.851C637.4 473.129 640.861 475.245 641.791 478.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M641.633 478.29C642.563 481.593 640.651 484.896 637.345 485.618C634.09 486.341 630.681 484.277 629.751 480.974C628.821 477.671 630.732 474.368 634.039 473.645C637.293 472.923 640.703 474.987 641.633 478.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M641.479 477.98C642.357 481.232 640.446 484.483 637.243 485.257C634.04 485.98 630.682 483.967 629.803 480.716C628.925 477.464 630.837 474.213 634.04 473.439C637.243 472.665 640.601 474.729 641.479 477.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M641.324 477.671C642.151 480.87 640.291 484.07 637.088 484.844C633.885 485.618 630.63 483.606 629.803 480.406C628.925 477.206 630.837 474.006 634.04 473.232C637.243 472.458 640.497 474.471 641.324 477.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M641.116 477.412C641.943 480.561 640.083 483.709 636.932 484.483C633.78 485.257 630.577 483.296 629.751 480.148C628.924 476.999 630.784 473.851 633.935 473.077C637.087 472.303 640.29 474.264 641.116 477.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M640.964 477.103C641.739 480.199 639.879 483.348 636.831 484.07C633.783 484.793 630.58 482.935 629.805 479.838C629.03 476.742 630.89 473.593 633.938 472.871C636.986 472.148 640.189 474.006 640.964 477.103Z"
          fill="#150B0B"
        />
        <path
          d="M640.807 476.793C641.582 479.838 639.723 482.935 636.675 483.657C633.626 484.38 630.527 482.573 629.804 479.528C629.08 476.484 630.888 473.387 633.936 472.664C636.984 471.89 640.084 473.748 640.807 476.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="629" y="471" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M637.82 472.381C640.713 473.619 642.057 476.922 640.868 479.812C639.629 482.703 636.322 484.044 633.429 482.857C630.536 481.619 629.193 478.316 630.381 475.426C631.621 472.536 634.927 471.194 637.82 472.381Z"
        fill="url(#paint73_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M638.183 471.503C641.541 472.948 643.143 476.819 641.748 480.174C640.301 483.528 636.427 485.128 633.069 483.735C629.711 482.29 628.109 478.419 629.504 475.064C630.95 471.71 634.825 470.11 638.183 471.503Z"
        fill="url(#paint74_radial_12021_246197)"
      />
      <g
        filter="url(#filter26_f_12021_246197)"
        style={{
          animationDelay: '2ms',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="616.789"
          y="458.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter27_f_12021_246197)">
        <rect x="631" y="472" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M646.425 253.347C647.819 257.579 645.701 261.553 641.62 262.224C637.59 262.895 633.147 260.005 631.753 255.773C630.358 251.541 632.476 247.567 636.557 246.896C640.587 246.225 645.03 249.115 646.425 253.347Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M646.269 253.038C647.663 257.218 645.494 261.141 641.516 261.811C637.486 262.482 633.147 259.644 631.752 255.464C630.357 251.283 632.527 247.361 636.505 246.69C640.534 246.019 644.874 248.858 646.269 253.038Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M646.062 252.728C647.406 256.857 645.287 260.728 641.309 261.45C637.331 262.121 633.044 259.334 631.7 255.205C630.357 251.077 632.475 247.206 636.453 246.483C640.431 245.813 644.719 248.599 646.062 252.728Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M645.908 252.418C647.251 256.496 645.133 260.366 641.207 261.037C637.28 261.708 633.044 258.973 631.701 254.896C630.358 250.819 632.476 246.948 636.402 246.277C640.328 245.606 644.565 248.341 645.908 252.418Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M645.752 252.161C647.043 256.186 644.977 260.005 641.102 260.676C637.228 261.347 633.043 258.663 631.752 254.638C630.46 250.612 632.526 246.793 636.401 246.122C640.276 245.451 644.46 248.135 645.752 252.161Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M645.6 251.851C646.839 255.825 644.773 259.592 640.95 260.263C637.127 260.934 632.994 258.302 631.754 254.328C630.514 250.354 632.581 246.587 636.404 245.916C640.227 245.245 644.36 247.877 645.6 251.851Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M645.441 251.541C646.681 255.463 644.614 259.179 640.843 259.902C637.072 260.624 632.991 257.992 631.751 254.07C630.511 250.148 632.577 246.432 636.349 245.709C640.12 244.987 644.201 247.619 645.441 251.541Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M645.286 251.231C646.474 255.102 644.459 258.766 640.688 259.489C636.968 260.211 632.939 257.631 631.751 253.812C630.562 249.941 632.577 246.277 636.348 245.554C640.12 244.832 644.098 247.412 645.286 251.283V251.231Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M645.083 250.973C646.271 254.793 644.205 258.405 640.537 259.128C636.869 259.85 632.891 257.321 631.754 253.554C630.566 249.735 632.632 246.122 636.3 245.4C639.968 244.677 643.946 247.206 645.083 250.973Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M644.924 250.664C646.061 254.431 644.046 258.044 640.43 258.715C636.814 259.437 632.887 256.96 631.751 253.244C630.614 249.477 632.629 245.864 636.245 245.193C639.862 244.471 643.788 246.948 644.924 250.664Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M644.77 250.354C645.906 254.07 643.891 257.631 640.275 258.354C636.659 259.076 632.887 256.65 631.751 252.986C630.614 249.27 632.629 245.709 636.245 244.987C639.862 244.264 643.633 246.69 644.77 250.354Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M644.618 250.045C645.703 253.657 643.688 257.218 640.175 257.941C636.662 258.663 632.891 256.289 631.806 252.677C630.721 249.064 632.736 245.503 636.249 244.78C639.813 244.058 643.533 246.432 644.618 250.045Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M644.46 249.787C645.494 253.348 643.531 256.857 640.018 257.58C636.505 258.302 632.837 255.98 631.752 252.419C630.718 248.858 632.682 245.348 636.195 244.626C639.708 243.903 643.376 246.226 644.46 249.787Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M644.305 249.477C645.338 252.986 643.375 256.444 639.914 257.167C636.452 257.889 632.836 255.618 631.803 252.109C630.77 248.6 632.733 245.142 636.194 244.419C639.655 243.697 643.272 245.967 644.305 249.477Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M644.101 249.167C645.082 252.625 643.171 256.031 639.71 256.805C636.3 257.528 632.735 255.309 631.702 251.851C630.72 248.393 632.684 244.987 636.093 244.213C639.503 243.49 643.068 245.709 644.101 249.167Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M643.945 248.858C644.927 252.264 642.964 255.67 639.606 256.393C636.248 257.115 632.735 254.947 631.753 251.541C630.772 248.135 632.735 244.729 636.093 244.006C639.451 243.284 642.964 245.451 643.945 248.858Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M643.791 248.599C644.721 251.954 642.809 255.309 639.503 256.031C636.197 256.754 632.735 254.638 631.805 251.283C630.875 247.929 632.787 244.574 636.093 243.851C639.4 243.129 642.861 245.245 643.791 248.599Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M643.633 248.29C644.563 251.593 642.651 254.896 639.345 255.618C636.09 256.341 632.681 254.277 631.751 250.974C630.821 247.671 632.732 244.368 636.039 243.645C639.293 242.923 642.703 244.987 643.633 248.29Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M643.479 247.98C644.357 251.232 642.446 254.483 639.243 255.257C636.04 255.98 632.682 253.967 631.803 250.716C630.925 247.464 632.837 244.213 636.04 243.439C639.243 242.665 642.601 244.729 643.479 247.98Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M643.324 247.671C644.151 250.87 642.291 254.07 639.088 254.844C635.885 255.618 632.63 253.606 631.803 250.406C630.925 247.206 632.837 244.006 636.04 243.232C639.243 242.458 642.497 244.471 643.324 247.671Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M643.116 247.412C643.943 250.561 642.083 253.709 638.932 254.483C635.78 255.257 632.577 253.296 631.751 250.148C630.924 246.999 632.784 243.851 635.935 243.077C639.087 242.303 642.29 244.264 643.116 247.412Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M642.964 247.103C643.739 250.199 641.879 253.348 638.831 254.07C635.783 254.793 632.58 252.935 631.805 249.838C631.03 246.742 632.89 243.593 635.938 242.871C638.986 242.148 642.189 244.006 642.964 247.103Z"
          fill="#150B0B"
        />
        <path
          d="M642.807 246.793C643.582 249.838 641.723 252.935 638.675 253.657C635.626 254.38 632.527 252.573 631.804 249.528C631.08 246.484 632.888 243.387 635.936 242.664C638.984 241.89 642.084 243.748 642.807 246.793Z"
          fill="#0A0000"
        />
      </g>
      <rect x="631" y="241" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M639.82 242.381C642.713 243.619 644.057 246.922 642.868 249.812C641.629 252.703 638.322 254.044 635.429 252.857C632.536 251.619 631.193 248.316 632.381 245.426C633.621 242.536 636.927 241.194 639.82 242.381Z"
        fill="url(#paint75_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M640.183 241.503C643.541 242.948 645.143 246.819 643.748 250.174C642.301 253.528 638.427 255.128 635.069 253.735C631.711 252.29 630.109 248.419 631.504 245.064C632.95 241.71 636.825 240.11 640.183 241.503Z"
        fill="url(#paint76_radial_12021_246197)"
      />
      <g
        filter="url(#filter28_f_12021_246197)"
        style={{
          animationDelay: '2s',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="618.789"
          y="228.474"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter29_f_12021_246197)">
        <rect x="633" y="242" width="10" height="11" rx="5" fill="white" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          opacity="0.04"
          d="M484.425 91.3475C485.819 95.5794 483.701 99.5533 479.62 100.224C475.59 100.895 471.147 98.005 469.753 93.7731C468.358 89.5411 470.476 85.5672 474.557 84.8963C478.587 84.2254 483.03 87.1155 484.425 91.3475Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.09"
          d="M484.269 91.0379C485.663 95.2182 483.494 99.1405 479.516 99.8115C475.486 100.482 471.147 97.6439 469.752 93.4635C468.357 89.2832 470.527 85.3609 474.505 84.69C478.534 84.0191 482.874 86.8576 484.269 91.0379Z"
          fill="#EAE9E9"
        />
        <path
          opacity="0.13"
          d="M484.062 90.7282C485.406 94.8569 483.287 98.7276 479.309 99.4501C475.331 100.121 471.044 97.3341 469.7 93.2054C468.357 89.0767 470.475 85.206 474.453 84.4834C478.431 83.8125 482.719 86.5994 484.062 90.7282Z"
          fill="#DFDEDE"
        />
        <path
          opacity="0.17"
          d="M483.908 90.4184C485.251 94.4956 483.133 98.3663 479.207 99.0372C475.28 99.7081 471.044 96.9728 469.701 92.8957C468.358 88.8186 470.476 84.9479 474.402 84.277C478.328 83.606 482.565 86.3413 483.908 90.4184Z"
          fill="#D4D3D3"
        />
        <path
          opacity="0.22"
          d="M483.752 90.1605C485.043 94.1861 482.977 98.0051 479.102 98.6761C475.228 99.347 471.043 96.6633 469.752 92.6378C468.46 88.6123 470.526 84.7932 474.401 84.1223C478.276 83.4514 482.46 86.135 483.752 90.1605Z"
          fill="#CAC8C8"
        />
        <path
          opacity="0.26"
          d="M483.6 89.8509C484.839 93.8249 482.773 97.5923 478.95 98.2632C475.127 98.9342 470.994 96.3021 469.754 92.3282C468.514 88.3543 470.581 84.5868 474.404 83.9159C478.227 83.245 482.36 85.877 483.6 89.8509Z"
          fill="#BFBCBC"
        />
        <path
          opacity="0.3"
          d="M483.441 89.5411C484.681 93.4634 482.614 97.1793 478.843 97.9018C475.072 98.6243 470.991 95.9922 469.751 92.07C468.511 88.1477 470.577 84.4318 474.349 83.7093C478.12 82.9867 482.201 85.6188 483.441 89.5411Z"
          fill="#B4B1B1"
        />
        <path
          opacity="0.35"
          d="M483.286 89.2314C484.474 93.1021 482.459 96.7664 478.688 97.4889C474.968 98.2114 470.939 95.631 469.751 91.8119C468.562 87.9412 470.577 84.2769 474.348 83.5544C478.12 82.8319 482.098 85.4124 483.286 89.283V89.2314Z"
          fill="#AAA6A6"
        />
        <path
          opacity="0.39"
          d="M483.083 88.9735C484.271 92.7926 482.205 96.4052 478.537 97.1277C474.869 97.8503 470.891 95.3214 469.754 91.5539C468.566 87.7349 470.632 84.1222 474.3 83.3997C477.968 82.6772 481.946 85.206 483.083 88.9735Z"
          fill="#9F9B9B"
        />
        <path
          opacity="0.43"
          d="M482.924 88.6638C484.061 92.4313 482.046 96.0439 478.43 96.7148C474.814 97.4373 470.887 94.9601 469.751 91.2442C468.614 87.4768 470.629 83.8641 474.245 83.1932C477.862 82.4707 481.788 84.9479 482.924 88.6638Z"
          fill="#949090"
        />
        <path
          opacity="0.48"
          d="M482.77 88.3541C483.906 92.07 481.891 95.631 478.275 96.3536C474.659 97.0761 470.887 94.6505 469.751 90.9862C468.614 87.2703 470.629 83.7093 474.245 82.9868C477.862 82.2643 481.633 84.6899 482.77 88.3541Z"
          fill="#8A8585"
        />
        <path
          opacity="0.52"
          d="M482.618 88.0446C483.703 91.6572 481.688 95.2182 478.175 95.9408C474.662 96.6633 470.891 94.2893 469.806 90.6766C468.721 87.064 470.736 83.503 474.249 82.7804C477.813 82.0579 481.533 84.4319 482.618 88.0446Z"
          fill="#7F7A7A"
        />
        <path
          opacity="0.57"
          d="M482.46 87.7866C483.494 91.3476 481.531 94.857 478.018 95.5796C474.505 96.3021 470.837 93.9797 469.752 90.4187C468.718 86.8576 470.682 83.3482 474.195 82.6257C477.708 81.9031 481.376 84.2256 482.46 87.7866Z"
          fill="#756F6F"
        />
        <path
          opacity="0.61"
          d="M482.305 87.4769C483.338 90.9863 481.375 94.4441 477.914 95.1667C474.452 95.8892 470.836 93.6184 469.803 90.109C468.77 86.5996 470.733 83.1417 474.194 82.4192C477.655 81.6967 481.272 83.9675 482.305 87.4769Z"
          fill="#6A6464"
        />
        <path
          opacity="0.65"
          d="M482.101 87.1671C483.082 90.6249 481.171 94.0311 477.71 94.8052C474.3 95.5278 470.735 93.3086 469.702 89.8508C468.72 86.393 470.684 82.9867 474.093 82.2126C477.503 81.4901 481.068 83.7093 482.101 87.1671Z"
          fill="#5F5959"
        />
        <path
          opacity="0.7"
          d="M481.945 86.8576C482.927 90.2638 480.964 93.67 477.606 94.3925C474.248 95.115 470.735 92.9475 469.753 89.5412C468.772 86.135 470.735 82.7288 474.093 82.0063C477.451 81.2838 480.964 83.4514 481.945 86.8576Z"
          fill="#554E4E"
        />
        <path
          opacity="0.74"
          d="M481.791 86.5994C482.721 89.954 480.809 93.3086 477.503 94.0312C474.197 94.7537 470.735 92.6377 469.805 89.2831C468.875 85.9285 470.787 82.5739 474.093 81.8514C477.4 81.1289 480.861 83.2448 481.791 86.5994Z"
          fill="#4A4343"
        />
        <path
          opacity="0.78"
          d="M481.633 86.2899C482.563 89.5928 480.651 92.8958 477.345 93.6184C474.09 94.3409 470.681 92.2765 469.751 88.9735C468.821 85.6706 470.732 82.3676 474.039 81.645C477.293 80.9225 480.703 82.9869 481.633 86.2899Z"
          fill="#3F3737"
        />
        <path
          opacity="0.83"
          d="M481.479 85.9803C482.357 89.2317 480.446 92.483 477.243 93.2572C474.04 93.9797 470.682 91.9669 469.803 88.7156C468.925 85.4642 470.837 82.2128 474.04 81.4387C477.243 80.6645 480.601 82.7289 481.479 85.9803Z"
          fill="#352C2C"
        />
        <path
          opacity="0.87"
          d="M481.324 85.6706C482.151 88.8704 480.291 92.0702 477.088 92.8443C473.885 93.6184 470.63 91.6057 469.803 88.4059C468.925 85.2062 470.837 82.0064 474.04 81.2323C477.243 80.4581 480.497 82.4709 481.324 85.6706Z"
          fill="#2A2121"
        />
        <path
          opacity="0.91"
          d="M481.116 85.4124C481.943 88.5605 480.083 91.7087 476.932 92.4828C473.78 93.257 470.577 91.2958 469.751 88.1477C468.924 84.9995 470.784 81.8513 473.935 81.0772C477.087 80.3031 480.29 82.2642 481.116 85.4124Z"
          fill="#1F1616"
        />
        <path
          opacity="0.96"
          d="M480.964 85.1029C481.739 88.1995 479.879 91.3476 476.831 92.0701C473.783 92.7927 470.58 90.9347 469.805 87.8382C469.03 84.7417 470.89 81.5935 473.938 80.871C476.986 80.1484 480.189 82.0064 480.964 85.1029Z"
          fill="#150B0B"
        />
        <path
          d="M480.807 84.7932C481.582 87.8381 479.723 90.9347 476.675 91.6572C473.626 92.3797 470.527 90.5734 469.804 87.5285C469.08 84.4835 470.888 81.387 473.936 80.6644C476.984 79.8903 480.084 81.7482 480.807 84.7932Z"
          fill="#0A0000"
        />
      </g>
      <rect x="469" y="79" width="13" height="13" rx="6.5" fill="#9E0404" />
      <path
        d="M477.82 80.3807C480.713 81.6193 482.057 84.9223 480.868 87.8124C479.629 90.7025 476.322 92.0444 473.429 90.8574C470.536 89.6188 469.193 86.3158 470.381 83.4257C471.621 80.5356 474.927 79.1937 477.82 80.3807Z"
        fill="url(#paint77_radial_12021_246197)"
      />
      <path
        opacity="0.5"
        d="M478.183 79.5034C481.541 80.9484 483.143 84.8191 481.748 88.1737C480.301 91.5283 476.427 93.1282 473.069 91.7347C469.711 90.2897 468.109 86.419 469.504 83.0644C470.95 79.7098 474.825 78.1099 478.183 79.5034Z"
        fill="url(#paint78_radial_12021_246197)"
      />
      <g
        filter="url(#filter30_f_12021_246197)"
        className="bulb"
        style={{
          animationDelay: '2ms',
          animationDuration: '2s',
          animationName: 'lightBulb',
          opacity: '0',
          animationIterationCount: 'infinite'
        }}>
        <rect
          x="456.789"
          y="66.4736"
          width="38.7368"
          height="35.3684"
          rx="17.6842"
          fill="#FFF0C9"
        />
      </g>
      <g filter="url(#filter31_f_12021_246197)">
        <rect x="471" y="80" width="10" height="11" rx="5" fill="white" />
      </g>
      <g clipPath="url(#clip0_12021_246197)">
        <path
          d="M382.387 411.292C409.898 399.823 422.894 368.247 411.414 340.763C399.934 313.28 368.325 300.297 340.814 311.765C313.302 323.234 300.306 354.811 311.787 382.294C323.267 409.777 354.875 422.76 382.387 411.292Z"
          fill="url(#paint79_linear_12021_246197)"
        />
        <path
          d="M361.582 414.303C390.756 414.303 414.406 390.677 414.406 361.533C414.406 332.388 390.756 308.762 361.582 308.762C332.408 308.762 308.758 332.388 308.758 361.533C308.758 390.677 332.408 414.303 361.582 414.303Z"
          fill="url(#paint80_linear_12021_246197)"
        />
        <path
          d="M380.754 409.453C407.248 398.861 420.131 368.819 409.529 342.351C398.926 315.884 368.854 303.014 342.359 313.605C315.865 324.197 302.982 354.239 313.584 380.707C324.186 407.174 354.259 420.044 380.754 409.453Z"
          fill="url(#paint81_linear_12021_246197)"
        />
        <path
          d="M361.581 412C389.481 412 412.099 389.405 412.099 361.533C412.099 333.661 389.481 311.066 361.581 311.066C333.68 311.066 311.062 333.661 311.062 361.533C311.062 389.405 333.68 412 361.581 412Z"
          fill="url(#paint82_linear_12021_246197)"
        />
        <path
          d="M361.582 410.795C388.817 410.795 410.895 388.74 410.895 361.533C410.895 334.326 388.817 312.27 361.582 312.27C334.348 312.27 312.27 334.326 312.27 361.533C312.27 388.74 334.348 410.795 361.582 410.795Z"
          fill="url(#paint83_linear_12021_246197)"
        />
        <path
          d="M380.084 405.968C404.634 395.744 416.24 367.574 406.005 343.049C395.771 318.524 367.572 306.93 343.022 317.154C318.472 327.378 306.867 355.548 317.101 380.073C327.336 404.599 355.534 416.192 380.084 405.968Z"
          fill="url(#paint84_linear_12021_246197)"
        />
        <path
          d="M361.585 408.492C387.546 408.492 408.592 387.468 408.592 361.533C408.592 335.598 387.546 314.573 361.585 314.573C335.624 314.573 314.578 335.598 314.578 361.533C314.578 387.468 335.624 408.492 361.585 408.492Z"
          fill="url(#paint85_linear_12021_246197)"
        />
        <path
          d="M361.583 407.288C386.879 407.288 407.385 386.803 407.385 361.533C407.385 336.263 386.879 315.778 361.583 315.778C336.287 315.778 315.781 336.263 315.781 361.533C315.781 386.803 336.287 407.288 361.583 407.288Z"
          fill="url(#paint86_linear_12021_246197)"
        />
        <path
          d="M378.759 402.724C401.521 393.249 412.285 367.135 402.8 344.396C393.316 321.657 367.175 310.905 344.413 320.379C321.651 329.854 310.888 355.968 320.372 378.707C329.856 401.446 355.997 412.198 378.759 402.724Z"
          fill="url(#paint87_linear_12021_246197)"
        />
        <path
          d="M361.582 404.984C385.604 404.984 405.078 385.53 405.078 361.533C405.078 337.535 385.604 318.081 361.582 318.081C337.56 318.081 318.086 337.535 318.086 361.533C318.086 385.53 337.56 404.984 361.582 404.984Z"
          fill="url(#paint88_linear_12021_246197)"
        />
        <path
          d="M361.585 403.833C384.971 403.833 403.928 384.894 403.928 361.533C403.928 338.171 384.971 319.233 361.585 319.233C338.2 319.233 319.242 338.171 319.242 361.533C319.242 384.894 338.2 403.833 361.585 403.833Z"
          fill="url(#paint89_linear_12021_246197)"
        />
        <path
          d="M361.583 402.629C384.303 402.629 402.721 384.229 402.721 361.533C402.721 338.836 384.303 320.437 361.583 320.437C338.863 320.437 320.445 338.836 320.445 361.533C320.445 384.229 338.863 402.629 361.583 402.629Z"
          fill="url(#paint90_linear_12021_246197)"
        />
        <path
          d="M361.582 401.477C383.665 401.477 401.567 383.593 401.567 361.533C401.567 339.472 383.665 321.589 361.582 321.589C339.499 321.589 321.598 339.472 321.598 361.533C321.598 383.593 339.499 401.477 361.582 401.477Z"
          fill="url(#paint91_linear_12021_246197)"
        />
        <path
          d="M399.364 370.386C404.276 349.531 391.334 328.647 370.458 323.74C349.582 318.833 328.677 331.761 323.765 352.616C318.853 373.471 331.795 394.355 352.671 399.262C373.547 404.169 394.452 391.241 399.364 370.386Z"
          fill="url(#paint92_linear_12021_246197)"
        />
        <path
          d="M361.584 399.121C382.364 399.121 399.21 382.292 399.21 361.533C399.21 340.773 382.364 323.944 361.584 323.944C340.803 323.944 323.957 340.773 323.957 361.533C323.957 382.292 340.803 399.121 361.584 399.121Z"
          fill="url(#paint93_linear_12021_246197)"
        />
        <path
          d="M361.583 397.969C381.727 397.969 398.057 381.656 398.057 361.533C398.057 341.409 381.727 325.096 361.583 325.096C341.439 325.096 325.109 341.409 325.109 361.533C325.109 381.656 341.439 397.969 361.583 397.969Z"
          fill="url(#paint94_linear_12021_246197)"
        />
        <path
          d="M396.903 361.533C396.903 381.007 381.077 396.817 361.582 396.817C342.088 396.817 326.262 381.007 326.262 361.533C326.262 342.058 342.088 326.248 361.582 326.248C381.077 326.248 396.903 342.058 396.903 361.533Z"
          fill="url(#paint95_linear_12021_246197)"
        />
        <path
          d="M361.582 395.666C380.452 395.666 395.75 380.384 395.75 361.533C395.75 342.681 380.452 327.399 361.582 327.399C342.712 327.399 327.414 342.681 327.414 361.533C327.414 380.384 342.712 395.666 361.582 395.666Z"
          fill="url(#paint96_linear_12021_246197)"
        />
        <path
          d="M394.546 361.533C394.546 379.751 379.768 394.462 361.584 394.462C343.399 394.462 328.621 379.699 328.621 361.533C328.621 343.367 343.399 328.604 361.584 328.604C379.768 328.604 394.546 343.367 394.546 361.533Z"
          fill="url(#paint97_linear_12021_246197)"
        />
        <path
          d="M361.583 393.31C379.151 393.31 393.393 379.083 393.393 361.533C393.393 343.983 379.151 329.755 361.583 329.755C344.015 329.755 329.773 343.983 329.773 361.533C329.773 379.083 344.015 393.31 361.583 393.31Z"
          fill="url(#paint98_linear_12021_246197)"
        />
        <path
          d="M392.239 361.533C392.239 378.442 378.509 392.158 361.583 392.158C344.656 392.158 330.926 378.442 330.926 361.533C330.926 344.623 344.656 330.907 361.583 330.907C378.509 330.907 392.239 344.623 392.239 361.533Z"
          fill="url(#paint99_linear_12021_246197)"
        />
        <path
          d="M391.035 361.533C391.035 377.814 377.829 391.007 361.531 391.007C345.233 391.007 332.027 377.814 332.027 361.533C332.027 345.251 345.233 332.059 361.531 332.059C377.829 332.059 391.035 345.251 391.035 361.533Z"
          fill="url(#paint100_linear_12021_246197)"
        />
        <path
          d="M361.58 389.802C377.209 389.802 389.878 377.146 389.878 361.533C389.878 345.92 377.209 333.263 361.58 333.263C345.951 333.263 333.281 345.92 333.281 361.533C333.281 377.146 345.951 389.802 361.58 389.802Z"
          fill="url(#paint101_linear_12021_246197)"
        />
        <path
          d="M388.729 361.533C388.729 376.505 376.571 388.651 361.583 388.651C346.595 388.651 334.438 376.505 334.438 361.533C334.438 346.56 346.595 334.415 361.583 334.415C376.571 334.415 388.729 346.56 388.729 361.533Z"
          fill="url(#paint102_linear_12021_246197)"
        />
        <path
          d="M387.524 361.533C387.524 375.877 375.891 387.499 361.532 387.499C347.173 387.499 335.539 375.877 335.539 361.533C335.539 347.188 347.173 335.566 361.532 335.566C375.891 335.566 387.524 347.188 387.524 361.533Z"
          fill="url(#paint103_linear_12021_246197)"
        />
        <path
          d="M361.58 386.295C375.27 386.295 386.368 375.209 386.368 361.533C386.368 347.857 375.27 336.771 361.58 336.771C347.891 336.771 336.793 347.857 336.793 361.533C336.793 375.209 347.891 386.295 361.58 386.295Z"
          fill="url(#paint104_linear_12021_246197)"
        />
        <path
          d="M385.214 361.533C385.214 374.568 374.629 385.143 361.58 385.143C348.531 385.143 337.945 374.568 337.945 361.533C337.945 348.497 348.531 337.922 361.58 337.922C374.629 337.922 385.214 348.497 385.214 361.533Z"
          fill="url(#paint105_linear_12021_246197)"
        />
        <path
          d="M384.065 361.533C384.065 373.94 374.003 383.992 361.583 383.992C349.163 383.992 339.102 373.94 339.102 361.533C339.102 349.125 349.163 339.074 361.583 339.074C374.003 339.074 384.065 349.125 384.065 361.533Z"
          fill="url(#paint106_linear_12021_246197)"
        />
        <path
          d="M361.583 382.84C373.362 382.84 382.911 373.3 382.911 361.533C382.911 349.765 373.362 340.226 361.583 340.226C349.803 340.226 340.254 349.765 340.254 361.533C340.254 373.3 349.803 382.84 361.583 382.84Z"
          fill="url(#paint107_linear_12021_246197)"
        />
        <path
          d="M381.704 361.533C381.704 372.631 372.69 381.636 361.58 381.636C350.471 381.636 341.457 372.631 341.457 361.533C341.457 350.434 350.471 341.43 361.58 341.43C372.69 341.43 381.704 350.434 381.704 361.533Z"
          fill="url(#paint108_linear_12021_246197)"
        />
        <path
          d="M380.55 361.533C380.55 372.003 372.061 380.484 361.58 380.484C351.099 380.484 342.609 372.003 342.609 361.533C342.609 351.062 351.099 342.582 361.58 342.582C372.061 342.582 380.55 351.062 380.55 361.533Z"
          fill="url(#paint109_linear_12021_246197)"
        />
        <path
          d="M361.583 379.332C371.424 379.332 379.401 371.363 379.401 361.533C379.401 351.702 371.424 343.733 361.583 343.733C351.743 343.733 343.766 351.702 343.766 361.533C343.766 371.363 351.743 379.332 361.583 379.332Z"
          fill="url(#paint110_linear_12021_246197)"
        />
        <path
          d="M378.193 361.533C378.193 370.694 370.752 378.128 361.581 378.128C352.41 378.128 344.969 370.694 344.969 361.533C344.969 352.371 352.41 344.938 361.581 344.938C370.752 344.938 378.193 352.371 378.193 361.533Z"
          fill="url(#paint111_linear_12021_246197)"
        />
        <path
          d="M361.58 376.976C370.118 376.976 377.04 370.062 377.04 361.533C377.04 353.003 370.118 346.089 361.58 346.089C353.043 346.089 346.121 353.003 346.121 361.533C346.121 370.062 353.043 376.976 361.58 376.976Z"
          fill="url(#paint112_linear_12021_246197)"
        />
        <path
          d="M375.886 361.533C375.886 369.438 369.493 375.825 361.58 375.825C353.667 375.825 347.273 369.438 347.273 361.533C347.273 353.628 353.667 347.241 361.58 347.241C369.493 347.241 375.886 353.628 375.886 361.533Z"
          fill="url(#paint113_linear_12021_246197)"
        />
        <path
          d="M374.682 361.533C374.682 368.757 368.813 374.673 361.529 374.673C354.244 374.673 348.375 368.809 348.375 361.533C348.375 354.256 354.244 348.392 361.529 348.392C368.813 348.392 374.682 354.256 374.682 361.533Z"
          fill="url(#paint114_linear_12021_246197)"
        />
        <path
          d="M361.581 373.469C368.18 373.469 373.529 368.125 373.529 361.533C373.529 354.94 368.18 349.596 361.581 349.596C354.982 349.596 349.633 354.94 349.633 361.533C349.633 368.125 354.982 373.469 361.581 373.469Z"
          fill="url(#paint115_linear_12021_246197)"
        />
        <path
          d="M372.376 361.533C372.376 367.501 367.555 372.317 361.581 372.317C355.606 372.317 350.785 367.501 350.785 361.533C350.785 355.565 355.606 350.748 361.581 350.748C367.555 350.748 372.376 355.565 372.376 361.533Z"
          fill="url(#paint116_linear_12021_246197)"
        />
        <path
          d="M371.222 361.533C371.222 366.873 366.925 371.165 361.58 371.165C356.235 371.165 351.938 366.873 351.938 361.533C351.938 356.193 356.235 351.9 361.58 351.9C366.925 351.9 371.222 356.193 371.222 361.533Z"
          fill="url(#paint117_linear_12021_246197)"
        />
        <path
          d="M361.579 370.014C366.268 370.014 370.069 366.217 370.069 361.533C370.069 356.849 366.268 353.052 361.579 353.052C356.891 353.052 353.09 356.849 353.09 361.533C353.09 366.217 356.891 370.014 361.579 370.014Z"
          fill="url(#paint118_linear_12021_246197)"
        />
        <path
          d="M368.865 361.533C368.865 365.564 365.616 368.81 361.581 368.81C357.546 368.81 354.297 365.564 354.297 361.533C354.297 357.502 357.546 354.256 361.581 354.256C365.616 354.256 368.865 357.502 368.865 361.533Z"
          fill="url(#paint119_linear_12021_246197)"
        />
        <path
          d="M367.712 361.533C367.712 364.936 364.987 367.658 361.581 367.658C358.174 367.658 355.449 364.936 355.449 361.533C355.449 358.13 358.174 355.408 361.581 355.408C364.987 355.408 367.712 358.13 367.712 361.533Z"
          fill="url(#paint120_linear_12021_246197)"
        />
        <path
          d="M361.58 366.506C364.33 366.506 366.558 364.279 366.558 361.533C366.558 358.786 364.33 356.559 361.58 356.559C358.831 356.559 356.602 358.786 356.602 361.533C356.602 364.279 358.831 366.506 361.58 366.506Z"
          fill="url(#paint121_linear_12021_246197)"
        />
        <path
          d="M365.351 361.532C365.351 363.627 363.674 365.302 361.578 365.302C359.482 365.302 357.805 363.627 357.805 361.532C357.805 359.438 359.482 357.763 361.578 357.763C363.674 357.763 365.351 359.438 365.351 361.532Z"
          fill="url(#paint122_linear_12021_246197)"
        />
        <path
          d="M364.201 361.533C364.201 362.998 363.049 364.15 361.581 364.15C360.114 364.15 358.961 362.998 358.961 361.533C358.961 360.067 360.114 358.915 361.581 358.915C363.049 358.915 364.201 360.067 364.201 361.533Z"
          fill="url(#paint123_linear_12021_246197)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12021_246197"
          x="328.867"
          y="88.0488"
          width="61.0859"
          height="57.1836"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12021_246197"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12021_246197"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12021_246197"
          x="321.934"
          y="48"
          width="74.6445"
          height="72.6147"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12021_246197"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12021_246197"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_f_12021_246197"
          x="118.789"
          y="99.4736"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter3_f_12021_246197"
          x="154"
          y="134"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter4_f_12021_246197"
          x="17.7891"
          y="317.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter5_f_12021_246197"
          x="53"
          y="352"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter6_f_12021_246197"
          x="104.789"
          y="528.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter7_f_12021_246197"
          x="140"
          y="563"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter8_f_12021_246197"
          x="316.789"
          y="615.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter9_f_12021_246197"
          x="352"
          y="650"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter10_f_12021_246197"
          x="527.789"
          y="527.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter11_f_12021_246197"
          x="563"
          y="562"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter12_f_12021_246197"
          x="614.789"
          y="316.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter13_f_12021_246197"
          x="650"
          y="351"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter14_f_12021_246197"
          x="526.789"
          y="105.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter15_f_12021_246197"
          x="562"
          y="140"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter16_f_12021_246197"
          x="40.7891"
          y="200.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter17_f_12021_246197"
          x="76"
          y="235"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter18_f_12021_246197"
          x="203.789"
          y="39.4736"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter19_f_12021_246197"
          x="239"
          y="74"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter20_f_12021_246197"
          x="39.7891"
          y="428.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter21_f_12021_246197"
          x="75"
          y="463"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter22_f_12021_246197"
          x="200.789"
          y="592.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter23_f_12021_246197"
          x="236"
          y="627"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter24_f_12021_246197"
          x="428.789"
          y="593.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter25_f_12021_246197"
          x="464"
          y="628"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter26_f_12021_246197"
          x="591.789"
          y="433.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter27_f_12021_246197"
          x="627"
          y="468"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter28_f_12021_246197"
          x="593.789"
          y="203.474"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter29_f_12021_246197"
          x="629"
          y="238"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter30_f_12021_246197"
          x="431.789"
          y="41.4736"
          width="88.7383"
          height="85.3684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter31_f_12021_246197"
          x="467"
          y="76"
          width="18"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12021_246197" />
        </filter>
        <filter
          id="filter32_i_12021_246197"
          x="309.137"
          y="309.637"
          width="109.001"
          height="109"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.27451" dy="4.27451" />
          <feGaussianBlur stdDeviation="10.6863" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.102545 0 0 0 0 0 0 0 0 0 0.120833 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12021_246197" />
        </filter>
        <linearGradient
          id="paint0_linear_12021_246197"
          x1="361.745"
          y1="41.1225"
          x2="361.745"
          y2="654.601"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3616" />
          <stop offset="1" stopColor="#D83013" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12021_246197"
          x1="361.745"
          y1="45.7673"
          x2="361.745"
          y2="659.245"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D30300" />
          <stop offset="1" stopColor="#850400" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12021_246197"
          x1="359.41"
          y1="87.894"
          x2="359.41"
          y2="148.432"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12021_246197"
          x1="359.41"
          y1="87.8939"
          x2="359.41"
          y2="130.213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12021_246197"
          x1="359.41"
          y1="88.3389"
          x2="353"
          y2="193.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB1" />
          <stop offset="0.14" stopColor="#C4A149" />
          <stop offset="0.24" stopColor="#9D6F1F" />
          <stop offset="0.3" stopColor="#895814" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#78460D" />
          <stop offset="0.77" stopColor="#C1A04D" />
          <stop offset="0.88" stopColor="#B9903A" />
          <stop offset="0.93" stopColor="#AE822D" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="127.323"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="126.549"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12021_246197"
          x1="359.233"
          y1="47.7936"
          x2="359.233"
          y2="125.723"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="124.898"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12021_246197"
          x1="359.342"
          y1="47.7936"
          x2="359.342"
          y2="124.072"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12021_246197"
          x1="359.204"
          y1="47.7936"
          x2="359.204"
          y2="123.246"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="122.42"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="121.595"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12021_246197"
          x1="359.464"
          y1="47.7936"
          x2="359.464"
          y2="120.769"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12021_246197"
          x1="359.378"
          y1="47.7936"
          x2="359.378"
          y2="119.943"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="119.117"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12021_246197"
          x1="359.351"
          y1="47.7936"
          x2="359.351"
          y2="118.292"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12021_246197"
          x1="359.322"
          y1="47.7936"
          x2="359.322"
          y2="117.466"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="116.692"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="115.866"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_12021_246197"
          x1="359.358"
          y1="47.7936"
          x2="359.358"
          y2="115.04"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12021_246197"
          x1="359.192"
          y1="47.7936"
          x2="359.192"
          y2="114.215"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12021_246197"
          x1="359.396"
          y1="47.7936"
          x2="359.396"
          y2="113.389"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="112.563"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="111.737"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="110.912"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="110.086"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_12021_246197"
          x1="359.373"
          y1="47.7936"
          x2="359.373"
          y2="109.26"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_12021_246197"
          x1="359.259"
          y1="47.7936"
          x2="359.259"
          y2="108.434"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB1" />
          <stop offset="0.149561" stopColor="#C4A149" />
          <stop offset="0.297738" stopColor="#9D6F1F" />
          <stop offset="0.410353" stopColor="#895814" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#78460D" />
          <stop offset="0.77" stopColor="#C1A04D" />
          <stop offset="0.88" stopColor="#B9903A" />
          <stop offset="0.93" stopColor="#AE822D" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.130208" stopColor="#F4ECB1" />
          <stop offset="0.244792" stopColor="#C4A149" stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB1" />
          <stop offset="0.149561" stopColor="#C4A149" />
          <stop offset="0.297738" stopColor="#9D6F1F" />
          <stop offset="0.410353" stopColor="#895814" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#78460D" />
          <stop offset="0.77" stopColor="#C1A04D" />
          <stop offset="0.88" stopColor="#B9903A" />
          <stop offset="0.93" stopColor="#AE822D" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.130208" stopColor="#F4ECB1" />
          <stop offset="0.244792" stopColor="#C4A149" stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB1" />
          <stop offset="0.149561" stopColor="#C4A149" />
          <stop offset="0.297738" stopColor="#9D6F1F" />
          <stop offset="0.410353" stopColor="#895814" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#78460D" />
          <stop offset="0.77" stopColor="#C1A04D" />
          <stop offset="0.88" stopColor="#B9903A" />
          <stop offset="0.93" stopColor="#AE822D" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_12021_246197"
          x1="359.255"
          y1="48.3811"
          x2="359.255"
          y2="112.572"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.130208" stopColor="#F4ECB1" />
          <stop offset="0.244792" stopColor="#C4A149" stopOpacity="0.08" />
        </linearGradient>
        <radialGradient
          id="paint35_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(160.193 145.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(159.856 145.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint37_linear_12021_246197"
          x1="154.021"
          y1="141.364"
          x2="165.015"
          y2="144.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_12021_246197"
          x1="168.523"
          y1="144.977"
          x2="162.715"
          y2="143.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint39_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(59.1925 363.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.8564 363.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint41_linear_12021_246197"
          x1="53.0212"
          y1="359.364"
          x2="64.0152"
          y2="362.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_12021_246197"
          x1="67.5232"
          y1="362.977"
          x2="61.7152"
          y2="361.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint43_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(146.193 574.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(145.856 574.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint45_linear_12021_246197"
          x1="140.021"
          y1="570.364"
          x2="151.015"
          y2="573.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_12021_246197"
          x1="154.523"
          y1="573.977"
          x2="148.715"
          y2="572.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint47_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(358.193 661.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint48_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(357.856 661.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint49_linear_12021_246197"
          x1="352.021"
          y1="657.364"
          x2="363.015"
          y2="660.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_12021_246197"
          x1="366.523"
          y1="660.977"
          x2="360.715"
          y2="659.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint51_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(569.193 573.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint52_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(568.856 573.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint53_linear_12021_246197"
          x1="563.021"
          y1="569.364"
          x2="574.015"
          y2="572.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_12021_246197"
          x1="577.523"
          y1="572.977"
          x2="571.715"
          y2="571.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint55_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(656.193 362.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint56_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(655.856 362.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint57_linear_12021_246197"
          x1="650.021"
          y1="358.364"
          x2="661.015"
          y2="361.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_12021_246197"
          x1="664.523"
          y1="361.977"
          x2="658.715"
          y2="360.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint59_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(568.193 151.708) rotate(-44.9709) scale(7.38386 7.38385)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <radialGradient
          id="paint60_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(567.856 151.596) rotate(-44.9709) scale(8.63344 8.63343)">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" stopColor="#FFA200" />
          <stop offset="0.94" stopColor="#FFCB59" />
        </radialGradient>
        <linearGradient
          id="paint61_linear_12021_246197"
          x1="562.021"
          y1="147.364"
          x2="573.015"
          y2="150.398"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_12021_246197"
          x1="576.523"
          y1="150.977"
          x2="570.715"
          y2="149.989"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stopColor="#E0E0E0" />
          <stop offset="0.81" />
        </linearGradient>
        <radialGradient
          id="paint63_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82.9476 247.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint64_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82.5725 247.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint65_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(245.948 86.4924) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint66_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(245.573 86.5066) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint67_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(81.9476 475.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint68_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(81.5725 475.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint69_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(242.948 639.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint70_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(242.573 639.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint71_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(470.948 640.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint72_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(470.573 640.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint73_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(633.948 480.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint74_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(633.573 480.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint75_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(635.948 250.492) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint76_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(635.573 250.507) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint77_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(473.948 88.4924) rotate(-67.1292) scale(7.38124 7.38648)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <radialGradient
          id="paint78_radial_12021_246197"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(473.573 88.5066) rotate(-67.1291) scale(8.63037 8.6365)">
          <stop offset="0.08" stopColor="#E0D7E0" />
          <stop offset="0.81" stopColor="#FF2900" />
          <stop offset="0.94" stopColor="#FF5D2C" />
        </radialGradient>
        <linearGradient
          id="paint79_linear_12021_246197"
          x1="361.577"
          y1="308.014"
          x2="361.577"
          y2="415.282"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE596" />
          <stop offset="0.14" stopColor="#BD9533" />
          <stop offset="0.24" stopColor="#916119" />
          <stop offset="0.3" stopColor="#7A460C" />
          <stop offset="0.57" stopColor="#723D07" />
          <stop offset="0.59" stopColor="#7B4910" />
          <stop offset="0.77" stopColor="#D4BB62" />
          <stop offset="0.88" stopColor="#C39F46" />
          <stop offset="0.93" stopColor="#BB9138" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_12021_246197"
          x1="361.582"
          y1="309.234"
          x2="361.582"
          y2="414.146"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0E598" />
          <stop offset="0.14" stopColor="#BD9635" />
          <stop offset="0.24" stopColor="#926219" />
          <stop offset="0.3" stopColor="#7B480C" />
          <stop offset="0.57" stopColor="#723D07" />
          <stop offset="0.59" stopColor="#7B4810" />
          <stop offset="0.77" stopColor="#D3B960" />
          <stop offset="0.88" stopColor="#C29E45" />
          <stop offset="0.93" stopColor="#BA9037" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_12021_246197"
          x1="361.575"
          y1="310.369"
          x2="361.575"
          y2="412.978"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0E69B" />
          <stop offset="0.14" stopColor="#BE9736" />
          <stop offset="0.24" stopColor="#93631A" />
          <stop offset="0.3" stopColor="#7D490D" />
          <stop offset="0.57" stopColor="#723D07" />
          <stop offset="0.59" stopColor="#7A480F" />
          <stop offset="0.77" stopColor="#D1B65F" />
          <stop offset="0.88" stopColor="#C19C44" />
          <stop offset="0.93" stopColor="#B98E36" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_12021_246197"
          x1="361.581"
          y1="311.537"
          x2="361.581"
          y2="411.843"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0E69D" />
          <stop offset="0.14" stopColor="#BE9838" />
          <stop offset="0.24" stopColor="#94651A" />
          <stop offset="0.3" stopColor="#7E4B0E" />
          <stop offset="0.57" stopColor="#723D07" />
          <stop offset="0.59" stopColor="#7A480F" />
          <stop offset="0.77" stopColor="#D0B45D" />
          <stop offset="0.88" stopColor="#C19B43" />
          <stop offset="0.93" stopColor="#B88D35" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_12021_246197"
          x1="361.582"
          y1="312.741"
          x2="361.582"
          y2="410.691"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E79F" />
          <stop offset="0.14" stopColor="#BF993A" />
          <stop offset="0.24" stopColor="#95661B" />
          <stop offset="0.3" stopColor="#7F4C0F" />
          <stop offset="0.57" stopColor="#723E07" />
          <stop offset="0.59" stopColor="#7A480F" />
          <stop offset="0.77" stopColor="#CEB25B" />
          <stop offset="0.88" stopColor="#C09A42" />
          <stop offset="0.93" stopColor="#B78C34" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_12021_246197"
          x1="361.588"
          y1="313.889"
          x2="361.588"
          y2="409.535"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E7A1" />
          <stop offset="0.14" stopColor="#C09A3C" />
          <stop offset="0.24" stopColor="#96671B" />
          <stop offset="0.3" stopColor="#804E0F" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#7A470F" />
          <stop offset="0.77" stopColor="#CCB059" />
          <stop offset="0.88" stopColor="#BF9941" />
          <stop offset="0.93" stopColor="#B58B34" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_12021_246197"
          x1="361.585"
          y1="315.097"
          x2="361.585"
          y2="408.387"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E8A4" />
          <stop offset="0.14" stopColor="#C09B3E" />
          <stop offset="0.24" stopColor="#97681C" />
          <stop offset="0.3" stopColor="#824F10" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#7A470F" />
          <stop offset="0.77" stopColor="#CBAE58" />
          <stop offset="0.88" stopColor="#BE9840" />
          <stop offset="0.93" stopColor="#B48A33" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_12021_246197"
          x1="361.583"
          y1="316.249"
          x2="361.583"
          y2="407.183"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2E9A6" />
          <stop offset="0.14" stopColor="#C19C40" />
          <stop offset="0.24" stopColor="#98691C" />
          <stop offset="0.3" stopColor="#835111" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#79470E" />
          <stop offset="0.77" stopColor="#C9AB56" />
          <stop offset="0.88" stopColor="#BD963F" />
          <stop offset="0.93" stopColor="#B38832" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_12021_246197"
          x1="361.601"
          y1="317.396"
          x2="361.601"
          y2="406.027"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2E9A8" />
          <stop offset="0.14" stopColor="#C29D41" />
          <stop offset="0.24" stopColor="#996A1D" />
          <stop offset="0.3" stopColor="#845211" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#79470E" />
          <stop offset="0.77" stopColor="#C7A954" />
          <stop offset="0.88" stopColor="#BC953E" />
          <stop offset="0.93" stopColor="#B28731" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_12021_246197"
          x1="361.582"
          y1="318.552"
          x2="361.582"
          y2="404.88"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EAAA" />
          <stop offset="0.14" stopColor="#C29E43" />
          <stop offset="0.24" stopColor="#9A6C1E" />
          <stop offset="0.3" stopColor="#855412" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#79460E" />
          <stop offset="0.77" stopColor="#C6A752" />
          <stop offset="0.88" stopColor="#BB943D" />
          <stop offset="0.93" stopColor="#B18630" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_12021_246197"
          x1="361.585"
          y1="319.756"
          x2="361.585"
          y2="403.728"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EAAC" />
          <stop offset="0.14" stopColor="#C39F45" />
          <stop offset="0.24" stopColor="#9B6D1E" />
          <stop offset="0.3" stopColor="#875513" />
          <stop offset="0.57" stopColor="#733E07" />
          <stop offset="0.59" stopColor="#79460E" />
          <stop offset="0.77" stopColor="#C4A550" />
          <stop offset="0.88" stopColor="#BA933C" />
          <stop offset="0.93" stopColor="#B0852F" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_12021_246197"
          x1="361.583"
          y1="320.908"
          x2="361.583"
          y2="402.576"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EBAF" />
          <stop offset="0.14" stopColor="#C3A047" />
          <stop offset="0.24" stopColor="#9C6E1F" />
          <stop offset="0.3" stopColor="#885714" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#79460E" />
          <stop offset="0.77" stopColor="#C3A34F" />
          <stop offset="0.88" stopColor="#BA923B" />
          <stop offset="0.93" stopColor="#AF842E" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_12021_246197"
          x1="361.582"
          y1="322.06"
          x2="361.582"
          y2="401.425"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB1" />
          <stop offset="0.14" stopColor="#C4A149" />
          <stop offset="0.24" stopColor="#9D6F1F" />
          <stop offset="0.3" stopColor="#895814" />
          <stop offset="0.57" stopColor="#733E08" />
          <stop offset="0.59" stopColor="#78460D" />
          <stop offset="0.77" stopColor="#C1A04D" />
          <stop offset="0.88" stopColor="#B9903A" />
          <stop offset="0.93" stopColor="#AE822D" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_12021_246197"
          x1="361.594"
          y1="323.204"
          x2="361.594"
          y2="400.265"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4ECB3" />
          <stop offset="0.14" stopColor="#C5A24B" />
          <stop offset="0.24" stopColor="#9E7020" />
          <stop offset="0.3" stopColor="#8A5A15" />
          <stop offset="0.57" stopColor="#733F08" />
          <stop offset="0.59" stopColor="#78450D" />
          <stop offset="0.77" stopColor="#BF9E4B" />
          <stop offset="0.88" stopColor="#B88F39" />
          <stop offset="0.93" stopColor="#AD812C" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_12021_246197"
          x1="361.584"
          y1="324.415"
          x2="361.584"
          y2="399.069"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4EDB5" />
          <stop offset="0.14" stopColor="#C5A34C" />
          <stop offset="0.24" stopColor="#9F7120" />
          <stop offset="0.3" stopColor="#8C5B16" />
          <stop offset="0.57" stopColor="#733F08" />
          <stop offset="0.59" stopColor="#78450D" />
          <stop offset="0.77" stopColor="#BE9C49" />
          <stop offset="0.88" stopColor="#B78E38" />
          <stop offset="0.93" stopColor="#AC802B" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_12021_246197"
          x1="361.583"
          y1="325.567"
          x2="361.583"
          y2="397.917"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F5EDB8" />
          <stop offset="0.14" stopColor="#C6A44E" />
          <stop offset="0.24" stopColor="#A07221" />
          <stop offset="0.3" stopColor="#8D5C16" />
          <stop offset="0.57" stopColor="#733F08" />
          <stop offset="0.59" stopColor="#78450D" />
          <stop offset="0.77" stopColor="#BC9A48" />
          <stop offset="0.88" stopColor="#B68D37" />
          <stop offset="0.93" stopColor="#AB7F2A" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_12021_246197"
          x1="361.582"
          y1="326.719"
          x2="361.582"
          y2="396.765"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F5EEBA" />
          <stop offset="0.14" stopColor="#C7A550" />
          <stop offset="0.24" stopColor="#A17421" />
          <stop offset="0.3" stopColor="#8E5E17" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#78440D" />
          <stop offset="0.77" stopColor="#BA9846" />
          <stop offset="0.88" stopColor="#B58C36" />
          <stop offset="0.93" stopColor="#A97E2A" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_12021_246197"
          x1="361.582"
          y1="327.871"
          x2="361.582"
          y2="395.613"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F5EEBC" />
          <stop offset="0.14" stopColor="#C7A652" />
          <stop offset="0.24" stopColor="#A27522" />
          <stop offset="0.3" stopColor="#8F5F18" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#77440C" />
          <stop offset="0.77" stopColor="#B99544" />
          <stop offset="0.88" stopColor="#B48A35" />
          <stop offset="0.93" stopColor="#A87C29" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_12021_246197"
          x1="361.584"
          y1="329.023"
          x2="361.584"
          y2="394.462"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F6EFBE" />
          <stop offset="0.14" stopColor="#C8A754" />
          <stop offset="0.24" stopColor="#A37623" />
          <stop offset="0.3" stopColor="#916119" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#77440C" />
          <stop offset="0.77" stopColor="#B79342" />
          <stop offset="0.88" stopColor="#B48934" />
          <stop offset="0.93" stopColor="#A77B28" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_12021_246197"
          x1="361.583"
          y1="330.227"
          x2="361.583"
          y2="393.258"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F6F0C0" />
          <stop offset="0.14" stopColor="#C8A855" />
          <stop offset="0.24" stopColor="#A47723" />
          <stop offset="0.3" stopColor="#926219" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#77440C" />
          <stop offset="0.77" stopColor="#B69140" />
          <stop offset="0.88" stopColor="#B38833" />
          <stop offset="0.93" stopColor="#A67A27" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_12021_246197"
          x1="361.583"
          y1="331.378"
          x2="361.583"
          y2="392.106"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F6F0C3" />
          <stop offset="0.14" stopColor="#C9A957" />
          <stop offset="0.24" stopColor="#A57824" />
          <stop offset="0.3" stopColor="#93641A" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#77430C" />
          <stop offset="0.77" stopColor="#B48F3F" />
          <stop offset="0.88" stopColor="#B28732" />
          <stop offset="0.93" stopColor="#A57926" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_12021_246197"
          x1="361.584"
          y1="332.53"
          x2="361.584"
          y2="390.954"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7F1C5" />
          <stop offset="0.14" stopColor="#CAAA59" />
          <stop offset="0.24" stopColor="#A67924" />
          <stop offset="0.3" stopColor="#94651B" />
          <stop offset="0.57" stopColor="#743F08" />
          <stop offset="0.59" stopColor="#77430C" />
          <stop offset="0.77" stopColor="#B28D3D" />
          <stop offset="0.88" stopColor="#B18631" />
          <stop offset="0.93" stopColor="#A47825" />
          <stop offset="1" stopColor="#734A00" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_12021_246197"
          x1="361.58"
          y1="333.682"
          x2="361.58"
          y2="389.802"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7F1C7" />
          <stop offset="0.14" stopColor="#CAAA5B" />
          <stop offset="0.24" stopColor="#A67B25" />
          <stop offset="0.3" stopColor="#96671B" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#76430B" />
          <stop offset="0.77" stopColor="#B18A3B" />
          <stop offset="0.88" stopColor="#B08431" />
          <stop offset="0.93" stopColor="#A37624" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_12021_246197"
          x1="361.583"
          y1="334.834"
          x2="361.583"
          y2="388.651"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8F2C9" />
          <stop offset="0.14" stopColor="#CBAB5D" />
          <stop offset="0.24" stopColor="#A77C25" />
          <stop offset="0.3" stopColor="#97681C" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#76430B" />
          <stop offset="0.77" stopColor="#AF8839" />
          <stop offset="0.88" stopColor="#AF8330" />
          <stop offset="0.93" stopColor="#A27523" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_12021_246197"
          x1="361.584"
          y1="335.985"
          x2="361.584"
          y2="387.447"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8F2CC" />
          <stop offset="0.14" stopColor="#CCAC5F" />
          <stop offset="0.24" stopColor="#A87D26" />
          <stop offset="0.3" stopColor="#986A1D" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#76420B" />
          <stop offset="0.77" stopColor="#AD8638" />
          <stop offset="0.88" stopColor="#AE822F" />
          <stop offset="0.93" stopColor="#A17422" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint104_linear_12021_246197"
          x1="361.58"
          y1="337.137"
          x2="361.58"
          y2="386.295"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8F3CE" />
          <stop offset="0.14" stopColor="#CCAD60" />
          <stop offset="0.24" stopColor="#A97E26" />
          <stop offset="0.3" stopColor="#996B1D" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#76420B" />
          <stop offset="0.77" stopColor="#AC8436" />
          <stop offset="0.88" stopColor="#AD812E" />
          <stop offset="0.93" stopColor="#A07321" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint105_linear_12021_246197"
          x1="361.58"
          y1="338.289"
          x2="361.58"
          y2="385.143"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F4D0" />
          <stop offset="0.14" stopColor="#CDAE62" />
          <stop offset="0.24" stopColor="#AA7F27" />
          <stop offset="0.3" stopColor="#9B6D1E" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#76420B" />
          <stop offset="0.77" stopColor="#AA8234" />
          <stop offset="0.88" stopColor="#AD802D" />
          <stop offset="0.93" stopColor="#9F7220" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_12021_246197"
          x1="361.583"
          y1="339.44"
          x2="361.583"
          y2="383.992"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F4D2" />
          <stop offset="0.14" stopColor="#CDAF64" />
          <stop offset="0.24" stopColor="#AB8028" />
          <stop offset="0.3" stopColor="#9C6E1F" />
          <stop offset="0.57" stopColor="#744008" />
          <stop offset="0.59" stopColor="#75420A" />
          <stop offset="0.77" stopColor="#A97F32" />
          <stop offset="0.88" stopColor="#AC7E2C" />
          <stop offset="0.93" stopColor="#9E701F" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint107_linear_12021_246197"
          x1="361.583"
          y1="340.592"
          x2="361.583"
          y2="382.84"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F5D4" />
          <stop offset="0.14" stopColor="#CEB066" />
          <stop offset="0.24" stopColor="#AC8228" />
          <stop offset="0.3" stopColor="#9D7020" />
          <stop offset="0.57" stopColor="#754008" />
          <stop offset="0.59" stopColor="#75410A" />
          <stop offset="0.77" stopColor="#A77D30" />
          <stop offset="0.88" stopColor="#AB7D2B" />
          <stop offset="0.93" stopColor="#9C6F1F" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint108_linear_12021_246197"
          x1="361.58"
          y1="341.744"
          x2="361.58"
          y2="381.636"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAF5D7" />
          <stop offset="0.14" stopColor="#CFB168" />
          <stop offset="0.24" stopColor="#AD8329" />
          <stop offset="0.3" stopColor="#9E7120" />
          <stop offset="0.57" stopColor="#754008" />
          <stop offset="0.59" stopColor="#75410A" />
          <stop offset="0.77" stopColor="#A57B2F" />
          <stop offset="0.88" stopColor="#AA7C2A" />
          <stop offset="0.93" stopColor="#9B6E1E" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint109_linear_12021_246197"
          x1="361.58"
          y1="342.896"
          x2="361.58"
          y2="380.484"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAF6D9" />
          <stop offset="0.14" stopColor="#CFB269" />
          <stop offset="0.24" stopColor="#AE8429" />
          <stop offset="0.3" stopColor="#A07221" />
          <stop offset="0.57" stopColor="#754008" />
          <stop offset="0.59" stopColor="#75410A" />
          <stop offset="0.77" stopColor="#A4792D" />
          <stop offset="0.88" stopColor="#A97B29" />
          <stop offset="0.93" stopColor="#9A6D1D" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint110_linear_12021_246197"
          x1="361.583"
          y1="344.048"
          x2="361.583"
          y2="379.332"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAF6DB" />
          <stop offset="0.14" stopColor="#D0B36B" />
          <stop offset="0.24" stopColor="#AF852A" />
          <stop offset="0.3" stopColor="#A17422" />
          <stop offset="0.57" stopColor="#754108" />
          <stop offset="0.59" stopColor="#75400A" />
          <stop offset="0.77" stopColor="#A2772B" />
          <stop offset="0.88" stopColor="#A87A28" />
          <stop offset="0.93" stopColor="#996C1C" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint111_linear_12021_246197"
          x1="361.581"
          y1="345.199"
          x2="361.581"
          y2="378.181"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBF7DD" />
          <stop offset="0.14" stopColor="#D1B46D" />
          <stop offset="0.24" stopColor="#B0862A" />
          <stop offset="0.3" stopColor="#A27522" />
          <stop offset="0.57" stopColor="#754108" />
          <stop offset="0.59" stopColor="#744009" />
          <stop offset="0.77" stopColor="#A07429" />
          <stop offset="0.88" stopColor="#A77827" />
          <stop offset="0.93" stopColor="#986A1B" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint112_linear_12021_246197"
          x1="361.58"
          y1="346.351"
          x2="361.58"
          y2="376.976"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBF8E0" />
          <stop offset="0.14" stopColor="#D1B56F" />
          <stop offset="0.24" stopColor="#B1872B" />
          <stop offset="0.3" stopColor="#A37723" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#744009" />
          <stop offset="0.77" stopColor="#9F7228" />
          <stop offset="0.88" stopColor="#A77726" />
          <stop offset="0.93" stopColor="#97691A" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint113_linear_12021_246197"
          x1="361.58"
          y1="347.502"
          x2="361.58"
          y2="375.825"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBF8E2" />
          <stop offset="0.14" stopColor="#D2B671" />
          <stop offset="0.24" stopColor="#B2882B" />
          <stop offset="0.3" stopColor="#A57824" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#744009" />
          <stop offset="0.77" stopColor="#9D7026" />
          <stop offset="0.88" stopColor="#A67625" />
          <stop offset="0.93" stopColor="#966819" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint114_linear_12021_246197"
          x1="361.581"
          y1="348.654"
          x2="361.581"
          y2="374.673"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCF9E4" />
          <stop offset="0.14" stopColor="#D2B773" />
          <stop offset="0.24" stopColor="#B38A2C" />
          <stop offset="0.3" stopColor="#A67A25" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#743F09" />
          <stop offset="0.77" stopColor="#9C6E24" />
          <stop offset="0.88" stopColor="#A57524" />
          <stop offset="0.93" stopColor="#956718" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint115_linear_12021_246197"
          x1="361.581"
          y1="349.806"
          x2="361.581"
          y2="373.469"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCF9E6" />
          <stop offset="0.14" stopColor="#D3B874" />
          <stop offset="0.24" stopColor="#B48B2D" />
          <stop offset="0.3" stopColor="#A77B25" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#743F09" />
          <stop offset="0.77" stopColor="#9A6C22" />
          <stop offset="0.88" stopColor="#A47423" />
          <stop offset="0.93" stopColor="#946617" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint116_linear_12021_246197"
          x1="361.581"
          y1="350.958"
          x2="361.581"
          y2="372.317"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFAE8" />
          <stop offset="0.14" stopColor="#D4B976" />
          <stop offset="0.24" stopColor="#B58C2D" />
          <stop offset="0.3" stopColor="#A87D26" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#733F08" />
          <stop offset="0.77" stopColor="#986920" />
          <stop offset="0.88" stopColor="#A37222" />
          <stop offset="0.93" stopColor="#936416" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint117_linear_12021_246197"
          x1="361.58"
          y1="352.11"
          x2="361.58"
          y2="371.165"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFBEB" />
          <stop offset="0.14" stopColor="#D4BA78" />
          <stop offset="0.24" stopColor="#B68D2E" />
          <stop offset="0.3" stopColor="#AA7E27" />
          <stop offset="0.57" stopColor="#754109" />
          <stop offset="0.59" stopColor="#733F08" />
          <stop offset="0.77" stopColor="#97671F" />
          <stop offset="0.88" stopColor="#A27121" />
          <stop offset="0.93" stopColor="#926315" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint118_linear_12021_246197"
          x1="361.579"
          y1="353.261"
          x2="361.579"
          y2="370.014"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFBED" />
          <stop offset="0.14" stopColor="#D5BB7A" />
          <stop offset="0.24" stopColor="#B78E2E" />
          <stop offset="0.3" stopColor="#AB8027" />
          <stop offset="0.57" stopColor="#764109" />
          <stop offset="0.59" stopColor="#733E08" />
          <stop offset="0.77" stopColor="#95651D" />
          <stop offset="0.88" stopColor="#A17020" />
          <stop offset="0.93" stopColor="#906215" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint119_linear_12021_246197"
          x1="361.581"
          y1="354.361"
          x2="361.581"
          y2="368.81"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEFCEF" />
          <stop offset="0.14" stopColor="#D6BC7C" />
          <stop offset="0.24" stopColor="#B88F2F" />
          <stop offset="0.3" stopColor="#AC8128" />
          <stop offset="0.57" stopColor="#764209" />
          <stop offset="0.59" stopColor="#733E08" />
          <stop offset="0.77" stopColor="#93631B" />
          <stop offset="0.88" stopColor="#A06F1F" />
          <stop offset="0.93" stopColor="#8F6114" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint120_linear_12021_246197"
          x1="361.581"
          y1="355.512"
          x2="361.581"
          y2="367.658"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEFCF1" />
          <stop offset="0.14" stopColor="#D6BD7E" />
          <stop offset="0.24" stopColor="#B9912F" />
          <stop offset="0.3" stopColor="#AD8329" />
          <stop offset="0.57" stopColor="#764209" />
          <stop offset="0.59" stopColor="#733E08" />
          <stop offset="0.77" stopColor="#926119" />
          <stop offset="0.88" stopColor="#A06E1E" />
          <stop offset="0.93" stopColor="#8E6013" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint121_linear_12021_246197"
          x1="361.58"
          y1="356.664"
          x2="361.58"
          y2="366.506"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEFDF4" />
          <stop offset="0.14" stopColor="#D7BE7F" />
          <stop offset="0.24" stopColor="#BA9230" />
          <stop offset="0.3" stopColor="#AF842A" />
          <stop offset="0.57" stopColor="#764209" />
          <stop offset="0.59" stopColor="#723E07" />
          <stop offset="0.77" stopColor="#905E18" />
          <stop offset="0.88" stopColor="#9F6C1D" />
          <stop offset="0.93" stopColor="#8D5E12" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint122_linear_12021_246197"
          x1="361.578"
          y1="357.816"
          x2="361.578"
          y2="365.302"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFDF6" />
          <stop offset="0.14" stopColor="#D7BF81" />
          <stop offset="0.24" stopColor="#BB9330" />
          <stop offset="0.3" stopColor="#B0862A" />
          <stop offset="0.57" stopColor="#764209" />
          <stop offset="0.59" stopColor="#723D07" />
          <stop offset="0.77" stopColor="#8F5C16" />
          <stop offset="0.88" stopColor="#9E6B1C" />
          <stop offset="0.93" stopColor="#8C5D11" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint123_linear_12021_246197"
          x1="361.581"
          y1="358.967"
          x2="361.581"
          y2="364.15"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFEF8" />
          <stop offset="0.24" stopColor="#BC9431" />
          <stop offset="0.59" stopColor="#723D07" />
          <stop offset="0.88" stopColor="#9D6A1B" />
          <stop offset="1" stopColor="#734900" />
        </linearGradient>
        <linearGradient
          id="paint124_linear_12021_246197"
          x1="320.773"
          y1="324.854"
          x2="403.121"
          y2="390.195"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DB00FF" />
          <stop offset="1" stopColor="#540072" />
        </linearGradient>
        <linearGradient
          id="paint125_linear_12021_246197"
          x1="361.5"
          y1="307.152"
          x2="361.5"
          y2="409.448"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFE494" />
          <stop offset="0.09" stopColor="#BC9431" />
          <stop offset="0.43" stopColor="#754109" />
          <stop offset="0.72" stopColor="#723D07" />
          <stop offset="1" stopColor="#B07E24" />
        </linearGradient>
        <clipPath id="clip0_12021_246197">
          <rect width="141" height="141" fill="white" transform="translate(291 291)" />
        </clipPath>
      </defs>
    </svg>
  );
};
