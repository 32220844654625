import { FullInfoResponse } from 'react-easyrocket';
import { PaymentFormField } from 'src/types';

export const shouldFetchDefaultValue = (
  field: PaymentFormField,
  fullInfo: FullInfoResponse | undefined
) => {
  if (field.toShow && fullInfo) {
    const DATA = [
      {
        fieldName: /surname/i,
        value: fullInfo.surname
      },
      {
        fieldName: /name/i,
        value: fullInfo.name
      },
      {
        fieldName: /email/i,
        value: fullInfo.email
      },
      {
        fieldName: /phone/i,
        value: fullInfo.phone.code + fullInfo.phone.number
      },
      {
        fieldName: /city/i,
        value: fullInfo.city
      },
      {
        fieldName: /postcode|zip|client_pincode/i,
        value: fullInfo.postcode
      },
      {
        fieldName: /address/i,
        value: fullInfo.address
      },
      {
        fieldName: /country/i,
        value: fullInfo.country
      },
      {
        fieldName: /birthday/i,
        value: fullInfo.birthday
      }
    ];

    for (let i = 0; i < DATA.length; i += 1) {
      if (DATA[i]?.fieldName.test(field.name)) {
        return DATA[i]?.value;
      }
    }
    return field.value;
  }
  return field.value;
};
