import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const RippleIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M11 0C4.92129 0 0 4.92129 0 11C0 17.0739 4.92129 22 11 22C17.0739 22 22 17.0739 22 11C22 4.92129 17.0739 0 11 0Z"
        fill="#1277A8"
      />
      <path
        d="M12.0978 10.3887C12.3018 10.0347 12.6705 9.81553 13.0779 9.78359C13.7911 9.72766 14.4668 9.33248 14.8526 8.66579C15.4739 7.59262 15.1003 6.21535 14.0271 5.59402C12.9539 4.97705 11.5766 5.34202 10.9553 6.41519C10.5697 7.08164 10.5659 7.86398 10.8744 8.50994C11.0509 8.87935 11.0453 9.30898 10.8399 9.66312L10.8383 9.66598C10.6203 10.0419 10.2185 10.2734 9.78394 10.2734C9.3751 10.2734 9.00099 10.0632 8.76992 9.72594C8.36556 9.13579 7.68624 8.74834 6.91464 8.74834C5.50391 8.74834 4.40108 10.0533 4.72551 11.5123C4.9161 12.3695 5.62546 13.053 6.4893 13.2107C7.43383 13.383 8.29545 12.9672 8.77225 12.2685C9.00183 11.9321 9.37638 11.7245 9.78371 11.7245H9.78616C10.2219 11.7245 10.6245 11.9571 10.8421 12.3347L10.8426 12.3354C11.0464 12.6891 11.0518 13.1176 10.8754 13.4859C10.5659 14.1322 10.5693 14.9155 10.9553 15.5827C11.5766 16.6558 12.9539 17.0208 14.0271 16.4038C15.1003 15.7825 15.4739 14.4052 14.8526 13.3321C14.4668 12.6654 13.7911 12.2702 13.0779 12.2143C12.6705 12.1824 12.3018 11.9632 12.0978 11.6092L12.0969 11.6076C11.8798 11.2309 11.8798 10.767 12.0969 10.3903L12.0978 10.3887Z"
        fill="white"
      />
    </svg>
  );
};
