import { FIRST_ROW, SECOND_ROW } from 'src/pages/LocalTournament/constants';

export const getRowColor = ({ row }: { row: number }): string | undefined => {
  if (row === FIRST_ROW) {
    return '!bg-b_primary_bg';
  }
  if (row === SECOND_ROW) {
    return '!bg-game-badge-top';
  }
};
