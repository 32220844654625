import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const CryptoIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none">
      <path
        d="M13.5464 8.81778C13.4118 8.48361 13.2019 8.19672 12.9201 7.96028C12.6351 7.72383 12.2686 7.55675 11.8208 7.45902C12.3469 7.24149 12.7353 6.91992 12.989 6.49748C13.2426 6.07503 13.3679 5.59584 13.3679 5.0599C13.3679 4.01955 12.9702 3.23771 12.1747 2.71438C11.6736 2.38335 11.0472 2.15637 10.2956 2.03342H10.377V0H9.13365V1.92938C9.0397 1.92623 8.94887 1.92623 8.85178 1.92623H8.17217V0H6.93196V1.92623H3.75V3.5309L4.91818 3.77995V11.425L3.75 11.674V13.2724H6.93196V15H8.1753V13.2724H9.13678V15H10.377V13.2251C11.3228 13.1211 12.0807 12.8562 12.6413 12.4243C13.3804 11.8569 13.75 11.0183 13.75 9.90542C13.7469 9.51765 13.678 9.15511 13.5464 8.81778ZM10.2047 4.33796C10.5023 4.55233 10.6495 4.89912 10.6495 5.372C10.6495 5.80391 10.4897 6.12232 10.1734 6.32724C9.85711 6.53216 9.37794 6.63619 8.73591 6.63619H7.63663V4.01639H8.85178C9.45623 4.01324 9.90722 4.12358 10.2047 4.33796ZM10.6369 10.8733C10.3676 11.0876 9.96359 11.1917 9.42491 11.1917H7.63663V8.43947H9.55645C9.90722 8.43947 10.1922 8.49306 10.4083 8.60025C10.6244 8.70744 10.7841 8.86822 10.8875 9.0826C10.9908 9.29697 11.0409 9.57125 11.0409 9.89597C11.0441 10.3373 10.9094 10.6589 10.6369 10.8733Z"
        fill="white"
      />
    </svg>
  );
};
