import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { Spin } from '../svg';
import { DefaultTFuncReturn } from 'i18next';

export interface Props
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  mode?: Mode;
  disabled?: boolean;
  label?: string | DefaultTFuncReturn;
  className?: string;
  children?: ReactNode;
  loading?: boolean;
  icon?: ReactNode;
  leftIcon?: ReactNode;
  classNameSvg?: string;
  ariaLabel?: string;
}

type Mode = 'button-primary' | 'button-secondary' | 'button-simple' | 'button-transparent';

export const Button = ({
  mode = 'button-primary',
  className,
  disabled,
  label,
  children,
  onClick,
  loading,
  icon,
  leftIcon,
  classNameSvg,
  ...restProps
}: Props) => {
  return (
    <button
      {...restProps}
      className={`btn ${mode} [&]:uppercase ${className ? className : ''}`}
      disabled={disabled}
      onClick={onClick}>
      {leftIcon}
      {loading && <Spin classNameSvg={classNameSvg} />}
      {children ? children : label}
      {icon}
    </button>
  );
};
