import { useApiContext } from 'react-easyrocket';

import { FC, Fragment, PropsWithChildren } from 'react';
import { NavigateLng } from 'src/lib';

export const ProtectedAuthRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useApiContext();

  if (!isAuthenticated) {
    return <NavigateLng key="NavigateLng" to="/" />;
  }

  return <Fragment key="children">{children}</Fragment>;
};
