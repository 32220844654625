import axios from 'axios';
import { FormEvent } from 'react';
import { DepositTransaction } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { HellocleverResponse } from 'src/types';

const HELLOCLEVER_PAYMENT_METHOD = 'holypayment';

export const useProcessHelloclever = () => {
  const { openModal, closeModal, openedModalName } = useModalContext();
  return async ({
    event,
    transaction,
    checkoutUrl,
    paymentMethodName
  }: {
    event: FormEvent<HTMLFormElement>;
    transaction?: DepositTransaction;
    checkoutUrl?: string;
    paymentMethodName?: string;
  }) => {
    if (transaction?.paymentMethod !== HELLOCLEVER_PAYMENT_METHOD || !checkoutUrl) return;
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    try {
      const axiosInstance = axios.create();
      const response = await axiosInstance.post<HellocleverResponse>(checkoutUrl, data);
      openedModalName === 'FORM_FIELDS' && closeModal();
      openModal('DEPOSIT_HELLOCLEVER_RESULT', {
        props: { ...response.data, paymentMethodName }
      });
      // redicrect ?
    } catch (error) {
      console.error(error);
      const failUrl = data.get('fail_url');
      if (failUrl) {
        window.open(failUrl as string);
      }
    }
  };
};
