import { ExtendedWindow, assignCustomerData } from '@livechat/widget-core';
import { useWidgetIsReady } from '@livechat/widget-react';
import { useEffect } from 'react';
import { useApiContext, useGetFullInfo } from 'react-easyrocket';

declare const window: ExtendedWindow;

export const useChangeEmailInLiveChat = () => {
  const { isAuthenticated } = useApiContext();
  const { fullInfo } = useGetFullInfo();
  const isWidgetReady = useWidgetIsReady();

  useEffect(() => {
    if (window.LiveChatWidget) {
      if (isAuthenticated) {
        assignCustomerData({ email: fullInfo?.email });
      } else {
        assignCustomerData({ email: ' ' });
      }
    }
  }, [fullInfo?.email, isAuthenticated, isWidgetReady]);
};
