import { memo, useEffect, useState } from 'react';
import { Story } from './components/Story';
import { StoriesList } from './components/StoriesList';
import { useStories } from './hooks/useStories';
import { Child } from 'react-easyrocket';
import { ActiveStory, StoryDirection } from './types';
import { getSortedChilds } from './utils';

import './styles.css';

const Stories = () => {
  const { data, isFetching, visited, markVisited, isStoryVisited } = useStories();
  const [activeStory, setActiveStory] = useState<ActiveStory>(null);
  const [storyDirection, setStoryDirection] = useState<StoryDirection>(null);
  const [sortedChilds, setSortedChilds] = useState(getSortedChilds(data, visited, isStoryVisited));

  const openStory = (story: Child, index: number) => {
    setActiveStory({ item: story, index });
  };

  const closeStory = () => {
    setActiveStory(null);
    setStoryDirection(null);
  };

  const openNextStory = () => {
    if (!activeStory || !sortedChilds) return;
    const nextStory = sortedChilds[activeStory.index + 1];

    if (nextStory) {
      openStory(nextStory, activeStory.index + 1);
      setStoryDirection('forward');
      return;
    }

    closeStory();
  };

  const openPrevStory = () => {
    if (!activeStory || !sortedChilds) return;
    const prevStory = sortedChilds[activeStory.index - 1];

    if (prevStory) {
      openStory(prevStory, activeStory.index - 1);
      setStoryDirection('back');
      return;
    }

    closeStory();
  };

  // sort childs only when when story is closed
  useEffect(() => {
    if (activeStory === null) {
      setSortedChilds(getSortedChilds(data, visited, isStoryVisited));
    }
  }, [activeStory, data, visited]);

  return (
    <>
      <StoriesList
        handleOpenStory={openStory}
        sortedChilds={sortedChilds}
        isStoryVisited={isStoryVisited}
        isFetching={isFetching}
      />
      {activeStory && (
        <Story
          data={data}
          activeStory={activeStory.item}
          openStory={openStory}
          closeStory={closeStory}
          openNextStory={openNextStory}
          openPrevStory={openPrevStory}
          visited={visited}
          isStoryVisited={isStoryVisited}
          markVisited={markVisited}
          storyDirection={storyDirection}
        />
      )}
    </>
  );
};

export default memo(Stories);
