import { SVGPropsIcon } from 'src/types';

export const FailureIcon = (props: SVGPropsIcon) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.85 3.85833C15.6583 2.5 13.1 1.66666 10 1.66666C6.90001 1.66666 4.34167 2.5 3.15001 3.85833C1.16667 6.15833 1.16667 13.8583 3.15001 16.1417C4.34167 17.5 6.90001 18.3333 10 18.3333C13.1 18.3333 15.6583 17.5 16.85 16.1417C18.8333 13.8417 18.8333 6.15833 16.85 3.85833Z"
      fill="#FD3C4F"
    />
    <path
      d="M7.91667 12.9167C7.807 12.9173 7.69828 12.8963 7.59675 12.8548C7.49522 12.8133 7.40287 12.7522 7.32501 12.675C7.2469 12.5975 7.1849 12.5054 7.1426 12.4038C7.10029 12.3023 7.07851 12.1933 7.07851 12.0833C7.07851 11.9733 7.10029 11.8644 7.1426 11.7629C7.1849 11.6613 7.2469 11.5691 7.32501 11.4917L11.4917 7.325C11.6486 7.16808 11.8614 7.07993 12.0833 7.07993C12.3053 7.07993 12.5181 7.16808 12.675 7.325C12.8319 7.48192 12.9201 7.69475 12.9201 7.91667C12.9201 8.13859 12.8319 8.35142 12.675 8.50833L8.50834 12.675C8.43047 12.7522 8.33812 12.8133 8.23659 12.8548C8.13506 12.8963 8.02634 12.9173 7.91667 12.9167Z"
      fill="white"
    />
    <path
      d="M12.0833 12.9167C11.9737 12.9173 11.8649 12.8963 11.7634 12.8548C11.6619 12.8133 11.5695 12.7522 11.4917 12.675L7.325 8.50833C7.16808 8.35142 7.07993 8.13859 7.07993 7.91667C7.07993 7.69475 7.16808 7.48192 7.325 7.325C7.48192 7.16808 7.69475 7.07993 7.91667 7.07993C8.13859 7.07993 8.35142 7.16808 8.50833 7.325L12.675 11.4917C12.7531 11.5691 12.8151 11.6613 12.8574 11.7629C12.8997 11.8644 12.9215 11.9733 12.9215 12.0833C12.9215 12.1933 12.8997 12.3023 12.8574 12.4038C12.8151 12.5054 12.7531 12.5975 12.675 12.675C12.5971 12.7522 12.5048 12.8133 12.4033 12.8548C12.3017 12.8963 12.193 12.9173 12.0833 12.9167Z"
      fill="white"
    />
  </svg>
);

export const SuccessIcon = (props: SVGPropsIcon) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.2539 16.4905C13.8024 18.4891 6.14761 18.4891 3.7461 16.4905C1.29457 14.4919 1.54472 5.94786 3.7461 3.69944C5.94748 1.45102 14.0525 1.45102 16.2539 3.69944C18.4553 5.94786 18.7054 14.4919 16.2539 16.4905Z"
      fill="#1DD100"
    />
    <path
      d="M9.09747 13C8.82277 13 8.54811 12.882 8.39114 12.6459L6.1937 9.81346C5.87978 9.42007 5.95827 8.86931 6.35067 8.55459C6.74306 8.23986 7.29242 8.31855 7.60635 8.71195L9.09747 10.6396L12.3937 6.35156C12.7076 5.95817 13.2569 5.87947 13.6493 6.1942C14.0417 6.50892 14.1202 7.05968 13.8063 7.45307L9.80379 12.6459C9.60759 12.8426 9.37216 13 9.09747 13Z"
      fill="white"
    />
  </svg>
);

export const InfoIcon = (props: SVGPropsIcon) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.3125 2H6.6875C4.24544 2 2.23954 3.77965 1.60025 6.23728C1.52302 6.53418 1.5 6.84187 1.5 7.14866V12.8513C1.5 13.1581 1.52302 13.4658 1.60025 13.7627C2.23954 16.2203 4.24544 18 6.6875 18H13.3125C15.9013 18 18 15.9013 18 13.3125V6.6875C18 4.09867 15.9013 2 13.3125 2Z"
      fill="#4B3CFD"
    />
    <path
      d="M10.7812 8.74344C10.7812 8.31197 10.4315 7.96219 10 7.96219C9.56853 7.96219 9.21875 8.31197 9.21875 8.74344V13.7434C9.21875 14.1749 9.56853 14.5247 10 14.5247C10.4315 14.5247 10.7812 14.1749 10.7812 13.7434V8.74344Z"
      fill="white"
    />
    <path
      d="M10.5509 5.70448C10.2458 5.39943 9.75122 5.39943 9.44617 5.70448C9.14112 6.00953 9.14112 6.50411 9.44617 6.80916C9.75122 7.11422 10.2458 7.11422 10.5509 6.80916C10.8559 6.50411 10.8559 6.00953 10.5509 5.70448Z"
      fill="white"
    />
  </svg>
);

export const CrossCloseIcon = (props: SVGPropsIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#CECECE"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M14.2625 12L19.5312 6.73125C19.8333 6.42916 20 6.02709 20 5.6C20 5.17291 19.8333 4.77084 19.5312 4.46875C19.2292 4.16666 18.8271 4 18.4 4C17.9729 4 17.5708 4.16666 17.2688 4.46875L12 9.7375L6.73125 4.46875C6.42916 4.16666 6.02709 4 5.6 4C5.17291 4 4.77084 4.16666 4.46875 4.46875C4.16666 4.77084 4 5.17291 4 5.6C4 6.02709 4.16666 6.42916 4.46875 6.73125L9.7375 12L4.46875 17.2688C4.16666 17.5708 4 17.9729 4 18.4C4 18.8271 4.16666 19.2292 4.46875 19.5312C4.77134 19.8333 5.17291 20 5.6 20C6.02709 20 6.42866 19.8333 6.73125 19.5312L12 14.2625L17.2688 19.5312C17.5713 19.8333 17.9729 20 18.4 20C18.8271 20 19.2287 19.8333 19.5312 19.5312C19.8333 19.2292 20 18.8271 20 18.4C20 17.9729 19.8333 17.5708 19.5312 17.2688L14.2625 12Z" />
  </svg>
);
