import { SOFTSWISS_FRAME_DOMAIN } from 'src/pages/SportSwiss/config';
import { getFrameUrlFromInAppPathname } from 'src/pages/SportSwiss/utils/getFrameUrlFromInAppPathname';

export const changeInFrameLocation = (
  frameContentWindow: Window | null | undefined,
  inAppPathname: string
) => {
  frameContentWindow?.postMessage(
    {
      type: 'page_changed',
      payload: { url: getFrameUrlFromInAppPathname(inAppPathname) }
    },
    SOFTSWISS_FRAME_DOMAIN
  );
};
