import firstPlaceBadge from 'src/assets/images/firstPlaceBadge.webp';
import secondPlaceBadge from 'src/assets/images/secondPlaceBadge.webp';
import thirdPlaceBadge from 'src/assets/images/thirdPlaceBadge.webp';

export const PRIZE_PODIUM_BADGES = [firstPlaceBadge, secondPlaceBadge, thirdPlaceBadge];
export const PLACE_SUFFIX = ['ST', 'ND', 'RD'];

export const DEFAULT_TABLE_ROWS = 10;

export const WIDTH_CHERRY_ICON = 40;
export const HEIGHT_CHERRY_ICON = 30;

export const FIRST_ROW = 0;
export const SECOND_ROW = 1;
