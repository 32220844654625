import { useDrawer } from 'src/hooks/useDrawer';
import { useBackgroundScrollLock } from 'react-easyrocket';
import CloseModal from '../ModalWrapper/CloseModal';
import { twJoin } from 'tailwind-merge';
import { GamesSearch } from './components/GamesSearch';
import { Tabs } from './components/Tabs';
import { useEffect, useState } from 'react';
import { Providers } from './components/Providers';

export const TopbarSearch = () => {
  const {
    isTopSearchDrawerOpen,
    isProvidersSearchOpen,
    closeIsTopSearchDrawerOpen,
    closeProvidersSearch
  } = useDrawer();
  const [activeTab, setActiveTab] = useState(1);
  const isVisible = isTopSearchDrawerOpen || isProvidersSearchOpen;

  useEffect(() => {
    if (isProvidersSearchOpen) {
      setActiveTab(2);
    }
    if (isTopSearchDrawerOpen) {
      setActiveTab(1);
    }
  }, [isProvidersSearchOpen, isTopSearchDrawerOpen]);

  const isOpen = isProvidersSearchOpen || isTopSearchDrawerOpen;

  useBackgroundScrollLock(isTopSearchDrawerOpen, [isTopSearchDrawerOpen]);

  return (
    <div
      className={twJoin(
        'w-full flex flex-col h-screen bg-background-main fixed top-0 left-0 z-[700] p-[15px] transition-all overflow-y-auto',
        isVisible ? 'translate-y-0' : 'translate-y-[-100%]'
      )}>
      <div className="w-full flex justify-end items-start">
        <CloseModal
          onClose={() => {
            closeIsTopSearchDrawerOpen();
            if (isProvidersSearchOpen) {
              closeProvidersSearch();
            }
          }}
          className="flex-shrink-0 w-4 h-4"
        />
      </div>
      <Tabs
        className="mt-3"
        items={[
          {
            id: 1,
            handleClick: () => {
              setActiveTab(1);
            },
            label: 'general.games',
            isActive: activeTab === 1
          },
          {
            id: 2,
            handleClick: () => {
              setActiveTab(2);
            },
            label: 'general.providers',
            isActive: activeTab == 2
          }
        ]}
      />
      {isOpen && activeTab === 1 && <GamesSearch />}
      {isOpen && activeTab === 2 && <Providers />}
    </div>
  );
};
