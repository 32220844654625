export const RouletteIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278217)">
      <path
        d="M37.2588 29.7979L28.8157 21.2561H26.1432V17.2721H23.6843V15.688C24.3706 15.1137 24.8655 14.341 25.1045 13.4851H32.7013V15.0569H35.0449V13.4851H36.7694V11.1415H35.0449V9.74791H32.7013V11.1415H25.1045C24.8655 10.2855 24.3707 9.51285 23.6843 8.93859V6.15597C23.6843 4.6053 22.4227 3.34375 20.8721 3.34375C19.3214 3.34375 18.0599 4.6053 18.0599 6.15597V8.93851C17.3734 9.51285 16.8786 10.2855 16.6396 11.1414H9.14835V9.74783H6.80479V11.1414H5.12804V13.485H6.80486V15.0568H9.14843V13.485H16.6397C16.8787 14.3409 17.3736 15.1136 18.0599 15.6879V17.2721H15.2046V21.256H12.5322L4.08898 29.7979H0.675415V37.9694H40.6723V29.7979H37.2588ZM19.8292 10.5479L20.4034 10.2075V6.15597C20.4034 5.89755 20.6136 5.68733 20.8721 5.68733C21.1305 5.68733 21.3407 5.89755 21.3407 6.15597V10.2075L21.9149 10.5479C22.5458 10.922 22.9225 11.5818 22.9225 12.3132C22.9225 13.0446 22.5459 13.7045 21.915 14.0785L21.3407 14.4188V17.2721H20.4034V14.4188L19.8291 14.0785C19.1982 13.7045 18.8216 13.0446 18.8216 12.3132C18.8216 11.5819 19.1983 10.922 19.8292 10.5479ZM23.6843 19.6157H23.7996V21.2561H17.5482V19.6157H23.6843ZM13.5109 23.5997H19.7003V27.5987H22.0438V23.5997H27.8368L33.9635 29.7979H7.38419L13.5109 23.5997ZM38.3288 35.6259H3.01898V32.1415H38.3288V35.6259Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278217">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.666016 0.658203)"
        />
      </clipPath>
    </defs>
  </svg>
);
