import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const EtheriumIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11Z"
        fill="#687DE4"
      />
      <path
        d="M10.9969 2.47363L10.8827 2.86218V14.1359L10.9969 14.25L16.2202 11.1567L10.9969 2.47363Z"
        fill="#C3CDF3"
      />
      <path
        d="M10.9969 2.47363L5.77344 11.1567L10.9969 14.25L10.9969 8.77807L10.9969 2.47363Z"
        fill="#C3CDF3"
      />
      <path
        d="M10.994 15.2411L10.9297 15.3197V19.3356L10.994 19.5238L16.2206 12.1494L10.994 15.2411Z"
        fill="#C3CDF3"
      />
      <path d="M10.9969 19.5238V15.2411L5.77344 12.1494L10.9969 19.5238Z" fill="#C3CDF3" />
      <path d="M10.9969 14.25L16.2202 11.1567L10.9969 8.77807L10.9969 14.25Z" fill="#8697E8" />
      <path d="M5.77344 11.1567L10.9969 14.25L10.9969 8.77807L5.77344 11.1567Z" fill="#C3CDF3" />
    </svg>
  );
};
