import classNames from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { PaymentMethod } from 'src/types';
import wallet from 'src/assets/images/wallet.png';

export const CardPaymentMethod: FC<{
  method: PaymentMethod;
  onClick?: (method: PaymentMethod) => void;
  selected?: boolean;
  id: string;
}> = ({ method, onClick, selected, id }) => {
  const title = useMemo(() => {
    return method.title ? method.title : '';
  }, [method.title]);

  const handleClick = useCallback(() => {
    onClick?.(method);
  }, [method, onClick]);

  return (
    <div
      className={classNames(
        'rounded-[10px] relative w-[75px] 768:w-[85px] h-[80px] flex flex-col items-center justify-center py-2 px-1 gap-1 payment',
        selected ? 'bg-button-primary' : 'bg-background-octal'
      )}>
      <input
        type="radio"
        className="absolute w-[100%] h-[100%] top-0 left-0 opacity-0 cursor-pointer"
        id={id}
        name={id}
        value={id}
        onClick={handleClick}
      />
      {method.logoUrl ? (
        <img src={method.logoUrl} alt={title} className="h-7 object-contain" />
      ) : (
        <img src={wallet} alt={title} className="object-contain h-7" />
      )}
      <p
        className={classNames(
          'text-[11px] text-center break-all leading-[110%]',
          selected ? 'text-black' : 'text-white'
        )}>
        {title}
      </p>
    </div>
  );
};
