export const splitStringRegex = (str: string): [string, string] => {
  /**  Регулярное выражение делит любую строку на 2 части
   * 1 часть это первое слово
   * 2 часть все остальные слова если они есть, если их нет то возвращает пустую строку.
   * */
  const match = str.match(/^(\S+)\s*(.*)$/);
  if (!match) {
    return [str, ''];
  }

  if (match[1].length <= 3 || match[2].length <= 3) {
    return [match[0], ''];
  }

  return [match[1], match[2]];
};
