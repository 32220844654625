export const JackpotsIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278197)">
      <path
        d="M35.1457 12.2355C35.415 12.3111 35.6984 12.3524 35.9915 12.3524C37.7188 12.3524 39.1242 10.9471 39.1242 9.21975C39.1242 7.49245 37.7189 6.08716 35.9915 6.08716C34.2642 6.08716 32.8589 7.49237 32.8589 9.21975C32.8589 9.47575 32.8905 9.7244 32.9487 9.96267L27.0172 12.8821L22.4318 6.33152C23.222 5.76226 23.7378 4.83506 23.7378 3.78896C23.7378 2.06166 22.3325 0.656372 20.6052 0.656372C18.8779 0.656372 17.4726 2.06166 17.4726 3.78904C17.4726 4.82865 17.982 5.75101 18.764 6.32113L14.1713 12.8821L8.24459 9.96509C8.36356 9.63449 8.42879 9.27842 8.42879 8.90735C8.42879 7.18005 7.02359 5.77476 5.29622 5.77476C3.56893 5.77476 2.16357 7.17997 2.16357 8.90735C2.16357 10.6346 3.56886 12.04 5.29622 12.04C5.51996 12.04 5.73814 12.016 5.94875 11.9712L9.59363 22.2206V24.962H4.61174V40.6535H36.6759V24.9619H31.5829V22.254L35.1457 12.2355ZM35.9915 8.43082C36.4266 8.43082 36.7806 8.7847 36.7806 9.21983C36.7806 9.65487 36.4266 10.0089 35.9915 10.0089C35.5565 10.0089 35.2025 9.65495 35.2025 9.21983C35.2025 8.7847 35.5565 8.43082 35.9915 8.43082ZM5.2963 9.69636C4.86118 9.69636 4.50722 9.34232 4.50722 8.90727C4.50722 8.47222 4.86118 8.11827 5.2963 8.11827C5.73135 8.11827 6.0853 8.47215 6.0853 8.90727C6.0853 9.34232 5.73142 9.69636 5.2963 9.69636ZM20.6052 2.99996C21.0402 2.99996 21.3943 3.35392 21.3943 3.78904C21.3943 4.22417 21.0403 4.57805 20.6052 4.57805C20.1702 4.57805 19.8162 4.22417 19.8162 3.78904C19.8162 3.35392 20.1702 2.99996 20.6052 2.99996ZM34.3323 27.3055V38.31H6.95531V27.3055H34.3323ZM11.9372 24.7809V23.1902H29.2394V24.7809H11.9372ZM29.5962 20.8466H11.5924L8.73799 12.82L14.9391 15.872L20.5943 7.79313L26.2495 15.872L32.4506 12.82L29.5962 20.8466Z"
        fill="white"
      />
      <path
        d="M18.8891 29.4589L17.0447 34.8676L16.2987 32.8885L17.5318 29.4693H15.9568L15.2108 31.8213L14.4648 29.4693H12.9001L14.1125 32.8885L13.3871 34.8676L11.5324 29.4589H9.68805L12.6204 36.8156H14.0503L15.2108 33.9351L16.3817 36.8156H17.8012L20.7438 29.4589H18.8891Z"
        fill="white"
      />
      <path d="M23.0693 29.4587H21.37V36.8153H23.0693V29.4587Z" fill="white" />
      <path
        d="M29.213 29.4581V33.7789L25.7833 29.4581H24.457V36.8149H26.1564V32.6081L29.5445 36.8149H30.9123V29.4581H29.213Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278197">
        <rect
          width="39.9969"
          height="39.9972"
          fill="white"
          transform="translate(0.648438 0.661133)"
        />
      </clipPath>
    </defs>
  </svg>
);
