import { useEffect } from 'react';
import { useApiContext } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';

export const useKillGameOnSessionEnd = () => {
  const { isAuthenticated } = useApiContext();
  const { closeModal } = useModalContext();

  useEffect(() => {
    if (!isAuthenticated) {
      closeModal();
    }
  }, [isAuthenticated]);
};
