import { FocusEventHandler, ReactNode } from 'react';

type Props = {
  placeholder: string;
  value: string | undefined;
  onChange: (value: string) => void;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  classNameInput?: string;
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

export const InputSearch = ({
  placeholder,
  value,
  onChange,
  rightIcon,
  leftIcon,
  classNameInput,
  className,
  onBlur
}: Props) => {
  return (
    <div className={className}>
      {leftIcon}
      <input
        placeholder={placeholder}
        className={classNameInput}
        type="text"
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
      />
      {rightIcon}
    </div>
  );
};
