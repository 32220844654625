import { FC, RefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Separator } from 'src/components/Separator/Separator';
import { AccountIcon, Download, LiveChatIcon } from 'src/components/svg';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';
import { sidebarLinks, sportLinks } from 'src/components/SidebarGamesDropdown/config';
import { BonusesResponse, useApiContext, useGetPromo } from 'react-easyrocket';

import { CompactLanguageSelector } from 'src/components/LanguagesSelect/CompactLanguageSelector';
import { collapsedSidebarDataForTooltips } from './constants';
import { ProgressiveTooltipController } from '../ProgressiveTooltipController/ProgressiveTooltipController';
import { PROGRESSIVE_TOOLTIP_TYPE, useTooltip } from 'src/hooks/useProgressiveTooltip';
import { SidebarTooltip, Tooltips, useSidebarTooltip } from './components/SidebarTooltip';
// import PromoLink from '../../event/components/PromoLink/PromoLink';
import { AuthButtonsWithTooltips } from './components/AuthButtonsWithTooltips';
import { openChat } from '../LiveChatWidget/utils';
import { DailyBonusButton } from 'src/modules/GPT/GPTUi/DailyBonusButton';
import { GPTWheelOfFortuneButton } from 'src/modules/GPT/GPTUi/GPTWheelButton';
import { SmarticoButtonsCollapsed } from './components/SmarticoButtonsCollapsed';
import { CollapsedLink } from './components/CollapsedLink';
import { useNavigateLng } from 'src/lib';

const bonusTypes = {
  0: 'Registration',
  3: 'PromoCode',
  5: 'Deposits',
  6: 'Periodic',
  7: 'IncreaseWinnings',
  8: 'ActivatedByLink'
};

export const DesktopCollapsedSidebarContent: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApiContext();
  const navigate = useNavigateLng();
  const [onInstallPwaClick] = useAddToHomescreenPrompt();
  const { data, isFetched } = useGetPromo();
  const bonusesToSort = data as BonusesResponse;
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const buttonRefSecond = useRef<HTMLDivElement | null>(null);
  const buttonRefThird = useRef<HTMLDivElement | null>(null);

  const bonuses = useMemo(() => {
    return bonusesToSort?.filter((bonus) => {
      return !!(
        bonus.type in bonusTypes &&
        bonus.fullDescription &&
        bonus.shortDescription &&
        bonus.imageBanner &&
        bonus.imageBackground
      );
    });
  }, [bonusesToSort]);

  const refs = [buttonRef, buttonRefSecond, buttonRefThird];

  const { openTooltip, openedTooltip, closeTooltips } = useTooltip();
  const showTooltip = (ref: RefObject<HTMLDivElement>, tooltipType?: PROGRESSIVE_TOOLTIP_TYPE) => {
    if (openedTooltip !== undefined) {
      closeTooltips();
    } else {
      openTooltip(tooltipType, ref);
    }
  };

  const { handleActiveTooltip, clearTooltipState, activeTooltip } = useSidebarTooltip();

  return (
    <div className="h-screen flex flex-col justify-start items-center pb-[100px]">
      <div className="mb-[20px] gap-[10px] flex flex-col justify-center items-center">
        {isAuthenticated ? (
          <>
            <SidebarTooltip
              id="account-icon"
              mouseEnterFunction={() => handleActiveTooltip(Tooltips.ACCOUNT)}
              mouseLeaveFunction={clearTooltipState}
              containerClickFunction={() => {
                navigate('/account/profile/main');
              }}
              showTooltip={activeTooltip === Tooltips.ACCOUNT}
              tooltipContent={t('general.account')}
              className="hover:hover:text-yellow">
              <AccountIcon className="w-[30px] h-[30px] hover:stroke-yellow" />
            </SidebarTooltip>
            {/* TODO раcкомментить после релиза */}
            {/* <NotificationButton /> */}
          </>
        ) : (
          <AuthButtonsWithTooltips
            handleActiveTooltip={handleActiveTooltip}
            clearTooltipState={clearTooltipState}
            activeTooltip={activeTooltip}
          />
        )}
      </div>
      <SmarticoButtonsCollapsed />
      <Separator width="w-[60%]" noMarginTop />
      {/* <GameBeerLink isCollapsed={true} className="mt-5" />*/}
      <div className="collapsed-container gap-[15px] my-[15px]">
        {collapsedSidebarDataForTooltips.map((item, index) => (
          <ProgressiveTooltipController
            key={item.title}
            controllerRef={refs[index]}
            icon={item.icon}
            onClick={() => showTooltip(refs[index], item.progressiveTooltipToOpen)}
            active={openedTooltip === item.progressiveTooltipToOpen}
          />
        ))}
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5 flex flex-col items-center justify-center gap-3">
        {/* <PromoLink compact />
        <PromoLink compact shop /> */}
        {sportLinks.map(({ path, icon, title, id, tooltip }) => (
          <SidebarTooltip
            key={path}
            id={id}
            mouseEnterFunction={() => handleActiveTooltip(tooltip)}
            mouseLeaveFunction={clearTooltipState}
            containerClickFunction={() => {
              navigate(path, { replace: true });
              // openModal('SPORT_STUB_MODAL');
            }}
            showTooltip={activeTooltip === tooltip}
            tooltipContent={t(title)}>
            <button className={'[&_>svg>path]:hover:fill-yellow'}>{icon}</button>
          </SidebarTooltip>
        ))}
        <Separator width="w-[60%]" noMarginTop />

        <DailyBonusButton
          compact={true}
          handleActiveTooltip={handleActiveTooltip}
          clearTooltipState={clearTooltipState}
          activeTooltip={activeTooltip}
        />
        <GPTWheelOfFortuneButton
          compact={true}
          handleActiveTooltip={handleActiveTooltip}
          clearTooltipState={clearTooltipState}
          activeTooltip={activeTooltip}
        />
        <CollapsedLink data={sidebarLinks.jackpots} />
        {(Boolean(bonuses?.length) || !isFetched) && <CollapsedLink data={sidebarLinks.bonuses} />}
        <CollapsedLink data={sidebarLinks.tournaments} />
        <CollapsedLink data={sidebarLinks.vip} />
        <SidebarTooltip
          id="support-icon"
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.CHAT)}
          mouseLeaveFunction={clearTooltipState}
          containerClickFunction={openChat}
          showTooltip={activeTooltip === Tooltips.CHAT}
          tooltipContent={t('mainSidebarLinks.liveChat')}
          className="[&_>svg>path]:hover:fill-yellow">
          <LiveChatIcon />
        </SidebarTooltip>
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5">
        <SidebarTooltip
          id="mobile-app-icon"
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.DOWNLOAD)}
          mouseLeaveFunction={clearTooltipState}
          containerClickFunction={onInstallPwaClick}
          showTooltip={activeTooltip === Tooltips.DOWNLOAD}
          tooltipContent={t('general.installApp')}
          className="w-[50px] h-[36px]">
          <Download className="bg-button-primary w-full h-full py-[3px] hover:bg-button-primary-hover rounded-[5px]" />
        </SidebarTooltip>
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5">
        <CompactLanguageSelector />
      </div>
    </div>
  );
};
