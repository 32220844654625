import { CSSProperties, forwardRef } from 'react';
import { CONTENT_URL } from 'src/api';
import { Button } from 'src/components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { scrollIntoViewWithHeaderOffset } from 'src/utils/scrollIntoViewWithOffset';
import { MESSAGE_STATUS, useApiContext, useApplyTournament } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useMessage } from 'src/modules/messages';

interface BannerProps {
  isUserParticipate: boolean;
  isFinishedTournament: boolean;
  tournamentImage: string;
  isLoading: boolean;
}

export const Banner = forwardRef<HTMLHeadingElement, BannerProps>(
  ({ isUserParticipate, isFinishedTournament, tournamentImage, isLoading }, ref) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useApiContext();
    const { openModal } = useModalContext();
    const { tournamentId } = useParams();
    const queryClient = useQueryClient();
    const [, setSearchParams] = useSearchParams();

    const { openMessage, closeMessage } = useMessage();

    const onApplySuccess = () => {
      openMessage({
        type: 'USER_MESSAGE',
        props: { text: t('tournaments.successfulRegistratio'), type: MESSAGE_STATUS.SUCCESS },
        saveToHistory: false
      });
      if (ref && 'current' in ref) {
        scrollIntoViewWithHeaderOffset(ref?.current);
      }
      setSearchParams({ participating: 'true' }, { replace: true });
      queryClient.invalidateQueries(['user-tournament-progress']);
      queryClient.invalidateQueries(['single-local-tournament']);
      queryClient.invalidateQueries(['tournament-with-users-progress']);
    };

    const { mutateAsync: applyForTournament, isLoading: applyTournamentLoading } =
      useApplyTournament({
        onError: (error) => {
          if (error.response?.data && error.response?.data?.code === 16) {
            closeMessage?.(error.response.data.message || '');
            openModal('TOURNAMENT_LOW_LEVEL');
          }
        },
        onSuccess: onApplySuccess
      });

    const handleApplyButtonClick = () => {
      if (!tournamentId) return;
      if (!isAuthenticated) {
        openModal('SIGNUP', { props: { isSuccessfullNavigateDisabled: true } });
        return;
      }
      applyForTournament({ tournament_id: Number(tournamentId) });
    };

    const isActive = isUserParticipate && isAuthenticated;
    const style = {
      '--image-tournament': `url(${CONTENT_URL}${tournamentImage})`
    } as CSSProperties;

    return (
      <div
        style={style}
        className={twJoin(
          'flex justify-end bg-LOCAL_TOURNAMENT_BANNER items-center flex-col gap-[20px] 768:gap-[50px] min-h-[287px] p-[25px] bg-no-repeat bg-center bg-cover bg-[image:var(--image-tournament)]'
        )}>
        {!isFinishedTournament && !isLoading && (
          <Button
            className="shadow-xl min-w-[190px] w-auto py-[14px] px-[30px]"
            label={
              isActive
                ? `${t('tournaments.alreadyParticipate')}`
                : `${t('tournaments.participate')}`
            }
            title={
              isActive
                ? `${t('tournaments.alreadyParticipate')}`
                : `${t('tournaments.participate')}`
            }
            disabled={isActive}
            loading={applyTournamentLoading}
            onClick={handleApplyButtonClick}
            mode="button-secondary">
            {isActive
              ? `${t('tournaments.alreadyParticipate')}`
              : `${t('tournaments.participate')}`}
          </Button>
        )}
      </div>
    );
  }
);

Banner.displayName = 'Banner';
