import React, { useEffect } from 'react';
import { VideoPreloader } from '../VideoPreloader/VideoPreloader';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import logo from 'src/assets/images/logoSidebar.webp';
import errorPageCharacter from 'src/assets/images/errorPageCharacter.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import './index.css';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { MESSAGE_STATUS } from 'react-easyrocket';
import { replaceWithBigLogo } from 'src/event/utils/replaceWithBigLogo';
import { useMessage } from 'src/modules/messages';
import { MessagesContainer } from 'src/modules/messages/components/MessagesContainer';

export const QueryBoundaries = ({ children }: { children: React.ReactNode }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorView}>
        <React.Suspense fallback={<VideoPreloader />}>{children}</React.Suspense>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

const ErrorView = ({ error }: FallbackProps) => {
  const { t } = useTranslation();
  const { openMessage } = useMessage();

  useEffect(() => {
    openMessage({
      type: 'USER_MESSAGE',
      props: { text: error.message, type: MESSAGE_STATUS.ERROR },
      saveToHistory: false
    });
  }, [error, openMessage]);

  return (
    <>
      <MessagesContainer />
      <div className="w-full px-[10px] h-screen jmid:min-h-[660px] min-h-[820px] flex flex-col gap-[30px] jmid:gap-[20px] items-center justify-center text-center bg-[url('/src/assets/images/errorPageBg.webp')] bg-center bg-no-repeat bg-cover text-white">
        <img
          src={replaceWithBigLogo(logo)}
          className="w-[220px] jmid:w-[150px]"
          alt="jacktop logo"
        />
        <p className="text-[26px] jmid:text-[16px] font-bold max-w-[660px]">{t('general.error')}</p>
        <img src={errorPageCharacter} className="w-[360px] jmid:max-w-[300px]" alt="character" />
        <Button
          mode="button-secondary"
          onClick={() => {
            window.location.href = '/';
          }}
          className="text-[black] w-max max-w-full px-[30px]">
          {t('general.backToMain')}
        </Button>
      </div>
    </>
  );
};
