import { FC, SVGProps } from 'react';

export const WheelArrow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Pointer"
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    version="1.1"
    viewBox="0 0 192.1 192.4"
    {...props}>
    <path
      fill="#551b8e"
      strokeWidth={0}
      d="M96,178.5c-8.5,0-15.9-4.4-19.1-11.6L21.8,46.8c-2.2-4.9-2.2-10.4.1-15.2,2.5-5.2,7.4-9.1,13.5-10.6,19.6-4.9,40-7.3,60.6-7.3s41,2.5,60.6,7.3c6,1.5,11,5.4,13.5,10.6,2.3,4.8,2.4,10.4.1,15.2l-55.1,120.1c-3.3,7.2-10.6,11.6-19.1,11.6h0Z"
    />
    <path
      className="group-hover:fill-yellow"
      fill="#fff"
      strokeWidth={0}
      d="M96.1,26.1c20.1,0,39.4,2.4,57.6,7,4.5,1.1,6.9,5,5.3,8.5l-55.1,120.1c-1.3,2.9-4.6,4.3-7.8,4.3s-6.5-1.4-7.8-4.3L33.2,41.6c-1.6-3.5.8-7.4,5.3-8.5,18.2-4.5,37.5-7,57.6-7M96.1,1.1h0c-21.7,0-43.1,2.6-63.6,7.7-9.7,2.4-17.6,8.7-21.7,17.3-3.9,8.2-4,17.6-.2,25.9l55.1,120.1c5.3,11.5,17.2,18.9,30.5,18.9s25.2-7.4,30.5-18.9l55.1-120.1c3.8-8.2,3.7-17.7-.2-25.9-4.1-8.6-12.1-14.9-21.7-17.3-20.5-5.1-42-7.7-63.6-7.7h0Z"
    />
  </svg>
);
