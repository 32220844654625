import ModalWrapper from 'src/components/ModalWrapper';
import image from './assets/image.webp';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Buttons/Button';
import { useModalContext } from 'src/hooks/useModalContext';

export const SmarticoStubModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();

  return (
    <ModalWrapper>
      <div className="w-[400px] py-10 px-5 max-w-full flex flex-col items-center gap-[30px]">
        <img
          className="w-[238px] max-w-full h-auto p-2"
          src={image}
          alt="avatar"
          width={238}
          height={238}
        />
        <h2 className="text-center text-[26px] text-smartico_modal_text leading-[110%] font-bold">
          {t('smartico.modalTitle')}
        </h2>
        <p className="text-[14px] leading-[18px] text-center font-medium">
          {t('smartico.modalDescription')}
        </p>
        <Button mode="button-secondary" onClick={closeModal} className="w-full !max-w-full">
          {t('gameNotification.close')}
        </Button>
      </div>
    </ModalWrapper>
  );
};
