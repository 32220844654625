export const FruitsIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12190_278177)">
      <path
        d="M39.3432 4.84548L37.2451 3.82125C37.0325 4.25685 36.8149 4.66885 36.5944 5.05835C36.3817 4.27364 35.997 3.57705 35.4405 2.97366C32.8134 0.125109 27.2822 0.695928 27.048 0.721942L25.7704 0.862167L26.0325 2.12044C26.0806 2.35105 27.2499 7.78738 30.7718 9.40375C31.4111 9.69709 32.0766 9.84809 32.7649 9.86012C32.3788 10.1945 32.0383 10.4522 31.7639 10.6434C30.4444 9.55913 28.499 9.43945 26.7466 10.3172C24.0764 9.07987 20.8483 8.80473 17.5769 9.54757C14.0221 10.3549 10.6712 12.2535 7.88658 15.0382C5.09234 17.8325 3.19108 21.1948 2.38833 24.762C1.64644 28.0582 1.93837 31.306 3.20515 33.9836C2.3863 35.8132 2.63535 37.7839 3.894 39.0424C5.18499 40.3334 7.26671 40.5521 9.12945 39.6479C10.7175 40.3166 12.491 40.6555 14.3416 40.6555C15.5895 40.6555 16.8725 40.5014 18.1572 40.1904C21.6222 39.3516 24.8893 37.4726 27.6051 34.7568C30.3128 32.049 32.1894 28.792 33.0318 25.3375C33.8043 22.1703 33.6233 19.0099 32.5291 16.3592C32.9594 15.562 33.1949 14.6899 33.201 13.8466C33.2042 13.4153 33.1479 12.9983 33.0364 12.6052C34.4608 11.6311 37.1496 9.33977 39.3432 4.84548ZM25.9542 33.1059C22.5839 36.4762 18.2684 38.3299 14.3247 38.3297C12.6439 38.3295 11.0299 37.9927 9.58645 37.293L9.00368 37.0105L8.45552 37.3555C7.3906 38.0255 6.19374 38.0403 5.54497 37.3916C4.92353 36.7702 4.914 35.6133 5.52176 34.5782L5.85518 34.0104L5.54176 33.4314C2.9479 28.6395 4.6284 21.5984 9.53755 16.6892C14.5036 11.7232 21.3698 10.0701 26.2349 12.6697L26.845 12.9958L27.4241 12.617C28.5053 11.9094 29.7253 11.8801 30.3908 12.5456C31.0732 13.228 31.0164 14.5148 30.2559 15.6053L29.8666 16.1636L30.1606 16.7774C32.4722 21.6031 30.7424 28.3176 25.9542 33.1059ZM34.4268 7.14766C33.4153 7.59927 32.5354 7.64286 31.7489 7.2832C30.2459 6.59582 29.2356 4.52988 28.7034 2.99186C30.3305 3.0217 32.6079 3.34309 33.7267 4.55933C34.3121 5.19577 34.543 6.0461 34.4268 7.14766Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_278177">
        <rect
          width="39.9968"
          height="39.9971"
          fill="white"
          transform="translate(0.65625 0.661133)"
        />
      </clipPath>
    </defs>
  </svg>
);
