import { FC, ReactNode } from 'react';
import { TableHeader } from './components/TableHeader';

interface TableProps {
  columnHeaders: string[];
  tableRows?: ReactNode;
}

export const Table: FC<TableProps> = ({ columnHeaders, tableRows }) => {
  return (
    <table className="w-full">
      <thead className="rounded-md">
        <tr className="text-text-secondary">
          <TableHeader columnHeaders={columnHeaders} />
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
