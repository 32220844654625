import { FC } from 'react';
import { Provider, useRecentSearches, useSearch } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { ProviderButton } from '../ProviderButton';
import { SearchIcon, SimpleClose } from 'src/components/svg';
import { InputSearch } from 'src/components/Inputs/InputSearch';

import { NoGamesStub } from '../../../NoGamesStub';
import { RecentSearches } from 'src/components/RecentSearches/RecentSearches';

interface ProvidersSearchResults {
  isFetching: boolean;
  providers: Provider[];
}

const PROVIDERS_SEARCH_LC_KEY = 'providersSearchResults';

export const ProvidersSearchResults: FC<ProvidersSearchResults> = ({ isFetching, providers }) => {
  const { t } = useTranslation();
  const { value, onChange } = useSearch();
  const { recentSearches, saveRecentResult, deleteRecentSearch } = useRecentSearches({
    storageKey: PROVIDERS_SEARCH_LC_KEY,
    maxStoredSearchesLength: 6
  });

  const handleSaveRecentResult = () => {
    if (providers?.length) {
      saveRecentResult(value, true);
    }
  };

  const handleRecentSearchClick = (item: string) => {
    onChange(item);
  };

  const filterProviders = providers?.filter((provider) => {
    return provider.name.toLowerCase().includes(value.toLowerCase());
  });
  return (
    <div>
      <InputSearch
        placeholder={`${t('general.searchProvider')}`}
        value={value}
        onChange={onChange}
        leftIcon={<SearchIcon className="w-5 h-5" fill="#3C404A" />}
        className="flex bg-white items-center rounded-[20px] px-5 py-[13px] w-full mt-2.5"
        classNameInput="text-[#3C404A] text-[16px] px-2.5 focus:outline-none w-full"
        rightIcon={
          value.length ? (
            <button onClick={() => onChange('')}>
              <SimpleClose className="w-[11px] h-[11px]" fill="#3c404a" />
            </button>
          ) : null
        }
      />
      {!value && Boolean(recentSearches?.length) && (
        <RecentSearches
          items={recentSearches}
          handleDelete={deleteRecentSearch}
          handleClick={handleRecentSearchClick}
        />
      )}
      {value && Boolean(filterProviders.length) && (
        <>
          <h3 className="text-text-secondary font-bold text-base mt-3">
            {t('general.searchResults')}
          </h3>
          <div className="mt-2 grid grid-cols-2 gap-2 568:grid-cols-3">
            {filterProviders?.map((provider) => (
              <ProviderButton
                onClick={handleSaveRecentResult}
                provider={provider}
                key={provider.id}
              />
            ))}
          </div>
        </>
      )}

      {!filterProviders?.length && !isFetching && (
        <NoGamesStub>{t('general.noProvidersFound')}</NoGamesStub>
      )}
    </div>
  );
};
