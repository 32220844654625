import classNames from 'classnames';
import { memo, useMemo } from 'react';
import {
  makeFloat,
  useGetFullInfo,
  useGetTournamentWithUsersProgress,
  useGetUserTournamentProgress
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { useGetLocalTournamentPrize } from 'src/hooks/useGetLocalTournamentPrize';
import { useModalContext } from 'src/hooks/useModalContext';
import { Button } from '../Buttons/Button';
import ModalWrapper from '../ModalWrapper';
import { tournamentProgressFormatter } from 'src/utils/tournamentProgressFormatter';

const MAX_MEMBERS_DISPLAY_COUNT = 10;

const GameTournamentMembers = () => {
  const { modalProps, closeModal } = useModalContext<{ tournamentId: string }>();
  const { t } = useTranslation();
  const { getPrize } = useGetLocalTournamentPrize();
  const { fullInfo } = useGetFullInfo();
  const { data: tournamentProgress } = useGetUserTournamentProgress(
    modalProps?.tournamentId || '0',
    {
      refetchInterval: 5000,
      enabled: Boolean(modalProps?.tournamentId)
    }
  );
  const { data: tournament } = useGetTournamentWithUsersProgress(
    {
      tournamentId: modalProps?.tournamentId
    },
    {
      refetchInterval: 5000
    }
  );

  const { members, places } = tournament || {};

  const membersUserIndex = useMemo(() => {
    return members?.findIndex((member) => member.isCurrentUser);
  }, [members]);

  return (
    <ModalWrapper>
      <div className="max-w-full 412:w-[350px] 768:w-[460px] bg-gradient text-white py-[15px]">
        <p className="text-center text-[26px] font-bold px-[10px] mb-[20px]">
          {t('tournaments.currentLeaderboard')}
        </p>

        <table className="table-auto w-full text-[14px] font-medium">
          <thead className="px-[10px] py-[20px]">
            <tr className="capitalize bg-background-hexademical font-bold [&>th]:py-[20px]">
              <th>№</th>
              <th>{t('tournaments.user')}</th>
              <th>{t('tournaments.results')}</th>
              <th>{t('tournaments.prize')}</th>
            </tr>
          </thead>
          <tbody>
            {members
              ?.slice(0, MAX_MEMBERS_DISPLAY_COUNT)
              .map(({ isCurrentUser, login, progress }, index) => (
                <tr
                  key={index}
                  className={classNames(
                    'text-center even:bg-background-main odd:bg-bg-gamecard-bottom [&>td]:py-[9px]',
                    isCurrentUser && '!bg-yellow text-background-fivefold',
                    index === 0 && '!bg-button-secondary',
                    index === 1 && '!bg-game-badge-top'
                  )}>
                  <td>{index + 1}</td>
                  <td>{login}</td>
                  <td>
                    {tournament &&
                      tournamentProgressFormatter(
                        tournament.type,
                        tournament.tasks[0].type,
                        progress
                      )}
                  </td>
                  <td>{getPrize(places[index])}</td>
                </tr>
              ))}
            {!!tournamentProgress && membersUserIndex + 1 > MAX_MEMBERS_DISPLAY_COUNT ? (
              <tr className="text-center bg-yellow text-background-fivefold [&>td]:py-[9px] px-[10px]">
                <td>{membersUserIndex + 1}</td>
                <td>{fullInfo?.email}</td>
                <td>{makeFloat(tournamentProgress.progress[0].progress)}</td>
                <td>{getPrize(places[membersUserIndex])}</td>
              </tr>
            ) : null}
          </tbody>
        </table>

        <Button
          onClick={() => closeModal()}
          title={`${t('modalResetPassword.okay')}`}
          mode="button-secondary"
          className="text-text-primary lowercase text-[18px] w-[190px] mx-auto mt-[30px]">
          {t('modalResetPassword.okay')}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default memo(GameTournamentMembers);
