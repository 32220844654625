import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import allCountries from './countries.v1.json';
import { Country } from '../types';
import sidebarBanner1 from 'src/assets/images/sidebarBanner1.webp';
import sidebarBanner2 from 'src/assets/images/sidebarBanner2.webp';
import sidebarBanner3 from 'src/assets/images/sidebarBanner3.webp';
import lnld from '../assets/images/lnld.webp';
import jackpotCategoryIcon from 'src/assets/images/jackpotCategoryIcon.png';
import { License } from 'src/components/svg/License';
import {
  Astropay,
  Ball,
  BankTransfer,
  Bitcoin,
  Cashtocode,
  Chalice,
  Cherry,
  Crown,
  EcoPayz,
  Ethereum,
  Ezeewallet,
  Flexepin,
  IdentityVerification,
  Jeton,
  Litecoin,
  MB,
  Neosurf,
  Neteller,
  Paysafe,
  Present,
  Ripple,
  Skrill,
  StarIcon,
  Tether,
  VerificarionAddress,
  VerificarionIdentity,
  VerificarionPayment
} from 'src/components/svg';
import { TopIcon } from 'src/components/svg/categories/Top';
import { BonusIcon } from 'src/components/svg/categories/Bonus';
import { Slots } from 'src/components/svg/categories/Slots';
import { Megaways } from 'src/components/svg/categories/Megaways';
import { NewIcon } from 'src/components/svg/categories/New';
import { FruitsIcon } from 'src/components/svg/categories/Fruits';
import { BlackjackIcon } from 'src/components/svg/categories/Blackjack';
import { DropwinsIcon } from 'src/components/svg/categories/Dropwins';
import { BookIcon } from 'src/components/svg/categories/Book';
import { Games, Support, Security, Wallet } from 'src/components/svg/Advantage';

// Эта структура зависит от настроек категорий в админке и должна
// соответствовать значениям в GameCategories
export const jackpotGameCategories = {
  595: 'mini',
  596: 'middle',
  597: 'grand'
};

export const bonusHeader = [
  'bonusTransactionHistoryTable.id',
  'bonusTransactionHistoryTable.type',
  'bonusTransactionHistoryTable.amount',
  'bonusTransactionHistoryTable.balance',
  'bonusTransactionHistoryTable.status'
];
export const transactionHistoryHeader = [
  'transactionHistoryTable.status',
  'transactionHistoryTable.date',
  'transactionHistoryTable.type',
  'transactionHistoryTable.amount',
  'transactionHistoryTable.fee',
  'transactionHistoryTable.curerncy'
];
export const menuItem = [
  {
    path: '/games/top',
    name: 'menuItem.games',
    image: <Cherry />
  },
  {
    path: '/jackpots',
    name: 'menuItem.jackpots',
    image: <Crown />
  },
  {
    path: '/games/live',
    name: 'menuItem.live',
    image: <Ball />
  },
  // {
  //   path: '/halloween',
  //   name: 'menuItem.halloweenSpins',
  //   image: <Pumkins />
  // },
  {
    path: '/bonuses',
    name: 'menuItem.promo',
    image: <Present />
  },
  {
    path: '/tournaments',
    name: 'menuItem.tournaments',
    image: <Chalice />
  },
  {
    path: '/vip',
    name: 'menuItem.vip',
    image: <StarIcon />
  }
];

export const categoriesItem = [
  {
    name: 'categoriesItem.jackpotsGames',
    path: '/games/jackpots',
    image: <img src={`${jackpotCategoryIcon}`} className="w-[31px] h-auto" />
  },
  {
    name: 'categoriesItem.top',
    path: '/games/top-games',
    image: <TopIcon />,
    key: 'top'
  },
  {
    name: 'categoriesItem.bonusGames',
    path: '/games/bonus-games',
    image: <BonusIcon />,
    key: 'bonusGames'
  },
  {
    name: 'categoriesItem.slots',
    path: '/games/slots',
    image: <Slots />,
    key: 'slots'
  },
  {
    name: 'categoriesItem.megaways',
    path: '/games/megaways',
    image: <Megaways />,
    key: 'megaways'
  },
  {
    path: '/games/new-games',
    name: 'categoriesItem.new',
    image: <NewIcon />,
    key: 'new'
  },
  {
    name: 'categoriesItem.fruits',
    path: '/games/fruits-games',
    image: <FruitsIcon />,
    key: 'fruits'
  },
  {
    name: 'categoriesItem.blackjack',
    path: '/games/blackjack',
    image: <BlackjackIcon />,
    key: 'blackjack'
  },
  {
    name: 'categoriesItem.dropWins',
    path: '/games/dropwins',
    image: <DropwinsIcon />,
    key: 'dropWins'
  },
  {
    name: 'categoriesItem.bookSlots',
    path: '/games/books',
    image: <BookIcon />,
    key: 'bookSlots'
  }
];

export const L = {
  NLD: {
    text: 'l.ll',
    logo: <img src={lnld} className="max-w-[65px] " alt="first license" />,
    getPageCodeFormat: (page?: string) => (page ? `${page}_jt` : undefined)
  },
  OTHERS: {
    text: 'l.l',
    logo: (
      <a
        href="https://licensing.gaming-curacao.com/validator/?lh=21c0bec8198be1c2d52fe40bb0af4961"
        target="_blank"
        rel="noreferrer">
        <License className="max-w-[100px]" />
      </a>
    ),
    getPageCodeFormat: (page?: string) => page
  }
};

export const paymentsCards = [
  // {
  //   name: 'Volt',
  //   image: <Volt />
  // },
  // {
  //   name: 'NodaPay',
  //   image: <Nodapay />
  // },
  {
    name: 'Skrill',
    image: <Skrill />
  },
  {
    name: 'Neteller',
    image: <Neteller />
  },
  {
    name: 'Ezeewallet',
    image: <Ezeewallet />
  },
  {
    name: 'Neosurf',
    image: <Neosurf />
  },
  {
    name: 'Astropay',
    image: <Astropay />
  },
  {
    name: 'Flexepin',
    image: <Flexepin />
  },
  {
    name: 'Cashtocode',
    image: <Cashtocode />
  },
  {
    name: 'Jeton',
    image: <Jeton />
  },
  {
    name: 'MB',
    image: <MB />
  },
  {
    name: 'EcoPayz',
    image: <EcoPayz />
  },
  {
    name: 'BankTransfer',
    image: <BankTransfer />
  },
  {
    name: 'Paysafe',
    image: <Paysafe />
  },
  {
    name: 'Ripple',
    image: <Ripple />
  },
  {
    name: 'Bitcoin',
    image: <Bitcoin />
  },
  {
    name: 'Ethereum',
    image: <Ethereum />
  },
  {
    name: 'Litecoin',
    image: <Litecoin />
  },
  {
    name: 'Tether',
    image: <Tether />
  },
  {
    name: 'IdentityVerification',
    image: <IdentityVerification />
  }
];

const arrayTransactions = [
  'Deposit',
  'Withdraw',
  'Bet',
  'Win',
  'Bonus',
  'JackpotGraduation',
  'Change Balance Inc',
  'Change Balance Dec',
  'Referral',
  'Convert Balance',
  'Money Prize',
  'Bonus LatestTransaction',
  'Cancel Bonus Fine',
  'Refund',
  'Rollback Bet',
  'Rollback Win',
  'Bet Sport',
  'Index Increasing',
  'Level Bonus'
];

dayjs.extend(localeData);
dayjs.extend(utc);

export const months = dayjs.monthsShort().map((elem, index) => {
  return { name: elem, id: String(index + 1) };
});

export const days = new Array(31).fill(null).map((_, i) => {
  return { name: `${i + 1}`, id: String(`${i + 1}`) };
});

const currentYear = new Date().getFullYear();

export const years = new Array(100).fill(null).map((year, i) => {
  return { name: `${currentYear - 18 - i}`, id: String(`${currentYear - 18 - i}`) };
});

export const transactionTypesOptions = arrayTransactions.map((element, index) => {
  return { name: element, id: String(index + 1) };
});

export const IconCurrency = {
  EUR: '€',
  USD: '$',
  RUB: '₽',
  FS: 'FS',
  JPY: '¥',
  CHF: 'CHF',
  AUD: 'A$',
  CAD: 'C$',
  PLN: 'zł',
  NOK: 'kr',
  NZD: 'NZ$',
  DKK: 'kr',
  BRL: 'R$',
  SEK: 'kr',
  INR: '₹',
  KZT: '₸',
  ISK: 'Kr',
  USZ: 'UZS',
  TRY: 'TRY',
  NGN: '₦',
  ZAR: 'R',
  UAH: '₴',
  RSD: 'DIN',
  CZK: 'Kč',
  RON: 'RON',
  GBP: '£'
};

export const CurrencyMinRawJackpotFOrRecentWinners = {
  EUR: 20000,
  USD: 20000,
  GBP: 16000,
  RUB: 0,
  JPY: 0,
  CHF: 0,
  AUD: 0,
  CAD: 0,
  PLN: 0,
  NOK: 0,
  NZD: 0,
  DKK: 0,
  BRL: 0,
  SEK: 0,
  INR: 0,
  KZT: 0,
  ISK: 0,
  USZ: 0,
  TRY: 0,
  NGN: 0,
  ZAR: 0,
  UAH: 0,
  RSD: 0,
  CZK: 0,
  FS: 0,
  RON: 0
};

export const allCountriesData: Country[] = allCountries;
export const countriesOptions = allCountries.map((c) => ({ id: c.alpha3, name: c.name }));

export const sortType = [
  { id: 'amountAsc', name: 'sortType.amountUp', sort: 'amount', sortDir: 'asc' },
  { id: 'amountDesc', name: 'sortType.amountDown', sort: 'amount', sortDir: 'desc' },
  { id: 'dateAsc', name: 'sortType.dateUp', sort: 'date', sortDir: 'asc' },
  { id: 'dateDesc', name: 'sortType.amountUp', sort: 'date', sortDir: 'desc' }
];

export const categoryBadgeGame = {
  1: {
    id: 1,
    title: 'categoryBadgeGame.new',
    style: 'bg-game-badge-new rounded-md text-text-secondary'
  },
  2: {
    id: 2,
    title: 'categoryBadgeGame.top',
    style: 'bg-game-badge-top rounded-md text-text-secondary'
  }
};

export const sidebarBanners = [
  {
    image: sidebarBanner1,
    link: '/games/jackpot-mini',
    bannerTitle: 'gameCategories.miniJackpot'
  },
  {
    image: sidebarBanner2,
    link: '/games/jackpot-middle',
    bannerTitle: 'gameCategories.middleJackpot'
  },
  {
    image: sidebarBanner3,
    link: '/games/jackpot-grand',
    bannerTitle: 'gameCategories.grandJackpot'
  }
];

export const contentGamesPageCodes = {
  megaways: 'megaways',
  'fruits-games': 'fruit',
  books: 'book',
  dropwins: 'drops_win'
};

export const COUNTRIES_CURRENCIES_MAP: { [countryCode: string]: string } = {
  RUS: 'RUB',
  NZL: 'NZD',
  AUS: 'AUD',
  NOR: 'NOK',
  UZB: 'UZS',
  KAZ: 'KZT',
  CAN: 'CAD',
  POL: 'PLN',
  NGA: 'NGN',
  ZAF: 'ZAR',
  CHE: 'CHF',
  CZE: 'CZK',
  DNK: 'DKK',
  SWE: 'SEK',
  AUT: 'EUR',
  ALB: 'EUR',
  AND: 'EUR',
  BLR: 'EUR',
  BEL: 'EUR',
  BGR: 'EUR',
  BIH: 'EUR',
  VAT: 'EUR',
  GBR: 'EUR',
  HUN: 'EUR',
  DEU: 'EUR',
  GRC: 'EUR',
  IRL: 'EUR',
  ESP: 'EUR',
  LVA: 'EUR',
  LTU: 'EUR',
  LIE: 'EUR',
  LUX: 'EUR',
  MKD: 'EUR',
  MLT: 'EUR',
  MDA: 'EUR',
  MCO: 'EUR',
  NLD: 'EUR',
  PRT: 'EUR',
  ISL: 'EUR',
  ITA: 'EUR',
  ROU: 'EUR',
  SMR: 'EUR',
  SRB: 'EUR',
  SVK: 'EUR',
  SVN: 'EUR',
  UKR: 'EUR',
  FIN: 'EUR',
  FRA: 'EUR',
  HRV: 'EUR',
  MNE: 'EUR',
  EST: 'EUR',
  JPN: 'JPY'
};

export const adventagesBonus = [
  { text: 'Fast payouts', icon: <Wallet /> },
  { text: '24/7 support', icon: <Support /> },
  { text: 'Licensed games', icon: <Games /> },
  { text: 'Secure payments', icon: <Security /> }
];

export const accountBonusTypes = [
  {
    type: 0,
    name: 'Registration',
    content: 'bonusType.registration'
  },
  {
    type: 1,
    name: 'RegistrationPromoCode',
    content: 'bonusType.registrationPromoCode'
  },
  {
    type: 2,
    name: 'FirstAuthorization',
    content: 'bonusType.firstAuthorization'
  },
  {
    type: 3,
    name: 'PromoCode',
    content: 'bonusType.promoCode'
  },
  {
    type: 4,
    name: 'Birthday',
    content: 'bonusType.birthday'
  },
  {
    type: 5,
    name: 'Deposits',
    content: 'bonusType.deposits'
  },
  {
    type: 6,
    name: 'Periodic',
    content: 'bonusType.periodic'
  },
  {
    type: 7,
    name: 'IncreaseWinnings',
    content: 'bonusType.increaseWinnings'
  },
  {
    type: 8,
    name: 'ActivatedByLink',
    content: 'bonusType.activatedByLink'
  }
];

type JackpotBadgeUtilObjectType = Record<
  string,
  {
    badgeColor: string;
  }
>;

export const jackpotBadgeUtilObject: JackpotBadgeUtilObjectType = {
  'jackpot-mini': {
    badgeColor: 'bg-[#5C89FF]'
  },
  'jackpot-middle': {
    badgeColor: 'bg-[#1BB2A9]'
  },
  'jackpot-grand': {
    badgeColor: 'bg-[#FF493E]'
  }
};
export const KYC_PREFFERED_DATA = [
  {
    icon: <VerificarionIdentity />,
    title: 'accountVerification.identityTitle',
    description: 'accountVerification.identityDescription',
    text: 'accountVerification.identityContent'
  },
  {
    icon: <VerificarionAddress />,
    title: 'accountVerification.addressTitle',
    description: 'accountVerification.addressDescription',
    text: 'accountVerification.addressContent'
  },
  {
    icon: <VerificarionPayment />,
    title: 'accountVerification.paymentTitle',
    description: 'accountVerification.paymentDescriprion',
    text: 'accountVerification.paymentContent'
  }
];
// Должен точно дублировать текущий tailwind config
export const BP = {
  gf: 310,
  xxs: 375,
  xs: 540,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  // MOBILE
  M_XS: 320,
  M_SM: 360,
  M_MD: 375,
  M_LG: 412,

  // TABLET
  T_XS: 568,
  T_SM: 640,
  T_MD: 768,

  // DEKSTOP
  XGA: 1024,
  HD: 1280,
  QHD: 1440,
  FULL_HD: 1920,
  WQHD: 2560
};

export const PROVIDER_ID_SEARCH_PARAM = 'providerId';

export enum CONTENT_PAGES {
  MAIN_STORIES = 'stories-main',
  TOURNAMENTS = 'tournaments',
  LOYALTY_VIP = 'loyalty_vip',
  VIP = 'loyalty_rules',
  IOS = 'ios_app',
  MAIN_PAGE_SLIDERS = 'main_sliders',
  PAYMENT_PENDING = 'payment_pending',
  JACKPOTS = 'jackpots',
  VIP_CLUB = 'vip_club',
  INFO = 'info_pages',
  CATEGORY_SLIDER = 'category-slider',
  FAQ = 'faq',
  APP = 'app'
}

export const MIN_SEARCH_VALUE_LENGTH = 2;
