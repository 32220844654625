export interface UpdateInfoMap {
  address: 'address';
  birthday: 'birthday';
  city: 'city';
  code: 'code';
  country: 'country';
  name: 'name';
  phone: 'phone';
  postcode: 'postcode';
  surname: 'surname';
}

export type Key =
  | 'address'
  | 'birthday'
  | 'city'
  | 'code'
  | 'country'
  | 'name'
  | 'phone'
  | 'postcode'
  | 'surname';

export const updateInfoMap: UpdateInfoMap = {
  address: 'address',
  birthday: 'birthday',
  city: 'city',
  code: 'code',
  country: 'country',
  name: 'name',
  phone: 'phone',
  postcode: 'postcode',
  surname: 'surname'
};

export const maxLengthField = {
  surname: 100,
  name: 100,
  email: 200,
  phone: 20,
  city: 55,
  address: 100,
  postcode: 20
};
