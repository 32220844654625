import { DetailedHTMLProps, forwardRef, SelectHTMLAttributes, useState } from 'react';
import { PaymentFormField } from 'react-easyrocket';
import { Arrow } from 'src/components/svg';
import classNames from 'classnames';

interface SelectComponentProps
  extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  field: PaymentFormField;
  options: { id: string; name: string }[];
  className: string;
}

export const SelectComponent = forwardRef<HTMLSelectElement, SelectComponentProps>(
  ({ field, options, className, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleBlur = () => setIsOpen(false);
    const handleClick = () => setIsOpen((prevState) => !prevState);

    return (
      <div className={classNames(!field.toShow && 'hidden')}>
        <label className="capitalize block mb-2 ml-5">{field.name}</label>
        <div className="relative" key={field.name}>
          <select
            ref={ref}
            {...props}
            name={field.name}
            key={field.name}
            className={className}
            autoComplete="new-password"
            onClick={handleClick}
            onBlur={handleBlur}>
            {options.map((option) => (
              <option value={option.id} key={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <Arrow
            className={`absolute w-4 h-4 top-[50%] translate-y-[-50%] right-[21px] transition-transform duration-200 ${
              isOpen ? 'rotate-180' : ''
            }`}
          />
        </div>
      </div>
    );
  }
);

SelectComponent.displayName = 'SelectComponent';
