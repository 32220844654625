import { ReactNode } from 'react';
import { JackpotsIcon, PromoIcon, TournamentsIcon, VipIcon } from '../svg/SidebarIcons';
import { Tooltips } from '../Sidebar/components/SidebarTooltip';
import { SportIcon } from '../svg/SportIcon';
import { LiveBetIcon } from '../svg/LiveBetIcon';
import { CyberSportIcon } from '../svg/CyberSportIcon';

export type SidebarLink = {
  id: string;
  icon: ReactNode;
  title: string;
  path: string;
  tooltip: Tooltips;
};

// TODO: edit sport links
export const sportLinks: SidebarLink[] = [
  {
    id: 'sport-link',
    path: '/sport/main-page',
    icon: <SportIcon />,
    title: 'menuItem.sport',
    tooltip: Tooltips.SPORT
  },
  {
    id: 'live-betting',
    path: '/sport/live',
    icon: <LiveBetIcon />,
    title: 'menuItem.liveBetting',
    tooltip: Tooltips.LIVEBETTING
  },
  {
    id: 'cyber-sports',
    path: '/sport/cybersport',
    icon: <CyberSportIcon />,
    title: 'menuItem.cyberSports',
    tooltip: Tooltips.CYBERSPORTS
  }
];

export const sidebarLinks: { [linkName: string]: SidebarLink } = {
  jackpots: {
    id: 'jackpots-icon',
    path: '/jackpots',
    icon: <JackpotsIcon />,
    title: 'menuItem.jackpots',
    tooltip: Tooltips.JACKPOTS
  },
  bonuses: {
    id: 'bonuses-icon',
    path: '/bonuses',
    icon: <PromoIcon />,
    title: 'menuItem.promo',
    tooltip: Tooltips.PROMO
  },
  tournaments: {
    id: 'tournaments-icon',
    path: '/tournaments',
    icon: <TournamentsIcon />,
    title: 'menuItem.tournaments',
    tooltip: Tooltips.TOURNAMENTS
  },
  vip: {
    id: 'vip-icon',
    path: '/vip',
    icon: <VipIcon />,
    title: 'menuItem.vip',
    tooltip: Tooltips.VIP
  }
};
