import { useGetDepositMethods, useTrackReferralLink } from 'react-easyrocket';
import { useDoSomethingOnEveryPage } from 'src/hooks/useDoSomethingOnEveryPage';
import { useSetSmarticoUser } from 'src/hooks/useSetSmarticoUser';
import { useTrackIncomingBonusTransaction } from 'src/hooks/useTrackIncomingBonusTransaction';
import { useJackpotWin } from '../Modal/JackpotWinModal/useJackpotWin';
import { useTournamentWin } from 'src/hooks/useTournamentWin';
import { useOpenDepositOnSessionStart } from 'src/hooks/useOpenDepositOnSessionStart';
import { useCentrifugeMessages } from 'src/hooks/useCentrifugeMessages';
import { useActivateAuthBonus } from 'src/hooks/useActivateAuthBonus';
import { useSetUserLanguage } from 'src/hooks/useSetUserLanguage';
import { useModalContext } from 'src/hooks/useModalContext';
import { useDoSomethingOnMount } from 'src/hooks/useDoSomethingOnMount';
import { useDoSomethingOnLogout } from 'src/hooks/useDoSomethingOnLogout';
import { useLanguageSync } from '../../hooks/useSyncUrlLanguage';
import { MessagesContainer } from '../../modules/messages/components/MessagesContainer';
import { Modal } from '../Modal';
import { PageHead } from '../PageHead';
import { LiveChatWidget } from '../LiveChatWidget';
import { ProgressiveTooltip } from '../ProgressiveTooltip/ProgressiveTooltip';
import Cookies from '../CookiesBanner';
import { FreeSpinsHandler } from '../FreeSpinsHandler';
import { OpenSingnUpByLink } from '../OpenSingnUpByLink';

export const SideEffects = () => {
  const { openModal } = useModalContext();
  useDoSomethingOnMount();
  useDoSomethingOnEveryPage();
  useDoSomethingOnLogout();
  useSetSmarticoUser();

  useSetUserLanguage();
  useLanguageSync();

  useTrackIncomingBonusTransaction();
  useJackpotWin();
  useTournamentWin();
  useGetDepositMethods();
  useOpenDepositOnSessionStart();
  useTrackReferralLink({ openSignup: () => openModal('SIGNUP') });
  useCentrifugeMessages();
  useActivateAuthBonus();

  return (
    <>
      <MessagesContainer />
      <Modal />
      <PageHead />
      <LiveChatWidget />
      <ProgressiveTooltip />
      <Cookies />
      <FreeSpinsHandler />
      <OpenSingnUpByLink />
      {/* <EventPromoBanner /> */}
    </>
  );
};
