import { isProductionEnv } from '../api';
import { executeAdditionalGTMScript } from './index';
import TagManager from 'react-gtm-module';
import { unregisterOldServiceWorkers } from './unregisterOldServiceWorkers';
import { registerDnsSpoofer, unregisterDnsSpoofer } from './dnsSpoofer';
import { addVitePreloadErrorHandler } from './preloadRetry';
import { smarticoInit } from './smartico';

export const initializingExtensions = () => {
  if (isProductionEnv) {
    const userId = localStorage.getItem('userId');
    const tagManagerArgs = {
      gtmId: 'GTM-NNQNPWL',
      events: {
        user_id: userId || undefined
      }
    };
    executeAdditionalGTMScript(userId ? parseInt(userId) : undefined);
    TagManager.initialize(tagManagerArgs);
  }

  unregisterOldServiceWorkers();

  // Регистрирует спуфер на проде и прод зеркалах
  if (isProductionEnv) registerDnsSpoofer();

  // Удаляет возможные зарегистрированные спуферы на всех доменах, кроме прода
  if (!isProductionEnv) unregisterDnsSpoofer();

  // Добавление обработчика неудачной подгрузки lazy модуля
  addVitePreloadErrorHandler();

  // Инициализация smartico
  smarticoInit();
};
