import {
  RippleIcon,
  BitcoinCashIcon,
  BitcoinIcon,
  EtheriumIcon,
  LitecoinIcon,
  DogeCoin,
  TehterIcon
} from 'src/components/svg';

export const CRYPTO_ICONS: any = {
  XRP: <RippleIcon className="h-7 w-auto" />,
  BCH: <BitcoinCashIcon className="h-7 w-auto" />,
  BTC: <BitcoinIcon className="h-7 w-auto" />,
  ETH: <EtheriumIcon className="h-7 w-auto" />,
  LTC: <LitecoinIcon className="h-7 w-auto" />,
  DOGE: <DogeCoin className="h-7 w-auto" />,
  USDTTRC20: <TehterIcon className="h-7 w-auto" />,
  USDTERC20: <TehterIcon className="h-7 w-auto" />
};
