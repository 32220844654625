import { useTranslation } from 'react-i18next';
import { ShopItemType, useBuyItem, useGetFullInfo } from 'react-easyrocket';
import { memo } from 'react';
import BonusFreespinsShopItemEvent from '../BonusFreespinsShopItemEvent';
import { useModalContext } from 'src/hooks/useModalContext';

interface ByBonusModalFreespinsProps {
  bonus?: ShopItemType;
  isCoins: boolean;
}

const ByBonusModalFreespinsEvent = ({ bonus, isCoins }: ByBonusModalFreespinsProps) => {
  const { mutateAsync: buyShopItem, isLoading } = useBuyItem({});
  const { closeModal } = useModalContext();
  const handleToHowToEarn = () => {
    closeModal();
    const freespinsInGames = document.getElementById('howToEarn');
    freespinsInGames?.scrollIntoView({
      behavior: 'smooth'
    });
  };
  const { fullInfo } = useGetFullInfo({});
  const userShopPoints = fullInfo?.shopPoints || 0;
  const costPoints = bonus?.costPoints || 0;
  const isHaveCoins = Boolean(userShopPoints >= costPoints);
  const { t } = useTranslation();

  const onBuyButtonClick = async () => {
    if (!bonus) return;
    // eslint-disable-next-line camelcase
    await buyShopItem({ item_id: bonus.id });
  };

  return (
    <>
      {isCoins && isHaveCoins ? (
        <p className="text-mp_text first-letter:uppercase font-bold 768:text-2xl text-base text-center pt-3 max-w-[610px] w-full">
          {t('shop.doYouWantFs')}
        </p>
      ) : (
        <p className="text-mp_text first-letter:uppercase font-bold 768:text-2xl text-base text-center pt-3 max-w-[610px] w-full">
          {t('shop.youDontFs')}
        </p>
      )}
      <div className="mb-12 flex flex-col">
        <BonusFreespinsShopItemEvent
          isCoins={Boolean(isCoins && isHaveCoins)}
          item={bonus}
          className="bg-[transparent]"
          isModal={true}
          handleBuyButtonClick={onBuyButtonClick}
          isLoading={isLoading}
        />
        {!isCoins && (
          <p
            className="text-base first-letter:uppercase underline text-center pt-5 text-mp_title font-bold cursor-pointer"
            onClick={() => handleToHowToEarn()}>
            {t('shop.howToEarn')}
          </p>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line no-restricted-exports
export default memo(ByBonusModalFreespinsEvent);
