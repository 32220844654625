import { useTranslation } from 'react-i18next';

export const FinishedBadge = () => {
  const { t } = useTranslation();
  return (
    <p className="px-1.5 py-2 text-[12px] leading-[13px] font-bold text-text-secondary uppercase bg-input-border-error rounded-[7px] mb-[30px] mt-2.5">
      {t('tournaments.finished')}
    </p>
  );
};
