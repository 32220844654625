import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

export const FullScreenImage = () => {
  const { modalProps } = useModalContext<{ photo: string }>();

  return (
    <ModalWrapper>
      {!modalProps?.photo.endsWith('pdf') ? (
        <img src={modalProps?.photo} className="h-[70vh] lg:h-[60vh] w-auto" />
      ) : (
        <iframe src={modalProps?.photo} className="h-[70vh] lg:h-[60vh] w-auto" />
      )}
    </ModalWrapper>
  );
};
