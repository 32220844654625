import { FC } from 'react';
import { useCountdownTimer } from 'src/hooks/useCountdownTimer';
import { CountdownItemCell } from './components/CountdownItemCell';
import sandClockIcon from 'src/assets/images/sandClock.webp';

interface CountdownTimerProps {
  tournamentDateEnd: number;
}

export const CountdownTimer: FC<CountdownTimerProps> = ({ tournamentDateEnd }) => {
  const timeLeft = useCountdownTimer(tournamentDateEnd);

  return (
    <div className="shadow-tournamentCard flex gap-[12.5px] 768:gap-[15px] border-2 border-yellow rounded-lg px-[18px] py-3">
      <img src={sandClockIcon} alt="clock" className="h-8 w-[25px] 768:h-[45px] 768:w-[35px]" />
      {Object.entries(timeLeft).map(([date, value]) => {
        return <CountdownItemCell key={date + value} value={value} date={date} />;
      })}
    </div>
  );
};
