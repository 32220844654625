import { CasinoIcon } from '../svg/SidebarIcons';
import { SportIcon } from '../svg/SportIcon';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  MouseEventHandler,
  useEffect,
  useState
} from 'react';
import classes from './index.module.css';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useSportContext } from 'src/context/SportProvider';
import { useNavigateLng } from 'src/lib';

// TODO: change constants to reflect navigation
const casinoNavigatePath = '/games';
const sportNavigatePath = '/sport';

const minifiedVariantLanguages = ['pt', 'sl'];

export const SportSwitch: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className, onClick }) => {
  const { isSportPage } = useSportContext();
  const navigate = useNavigateLng();

  const [isActive, setIsActive] = useState(isSportPage);
  // const { openModal } = useModalContext();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  useEffect(() => {
    setIsActive(isSportPage);
  }, [isSportPage]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (isActive) {
      navigate(casinoNavigatePath);
    } else {
      navigate(sportNavigatePath);
    }

    setIsActive(!isActive);
    // openModal('SPORT_STUB_MODAL');

    onClick?.(event);
  };

  const isMinifiedVersion = minifiedVariantLanguages.some((lng) => lng === language);

  return (
    <button
      className={twJoin(
        'relative rounded-[32px] flex text-base text-white w-full font-bold uppercase bg-sport_switch_bg',
        classes['sport-switch'],
        isActive && classes['sport-switch-active'],
        isMinifiedVersion && classes['sport-switch-minified'],
        className
      )}
      onClick={handleClick}>
      <div className="casino-item w-1/2 flex items-center justify-center rounded-[32px] z-10 px-1 py-2.5">
        <CasinoIcon className="w-6 h-6 flex-none" />
        <span className="ml-2">{t('menuItem.casino')}</span>
      </div>
      <div className="sport-item w-1/2 flex items-center justify-center rounded-[32px] z-10 px-4 py-2.5">
        <SportIcon className="w-6 h-6 flex-none" />
        <span className="ml-2">{t('menuItem.sport')}</span>
      </div>
    </button>
  );
};
