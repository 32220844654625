const FILENAME = 'spoofer+offline.js';

export function registerDnsSpoofer() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      navigator.serviceWorker
        .register(`/${FILENAME}`, {
          scope: '/'
        })
        .then((registration) => {
          console.log('Service Worker registered with scope: ', registration.scope);
        })
        .catch((error) => {
          console.log('Service Worker registration failed: ', error);
        });
    });
  }
}

export function unregisterDnsSpoofer() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        if (registration.active?.scriptURL.includes(FILENAME)) registration.unregister();
      }
    });
  }
}
