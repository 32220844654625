import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ThinClose } from 'src/components/svg';

interface RecentSearchesProps {
  items: string[];
  handleClick: (search: string) => void;
  handleDelete?: (search: string) => void;
}

export const RecentSearches: FC<RecentSearchesProps> = ({ items, handleDelete, handleClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-sm font-medium text-text-secondary mt-3">
        {t('general.recentSearches')}
      </h3>
      <div role="button" tabIndex={0} className="overflow-x-auto flex gap-1 pb-1 mt-2.5">
        {items.map((item) => {
          const onCrossClick: MouseEventHandler<HTMLButtonElement> = (e) => {
            e.stopPropagation();
            handleDelete?.(item);
          };

          const onItemClick = () => {
            handleClick(item);
          };

          return (
            <div
              className="p-3 flex-none border-1 rounded-lg border-solid border-topbar_search_button text-sm text-text-secondary"
              key={item}
              onClick={onItemClick}>
              {item}
              <button className="p-1 ml-2.5 hover:opacity-75" onClick={onCrossClick}>
                <ThinClose className="w-3 h-3" />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};
