import { DoubleTick } from 'src/components/svg/DoubleTick';
import { CrossCloseIcon } from 'src/components/svg/userMessageIcons';
import { useMessage } from 'src/modules/messages';

interface MessageActionInterface {
  isReadIt?: boolean;
  id: string;
}

export const MessageHead = ({ isReadIt, id }: MessageActionInterface) => {
  const { closeMessage } = useMessage();
  if (isReadIt) return null;

  return isReadIt ? (
    <DoubleTick className="absolute top-3 right-3" />
  ) : (
    <CrossCloseIcon
      className="absolute top-3 right-3 cursor-pointer"
      onClick={() => closeMessage(id)}
    />
  );
};
