import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiContext, useGetBonusesTransactions } from 'react-easyrocket';
import { useModalContext } from './useModalContext';

export function useTrackIncomingBonusTransaction() {
  const { openModal } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const { pathname } = useLocation();

  const { refetch } = useGetBonusesTransactions(
    {
      bonus_type: 'All',
      status: ['Pending']
    },
    { isNeedPages: false, enabled: false }
  );

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const response = await refetch();
        const transactions = response.data?.data?.bonusTransactions || [];
        if (transactions[0]?.id && transactions[0]?.type !== 16 && transactions[0]?.type !== 1) {
          openModal('BONUS', { props: { bonusId: transactions[0].id } });
        }
      })();
    }
  }, [pathname, isAuthenticated]);
}
