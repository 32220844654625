import { parsePhoneNumber } from 'libphonenumber-js/max';

export const splitPhoneNumber = (value: string): { code?: string; phone?: string } => {
  try {
    const result = parsePhoneNumber(value);
    return { code: result.countryCallingCode, phone: result.nationalNumber };
  } catch (error) {
    console.debug(error);
    return {};
  }
};
