import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PASSWORD_VALIDATION_LENGTH,
  PASSWORD_VALIDATION_SYMBOLS
} from 'src/constants/validationRules';

export function usePasswordChecklist(password: string) {
  const { t } = useTranslation();
  const passwordRules = [
    {
      text: `${t('rulesPassword.countSymbols')}`,
      regex: PASSWORD_VALIDATION_LENGTH,
      done: false
    },
    {
      text: `${t('rulesPassword.onlyLatinLetters')}`,
      regex: PASSWORD_VALIDATION_SYMBOLS,
      done: false
    }
  ];

  const [passwordChecklist, setPasswordChecklist] = useState(passwordRules);

  const runThroughPasswordChecklist = useCallback(() => {
    const passwordValidationResult = passwordChecklist.map((rule) => {
      return {
        ...rule,
        done: rule.regex.test(password)
      };
    });

    setPasswordChecklist(passwordValidationResult);
  }, [passwordChecklist, password]);

  useEffect(() => {
    runThroughPasswordChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return passwordChecklist;
}
