import { useModalContext } from 'src/hooks/useModalContext';

export const useGameTrigger = () => {
  const { openModal } = useModalContext();

  const triggerGame = () => {
    openModal('GPT_WHEEL');
  };

  return triggerGame;
};
