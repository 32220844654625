import { FC, forwardRef, ReactNode } from 'react';

import { twJoin } from 'tailwind-merge';
import { DatePicker } from './components/DatePicker';
import { PickerPosition } from './components/DatePicker/types';
import { getHiddenInputValue } from './utils';
import DatePickerLib from 'react-datepicker';

export interface InputProps {
  label?: ReactNode;
  labelWithPadding?: boolean;
  labelClassName?: string;
  buttonClassName?: string;
  pickerPosition?: PickerPosition;
  name?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  displayedValueFormat?: string;
  isFullScreenDesktop?: boolean;
  isDevCodeMethod?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const InputDate: FC<InputProps> = forwardRef<DatePickerLib, InputProps>(
  (
    {
      value,
      onChange,
      onBlur,
      placeholder,
      label,
      labelWithPadding,
      labelClassName,
      displayedValueFormat,
      name,
      pickerPosition,
      buttonClassName,
      isFullScreenDesktop,
      isDevCodeMethod,
      required,
      disabled
    },
    ref
  ) => {
    const hiddenInputValue = getHiddenInputValue(value, isDevCodeMethod);

    return (
      <div className="flex flex-col justify-center w-full">
        {label && (
          <label
            className={twJoin(
              'text-text-secondary mb-2',
              labelWithPadding && 'pl-5',
              labelClassName
            )}>
            {label}
          </label>
        )}
        <DatePicker
          ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          displayedValueFormat={displayedValueFormat}
          pickerPosition={pickerPosition}
          buttonClassName={buttonClassName}
          isFullScreenDesktop={isFullScreenDesktop}
        />
        {name && (
          <input
            readOnly={true}
            required={required}
            type="text"
            className="hidden"
            name={name}
            value={hiddenInputValue}
          />
        )}
      </div>
    );
  }
);

InputDate.displayName = 'InputDate';
