import { twJoin } from 'tailwind-merge';
import { GPTWheelOfFortuneButtonProps } from '../../';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WheelIcon } from '../../icons/WheelIcon';
import { WheelArrow } from '../../icons/WheelArrow';
import { useGameTrigger } from 'src/modules/GPT/GPTWheelGame/hooks/useGameTrigger';

export const GPTWheelOfFortuneButtonAuth: FC<GPTWheelOfFortuneButtonProps> = ({ compact }) => {
  const { t } = useTranslation();
  const trigger = useGameTrigger();

  return (
    <button
      onClick={trigger}
      className={twJoin(
        'w-full flex items-center gap-2 text-[18px] font-bold relative hover:text-yellow group',
        compact && 'justify-center'
      )}>
      <div className="relative">
        <WheelArrow className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[3px] z-10" />
        <WheelIcon />
      </div>
      {!compact && t('menuItem.wheelOfFortune')}
    </button>
  );
};
