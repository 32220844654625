import eggShop from 'src/assets/event-images/eggShop.webp';
import { useTranslation } from 'react-i18next';
import { MESSAGE_STATUS, ShopItemType } from 'react-easyrocket';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { ArrowsShop } from 'src/components/svg';
import { Button } from 'src/components/Buttons/Button';
import { useModalContext } from 'src/hooks/useModalContext';
import { useMessage } from 'src/modules/messages';

interface BonusFreespinsShopItemProps {
  item?: ShopItemType;
  buyShopItem?: (item: ShopItemType) => void;
  className?: string;
  isModal?: boolean;
  isCoins?: boolean;
  handleBuyButtonClick?: (itemId: number) => Promise<void>;
  isLoading?: boolean;
}

const BonusFreespinsShopItem = ({
  item,
  buyShopItem,
  className,
  isModal,
  handleBuyButtonClick,
  isCoins,
  isLoading
}: BonusFreespinsShopItemProps) => {
  const { closeModal, openModal } = useModalContext();
  const { t } = useTranslation();
  const { openMessage } = useMessage();

  return (
    <div className="flex flex-col justify-center w-[300px] 412:w-[350px]">
      <div
        className={twJoin(
          'flex flex-col items-center  justify-center pt-3 pb-12',
          className ? className : 'bg-mp_card_bg rounded-[20px]'
        )}>
        <p className="text-mp_title first-letter:uppercase text-3xl font-bold pb-2.5 text-center">
          {t('shop.bonusSpins')}
        </p>
        <div className="flex flex-row justify-between w-full px-5 gap-2.5">
          <div className="flex flex-col items-center">
            <p className="text-xl text-mp_text font-bold text-center first-letter:uppercase">
              {t('shop.eggs')}
            </p>
            <div className="flex flex-row items-center gap-1">
              <img src={eggShop} className="w-6 h-6" />
              <p className="text-xl text-mp_text font-bold">{item?.costPoints}</p>
            </div>
          </div>
          <ArrowsShop />
          <div className="flex flex-col items-center">
            <p className="text-xl text-mp_text font-bold text-center first-letter:uppercase">
              {t('freeSpins.freeSpins')}
            </p>
            <p className="bg-mp_freespins_number_bg w-[60px] rounded-[20px] text-xl py-1 text-mp_freespins_number font-bold text-center">
              {item?.freeSpins?.number}
            </p>
          </div>
        </div>
        {!isModal && (
          <Button
            mode="button-secondary"
            className="!w-[180px] mt-5"
            onClick={() => item && buyShopItem?.(item)}
            loading={isLoading}>
            {t('christmasShop.buy')}
          </Button>
        )}

        {isModal && isCoins && (
          <Button
            mode="button-secondary"
            className="!w-[180px] self-center mt-5"
            onClick={async () => {
              openMessage({
                type: 'USER_MESSAGE',
                props: { text: `${t('shop.successfully')}`, type: MESSAGE_STATUS.SUCCESS },
                saveToHistory: false
              });
              closeModal();
              if (!item) return;
              await handleBuyButtonClick?.(item.id);
            }}>
            {t('christmasShop.accept')}
          </Button>
        )}
        {isModal && !isCoins && (
          <Button
            mode="button-secondary"
            className="!w-[180px] self-center mt-5"
            onClick={() => {
              closeModal();
              openModal('POPUP_DEPOSIT');
            }}>
            {t('christmasShop.deposit')}
          </Button>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default memo(BonusFreespinsShopItem);
