import { CSSProperties, FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from 'src/hooks/useModalContext';
import authModalBonus from 'src/assets/images/AuthModalBonus.webp';
import authModalBonusMobile from 'src/assets/images/AuthModalBonusMobile.webp';
import { twJoin } from 'tailwind-merge';

interface AuthModalContainerProps {
  className?: string;
}

export const AuthModalContainer: FC<PropsWithChildren<AuthModalContainerProps>> = ({
  children,
  className
}) => {
  const { closeModal } = useModalContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBonusClick = () => {
    closeModal();
    navigate('/bonuses');
  };

  const style = {
    '--image-desktop': `url(${authModalBonus})`,
    '--image-mobile': `url(${authModalBonusMobile})`
  } as CSSProperties;

  return (
    <div className="320:w-full bg-LOCAL_AUTH_MODAL_BG flex flex-col h-full w-[320px] 768:flex-row-reverse 768:w-[690px] 1024:w-[790px]">
      <div
        style={style}
        className="768:text-center cursor-pointer 768:aspect-[385/484] py-5 bg-[image:var(--image-mobile)] bg-cover 768:bg-bottom 768:w-1/2 768:px-9 768:py-12 pl-[111px] bg-left 768:bg-[image:var(--image-desktop)]"
        onClick={handleBonusClick}>
        <h2 className="text-[12px] leading-[110%] text-white font-bold 768:text-[26px]">
          {t('general.welcomePackBonus')}
        </h2>
        <p className="mt-[3px] text-[16px] text-yellow leading-[110%] font-bold 768:mt-[5px] 768:text-[30px] 768:tracking-[0.9px]">
          {t('registration.banerText')}
        </p>
      </div>
      <div className={twJoin('768:w-1/2 pt-3 pb-5 px-2 768:pt-5 768:pb-6 768:px-5', className)}>
        {children}
      </div>
    </div>
  );
};
