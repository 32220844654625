import { FC, memo, useCallback, useMemo, useRef } from 'react';
import { useDrawer } from 'src/hooks/useDrawer';
import { Arrow } from '../svg';
import { useTranslation } from 'react-i18next';
import { SidebarGamesDropdownOption } from 'src/constants/gameCategories';
import { twJoin } from 'tailwind-merge';
import MenuElement from './components/MenuElement';
import { useBoolean, useGetUserCountry, useWindowSize } from 'react-easyrocket';

interface SidebarGamesDropdownProps {
  option: SidebarGamesDropdownOption;
  isCollapsed?: boolean;
}

const SidebarGamesDropdown: FC<SidebarGamesDropdownProps> = ({
  option: { title, icon, menuItems },
  isCollapsed = false
}) => {
  const [isMenuOpen, , , toggleMenu] = useBoolean(false);
  const { closeLeft, closeNotifications } = useDrawer();
  const { isMobileLayout } = useWindowSize();
  const { t } = useTranslation();
  const { isInitialLoading: isUserCountryLoading } = useGetUserCountry();

  const buttonRef = useRef<HTMLInputElement | null>(null);

  const handleLink = useCallback(() => {
    if (isMobileLayout) {
      closeLeft();
      closeNotifications();
    }
    toggleMenu();
    closeNotifications();
  }, [isMobileLayout, closeLeft, toggleMenu, closeNotifications]);

  const menuElements = useMemo(() => {
    return menuItems.map(({ path, image, title, isEvent, param }) => (
      <MenuElement
        key={title}
        path={path}
        image={image}
        title={title}
        menuElementClickHandler={handleLink}
        isPromo={isEvent}
        isLoading={isUserCountryLoading && param === 'dropwinscat'}
      />
    ));
  }, [menuItems, handleLink, isUserCountryLoading]);

  if (isCollapsed) {
    return (
      <div
        ref={buttonRef}
        onMouseEnter={toggleMenu}
        onMouseLeave={toggleMenu}
        className="cursor-pointer">
        <div className="p-[10px] rounded-[4px] flex relative [&_div>svg>path]:hover:fill-yellow">
          <div
            className={
              (twJoin(isMenuOpen ? '[&>svg>path]:fill-yellow' : ''), '[&>svg]:w-6 [&>svg]:h-6 ')
            }>
            {icon}
          </div>

          <Arrow
            className={twJoin(
              'w-[13px] absolute top-1/2 left-[40px] -translate-y-1/2 stroke-text-hexadecimal stroke-[3px] transition-all',
              isMenuOpen ? '-rotate-90' : 'rotate-0'
            )}
          />
        </div>

        {isMenuOpen && (
          <div
            style={{ top: buttonRef.current?.offsetTop }}
            className="absolute z-[1000] left-[calc(100%-15px)] pl-[30px]">
            <div className="bg-background-sidebar p-[10px] w-[215px] rounded-[5px]">
              <p
                className={
                  'w-full flex items-center gap-[8px] text-[12px] font-bold mb-[8px] [&>svg]:w-6 [&>svg]:h-6'
                }>
                {icon}
                <span className="first-letter:uppercase text-left">{t(title)}</span>
              </p>

              <div className="w-full flex flex-col items-center gap-[5px]">{menuElements}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full">
      <button
        onClick={toggleMenu}
        className={twJoin(
          'w-full flex items-center gap-[8px] text-[18px] font-bold 768:hover:text-yellow 768:[&_div>svg>path]:hover:fill-yellow',
          isMenuOpen && 'text-yellow'
        )}>
        <div
          className={twJoin(
            'w-[24px] [&>svg]:w-6 [&>svg]:h-6',
            isMenuOpen && '[&>svg>path]:fill-yellow'
          )}>
          {icon}
        </div>
        <p className="first-letter:uppercase text-left">{t(title)}</p>

        <Arrow
          className={twJoin(
            'w-[15px] ml-auto stroke-text-hexadecimal stroke-[3px] transition-all',
            isMenuOpen ? 'rotate-0' : '-rotate-90'
          )}
        />
      </button>

      {isMenuOpen && (
        <div className="w-full flex flex-col items-center gap-[5px] mt-[10px]">{menuElements}</div>
      )}
    </div>
  );
};

export default memo(SidebarGamesDropdown);
