import { FC } from 'react';
import { makeFloat, useCurrency, useGetFullInfo } from 'react-easyrocket';
import { getCurrencyIcon } from 'src/utils';
import { Cell } from './components/Cell';

interface CurrentUserRowProps {
  membersUserPlace: number;
  progress: number;
  prize: string | undefined;
}

export const CurrentUserRow: FC<CurrentUserRowProps> = ({ membersUserPlace, progress, prize }) => {
  const { fullInfo } = useGetFullInfo({});
  const { currencyName } = useCurrency();

  return (
    <tr className="bg-yellow">
      <Cell>{membersUserPlace}</Cell>
      <Cell>{fullInfo?.email}</Cell>
      <Cell>{`${getCurrencyIcon(currencyName)} ${makeFloat(progress)}`}</Cell>
      <Cell>{prize}</Cell>
    </tr>
  );
};
