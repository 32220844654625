import { useTranslation } from 'react-i18next';

interface HeaderWalletProps {
  currencySign: string;
  amount?: string;
}

export const HeaderDeposit = ({ currencySign, amount }: HeaderWalletProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <p className="text-base text-text-secondary font-bold">
        {t('depositTab.balance')}{' '}
        <span className="font-bold text-yellow whitespace-nowrap">
          {currencySign} {amount}
        </span>
      </p>
      <p className="text-xs text-text-secondary font-bold">{t('depositTab.choosePaymentMethod')}</p>
    </div>
  );
};
