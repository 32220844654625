import { useEffect, useRef, useState } from 'react';
import { postShowRequest } from './utils/postShowRequest';
import { MessageFromFrame, MessageScenarios } from './types';
import { executeMessageScenarios } from './utils/handleFrameMessage';
import { useModalContext } from 'src/hooks/useModalContext';

export const useGameFrameMessages = () => {
  const { closeModal } = useModalContext();
  const messagesRef = useRef<HTMLIFrameElement>(null);
  const [isGameReady, setIsGameReady] = useState(false);

  // Эффект, который хендлит сообщения, приходящие на React клиент с iframe игры
  useEffect(() => {
    const messageScenarios: MessageScenarios = {
      LOADED: () => {
        const gameFrame = messagesRef.current;
        if (gameFrame) {
          postShowRequest(gameFrame);
        }
      },
      SHOW_RESPONSE: () => {
        setIsGameReady(true);
      },
      QUIT: () => {
        closeModal();
      },
      QUIT_AND_HIDE: () => {
        closeModal();
      }
    };
    /**
     * Ивент-хендлер для сообщений с юнити клиента
     */
    const handleFrameMessage = (event: MessageEvent<MessageFromFrame>) => {
      executeMessageScenarios(
        (messageFromFrame) => {
          const messageType = messageFromFrame.type as keyof MessageScenarios;

          messageScenarios[messageType]?.();
        },
        {
          messageEvent: event,
          gameFrame: messagesRef.current
        }
      );
    };

    window.addEventListener('message', handleFrameMessage);

    return () => {
      window.removeEventListener('message', handleFrameMessage);
    };
  }, []);

  return { messagesRef, isGameReady };
};
