import { FC } from 'react';
import { paymentsCards } from 'src/constants/index';
import { Separator } from 'src/components/Separator/Separator';

export const PaymentsCards: FC = () => {
  return (
    <>
      <Separator width="w-full" />
      <div className="flex flex-wrap items-center justify-center w-full my-7">
        {paymentsCards.map((element, index) => (
          <div
            className="flex items-center justify-center max-w-[120px] h-[30px] px-2.5 mt-2.5"
            key={index}>
            {element.image}
          </div>
        ))}
      </div>
    </>
  );
};
