import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { Checkbox } from 'src/components';
import {
  FieldType,
  useForm,
  validateCountry,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validateRequired
} from 'src/hooks/useForm/index';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'src/components/Inputs/Input';
import { countriesOptions } from 'src/constants';
import { Autocomplete } from 'src/components/Autocomplete';
import { findCountryCodeByName, findCountryNameByCode } from 'src/utils';
import { usePasswordChecklist } from 'src/hooks/usePasswordChecklist';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import {
  passAffiliateParams,
  useApiContext,
  useCurrency,
  useGetUserCountry,
  useRegistration,
  useSignUpModal
} from 'react-easyrocket';
import {
  resetDepositModalShow,
  setDepositModalAsShown
} from 'src/hooks/useOpenDepositOnSessionStart';
import { useModalContext } from 'src/hooks/useModalContext';
import { AlternativeAuthMethodsBlock } from 'src/components/AlternativeAuthMethods';
import { SignUpModalProps } from '..';
import { LinkLng, useNavigateLng } from 'src/lib';

interface StepOneProps {
  changeStep?: (value: number) => void;
  onRegistrationSuccess?: () => void;
  disableFields?: boolean;
  isCompleted?: boolean;
}

export const StepOne: FC<StepOneProps> = ({
  changeStep,
  onRegistrationSuccess,
  disableFields = false
}) => {
  const navigate = useNavigateLng();
  const { t } = useTranslation();
  const { isLongModal } = useSignUpModal();
  const { isAuthenticated } = useApiContext();
  const { userCountry } = useGetUserCountry();
  const { currencyName } = useCurrency();
  const { modalProps, closeModal } = useModalContext<SignUpModalProps>();
  /** Этот useState нужен что бы отдельно тригерить ошибку в чекбоксе подтверждения ДР
   * при попытке регестрации через соц-сети.
   * */

  const [ageError, setAgeError] = useState(false);

  const { mutateAsync, isLoading } = useRegistration({
    onSuccess: () => {
      if (!modalProps?.onSignupSuccess && !modalProps?.isSuccessfullNavigateDisabled) {
        navigate('/games/top-games');
      }
      onRegistrationSuccess?.();
      if (!isLongModal) {
        closeModal?.();
      }
    }
  });

  useEffect(() => {
    if (isLongModal !== undefined && isLongModal) {
      setDepositModalAsShown();
    }
    if (isLongModal !== undefined && !isLongModal) {
      resetDepositModalShow();
    }
  }, [isLongModal]);

  const {
    values,
    errors,
    fieldsProps: { emailProps, passwordProps, countryProps, is18yearsProps },
    isFormValid,
    submitTried,
    setSubmitTried,
    updateDefaultStatePartially,
    focused
  } = useForm<{
    email: FieldType.Text;
    password: FieldType.Text;
    country: FieldType.Autocomplete;
    is18years: FieldType.Checkbox;
  }>({
    email: {
      placeholder: `${t('general.email')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(256), validateEmail]
    },
    password: {
      placeholder: `${t('general.password')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(64), validatePassword]
    },
    country: {
      fieldType: FieldType.Autocomplete,
      placeholder: `${t('general.chooseYourCoutry')}`,
      options: countriesOptions,
      validation: [validateRequired, validateCountry]
    },
    is18years: {
      fieldType: FieldType.Checkbox,
      validation: [validateRequired]
    }
  });

  useEffect(() => {
    if (!userCountry?.country) return;
    updateDefaultStatePartially({
      country: findCountryNameByCode(userCountry.country)
    });
  }, [userCountry?.country]);

  const handleClick = useCallback(async () => {
    setSubmitTried(true);
    if (!isFormValid) return;

    await mutateAsync({
      email: values.email,
      password: values.password,
      country: findCountryCodeByName(values.country),
      currency: userCountry?.currency[0] || currencyName,
      ...passAffiliateParams()
    });
  }, [
    setSubmitTried,
    isFormValid,
    values.email,
    values.password,
    values.country,
    mutateAsync,
    userCountry?.currency,
    currencyName
  ]);

  const passwordChecklist = usePasswordChecklist(values.password);

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (!values.is18years) {
      setAgeError(true);
      event.preventDefault();
    }
  };

  return (
    <form
      className="flex flex-col gap-3 items-center"
      onSubmit={(e) => {
        e.preventDefault();
        isAuthenticated ? changeStep?.(1) : handleClick();
      }}
      autoComplete="off">
      <AlternativeAuthMethodsBlock onLinkClick={handleLinkClick} />
      <div className="w-full relative">
        <Input
          id="email-input"
          {...emailProps}
          autoComplete="off"
          disabled={disableFields}
          inputBorderColor={classNames(
            focused !== 'email' &&
              submitTried &&
              Boolean(errors.email) &&
              'border-input-border-error'
          )}
        />
        {focused !== 'email' && (
          <Tooltip
            anchorSelect="#email-input"
            className="bg-color-link text-bg-content-page rounded-4xl z-[1000] !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.email)}>
            <>{errors.email}</>
          </Tooltip>
        )}
      </div>
      <div className="w-full relative">
        <PasswordInput
          id="password-input"
          {...passwordProps}
          autoComplete="off"
          disabled={disableFields}
          inputBorderColor={classNames(
            focused !== 'password' &&
              submitTried &&
              Boolean(errors.password) &&
              'border-input-border-error'
          )}
        />
        {focused !== 'password' && (
          <Tooltip
            anchorSelect="#password-input"
            className="bg-color-link text-bg-content-page rounded-4xl z-[1000] !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.password)}>
            <>{errors.password}</>
          </Tooltip>
        )}
      </div>
      <div className="w-full relative flex items-center">
        <Tooltip
          anchorSelect="#password-input"
          className="bg-color-link self-center text-bg-content-page rounded-4xl z-[2000] !max-w-full"
          place="bottom"
          openOnClick>
          <>
            {passwordChecklist.map((error) => (
              <p key={error.text} className={classNames({ 'line-through': error.done })}>
                {error.text}
              </p>
            ))}
          </>
        </Tooltip>
        <div className="w-full" id="country-autocomplete">
          <Autocomplete
            notFoundText="registration.notFoundCountry"
            {...countryProps}
            regexAllowedSymbols={/[A-Za-z\- ]/g}
            disabled={disableFields}
            inputBorderColor={classNames(
              focused !== 'country' &&
                submitTried &&
                Boolean(errors.country) &&
                'border-input-border-error'
            )}
          />
          {focused !== 'country' && (
            <Tooltip
              anchorSelect="#country-autocomplete"
              className="bg-color-link text-bg-content-page rounded-4xl z-[1000]"
              place="top"
              isOpen={submitTried && Boolean(errors.country)}>
              <>{errors.country}</>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="w-full text-white relative">
        <Checkbox
          id="is18-checkbox"
          disabled={disableFields}
          classNamesForDescription="text-[14px] font-medium"
          description={
            <Trans
              i18nKey="registration.text"
              components={{
                1: (
                  <LinkLng
                    target="_blank"
                    to="/info_pages/terms_and_conditions"
                    className="cursor-pointer text-button-primary font-normal underline"
                    aria-label="terms and conditions"
                  />
                ),
                2: (
                  <LinkLng
                    target="_blank"
                    to="/info_pages/privacy_policy"
                    className="cursor-pointer text-button-primary font-normal underline"
                    aria-label="privacy policy"
                  />
                )
              }}
            />
          }
          {...is18yearsProps}
        />

        <Tooltip
          anchorSelect="#is18-checkbox"
          className="bg-color-link text-bg-content-page rounded-4xl z-[1000]"
          style={{ transform: 'translateZ(0)' }}
          place="bottom"
          isOpen={
            Boolean(errors.is18years && submitTried) || Boolean(errors.is18years && ageError)
          }>
          <>{errors.is18years}</>
        </Tooltip>
      </div>
      <Button
        className="text-background-fivefold !w-auto mt-1 !px-11 !font-bold !text-[18px] 768:mt-auto"
        title={`${isAuthenticated ? t('registration.nextStep') : t('general.signUp')}`}
        loading={isLoading}
        disabled={isLoading}
        mode="button-secondary">
        {isAuthenticated ? t('registration.nextStep') : t('general.signUp')}
      </Button>
    </form>
  );
};
