import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, NavigateProps, useResolvedPath } from 'react-router-dom';

import { defaultFallbackLanguage } from '../../constants';

export const NavigateLng: FC<NavigateProps> = ({ to, ...props }) => {
  const {
    i18n: {
      options: { fallbackLng },
      language
    }
  } = useTranslation();

  const { pathname, hash, search } = useResolvedPath(to);

  const href = pathname + search + hash;

  const fallbackLanguage = Array.isArray(fallbackLng) ? fallbackLng[0] : fallbackLng;

  const defaultLanguage = (fallbackLanguage as string) || defaultFallbackLanguage;

  const linkWithLanguage = language !== defaultLanguage ? `/${language}${href}` : href;

  return <Navigate to={linkWithLanguage} {...props} />;
};
