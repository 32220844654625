import { FC, HTMLProps, useEffect, useState } from 'react';
import { useFormatter } from 'react-easyrocket';
import { getCurrencyIcon } from 'src/utils';

const MULTIPLIER = 60;

interface CountUpProps extends HTMLProps<HTMLDivElement> {
  to: number;
  duration?: number;
  currency?: string;
  reload?: boolean | string;
}

export const CountUp: FC<CountUpProps> = ({ to, duration = 2, currency, reload, ...rest }) => {
  const { numberFormatter } = useFormatter();
  const [count, setCount] = useState(0);

  const increment = to / (duration * MULTIPLIER);

  useEffect(() => {
    setCount(0);
  }, [to, reload]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + increment;
        if (newCount >= to) {
          clearInterval(interval);
          return to;
        }
        return newCount;
      });
    }, 1000 / MULTIPLIER);

    return () => clearInterval(interval);
  }, [to, increment]);

  return (
    <div {...rest}>
      {getCurrencyIcon(currency || 'EUR')} {numberFormatter.format(count)}
    </div>
  );
};
