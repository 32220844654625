import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { openChat } from '../LiveChatWidget/utils';

export const DepositPaymentInfo = () => {
  const { modalProps, closeModal } = useModalContext<{
    text: { title: string | undefined; content: string | undefined } | undefined;
  }>();
  const { t } = useTranslation();

  return (
    <ModalWrapper>
      <div className="p-[20px] max-w-[600px] flex flex-col gap-7">
        <div>
          <p className=" text-2xl text-center text-text-strong font-bold">
            {modalProps?.text?.title}
          </p>
        </div>
        <div
          className="static-page cms-dropdown"
          dangerouslySetInnerHTML={{ __html: modalProps?.text?.content || '' }}></div>
        <Button
          title={t('modalCancelBonus.ask') as string}
          label={t('modalCancelBonus.ask') as string}
          className="m-auto mt-[15px]"
          mode="button-secondary"
          onClick={() => {
            closeModal();
            openChat();
          }}
        />
      </div>
    </ModalWrapper>
  );
};
