import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const BitcoinIcon = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M21.6705 13.661C20.2012 19.5539 14.2321 23.1402 8.33793 21.6707C2.44618 20.2015 -1.14051 14.2327 0.329502 8.34018C1.79814 2.44665 7.76726 -1.14 13.6597 0.32917C19.5535 1.79834 23.1399 7.76785 21.6705 13.661Z"
        fill="#F7931A"
      />
      <path
        d="M15.8486 9.43299C16.0676 7.96932 14.9531 7.18248 13.4291 6.65758L13.9234 4.67484L12.7164 4.37407L12.2351 6.30455C11.9178 6.22548 11.5919 6.1509 11.2681 6.07699L11.7528 4.13379L10.5465 3.83301L10.0518 5.81505C9.78911 5.75524 9.53127 5.69612 9.281 5.6339L9.28237 5.62771L7.61778 5.21212L7.29669 6.50117C7.29669 6.50117 8.19224 6.70638 8.17334 6.7191C8.6622 6.84113 8.75054 7.1646 8.73576 7.42103L8.17264 9.67979C8.20633 9.68839 8.25 9.70076 8.29813 9.72001C8.25791 9.71004 8.21494 9.69904 8.17059 9.68839L7.38125 12.8526C7.32144 13.0011 7.16984 13.2238 6.82811 13.1393C6.84015 13.1568 5.95078 12.9203 5.95078 12.9203L5.35156 14.3018L6.92231 14.6933C7.21452 14.7666 7.50089 14.8432 7.78279 14.9154L7.28329 16.9208L8.48893 17.2216L8.98363 15.2375C9.31297 15.3269 9.63269 15.4094 9.94554 15.4871L9.45256 17.4619L10.6596 17.7627L11.1591 15.761C13.2173 16.1505 14.765 15.9934 15.4165 14.132C15.9415 12.6333 15.3904 11.7687 14.3075 11.205C15.0961 11.0232 15.6901 10.5044 15.8486 9.43299ZM13.0908 13.2998C12.7178 14.7985 10.1941 13.9883 9.37588 13.7852L10.0387 11.1283C10.8569 11.3325 13.4807 11.7368 13.0908 13.2998ZM13.4642 9.41133C13.1238 10.7746 11.0233 10.082 10.3419 9.91216L10.9429 7.50251C11.6242 7.67232 13.8186 7.98925 13.4642 9.41133Z"
        fill="white"
      />
    </svg>
  );
};
