import { FC } from 'react';

import preloader from '../../assets/video/preloader.gif';

export const VideoPreloader: FC = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <img src={preloader} className="w-auto h-auto " alt="logo" />
    </div>
  );
};
