import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }: { children: ReactNode }) => {
  const [container] = useState(() => document.createElement('div'));
  const ref = document.getElementById('Jacktop');
  useEffect(() => {
    ref && ref.appendChild(container);
    return () => {
      ref && ref.removeChild(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ReactDOM.createPortal(children, container);
};
