import { GameData } from 'react-easyrocket';
import { jackpotGameCategories } from 'src/constants';

export function getJackpotOfGameIfParticipates(game: GameData): string | undefined {
  if (game.jackpot?.group?.name) {
    return game.jackpot.group.name.toLowerCase();
  }
  if (game.categories) {
    const { categories } = game;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id in jackpotGameCategories) {
        return jackpotGameCategories[categories[i].id as keyof typeof jackpotGameCategories];
      }
    }
  }
  return undefined;
}

export function makeJackpotQueryParameter(jackpotType: string | undefined): string {
  if (!jackpotType) return '';
  return `&jackpot=${jackpotType}`;
}
