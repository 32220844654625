import { Deposit } from 'src/pages/AccountWallet/Deposit';
import { Wrapper } from './components/Wrapper';

export const DepositModal = () => {
  return (
    <Wrapper>
      <Deposit isModal />
    </Wrapper>
  );
};
