import React from 'react';
import { SVGPropsIcon } from 'src/types';

export const DogeCoin = (props: SVGPropsIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M11 21.875C17.0061 21.875 21.875 17.0061 21.875 11C21.875 4.9939 17.0061 0.125 11 0.125C4.9939 0.125 0.125 4.9939 0.125 11C0.125 17.0061 4.9939 21.875 11 21.875Z"
        fill="#FFDA44"
      />
      <path
        d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
        fill="#EBBF00"
      />
      <path
        d="M11.0544 0.125C11.1758 0.620441 11.2623 1.12381 11.3131 1.63138C13.0445 1.68908 14.726 2.22537 16.1711 3.18073C17.6162 4.13609 18.7683 5.47316 19.4995 7.04356C20.2308 8.61397 20.5127 10.3563 20.3139 12.0772C20.1151 13.798 19.4433 15.4302 18.3732 16.7924C17.3031 18.1547 15.8764 19.1939 14.2516 19.7945C12.6267 20.3952 10.8672 20.5339 9.16833 20.1953C7.46942 19.8567 5.89756 19.054 4.62717 17.8763C3.35678 16.6985 2.43756 15.1918 1.9715 13.5234C1.48382 13.5901 0.992221 13.6241 0.5 13.625C0.481625 13.625 0.4625 13.625 0.445625 13.625C0.937038 15.6005 1.97309 17.3985 3.43591 18.8143C4.89872 20.23 6.72956 21.2068 8.7201 21.6334C10.7106 22.06 12.781 21.9193 14.6955 21.2274C16.61 20.5354 18.2919 19.3199 19.5497 17.7193C20.8076 16.1187 21.5909 14.1971 21.8105 12.1732C22.0302 10.1494 21.6774 8.10448 20.7922 6.27125C19.907 4.43803 18.525 2.89006 16.8035 1.80349C15.082 0.716914 13.0901 0.135361 11.0544 0.125Z"
        fill="#FFCD00"
      />
      <path
        d="M10.8575 5.75H8V10.25H6.5C6.30109 10.25 6.11032 10.329 5.96967 10.4697C5.82902 10.6103 5.75 10.8011 5.75 11C5.75 11.1989 5.82902 11.3897 5.96967 11.5303C6.11032 11.671 6.30109 11.75 6.5 11.75H8V16.25H11C11.7211 16.25 12.4344 16.1015 13.0955 15.8138C13.7567 15.526 14.3515 15.1051 14.8428 14.5774C15.3341 14.0496 15.7115 13.4263 15.9513 12.7463C16.1912 12.0663 16.2884 11.3442 16.2369 10.625C16.1187 9.28439 15.4992 8.03771 14.5019 7.13398C13.5047 6.23026 12.2032 5.73602 10.8575 5.75ZM14.741 11.2599C14.6531 12.2226 14.2052 13.1168 13.4868 13.7638C12.7685 14.4108 11.8324 14.763 10.8658 14.75H9.5V11.75H11C11.1989 11.75 11.3897 11.671 11.5303 11.5303C11.671 11.3897 11.75 11.1989 11.75 11C11.75 10.8011 11.671 10.6103 11.5303 10.4697C11.3897 10.329 11.1989 10.25 11 10.25H9.5V7.25H11C11.5141 7.25082 12.0226 7.35719 12.4939 7.56251C12.9652 7.76783 13.3894 8.06774 13.7401 8.44366C14.0908 8.81957 14.3606 9.26349 14.5328 9.74791C14.7049 10.2323 14.7758 10.7469 14.741 11.2599Z"
        fill="#F1F2F2"
      />
    </svg>
  );
};
