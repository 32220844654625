import { GameCard } from '../GameCard';
import { GamesGridConfigurable } from 'react-easyrocket';
import { SkeletonOfAnySize } from '../Skeletons';

export const TOTAL_GAMES = 24;

export const GamesGrid = GamesGridConfigurable({
  gridCls:
    'grid-cols-2 412:grid-cols-3 640:grid-cols-4 768:grid-cols-5 1024:grid-cols-6 1440:grid-cols-8',
  renderGameCard: ({ game, onGameClick, key }) => (
    <GameCard key={key} onGameClick={onGameClick} game={game} />
  ),
  GameSkeletonComponent: () => <SkeletonOfAnySize className="w-full h-auto aspect-[158/211]" />
});
