import { useTranslation } from 'react-i18next';
import landingPromoName from 'src/assets/images/landingPromoName.webp';
import { LanguagesSelect } from 'src/components/LanguagesSelect';
import { StepOne } from 'src/components/Modal/AuthModals/Signup/components/StepOne';
import desctopLandingImageFirst from '/src/assets/images/desctopLandingImageFirst.webp';
import desctopLandingImageSecond from '/src/assets/images/desctopLandingImageSecond.webp';
import landingPromoNameSecond from '/src/assets/images/landingPromoNameSecond.webp';
import { useModalContext } from 'src/hooks/useModalContext';
import { twJoin } from 'tailwind-merge';
import { useWindowSize } from 'react-easyrocket';
import { CHANGE_IMAGE_TIME } from './constants';
import WelcomePackInfo from './WelcomePackInfo';
import { Button } from 'src/components/Buttons/Button';
import { EVENT_IMAGES } from 'src/constants/eventImages';

export const Landing = () => {
  const timeRemainder = Date.now() % CHANGE_IMAGE_TIME;
  const showFirstImage = timeRemainder >= CHANGE_IMAGE_TIME / 2;
  const { is1280 } = useWindowSize();
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const openSignup = () => {
    openModal('SIGNUP', {
      props: {
        hideWhyNot: true
      }
    });
  };

  return !is1280 ? (
    <div
      className={twJoin(
        'overflow-hidden bg-no-repeat bg-cover bg-center min-h-screen flex items-center justify-start flex-shrink-0 flex-col pb-[30px]',
        showFirstImage ? 'bg-LANDING_BACKGROUND_FIRST' : 'bg-LANDING_BACKGROUND_SECOND'
      )}>
      <LanguagesSelect containerClassname="h-auto mt-4 mb-9" />
      <img src={EVENT_IMAGES.LOGO.REGULAR} className="w-[35%] aspect-[174/111]" alt="logo" />
      <div
        className={twJoin(
          'w-[55%] 768:w-[30%] aspect-[354/271] bg-contain mt-[20px] bg-no-repeat',
          showFirstImage ? 'bg-LANDING_FIRST_IMAGE' : 'bg-LANDING_SECOND_IMAGE'
        )}
      />
      <Button mode="button-secondary" onClick={openSignup}>
        {t('general.playNow')}
      </Button>
      <h1 className="text-wblp_title font-black text-[26px] uppercase text-center leading-[99%] mt-5">
        {t('general.welcomeBonus')}
      </h1>
      <WelcomePackInfo className="!w-[280px] 1280:!w-[75%]" />
    </div>
  ) : (
    <div
      className={twJoin(
        'overflow-hidden bg-no-repeat bg-cover gap-7 bg-center flex flex-row min-h-screen items-start justify-center flex-shrink-0 pt-[120px]',
        showFirstImage ? 'bg-LANDING_BACKGROUND_FIRST' : 'bg-LANDING_BACKGROUND_SECOND'
      )}>
      <div className="relative flex-[0.3] z-10">
        <img
          src={showFirstImage ? desctopLandingImageFirst : desctopLandingImageSecond}
          className="fixed bottom-0 left-0 bg-no-repeat h-[90%] w-auto"
        />
      </div>
      <div className="flex-[0.3] max-w-[33%] flex flex-col items-center px-[1%] justify-center z-20">
        <img
          alt="landing"
          src={showFirstImage ? landingPromoName : landingPromoNameSecond}
          className="max-h-[200px] w-full object-contain"
        />
        <h3 className="text-wblp_title font-medium text-3xl uppercase text-center mb-5">
          {t('registration.welcomePackage')}
        </h3>
        <h1 className="text-wblp_title font-black text-4xl uppercase text-center leading-[99%]">
          {t('general.welcomeBonus')}
        </h1>
        <WelcomePackInfo />
      </div>
      <div className="flex-[0.3] flex flex-col items-end gap-y-8 relative">
        <LanguagesSelect containerClassname="m-0 h-fit" />

        <div className="flex flex-col items-center rounded-3xl bg-LOCAL_AUTH_MODAL_BG p-5 max-w-[490px]">
          <img src={EVENT_IMAGES.LOGO.REGULAR} className="w-[172px] aspect-[174/111] mb-6" />
          <StepOne
            onRegistrationSuccess={() => {
              openModal('SIGNUP', { props: { initialStep: 1 } });
            }}
          />
        </div>
      </div>
    </div>
  );
};
