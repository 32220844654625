import { useCallback } from 'react';
import { LocalTournamentPlace, makeFloat, useGetShortBalance } from 'react-easyrocket';
import { getCurrencyIcon } from 'src/utils';

export const useGetLocalTournamentPrize = () => {
  const { data } = useGetShortBalance();

  const getPrize = useCallback(
    (prize: LocalTournamentPlace): string | undefined => {
      if (prize?.type === 0) {
        return `${getCurrencyIcon(data?.currency)} ${makeFloat(
          prize.amount[data?.currency || 'EUR']
        )}`;
      }
      if (prize?.type === 3) {
        return prize.freeSpins.number + ' FS';
      }
      if (prize?.type === 0 && data) {
        return `${getCurrencyIcon(data?.currency)} ${makeFloat(prize.amount[data?.currency])}`;
      }
      if (prize?.type === 1 || prize?.type === 2) {
        return prize.name;
      }
      if (prize?.type === 4) {
        return prize.points + ' E.P.';
      }
    },
    [data]
  );
  return { getPrize };
};
