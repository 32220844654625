import { useMemo } from 'react';
import { makeFloat } from 'react-easyrocket';

export const factor = [2, 5, 10, 25];

export const useAmountPresets = ({ minPayment }: { minPayment?: number }) => {
  const amountPresets = useMemo(() => {
    if (!minPayment) {
      return ['40', '100', '200', '500'];
    }
    return factor.map((multiplier) => {
      return (multiplier * Number(makeFloat(minPayment))).toString();
    });
  }, [minPayment]);

  return amountPresets;
};
