import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

type Props = {
  item: ReactNode;
  className?: string;
};

export const TableCell = ({ item, className }: Props) => {
  return (
    <td className={twJoin(className, 'p-6')}>
      <p className="text-text-secondary">{item}</p>
    </td>
  );
};
