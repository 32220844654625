import {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
  RefObject,
  useEffect,
  PropsWithChildren
} from 'react';
import { useDeviceDetect } from 'react-easyrocket';
import { useLocation } from 'react-router-dom';
import { EVENT_LINKS } from 'src/event/config';

function fullScreen(element: HTMLDivElement | null) {
  // @ts-ignore
  if (element?.requestFullscreen) {
    // @ts-ignore
    element.requestFullscreen();
    // @ts-ignore
  } else if (element?.webkitrequestFullscreen) {
    // @ts-ignore
    element.webkitRequestFullscreen();
    // @ts-ignore
  } else if (element?.mozRequestFullscreen) {
    // @ts-ignore
    element.mozRequestFullScreen();
  }
}

function fullScreenCancel() {
  // @ts-ignore
  if (document.exitFullscreen) {
    // @ts-ignore
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.mozExitFullscreen) {
    // @ts-ignore
    document.mozExitFullscreen();
  }
}

export const FullScreenContext = createContext<{
  onFullscreenClose: () => void;
  isFullscreen: boolean;
  refFullscreen: RefObject<HTMLDivElement> | undefined;
  refGameFullscreen: RefObject<HTMLDivElement> | undefined;
  onFullscreenOpen: () => void;
  isHideHUD: boolean;
  isFullscreenGameBoard: boolean;
  refGameBoardFullscreen: RefObject<HTMLDivElement> | undefined;
}>({
  onFullscreenClose: () => {},
  isFullscreen: false,
  refFullscreen: undefined,
  refGameFullscreen: undefined,
  onFullscreenOpen: () => {},
  isHideHUD: false,
  isFullscreenGameBoard: false,
  refGameBoardFullscreen: undefined
});

export const useInitFullScreen = () => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const refFullscreen = useRef<HTMLDivElement>(null);
  const refGameFullscreen = useRef<HTMLDivElement>(null);

  const refGameBoardFullscreen = useRef<HTMLDivElement>(null);
  const [isFullscreenGameBoard, setIsFullscreenGameBoard] = useState<boolean>(false);

  const [isHideHUD, setIsHideHUD] = useState<boolean>(false);
  const { isIos } = useDeviceDetect();
  const { pathname } = useLocation();

  const onFullscreenClose = useCallback(() => {
    setIsHideHUD(false);
    fullScreenCancel();
    document.body.style.overflow = 'visible';
  }, []);

  const onFullscreenOpen = useCallback(() => {
    setIsHideHUD(true);
    fullScreen(refFullscreen.current);
    fullScreen(refGameBoardFullscreen.current);
    fullScreen(refGameFullscreen.current);
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    function onFullscreenChange() {
      const element = Boolean(document.fullscreenElement);
      setIsFullscreen(element);
      setIsFullscreenGameBoard(element);
      setIsHideHUD(element);

      element
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'visible');
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  // FALLBACK QUIT FOR IOS FAKE FULL SCREEN MODE
  useEffect(() => {
    if (isIos && (pathname !== EVENT_LINKS.CARD_GAME || pathname !== EVENT_LINKS.CATCH_GAME)) {
      setIsHideHUD(false);
      document.body.style.overflow = 'visible';
    }
  }, [isIos, pathname]);

  return {
    onFullscreenClose,
    isFullscreen,
    refFullscreen,
    onFullscreenOpen,
    refGameFullscreen,
    isHideHUD,
    isFullscreenGameBoard,
    refGameBoardFullscreen
  };
};

export const FullScreenProvider = ({ children }: PropsWithChildren) => {
  const values = useInitFullScreen();

  return <FullScreenContext.Provider value={values}>{children}</FullScreenContext.Provider>;
};

export const useFullScreen = () => {
  return useContext(FullScreenContext);
};

// /////////////////////
