import { LocalTournament, ShortBalanceResponse } from 'react-easyrocket';
import { PrizeTournament } from '../PrizeTournament';
import winnersCupIcon from 'src/assets/images/winnersCup.webp';
import { useTranslation } from 'react-i18next';

export const PrizePool = ({
  newestTournament,
  userBalance
}: {
  newestTournament: LocalTournament;
  userBalance: ShortBalanceResponse;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-[10px] items-center p-[10px] border-2 border-slt_prize_pool_border rounded-lg backdrop-blur-md  mb-[30px]">
      <img src={winnersCupIcon} alt="cup" className="h-[45px] w-[45px]" />
      <div className="flex flex-col justify-between">
        <p className="text-[16px] leading-[17px] font-bold text-slt_prize_pool first-letter:uppercase">
          {t('general.prizePool')}
        </p>
        {newestTournament && (
          <div className="flex flex-wrap text-[26px] leading-[28px] font-bold text-slt_prize_pool_text">
            <PrizeTournament tournament={newestTournament} currency={userBalance?.currency} />
          </div>
        )}
      </div>
    </div>
  );
};
