import { useEffect, useState } from 'react';
import { Child, useGetSinglePage } from 'react-easyrocket';
import { API_ENDPOINT_STORIES, LOCAL_STORAGE_STORIES, NEW_LOCAL_STORAGE_STORIES } from '../config';
import { OldStory, VisitedStories } from '../types';
import { getStoryKey } from '../utils';

export const useStories = () => {
  const { data, isFetched, isFetching } = useGetSinglePage(API_ENDPOINT_STORIES);

  const [visited, setVisited] = useState<VisitedStories>({});

  // mark story or substory as visited
  const markVisited = (storyKey: string, childInfo?: string) => {
    if (!childInfo) {
      setVisited((visited) => ({ ...visited, [storyKey]: true }));
      return;
    }

    setVisited((visited) => {
      const record = visited[storyKey];

      if (typeof record === 'object') {
        return { ...visited, [storyKey]: { ...record, [childInfo]: true } };
      }

      return { ...visited, [storyKey]: { [childInfo]: true } };
    });
  };

  const isStoryVisited = (story: Child) => {
    const key = getStoryKey(story.code, story.imageMobile);
    const record = visited[key];

    if (record) {
      return typeof record === 'object'
        ? Boolean(
            story.childs?.every((item) => {
              const itemKey = getStoryKey(item.code, item.imageMobile);
              return record[itemKey];
            })
          )
        : record;
    }

    return false;
  };

  // comment date 11.06.2024 - move old saved story format to new, TODO: should be removed after a while after release to users run this hook ones
  useEffect(() => {
    const oldStoriesData = localStorage.getItem(LOCAL_STORAGE_STORIES);

    if (oldStoriesData && data?.childs) {
      const visitedStories: VisitedStories = {};
      const oldStories: OldStory[] = JSON.parse(oldStoriesData);

      if (oldStories && Array.isArray(oldStories)) {
        oldStories.forEach((item) => {
          const dataItem = data.childs?.find((child) => child.code === item.code && item.visited);

          if (dataItem?.childs) {
            if (dataItem.childs.length === 1) {
              visitedStories[getStoryKey(dataItem.code, dataItem.imageMobile)] = true;
            } else {
              const visitedChilds: { [key: string]: boolean } = {};

              dataItem.childs.forEach((child) => {
                visitedChilds[getStoryKey(child.code, child.imageMobile)] = true;
              });

              visitedStories[getStoryKey(dataItem.code, dataItem.imageMobile)] = visitedChilds;
            }
          }
        });

        localStorage.setItem(NEW_LOCAL_STORAGE_STORIES, JSON.stringify(visitedStories));
        localStorage.removeItem(LOCAL_STORAGE_STORIES);
      }
    }
  }, [data]);

  // get data from local storage about visited stories
  useEffect(() => {
    const storedData = localStorage.getItem(NEW_LOCAL_STORAGE_STORIES);

    if (storedData) {
      const visitedStories: VisitedStories = JSON.parse(storedData);

      setVisited(visitedStories);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(NEW_LOCAL_STORAGE_STORIES, JSON.stringify(visited));
  }, [visited]);

  return {
    data,
    isFetched,
    isFetching,
    visited,
    markVisited,
    isStoryVisited
  };
};
