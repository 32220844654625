import { FC } from 'react';
import { SingleNavbarItem } from '../SingleNavbarItem';
import { useTranslation } from 'react-i18next';
import { NavbarSearchIcon, ProvidersMobile } from 'src/components/svg';
import { useDrawer } from 'src/hooks/useDrawer';
import { twJoin } from 'tailwind-merge';

interface ProvidersAndSearchProps {
  activeCategory: {
    GAME: boolean;
    LIVE: boolean;
    VIP: boolean;
    PROVIDERS: boolean;
  };
}

export const ProvidersAndSearch: FC<ProvidersAndSearchProps> = ({ activeCategory }) => {
  const { t } = useTranslation();
  const {
    toggleIsTopSearchDrawerOpen,
    isTopSearchDrawerOpen,
    toggleProvidersSearch,
    isTopDrawerOpen,
    closeTopDrawer
  } = useDrawer();

  const handleProvidersClick = () => {
    toggleProvidersSearch?.();
    isTopDrawerOpen && closeTopDrawer?.();
  };

  return (
    <>
      <SingleNavbarItem
        onClick={handleProvidersClick}
        textHighlightCondition={activeCategory.PROVIDERS}
        title={t('mobileMenuItem.providers')}>
        {/* w-[26px] h-auto */}
        <ProvidersMobile
          className={twJoin('w-[26px] h-auto', activeCategory.PROVIDERS && 'fill-yellow')}
        />
      </SingleNavbarItem>

      <SingleNavbarItem
        onClick={toggleIsTopSearchDrawerOpen}
        textHighlightCondition={false}
        title={t('general.search')}>
        <NavbarSearchIcon
          className={twJoin('w-[26px] h-auto', isTopSearchDrawerOpen && 'fill-yellow')}
        />
      </SingleNavbarItem>
    </>
  );
};
