import { useState } from 'react';
import { storageKeys } from './useTimeLeft';

export const useSpinCount = () => {
  const [spinCount, setSpinCount] = useState<number>(() => {
    const freespinsCount = localStorage.getItem(storageKeys.freespinsCount);

    if (!freespinsCount) {
      localStorage.setItem(storageKeys.freespinsCount, '2');
      return 2;
    }

    return +freespinsCount;
  });

  return { spinCount, setSpinCount };
};
