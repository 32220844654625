import { MakeDepositResponse } from 'react-easyrocket';
import { FormGenerator } from '../FormGenerator';
import { useModalContext } from 'src/hooks/useModalContext';

type FormFieldsModalProps = MakeDepositResponse & {
  paymentMethodName?: string;
  method: 'withdrawal';
};

export const FormFieldsModal = () => {
  const { modalProps } = useModalContext<FormFieldsModalProps>();

  return (
    <FormGenerator
      methodName={modalProps?.method}
      formFields={modalProps?.formFields}
      checkoutUrl={modalProps?.checkoutUrl}
      transaction={modalProps?.transaction}
      paymentMethodName={modalProps?.paymentMethodName}
    />
  );
};
