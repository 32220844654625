export const BookIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29688 34.1406C6.32604 34.1406 5.53906 34.9276 5.53906 35.8984C5.53906 36.8693 6.32604 37.6562 7.29688 37.6562H35.6403C36.2875 37.6562 36.8122 38.1809 36.8122 38.8281C36.8122 39.4753 36.2875 40 35.6403 40H7.29688C5.03162 40 3.19531 38.1637 3.19531 35.8984C3.19531 33.6332 5.03162 31.7969 7.29688 31.7969H35.6404C36.2876 31.7969 36.8123 32.3215 36.8123 32.9688C36.8123 33.616 36.2876 34.1406 35.6404 34.1406H7.29688Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29688 2.34375C6.32604 2.34375 5.53906 3.13072 5.53906 4.10156V35.8984C5.53906 36.5456 5.0144 37.0703 4.36719 37.0703C3.71998 37.0703 3.19531 36.5456 3.19531 35.8984V4.10156C3.19531 1.83631 5.03162 0 7.29688 0H35.6404C36.2876 0 36.8123 0.524666 36.8123 1.17188V32.9688C36.8123 33.616 36.2876 34.1406 35.6404 34.1406C34.9932 34.1406 34.4685 33.616 34.4685 32.9688V2.34375H7.29688Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05469 0C9.7019 0 10.2266 0.524666 10.2266 1.17188V32.9688C10.2266 33.616 9.7019 34.1406 9.05469 34.1406C8.40748 34.1406 7.88281 33.616 7.88281 32.9688V1.17188C7.88281 0.524666 8.40748 0 9.05469 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4297 31.7969C34.0769 31.7969 34.6016 32.3215 34.6016 32.9688V38.8281C34.6016 39.4753 34.0769 40 33.4297 40C32.7825 40 32.2578 39.4753 32.2578 38.8281V32.9688C32.2578 32.3215 32.7825 31.7969 33.4297 31.7969Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6365 5.08855C22.0525 5.02528 22.4704 5.18981 22.7316 5.51965L25.3712 8.85348L27.686 6.06762C27.9954 5.69526 28.5019 5.55149 28.9608 5.70577C29.4197 5.86005 29.7364 6.28062 29.758 6.76425L30.1284 15.0563C30.1556 15.6657 29.7108 16.1941 29.1056 16.2711L17.531 17.7437C16.9225 17.8212 16.3573 17.4163 16.2348 16.8152L14.5783 8.68672C14.4822 8.21485 14.6845 7.73218 15.0884 7.4699C15.4923 7.20762 16.0155 7.21912 16.4075 7.49891L19.2836 9.55191L20.7193 5.82577C20.8705 5.43319 21.2206 5.15182 21.6365 5.08855ZM22.1683 8.58391L20.927 11.8055C20.7967 12.1436 20.5174 12.4025 20.1703 12.5066C19.8233 12.6108 19.4476 12.5485 19.1527 12.338L17.4695 11.1366L18.3142 15.2815L27.7388 14.0823L27.5518 9.89639L26.2505 11.4625C26.0248 11.7342 25.6886 11.8895 25.3354 11.8854C24.9822 11.8812 24.6497 11.7179 24.4304 11.441L22.1683 8.58391Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3828 15.4092C18.03 15.4092 18.5547 15.9338 18.5547 16.5811V18.3056C18.5547 18.9528 18.03 19.4775 17.3828 19.4775C16.7356 19.4775 16.2109 18.9528 16.2109 18.3056V16.5811C16.2109 15.9338 16.7356 15.4092 17.3828 15.4092Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3438 14.7783C22.991 14.7783 23.5156 15.303 23.5156 15.9502V18.9233C23.5156 19.5705 22.991 20.0952 22.3438 20.0952C21.6965 20.0952 21.1719 19.5705 21.1719 18.9233V15.9502C21.1719 15.303 21.6965 14.7783 22.3438 14.7783Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8594 27.7637C15.8594 27.1165 16.384 26.5918 17.0312 26.5918H27.6475C28.2947 26.5918 28.8194 27.1165 28.8194 27.7637C28.8194 28.4109 28.2947 28.9355 27.6475 28.9355H17.0312C16.384 28.9355 15.8594 28.4109 15.8594 27.7637Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8594 23.2012C15.8594 22.554 16.384 22.0293 17.0312 22.0293H27.6475C28.2947 22.0293 28.8194 22.554 28.8194 23.2012C28.8194 23.8484 28.2947 24.373 27.6475 24.373H17.0312C16.384 24.373 15.8594 23.8484 15.8594 23.2012Z"
      fill="white"
    />
  </svg>
);
