import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react';

type SportContextType = {
  isSportPage: boolean;
  setIsSportPage: Dispatch<SetStateAction<boolean>>;
};

const SportContext = createContext<SportContextType>({
  isSportPage: false,
  setIsSportPage: () => undefined
});

export const SportContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSportPage, setIsSportPage] = useState(false);

  return (
    <SportContext.Provider
      value={{
        isSportPage,
        setIsSportPage
      }}>
      {children}
    </SportContext.Provider>
  );
};

export const useSportContext = () => {
  return useContext(SportContext);
};
