import { FC } from 'react';
import { NavLinkLng } from 'src/lib';
import { createPath } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { SkeletonOfAnySize } from 'src/components/Skeletons';

interface CategoryLinkProps {
  onCategoryClick?: () => void;
  lightActiveLink?: boolean;
  path: string;
  title: string;
  image: JSX.Element;
  isLoading?: boolean;
}

export const CategoryLink: FC<CategoryLinkProps> = ({
  onCategoryClick,
  lightActiveLink,
  path,
  title,
  image,
  isLoading
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="py-1 pr-2.5 flex flex-col items-center">
        <SkeletonOfAnySize className="w-[30px] h-[30px]" />
        <SkeletonOfAnySize className="w-32 h-5 mt-2" />
      </div>
    );
  }

  return (
    <div className="flex 768:gap-5 items-center gap-2" id={path}>
      <NavLinkLng
        aria-label={`${path}`}
        to={createPath({
          pathname: path
        })}
        key={title}
        className={({ isActive }) =>
          twJoin(
            'w-full min-w-[60px] 768:py-1 px-2.5 first:pl-0 text-[16px] font-[900] lowercase justify-center items-center flex gap-1 flex-col text-gcs_text 768:rounded-[100px] hover:text-gcs_text_hover rounded-[15px] whitespace-nowrap h-full py-1 text-sm 768:text-[16px]',
            isActive && lightActiveLink && 'text-gcs_text_active [&_svg_path]:fill-gcs_text_active',
            'transition-all duration-300 [&_svg_path]:transition-all [&_svg_path]:duration-300 [&_svg_path]:hover:fill-gcs_text_active'
          )
        }
        onClick={onCategoryClick}>
        <div className="w-[30px] h-[30px] flex justify-center items-center [&_svg]:w-full [&_svg]:h-auto">
          {image}
        </div>
        <span className="text-center mt-2 uppercase">{t(title)}</span>
      </NavLinkLng>
    </div>
  );
};
