import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import ModalWrapper from '../ModalWrapper';
import lockImage from 'src/assets/images/sidebarZeroLevelLock.webp';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from 'src/hooks/useModalContext';

export const TournamentRequiredLevel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeModal, openModal } = useModalContext();

  const handleDepositClick = () => {
    closeModal();
    openModal('POPUP_DEPOSIT');
  };

  const handleVipClick = () => {
    closeModal();
    navigate('/vip');
  };

  return (
    <ModalWrapper>
      <div className="px-5 pt-5 pb-10 max-w-[400px] flex flex-col items-center">
        <img className="w-[110px] mb-5" src={lockImage} alt="lock" />
        <p className="text-center text-xl font-bold leading-6 mb-2.5 tracking-[0.6px]">
          {t('tournaments.lowLevel')}
        </p>
        <p className="text-center text-xl font-bold leading-6 mb-10 tracking-[0.6px]">
          <Trans
            i18nKey="tournaments.zeroLevel"
            components={{
              '1': <span className="text-text-strong" />
            }}
          />
        </p>
        <div className="flex flex-col gap-5 w-full">
          <Button mode="button-secondary" className="!max-w-full" onClick={handleDepositClick}>
            {t('general.deposit')}
          </Button>
          <Button mode="button-primary" className="!max-w-full" onClick={handleVipClick}>
            {t('general.aboutVip')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};
