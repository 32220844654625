import { SVGPropsIcon } from 'src/types';

export const DoubleTick = (props: SVGPropsIcon) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.13317 11.8C7.93317 11.8 7.79984 11.7333 7.6665 11.6L4.8665 8.79999C4.59984 8.53332 4.59984 8.13332 4.8665 7.86665C5.13317 7.59999 5.53317 7.59999 5.79984 7.86665L8.13317 10.2L13.3332 4.99999C13.5998 4.73332 13.9998 4.73332 14.2665 4.99999C14.5332 5.26665 14.5332 5.66665 14.2665 5.93332L8.59984 11.6C8.53317 11.7333 8.33317 11.8 8.13317 11.8Z"
      fill="#A5AFD8"
    />
    <path
      d="M4.80016 11.8C4.60016 11.8 4.46683 11.7333 4.3335 11.6L1.5335 8.79999C1.26683 8.53332 1.26683 8.13332 1.5335 7.86665C1.80016 7.59999 2.20016 7.59999 2.46683 7.86665L5.26683 10.6667C5.5335 10.9333 5.5335 11.3333 5.26683 11.6C5.20016 11.7333 5.00016 11.8 4.80016 11.8ZM8.3335 8.33332C8.1335 8.33332 8.00016 8.26665 7.86683 8.13332C7.60016 7.86665 7.60016 7.46665 7.86683 7.19999L10.0002 4.99999C10.2668 4.73332 10.6668 4.73332 10.9335 4.99999C11.2002 5.26665 11.2002 5.66665 10.9335 5.93332L8.80016 8.13332C8.66683 8.26665 8.5335 8.33332 8.3335 8.33332Z"
      fill="#A5AFD8"
    />
  </svg>
);
