import { GAME_NAME, GAME_ORIGIN } from '../../../config';

type CheckIfHandleMessageParams = {
  gameName: string;
  messageOrigin: string;
};

export const checkIsValidMessage = ({ gameName, messageOrigin }: CheckIfHandleMessageParams) => {
  const isTrustedOrigin = messageOrigin === GAME_ORIGIN;
  const isRightGame = gameName === GAME_NAME;

  return isTrustedOrigin && isRightGame;
};
