import { useGetSinglePage } from 'react-easyrocket';
import { CONTENT_PAGES } from 'src/constants';
import ModalWrapper from '../ModalWrapper';

export const VipRulesModal = () => {
  const { data } = useGetSinglePage(CONTENT_PAGES.VIP);
  const rules = data?.childs?.find((child) => child.code === 'loyalty_main');
  return (
    <ModalWrapper containerClassName="!no-scrollbar">
      <div className="flex flex-col items-center  gap-5 p-[15px]  412:w-[400px] w-full h-full">
        {rules ? (
          <>
            <p className="text-lc_general_level_text text-[26px] font-bold text-center">
              {rules?.title}
            </p>
            <div className="cms-dropdown" dangerouslySetInnerHTML={{ __html: rules.content }} />
          </>
        ) : null}
      </div>
    </ModalWrapper>
  );
};
