import { UserMessage } from 'src/components/UserMessage';
import { MESSAGE_TYPE } from '../types';
import { MarketingMessage } from './MarketingMessage';
import { AnnouncementMessage } from './AnnouncementMessage';
import { NewsMessage } from './NewsMessage';

export const Message = ({
  type,
  uuid,
  props,
  isReadIt
}: {
  type: MESSAGE_TYPE;
  props: any;
  uuid: string;
  isReadIt?: boolean;
}) => {
  if (type === 'MARKETING') {
    return (
      <MarketingMessage id={uuid} status={props.type} text={props?.html} isReadIt={isReadIt} />
    );
  }

  if (type === 'ANNOUNCEMENT') {
    return (
      <AnnouncementMessage id={uuid} status={props.type} text={props?.html} isReadIt={isReadIt} />
    );
  }

  if (type === 'NEWS') {
    return <NewsMessage id={uuid} status={props.type} text={props?.html} isReadIt={isReadIt} />;
  }

  if (type === 'USER_MESSAGE') {
    return <UserMessage id={uuid} status={props.type} text={props.text} isReadIt={isReadIt} />;
  }
  return null;
};
