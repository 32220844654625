import { LocalTournament, useCountdownTimer } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';

export const TournamentTimer = ({ newestTournament }: { newestTournament: LocalTournament }) => {
  const timeLeft = useCountdownTimer(newestTournament?.dateEnd);
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-4 px-[18px] py-[12px] 768:px-0 min-w-[320px] max-w-[320px] w-full self-center 768:self-start ">
      {Object.entries(timeLeft).map(([date, value]) => {
        return (
          <div className="flex flex-col justify-center" key={date}>
            <p
              className={`text-slt_date tracking-[0.03em] text-[30px] leading-none font-medium text-center ${
                date !== 'seconds' ? 'border-r-2' : ''
              } `}>
              {value}
            </p>
            <p className="text-slt_date text-[11px] tracking-[0.03em] font-medium text-center">
              {t(`general.${date}`)}
            </p>
          </div>
        );
      })}
    </div>
  );
};
